import styled from "@emotion/styled";
import {
  Button,
  FlexRow,
  IconChevron,
  IconFilter,
  PageHeader,
  SiteContainer,
} from "atoms";
import { preventForwarding } from "styles/styleUtils";

type LayoutFilteredResultsProps = {
  /**
   * Optional element to display list of currently active filters
   */
  activeFilters?: React.ReactNode;
  /**
   * Form element to display filter inputs
   */
  filters: React.ReactNode;
  /**
   * Optional element to be displayed below results
   */
  footer?: React.ReactNode;
  /**
   * Element to be displayed in the header
   */
  header: React.ReactNode;
  /**
   * Boolean to control if filter sidebar is open or closed
   */
  isShowingFilters: boolean;
  /**
   * Element to be displayed in results section
   */
  results: React.ReactNode;
  /**
   * Optional element to display sort menu
   */
  sort?: React.ReactNode;
  /**
   * Function to toggle isShowingFilters boolean state
   */
  toggleIsShowingFilters: VoidFunction;
};

function LayoutFilteredResults({
  activeFilters = null,
  filters,
  footer = null,
  header,
  isShowingFilters,
  results,
  sort = null,
  toggleIsShowingFilters,
}: LayoutFilteredResultsProps) {
  return (
    <main>
      <SPageHeader bg="black" brush="2">
        {header}
      </SPageHeader>

      <SSiteContainer>
        {activeFilters && <SActiveFilters>{activeFilters}</SActiveFilters>}

        <FiltersToggleWrapper>
          <FiltersToggle
            onClick={toggleIsShowingFilters}
            aria-expanded={isShowingFilters ? "true" : "false"}
            variant="secondary"
            size="sm"
          >
            {isShowingFilters ? (
              <span>Hide&nbsp;Filters</span>
            ) : (
              <span>Show&nbsp;Filters</span>
            )}
            <IconFilter />
          </FiltersToggle>
        </FiltersToggleWrapper>

        <SortWrapper>
          <MobileFiltersToggle
            onClick={toggleIsShowingFilters}
            isShowing={isShowingFilters}
            aria-expanded={isShowingFilters ? "true" : "false"}
            variant="secondary"
            size="sm"
          >
            Filter
            <IconChevron size=".75em" />
          </MobileFiltersToggle>

          {sort}
        </SortWrapper>

        <ResultsWrapper>
          <FiltersWrapper isShowing={isShowingFilters}>
            {filters}
          </FiltersWrapper>

          <Results isShowing={isShowingFilters}>{results}</Results>
        </ResultsWrapper>

        {footer}
      </SSiteContainer>
    </main>
  );
}

const SPageHeader = styled(PageHeader)(({ theme }) => ({
  [theme.breakpoints.maxDesktop]: {
    textAlign: "left",
  },
  paddingTop: 0,
  paddingBottom: 0,
}));

const bpDesktop = "@media (min-width: 1100px)";
const bpMobile = "@media (max-width: 1099px)";
const filtersWidth = "250px";

const SSiteContainer = styled(SiteContainer)({
  "body &": {
    paddingTop: "3rem",
  },
  [bpDesktop]: {
    display: "grid",
    gap: "2rem 3rem",
    gridTemplateColumns: `${filtersWidth} auto`,
    gridTemplateAreas: `
      "header header"
      "leftMenu rightMenu"
      "main main"
      "footer footer"`,
  },
});

const SActiveFilters = styled.div({
  gridArea: "header",
});

const FiltersToggleWrapper = styled.div({
  alignItems: "center",
  display: "flex",
  gridArea: "leftMenu",
});

const FiltersToggle = styled(Button)({
  display: "none",
  alignItems: "center",
  cursor: "pointer",
  width: "18.5ch",
  "& > span": {
    flex: "1 0 50%",
    textAlign: "left",
  },
  svg: {
    display: "inline-block",
  },
  [bpDesktop]: {
    display: "flex",
  },
});

const SortWrapper = styled(FlexRow)({
  alignItems: "center",
  justifyContent: "space-between",
  gridArea: "rightMenu",
  padding: "2rem 0",
  position: "relative",
  "&>*": {
    flex: "0 0 auto",
  },
  [bpDesktop]: {
    padding: 0,
    justifyContent: "flex-end",
  },
});

type WithIsShowing = { isShowing: boolean };

const MobileFiltersToggle = styled(Button, {
  shouldForwardProp: preventForwarding<WithIsShowing>("isShowing"),
})<WithIsShowing>(({ isShowing }) => ({
  alignItems: "center",
  display: "flex",
  flex: "0 0 auto",
  minHeight: 0,
  svg: {
    transform: isShowing ? "rotate(0deg)" : "rotate(180deg)",
  },
  [bpDesktop]: {
    display: "none",
  },
}));

const ResultsWrapper = styled.div({
  gridArea: "main",
  position: "relative",
  [bpDesktop]: {
    display: "grid",
    gap: "2rem 3rem",
    gridTemplateColumns: `${filtersWidth} auto`,
    gridTemplateAreas: `"filters results"`,
  },
});

const FiltersWrapper = styled.div<WithIsShowing>(({ isShowing, theme }) => ({
  alignSelf: "start",
  background: "rgba(255 255 255 / 0)",
  opacity: isShowing ? 1 : 0,
  visibility: isShowing ? "visible" : "hidden",
  "@media (prefers-reduced-motion: no-preference)": {
    transition: "opacity 0.3s ease, transform 0.2s ease",
  },
  zIndex: 11,
  [bpMobile]: {
    background: theme.colors.bgLight,
    borderRadius: theme.borderRadius.md,
    boxShadow: isShowing ? `${theme.boxShadow.dark}` : "none",
    height: isShowing ? "auto" : 0,
    ...(!isShowing && {
      overflow: "hidden",
    }),
    padding: isShowing ? "1rem" : 0,
    position: "absolute",
    left: 0,
    width: "min(20rem, calc(100vw - 2rem))",
    zIndex: 11,
    transform: "translateY(-1.5rem)",
  },
  [bpDesktop]: {
    boxShadow: "none",
    gridArea: "filters",
    marginLeft: "-1rem",
    maxHeight: "unset",
    padding: "0 0 0 1rem",
    pointerEvents: isShowing ? "auto" : "none",
    position: "sticky",
    top: 60,
    transform: isShowing ? "translateX(0)" : "translateX(-100%)",
  },
}));

const Results = styled.div<WithIsShowing>(({ isShowing }) => ({
  gridArea: "results",
  "@media (prefers-reduced-motion: no-preference)": {
    transition: "margin 0.2s ease",
  },
  [bpDesktop]: {
    marginLeft: isShowing ? 0 : `calc(-${filtersWidth} - 3rem)`,
  },
}));

export { LayoutFilteredResults };
