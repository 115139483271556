import * as React from "react";
import styled from "@emotion/styled";
import { useSearchParams } from "react-router-dom";
import type { MutateOptions } from "react-query";
import { constants } from "config";
import {
  Button,
  FlexRow,
  IconInstagram,
  IconNewImage,
  Modal,
  Stack,
  Text,
} from "atoms";
import { HiddenInput, acceptTypes } from "features/images";
import { useEditorContext } from "../utils/useEditorContext";
import { GalleryItemForm } from "./GalleryItemForm";
import { GalleryItemsForm } from "./GalleryItemsForm";
import type { CreateGalleryItemsRequest, GalleryItem } from "../types";
import { AddItemsFromPortfolio } from "./AddItemsFromPortfolio";
import { useGalleryItems } from "../api/useGalleryItems";
import { rootGalleryId } from "../utils/rootGallery";
import { AddItemsFromWallet } from "./AddItemsFromWallet";
import { AddItemsInstagram } from "./AddItemsInstagram";

type AddItemsState = {
  source: undefined | "instagram" | "wallet" | "portfolio";
  files: File[];
};

const initialState: AddItemsState = { source: undefined, files: [] };

type AddItemsAction =
  | { type: "addFiles"; files: AddItemsState["files"] }
  | { type: "changeSource"; source: AddItemsState["source"] }
  | { type: "reset" };

const addItemsReducer = (state: AddItemsState, action: AddItemsAction) => {
  switch (action.type) {
    case "addFiles": {
      return { ...state, files: action.files };
    }
    case "changeSource": {
      return { ...state, files: [], source: action.source };
    }
    case "reset": {
      return initialState;
    }
    default: {
      return state;
    }
  }
};

const addItemsFineText = "Multiple images and videos can be added at a once.";

type AddItemsProps = {
  close: VoidFunction;
  galleryId?: string;
  multiple?: boolean;
  onSuccess?: MutateOptions<
    GalleryItem[],
    unknown,
    CreateGalleryItemsRequest
  >["onSuccess"];
};

function AddItems({
  close,
  galleryId,
  multiple = true,
  onSuccess,
}: AddItemsProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  const { artist } = useEditorContext();

  const { data } = useGalleryItems({ galleryId: rootGalleryId(artist.id) });
  const totalResults = data?.length ?? 0;

  const [{ source, files }, dispatch] = React.useReducer(
    addItemsReducer,
    initialState,
  );

  const dispatchAddSelectedFiles = (selectedFiles: File[]) =>
    dispatch({ type: "addFiles", files: selectedFiles });

  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    if (event.target.files && event.target.files.length > 0) {
      dispatchAddSelectedFiles([...event.target.files]);
    }
  };

  React.useEffect(() => {
    if (searchParams.get("code")) {
      dispatch({ type: "changeSource", source: "instagram" });
    }
  }, [searchParams, setSearchParams]);

  // If we have files already, show the form for a Single or Multi item upload
  if (files.length) {
    // Pass default values for creating new Gallery Items to underlying form fields
    const defaultValues: Partial<GalleryItem> = {
      galleryFolderIds: galleryId ? [galleryId] : [],
      itemType: "item",
      source,
    };

    if (totalResults + files.length > constants.maxPortfolioItems) {
      return (
        <>
          <Modal.Header>
            <Modal.Title>
              <IconNewImage /> Item Limit Reached
            </Modal.Title>
          </Modal.Header>

          <Text textAlign="center">
            Your Portfolio supports a maximum of {constants.maxPortfolioItems}{" "}
            Items. Please try again with fewer uploads.
          </Text>

          <Modal.Footer>
            <FlexRow justifyContent="center">
              <Button variant="secondary" onClick={close}>
                Close
              </Button>
            </FlexRow>
          </Modal.Footer>
        </>
      );
    }

    if (files.length === 1) {
      // Use the first file's name sans extension to fill the Single item upload form's title field
      const title = files[0].name.slice(0, files[0].name.lastIndexOf("."));

      return (
        <GalleryItemForm
          artistId={artist.id}
          cancel={close}
          fileToUpload={files[0]}
          defaultValues={{ ...defaultValues, title }}
          onSuccess={onSuccess ?? close}
          hideReplaceButton={source === "instagram" || source === "wallet"}
        />
      );
    }

    // Otherwise, use the Multi item upload form with simplified fields per item
    return (
      <GalleryItemsForm
        artistId={artist.id}
        cancel={close}
        defaultValues={defaultValues}
        filesToUpload={files}
        onSuccess={onSuccess ?? close}
        retry={() => dispatch({ type: "reset" })}
      />
    );
  }

  const createClickHandler = (newSource: AddItemsState["source"]) => () =>
    dispatch({ type: "changeSource", source: newSource });

  if (source === "instagram") {
    return (
      <AddItemsInstagram
        setSelectedFiles={dispatchAddSelectedFiles}
        onBack={createClickHandler(undefined)}
      />
    );
  }

  if (source === "portfolio" && !!galleryId) {
    return <AddItemsFromPortfolio galleryId={galleryId} closeModal={close} />;
  }

  if (source === "wallet") {
    return (
      <AddItemsFromWallet
        setSelectedFiles={dispatchAddSelectedFiles}
        onClick={createClickHandler(undefined)}
      />
    );
  }
  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <IconNewImage /> Add <span>{multiple ? "Items" : "Item"}</span> to{" "}
          <span>{galleryId ? "Gallery" : "Portfolio"}</span>
        </Modal.Title>
      </Modal.Header>
      <Stack gap="md">
        <Stack gap="sm">
          <Stack gap="xxs">
            {galleryId ? (
              <Text textAlign="center">
                <span>
                  Add <span>{multiple ? "Items" : "Item"}</span> to your
                  Gallery.{" "}
                </span>
                {multiple && <span>{addItemsFineText}</span>}
              </Text>
            ) : (
              <>
                <Text textAlign="center" bold>
                  You may add up to {constants.maxPortfolioItems} items to your
                  Portfolio.
                </Text>
                {multiple && (
                  <Text size="xs" textAlign="center">
                    {addItemsFineText}
                  </Text>
                )}
              </>
            )}
          </Stack>

          <ButtonFlexRow justifyContent="center" gap="10px">
            <Button as="label" variant="primary">
              Upload From Device
              <HiddenInput
                type="file"
                accept={acceptTypes.imageOrVideo.values}
                multiple={multiple}
                onChange={onChange}
              />
            </Button>

            <InstagramButton
              onClick={createClickHandler("instagram")}
              variant="secondary"
            >
              <FlexRow gap="4px" as="span">
                Import from Instagram <IconInstagram />
              </FlexRow>
            </InstagramButton>

            <Button onClick={createClickHandler("wallet")} variant="secondary">
              Import from Wallet
            </Button>
          </ButtonFlexRow>
        </Stack>

        {galleryId && (
          <Stack gap="xs">
            <Text textAlign="center">Or select from your Portfolio</Text>

            <ButtonFlexRow justifyContent="center" gap="10px">
              <Button
                onClick={createClickHandler("portfolio")}
                variant="primary"
                // size="xs"
              >
                Select Existing Items
              </Button>
            </ButtonFlexRow>
          </Stack>
        )}
      </Stack>
      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button variant="secondary" onClick={close}>
            Cancel
          </Button>
        </FlexRow>
      </Modal.Footer>
    </>
  );
}

const InstagramButton = styled(Button)(({ theme }) => ({
  // Instagram Gradient
  borderColor: theme.colors.transparent,
  position: "relative",
  span: {
    position: "relative",
    zIndex: 4,
  },
  "&::before": {
    borderRadius: theme.borderRadius.round,
    content: "''",
    position: "absolute",
    inset: -2,
    zIndex: 1,
    backgroundImage:
      "linear-gradient(90deg, #EDB940 1.2%, #E23E2E 31.04%, #E337BF 61.92%, #4F49C2 100%)",
  },
  "&::after": {
    backgroundColor: theme.colors.bg,
    borderRadius: theme.borderRadius.round,
    content: "''",
    position: "absolute",
    inset: 0,
    zIndex: 2,
    opacity: 1,
  },
  "&:hover, &:focus-visible": {
    borderColor: theme.colors.transparent,
    backgroundColor: theme.colors.transparent,
    color: theme.colors.fg,

    "&::after": {
      opacity: 0.66,
    },
  },
}));

const ButtonFlexRow = styled(FlexRow)(({ theme }) => ({
  flexDirection: "column",
  gap: 10,
  "&>*": {
    width: "100%",
    maxWidth: 360,
  },
  [theme.breakpoints.desktop]: {
    flexDirection: "row",
    marginInline: -10,
    "&>*": {
      width: "auto",
    },
  },
  [theme.breakpoints.desktopMedium]: {
    flexDirection: "row",
    marginInline: 0,
    "&>*": {
      minWidth: 260,
    },
  },
}));

export { AddItems };
