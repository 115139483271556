import * as React from "react";
import { CartProvider, ShopifyProvider } from "@shopify/hydrogen-react";
import { constants } from "config";

type CartNotificationState = {
  isOpen: boolean;
  open: () => void;
  close: () => void;
};

const CartNotificationContext =
  React.createContext<CartNotificationState | null>(null);

const useCartNotifications = () => {
  const context = React.useContext(CartNotificationContext);

  if (!context) {
    throw new Error(
      "useCartNotifications() may be used only within the context of a <ShopProvider> component",
    );
  }

  return context;
};

// TODO: Add mixpanel events to on* event props for CartProvider?
// onCreateComplete
// onLineAddComplete
// onLineRemoveComplete
// onBuyerIdentityUpdate
function ShopProvider({ children }: { children: React.ReactNode }) {
  const [isOpen, setIsOpen] = React.useState(false);

  const value = React.useMemo(
    () => ({
      isOpen,
      open: () => setIsOpen(true),
      close: () => setIsOpen(false),
    }),
    [isOpen, setIsOpen],
  );

  return (
    <ShopifyProvider
      countryIsoCode="US"
      languageIsoCode="EN"
      storeDomain={constants.shopifyDomain}
      storefrontToken={constants.shopifyAccessToken}
      // NOTE: this has to match the installed @shopify/hydrogen-react version
      storefrontApiVersion="2024-01"
    >
      <CartProvider
        onCreateComplete={value.open}
        onLineAddComplete={value.open}
      >
        <CartNotificationContext.Provider value={value}>
          {children}
        </CartNotificationContext.Provider>
      </CartProvider>
    </ShopifyProvider>
  );
}

export { ShopProvider, useCartNotifications };
