import * as React from "react";
import styled from "@emotion/styled";
import shipYourselfImage from "assets/images/product-category-ship-it-yourself.jpg";
import { Button, FlexRow, IconArrow } from "atoms";
import { useArtist } from "features/reviewable";
import { paths } from "utils/paths";
import { ProductWizardTemplate } from "./ProductWizardTemplate";
import { StepButton } from "./WizardStepOption";
import {
  comingSoonTemplates,
  productTemplateSteps,
  templateCategories,
} from "../data/productTemplates";
import type {
  ProductTemplate,
  ProductTemplateSteps,
  TemplateCategory,
} from "../types";

const TITLE = "Create New Product";

const templatesByCategory = productTemplateSteps.reduce<
  Record<TemplateCategory, ProductTemplateSteps[]>
>(
  (acc, cur) => {
    acc[cur.templateCategory].push(cur);
    return acc;
  },
  {
    "Your Physical Product": [],
    "Onchain Collectible": [],
    "Digital Download": [],
    "Fine Art Print": [],
    "Spiral Notebook": [],
    "Hardcover Journal": [],
    "Phone Case": [],
    "Ceramic Mug": [],
  },
);

type ChooseProductTemplateProps = {
  artistId: string;
  templates: ProductTemplate[];
};

function ChooseProductTemplate({
  artistId,
  templates,
}: ChooseProductTemplateProps) {
  const { data: artist } = useArtist(artistId);
  const hasShipYourselfAccess = artist?.shopEnableShipItYourself;

  const [state, setState] = React.useState<{
    category?: TemplateCategory;
    template?: ProductTemplateSteps;
  }>({});

  // If the artist does not have SIY access, remove it from the array

  // First, choose category of templates
  if (!state.category) {
    const createOnClick = (category: TemplateCategory) => () => {
      // If the category only has one associated template, select it as well to skip step 2
      if (templatesByCategory[category].length === 1) {
        setState({ category, template: templatesByCategory[category][0] });
      } else {
        setState({ category });
      }
    };

    return (
      <ProductWizardTemplate
        title={TITLE}
        subtitle="What type of product would you like to sell?"
        artistId={artistId}
      >
        <SGrid>
          {/* Show categories, except SIY if the artist does note have access */}
          {templateCategories.map(({ name, ...category }) =>
            name === "Your Physical Product" &&
            !hasShipYourselfAccess ? null : (
              <StepButton
                key={name}
                label={name}
                {...category}
                onClick={createOnClick(name)}
              />
            ),
          )}

          {/* Show the Request Access version of the SIY card */}
          {!hasShipYourselfAccess && (
            <a
              href={paths.shopRequestShipItYourselfAccess}
              target="_blank"
              rel="noreferrer"
              style={{ textDecoration: "none", display: "flex" }}
            >
              <SStepButton
                label="Your Physical Product"
                image={shipYourselfImage}
                description={
                  <>
                    Ship It Yourself
                    <AccessButton>Request Access</AccessButton>
                  </>
                }
              />
            </a>
          )}

          {comingSoonTemplates.map(({ name, ...category }) => (
            <StepButton key={name} label={name} {...category} isDisabled />
          ))}
        </SGrid>
      </ProductWizardTemplate>
    );
  }

  // Second, choose the template (unless the above useEffect selected it automatically)
  if (!state.template) {
    const moveBack = () => setState({});
    const createOnClick = (template: ProductTemplateSteps) => () =>
      setState({ ...state, template });

    return (
      <ProductWizardTemplate
        title={TITLE}
        subtitle={`Choose a ${state.category} product`}
        artistId={artistId}
      >
        <FlexRow justifyContent="center" style={{ marginBlock: 18 }}>
          {/* TODO: Create better "back" button function */}
          <Button size="xxs" variant="text" onClick={moveBack}>
            <small>
              <IconArrow dir="l" />
            </small>{" "}
            Choose a Different Category
          </Button>
        </FlexRow>
        <SGrid>
          {templatesByCategory[state.category].map((template) => (
            <StepButton
              key={template.id}
              onClick={createOnClick(template)}
              label={template.name}
              image="placeholder"
            />
          ))}
        </SGrid>
      </ProductWizardTemplate>
    );
  }

  // Lastly, show the CreateStep component for this template
  const {
    template: { CreateStep, createStepOptions, id, name },
    category,
  } = state;

  // Find the template data from backend for this template id
  const productTemplate = templates.find((t) => t.id === id);
  if (!productTemplate) {
    throw new Error(`There is no template data for id: ${id}`);
  }

  // Create a back action for the step component, restarting at the beginning if the template was
  // automatically selected as the only template of its category, or at step 2 to repick template
  const moveBack =
    templatesByCategory[category].length === 1
      ? () => setState({})
      : () => setState({ category });

  return (
    <CreateStep
      artistId={artistId}
      moveBack={moveBack}
      productTemplateName={name}
      productTemplate={productTemplate}
      options={createStepOptions}
    />
  );
}

const SGrid = styled.div(({ theme }) => ({
  display: "grid",
  marginInline: "auto",
  gap: theme.spacing.gap,
  gridTemplateColumns: "repeat(2, 1fr)",
  [theme.breakpoints.tablet]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  [theme.breakpoints.desktopMedium]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));

const SStepButton = styled(StepButton)({
  position: "relative",
});

const AccessButton = styled.div(({ theme }) => ({
  ...theme.fonts.display,
  alignItems: "center",
  backgroundColor: theme.colors.bg,
  border: `2px solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.round,
  color: theme.colors.fg,
  cursor: "pointer",
  display: "inline-flex",
  fontWeight: "bold",
  gap: 6,
  justifyContent: "center",
  fontSize: theme.fontSizes.xxs,
  letterSpacing: theme.spacing.letterSpacing,
  lineHeight: 1.2,
  padding: "0.45em 1.2em 0.4em",
  textAlign: "center",
  textDecoration: "none",
  textTransform: "uppercase",

  "&:hover, &:focus-visible": {
    textDecoration: "none",
    color: theme.colors.fg,
  },
  position: "absolute",
  left: "50%",
  top: "40%",
  transform: "translate(-50%, -50%)",
  whiteSpace: "nowrap",
  "button:hover &, button:focus-visible &": {
    backgroundColor: theme.colors.fg,
    color: theme.colors.bg,
  },
}));

export { ChooseProductTemplate };
