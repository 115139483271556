import styled from "@emotion/styled";
import { H2, Link, Text } from "atoms";
import { Thumbnail } from "features/images";
import { paths } from "utils/paths";
import type { Artist } from "../types";

type Props = {
  artist?: Pick<Artist, "id" | "name" | "description" | "heroImage">;
  showDescription?: boolean;
  inline?: boolean;
};

function ArtistSummary({ artist, showDescription = true, ...props }: Props) {
  if (!artist) {
    return null;
  }

  const { name, description, id, heroImage } = artist;

  return (
    <SummaryWrapper {...props}>
      <SThumbnail alt={name ?? ""} src={heroImage} />
      <div>
        <SH2>{name}</SH2>
        {showDescription && <Text size="xs">{description}</Text>}
        <SLink to={paths.artistProfile(id)}>Return to profile</SLink>
      </div>
    </SummaryWrapper>
  );
}

const SummaryWrapper = styled.div<{ inline?: boolean }>(
  ({ inline, theme }) => ({
    alignItems: "flex-start",
    borderRadius: theme.borderRadius.md,
    boxShadow: theme.boxShadow.light,
    display: "flex",
    flexWrap: "wrap",
    gap: "1rem",
    marginBottom: "4rem",
    padding: "1rem",
    width: "max-content",
    maxWidth: "100%",
    "div:first-of-type": {
      borderRadius: theme.borderRadius.round,
    },
    ...(inline && {
      alignItems: "center",
      boxShadow: "none",
      borderRadius: 0,
      flexWrap: "nowrap",
      margin: "1rem 0",
      padding: 0,
      figure: {
        aspectRatio: "1 / 1",
        margin: 0,
      },
    }),
  }),
);

const SThumbnail = styled(Thumbnail)(() => ({
  flex: "0 0 80px",
  aspectRatio: "1",
  margin: 0,
  height: 80,
  objectFit: "cover",
  width: 80,
}));

const SH2 = styled(H2)(({ theme }) => ({
  fontSize: theme.fontSizes.lg,
  marginBottom: ".3em",
}));

const SLink = styled(Link)(({ theme }) => ({
  fontSize: theme.fontSizes.xxs,
  whiteSpace: "nowrap",
}));

export { ArtistSummary };
