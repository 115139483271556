import styled from "@emotion/styled";

const Callout = styled.div<{ center?: boolean }>(({ center, theme }) => ({
  background: theme.colors.fg05,
  borderRadius: theme.borderRadius.md,
  padding: theme.spacing.lg,
  width: "100%",
  [theme.breakpoints.tablet]: {
    padding: theme.spacing.lg,
  },
  [theme.breakpoints.desktop]: {
    padding: theme.spacing.xl,
  },
  ...(center && {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    textAlign: "center",
  }),
}));

export { Callout };
