import { constants } from "config";
import type { CatalogItem } from "../types";
import { CHECKOUT_SESSION_ID_PARAM } from "../utils/stripe";

// NOTE: Check price ids on https://dashboard.stripe.com/products/<productId>
// Make sure you are in the correct mode (Prod or Test), toggle is in top right of header

type ProductKey =
  | "innoLabDeposit"
  | "innoLabV2Deposit"
  | "extraOCSubmissions"
  | "webinarFFCTaxStrategy"
  | "hugFest2024"
  | "webinarFFCArtLicensing"
  | "webinarRefikAnadol"
  | "hugLensBootcamp";

// Default checkout confirmation page for successful Stripe checkout redirects
const returnUrl = `${window.location.origin}/checkout/confirmation?${CHECKOUT_SESSION_ID_PARAM}`;

const prodCatalog: Readonly<Record<ProductKey, Readonly<CatalogItem>>> = {
  innoLabDeposit: {
    title: "HUG x Stability.AI Innovation Lab",
    priceId: "price_1OAb8ZGt1zTubKVyJ2PV2kEt",
    productId: "prod_OvdQCox9YirCqy",
    returnUrl,
    cost: "$179",
    costInCents: 17900,
  },
  innoLabV2Deposit: {
    title: "HUG x Stability AI Innovation Laboratory II",
    priceId: "price_1PEcTBGt1zTubKVyjYG2BYqP",
    productId: "prod_Q4m1KUGpClEtT7",
    returnUrl,
    cost: "$179",
    costInCents: 17900,
  },
  extraOCSubmissions: {
    priceId: "price_1ODAgyGt1zTubKVy3Fj95cBe",
    productId: "prod_P1D7FZSpswe26P",
    returnUrl,
    cost: "$10",
    costInCents: 1000,
  },
  webinarFFCTaxStrategy: {
    title: "Finance for Creatives: Tax Strategy Masterclass",
    priceId: "price_1ObWyQGt1zTubKVyb4CF0CsM",
    productId: "prod_PQNj5UNdvXZvjs",
    returnUrl,
    cost: "$69",
    costInCents: 6900,
  },
  hugFest2024: {
    title: "HUGFest",
    priceId: "price_1Oj7PpGt1zTubKVywUgu8BGI",
    productId: "prod_PYDrUtzH7DnQEm",
    returnUrl,
    cost: "Free",
    costInCents: 0,
  },
  webinarFFCArtLicensing: {
    title:
      "Finance for Creatives: Art Licensing Masterclass with Amber Vittoria",
    priceId: "price_1Oj7PHGt1zTubKVyKvr4wSjx",
    productId: "prod_PYDqXgUkYhTAAV",
    returnUrl,
    cost: "$129",
    costInCents: 12900,
  },
  webinarRefikAnadol: {
    title: "Refik Anadol Webinar",
    priceId: "price_1Papf6Gt1zTubKVyssL5Tnj4",
    productId: "prod_QRj7xiWQir71Py",
    returnUrl,
    cost: "Free",
    costInCents: 0,
  },
  hugLensBootcamp: {
    title: "HUG x Lens Innovation Bootcamp",
    priceId: "price_1Phwl9Gt1zTubKVyFzE6YA6V",
    productId: "prod_QZ4uStgNTRMl08",
    returnUrl,
    cost: "Free",
    costInCents: 0,
  },
};

const testCatalog: typeof prodCatalog = {
  innoLabDeposit: {
    ...prodCatalog.innoLabDeposit,
    priceId: "price_1O70pMGt1zTubKVybZ4x0kVn",
    productId: "prod_OuqWns54Gcw9Vf",
  },
  innoLabV2Deposit: {
    title: "HUG x Stability AI Innovation Laboratory II",
    priceId: "price_1PGLhbGt1zTubKVyxLOOoWzH",
    productId: "prod_Q6Yp36qBHYS97S",
    returnUrl,
    cost: "$179",
    costInCents: 17900,
  },
  extraOCSubmissions: {
    ...prodCatalog.extraOCSubmissions,
    priceId: "price_1OC22pGt1zTubKVyQqdEJswj",
    productId: "prod_P026JINGoA3ei8",
  },
  webinarFFCTaxStrategy: {
    ...prodCatalog.webinarFFCTaxStrategy,
    priceId: "price_1OboBRGt1zTubKVyoSLIVy53",
    productId: "prod_PQfWBf82x4btE7",
  },
  hugFest2024: {
    ...prodCatalog.hugFest2024,
    priceId: "price_1Ojj1VGt1zTubKVyI1yYvm5x",
    productId: "prod_PYqiwTqQrVhpOk",
  },
  webinarFFCArtLicensing: {
    ...prodCatalog.webinarFFCArtLicensing,
    priceId: "price_1OlYUKGt1zTubKVy63KGaDb2",
    productId: "prod_PajyWqILgSTTXu",
  },
  webinarRefikAnadol: {
    ...prodCatalog.webinarRefikAnadol,
    priceId: "price_1PcswjGt1zTubKVyNs7miG8P",
    productId: "prod_QTqe2rnvPQslIo",
  },
  hugLensBootcamp: {
    ...prodCatalog.hugLensBootcamp,
    priceId: "price_1PlAueGt1zTubKVySWn5MM6N",
    productId: "prod_QcPkU5REVTZ3Gp",
  },
};

const catalog = constants.stripeTestEnv ? testCatalog : prodCatalog;

export { catalog };
