import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";
import { SiteHeader } from "./SiteHeader";
import { SiteFooter } from "./SiteFooter";

function LayoutTask() {
  return (
    <Site>
      <SiteHeader minimal isSticky={false} />
      <SiteContent>
        <Outlet />
      </SiteContent>
      <SiteFooter />
    </Site>
  );
}
function LayoutTaskBlank() {
  return (
    <Site>
      <SiteContent>
        <Outlet />
      </SiteContent>
    </Site>
  );
}

const Site = styled.div({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
  "#newsletter": {
    display: "none",
  },
});

const SiteContent = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  flex: "1 1 auto",
  // overflow: "hidden",
}));

export { LayoutTask, LayoutTaskBlank };
