import * as React from "react";
import {
  Alert,
  AppError,
  Button,
  FieldError,
  Form,
  Input,
  Label,
  Link,
  PasswordInput,
  RequiredText,
  Stack,
  Text,
} from "atoms";
import { fieldOptions, validators } from "utils/form";
import type { RedirectState } from "utils/routing";
import { useCreateAccountForm } from "../api/useCreateAccountForm";

type CreateAccountFormProps = {
  onSuccess?: VoidFunction;
  redirectState?: RedirectState;
};

function CreateAccountForm({
  onSuccess = noop,
  redirectState,
}: CreateAccountFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    mutation: { mutate, data, error, isLoading },
    setValue,
  } = useCreateAccountForm({ onSuccess });

  // Current state of the form - Email or Verification
  const [formState, setFormState] = React.useState<"EMAIL" | "VERIFY">("EMAIL");

  const resetForm = () => {
    setValue("verificationCode", undefined);
    setFormState("EMAIL");
  };

  const onSubmit = handleSubmit((fields) =>
    mutate(fields, {
      onSuccess: (resp) => {
        if (resp.status === "VERIFICATION_SENT") {
          setFormState("VERIFY");
        }
      },
    }),
  );

  return (
    <Form onSubmit={onSubmit} style={{ width: "100%" }}>
      {formState === "VERIFY" ? (
        <Label>
          Verification Code
          <RequiredText />
          <Input
            {...register("verificationCode", { ...fieldOptions.required })}
            type="text"
          />
        </Label>
      ) : (
        <>
          <Label>
            Email
            <RequiredText />
            <Input
              {...register(`email`, {
                ...fieldOptions.required,
                validate: validators.validEmail,
              })}
            />
            {errors?.email && <FieldError>{errors?.email?.message}</FieldError>}
          </Label>

          <Label>
            Display Name
            <RequiredText /> <small>Minimum of 3 characters</small>
            <Input
              {...register("displayName", {
                ...fieldOptions.required,
                ...fieldOptions.minLength(3),
              })}
              type="text"
            />
            {errors?.displayName && (
              <FieldError>{errors?.displayName?.message}</FieldError>
            )}
          </Label>

          <PasswordInput
            register={register}
            fieldName="password"
            error={errors.password}
          />
        </>
      )}

      {error instanceof Error && (
        <AppError style={{ margin: 0 }}>
          <Stack>
            <Text size="sm" bold>
              The email or display name is associated with
              an&nbsp;existing&nbsp;account.
            </Text>
            <Text size="sm">
              Please{" "}
              <Link to="/sign-in" state={redirectState}>
                sign&nbsp;in
              </Link>{" "}
              or choose a&nbsp;different display&nbsp;name.
            </Text>
          </Stack>
        </AppError>
      )}

      <Text textAlign="center">
        <Button type="submit" disabled={isLoading}>
          {formState === "VERIFY" ? "Verify Email" : "Continue"}
        </Button>
      </Text>

      {formState === "VERIFY" && data?.status === "BAD_CODE" && (
        <Alert type="warning">
          The verification code does not match. Please enter the correct code or{" "}
          <Button variant="text" onClick={resetForm}>
            request a new code
          </Button>
          .
        </Alert>
      )}
    </Form>
  );
}

export { CreateAccountForm };
