import * as React from "react";
import styled from "@emotion/styled";
import { Button, LoadingMessage, ScreenReaderText, Stack } from "atoms";
import { Image } from "@shopify/hydrogen-react";
import { preventForwarding } from "styles/styleUtils";
import { useProductQuery } from "../api/useProductQuery";
import { ProductImage } from "./ProductImage";
import { isMediaImage } from "../utils/isMediaImage";

type ProductGalleryProps = Pick<
  NonNullable<ReturnType<typeof useProductQuery>["data"]>,
  "media" | "selectedVariant"
>;

function ProductGallery({ media, selectedVariant }: ProductGalleryProps) {
  const images = React.useMemo(
    () => media.filter(isMediaImage).map((m) => ({ ...m.image, id: m.id })),
    [media],
  );
  const hasThumbnails = media.length > 1;

  // Track the selected image's url, by default use the selected variant's image url if we have one
  const [selectedUrl, setSelectedUrl] = React.useState<
    string | undefined | null
  >(selectedVariant?.image?.url ?? images[0]?.url);

  const selectedImage = React.useMemo(
    () =>
      !selectedUrl ? images[0] : images.find((img) => img.url === selectedUrl),
    [images, selectedUrl],
  );

  // If the selected variant changes, update the selected image
  React.useEffect(() => {
    if (selectedVariant?.image?.url) {
      setSelectedUrl(selectedVariant.image.url);
    }
  }, [selectedVariant?.image?.url, setSelectedUrl]);

  if (!media.length) {
    return null;
  }

  return (
    <Stack gap="10px">
      <FeaturedImageWrapper hasThumbnails={hasThumbnails}>
        {selectedImage?.url ? (
          <ProductGalleryImage
            data={selectedImage}
            sizes="
        (maxWidth: 719px) 100vw,
        (max-width: 831px) 50vw,
        (max-width: 1439px) 60vw,
        800px"
          />
        ) : (
          <ProductGalleryImageLoading />
        )}
      </FeaturedImageWrapper>

      {hasThumbnails && (
        <Thumbnails>
          {images.map((image) => (
            <ThumbnailWrapper
              key={image.id}
              variant="text"
              onClick={() => setSelectedUrl(image.url)}
              isSelected={image.url === selectedUrl}
            >
              {image.url ? (
                <ProductGalleryThumbnail
                  data={image}
                  sizes="(max-width: 830px) 60px, 50vw"
                />
              ) : (
                <ProductGalleryThumbnailLoading />
              )}
            </ThumbnailWrapper>
          ))}
        </Thumbnails>
      )}
    </Stack>
  );
}

const FeaturedImageWrapper = styled.div<{ hasThumbnails: boolean }>(
  ({ hasThumbnails, theme }) => ({
    position: "relative",
    aspectRatio: hasThumbnails ? 1 : "auto",
    // display: "grid",
    // placeItems: "center",
    borderRadius: theme.borderRadius.sm,
    boxShadow: `inset 0 0 0 1px ${theme.colors.fg10}`,
    overflow: "hidden",
    // width: "100%",
    // height: "100%",
    // objectFit: "contain",
  }),
);

const ProductGalleryImage = styled(Image)({
  display: "block",
  margin: 0,
  width: "100%",
  height: "100%",
  objectFit: "contain",
});

function ProductGalleryImageLoading() {
  return (
    <LoadingImage>
      <SLoadingMessage>Generating Mockup</SLoadingMessage>
    </LoadingImage>
  );
}

const LoadingImage = styled.div({
  aspectRatio: 1,
  border: 0,
  width: "100%",
  height: "100%",
});
const SLoadingMessage = styled(LoadingMessage)({
  height: "100%",
  opacity: 0.5,
  padding: 1,
  flex: 1,
  display: "grid",
  placeItems: "stretch",
  ".LoadingMessagePause": {
    display: "none",
  },
});

const Thumbnails = styled.div({
  display: "flex",
  flexWrap: "wrap",
  gap: 10,
});

type ThumbnailWrapperProps = { isSelected: boolean };

const ThumbnailWrapper = styled(Button, {
  shouldForwardProp: preventForwarding<ThumbnailWrapperProps>("isSelected"),
})<ThumbnailWrapperProps>(({ isSelected, theme }) => ({
  aspectRatio: 1,
  border: "1px solid",
  borderColor: theme.colors.fg10,
  borderRadius: 2,
  cursor: "pointer",
  flex: "0 0 66px",
  height: 66,
  "&:hover": {
    borderColor: theme.colors.bg,
    outline: "2px solid",
    outlineColor: theme.colors.fg60,
  },
  ...(isSelected && {
    img: { opacity: 0.7 },
    "&, &:hover": {
      borderColor: theme.colors.bg,
      outline: "2px solid",
      outlineColor: theme.colors.fg,
    },
    "&:hover img": { opacity: 1 },
  }),
}));

const ProductGalleryThumbnail = styled(ProductImage)({
  aspectRatio: 1,
  border: 0,
  width: "100%",
  height: "100%",
  objectFit: "cover",
});

function ProductGalleryThumbnailLoading() {
  return (
    <LoadingThumbnail>
      <SLoadingMessage>
        <ScreenReaderText>Generating Thumbnail</ScreenReaderText>
      </SLoadingMessage>
    </LoadingThumbnail>
  );
}

const LoadingThumbnail = styled.div(({ theme }) => ({
  ...theme.fonts.display,
  display: "grid",
  placeItems: "center",
  aspectRatio: 1,
  border: 0,
  width: "100%",
  height: "100%",
  "&,*": {
    textDecoration: "none",
    padding: 0,
    boxShadow: "none",
  },
  ".LoadingMessageDots": {
    fontSize: 5,
    margin: 0,
  },
}));

export { ProductGallery };
