import { setupWorker } from "msw";
import { handlers } from "./handlers";

/**
 * MockServiceWorker setup for Browser
 *
 * See integration based on environment:
 * https://mswjs.io/docs/getting-started/integrate/browser
 */
const worker = setupWorker(...handlers);

export { worker };
