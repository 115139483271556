import * as React from "react";
import styled from "@emotion/styled";
import { FlexRow } from "./FlexRow";
import { Img } from "./Img";
import { Link } from "./Link";
import { Stack } from "./Stack";
import { Box } from "./Box";
import { H3 } from "./Typography";

type CtaBoxProps = {
  title: string | React.ReactNode;
  icon?: React.ReactNode;
  text: string | React.ReactNode;
  button?: React.ReactNode;
  linkText?: string | React.ReactNode;
  linkUrl?: string;
  imageSrc?: string;
  linkTarget?: string;
};

function CtaBox({
  title,
  button,
  icon,
  text,
  linkText,
  linkUrl,
  linkTarget,
  imageSrc,
  ...props
}: CtaBoxProps) {
  const hasImage = !!imageSrc;

  return (
    <CtaBoxWrapper hasImage={hasImage} {...props}>
      <CtaBoxMain hasImage={hasImage} gap="xs">
        {icon && <CtaBoxIcon>{icon}</CtaBoxIcon>}

        <SFlexRow gap="1rem">
          <SStack gap="xs">
            <CtaBoxTitle>{title}</CtaBoxTitle>
            <CtaBoxText>{text}</CtaBoxText>
          </SStack>

          {button ? (
            <FlexRow gap="10px">{button}</FlexRow>
          ) : (
            <>
              {linkUrl && (
                <Link
                  variant="primary"
                  size="xs"
                  href={linkUrl}
                  target={linkTarget}
                >
                  {linkText}
                </Link>
              )}
            </>
          )}
        </SFlexRow>
      </CtaBoxMain>

      {imageSrc && (
        <CtaBoxImage>
          <Img src={imageSrc} />
        </CtaBoxImage>
      )}
    </CtaBoxWrapper>
  );
}

const CtaBoxWrapper = styled(Box)<{ hasImage: boolean }>(
  ({ hasImage, theme }) => ({
    padding: 0,
    borderRadius: theme.borderRadius.md,
    overflow: "hidden",
    position: "relative",
    ...(hasImage && {
      display: "flex",
      flexWrap: "wrap",
      gap: 0,
      "&>*": {
        flex: "1 1 250px",
      },
    }),
  }),
);

const CtaBoxMain = styled(Stack)<{ hasImage: boolean }>(
  ({ hasImage, theme }) => ({
    padding: theme.spacing.gap,
    flex: "1 1 250px",
    a: {
      alignSelf: "flex-end",
    },
    [theme.breakpoints.tablet]: {
      margin: "0 0 0 .5rem",
      ...(!hasImage && {
        alignItems: "center",
        display: "flex",
        flexWrap: "wrap",
        gap: "0 1rem",
        marginRight: "0.5rem",
      }),
    },
  }),
);
const SFlexRow = styled(FlexRow)({ flex: "1 1 300px" });

const SStack = styled(Stack)({ flex: "1 1 500px" });

const CtaBoxIcon = styled.div(({ theme }) => ({
  borderRadius: theme.borderRadius.round,
  color: theme.colors.fg,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  width: 30,
  height: 40,
  flex: "0 0 30",
  alignSelf: "flex-start",
  marginLeft: "auto",
  fontSize: 30,
  svg: {
    marginBottom: 10,
  },
}));

const CtaBoxTitle = styled(H3)(({ theme }) => ({
  ...theme.fonts.display,
  color: theme.colors.fg,
  fontSize: theme.fontSizes.sm,
  lineHeight: 1.3,
  marginBottom: 0,
})).withComponent("h3");

const CtaBoxText = styled.div(({ theme }) => ({
  p: {
    color: theme.colors.bodyColor,
  },
  "p:last-of-type": {
    marginBottom: 5,
  },
  [theme.breakpoints.tablet]: {
    fontSize: theme.fontSizes.xs,
  },
}));

const CtaBoxImage = styled.figure(({ theme }) => ({
  backgroundColor: theme.colors.fg10,
  borderRadius: `0 0 ${theme.borderRadius.sm} ${theme.borderRadius.sm}`,
  flex: "1 1 180px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  img: {
    display: "block",
    objectFit: "contain",
    objectPosition: "center",
    width: "90%",
    height: "80%",
    maxWidth: 300,
    maxHeight: 300,
  },
}));

export { CtaBox, CtaBoxIcon };
