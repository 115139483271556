import * as React from "react";
import styled from "@emotion/styled";
import defaultSRC from "assets/images/default-img.svg";
import type { Image } from "../types";
import { useImgState } from "../utils/useImgState";
import { Img } from "./Img";
import { NSFWOverlay } from "./NSFWOverlay";

// NOTE: Optimization techniques taken from:
// - https://css-tricks.com/a-guide-to-the-responsive-images-syntax-in-html/#using-srcset
// - https://www.11ty.dev/docs/plugins/image/
// - https://jakearchibald.com/2021/serving-sharp-images-to-high-density-screens/

type GenericImageProps = Omit<React.ComponentProps<typeof Img>, "src"> & {
  // TODO: add fixed aspect-ratio back in to prevent layout shift? require height/width?
  aspectRatio?: React.CSSProperties["aspectRatio"];
  src?: string | Image;
};

const createImgAttributes = (src?: string | Image) => {
  if (!src) {
    return { src: defaultSRC };
  }
  if (typeof src === "string") {
    return { src };
  }

  let srcSet: string | undefined;
  if (src.url150 || src.url300 || src.url600 || src.url1200) {
    srcSet = [
      src.url150 ? `${src.url150} 150w` : null,
      src.url300 ? `${src.url300} 300w` : null,
      src.url600 ? `${src.url600} 600w` : null,
      src.url1200 ? `${src.url1200} 1200w` : null,
      src.url && src.width ? `${src.url} ${src.width}w` : null,
    ]
      .filter(Boolean)
      .join(", ");
  }

  return {
    src: src.url1200 ?? src.url,
    srcSet,
    sizes: srcSet ? "150px" : undefined,
  };
};

/**
 * ReviewableTemplate featured image component
 */
function GenericImage({
  aspectRatio,
  src,
  className,
  ...props
}: GenericImageProps) {
  const { blur, isError, isNSFW, onError, onLoad } = useImgState({
    src,
    ...props,
  });

  // Build <img> attributes depending on `src` type
  const attrs = createImgAttributes(src);

  return (
    <ImgBackground
      aspectRatio={aspectRatio}
      className={className}
      style={{ "--bg-img": blur }}
    >
      {isNSFW && <NSFWOverlay />}

      <Img
        {...attrs}
        src={isError ? defaultSRC : attrs.src}
        {...props}
        aspectRatio={aspectRatio}
        onError={onError}
        onLoad={onLoad}
      />
    </ImgBackground>
  );
}

/**
 * Wrapper div with background blur
 *
 * Extends the above <Img> component to match size, aspect ratio, and border radius but as a simple
 * div. If Image has blur string, use it to show a blurry psuedo element before full img src loads.
 */
const ImgBackground = styled(Img)({
  overflow: "hidden",
  "&:before": {
    backgroundImage: "var(--bg-img)",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    backgroundSize: "cover",
    overflow: "hidden",
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
}).withComponent("div");

export { GenericImage, ImgBackground };
