/**
 * Language File: English (US)
 *
 * This file contains a single exported object containing all internationalized messages for the
 * front-end application. Each message is either a string or a function returning a string and is
 * keyed by a unique identifier in the shape of `ComponentName.messageName`, with re-usable
 * messages using `common` as the component name.
 */
const en = {
  /**
   * Common text strings used accross many components
   */
  "common.title": "HUG Platform",
  "common.unknownErrorTitle": "Sorry, something has gone wrong.",
  "common.unknownErrorMessage": "Please reload the app and try again.",
  /**
   * Onboarding Page
   */
  "mint.title": "Ready to Mint?",
  "onboardingMetaMask.installStepMessage":
    "It doesn't look like you have a wallet provider installed. We recommend the MetaMask extension.",
  "onboardingMetaMask.installStepAction": "Install MetaMask",
  "onboardingMetaMask.connectStepMessage":
    "It's almost time! While you wait, you can make sure your wallet is on the mint list. 🤗",
  "onboardingMetaMask.connectStepAction": "Connect to MetaMask",
  "onboardingMetaMask.connectedStepMessage": "Congrats! You're all set.",

  "onboardingCoinbase.connectStepAction": "Connect to Coinbase",
  "onboardingCoinbase.connectedStepMessage": "Congrats! You're all set.",

  "onboardingWalletConnect.connectStepAction": "Use Wallet Connect",
  "onboardingWalletConnect.connectedStepMessage": "Congrats! You're all set.",
};

export { en };
