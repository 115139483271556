import { WagmiConfig, createConfig, configureChains, mainnet } from "wagmi";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { CoinbaseWalletConnector } from "wagmi/connectors/coinbaseWallet";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { WalletConnectConnector } from "wagmi/connectors/walletConnect";
import { OkxWalletConnector } from "features/auth/utils/okxWallet";
import { constants } from "config";

const { chains, publicClient, webSocketPublicClient } = configureChains(
  [mainnet],
  [alchemyProvider({ apiKey: constants.alchemyApiKey }), publicProvider()],
);

const config = createConfig({
  autoConnect: true,
  connectors: [
    new MetaMaskConnector({ chains }),
    new OkxWalletConnector({ chains }),
    new CoinbaseWalletConnector({
      chains,
      options: {
        appName: "HUG.xyz",
      },
    }),
    new WalletConnectConnector({
      chains,
      options: {
        projectId: constants.walletConnectProjectId,
      },
    }),
  ],
  publicClient,
  webSocketPublicClient,
});

function WalletProvider({ children }: { children: React.ReactNode }) {
  return <WagmiConfig config={config}>{children}</WagmiConfig>;
}

export { WalletProvider };
