import * as React from "react";
import { constants, featureFlags } from "config";
import { api, ApiError } from "utils/api";
import { useAsync } from "utils/hooks";
import { getCookie } from "utils/storage";

/**
 * Custom hook for sign in request and response state
 */
const useStagingSignIn = () => {
  const [showStagingSignIn, setShowStagingSignIn] = React.useState(
    // Staging Preview feature needs to be enabled in config before we even check for existence of cookie
    featureFlags.enableStagingAuth && !getCookie(constants.stagingPreviewKey),
  );

  const { run, isError, isLoading, isSuccess, error } = useAsync<
    { status: string },
    ApiError
  >();

  const stagingSignIn = React.useCallback(
    (password: string) => {
      run(
        api.post<{ status: string }>("/beta", {
          body: JSON.stringify({ password }),
        }),
      );
    },
    [run],
  );

  React.useEffect(() => {
    if (isSuccess) {
      document.cookie = `${constants.stagingPreviewKey}=true;samesite=strict;max-age=${constants.stagingPreviewAge}`;
      setShowStagingSignIn(false);
    }
  }, [isSuccess]);

  return {
    showStagingSignIn,
    stagingSignIn,
    isError,
    isLoading,
    error,
  };
};

export { useStagingSignIn };
