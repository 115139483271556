import * as React from "react";
import styled from "@emotion/styled";
import { useToggle } from "utils/hooks";
import {
  Box,
  Button,
  FlexRow,
  Grid,
  H2,
  IconAddGallery,
  IconOrder,
  ScrollRow,
  Stack,
  Text,
} from "atoms";
import { pluralize } from "utils/text";
import { useGallery } from "../api/useGallery";
import { GalleryCard } from "./GalleryCard";
import type { DeleteItemFunction, GalleryItem } from "../types";
import { sortBySequence } from "../utils/sortBySequence";

type GalleryListProps = {
  artistId: string;
  isEditing?: boolean;
  addGallery?: VoidFunction;
  orderGalleries?: VoidFunction;
  deleteItem?: DeleteItemFunction;
};

const COLLAPSED_LENGTH = 4;

function GalleryList({
  isEditing = false,
  artistId,
  addGallery = noop,
  deleteItem = noop,
  orderGalleries = noop,
}: GalleryListProps) {
  const { data: folders } = useGallery({
    artistIds: [artistId],
    perPage: isEditing ? undefined : 40,
    itemType: "folder",
  });

  // When in editing mode, start with collapsed view of galleries
  const [isExpanded, toggleExpanded] = useToggle(false);

  const items = React.useMemo(() => {
    if (!folders || !folders.pages) {
      return [];
    }

    // Show all items while editing
    // TODO: remove UI sort and add to search request to handle pagination
    const allItems = folders.pages
      .flatMap((page) => page.data)
      .sort(sortBySequence);
    if (isEditing) {
      return allItems;
    }

    // Otherwise only show published and non-empty galleries
    return allItems.filter(
      ({ status, folderItemCount }) =>
        status === "PUBLISHED" && !!folderItemCount,
    );
  }, [folders, isEditing]);

  const numItems = items?.length ?? 0;
  const visibleItems =
    !isEditing || isExpanded ? items : items.slice(0, COLLAPSED_LENGTH);

  // Determine if we need to render the expand toggle button
  const showExpandToggle =
    isEditing && !isExpanded && numItems > COLLAPSED_LENGTH;

  // Show items in scrolling row on portfolio, but show full grid while editing
  const GridComponent = isEditing ? SGrid : ScrollRow;

  const createDeleteHandler =
    ({ id, itemType }: GalleryItem) =>
    () =>
      deleteItem({ itemId: id, itemType });

  return !isEditing && !visibleItems.length ? null : (
    <Stack gap="sm">
      <FlexRow alignItems="baseline" gap="10px">
        <Stack gap="0">
          <H2 size="h3" style={{ margin: 0 }}>
            Galleries
          </H2>
          {visibleItems.length > 0 && (
            <Text as="small" size="xxs">
              {pluralize(numItems, "gallery", "galleries")}
            </Text>
          )}
        </Stack>

        {isEditing && (
          <FlexRow gap="10px" style={{ transform: "translateY(-4px)" }}>
            <Button
              size="xs"
              variant="secondary"
              onClick={addGallery}
              aria-label="Add New Gallery"
            >
              <span>Add</span>
              <IconAddGallery />
            </Button>
            {numItems > 0 && (
              <Button
                size="xs"
                variant="secondary"
                onClick={orderGalleries}
                aria-label="Order Galleries"
              >
                Reorder
                <IconOrder />
              </Button>
            )}
          </FlexRow>
        )}
      </FlexRow>

      {isEditing && visibleItems.length === 0 && (
        <Text as="small" size="xxs">
          <strong>You do not have any Galleries.</strong> Use Galleries to
          organize the items in your Portfolio by collection, medium, project,
          and more.
        </Text>
      )}

      <Stack gap="sm">
        {isEditing && visibleItems.length === 0 && (
          <AddGalleryButton as="button" onClick={addGallery}>
            <H2 size="md" as="span">
              <FlexRow
                alignItems="center"
                justifyContent="center"
                flexDirection="column"
                gap="10px"
              >
                <IconAddGallery />
                Add Gallery
              </FlexRow>
            </H2>
          </AddGalleryButton>
        )}
        <GridComponent isExpanded={isExpanded}>
          {visibleItems.map((item) => (
            <GalleryCard
              key={item.id}
              isEditing={isEditing}
              deleteGallery={createDeleteHandler(item)}
              {...item}
            />
          ))}
        </GridComponent>

        {showExpandToggle && (
          <FlexRow justifyContent="center">
            <Button variant="secondary" size="xs" onClick={toggleExpanded}>
              Load All Galleries
            </Button>
          </FlexRow>
        )}
      </Stack>
    </Stack>
  );
}

const SGrid = styled(Grid)<{ isExpanded: boolean }>(({ isExpanded }) => ({
  gap: "var(--gallery-gap)",
  "@media (min-width: 320px)": {
    gridTemplateColumns: `repeat(2, 1fr)`,
  },
  "@media (min-width: 740px)": {
    gridTemplateColumns: `repeat(3, 1fr)`,
  },
  "@media (min-width: 1040px)": {
    gridTemplateColumns: `repeat(4, 1fr)`,
  },
  ...(!isExpanded && {
    "&>li": {
      "@media (max-width: 739px)": {
        ":nth-of-type(1n + 3)": { display: "none" },
      },
      "@media (max-width: 1039px)": {
        ":nth-of-type(1n + 4)": { display: "none" },
      },
    },
  }),
}));

const AddGalleryButton = styled(Box)(({ theme }) => ({
  minHeight: 100,
  height: "calc(100px + 2vh + 2vw)",
  maxHeight: 150,
  transition: ".2s ease box-shadow",
  cursor: "pointer",
  width: "100%",
  "&:hover, &:focus-visible": {
    boxShadow: theme.boxShadow.dark,
  },
  [theme.breakpoints.xs]: {
    width: "calc(50% - (var(--gallery-gap) / 2))",
  },
  [theme.breakpoints.desktop]: {
    width: "calc((100% / 3) - (var(--gallery-gap) * .666))",
  },
}));

export { GalleryList };
