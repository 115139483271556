import * as React from "react";
import styled from "@emotion/styled";
import { Money } from "@shopify/hydrogen-react";
import type {
  CartLineInput,
  SelectedOption,
} from "@shopify/hydrogen-react/storefront-api-types";
import { Navigate, useSearchParams } from "react-router-dom";
import {
  Alert,
  BlockTag,
  Box,
  ExpandContent,
  FakeLabel,
  FlexRow,
  H1,
  H2,
  IconArrow,
  Link,
  LoadingSpinner,
  NamedAvatar,
  ProgressBar,
  QuantityInput,
  RichText,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import { paths } from "utils/paths";
import { useAuth } from "features/auth";
import { Head } from "features/misc";
import { useArtist } from "features/reviewable";
import { useProductQuery } from "../api/useProductQuery";
import { AddToCart } from "./AddToCart";
import { FeaturedProducts } from "./FeaturedProducts";
import { ProductOptions } from "./ProductOptions";
import { ProductDetailAccordion } from "./ProductDetailAccordion";
import { ProductGallery } from "./ProductGallery";
import { ProductUpsells } from "./ProductUpsells";
import { SaleMoney, StrikeMoney } from "./ProductCard";
import { ProductTag } from "./ProductTag";
import { useCollectionQuery } from "../api/useCollectionQuery";
import {
  useProductAdminQuery,
  convertProductAdminToStoreFront,
} from "../api/useProductAdminQuery";

/**
 * Pass the handle field to use the Store Front API
 * Pass the productId field to use the Proxied Admin API
 */
type ProductDetailsProps = {
  artistId: string;
  handle?: string;
  productId?: string;

  // When true, displays a non-functioning preview of the product
  isPreview?: boolean;
};

function ProductDetails({ handle, productId, ...props }: ProductDetailsProps) {
  const {
    roles: { showAdminFeatures },
    user,
  } = useAuth();
  const [searchParams] = useSearchParams();
  const selectedOptions = React.useMemo(() => {
    const opts: SelectedOption[] = [];
    searchParams.forEach((value, name) => {
      opts.push({ name, value });
    });
    return opts;
  }, [searchParams]);

  const {
    data: storeFrontData,
    error: storeFrontError,
    isError: storeFrontIsError,
  } = useProductQuery(handle, selectedOptions);

  const {
    data: adminData,
    error: adminError,
    isError: adminIsError,
  } = useProductAdminQuery(productId);

  const error = storeFrontError ?? adminError;
  const isError = storeFrontIsError ?? adminIsError;
  const data =
    storeFrontData ??
    convertProductAdminToStoreFront(selectedOptions, adminData);

  // In case we're previewing the product in the builder, make sure user has access
  const userCanManageProduct =
    showAdminFeatures || user?.claimedArtistPageIds?.includes(props.artistId);
  if (data && data.status !== "ACTIVE" && !userCanManageProduct) {
    return <Navigate to={paths.artistShop(props.artistId)} replace />;
  }

  if (data) {
    return <ConnectedProductDetails {...props} product={data} />;
  }

  if (isError) {
    return <UnexpectedError error={error} />;
  }

  return <LoadingSpinner />;
}

type ConnectedProductDetailsProps = {
  product: NonNullable<ReturnType<typeof useProductQuery>["data"]>;

  // When true, displays a non-functioning preview of the product
  isPreview?: boolean;
};

function ConnectedProductDetails({
  product,
  isPreview,
  ...props
}: ConnectedProductDetailsProps) {
  const {
    artistId,
    descriptionHtml,
    details,
    featuredImage,
    hasVariants,
    id,
    maximumQuantity,
    media,
    options,
    status,
    selectedVariant,
    title,
    upsells,
    templateId,
  } = product;

  const {
    roles: { showAdminFeatures },
  } = useAuth();

  const { data: dataCollection } = useCollectionQuery({
    handle: artistId ?? "",
    numProducts: 5,
  });
  const productCount = dataCollection?.products.length;

  const { data: artist } = useArtist(artistId);

  const [quantity, setQuantity] = React.useState(1);

  const [selectedUpsells, setSelectedUpsells] = React.useState<CartLineInput[]>(
    [],
  );

  // NOTE: line item attributes prefixed with underscore are hidden on Checkout page. Otherwise
  // they'll be listed below the selected options like: `- key: value`
  const lineItemAttrs = React.useMemo(
    () =>
      artist
        ? [
            { key: "_artistId", value: artist.id },
            { key: "_artistName", value: artist.name },
          ]
        : [],
    [artist],
  );

  const maximumQuantityNumber = Number(maximumQuantity);
  const availableForSale = !!selectedVariant?.availableForSale;

  // For products with untracked inventory, they will be available for sale but show 0 quantity
  // available. In that case, allow the max safe integer. Otherwise use the quantity available.

  // Assume unlimited quantity if it's for sale
  let quantityInputMax = availableForSale ? Number.MAX_SAFE_INTEGER : 0;

  // Reduce to actual quantity available if quantityAvailable is positive.
  // Note that untracked inventory starts at zero and can become negative
  if (
    availableForSale &&
    typeof selectedVariant?.quantityAvailable === "number" &&
    selectedVariant.quantityAvailable > 0
  ) {
    quantityInputMax = selectedVariant.quantityAvailable;
  }

  let editionType = "";
  if (product.productType === "onchain collectible") {
    editionType = "Limited Edition";
  }
  if (maximumQuantity === "0") {
    editionType = "Open Edition";
  }
  if (maximumQuantity === "1") {
    editionType = "1/1";
  }

  const quantitySold =
    maximumQuantityNumber -
    (selectedVariant?.quantityAvailable ?? maximumQuantityNumber);

  // const isAvailabilityLow =
  //   !!selectedVariant?.quantityAvailable &&
  //   selectedVariant.quantityAvailable <= Math.ceil(maximumQuantityNumber / 3);

  // Don't show the quantity controls for digital downloads and on chain assets
  // as only one can be purchased at a time
  const forceQuantityOne = templateId === "HUG-DIGITAL-DOWNLOADS";

  // For Product Details Page (and not Builder Preview), non-admins are redirected to the shop tab
  // for unlisted projects. We'll make an exception for admins, but show a warning and status.
  const showStatusForAdmin =
    showAdminFeatures && status !== "ACTIVE" && !isPreview;

  // Show the "More from Artist" only if there are 2 or more published products
  const showMoreProductsList =
    !!artist && !isPreview && productCount && productCount >= 2;

  // Show "Only `n` left!" when inventory is less than 10
  const showLowInventoryMessage =
    product &&
    product.productType === "artist ships" &&
    product.variants[0].quantityAvailable &&
    product.variants[0].quantityAvailable < 10;

  return (
    <Stack gap="lg" {...props}>
      <Head
        title={`${product.title} | Shop ${artist?.name} on HUG`}
        description={`Shop ${product.title} alongside other physical & digital art from ${artist?.name} on HUG.`}
        socialTitle={`${product.title} | Shop ${artist?.name} on HUG`}
        socialDescription={`Shop ${product.title} alongside other artworks, digital downloads, and more from ${artist?.name} on HUG.`}
        socialImageAltText={product.title}
        socialImage={
          featuredImage?.url ?? artist?.cardImage?.url ?? artist?.heroImage.url
        }
      />

      {showStatusForAdmin && (
        <Alert type="info">
          You are viewing an unlisted product as an <strong>admin</strong>.
          Product Status: <span>{status}</span>
        </Alert>
      )}

      <Stack>
        {artistId && !isPreview && (
          <Link size="xs" variant="blank" to={paths.artistShop(artistId)}>
            <IconArrow dir="l" /> Back
          </Link>
        )}

        <ProductWrapper>
          <MediaColumn>
            <ProductGallery media={media} selectedVariant={selectedVariant} />
          </MediaColumn>
          <ContentColumn gap="md">
            <DetailsBox fullWidth>
              <Stack gap="sm">
                <Stack gap="xs">
                  <H1 size="h3" textTransform="none">
                    {title}
                  </H1>
                  {(product.productType || !!editionType) && (
                    <FlexRow gap="5px">
                      {product.productType && (
                        <ProductTag type={product.productType} size="xs" />
                      )}
                      {!!editionType && (
                        <BlockTag size="xs" bg="accent2LL" color="fg80">
                          {editionType}
                        </BlockTag>
                      )}
                    </FlexRow>
                  )}
                  {artist && (
                    <div>
                      <NamedAvatar
                        name={artist.name}
                        src={artist.heroImage}
                        link={
                          isPreview ? undefined : paths.artistProfile(artist.id)
                        }
                      />
                    </div>
                  )}
                </Stack>

                {descriptionHtml && (
                  <ExpandContent showLabel="Read more" hideLabel="Show less">
                    <RichText value={descriptionHtml} />
                  </ExpandContent>
                )}

                <Stack gap="1.33rem">
                  {hasVariants && (
                    <ProductOptions
                      options={options}
                      selectedOptions={selectedVariant?.selectedOptions}
                    />
                  )}

                  {editionType === "Limited Edition" && (
                    <Stack gap="0">
                      <ProgressText size="xxs">
                        <span>
                          {quantitySold} of {maximumQuantity} sold
                        </span>
                      </ProgressText>
                      <QuantityProgressBar
                        value={quantitySold}
                        max={maximumQuantityNumber}
                      >
                        <span />
                      </QuantityProgressBar>
                    </Stack>
                  )}

                  <FlexRow
                    justifyContent="space-between"
                    alignItems="flex-start"
                    flexWrap="nowrap"
                    itemsFlex="0 1 auto"
                  >
                    {availableForSale && !forceQuantityOne && (
                      <FakeLabel>
                        <Stack gap="xxs">
                          <div>Quantity</div>

                          <FlexRow gap=".5em">
                            <QuantityInput
                              defaultValue={1}
                              onChange={setQuantity}
                              min={1}
                              max={quantityInputMax}
                              size="sm"
                            />

                            {showLowInventoryMessage && (
                              <LowInventory>
                                Only {product.variants[0].quantityAvailable}{" "}
                                left!
                              </LowInventory>
                            )}
                          </FlexRow>
                        </Stack>
                      </FakeLabel>
                    )}

                    <PriceWrapper>
                      <Stack gap="xs">
                        <FakeLabel>Price</FakeLabel>
                        {selectedVariant && (
                          <>
                            {selectedVariant.compareAtPrice ? (
                              <FlexRow gap="6px">
                                <SaleMoney
                                  data={selectedVariant.price}
                                  productIsOnSale
                                />
                                <StrikeMoney
                                  data={selectedVariant.compareAtPrice}
                                />
                              </FlexRow>
                            ) : (
                              <Money data={selectedVariant.price} />
                            )}
                          </>
                        )}
                      </Stack>
                    </PriceWrapper>
                  </FlexRow>
                  <ProductUpsells
                    upsells={upsells}
                    setSelectedUpsells={setSelectedUpsells}
                  />
                  <FlexRow itemsFlex="0 0 100%">
                    <AddToCart
                      isPreview={isPreview}
                      attributes={lineItemAttrs}
                      disabled={isPreview}
                      quantity={quantity}
                      productVariant={selectedVariant}
                      productType={product.productType}
                      templateId={product.templateId}
                      editionType={editionType}
                      upsells={selectedUpsells}
                    />
                  </FlexRow>
                </Stack>
              </Stack>
            </DetailsBox>

            <ProductDetailAccordion details={details} />
          </ContentColumn>
        </ProductWrapper>
      </Stack>

      {showMoreProductsList && !isPreview && (
        <Stack gap="sm">
          <H2 size="md" style={{ margin: 0 }}>
            More from <span translate="no">{artist.name}</span>
          </H2>

          <FeaturedProducts artistId={artist.id} excluding={id} />
        </Stack>
      )}
    </Stack>
  );
}

const bpProduct = "@media (min-width: 720px)";
const ProductWrapper = styled.div(({ theme }) => ({
  display: "flex",
  gap: theme.spacing.gap,
  alignItems: "stretch",
  flexDirection: "column",
  position: "relative",
  [bpProduct]: {
    flexDirection: "row",
  },
  [theme.breakpoints.desktopMedium]: {
    gap: theme.spacing.gutter,
  },
}));

const MediaColumn = styled.div({
  flex: "1 0 50%",
});

const ContentColumn = styled(Stack)(({ theme }) => ({
  flex: "1 0 300px",
  [bpProduct]: {
    position: "sticky",
    top: theme.spacing.gutter,
  },
}));

const DetailsBox = styled(Box)(({ theme }) => ({
  [bpProduct]: {
    padding: theme.spacing.md,
  },
  [theme.breakpoints.desktop]: {
    padding: theme.spacing.lg,
  },
}));

const PriceWrapper = styled.div(({ theme }) => ({
  color: theme.colors.fg,
  flex: "0 0 auto",
  fontSize: theme.fontSizes.md,
  // matches height of the quantity input
  lineHeight: "30px",
}));

const QuantityProgressBar = styled(ProgressBar)(({ theme }) => ({
  "&, &>span": {
    height: 6,
  },
  "&>span, &>span::after": {
    backgroundColor: theme.colors.fg,
  },
}));
const ProgressText = styled(Text)(({ theme }) => ({
  color: theme.colors.fg,
}));

const LowInventory = styled(Text)(({ theme }) => ({
  color: theme.colors.accent4,
  fontSize: 14,
  margin: 0,
}));

export { ProductDetails };
