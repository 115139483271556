import styled from "@emotion/styled";
import { Stack } from "./Stack";

const HeadingGroup = styled(Stack)(() => ({
  small: {
    fontSize: 14,
    textTransform: "uppercase",
    lineHeight: 1.1,
  },
  "h2,h3,h4,h5,h6": {
    margin: ".1em 0 0 0",
    lineHeight: 1.1,
  },
})).withComponent("hgroup");

HeadingGroup.defaultProps = {
  gap: "0",
};
export { HeadingGroup };
