import { loadStripe } from "@stripe/stripe-js";
import { constants } from "config";

const stripe = loadStripe(constants.stripePublicKey);

/**
 * Key for Stripe's session id in URLSearchParams
 */
const CHECKOUT_SESSION_ID_KEY = "sessionId";
/**
 * Full key/value pair for Stripe's session id in URLSearchParams
 *
 * NOTE: the string `{CHECKOUT_SESSION_ID}` is intentionally NOT a variable within the template
 * string. It will be replaced by Stripe's session creator on the API. After checkout, they'll
 * redirect the user to our specified returnUrl and we can use the above key to get the actual value
 * and fetch the status of their checkout session.
 */
const CHECKOUT_SESSION_ID_PARAM = `${CHECKOUT_SESSION_ID_KEY}={CHECKOUT_SESSION_ID}`;

export { stripe, CHECKOUT_SESSION_ID_KEY, CHECKOUT_SESSION_ID_PARAM };
