import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { applicationKeys } from "./queryKeys";
import type { ArtistApplication } from "../types";

type Workflows = "ACCEPTED" | "REMINDER1" | "REMINDER2";

type WorkflowParams = {
  applicationId: string;
  workflow: Workflows;
};

const triggerArtistApplicationWorkflow = ({
  applicationId,
  workflow,
}: WorkflowParams) =>
  api.post<ArtistApplication>(
    `/artists/applications/${applicationId}/workflow`,
    {
      body: JSON.stringify({ workflow }),
    },
  );

const useTriggerArtistApplicationWorkflow = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation(triggerArtistApplicationWorkflow, {
    onSuccess: () => {
      queryClient.invalidateQueries(applicationKeys.base);
    },
  });
  const triggerWorkflow = (applicationId: string, workflow: Workflows) =>
    mutation.mutate({ applicationId, workflow });
  return { triggerWorkflow, ...mutation };
};

export { useTriggerArtistApplicationWorkflow };
