import * as React from "react";
import styled from "@emotion/styled";
import type { Image } from "../types";

type VideoProps = Omit<React.VideoHTMLAttributes<HTMLVideoElement>, "src"> & {
  src: string | Image;
};

const Video = React.forwardRef<HTMLVideoElement, VideoProps>(
  ({ src, ...props }, forwardedRef) => {
    const srcProps =
      typeof src === "string"
        ? {
            src,
          }
        : {
            src: src.videoUrl,
            poster: src.url600 ?? src.url,
          };

    return (
      <StyledVideo
        playsInline
        loop
        controls
        {...props}
        {...srcProps}
        ref={forwardedRef}
      />
    );
  },
);

const StyledVideo = styled.video({
  width: "100%",
  maxHeight: "95vh",
});

export { Video };
