import { useQuery } from "react-query";
import { api } from "utils/api";
import { OwnedContractListResponse } from "../types";
import { ownedContractKeys } from "./queryKeys";

type GetParams = {
  ownerId?: string;
};

/**
 * Get a owned list of owned contracts for a user id
 */
const getOwnedContracts = ({ ownerId }: GetParams) =>
  !ownerId
    ? Promise.reject(new Error("Invalid owner id"))
    : api.get<OwnedContractListResponse>(`/wallet/contracts/${ownerId}`);

/**
 * Custom hook to get a portfolio item for an artist
 */
const useOwnedContracts = ({ ownerId }: GetParams) =>
  useQuery(
    ownedContractKeys.detail(ownerId),
    () => getOwnedContracts({ ownerId }),
    {
      enabled: !!ownerId,
    }
  );

export { useOwnedContracts };
