import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import { preventInvalidForwarding } from "styles/styleUtils";

const loading = keyframes({
  "0%": {
    transform: "translateX(-100%)",
  },
  "66%, 100%": {
    transform: "translateX(200%)",
  },
});

type PlaceholderBlockProps = {
  animated?: boolean;
  circle?: boolean;
  inline?: boolean;
  rounded?: boolean;
  height?: string;
  width?: string;
};

const PlaceholderBlock = styled("div", {
  shouldForwardProp: preventInvalidForwarding("height", "width"),
})<PlaceholderBlockProps>(
  ({ animated = true, circle, height, inline, rounded, width, theme }) => ({
    width: width ?? "auto",
    display: inline ? "inline-block" : "block",
    height: height ?? "auto",
    minWidth: width ?? 60,
    minHeight: height ?? "1em",
    marginBottom: ".6em",
    backgroundColor: theme.colors.fg10,
    borderRadius: 4,
    overflow: "hidden",
    position: "relative",
    ...(circle && {
      aspectRatio: "1 / 1",
      borderRadius: 999,
      "&[src], &>img": {
        objectFit: "cover",
      },
    }),
    ...(rounded && {
      borderRadius: 999,
    }),
    "&::before": {
      content: "''",
      display: "block",
      height: height ?? "100%",
      minHeight: height ?? "1em",
      top: 0,
      bottom: 0,
      left: 0,
      width: "50%",
      backgroundImage:
        "linear-gradient(to right, rgb(0 0 0 / 0) 0%, rgb(255 255 255 / .4) 50%, rgb(0 0 0 / 0) 100%)",
      ...(animated && {
        animation: `${loading} 1.6s infinite`,
      }),
    },
  }),
);

export { PlaceholderBlock };
