import * as React from "react";
import styled from "@emotion/styled";
import { usePrefersReducedMotion } from "utils/accessibility";
import { useTimeUntilDate } from "utils/date";
import { Text } from "./Typography";

type CountdownProps = {
  date: Date;
  showSeconds?: boolean;
  isEndDate?: boolean;
  textBefore?: string;
  textAfter?: string;
  onComplete?: VoidFunction;
};

function Countdown({
  date,
  showSeconds,
  isEndDate,
  textBefore,
  textAfter,
  onComplete,
  ...props
}: CountdownProps) {
  const prefersReducedMotion = usePrefersReducedMotion();
  const { days, hours, minutes, seconds, isZero, isNegative } =
    useTimeUntilDate(date);

  const [calledOnComplete, setCalledOnComplete] = React.useState(
    isNegative || isZero,
  );

  React.useEffect(() => {
    // NOTE: We need to wait for the tick going from 0 seconds to -1 seconds or else we risk
    // running onComplete and rendering the result in fewer milliseconds than it takes to
    // complete the next tick, meaning state might stay the same since it's still the "same time"
    // as what was provided to the date prop (i.e. countdown at 0 seconds) and not past (i.e. after
    // the countdown has "ended").
    if (onComplete && isNegative && !calledOnComplete) {
      onComplete();
      setCalledOnComplete(true);
    }
  }, [onComplete, isNegative, calledOnComplete, setCalledOnComplete]);

  return isNegative ? null : (
    <CountdownText urgent={days === 0} isEndDate={isEndDate} {...props}>
      {textBefore && <span>{textBefore} </span>}

      <CountdownTime>
        {days > 0 && (
          <span>
            <span>{days}</span>
            <span aria-label="days">d</span>
          </span>
        )}
        <span>
          <span>{hours.toString()}</span>
          <span aria-label="hours">h</span>
        </span>
        <span>
          <span>{minutes.toString().padStart(2, "0")}</span>
          <span aria-label="minutes">m</span>
        </span>
        {showSeconds && !prefersReducedMotion && (
          <Seconds>
            <span>{seconds.toString().padStart(2, "0")}</span>
            <span aria-label="seconds">s</span>
          </Seconds>
        )}
      </CountdownTime>

      {textAfter && <span> {textAfter}</span>}
    </CountdownText>
  );
}

const CountdownText = styled(Text)<{ urgent: boolean; isEndDate?: boolean }>(
  ({ urgent, isEndDate, theme }) => ({
    "&, *": {
      margin: 0,
      fontWeight: "inherit",
    },
    ...(urgent &&
      isEndDate && {
        color: theme.colors.accent4,
      }),
  }),
).withComponent("span");

const CountdownTime = styled(Text)({
  display: "inline-flex",
  gap: ".3em",
  flexWrap: "nowrap",
  whiteSpace: "nowrap",
  "&, *": {
    fontVariantNumeric: "tabular-nums",
    margin: 0,
    fontWeight: "inherit",
  },
}).withComponent("span");
const Seconds = styled.span({
  "@media (prefers-reduced-motion: reduce)": {
    display: "none",
  },
});
export { Countdown };
