import type { Image } from "@shopify/hydrogen-react/storefront-api-types";
import { pick } from "lodash";
import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { parseGid } from "../utils/shopifyId";
import { productAdminKeys, productKeys } from "./queryKeys";
import type { TemplateVariant } from "../types";
import {
  ProductAdminResp,
  transformAdminProduct,
} from "./useProductAdminQuery";

type VariantField = Pick<
  TemplateVariant,
  "basePriceCents" | "minimumPriceCents" | "recommendedPriceCents"
> & {
  // Required Fields for API
  id: string;
  variantId?: string;
  // Fields Supported by API for Updates
  priceInCents: number;
  initialQuantity?: number;
  height?: number;
  width?: number;
  length?: number;
  weight?: number;
  shipFromAddressId?: string;
  // Fields NOT YET Supported by API for Updates
  image?: Image | null;
  title: string;
  aspectRatio?: number;
  isFramed?: boolean;
};

type UpdateProductVariantsReq = {
  variants: VariantField[];
};

type UpdateProductVariantsResp = {
  shopifyProductId: string;
  product: ProductAdminResp;
};

const putUpdateProductVariants = ({
  id,
  variants,
}: UpdateProductVariantsReq & { id: string }) =>
  api
    .put<UpdateProductVariantsResp>(`/shop/products/${id}/variants`, {
      body: JSON.stringify({
        // Omit extra values on VariantFields that were needed for display, but not the request body
        variants: variants.map((v) =>
          pick(
            v,
            "id",
            "variantId",
            "priceInCents",
            "weight",
            "length",
            "width",
            "height",
            "initialQuantity",
            "shipFromAddressId",
          ),
        ),
      }),
    })
    .then(({ shopifyProductId, product }) => ({
      shopifyProductId,
      product: transformAdminProduct(product),
    }));

const useUpdateProductVariants = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData: UpdateProductVariantsReq) =>
      putUpdateProductVariants({ ...formData, id }),
    {
      onSuccess: ({ shopifyProductId, product }) => {
        queryClient.setQueryData(
          productAdminKeys.detail(parseGid(shopifyProductId).id),
          product,
        );
        queryClient.invalidateQueries(productKeys.detail(product.handle));
      },
    },
  );
};

export type { UpdateProductVariantsReq, VariantField };
export { useUpdateProductVariants };
