import { useQuery } from "react-query";
import { api } from "utils/api";
import type { ArtistApplicationSearchResponse } from "../types";

const userApplicationsKey = {
  base: ["artistApplications"] as const,
  users: () => [...userApplicationsKey.base, "user"] as const,
  user: (id: string) => [...userApplicationsKey.users(), id] as const,
};

/**
 * Fetch a user's applications. This API can be called
 * by the user themselves or by an admin
 */
const getProfileApplications = (userId: string) =>
  api.get<ArtistApplicationSearchResponse>(`/users/${userId}/applications`);

const useProfileApplications = (userId: string) =>
  useQuery(userApplicationsKey.user(userId), () =>
    getProfileApplications(userId),
  );

export { useProfileApplications };
