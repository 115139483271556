import * as React from "react";
import { createPortal } from "react-dom";

type PortalProps = {
  children: React.ReactNode;
  /**
   * Optional container to insert Portal into, defaults to document.body
   */
  containerRef?: React.RefObject<HTMLElement>;
  /**
   * Optional tag name for inserted Portal, defaults to div
   */
  as?: Parameters<typeof document.createElement>[0];
  /**
   * Optionally prepend element instead of appending it
   */
  prepend?: boolean;
};

/**
 * Render children to a different part of the DOM
 *
 * By default, any children passed to this component will be rendered within a new <div> appended
 * to the document.body, regardless of where the <Portal> exists within the component tree. This is
 * helpful to avoid messy things like clipping containers, overflow, and z-index issues. You  can
 * optionally override the type of element created with the `as` prop or append it to a different
 * element by providing a `containerRef` for that element.
 */
function Portal({
  children,
  containerRef,
  as = "div",
  prepend = false,
}: PortalProps) {
  const portalEl = React.useRef<HTMLElement | null>(null);
  const setState = React.useState({})[1];

  React.useLayoutEffect(() => {
    // Create a new node to use as the portal element
    portalEl.current = document.createElement(as);
    portalEl.current.className = "portal";
    // Determine where to append portal element
    const parent = containerRef?.current ?? document.body;
    // Insert portal element into document
    if (prepend) {
      parent.prepend(portalEl.current);
    } else {
      parent.appendChild(portalEl.current);
    }
    // Then force an update
    setState({});

    // Handle removing it when unneeded
    return () => {
      parent.removeChild(portalEl.current as HTMLElement);
    };
  }, [as, containerRef, prepend, setState]);

  return portalEl.current ? createPortal(children, portalEl.current) : null;
}

export { Portal };
