import * as React from "react";
import { useQuery, useMutation, useQueryClient } from "react-query";
import { useSocketMessageHandler } from "contexts/socket";
import { useAuth } from "features/auth";
import { api } from "utils/api";
import { profileKeys } from "features/profile";
import type { Notification, NotificationResponse } from "../types";

const getNotifications = () =>
  api.get<NotificationResponse>(`/profile/notifications`, {
    requireAuth: true,
  });

/**
 * Custom hook to fetch list of all user's notifications
 */
const useNotifications = () => {
  const { user } = useAuth();
  return useQuery(["notifications"], getNotifications, {
    enabled: !!user,
    select: (resp) => resp.data,
  });
};

/**
 * Custom hook to subscribe to new notification updates over websocket
 */
const useNewNotifications = () => {
  // Notifications are stored in QueryClient, this just tracks new items coming from WebSocket
  const [newNotifications, setNewNotifications] = React.useState<
    Notification[]
  >([]);

  const markReadById = (id: string) => {
    setNewNotifications((prev) => {
      const index = prev.findIndex((p) => p.notificationId === id);
      return index > -1
        ? [...prev.slice(0, index), ...prev.slice(index + 1, prev.length)]
        : prev;
    });
  };

  const queryClient = useQueryClient();

  const notificationHandler = React.useCallback(
    (data: { notification: Notification }) => {
      setNewNotifications((previous) => [...previous, data.notification]);
      queryClient.setQueryData<NotificationResponse>(
        ["notifications"],
        (previous) => ({
          ...previous,
          data: [data.notification, ...(previous?.data ?? [])],
        }),
      );
      queryClient.invalidateQueries(profileKeys.base);
    },
    [queryClient],
  );

  useSocketMessageHandler("NOTIFICATION", notificationHandler);

  return {
    newNotifications,
    count: newNotifications.length,
    markReadById,
  };
};

const markNotificationsAsRead = async (ids: string[]) => {
  const options = {
    body: JSON.stringify({ read: true }),
  };

  return Promise.all(
    ids.map((id) => api.put(`/profile/notifications/${id}`, options)),
  );
};

const useMarkNotificationsAsRead = () => {
  const queryClient = useQueryClient();

  return useMutation(markNotificationsAsRead, {
    onSuccess: () => {
      queryClient.invalidateQueries(["notifications"]);
    },
  });
};

export { useNotifications, useNewNotifications, useMarkNotificationsAsRead };
