import * as React from "react";
import { Link } from "atoms";
import { useCart } from "./CartProvider";
import { catalog } from "../data/catalog";
import type { CheckoutSessionRequest } from "../types";
import { trackAddToCart } from "../utils/analytics";

const { priceId, returnUrl } = catalog.extraOCSubmissions;
const cart: CheckoutSessionRequest = {
  lineItems: [
    {
      priceId,
      quantity: 1,
    },
  ],
  returnUrl,
};

type CheckoutLinkProps = React.ComponentProps<typeof Link> & {
  openCallId: string;
  title: string;
  onClick?: React.MouseEventHandler<HTMLAnchorElement>;
};

function ExtraOCSubmissionsLink({
  openCallId,
  title,
  onClick: parentOnClick,
  ...props
}: CheckoutLinkProps) {
  const { createCart } = useCart();

  const onClick: React.MouseEventHandler<HTMLAnchorElement> = (event) => {
    createCart({
      ...cart,
      metadata: {
        openCallId,
      },
      checkoutOpts: {
        title,
        from: `/open-calls/${openCallId}/submit`,
      },
    });

    trackAddToCart(cart.lineItems);

    if (parentOnClick) {
      parentOnClick(event);
    }
  };

  return <Link {...props} to="/checkout" onClick={onClick} />;
}

export { ExtraOCSubmissionsLink };
