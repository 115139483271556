import styled from "@emotion/styled";

const ScreenReaderText = styled.div({
  position: "absolute",
  left: "-10000px",
  top: "auto",
  width: "1px",
  height: "1px",
  overflow: "hidden",
});

export { ScreenReaderText };
