import styled from "@emotion/styled";

interface TagListProps {
  justify?: "center" | "flex-start" | "flex-end";
}

const TagList = styled.div<TagListProps>(
  ({ theme, justify = "flex-start" }) => ({
    display: "flex",
    flexDirection: "row",
    flexWrap: "wrap",
    fontSize: theme.fontSizes.xxs,
    justifyContent: justify,
    gap: 10,
  }),
);

export { TagList };
