import * as React from "react";
import styled from "@emotion/styled";
import { parseGid } from "@shopify/hydrogen-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  AllCaps,
  Box,
  Button,
  Checkbox,
  Container,
  FieldError,
  FlexRow,
  IconError,
  IconOk,
  IconWarningSignAlt,
  Link,
  LoadingEllipses,
  LoadingSpinner,
  Modal,
  RequiredText,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import {
  acceptTypes,
  FileInput,
  GenericImage,
  getDimensionsFromUrl,
  getTempPreviewUrl,
  HiddenInput,
  parseS3URL,
  useFileUpload,
} from "features/images";
import { type GalleryItem, SelectFromPortfolio } from "features/gallery";
import { useToggle } from "utils/hooks";
import { fieldOptions } from "utils/form";
import { paths } from "utils/paths";
import { removeFileExt } from "utils/text";
import {
  type CreateProductReq,
  useCreateProduct,
} from "../../api/useCreateProduct";
import type { CreateStepProps } from "../../types";
import { ProductWizardTemplate } from "../ProductWizardTemplate";
import { DpiText } from "./StepArtPrintOptions";
import { ProductWizardFooter } from "../ProductWizardStep";

// TODO: Replace with constant when merged
// 100mb = mb * kb * b
const MAX_UPLOAD_SIZE = 100 * 1000 * 1000;

type UploadArtworkForm = CreateProductReq & { affirm: boolean };

function CreateStepUploadArtwork({
  artistId,
  productTemplate,
}: CreateStepProps) {
  const navigate = useNavigate();

  const uploadMutation = useFileUpload();
  const productMutation = useCreateProduct();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<UploadArtworkForm>({
    defaultValues: {
      artistId,
      templateId: productTemplate.id,
    },
  });

  const assetUrl = watch("assetUrl");

  const [isSelecting, toggleIsSelecting] = useToggle(false);

  // Once a file is selected, upload it
  const onChange = (file: File) =>
    uploadMutation.mutate(
      { file },
      {
        onSuccess: ({ url }) => {
          setValue("assetUrl", url, { shouldDirty: true, shouldTouch: true });
          setValue("productName", removeFileExt(file.name));
        },
      },
    );

  // Once a gallery item is selected, update the form
  const onSelectionChange = ([item]: GalleryItem[]) => {
    if (item?.image) {
      // For Some Gallery Items - item.image.videoUrl is a empty string
      const newImageUrl = item.image.videoUrl?.length
        ? item.image.videoUrl
        : item.image.url;
      setValue("assetUrl", newImageUrl, {
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("productName", item.title);
      toggleIsSelecting();
    }
  };

  const reset = () => {
    setValue("assetUrl", "");
    setValue("productName", "");
  };

  // Create product after validation
  const onSubmit = handleSubmit(({ affirm: _affirm, ...formData }) =>
    productMutation.mutate(formData, {
      onSuccess: ({ shopifyProductId }) => {
        // On successful product creation, navigate to Product Wizard to configure it
        navigate(
          `${paths.artistShopProductEdit(artistId, parseGid(shopifyProductId).id)}?new=true`,
        );
      },
    }),
  );

  // Merge states of both mutations to simplify UI logic
  const isLoading = uploadMutation.isLoading || productMutation.isLoading;
  const isError = uploadMutation.isError || productMutation.isError;
  const error = uploadMutation.error ?? productMutation.error;

  const isOnchainAsset = productTemplate.id === "HUG-ON-CHAIN";
  const isArtPrint = productTemplate.id === "PRODIGI-PRINTS";
  const isSticker = productTemplate.id === "PRODIGI-STICKERS";

  const acceptType = isOnchainAsset ? "onchainAsset" : "stillImage";
  const newAcceptType = isSticker ? "png" : acceptType;

  // Prevent videos from being selected for Art Prints
  // TODO: allow videos to be selected for onchain assets when we add support for viewing them
  // const filterPortfolioItems = isOnchainAsset
  //   ? undefined
  //   : (item: GalleryItem) => !item.image?.videoUrl;
  const filterPortfolioItems = (item: GalleryItem) => !item.image?.videoUrl;

  return (
    <Form onSubmit={onSubmit}>
      <ProductWizardTemplate
        title="Product Builder"
        subtitle={isOnchainAsset ? "Upload Collectible Media" : "Upload Image"}
        artistId={artistId}
      >
        <Container width="md">
          <Box fullWidth>
            <Stack gap="md">
              <Stack gap="xxs">
                <Text size="sm" bold style={{ color: "#262626" }}>
                  <span>{isOnchainAsset ? "Media" : "Image"}</span>{" "}
                  <RequiredText />
                </Text>

                {isOnchainAsset && (
                  <>
                    <Text size="xs">
                      Do you know that any digital creation can be collected and
                      owned onchain? Get creative and upload anything from an
                      original song, photograph, drawing, or even writing that
                      you would like to sell as an onchain collectible.
                    </Text>
                    <Link href={paths.shopHelp} target="_blank">
                      Learn more about selling onchain collectibles.
                    </Link>
                  </>
                )}
                {isArtPrint && (
                  <Text size="xs">
                    Upload the image you would like to produce and sell as a
                    print. You will be able to view mockups and select
                    additional options in subsequent steps.
                  </Text>
                )}
                {isSticker && (
                  <Text size="xs">
                    Upload the image you would like to produce and sell as a
                    sticker.
                  </Text>
                )}
              </Stack>

              <Columns>
                <UploadBox>
                  {assetUrl ? (
                    <Stack gap="xs">
                      <GenericImage
                        src={parseS3URL(assetUrl)}
                        alt=""
                        sizes="300px"
                      />
                      {uploadMutation.data?.file.name && (
                        <Text size="xxs">{uploadMutation.data.file.name}</Text>
                      )}

                      {!isOnchainAsset && (
                        <AssetQualityMessage assetUrl={assetUrl} />
                      )}

                      <Button
                        size="xxs"
                        variant="blank"
                        onClick={reset}
                        style={{ borderBottomColor: "#262626" }}
                      >
                        {isOnchainAsset ? "Replace Media" : "Replace Image"}
                      </Button>
                    </Stack>
                  ) : (
                    <>
                      <UploadButtons>
                        <FileInput
                          text="Upload From Device"
                          onChange={onChange}
                          accept={newAcceptType}
                          maxFileSize={MAX_UPLOAD_SIZE}
                          hideRequirements
                        />

                        <Button
                          size="xs"
                          variant="secondary"
                          onClick={toggleIsSelecting}
                        >
                          Select From Portfolio
                        </Button>
                      </UploadButtons>

                      <HiddenInput
                        {...register("assetUrl", fieldOptions.required)}
                      />

                      {errors.assetUrl && (
                        <FieldError>You need to select an image.</FieldError>
                      )}
                    </>
                  )}
                </UploadBox>
                <TextColumn>
                  <Stack gap="sm">
                    {isOnchainAsset && (
                      <Stack gap="0">
                        <Text size="xs" bold>
                          Media Requirements
                        </Text>

                        <Text size="xxs">
                          Accepted file types:{" "}
                          {acceptTypes[newAcceptType].labels.join(", ")}
                        </Text>
                      </Stack>
                    )}
                    {isArtPrint && (
                      <>
                        <Stack gap="0">
                          <Text size="xs" bold>
                            Image Requirements
                          </Text>

                          <Text size="xxs">
                            Accepted file types:{" "}
                            {acceptTypes[newAcceptType].labels.join(", ")}
                            <br />
                            Recommended dimensions: at least 6000px wide or tall
                            <br />
                            Recommended DPI: 300dpi
                          </Text>
                        </Stack>
                        <Stack gap="0">
                          <Text size="xs" bold>
                            Available Sizes
                          </Text>

                          <Text size="xxs">
                            Your image will be centered and may be cropped to
                            fit multiple sizes and orientations for you to
                            choose from in a later step.
                          </Text>
                        </Stack>
                      </>
                    )}
                    {isSticker && (
                      <Stack gap="0">
                        <Text size="xs" bold>
                          Image Requirements
                        </Text>

                        <Text size="xxs">
                          Accepted file types:{" "}
                          {acceptTypes[newAcceptType].labels.join(", ")}
                          <br />
                          Maximum dimensions: 3&rdquo;&times;4&rdquo; (900px
                          &times; 1200px)
                          <br />
                          Recommended DPI: 300dpi
                        </Text>
                      </Stack>
                    )}

                    <Stack gap="xxs">
                      <Text size="xs" bold>
                        Confirm <span>{isOnchainAsset ? "IP" : "Artwork"}</span>{" "}
                        Ownership <RequiredText />
                      </Text>

                      <Checkbox
                        {...register("affirm", fieldOptions.required)}
                        label={
                          <Text
                            as="span"
                            size="xxs"
                            style={{ display: "inline-block", lineHeight: 1.2 }}
                          >
                            I confirm that I created and own{" "}
                            <span>
                              {isOnchainAsset
                                ? "the intellectual property associated with this media"
                                : "this original artwork"}
                            </span>
                            , and that it does not violate{" "}
                            <AllCaps>Hug</AllCaps>
                            &apos;s{" "}
                            <Link to="/terms-of-service" target="_blank">
                              Terms of Service
                            </Link>
                          </Text>
                        }
                      />
                      {errors.affirm && (
                        <FieldError>{errors.affirm.message}</FieldError>
                      )}
                    </Stack>
                  </Stack>
                </TextColumn>
              </Columns>

              {isError && <UnexpectedError error={error} />}

              {isLoading && (
                <LoadingSpinner
                  text={
                    uploadMutation.isLoading ? "Uploading" : "Creating Product"
                  }
                />
              )}
            </Stack>
          </Box>
        </Container>
      </ProductWizardTemplate>

      <Modal isOpen={isSelecting} onClose={toggleIsSelecting}>
        <SelectFromPortfolio
          artistId={artistId}
          onChange={onSelectionChange}
          filter={filterPortfolioItems}
        />
      </Modal>

      <ProductWizardFooter>
        <Button
          variant="secondary"
          size="xs"
          onClick={() => window.location.reload()}
        >
          Back
        </Button>
        <Button size="xs" type="submit" disabled={isLoading}>
          Next
        </Button>
      </ProductWizardFooter>
    </Form>
  );
}

const getQuality = (firstGoodDpi: number, minDimension: number) => {
  if (!minDimension) {
    return {
      message: (
        <span>
          Calculating quality
          <LoadingEllipses />
        </span>
      ),
    };
  }
  if (firstGoodDpi === 0) {
    return {
      icon: (
        <span style={{ color: "#34b146" }}>
          <IconOk size={18} />
        </span>
      ),
      message: "Good image quality for all print sizes",
    };
  }
  if (firstGoodDpi === 1) {
    return {
      icon: (
        <span style={{ color: "#34b146", height: "1.2em" }}>
          <IconOk size={18} />
        </span>
      ),
      message: "Good image quality for most print sizes",
    };
  }
  if (firstGoodDpi === 2) {
    return {
      icon: (
        <span style={{ color: "#d67d00", height: "1.2em" }}>
          <IconWarningSignAlt size={18} />
        </span>
      ),
      message: "Average image quality for most print sizes",
    };
  }
  if (firstGoodDpi === 3) {
    return {
      icon: (
        <span style={{ color: "#D3462E", height: "1.2em" }}>
          <IconError size={18} />
        </span>
      ),
      message: "Poor image quality for most print sizes",
    };
  }
  return {
    icon: (
      <span style={{ color: "#D3462E", height: "1.2em" }}>
        <IconError size={18} />
      </span>
    ),
    message: "Poor image quality for all print sizes",
  };
};

type AssetQualityMessageProps = {
  assetUrl: string;
};

function AssetQualityMessage({ assetUrl, ...props }: AssetQualityMessageProps) {
  const [assetWidth, setAssetWidth] = React.useState(0);
  const [assetHeight, setAssetHeight] = React.useState(0);

  React.useEffect(() => {
    if (assetUrl) {
      const publicSrc = getTempPreviewUrl(assetUrl);
      getDimensionsFromUrl(publicSrc).then(({ height, width }) => {
        setAssetWidth(width);
        setAssetHeight(height);
      });
    }
  }, [assetUrl]);

  // get smallest dimension of asset
  const minDimension = Math.min(assetWidth, assetHeight);

  // get DPI for all print dimensions
  const dpi8in = Math.floor(minDimension / 8);
  const dpi12in = Math.floor(minDimension / 12);
  const dpi14in = Math.floor(minDimension / 14);
  const dpi16in = Math.floor(minDimension / 16);
  const dpi20in = Math.floor(minDimension / 20);
  const allDpis = [dpi20in, dpi16in, dpi14in, dpi12in, dpi8in];

  const firstGoodDpi = allDpis.findIndex((n) => n > 280);

  const quality = getQuality(firstGoodDpi, minDimension);
  const qualityMessage = (
    <FlexRow gap="4px" flexWrap="nowrap" style={{ lineHeight: 1.2 }} {...props}>
      {quality.icon}
      <DpiText>
        <span>{quality.message}</span>
      </DpiText>
    </FlexRow>
  );

  return qualityMessage;
}

const Form = styled.form({
  minHeight: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

const Columns = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
  [theme.breakpoints.desktop]: {
    flexDirection: "row",
  },
}));

const UploadBox = styled.div(({ theme }) => ({
  alignSelf: "flex-start",
  width: "100%",
  [theme.breakpoints.desktop]: {
    flex: "0 0 250px",
  },
}));

const UploadButtons = styled.div(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexWrap: "wrap",
  gap: 10,
  [theme.breakpoints.desktop]: {
    aspectRatio: 1,
    borderRadius: theme.borderRadius.md,
    backgroundColor: theme.colors.gray20,
    flexDirection: "column",
    justifyContent: "center",
    gap: 20,
    padding: 20,
    "*": {
      width: "100%",
    },
  },
  "&>button, &>div label": {
    minWidth: 200,
    [theme.breakpoints.desktop]: {},
  },
}));

const TextColumn = styled.div(({ theme }) => ({
  [theme.breakpoints.desktop]: {
    flex: "1",
  },
}));

export {
  CreateStepUploadArtwork,
  Columns,
  UploadBox,
  TextColumn,
  UploadButtons,
};
