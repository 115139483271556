import styled from "@emotion/styled";

const Hr = styled.hr({
  backgroundColor: "currentcolor",
  opacity: 0.25,
  border: 0,
  height: 1,
  margin: "1em 0",
});

export { Hr };
