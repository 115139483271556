import * as React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  FlexRow,
  IconAlignHorizontal,
  IconAlignVertical,
  IconCheckRounded,
  IconContain,
  IconCover,
  IconError,
  IconWarningSign,
  Legend,
  LoadingEllipses,
} from "atoms";
import { useDebouncer } from "utils/hooks";

// TODO:
// - handle leaving while job isn't finished and product isn't created yet!!

/** Acceleration threshold to prevent dragging asset away from "sticky" center or edge coordinates */
const STICKY_THRESHOLD = 2;

/** Percent of wrapper covered by print area */
const WRAPPER_COVERAGE = 0.8;

type DesignPreviewProps = {
  design: File | string;
  designTemplateSrc?: string;
  printArea: Dimensions;
};

const DesignPreview = React.forwardRef<
  DesignPreviewMethods | null,
  DesignPreviewProps
>(({ design, designTemplateSrc, printArea }, ref) => {
  // Scaled print area to fit DesignPreview within available space in window
  const [area, setArea] = React.useState<ScaledDimensions>({
    dimensions: { ...printArea },
    original: { ...printArea },
    scale: 1,
  });
  // Scaled asset and its translated coordinates within print area
  const [asset, setAsset] = React.useState<ScaledAssetState>(initialAssetState);
  const dpi = useDebouncer(
    Math.min(300, Math.round(300 / (asset.scale / area.scale))),
    100,
  );

  // Ref for wrapper element to handle screen resize events
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);

  // Find coordinates that will center the asset within the area
  const center = React.useMemo(
    () => getCoordinatesToCenter(asset.dimensions, area.dimensions, "both"),
    [asset.dimensions, area.dimensions],
  );

  // Load the file to get src url, dimensions, and starting coordinates/scale
  React.useEffect(() => {
    const img = new Image();
    const url =
      typeof design === "string" ? design : URL.createObjectURL(design);

    img.onload = () => {
      const original = { width: img.naturalWidth, height: img.naturalHeight };

      // const isLandscape = width > height;
      // const isSquarish =
      //   (isLandscape ? width / height : height / width) > SQUARE_TOLERANCE;

      // Get starting area scale in case we don't have it already
      const areaScale = getAreaScale(area.original, wrapperRef.current);

      // And update print area dimensions accordingly
      setArea({
        original: area.original,
        ...areaScale,
      });

      // Set asset's url to mark it as loaded along with its original dimensions and starting
      // coordinates, scale, and scaled dimensions.
      setAsset({
        url,
        original,
        ...getStartingCoordinates(original, areaScale.dimensions),
      });
    };

    img.src = url;

    return () => {
      if (typeof design !== "string") {
        URL.revokeObjectURL(url);
      }
      setAsset(initialAssetState);
    };
  }, [design, area.original]);

  // Add event listener to recalculate print area scale on window resize
  React.useLayoutEffect(() => {
    const handleResize = () => {
      setArea((prev) => ({
        ...prev,
        ...getAreaScale(printArea, wrapperRef.current),
      }));
    };
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [setArea, printArea]);

  // Allow parent to imperatively get current asset state
  React.useImperativeHandle<DesignPreviewMethods | null, DesignPreviewMethods>(
    ref,
    () => ({
      getState: () => ({
        ...(design instanceof File ? { file: design } : { url: design }),
        // Undo area scale used as zoom for DesignPreview to fit in window
        asset: {
          scale: asset.scale / area.scale,
          coordinates: getScaledCoordinates(asset.coordinates, 1 / area.scale),
          dimensions: getScaledDimensions(asset.dimensions, 1 / area.scale),
        },
        printArea,
      }),
    }),
    [design, asset, area.scale, printArea],
  );

  const onMouseMove: MouseEventHandler = ({
    currentTarget,
    movementX,
    movementY,
  }) => {
    const { handle } = currentTarget.dataset;
    const isDragging = currentTarget.classList.contains(DRAGGING);
    const isResizing = currentTarget.classList.contains(RESIZING) && !!handle;

    if (!isDragging && !isResizing) {
      return;
    }

    // Clamp x,y coordinates so asset can't fully leave area
    const minX = area.dimensions.width * 0.05 - asset.dimensions.width;
    const maxX = area.dimensions.width * 0.95;
    const minY = area.dimensions.height * 0.05 - asset.dimensions.height;
    const maxY = area.dimensions.height * 0.95;
    const clampX = (x: number) => clamp(minX, x, maxX);
    const clampY = (y: number) => clamp(minY, y, maxY);

    setAsset((prev) => {
      if (isResizing) {
        let dir = "";
        if (movementX < 0 && movementY < 0) {
          dir = "nw";
        } else if (movementX < 0 && movementY > 0) {
          dir = "sw";
        } else if (movementX > 0 && movementY < 0) {
          dir = "ne";
        } else if (movementX > 0 && movementY > 0) {
          dir = "se";
        }

        // Find the axis that is moving the most and use that to change both dimensions/coords in
        // order to keep the same aspect ratio.
        const delta = Math.max(Math.abs(movementX), Math.abs(movementY));
        // Math.abs(movementX) > Math.abs(movementY) ? movementX : movementY;
        // If the handle is on the east, we want to add the delta (e.g. positive motion -> larger
        // size), but we need to do the opposite for handles on the west (e.g. positive motion ->
        // smaller size).
        const newWidth = Math.max(
          prev.dimensions.width + (handle[1] === dir[1] ? delta : delta * -1),
          prev.original.width * 0.05, // never let width get resized below 5% of original
        );
        // Calculate new scale in order to change height
        const scale = getScale(prev.original.width, newWidth);
        const dimensions: Dimensions = {
          width: newWidth,
          height: prev.original.height * scale,
        };

        // Determine new coordinate based on change in size for that dimension
        const newX = clampX(
          prev.coordinates.x + prev.dimensions.width - dimensions.width,
        );
        const newY = clampY(
          prev.coordinates.y + prev.dimensions.height - dimensions.height,
        );

        // Set those coordinates based on which handle is being used and in which direction
        let coordinates: Coordinates | null = null;
        if (handle === "nw" && (dir === "nw" || dir === "se")) {
          coordinates = { x: newX, y: newY };
        } else if (handle === "ne" && (dir === "ne" || dir === "sw")) {
          coordinates = { ...prev.coordinates, y: newY };
        } else if (handle === "se" && (dir === "nw" || dir === "se")) {
          coordinates = prev.coordinates;
        } else if (handle === "sw" && (dir === "ne" || dir === "sw")) {
          coordinates = { ...prev.coordinates, x: newX };
        }

        // Lack of coordinates means we're pulling a direction that doesn't make sense for that
        // handle, in which case, do nothing.
        return !coordinates
          ? prev
          : {
              ...prev,
              scale,
              dimensions,
              coordinates,
            };
      }

      // We must be dragging instead of resizing
      // Determine if we have enough acceleration to break away from sticky positions
      const stuckToX =
        center.x === prev.coordinates.x &&
        Math.abs(movementX) < STICKY_THRESHOLD;
      const stuckToY =
        center.y === prev.coordinates.y &&
        Math.abs(movementY) < STICKY_THRESHOLD;

      // If we do, add that movement to the previous position
      const newX = prev.coordinates.x + (stuckToX ? 0 : movementX);
      const newY = prev.coordinates.y + (stuckToY ? 0 : movementY);

      // Then make sure that new position is within bounds
      return { ...prev, coordinates: { x: clampX(newX), y: clampY(newY) } };
    });
  };

  const onMouseDown: MouseEventHandler = ({ currentTarget }) => {
    if (wrapperRef.current && currentTarget.dataset.handle) {
      wrapperRef.current.classList.add(RESIZING);
      wrapperRef.current.dataset.handle = currentTarget.dataset.handle;
    } else if (!currentTarget.classList.contains(RESIZING)) {
      currentTarget.classList.add(DRAGGING);
    }
  };

  const stopInteraction: MouseEventHandler = ({ currentTarget }) => {
    currentTarget.classList.remove(DRAGGING, RESIZING);
    delete currentTarget.dataset.handle;
  };

  const onDragStart: React.DragEventHandler<HTMLElement> = ({
    preventDefault,
  }) => {
    preventDefault();
    // NOTE: returning false prevents default browser action of drag/dropping image to save file
    return false;
  };

  const centerHorizontally = () =>
    setAsset(({ coordinates: { y }, ...prev }) => ({
      ...prev,
      coordinates: { x: center.x, y },
    }));
  const centerVertically = () =>
    setAsset(({ coordinates: { x }, ...prev }) => ({
      ...prev,
      coordinates: { x, y: center.y },
    }));

  const cover = () => {
    setAsset((prev) => ({
      ...prev,
      ...getStartingCoordinates(prev.original, area.dimensions, "cover"),
    }));
  };

  const contain = () => {
    setAsset((prev) => ({
      ...prev,
      ...getStartingCoordinates(prev.original, area.dimensions, "contain"),
    }));
  };

  const isNotCovered = !isCovered(
    area.dimensions,
    asset.dimensions,
    asset.coordinates,
  );

  return (
    <div style={{ position: "relative" }}>
      <Wrapper fullWidth>
        <Actions>
          <Legend>Fit and Alignment</Legend>
          <ButtonWrapper>
            <Button
              size="md"
              variant="secondary"
              onClick={cover}
              title="Cover entire print area with your design"
            >
              <IconCover accent="fg20" />
            </Button>
            Cover
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              size="md"
              variant="secondary"
              onClick={contain}
              title="Contain your entire design within the print area"
            >
              <IconContain accent="fg20" />
            </Button>
            Contain
          </ButtonWrapper>
          <ButtonDivider />
          <ButtonWrapper>
            <Button
              size="md"
              variant="secondary"
              onClick={centerHorizontally}
              title="Center your design horizontally within the print area"
            >
              <IconAlignHorizontal accent="fg60" />
            </Button>
            Horizontal
          </ButtonWrapper>
          <ButtonWrapper>
            <Button
              size="md"
              variant="secondary"
              onClick={centerVertically}
              title="Center your design vertically within the print area"
            >
              <IconAlignVertical accent="fg60" />
            </Button>
            Vertical
          </ButtonWrapper>
        </Actions>

        <Quality dpi={dpi} />

        <DesignWrapper
          ref={wrapperRef}
          onMouseDown={onMouseDown}
          onMouseMove={onMouseMove}
          onMouseOut={stopInteraction}
          onMouseUp={stopInteraction}
          onDragStart={onDragStart}
          style={{
            "--area-width": `${area.dimensions.width}px`,
            "--area-height": `${area.dimensions.height}px`,
            "--asset-url": `url("${asset.url}")`,
            "--asset-width": `${asset.dimensions.width}px`,
            "--asset-height": `${asset.dimensions.height}px`,
            "--asset-x": `${asset.coordinates.x}px`,
            "--asset-y": `${asset.coordinates.y}px`,
          }}
        >
          {asset.url ? (
            <OriginReference>
              <AssetShadow />
              <PrintArea />
              <AssetArea />
              {designTemplateSrc && (
                <PrintAreaTemplate
                  style={{ "--area-url": `url("${designTemplateSrc}")` }}
                />
              )}
              <Handles
                showHorizontal={asset.coordinates.x === center.x}
                showVertical={asset.coordinates.y === center.y}
              >
                <button
                  type="button"
                  data-handle="nw"
                  onMouseDown={onMouseDown}
                >
                  resize by northwest corner
                </button>
                <button
                  type="button"
                  data-handle="ne"
                  onMouseDown={onMouseDown}
                >
                  resize by northeast corner
                </button>
                <button
                  type="button"
                  data-handle="se"
                  onMouseDown={onMouseDown}
                >
                  resize by southeast corner
                </button>
                <button
                  type="button"
                  data-handle="sw"
                  onMouseDown={onMouseDown}
                >
                  resize by southwest corner
                </button>
              </Handles>
            </OriginReference>
          ) : (
            <LoadingEllipses text="Loading image" />
          )}
        </DesignWrapper>
      </Wrapper>
      {isNotCovered && (
        <Warning>
          <IconWarningSign accent="accent3" size="1.2em" />
          Your design isn&apos;t completely filling the print area
        </Warning>
      )}
    </div>
  );
});

const Wrapper = styled(Box)(({ theme }) => ({
  display: "grid",
  gap: 0,
  gridTemplateAreas: `"actions"
    "quality"
    "design"`,
  padding: 0,
  position: "relative",
  [theme.breakpoints.desktop]: {
    gridTemplateAreas: `"actions quality"
      "design design"`,
    gridTemplateColumns: "1fr auto",
  },

  // creates full-width view on small screens.
  [theme.breakpoints.maxDesktopMedium]: {
    paddingBlock: 0,
    paddingInline: 0,
    border: 0,
    borderRadius: 0,
    boxShadow: "none",
    marginLeft: `calc(${theme.spacing.gutter} * -1)`,
    marginRight: `calc(${theme.spacing.gutter} * -1)`,
    width: `calc(100% + (${theme.spacing.gutter} * 2))`,
  },
}));

const Actions = styled(FlexRow)(({ theme }) => ({
  backgroundColor: theme.colors.gray10,
  gridArea: "actions",
  justifyContent: "center",
  padding: "0.5rem 2rem",
  gap: "1rem",
  legend: {
    display: "none",
    fontSize: theme.fontSizes.xs,
    color: theme.colors.fg,
  },
  [theme.breakpoints.desktop]: {
    justifyContent: "start",
    legend: {
      display: "block",
    },
  },
}));

function Quality({ dpi }: { dpi: number }) {
  let dpiQuality = "Good";
  let Icon = IconCheckRounded;
  if (dpi < 200) {
    dpiQuality = "Poor";
    Icon = IconError;
  } else if (dpi < 300) {
    dpiQuality = "Average";
    Icon = IconWarningSign;
  }

  return (
    <QualityWrapper quality={dpiQuality}>
      <Icon size="1.2em" />
      <span>{`${dpiQuality} image quality: ${dpi} dpi`}</span>
    </QualityWrapper>
  );
}

const MessageWithIcon = styled.div({
  alignItems: "center",
  display: "flex",
  gap: "0.25rem",
  justifyContent: "center",
  lineHeight: 1.3,
  padding: "0.5rem 2rem",
  svg: {
    transform: "translateY(-2px)",
  },
});

const QualityWrapper = styled(MessageWithIcon)<{ quality: string }>(
  ({ theme, quality }) => ({
    backgroundColor: theme.colors.successL,
    color: theme.colors.fg,
    gridArea: "quality",
    flex: "0 1 0%",
    fontSize: 14,
    svg: {
      color: theme.colors.success,
    },
    ...(quality === "Poor" && {
      backgroundColor: theme.colors.errorL,
      svg: {
        color: theme.colors.error,
      },
    }),
    ...(quality === "Average" && {
      backgroundColor: theme.colors.warningL,
      svg: {
        color: theme.colors.warning,
      },
    }),
  }),
);

const Warning = styled(MessageWithIcon)(({ theme }) => ({
  // backgroundColor: theme.colors.bg,
  backgroundColor: "rgb(255 255 255 / .85)",
  borderRadius: theme.borderRadius.sm,
  // boxShadow: theme.boxShadow.dark,
  color: theme.colors.fg,
  fontSize: 14,
  paddingBlock: 5,
  paddingInline: 15,
  position: "sticky",
  // position: "absolute",
  bottom: 20,
  // left: "50%",
  // transform: "translateX(-50%)",
  transform: "translateY(-150%)",
  margin: "0 auto",
  whiteSpace: "nowrap",
  width: "max-content",
}));

// Class constants
const DRAGGING = "dragging";
const RESIZING = "resizing";

/**
 * Wrapper element that will capture all drag/drop events, nothing should overflow it.
 */
const DesignWrapper = styled.div({
  aspectRatio: `var(--area-width) / var(--area-height)`,
  alignItems: "center",
  cursor: "grab",
  display: "flex",
  gridArea: "design",
  justifyContent: "center",
  maxHeight: "80vh",
  minHeight: "60vh",
  overflow: "hidden",
  width: "100%",
  [`&.${DRAGGING}`]: {
    cursor: "grabbing",
  },
});

/**
 * Relative positioned element used as a reference frame for all [x,y] translations within it.
 * Coordinates of [0,0] equate to this element's top left corner. Window resize event should
 * trigger new height and width calculations for fully viewable elements within, and changed
 * scale will cascade to nested elements.
 */
const OriginReference = styled.div({
  height: "var(--area-height)",
  position: "relative",
  width: "var(--area-width)",
});

/**
 * Element showing the available print area's dimensions
 */
const PrintArea = styled.div({
  height: `var(--area-height)`,
  // NOTE: using outline to not affect width x height dimensions
  outline: "1px solid #0080FF",
  position: "absolute",
  width: `var(--area-width)`,
});

const PrintAreaTemplate = styled(PrintArea)({
  backgroundImage: `var(--area-url)`,
  backgroundSize: "cover",
  outline: 0,
  // opacity: 0.8,
});

const ButtonWrapper = styled.div({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  fontSize: 12,
  textAlign: "center",
  position: "relative",
  maxWidth: 40,
  width: 40,
  button: {
    aspectRatio: 1,
    padding: 0,
    width: 40,
    height: 40,
  },
  "button::after": {
    content: "''",
    position: "absolute",
    top: "100%",
    left: 0,
    right: 0,
    height: "1em",
  },
});
const ButtonDivider = styled.span({
  backgroundColor: "currentColor",
  height: "100%",
  width: 1,
  opacity: 0.33,
});
/**
 * Shared styles for all elements that share the Asset's coordinates and dimensions. The former will
 * change on DesignWrapper's drag/drop events, the later will change on Handles' drag/drop events.
 */
const AssetBox = styled.div({
  height: `var(--asset-height)`,
  position: "absolute",
  transform: `translate(var(--asset-x), var(--asset-y))`,
  transformOrigin: "top left",
  transition: "translate ease-in 0.2s, opacity ease-in 0.2s",
  width: `var(--asset-width)`,
});

const AssetBoxWithImage = styled(AssetBox)({
  backgroundImage: "var(--asset-url)",
  backgroundSize: "contain",
});

/**
 * Semi-transparent copy of asset visible only where overflowing print area, lowest z-index
 */
const AssetShadow = styled(AssetBoxWithImage)({
  opacity: 0.25,
});

/**
 * Visible asset on top of print area, clip-path logic hides all portions overflowing it.
 */
const AssetArea = styled(AssetBoxWithImage)({
  // For top and left sides, use absolute value if coordinate is negative to clip back to origin.
  "--top": `calc(min(0px, var(--asset-y)) * -1)`,
  "--left": `calc(min(0px, var(--asset-x)) * -1)`,
  // For right and bottom sides, calculate amount of overlap beyond print area plus any translation
  "--right": `max(0px, calc(var(--asset-width) - var(--area-width) + var(--asset-x)))`,
  "--bottom": `max(0px, calc(var(--asset-height) - var(--area-height) + var(--asset-y)))`,
  // Combine all clip values into an inset rectangle to show AssetShadow layered underneath
  clipPath: `inset(var(--top) var(--right) var(--bottom) var(--left))`,
});

/**
 * Asset-sized container to hold resizable corners
 */
const Handles = styled(AssetBox)<{
  showHorizontal: boolean;
  showVertical: boolean;
}>(({ theme, showHorizontal, showVertical }) => ({
  // Pin handle buttons to the width and height of the wrapper, even if asset is overlapping it
  "--top": `max(0px, calc((var(--asset-y) + var(--wrapper-padding-h)) * -1))`,
  "--left": `max(0px, calc((var(--asset-x) + var(--wrapper-padding-w)) * -1))`,
  "--right": `max(0px, calc(var(--asset-x) + var(--asset-width) - var(--area-width) - var(--wrapper-padding-w)))`,
  "--bottom": `max(0px, calc(var(--asset-y) + var(--asset-height) - var(--area-height) - var(--wrapper-padding-h)))`,
  opacity: 0,
  outline: `2px dashed ${theme.colors.accent1}`,
  // Make visible when design wrapper mouseMove events are happening, or if child buttons are hovered
  [`.${DRAGGING} &, .${RESIZING} &, &:has(button:hover), &:has(button:focus-visible)`]:
    {
      opacity: 1,
    },
  "& > button": {
    background: theme.colors.transparent,
    border: 0,
    lineHeight: 0,
    padding: 10,
    pointerEvents: "auto",
    position: "absolute",
    textIndent: -9999,
    transformOrigin: "center",
    transition: "opacity ease-in 0.3s",
    "&:focus": {
      outline: 0,
    },
    "&::after": {
      content: `""`,
      background: theme.colors.bg,
      border: `2px solid ${theme.colors.gray60}`,
      display: "block",
      height: 20,
      transition: "background ease-in 0.2s",
      width: 20,
    },
    "&:focus-visible::after, &:active::after": {
      background: theme.colors.accent1L,
    },
    // While resizing, make the button quite large and on top of everything so the mouseMove event
    // is not interrupted by running the mouseOut event off the button
    [`.${RESIZING} &:active`]: {
      padding: `50vh 50vw`,
      zIndex: 10,
    },
  },
  // Top left
  "button:nth-of-type(1)": {
    cursor: "nwse-resize",
    left: `var(--left)`,
    transform: "translate(-50%, -50%)",
    top: `var(--top)`,
  },
  // Top right
  "button:nth-of-type(2)": {
    cursor: "nesw-resize",
    right: `var(--right)`,
    transform: "translate(50%, -50%)",
    top: `var(--top)`,
  },
  // Bottom right
  "button:nth-of-type(3)": {
    bottom: `var(--bottom)`,
    cursor: "nwse-resize",
    right: `var(--right)`,
    transform: "translate(50%, 50%)",
  },
  // Bottom left
  "button:nth-of-type(4)": {
    bottom: `var(--bottom)`,
    cursor: "nesw-resize",
    left: `var(--left)`,
    transform: "translate(-50%, 50%)",
  },
  // Center Marks
  "&::after,&::before": {
    content: `""`,
    background: theme.colors.accent1L,
    display: "block",
    transition: "opacity ease 0.3s",
    position: "absolute",
  },
  // Horizontal Center Mark (|)
  "&::after": {
    width: 2,
    height: `calc(var(--asset-height) + 50px)`,
    opacity: showHorizontal ? 1 : 0,
    left: "calc(50% - 1px)",
    top: -25,
  },
  // Vertical Center Mark (--)
  "&::before": {
    height: 2,
    width: `calc(var(--asset-width) + 50px)`,
    left: -25,
    top: "calc(50% - 1px)",
    opacity: showVertical ? 1 : 0,
  },
}));

type MouseEventHandler = React.MouseEventHandler<
  HTMLDivElement | HTMLButtonElement
>;

// If width/height are within 10% of each other, it's squarish
// const SQUARE_TOLERANCE = 0.1;

type ScaledDimensions = {
  original: Dimensions;
  dimensions: Dimensions;
  scale: number;
};
type WithCoordinates<T extends {}> = T & {
  coordinates: Coordinates;
};
type ScaledAssetState = WithCoordinates<ScaledDimensions> & {
  url: string;
};
type ScaledAsset = Pick<
  ScaledAssetState,
  "dimensions" | "coordinates" | "scale"
>;

/**
 * Given an object and area's dimensions, return coordinates of object's top left corner to center it within area
 *
 * By default, object will be centered along both axes. To center along just one axis, provide object's current coordinates.
 */
const getCoordinatesToCenter = (
  object: Dimensions,
  area: Dimensions,
  direction: "both" | "horizontal" | "vertical" = "both",
  { x, y }: Coordinates = { x: 0, y: 0 },
): Coordinates => {
  // Start from current coordinates in case we're only centering one axis (i.e. not changing the other value)
  // If we're centering by both axes, then we don't need current coordinates
  const newCoordinates = { x, y };

  // Find center of print area and center of object along one axis
  // Place the object at the difference of the two distances
  if (direction === "both" || direction === "horizontal") {
    const xP = Math.round(area.width / 2);
    const xA = Math.round(object.width / 2);
    newCoordinates.x = xP - xA;
  }
  if (direction === "both" || direction === "vertical") {
    const yP = Math.round(area.height / 2);
    const yA = Math.round(object.height / 2);
    newCoordinates.y = yP - yA;
  }

  return newCoordinates;
};

/**
 * Scale a set of dimensions up or down
 */
const getScaledDimensions = (
  { width, height }: Dimensions,
  scale: number,
): Dimensions => ({
  width: Math.round(width * scale),
  height: Math.round(height * scale),
});

/**
 * Scale a set of coordinates up or down
 */
const getScaledCoordinates = (
  { x, y }: Coordinates,
  scale: number,
): Coordinates => ({
  x: Math.round(x * scale),
  y: Math.round(y * scale),
});

/**
 * Calculate needed scale to get actual dimension to target dimension
 */
const getScale = (actual: number, target: number) => target / actual;

/**
 * Given dimensions of design and a print area, find coordinates and scale to cover the print area
 */
const getStartingCoordinates = (
  asset: Dimensions,
  printArea: Dimensions,
  fill: "contain" | "cover" = "cover",
): ScaledAsset => {
  // Find difference between asset and print area, positive indicates asset is larger than area
  const xDelta = asset.width - printArea.width;
  const yDelta = asset.height - printArea.height;

  let dimension: null | "width" | "height" = null;
  if (xDelta > 0 && yDelta > 0) {
    // dimensions are both larger than print area, so scale down by the smaller delta
    dimension = Math.abs(xDelta) < Math.abs(yDelta) ? "width" : "height";
  } else if (xDelta < 0 && yDelta < 0) {
    // dimensions are both smaller than print area, so scale up by the larger delta
    dimension = Math.abs(xDelta) > Math.abs(yDelta) ? "width" : "height";
  } else if (xDelta < 0) {
    // width is smaller but height is fine, scale up to fit width
    dimension = "width";
  } else if (yDelta < 0) {
    // height is smaller but width is fine, scale up to fit height
    dimension = "height";
  }

  if (fill !== "cover") {
    // flip the dimension to contain the asset instead of cover the area
    dimension = dimension === "width" ? "height" : "width";
  }

  const scale = !dimension
    ? 1
    : getScale(asset[dimension], printArea[dimension]);

  const dimensions = getScaledDimensions(asset, scale);

  // center image according to scaled dimensions
  const coordinates = getCoordinatesToCenter(dimensions, printArea, "both");

  return { scale, dimensions, coordinates };
};

/**
 * Calculate the area's scale according to the available size of its wrapper
 */
const getAreaScale = (area: Dimensions, wrapper?: HTMLDivElement | null) => {
  if (!wrapper) {
    return { scale: 1, dimensions: area };
  }
  // Find width/height of its content area to calculate scale, with some padding
  const scaleByWidth = getScale(
    area.width,
    wrapper.offsetWidth * WRAPPER_COVERAGE,
  );
  const scaleByHeight = getScale(
    area.height,
    wrapper.offsetHeight * WRAPPER_COVERAGE,
  );

  // Then find the smallest of the two, but limit it to a 1:1 ratio
  const scale = Math.min(1, Math.min(scaleByHeight, scaleByWidth));
  const dimensions = getScaledDimensions(area, scale);

  // Set the wrapper's CSS variable so that it knows how much "padding" there is in each dimension
  wrapper.style.setProperty(
    "--wrapper-padding-w",
    `${(wrapper.offsetWidth - dimensions.width) / 2}px`,
  );
  wrapper.style.setProperty(
    "--wrapper-padding-h",
    `${(wrapper.offsetHeight - dimensions.height) / 2}px`,
  );

  return { scale, dimensions };
};

const initialAssetState: ScaledAssetState = {
  coordinates: { x: 0, y: 0 },
  dimensions: { width: 0, height: 0 },
  original: { width: 0, height: 0 },
  scale: 1,
  url: "",
};

const isCovered = (
  area: Dimensions,
  asset: Dimensions,
  { x, y }: Coordinates,
) =>
  x <= 0 &&
  y <= 0 &&
  x + asset.width >= area.width &&
  y + asset.height >= area.height;

/**
 * Return preferred number if it is within bounds, otherwise return min or max
 */
const clamp = (min: number, preferred: number, max: number) =>
  Math.max(min, Math.min(preferred, max));

type DesignPreviewMethods = {
  getState: () => {
    file?: File;
    url?: string;
    asset: ScaledAsset;
    printArea: Dimensions;
  };
};

export type { DesignPreviewMethods };
export { DesignPreview };
