import styled from "@emotion/styled";
import { preventInvalidForwarding } from "styles/styleUtils";

const containerWidths = {
  xs: 640,
  sm: 900,
  md: 1140,
  lg: 1440,
  xl: 1600,
};

type ContainerProps = {
  width?: keyof typeof containerWidths;
};

/**
 * Automatically centered content without top or bottom spacing
 *
 * Use the `width` prop to control size centered with automatic margins. If top/bottom spacing is
 * required to fit within a Layout component, use `SiteContainer` instead. A `Container` can be
 * nested inside a `SiteContainer` to create a skinnier section than the rest of the page.
 */
const Container = styled("div", {
  shouldForwardProp: preventInvalidForwarding("width"),
})<ContainerProps>(({ width = "md", theme }) => ({
  paddingLeft: theme.spacing.gutter,
  paddingRight: theme.spacing.gutter,
  marginLeft: "auto",
  marginRight: "auto",
  maxWidth: containerWidths[width],
  width: "100%",
  ".Container": {
    paddingLeft: "0 !important",
    paddingRight: "0 !important",
  },
}));
Container.defaultProps = { className: "Container" };

/**
 * The outermost container on a page with vertical spacing for Layout components
 *
 * See `Container` for more usage notes.
 */
const SiteContainer = styled(Container)(({ theme }) => ({
  paddingTop: "3rem",
  paddingBottom: "6rem",
  paddingLeft: theme.spacing.md,
  paddingRight: theme.spacing.md,
  "@media (min-width: 600px)": {
    paddingTop: "4rem",
    paddingLeft: theme.spacing.xl,
    paddingRight: theme.spacing.xl,
  },
  "@media (min-width: 830px)": {
    paddingTop: "5rem",
  },
}));
SiteContainer.defaultProps = {
  width: "lg",
  className: "Container SiteContainer",
};

export { Container, SiteContainer, containerWidths };
