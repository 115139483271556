// import { isStaging } from "config";
import type { Chain } from "wagmi/chains";
import { type Address, getAddress, UserRejectedRequestError } from "viem";
import { ConnectorNotFoundError, type WindowProvider } from "wagmi";
import { InjectedConnector } from "wagmi/connectors/injected";

const options = {
  name: "OKX Wallet",
  shimDisconnect: true,
  getProvider() {
    function getOkxWalletProvider(ethereum?: WindowProvider) {
      const isOkxWallet = !!ethereum?.isOkxWallet;
      if (!isOkxWallet) {
        return undefined;
      }
      return ethereum;
    }

    // No injected provider during SSR (i.e. no window object)
    if (typeof window === "undefined") {
      return undefined;
    }

    // See if injected provider is on global window object
    const { ethereum, okxwallet } = window as unknown as {
      ethereum?: WindowProvider;
      okxwallet?: WindowProvider;
    };

    // In case there are multiple providers, find the one we want
    if (ethereum?.providers) {
      return ethereum.providers.find(getOkxWalletProvider);
    }
    // Otherwise, see if the lone provider is the one we want
    return getOkxWalletProvider(okxwallet ?? ethereum);
  },
};

// const handleConnectorNotFound = async () => {
//   // NOTE: this deep link will open the okx wallet mobile app if the user is on a regular
//   // mobile browser. It can only point toward generated deep links, so check if environment
//   // is production or staging; we can't redirect in dev environment
//   const deepLink = `okx://wallet/dapp/details?dappUrl=https://${isStaging ? "thesecrethug" : "thehug"
//     }.xyz/sign-in`;
//   window.location.assign(deepLink);

//   // HACK: We might be on a device that doesn't have app/extension installed, so wait a second.
//   // If this code is still running, then the previous line failed and we're still on thehug.xyz
//   // Try directing them to okx website to download extension just like metamask onboarding. There
//   // isn't a way to try/catch the location.assign() error for not having a registered handler for
//   // the `okx://` scheme
//   // eslint-disable-next-line no-promise-executor-return
//   await new Promise((resolve) => setTimeout(resolve, 1000));
//   const openHandler = window.open("https://okx.com/web3");
//   if (!openHandler) {
//     window.location.assign("https://okx.com/web3")
//   }
// }

class OkxWalletConnector extends InjectedConnector {
  readonly id = "okxwallet";

  // readonly ready: boolean;

  protected shimDisconnectKey = `${this.id}.shimDisconnect`;

  constructor({ chains }: { chains: Chain[] }) {
    super({ chains, options });

    // const provider = options.getProvider();
    // this.ready = true;
  }

  async connect({ chainId }: { chainId?: number } = {}) {
    try {
      const provider = await this.getProvider();
      if (!provider) {
        throw new ConnectorNotFoundError();
      }

      provider.on("accountsChanged", this.onAccountsChanged);
      provider.on("chainChanged", this.onChainChanged);
      provider.on("disconnect", this.onDisconnect);

      this.emit("message", { type: "connecting" });

      // Attempt to show wallet select prompt when disconnected and `shimDisconnect` is true
      let account: Address | null = null;
      if (!this.storage?.getItem(this.shimDisconnectKey)) {
        account = await this.getAccount().catch(() => null);

        if (account) {
          try {
            // Attempt to show another wallet select prompt if already connected
            await provider.request({
              method: "wallet_requestPermissions",
              params: [{ eth_accounts: {} }],
            });
            // Re-validate in case new wallet was selected
            account = await this.getAccount();
          } catch (error) {
            // Only bubble up error if user rejects request
            if (this.isUserRejectedRequestError(error)) {
              throw new UserRejectedRequestError(error as Error);
            }
          }
        }
      }

      if (!account) {
        const accounts = await provider.request({
          method: "eth_requestAccounts",
        });
        account = getAddress(accounts[0]);
      }

      // Switch chains if needed
      let id = await this.getChainId();
      let unsupported = this.isChainUnsupported(id);
      if (chainId && id !== chainId) {
        const chain = await this.switchChain(chainId);
        id = chain.id;
        unsupported = this.isChainUnsupported(id);
      }

      // Update storage with disconnect key
      this.storage?.setItem(this.shimDisconnectKey, true);

      return { account, chain: { id, unsupported }, provider };
    } catch (error) {
      if (this.isUserRejectedRequestError(error)) {
        throw new UserRejectedRequestError(error as Error);
      }
      throw error;
    }
  }
}

export { OkxWalletConnector };
