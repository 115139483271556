import styled from "@emotion/styled";
import { parseGid } from "@shopify/hydrogen-react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  AllCaps,
  Box,
  Button,
  Checkbox,
  Container,
  FakeLabel,
  FieldError,
  Link,
  LoadingSpinner,
  Modal,
  RequiredText,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import {
  acceptTypes,
  FileInput,
  GenericImage,
  HiddenInput,
  parseS3URL,
  useFileUpload,
} from "features/images";
import { type GalleryItem, SelectFromPortfolio } from "features/gallery";
import { useToggle } from "utils/hooks";
import { fieldOptions } from "utils/form";
import { paths } from "utils/paths";
import { removeFileExt } from "utils/text";
import {
  type CreateProductReq,
  useCreateProduct,
} from "../../api/useCreateProduct";
import type { CreateStepProps } from "../../types";
import { ProductWizardTemplate } from "../ProductWizardTemplate";
import { ProductWizardFooter } from "../ProductWizardStep";
import {
  TextColumn,
  UploadBox,
  Columns,
  UploadButtons,
} from "./CreateStepUploadArtwork";

type UploadArtworkForm = CreateProductReq & { affirm: boolean };

function CreateStepShipItYourself({
  artistId,
  productTemplate,
}: CreateStepProps) {
  const navigate = useNavigate();

  const uploadMutation = useFileUpload();
  const productMutation = useCreateProduct();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
    watch,
  } = useForm<UploadArtworkForm>({
    defaultValues: {
      artistId,
      templateId: productTemplate.id,
    },
  });

  const [isSelecting, toggleIsSelecting] = useToggle(false);

  const assetUrl = watch("assetUrl");

  // Once a file is selected, upload it
  const onChange = (file: File) =>
    uploadMutation.mutate(
      { file },
      {
        onSuccess: ({ url }) => {
          setValue("assetUrl", url, { shouldDirty: true, shouldTouch: true });
          setValue("productName", removeFileExt(file.name));
        },
      },
    );

  const reset = () => {
    setValue("assetUrl", "");
    setValue("productName", "");
  };

  const acceptType = "image";

  // Once a gallery item is selected, update the form
  const onSelectionChange = ([item]: GalleryItem[]) => {
    if (item?.image) {
      // For Some Gallery Items - item.image.videoUrl is a empty string
      const newImageUrl = item.image.videoUrl?.length
        ? item.image.videoUrl
        : item.image.url;
      setValue("assetUrl", newImageUrl, {
        shouldDirty: true,
        shouldTouch: true,
      });
      setValue("productName", item.title);
      toggleIsSelecting();
    }
  };

  // Create product after validation
  const onSubmit = handleSubmit(({ affirm: _affirm, ...formData }) =>
    productMutation.mutate(formData, {
      onSuccess: ({ shopifyProductId }) => {
        // On successful product creation, navigate to Product Wizard to configure it
        navigate(
          `${paths.artistShopProductEdit(artistId, parseGid(shopifyProductId).id)}?new=true`,
        );
      },
    }),
  );

  // Merge states of both mutations to simplify UI logic
  const isLoading = uploadMutation.isLoading || productMutation.isLoading;
  const isError = uploadMutation.isError || productMutation.isError;
  const error = uploadMutation.error ?? productMutation.error;

  // Prevent videos from being selected for Art Prints
  // TODO: allow videos to be selected for onchain assets when we add support for viewing them
  // const filterPortfolioItems = isOnchainAsset
  //   ? undefined
  //   : (item: GalleryItem) => !item.image?.videoUrl;
  const filterPortfolioItems = (item: GalleryItem) => !item.image?.videoUrl;

  return (
    <Form onSubmit={onSubmit}>
      <ProductWizardTemplate
        title="Product Builder"
        subtitle="Upload Product Image"
        artistId={artistId}
      >
        <Container width="md">
          <Box fullWidth>
            <Stack gap="md">
              <Stack gap="xxs">
                <Text size="sm" bold style={{ color: "#262626" }}>
                  Featured Product Image
                  <RequiredText />
                </Text>
                <Text size="xs">
                  Upload the featured image of the physical product that you
                  would like to sell and ship directly to the customer. You will
                  be able to upload additional images in subsequent steps to
                  showcase different angles, details, and/or lifestyle images of
                  your product.
                  <br />
                  <Link href={paths.shopHelp} target="_blank">
                    Learn more about selling and shipping physical products
                    yourself.
                  </Link>
                </Text>
              </Stack>

              <Columns>
                <UploadBox>
                  {assetUrl ? (
                    <Stack gap="xs">
                      <GenericImage
                        src={parseS3URL(assetUrl)}
                        alt=""
                        sizes="300px"
                      />
                      {uploadMutation.data?.file.name && (
                        <Text size="xxs">{uploadMutation.data.file.name}</Text>
                      )}

                      <Button
                        size="xxs"
                        variant="blank"
                        onClick={reset}
                        style={{ borderBottomColor: "#262626" }}
                      >
                        Replace Image
                      </Button>
                    </Stack>
                  ) : (
                    <UploadButtons>
                      <Stack>
                        <FileInput
                          text="Upload Image"
                          onChange={onChange}
                          accept={acceptType}
                          hideRequirements
                        />
                        <HiddenInput
                          {...register("assetUrl", fieldOptions.required)}
                        />
                        {errors.assetUrl && (
                          <FieldError>You need to select an image.</FieldError>
                        )}
                      </Stack>
                    </UploadButtons>
                  )}
                </UploadBox>
                <TextColumn>
                  <Stack gap="sm">
                    {!assetUrl && (
                      <Stack gap="xxs">
                        <FakeLabel>Image Guidelines</FakeLabel>
                        <Stack gap="xxs">
                          <Text size="xs">
                            Supported file formats:{" "}
                            {acceptTypes[acceptType].labels.join(", ")}
                            <br />
                            Recommended aspect ratio: 1:1 (Square)
                            <br />
                            Maximum file size: 10MB
                          </Text>
                        </Stack>
                      </Stack>
                    )}
                    <Stack gap="xxs">
                      <FakeLabel>
                        Confirm <abbr title="Intellectual Property">IP</abbr>{" "}
                        Ownership <RequiredText />
                      </FakeLabel>

                      <Checkbox
                        {...register("affirm", fieldOptions.required)}
                        label={
                          <Text
                            as="span"
                            size="xs"
                            style={{
                              display: "inline-block",
                              lineHeight: 1.2,
                              textWrap: "balance",
                            }}
                          >
                            I confirm that I created and own the intellectual
                            property associated with this media and product, and
                            that it does not violate <AllCaps>Hug</AllCaps>
                            &apos;s{" "}
                            <Link to="/terms-of-service" target="_blank">
                              Terms of Service
                            </Link>
                          </Text>
                        }
                      />
                      {errors.affirm && (
                        <FieldError>{errors.affirm.message}</FieldError>
                      )}
                    </Stack>
                  </Stack>

                  {isError && <UnexpectedError error={error} />}
                </TextColumn>
              </Columns>
            </Stack>
          </Box>
          {isLoading && (
            <LoadingSpinner
              text={uploadMutation.isLoading ? "Uploading" : "Creating Product"}
            />
          )}
        </Container>
      </ProductWizardTemplate>

      <Modal isOpen={isSelecting} onClose={toggleIsSelecting}>
        <SelectFromPortfolio
          artistId={artistId}
          onChange={onSelectionChange}
          filter={filterPortfolioItems}
        />
      </Modal>

      <ProductWizardFooter>
        <Button
          variant="secondary"
          size="xs"
          onClick={() => window.location.reload()}
        >
          Back
        </Button>
        <Button size="xs" type="submit" disabled={isLoading}>
          Next
        </Button>
      </ProductWizardFooter>
    </Form>
  );
}

const Form = styled.form({
  minHeight: "80vh",
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});

export { CreateStepShipItYourself };
