import * as React from "react";
import styled from "@emotion/styled";
import type { CartLineInput } from "@shopify/hydrogen-react/storefront-api-types";
import { Button } from "atoms";
import { trackEvent } from "contexts/mixpanel";
import { useCart } from "@shopify/hydrogen-react";
import type { VariantNode } from "../api/useProductQuery";

type AddToCartProps = {
  /** An array of cart line attributes that belong to the item being added to the cart. */
  attributes?: {
    key: string;
    value: string;
  }[];
  /** The selected variant of the product. */
  productVariant?: VariantNode;
  /** The item quantity. */
  quantity?: number;
  /** Any potential line items to be added along as product upsells */
  upsells?: CartLineInput[];
  /** Product Type for Mix Panel Tracking */
  productType?: string;
  /** Edition Type for Mix Panel Tracking */
  editionType?: string;
  /** Template ID */
  templateId?: string;
  /** Preview Mode */
  isPreview?: boolean;
} & Omit<React.ComponentProps<typeof Button>, "children" | "onClick">;

function AddToCart({
  attributes,
  quantity = 1,
  productVariant,
  productType,
  editionType,
  templateId,
  upsells = [],
  isPreview,
  ...props
}: AddToCartProps) {
  const { status, linesAdd } = useCart();

  const [isLoading, setIsLoading] = React.useState(false);

  React.useEffect(() => {
    if (isLoading && status === "idle") {
      setIsLoading(false);
    }
  }, [isLoading, status, setIsLoading]);

  const onClick = () => {
    if (!productVariant) {
      return;
    }

    setIsLoading(true);
    trackEvent({
      name: "AddToCartShopify",
      variantId: productVariant.id,
      productType,
      editionType,
      templateId,
      price: parseFloat(productVariant?.price?.amount ?? "0"),
    });
    linesAdd([
      // Attach upsells to the product variant added to the cart at time of adding. Otherwise, user
      // could select a different variant after selecting the upsell leading to a mismatch.
      ...upsells.map((line) => ({
        ...line,
        attributes: [
          // Add product's line item attributes (e.g. artistId) to upsell in addition to the upsell's
          // own attributes, if any
          ...(attributes ?? []),
          ...(line.attributes ?? []),
          { key: "_upsellFor", value: productVariant.id },
        ],
      })),
      {
        attributes,
        quantity,
        merchandiseId: productVariant.id,
      },
    ]);
  };

  const disabled =
    !productVariant ||
    !productVariant.availableForSale ||
    isLoading ||
    props.disabled;

  const availableForSale = isPreview || productVariant?.availableForSale;

  return (
    <SButton {...props} disabled={disabled} onClick={onClick}>
      {availableForSale ? "Add to Cart" : "Out of Stock"}
    </SButton>
  );
}

const SButton = styled(Button)({
  paddingBlock: 12,
  fontSize: 18,
});

export { AddToCart };
