import styled from "@emotion/styled";

const Label = styled.label<{ inline?: boolean }>(({ inline, theme }) => ({
  ...theme.fonts.body,
  color: theme.colors.fg,
  display: "block",
  fontSize: ".875rem",
  fontWeight: "700",
  lineHeight: 1.2,
  width: "auto",
  "&>small": {
    color: theme.colors.bodyColor,
    display: "block",
    fontSize: ".8rem",
  },
  "&>small, &>span": {
    fontWeight: "normal",
    a: {
      fontSize: "inherit",
    },
  },
  ...(inline && {
    alignItems: "center",
    borderRadius: theme.borderRadius.round,
    border: `2px solid ${theme.colors.fg}`,
    display: "flex",
    flex: "2 0 300px",
    paddingLeft: "1rem",
    paddingRight: ".5rem",
    minHeight: 50,
    "&>p, &>span": {
      margin: "0 1rem 0 0",
      position: "relative",
      lineHeight: 1.875,
      fontSize: 16,
      color: theme.colors.fg,
      "&::after": {
        content: "''",
        position: "absolute",
        inset: "1px -.75rem 1px auto",
        width: 1,
        backgroundColor: theme.colors.fg,
        borderRadius: theme.borderRadius.round,
      },
    },
    input: {
      margin: 0,
      border: `2px solid ${theme.colors.transparent}`,
      backgroundColor: theme.colors.transparent,
      paddingTop: 5,
      paddingBottom: 5,
      paddingLeft: ".5rem",
      paddingRight: 0,
      minHeight: 0,
      "&:focus-visible": {
        outline: 0,
      },
    },
    "&>div:last-child": {
      margin: "0 0 0 1rem",
      select: {
        border: 0,
      },
    },
    "form &+button": {
      flex: 1,
      minHeight: 50,
    },
  }),
  "&:has(.is-error)": {
    color: theme.colors.error,
    "input, textarea, select": {
      borderBottomColor: theme.colors.error,
      boxShadow: `0 1px 0 0 ${theme.colors.error}`,
    },
    'label [type="radio"], label [type="checkbox"]': {
      borderColor: theme.colors.error,
      boxShadow: "none",
    },
  },
}));

const Fieldset = styled(Label)<{ hasBorder?: boolean }>(
  ({ hasBorder, theme }) => ({
    "&>*+*": {
      marginTop: 6,
    },
    legend: {
      marginBottom: 0,
    },
    label: {
      "&>p, &>span": {
        fontSize: theme.fontSizes.xxs,
        marginBottom: 0,
        fontWeight: "bold",
        "&+*": {
          marginTop: ".125rem",
        },
      },
    },
    "input, textarea, select": {
      marginTop: 0,
    },
    ...(hasBorder && {
      backgroundColor: "inherit",
      border: "1px solid",
      borderColor: theme.colors.fg20,
      borderRadius: theme.borderRadius.sm,
      paddingBottom: "1rem",

      legend: {
        backgroundColor: "inherit",
        marginBottom: 0,
        padding: "0 .5rem",
        marginLeft: ".5rem",
      },
      "&>*:not(legend)": {
        padding: "0 1rem",
      },
    }),
  }),
).withComponent("fieldset");

type RequiredProps = {
  text?: string;
};

function RequiredText({ text = "*", ...props }: RequiredProps) {
  return (
    <RequiredTextElement aria-label="Required field" {...props}>
      {text}
    </RequiredTextElement>
  );
}

const RequiredTextElement = styled.span(({ theme }) => ({
  color: theme.colors.error,
}));

const FakeLabel = styled(Label)({
  marginBottom: ".5rem",
}).withComponent("div");

export { Fieldset, Label, FakeLabel, RequiredText };
