import styled from "@emotion/styled";

const Box = styled.div<{
  border?: boolean;
  fullWidth?: boolean;
  small?: boolean;
}>(({ border, fullWidth, small, theme }) => ({
  backgroundColor: theme.colors.bg,
  border: border ? `1px solid ${theme.colors.fg20}` : 0,
  borderRadius: theme.borderRadius.sm,
  boxShadow: border ? "none" : theme.boxShadow.light,
  padding: small ? theme.spacing.md : theme.spacing.gap,
  overflow: "hidden",
  flex: 1,
  ...(fullWidth && {
    padding: theme.spacing.gutter,
    [theme.breakpoints.maxTablet]: {
      border: 0,
      borderRadius: 0,
      boxShadow: "none",
      marginLeft: `calc(${theme.spacing.gutter} * -1)`,
      marginRight: `calc(${theme.spacing.gutter} * -1)`,
      width: `calc(100% + (${theme.spacing.gutter} * 2))`,
    },
  }),
}));

export { Box };
