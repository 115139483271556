import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import {
  Alert,
  Box,
  Button,
  FadeIn,
  FlexRow,
  IconEditItem,
  IconEyeClosed,
  IconEyeOpen,
  IconTrashCan,
  ScreenReaderText,
  Stack,
  Text,
} from "atoms";
import { useToggle } from "utils/hooks";
import { useDeleteDraftProduct } from "../api/useProductDeleteDraft";
import { useUpdateProductDetails } from "../api/useUpdateProductDetails";

type ProductCardEditActionsProps = {
  status?: string;
  productId: string;
  artistId: string;
  onEditSuccess?: (msg: string) => void;
};

function ProductCardEditActions({
  status,
  productId,
  artistId,
  onEditSuccess = noop,
}: ProductCardEditActionsProps) {
  const isPublished = status === "ACTIVE";
  const isDraft = status === "DRAFT";
  const isUnlisted = status === "ARCHIVED";

  const deleteMutation = useDeleteDraftProduct(productId, artistId);
  const updateMutation = useUpdateProductDetails(productId);

  const archiveProduct = () =>
    updateMutation.mutate(
      { status: "ARCHIVED" },
      {
        onSuccess: () => onEditSuccess("Product archived"),
      },
    );
  const deleteProduct = () =>
    deleteMutation.mutate(undefined, {
      onSuccess: () => onEditSuccess("Product deleted"),
    });
  const publishProduct = () =>
    updateMutation.mutate(
      { status: "ACTIVE" },
      {
        onSuccess: () => onEditSuccess("Product published"),
      },
    );

  const anyIsLoading = deleteMutation.isLoading || updateMutation.isLoading;
  const anyIsError = deleteMutation.isError || updateMutation.isError;

  const [showDeletePrompt, toggleShowDeletePrompt] = useToggle(false);

  return (
    <>
      <Scrim status={status} isLoading={anyIsLoading} />

      {anyIsError && (
        <SAlert type="error" duration={5}>
          Error <span>{deleteMutation.isError ? "deleting" : "updating"}</span>{" "}
          product.
        </SAlert>
      )}

      <EditButtons>
        <FakeEditButton
          isLoading={anyIsLoading}
          aria-hidden
          className="ProductCardEditButton"
        >
          <IconEditItem accent="fg70" />
        </FakeEditButton>

        {isUnlisted && (
          <SButton
            variant="circle"
            onClick={publishProduct}
            disabled={updateMutation.isLoading}
          >
            <ScreenReaderText>Publish product</ScreenReaderText>
            <IconEyeOpen accent="fg70" aria-hidden />
          </SButton>
        )}

        {isPublished && (
          <SButton
            variant="circle"
            onClick={archiveProduct}
            disabled={updateMutation.isLoading}
          >
            <ScreenReaderText>Archive product</ScreenReaderText>
            <IconEyeClosed accent="fg70" aria-hidden />
          </SButton>
        )}

        {isDraft && (
          <SButton
            variant="circle"
            onClick={toggleShowDeletePrompt}
            disabled={deleteMutation.isLoading}
          >
            <ScreenReaderText>Delete Draft</ScreenReaderText>
            <IconTrashCan accent="fg70" aria-hidden />
          </SButton>
        )}
      </EditButtons>

      {showDeletePrompt && (
        <ConfirmBox aria-live="assertive">
          <Stack gap="sm">
            <Text textAlign="center" bold style={{ lineHeight: 1.2 }}>
              Are you sure you want to delete this draft?
            </Text>
            <FlexRow justifyContent="center" gap="10px" flexWrap="nowrap">
              <Button size="xxs" onClick={deleteProduct}>
                Delete
              </Button>
              <Button
                size="xxs"
                variant="secondary"
                onClick={toggleShowDeletePrompt}
              >
                Cancel
              </Button>
            </FlexRow>
          </Stack>
        </ConfirmBox>
      )}
    </>
  );
}

const Scrim = styled.div<{ status?: string; isLoading?: boolean }>(
  ({ isLoading, status, theme }) => ({
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    "&::before, &::after": {
      content: "''",
      position: "absolute",
      top: 0,
      left: 0,
      right: 0,
      zIndex: 9,
      pointerEvents: "none",
    },
    "&::before": {
      backgroundImage:
        "linear-gradient(180deg, #00000080 0%, #00000040 40%, rgba(0,0,0,0) 100%)",
      height: 120,
    },
    ...((status === "ARCHIVED" || isLoading) && {
      "&::after": {
        backgroundColor: theme.colors.bg,
        opacity: 0.66,
        bottom: 0,
        zIndex: isLoading ? 99 : 8,
        pointerEvents: isLoading ? "auto" : "none",
        transition: ".2s ease opacity",
      },
    }),
  }),
);

const EditButtons = styled.div({
  pointerEvents: "none",
  display: "flex",
  justifyContent: "flex-end",
  gap: 10,
  position: "absolute",
  top: 10,
  right: 10,
  zIndex: 10,
});

const SButton = styled(Button)({
  pointerEvents: "auto",
  position: "relative",
  zIndex: 3,
  height: 36,
  width: 36,
  padding: 0,
});

const FakeEditButton = styled.span<{ isLoading?: boolean }>(
  ({ isLoading, theme }) => ({
    pointerEvents: "none",
    position: "relative",
    opacity: isLoading ? 0.5 : 1,
    zIndex: 3,
    height: 36,
    width: 36,
    // styles for circle button
    aspectRatio: "1",
    borderRadius: theme.borderRadius.round,
    color: theme.colors.fg,
    background: theme.colors.bg,
    boxShadow: "none",
    border: 0,
    display: "grid",
    placeItems: "center",
    minHeight: 0,
    padding: 0,
    textDecoration: "none",
    transition: `.2s ease box-shadow`,
    "&:hover, &:focus-visible": {
      transform: "unset",
      color: theme.colors.fg,
      textDecoration: "none",
      borderBottomColor: theme.colors.fg,
      boxShadow: `0 0 0 2px ${theme.colors.bg}, 0 0 0 4px ${theme.colors.fg}`,
    },
    "&:focus-visible": {
      outlineColor: theme.colors.fg,
    },
    "&:active": {
      transform: "unset",
    },
    "&::before": {
      content: "unset",
    },
    svg: {
      width: 18,
      height: 18,
      maxWidth: 18,
      maxHeight: 18,
    },
    [theme.breakpoints.desktop]: {
      padding: ".5rem",
      svg: {
        width: 16,
        height: 16,
        maxWidth: 16,
        maxHeight: 16,
      },
    },
  }),
);

const messageInOut = keyframes({
  "0%, 100%": {
    opacity: 0,
  },
  "7%, 93%": {
    opacity: 1,
    pointerEvents: "auto",
  },
});

const SAlert = styled(Alert)(({ duration }) => ({
  position: "absolute",
  display: "flex",
  alignItems: "center",
  left: 10,
  right: 10,
  bottom: 10,
  animation: `${duration}s ease 0s forwards ${messageInOut}`,
  pointerEvents: "none",
}));

const ConfirmBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.boxShadow.dark,
  padding: "1rem",
  position: "absolute",
  left: 10,
  right: 10,
  top: 10,
  whiteSpace: "nowrap",
  zIndex: 20,
  maxWidth: 400,
})).withComponent(FadeIn);

export { ProductCardEditActions };
