import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { galleryKeys } from "./queryKeys";

/**
 * API to delete a gallery or gallery item
 */
const deleteGalleryItem = ({
  itemId,
  artistId,
  cascade = false,
}: {
  itemId: string;
  artistId?: string;
  cascade?: boolean;
}) => {
  if (!artistId) {
    return Promise.reject(new Error("Invalid artist id"));
  }

  const query = cascade ? `?cascade=true` : "";
  return api.post<{ status: "DELETED" }>(
    `/gallery/artists/${artistId}/${itemId}/delete${query}`,
    {
      body: JSON.stringify({}),
    },
  );
};

/**
 * Mutation to delete a gallery or gallery item
 */
const useGalleryItemDelete = () => {
  const queryClient = useQueryClient();

  return useMutation(deleteGalleryItem, {
    onSuccess: (_, { itemId, artistId }) => {
      // Invalidate galleries in case an item was deleted from within
      queryClient.invalidateQueries(
        galleryKeys.detailOwner("artist", artistId),
      );
      queryClient.invalidateQueries(galleryKeys.lists());
      // Prevent refetching the item we just deleted
      queryClient.cancelQueries(galleryKeys.detail("artist", artistId, itemId));
    },
  });
};

export { useGalleryItemDelete };
