import { useQuery } from "react-query";
import { api } from "utils/api";
import { shopOnChainClaimKeys } from "./queryKeys";
import { ClaimOnChainDataResp } from "../types";

const getOnChainClaimData = (
  artistId?: string,
  fulfillmentLineItemId?: string,
) =>
  api.get<ClaimOnChainDataResp>(
    `/shop/claim-collectible/${artistId}/${fulfillmentLineItemId}`,
  );

const useOnChainClaimData = (
  artistId?: string,
  fulfillmentLineItemId?: string,
  pollForUpdates?: boolean,
) =>
  useQuery(
    shopOnChainClaimKeys.detail(artistId, fulfillmentLineItemId),
    () => getOnChainClaimData(artistId, fulfillmentLineItemId),
    {
      enabled: !!artistId && !!fulfillmentLineItemId,
      refetchInterval: pollForUpdates ? 2500 : undefined,
    },
  );

export { useOnChainClaimData };
