import { ErrorBoundary } from "react-error-boundary";
import Rollbar from "rollbar";
import { Provider } from "@rollbar/react";
import hugLogoSrc from "atoms/icons/HugLogo.svg";
import { constants, envName, isNodeProductionBuild } from "config";
import "./GlobalErrorBoundary.css";

/**
 * Top-most error fallback UI for all uncaught render errors
 *
 * ########     ###    ##    ##  ######   ######## ########
 * ##     ##   ## ##   ###   ## ##    ##  ##       ##     ##
 * ##     ##  ##   ##  ####  ## ##        ##       ##     ##
 * ##     ## ##     ## ## ## ## ##   #### ######   ########
 * ##     ## ######### ##  #### ##    ##  ##       ##   ##
 * ##     ## ##     ## ##   ### ##    ##  ##       ##    ##
 * ########  ##     ## ##    ##  ######   ######## ##     ##
 *
 * NOTE: This component is built to live _outside context providers_. This means no access
 * to query hooks, styled components, internationalization, etc. It will only be shown in
 * worst case scenarios. If possible, try to catch your errors with another boundary closer
 * to your components render tree.
 *
 * React error boundaries will only catch errors during render or effect hooks. You will have
 * to handle errors in async or event handlers with `useErrorBoundary` hook.
 */
function GlobalErrorFallback({ error }: { error: Error }) {
  return (
    <div role="alert" className="GlobalErrorBoundary">
      <img src={hugLogoSrc} alt="Hug logo" />

      <h1>Oh no, we broke something!</h1>

      <p>
        Reload the page and give it another shot. We’ll try better next time.
      </p>

      <button type="button" onClick={() => window.location.reload()}>
        Reload
      </button>

      {!isNodeProductionBuild && <pre>{error.stack}</pre>}
    </div>
  );
}

const rollbarConfig: Rollbar.Configuration = {
  accessToken: constants.rollbarKey,
  environment: envName,
  codeVersion: constants.commitHash,
};

const rollbar = new Rollbar(rollbarConfig);

function GlobalErrorBoundary({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  return (
    <ErrorBoundary FallbackComponent={GlobalErrorFallback}>
      <Provider instance={rollbar}>{children}</Provider>
    </ErrorBoundary>
  );
}

export { GlobalErrorBoundary, rollbar };
