import * as React from "react";
import styled from "@emotion/styled";
import {
  Button,
  FlexRow,
  Form,
  Grid,
  LoadingEllipses,
  LoadingSpinner,
  Modal,
  RequiredText,
  Text,
} from "atoms";
import { type Image } from "features/images";
import { useGalleryItems } from "../api/useGalleryItems";
import { GalleryItem } from "../types";
import { GalleryThumbnail } from "./GalleryCard";

type ThumbnailSelectorProps = {
  cancel: VoidFunction;
  currentImage?: Image;
  gallery: GalleryItem;
  setThumbnail: (img: Image) => void;
};

function ThumbnailSelector({
  cancel,
  currentImage,
  gallery,
  setThumbnail,
}: ThumbnailSelectorProps) {
  const [selection, setSelection] = React.useState(
    currentImage ?? gallery?.image,
  );

  const { data, isLoading } = useGalleryItems({ galleryId: gallery.id });

  // The URL of the image field may change (due to optimization) after the cover image is set
  // If we can match the URL from the cover image to an item, show it first.
  // If we cannot match - don't show the current image first (otherwise there will be a duplicate)
  const images = React.useMemo(() => {
    const items = data?.map((i) => i.image) ?? [];

    // Try to find the current image in that list of items
    const currentGalleryImage = items.find(
      (img) => img && img.url === gallery.image?.url,
    );

    // List of item without the match
    const filteredItems = items.filter(
      (img): img is Image =>
        !!img?.url && img?.url !== currentGalleryImage?.url,
    );

    // If there is a match - show it first. Otherwise just show all
    return currentGalleryImage
      ? [currentGalleryImage, ...filteredItems]
      : filteredItems;
  }, [data, gallery?.image]);

  const submit = () => {
    if (selection) {
      setThumbnail(selection);
    }
  };

  return (
    <Form>
      <Text bold>
        Choose Gallery Cover Image <RequiredText />
      </Text>

      <Text>
        {images.length
          ? "Choose the image to display as the cover of your Gallery. It will display cropped as previewed."
          : "You need to add images to this gallery before you can select a thumbnail for it."}
      </Text>

      {isLoading && <LoadingSpinner />}

      <Grid>
        {images.map((img) => (
          <GridItem
            key={img.url}
            isSelected={!!selection && selection?.url === img.url}
          >
            <GalleryThumbnail src={img} />

            <button type="button" onClick={() => setSelection(img)}>
              Select Image
            </button>
          </GridItem>
        ))}
      </Grid>

      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button onClick={cancel} variant="secondary">
            Go Back
          </Button>

          <Button onClick={submit} disabled={!selection || !images.length}>
            {isLoading ? (
              <>
                Saving
                <LoadingEllipses />
              </>
            ) : (
              "Save Cover Image"
            )}
          </Button>
        </FlexRow>
      </Modal.Footer>
    </Form>
  );
}

const GridItem = styled.div<{ isSelected: boolean }>(
  ({ isSelected, theme }) => ({
    borderRadius: 4,
    outline: "3px solid",
    outlineColor: isSelected ? theme.colors.fb : theme.colors.transparent,
    outlineOffset: 3,
    overflow: "hidden",
    position: "relative",
    "&:after, button": {
      position: "absolute",
      top: 0,
      right: 0,
    },
    button: {
      display: isSelected ? "none" : "block",
      left: 0,
      bottom: 0,
      opacity: 0,
    },
    ...(isSelected && {
      "&:after": {
        display: "block",
        content: "'X'",
      },
    }),
  }),
);

export { ThumbnailSelector };
