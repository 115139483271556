import * as React from "react";
import styled from "@emotion/styled";
import {
  useController,
  FieldPathByValue,
  FieldValues,
  Control,
} from "react-hook-form";
import { Button, FlexRow, IconSearch, Input, Label, List, Text } from "atoms";
import { Thumbnail } from "features/images";
import type { Artist, ArtistAffiliation } from "features/reviewable/types";
import { useDebouncer } from "utils/hooks";
import { useIncrementalSearch } from "../api/useSearch";

type AffiliatedArtistInputProps<TFieldValues extends FieldValues, TPath> = {
  control: Control<TFieldValues>;
  name: TPath;
  editingId?: string;
  typeLabel: string;
};

function AffiliatedArtistInput<
  TFieldValues extends FieldValues,
  TPath extends FieldPathByValue<TFieldValues, ArtistAffiliation[]>,
>({
  control,
  name,
  editingId,
  typeLabel,
}: AffiliatedArtistInputProps<TFieldValues, TPath>) {
  const {
    field: { value, onChange },
  } = useController({ control, name });

  const [searchVal, setSearchVal] = React.useState("");
  const onSearchValChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => setSearchVal(event.target.value);

  // Debounce the input value so we only query once user finishes typing
  const query = useDebouncer(searchVal, 300);

  // Run query and manage state
  const { isFetching, data } = useIncrementalSearch(query, 12);

  // Check if artist id can be added to selections
  const isDisabled = (id: string) =>
    id === editingId ||
    !!(
      value.length &&
      value.find((selection: ArtistAffiliation) => selection.id === id)
    );

  // Create event handler to add new artist to affiliations list
  const createAddHandler = (artist: Artist) => () => {
    const affiliated: ArtistAffiliation = {
      id: artist.id,
      name: artist.name,
      image: artist.heroImage,
    };
    onChange([...value, affiliated]);
  };

  // Create event handler to remove selection from list
  const createRemoveHandler = (selection: ArtistAffiliation) => () => {
    onChange(value.filter((s: ArtistAffiliation) => s.id !== selection.id));
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Wrapper>
      <Results>
        <ListWrapper isLoading={isFetching}>
          <SearchLabel>
            Search and Select {typeLabel}
            <SearchInput
              value={searchVal}
              placeholder="Artist name"
              onChange={onSearchValChange}
              onKeyDown={onKeyDown}
            />
            <IconSearch aria-hidden />
          </SearchLabel>
          {data?.length ? (
            <ScrollList type="blank">
              {data.map((artist) => (
                <ListItem
                  key={artist.id}
                  {...artist}
                  image={artist.heroImage}
                  action={
                    <Button
                      size="xxxs"
                      variant="secondary"
                      onClick={createAddHandler(artist)}
                      disabled={isDisabled(artist.id)}
                    >
                      Add
                    </Button>
                  }
                />
              ))}
            </ScrollList>
          ) : (
            <EmptyList>
              Use the search bar above <br />
              to find {typeLabel}
            </EmptyList>
          )}
        </ListWrapper>
      </Results>

      <Selections>
        <ListWrapper>
          <Heading>Selected {typeLabel}</Heading>
          {value && value.length ? (
            <ScrollList type="blank">
              {value.map((affiliated: ArtistAffiliation) => (
                <ListItem
                  key={affiliated.id}
                  {...affiliated}
                  action={
                    <Button
                      size="xxxs"
                      variant="secondary"
                      onClick={createRemoveHandler(affiliated)}
                    >
                      Remove
                    </Button>
                  }
                />
              ))}
            </ScrollList>
          ) : (
            <EmptyList>No selections</EmptyList>
          )}
        </ListWrapper>
      </Selections>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "grid",
  gap: theme.spacing.gap,
  gridTemplateAreas: `
    "results"
    "selections"`,
  marginTop: ".2rem",
  [theme.breakpoints.desktop]: {
    gridTemplateAreas: `"results selections"`,
    gridTemplateColumns: "repeat(2, 1fr)",
  },
}));

const listPadding = "1rem";

const SearchLabel = styled(Label)(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  borderRadius: `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`,
  margin: 0,
  paddingTop: 10,
  position: "relative",
  textIndent: listPadding,
  svg: {
    position: "absolute",
    bottom: ".875rem",
    left: listPadding,
    zIndex: 4,
  },
}));

const SearchInput = styled(Input)({
  margin: "5px -2px 0",
  border: "2px solid",
  borderTop: 0,
  padding: `.25rem calc(${listPadding} * 2.25)`,
  width: "calc(100% + 4px)",
  maxWidth: "calc(100% + 4px)",
});

const Heading = styled(Label)(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  borderBottom: `2px solid ${theme.colors.fg}`,
  borderRadius: `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`,
  padding: `10px ${listPadding}`,
})).withComponent("div");

const Results = styled.div({
  gridArea: "results",
});

const Selections = styled.div({
  gridArea: "selections",
});

const ListWrapper = styled.div<{ isLoading?: boolean }>(
  ({ isLoading = false, theme }) => ({
    border: `2px solid ${theme.colors.fg}`,
    borderRadius: theme.borderRadius.sm,
    display: "grid",
    gridTemplateRows: "auto 1fr",
    filter: isLoading ? "grayscale(1)" : undefined,
    opacity: isLoading ? 0.66 : 1,
    height: "calc(18rem + 10vh)",
    maxHeight: "80vh",
  }),
);

const ScrollList = styled(List)({
  overflowY: "auto",
  padding: listPadding,
});

const EmptyList = styled(Text)(({ theme }) => ({
  color: theme.colors.fg60,
  fontStyle: "italic",
  margin: "1em auto",
  fontSize: theme.fontSizes.xs,
  textAlign: "center",
}));

const StyledListItem = styled(FlexRow)(({ theme }) => ({
  gap: "0.5rem",
  paddingBottom: "0.5rem",
  "& + &": {
    borderTop: `1px solid ${theme.colors.fg20}`,
    paddingTop: "0.5rem",
  },
  "div:nth-of-type(1)": { width: 40, height: 40 },
  "div:nth-of-type(2)": { flex: 1, lineHeight: 1.2 },
  span: { display: "block", margin: 0 },
  "span:last-of-type": { color: theme.colors.fg60, flexGrow: 1 },
  img: {
    width: 40,
    height: 40,
    flex: "0 0 40px",
    maxHeight: 40,
    maxWidth: 40,
  },
})).withComponent("li");

type ListItemProps = ArtistAffiliation & {
  action: React.ReactNode;
};

function ListItem({ action, name, image }: ListItemProps) {
  return (
    <StyledListItem>
      <Thumbnail src={image} alt="" shape="circle" />

      <div>
        <Text as="span" size="xxs" bold>
          {name}
        </Text>

        <Text as="span" size="xxxs">
          artist
        </Text>
      </div>

      {action}
    </StyledListItem>
  );
}

export { AffiliatedArtistInput };
