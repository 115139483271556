import styled from "@emotion/styled";
import {
  AppError,
  AppSuccess,
  Button,
  FlexRow,
  Link,
  PasswordInput,
} from "atoms";
import { fieldOptions } from "utils/form";
import { SForm } from "./EmailSignIn";
import { useUpdatePasswordForm } from "../api/useUpdatePasswordForm";
import { User } from "../types";

type UpdatePasswordProps = {
  showCancel?: boolean;
  user: User;
};

function UpdatePassword({ user, showCancel = true }: UpdatePasswordProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    mutation: { mutate, isSuccess, isLoading, error },
  } = useUpdatePasswordForm({ defaultValues: { email: user?.email } });

  // Watch entered password to validate with confirmation
  const enteredPwd = watch("password");

  const onSubmit = handleSubmit((fields) => mutate(fields));

  return !isSuccess ? (
    <SForm onSubmit={onSubmit}>
      <HiddenInput type="text" {...register("email")} autoComplete="username" />

      <PasswordInput
        register={register}
        fieldName="password"
        error={errors.password}
        label="Create New Password"
        autoComplete="new-password"
      />

      <PasswordInput
        register={register}
        fieldName="confirm"
        error={errors.password}
        label="Confirm New Password"
        autoComplete="new-password"
        registerOptions={{
          ...fieldOptions.required,
          validate: (confirmation) =>
            confirmation === enteredPwd || "Your passwords do not match",
        }}
        showReqs={false}
      />

      {error instanceof Error && (
        <AppError className="AppError">{error.message}</AppError>
      )}

      <FlexRow justifyContent="center">
        <Button type="submit" disabled={isLoading} size="sm">
          Save Password
        </Button>

        {showCancel && (
          <Link to="/profile" variant="secondary" size="sm">
            Cancel
          </Link>
        )}
      </FlexRow>
    </SForm>
  ) : (
    <AppSuccess>
      Success!
      <br />
      Your password has been updated.
    </AppSuccess>
  );
}

const HiddenInput = styled.input({
  border: "none",
  height: 1,
  margin: 0,
  padding: 0,
  position: "absolute",
  left: -9999,
  width: 1,
});

export { UpdatePassword };
