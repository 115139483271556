import type { Product } from "@shopify/hydrogen-react/storefront-api-types";

type PartialProduct = Pick<Product, "priceRange" | "compareAtPriceRange">;

/**
 * Determine if a product (or any of its variants) are on sale
 *
 * NOTE: the compare price may be "0.0" instead of null to show no compare price, unfortunately...
 * */
const isOnSale = ({
  priceRange: price,
  compareAtPriceRange: compare,
}: PartialProduct) =>
  compare.minVariantPrice.amount !== "0.0" &&
  compare.maxVariantPrice.amount !== "0.0" &&
  (price.minVariantPrice.amount !== compare.minVariantPrice.amount ||
    price.maxVariantPrice.amount !== compare.maxVariantPrice.amount);

export { isOnSale };
