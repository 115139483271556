import isPropValid from "@emotion/is-prop-valid";

/**
 * Helper function to add classNames to a component
 *
 * Pass in any number of strings or objects. If using object syntax, this will filter out any falsy
 * values and apply the remaining keys as the classNames which allows helpful shortcuts like:
 * ```
 * cx("FormButton", { disabled: isLoading })
 * //-> "FormButton disabled" if isLoading is true, else "FormButton"
 * cx({ isShowing })
 * //-> "isShowing" if isShowing is in scope and is true, else ""
 * ```
 */
const cx = (...args: (string | Record<string, unknown>)[]) =>
  args
    .reduce<string[]>((acc, cur) => {
      if (typeof cur === "string") {
        return [...acc, cur];
      }
      return [
        ...acc,
        ...Object.entries(cur)
          .filter(([_, val]) => !!val)
          .map(([key]) => key),
      ];
    }, [])
    .join(" ");

/**
 * Prevent forwarding a given list of props to underlying component
 *
 * Helper function for Emotion's styled component `shouldForwardProp` method. Pass in all the prop
 * names that should NOT be forwarded to produce curried function for Emotion.
 * See: https://emotion.sh/docs/styled#customizing-prop-forwarding
 */
const preventForwarding =
  <TProps extends Record<string, unknown>>(...props: (keyof TProps)[]) =>
  (propName: string): boolean =>
    !props.map((p) => p.toString()).includes(propName.toString());

/**
 * Prevent forwarding a given list of props AND any invalid props to underlying component
 *
 * Helper function for Emotion's styled component `shouldForwardProp` method. Pass in all the prop
 * names that should NOT be forwarded to produce curried function for Emotion.
 * See: https://emotion.sh/docs/styled#customizing-prop-forwarding
 */
const preventInvalidForwarding =
  <TProps extends Record<string, unknown>>(...props: (keyof TProps)[]) =>
  (propName: string): boolean =>
    isPropValid(propName) &&
    !props.map((p) => p.toString()).includes(propName.toString());

/**
 * Convert RGB values to Hex, e.g. rgb(0, 0, 0) to #000000
 */
const rgbToHex = (rgb: string): string => {
  // Grab each individual value out of the rgb(0,0,0) string
  const components = rgb
    .slice(rgb.indexOf("(") + 1, rgb.indexOf(")"))
    .split(",");
  if (components.length < 3) {
    throw new Error("rgbToHex expected valid rgb(xxx, xxx, xxx) value");
  }
  // Using the first 3 strings, trim whitespace, convert it to a hex value, make it 2 characters
  // and concatenate it all together with an initial hash sign
  return `#${components
    .slice(0, 3)
    .map((c) => Number(c.trim()).toString(16).padStart(2, "0"))
    .join("")}`;
};

const getRGBComponents = (hex: string) => {
  const rgb = hexToRGB(hex);
  return rgb
    .slice(rgb.indexOf("(") + 1, rgb.indexOf(")"))
    .split(",")
    .map((c) => Number(c.trim()));
};

/**
 * Convert Hex values to RGB, e.g. #000000 to rgb(0, 0, 0), with optional alpha
 */
const hexToRGB = (hex: string, alpha?: number): string => {
  // Remove hash sign and duplicate components if string is in #000 format
  const val =
    hex.length === 7
      ? hex.slice(1)
      : hex
          .slice(1)
          .split("")
          .map((c) => c + c)
          .join("");
  // Parse each component into a string of hex values: "0, 0, 0"
  const parse = (s: string) => parseInt(s, 16);
  const components = `${parse(val.slice(0, 2))}, ${parse(
    val.slice(2, 4),
  )}, ${parse(val.slice(4, 6))}`;
  // Add the components into the full string including the optional transparency value
  return alpha !== undefined
    ? `rgba(${components}, ${alpha})`
    : `rgb(${components})`;
};
const opaqueHexOpacity = (fg: string, bg: string, opacity: number) => {
  // get rgb value to do math on decimal numbers, not hex
  const fgComponents = getRGBComponents(fg);
  const bgComponents = getRGBComponents(bg);

  // calculate difference in component value on a white background
  const opaqueComponents = fgComponents.map((c, i) =>
    Math.round(bgComponents[i] - opacity * (bgComponents[i] - c)),
  );

  // rebuild hex string
  return `#${opaqueComponents
    .map((o) => o.toString(16).padStart(2, "0"))
    .join("")}`;
};

export {
  cx,
  preventForwarding,
  preventInvalidForwarding,
  rgbToHex,
  hexToRGB,
  opaqueHexOpacity,
};
