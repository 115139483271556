import * as React from "react";
import createBricks, {
  SizeDetail,
  type Instance,
  type Options,
} from "bricks.js";
import styled from "@emotion/styled";
import { useDebouncer, useElementDimensions } from "utils/hooks";
import { Stack } from "./Stack";
import { Button } from "./Button";

/**
 * Default configuration for Brick.js
 *
 * See: https://github.com/callmecavs/bricks.js
 */
const options: Omit<Options, "container" | "sizes"> = {
  packed: "data-packed",
  position: true,
};

/** This masonry grid appears in both a full-width layout and a 2-col layout */

// Mobile and tablet grid sizes for small screens
const gridSizesDefaults = [
  { columns: 1, gutter: 10 },
  // 2 col
  { mq: "320px", columns: 2, gutter: 10 },
  { mq: "360px", columns: 2, gutter: 10 },
  { mq: "390px", columns: 2, gutter: 10 },
  { mq: "428px", columns: 2, gutter: 10 },
  { mq: "500px", columns: 2, gutter: 10 },
  { mq: "550px", columns: 2, gutter: 10 },
  { mq: "600px", columns: 2, gutter: 16 },
  { mq: "700px", columns: 2, gutter: 16 },
  { mq: "744px", columns: 2, gutter: 16 },
  { mq: "800px", columns: 2, gutter: 16 },
];
// grid sizes for full-width layout (portfolio page)
const gridSizesLarge: SizeDetail[] = [
  ...gridSizesDefaults,
  { mq: "831px", columns: 3, gutter: 16 },
  { mq: "900px", columns: 3, gutter: 16 },
  { mq: "1000px", columns: 3, gutter: 16 },
  { mq: "1100px", columns: 3, gutter: 16 },
  { mq: "1200px", columns: 3, gutter: 16 },
  { mq: "1300px", columns: 3, gutter: 16 },
  { mq: "1400px", columns: 3, gutter: 16 },
  { mq: "1440px", columns: 3, gutter: 16 },
];
// grid sizes for sidebar layout (gallery and item pages)
const gridSizesLargeGallery: SizeDetail[] = [
  ...gridSizesDefaults,
  { mq: "831px", columns: 2, gutter: 16 },
  { mq: "900px", columns: 2, gutter: 16 },
  { mq: "1000px", columns: 2, gutter: 16 },
  { mq: "1100px", columns: 2, gutter: 16 },
  // 3 cols
  { mq: "1200px", columns: 3, gutter: 16 },
  { mq: "1300px", columns: 3, gutter: 16 },
  { mq: "1400px", columns: 3, gutter: 16 },
  { mq: "1440px", columns: 3, gutter: 16 },
];
type MasonryGridProps<TItem> = {
  /** Array of items to render  */
  items: TItem[];
  /** Renders a smaller grid in the Gallery layout */
  isSmallGrid?: boolean;
  /**
   * Map callback to render each item with component
   *
   * Takes optional fourth param if component needs to access the grid instance from within
   */
  renderItem: (
    item: TItem,
    index: number,
    original: TItem[],
    grid?: Instance,
  ) => React.ReactNode;
};

function MasonryGrid<TItem>({
  items,
  isSmallGrid = false,
  renderItem,
}: MasonryGridProps<TItem>) {
  const [scale, setScale] = React.useState(1);
  const [height, setHeight] = React.useState(0);

  // Show first 24 items to reduce page load, unless 30 items or less
  const initialItems = items.length >= 30 ? items?.slice(0, 23) : items;
  // Set default state for `showAllItems` to false if less than 30 items
  const [showAllItems, setShowAllItems] = React.useState(items.length < 30);
  const handleSetAllItems = () => {
    setShowAllItems(true);
  };
  // set the number of items to show based on `showAllItems`
  const gridItems = showAllItems ? items : initialItems;

  // Store running instance of Bricks.js to use instance methods later in effects
  const bricks = React.useRef<Instance>();
  const containerRef = React.useRef<HTMLDivElement>(null);

  // Set up Bricks.js instance for this grid
  React.useEffect(() => {
    if (!containerRef.current) {
      return () => {};
    }
    // NOTE: Adding multiple breakpoints with the same properties allows the grid to update and fill
    // out the available space on the page.
    const sizes = isSmallGrid ? gridSizesLargeGallery : gridSizesLarge;
    const container = containerRef.current;
    bricks.current = createBricks({ ...options, container, sizes }).resize(
      true,
    );

    const onPack = () => {
      setHeight(container.offsetHeight);
    };

    // Add event handler to track height of grid when items are packed to adjust bottom margin
    bricks.current.on("pack", onPack);

    // Remove the event handler when component unmounts
    return () => bricks.current?.off("pack", onPack);
  }, [isSmallGrid]);

  // Whenever items change, we need to update the grid. Use pack in case order has changed or
  // items have been deleted or edited
  React.useEffect(() => {
    bricks.current?.pack();
  }, [items, gridItems]);

  // Track width of component and debounce it for target width of Bricks container
  const widthRef = React.useRef<HTMLDivElement>(null);
  const { width: targetWidth } = useElementDimensions(widthRef);
  const width = useDebouncer(targetWidth, 100);

  // Adjust computed height after scale with negative margin so container doesn't have large
  // gap between MasonryGrid and next sibling element.
  // 100px added to bottom margin to avoid white space below footer on large screens
  const marginBottom = (scale - 1) * height + 100;

  // If we've been given a target width, calculate the scale we need to transform to based on
  // the MasonryGrid's current width.
  React.useLayoutEffect(() => {
    if (width !== undefined && containerRef.current) {
      // Find width of grid container and calculate scale ratio
      const containerWidth = containerRef.current.offsetWidth;
      const newScale = width / containerWidth;

      // If the scale is problematic (e.g. divided by 0 and got Infinite, got 0 or negative number)
      // then set scale to 1. Otherwise, set to the calculated scale.
      setScale(Number.isFinite(newScale) && newScale > 0 ? newScale : 1);
    } else if (width === undefined) {
      // If target width is no longer requested but we've already scaled, reset to normal scale
      setScale(1);
    }
  }, [width]);

  return (
    <Stack gap="0" style={{ position: "relative" }}>
      <div ref={widthRef} />
      <GridWrapper
        isSmallGrid={isSmallGrid}
        ref={containerRef}
        style={{ transform: `scale(${scale})`, marginBottom }}
      >
        {gridItems.map((item, index, original) =>
          renderItem(item, index, original, bricks.current),
        )}
      </GridWrapper>

      {!showAllItems && (
        <Fade>
          <Button onClick={handleSetAllItems}>Load All Items</Button>
        </Fade>
      )}
    </Stack>
  );
}
const gutterSm = 32;
const gutterLg = 108;
const gapXs = 10;
const gap = 16;

const GridWrapper = styled.div<{ isSmallGrid: boolean }>(({ isSmallGrid }) => ({
  // margin: "0 auto",
  transformOrigin: "top left",
  transition: "transform ease 0.2s",
  "&>*": {
    /** Sets card width at each grid breakpoint. Necessary for masonry grid layout */
    width: "100%",
    // cols: 2 // gutter: 32 //
    "@media (min-width: 320px)": { width: (360 - gutterSm - gapXs) / 2 },
    "@media (min-width: 360px)": { width: (390 - gutterSm - gapXs) / 2 },
    "@media (min-width: 390px)": { width: (428 - gutterSm - gapXs) / 2 },
    "@media (min-width: 428px)": { width: (500 - gutterSm - gapXs) / 2 },
    "@media (min-width: 500px)": { width: (550 - gutterSm - gapXs) / 2 },
    "@media (min-width: 550px)": { width: (600 - gutterSm - gapXs) / 2 },
    // gutter: 108 //
    "@media (min-width: 600px)": { width: (700 - gutterLg - gap) / 2 },
    "@media (min-width: 700px)": { width: (744 - gutterLg - gap) / 2 },
    "@media (min-width: 744px)": { width: (800 - gutterLg - gap) / 2 },
    "@media (min-width: 800px)": { width: (831 - gutterLg - gap) / 2 },
    // cols: 3 //
    ...(isSmallGrid
      ? // masonry grid is in sidebar layout
        {
          "@media (min-width: 831px)": {
            width: (900 * 0.75 - gutterLg - gap) / 2,
          },
          "@media (min-width: 900px)": {
            width: (1000 * 0.75 - gutterLg - gap) / 2,
          },
          "@media (min-width: 1000px)": {
            width: (1100 * 0.75 - gutterLg - gap) / 2,
          },
          "@media (min-width: 1100px)": {
            width: (1200 * 0.75 - gutterLg - gap) / 2,
          },
          // 3 cols
          "@media (min-width: 1200px)": {
            width: (1300 * 0.75 - gutterLg - gap * 2) / 3,
          },
          "@media (min-width: 1300px)": {
            width: (1400 * 0.75 - gutterLg - gap * 2) / 3,
          },
          "@media (min-width: 1400px)": {
            width: (1440 * 0.75 - gap * 2) / 3,
          },
        }
      : {
          "@media (min-width: 831px)": {
            width: (900 - gutterLg - gap * 2) / 3,
          },
          "@media (min-width: 900px)": {
            width: (1000 - gutterLg - gap * 2) / 3,
          },
          "@media (min-width: 1000px)": {
            width: (1100 - gutterLg - gap * 2) / 3,
          },
          "@media (min-width: 1100px)": {
            width: (1200 - gutterLg - gap * 2) / 3,
          },
          // 3 cols
          "@media (min-width: 1200px)": {
            width: (1300 - gutterLg - gap * 2) / 3,
          },
          "@media (min-width: 1300px)": {
            width: (1400 - gutterLg - gap * 2) / 3,
          },
          "@media (min-width: 1400px)": {
            width: (1440 - gap * 2) / 3,
          },
        }),
  },
}));

const Fade = styled.div(({ theme }) => ({
  position: "absolute",
  width: "100%",
  display: "grid",
  paddingBottom: 100,
  placeContent: "end center",
  transform: "translateY(-100%)",
  "&::before": {
    content: '""',
    position: "absolute",
    height: 400,
    right: `calc(${theme.spacing.gutter} * -1)`,
    bottom: "-3rem",
    left: `calc(${theme.spacing.gutter} * -1)`,
    backgroundImage: `
      linear-gradient(
        to top, 
        #ffffff 25%, 
        #ffffffd1 50%, 
        #ffffff80 70%, 
        #ffffff00 100%
      )
    `,
    pointerEvents: "none",
    zIndex: 1,
  },
  "&::after": {
    content: '""',
    position: "absolute",
    height: 270,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 2,
  },
  button: {
    position: "relative",
    zIndex: 3,
  },
}));

export { MasonryGrid };
