import { useMutation, useQueryClient } from "react-query";
import { useAuth } from "features/auth";
import { api } from "utils/api";
import type { ProfileEmailResp } from "../types";
import { profileKeys } from "./queryKeys";

const updateEmail = (body: {
  email: string;
  verificationCode?: string;
  subscribeCreatorRoyalties?: boolean;
  subscribeWeeklyHug?: boolean;
}) =>
  api.put<ProfileEmailResp>("/profile/email", {
    body: JSON.stringify(body),
  });

const useUpdateEmail = () => {
  const { refreshAuth } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(updateEmail, {
    onSuccess: (resp) => {
      if (resp.status === "EMAIL_UPDATED") {
        // NOTE: User data is embedded within their authentication token, so updates to their info
        // will invalidate their current token resulting in 403 errors on further calls. This will
        // refresh
        refreshAuth(resp);
        queryClient.setQueryData(profileKeys.detail(resp.user.id), resp.user);
      }
    },
  });
};

export { useUpdateEmail };
