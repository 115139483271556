import styled from "@emotion/styled";
import { inputCSS } from "./Input";

type TextAreaProps = {
  isInvalid?: boolean;
};

const TextArea = styled.textarea<TextAreaProps>(
  ({ theme, isInvalid = false }) => ({
    ...inputCSS(theme, { bordered: true, isInvalid }),
    fontSize: 16,
    lineHeight: "1.3",
    minWidth: "100%",
    minHeight: "120px",
    padding: "1.2rem",
    position: "relative",
    zIndex: 1,
    resize: "vertical",
    verticalAlign: "top",
  }),
);

export { TextArea };
