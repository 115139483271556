import { api } from "utils/api";
import type { AuthWalletResponse, AuthWalletConnection } from "../types";

/**
 * Async API call for wallet signature authentication
 */
const postWalletAuth = (connection: AuthWalletConnection) =>
  api.post<AuthWalletResponse>("/auth/wallet", {
    body: JSON.stringify(connection),
  });

export { postWalletAuth };
