import styled from "@emotion/styled";
import { Outlet } from "react-router-dom";

function LayoutFull() {
  return (
    <FullHeight data-testid="LayoutFull">
      <Outlet />
    </FullHeight>
  );
}

const FullHeight = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.fg05,
  display: "flex",
  flexDirection: "column" as const,
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  padding: "1rem",
}));

export { LayoutFull };
