import styled from "@emotion/styled";
import {
  Button,
  FlexRow,
  IconTrashCan,
  LoadingEllipses,
  Modal,
  Stack,
  Text,
} from "atoms";
import { GenericImage } from "features/images";
import { pluralize } from "utils/text";
import { useGalleryItem } from "../api/useGalleryItem";
import { useGalleryItemDelete } from "../api/useGalleryItemDelete";
import { useEditorContext } from "../utils/useEditorContext";

type DeleteItemProps = {
  close: VoidFunction;
  onSuccess?: VoidFunction;
};

/**
 * Modal to delete a gallery or gallery item
 */
function DeleteItem({ close, onSuccess }: DeleteItemProps) {
  const { artist, itemId, itemType } = useEditorContext();
  const artistId = artist.id;

  const { data } = useGalleryItem({ artistId, itemId });

  const { mutate, isLoading } = useGalleryItemDelete();

  if (!itemId || !itemType) {
    throw new Error("Incorrect use of editor context");
  }

  const itemLabel = itemType === "folder" ? "Gallery" : "Item";

  const deleteItem = () =>
    mutate({ artistId, itemId }, { onSuccess: onSuccess ?? close });
  const deleteAllItems = () => {
    mutate(
      { artistId, itemId, cascade: true },
      { onSuccess: onSuccess ?? close },
    );
  };

  // If this item is a gallery, see if it contains any items to toggle cascade delete button
  const hasItemsToDelete =
    itemType === "folder" && (data?.folderItemCount ?? 0) > 0;

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <IconTrashCan /> Delete {itemLabel}
        </Modal.Title>
      </Modal.Header>

      <Stack gap="sm">
        <Stack gap="sm">
          <Stack gap="xs">
            <SGenericImage src={data?.image} />
            {data?.title && (
              <Text bold font="display" size="xxs" textAlign="center">
                {data.title}
              </Text>
            )}
          </Stack>
          {itemType === "folder" ? (
            <Stack gap="sm">
              <Text textAlign="center" bold>
                Are you sure you want to delete this Gallery?
              </Text>
            </Stack>
          ) : (
            <Text textAlign="center">
              Are you sure you want to <strong>permanently</strong> delete this
              item from your Portfolio?
            </Text>
          )}
        </Stack>

        <FlexRow justifyContent="center">
          <Button variant="primary" onClick={deleteItem} disabled={isLoading}>
            {isLoading ? (
              <>
                Deleting <LoadingEllipses />
              </>
            ) : (
              `Delete ${itemLabel}`
            )}
          </Button>
        </FlexRow>

        {/* show only one disabled `Deleting...` button when deleting is in progress */}
        {hasItemsToDelete && (
          <Stack gap="xxs">
            <Text size="xs" textAlign="center">
              Deleting this Gallery will not delete the items within it unless
              you choose to delete both the Gallery <em>and</em> its items using
              the button below.
            </Text>
            {!isLoading && (
              <FlexRow justifyContent="center">
                <Button
                  variant="secondary"
                  onClick={deleteAllItems}
                  disabled={isLoading}
                >
                  {`Delete ${itemLabel} and ${(data?.folderItemCount ?? 0) >= 3 ? "all " : ""}${pluralize(data?.folderItemCount, "Item")}`}
                </Button>
              </FlexRow>
            )}
          </Stack>
        )}
      </Stack>

      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button onClick={close} variant="secondary">
            Cancel
          </Button>
        </FlexRow>
      </Modal.Footer>
    </>
  );
}

const SGenericImage = styled(GenericImage)(() => ({
  width: "max(20vw, 250px)",
  height: "min(10vw, 250px)",
  maxHeight: 250,
  maxWidth: 250,
  minHeight: 100,
  margin: "0 auto",
  overflow: "hidden",
  img: {
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    margin: "0 auto",
  },
}));
export { DeleteItem };
