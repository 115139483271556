import { api } from "utils/api";
import type { User } from "../types";

/**
 * Async API call to refresh JWT
 */
const getAuthRefresh = () =>
  api.get<{ user: User; jwt: string }>("/auth/token");

export { getAuthRefresh };
