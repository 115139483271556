import * as React from "react";
import styled from "@emotion/styled";
import { Button, Stack, FakeLabel, Alert, Text } from "atoms";
import { formatSize } from "../utils/formatSize";

// 10mb = mb * kb * b
const DEFAULT_MAX_FILE_SIZE = 10 * 1000 * 1000;

const imageTypes = {
  values:
    ".jpg,.jpeg,image/jpeg,.png,image/png,.webp,image/webp,.gif,image/gif" as const,
  labels: ["JPG", "PNG", "GIF"] as const,
};
const videoTypes = {
  values: ".mp4,video/mp4" as const,
  labels: ["MP4"] as const,
};
const docTypes = {
  values: ".zip,application/zip,.pdf,application/pdf" as const,
  labels: ["ZIP", "PDF"] as const,
};

const acceptTypes = {
  image: imageTypes,
  video: videoTypes,
  imageOrVideo: {
    values: `${imageTypes.values},${videoTypes.values}` as const,
    labels: [...imageTypes.labels, ...videoTypes.labels] as const,
  },
  stillImage: {
    values: `.jpg,.jpeg,image/jpeg,.png,image/png` as const,
    labels: ["JPG", "PNG"] as const,
  },
  png: {
    values: `.png,image/png` as const,
    labels: ["PNG"] as const,
  },
  onchainAsset: {
    values:
      `${imageTypes.values},${videoTypes.values},.pdf,application/pdf` as const,
    labels: [...imageTypes.labels, ...videoTypes.labels, "PDF"],
  },
  // audio?
  // media? (i.e. image/video/audio)
  document: docTypes,
  all: {
    values:
      `${docTypes.values},${imageTypes.values},${videoTypes.values}` as const,
    labels: [
      ...docTypes.labels,
      ...imageTypes.labels,
      ...videoTypes.labels,
    ] as const,
  },
  any: {
    values: [],
    labels: [],
  },
} as const;

type AcceptType = keyof typeof acceptTypes;

const stopDragEvent: React.DragEventHandler = (event) => {
  event.stopPropagation();
  event.preventDefault();
};

type FileInputProps = Omit<
  React.ComponentProps<"input">,
  "accept" | "onChange"
> & {
  /** Optional text for input */
  text?: string;
  /** Optional text or node for label above input */
  label?: React.ReactNode;
  /**
   * Optional limit for file size in bits, defaults to max allowed size
   */
  maxFileSize?: number;
  /**
   * Optional handler that renders a cancel button and runs on click
   */
  onCancel?: VoidFunction;
  /**
   * Handler that runs with selected File
   */
  onChange: (file: File) => void;
  /**
   * Optional attribute to accept certain file types
   */
  accept?: AcceptType;
  /** Hide the requirements text below input */
  hideRequirements?: boolean;
};

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    {
      text = "Choose File...",
      label,
      maxFileSize = DEFAULT_MAX_FILE_SIZE,
      onCancel,
      onChange,
      accept = "image",
      hideRequirements = false,
      ...props
    },
    ref,
  ) => {
    const [isDragging, setIsDragging] = React.useState(false);
    const [isImageTooLarge, setIsImageTooLarge] = React.useState(false);

    /**
     * Initialize cropper with new file from select or drop event's file
     */
    const handleFile = (file: File) => {
      if (file.size <= maxFileSize) {
        onChange(file);
      } else {
        setIsImageTooLarge(true);
      }
    };

    /**
     * Handle onChange event when selecting file through dialog menu
     */
    const handleSelectFile: React.ChangeEventHandler<HTMLInputElement> = (
      event,
    ) => {
      if (event.target.files && event.target.files.length > 0) {
        handleFile(event.target.files[0]);
      }
    };

    /**
     * Update state to style on start of dragging event
     */
    const handleDragEnter: React.DragEventHandler = (event) => {
      stopDragEvent(event);
      setIsDragging(true);
    };

    /**
     * Update state to style on end of dragging event
     */
    const handleDragLeave: React.DragEventHandler = () => {
      setIsDragging(false);
    };

    /**
     * Handle onDrop event when selecting file through drag and drop
     */
    const handleDrop: React.DragEventHandler = (event) => {
      stopDragEvent(event);
      const files = event.dataTransfer?.files;
      if (files) {
        handleFile(files[0]);
      }
    };

    return (
      <Dropbox
        isDragging={isDragging}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={stopDragEvent}
        onDrop={handleDrop}
      >
        {label && <FakeLabel>{label}</FakeLabel>}

        <Stack gap="xxs">
          {onCancel && (
            <div>
              <SButton onClick={onCancel} variant="blank" size="xxs">
                Cancel
              </SButton>
            </div>
          )}
          <div>
            <SButton variant="secondary" as="label">
              <span>{text}</span>

              <HiddenInput
                {...props}
                type="file"
                accept={
                  accept === "any" ? undefined : acceptTypes[accept].values
                }
                onChange={handleSelectFile}
                ref={ref}
              />
            </SButton>
          </div>
        </Stack>

        {!hideRequirements && (
          <div>
            <small>
              Supports {acceptTypes[accept].labels.join(", ")} files. Animated
              GIFs not currently supported. {formatSize(maxFileSize)}
              &nbsp;max&nbsp;file&nbsp;size.
            </small>
          </div>
        )}

        {isImageTooLarge && (
          <Alert type="error">
            <Text size="xs" textAlign="center" bold>
              The selected image exceeds the max file size of{" "}
              {formatSize(maxFileSize)}.
            </Text>
          </Alert>
        )}
      </Dropbox>
    );
  },
);

type DropboxProps = {
  isDragging: boolean;
};

const Dropbox = styled.div<DropboxProps>(() => ({
  alignItems: "flex-start",
  border: "none",
  borderRadius: 0,
  padding: 0,
  "@media (prefers-reduced-motion: no-preference)": {
    transition: "border ease 0.3s",
  },
  lineHeight: 1.1,
  maxWidth: "100%",
  display: "flex",
  gap: ".2rem",
  flexDirection: "column",
  justifyContent: "space-around",
  "& > div": {
    maxWidth: "100%",
  },
  "& p": {
    marginBottom: "0.5rem",
  },
  "& p:last-child": {
    marginBottom: "0",
  },
}));

const HiddenInput = styled.input({
  clip: "rect(1px, 1px, 1px, 1px)",
  height: 1,
  overflow: "hidden",
  position: "absolute",
  width: 1,
});

const SButton = styled(Button)(({ theme }) => ({
  "&&": { minHeight: 0 },
  "&:focus-within": {
    backgroundColor: theme.colors.fg,
    color: theme.colors.bg,
  },
}));
SButton.defaultProps = {
  size: "xs",
};

export type { AcceptType };
export { FileInput, HiddenInput, acceptTypes };
