import { api } from "utils/api";
import { useMutation, useQueryClient } from "react-query";
import { OwnedContractList } from "../types";
import { ownedContractKeys, ownedTokenKeys } from "./queryKeys";

type SyncOwnedContractsRequest = { userId: string };

const syncOwnedContracts = ({ userId }: SyncOwnedContractsRequest) =>
  api.put<OwnedContractList>(`/wallet/contracts/sync/${userId}`, {
    body: JSON.stringify({}),
  });

const useSyncOwnedContracts = () => {
  const queryClient = useQueryClient();

  return useMutation(syncOwnedContracts, {
    onSuccess: () => {
      queryClient.invalidateQueries(ownedTokenKeys.base);
      queryClient.invalidateQueries(ownedContractKeys.base);
    },
  });
};

export { useSyncOwnedContracts };
