import styled from "@emotion/styled";
import { Text } from "atoms";
import { GenericImage } from "features/images";

type ArtPrintPreviewProps = {
  src?: string;
  size: string;
  index?: number;
  totalPreviews?: number;

  /** Width of the largest print in the preview group */
  maxPreviewWidth?: number;

  showShadow?: boolean;

  /** Show the print to scale compared to the `maxPreviewWidth` value */
  showToScale?: boolean;
};

function ArtPrintPreview({
  src,
  size,
  index,
  totalPreviews,
  maxPreviewWidth,
  showShadow,
  showToScale = false,
  ...props
}: ArtPrintPreviewProps) {
  if (!src || size.indexOf("x") === -1) {
    return (
      <Text>
        <span>
          Error displaying preview <span>{index && index + 1}</span>/
          {totalPreviews}:
        </span>
        <span>{!src ? "invalid source" : `invalid size ${size}`}</span>
      </Text>
    );
  }

  const [width, height] = size.split("x").map((n) => Number(n));
  const previewScale = showToScale
    ? width / (maxPreviewWidth ?? width)
    : undefined;

  // let orientation = "Square";
  // if (width > height) {
  //   orientation = "Landscape";
  // } else if (width < height) {
  //   orientation = "Portrait";
  // }
  if (showShadow) {
    return (
      <ArtPrintPreviewPaper
        aspectRatio={width / height}
        previewScale={previewScale}
        showShadow={showShadow}
        {...props}
      >
        <GenericImage src={src} />
      </ArtPrintPreviewPaper>
    );
  }
  return <GenericImage src={src} aspectRatio={width / height} {...props} />;
  // return (
  //   <Stack>
  //     <FlexRow justifyContent="space-between">
  //       <H3 size="sm" textTransform="none" style={{ margin: 0 }}>
  //         {width}&rdquo; &times; {height}&rdquo;
  //       </H3>

  //       <Text size="xxs" style={{ margin: 0, opacity: 0.85 }}>
  //         <em>{orientation}</em>
  //       </Text>
  //     </FlexRow>

  //     <ArtPrintPreviewPaper
  //       aspectRatio={width / height}
  //       previewScale={previewScale}
  //     >
  //       <GenericImage src={src} />
  //     </ArtPrintPreviewPaper>
  //   </Stack>
  // );
}

const ArtPrintPreviewPaper = styled.div<{
  aspectRatio?: React.CSSProperties["aspectRatio"];
  previewScale?: number;
  showShadow?: boolean;
}>(({ aspectRatio, previewScale, showShadow, theme }) => ({
  aspectRatio,
  boxShadow: `0 1px 3px 0 ${theme.colors.fg20}`,
  position: "relative",
  width: previewScale ? `calc(100% * ${previewScale})` : "100%",
  zIndex: 1,
  "&>div": {
    aspectRatio,
    backgroundColor: theme.colors.bg,
    position: "relative",
    zIndex: 2,
  },
  img: {
    objectFit: "cover",
    width: "100%",
    height: "100%",
    objectPosition: "center",
  },
  ...(showShadow && {
    "&::before, &::after": {
      backgroundColor: theme.colors.fg,
      bottom: ".7em",
      boxShadow: `0 1em 1em ${theme.colors.fg}`,
      content: "''",
      height: "50%",
      opacity: 0.5,
      position: "absolute",
      width: "46%",
      zIndex: 0,
    },
    "&::before": {
      transform: "skew(-7deg, -1deg)",
      transformOrigin: "bottom left",
      left: "5%",
    },
    "&::after": {
      transform: "skew(7deg, 1deg)",
      transformOrigin: "bottom right",
      right: "5%",
    },
  }),
}));

export { ArtPrintPreview, ArtPrintPreviewPaper };
