import { type Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { type CSSProperties } from "react";

type TextProps = {
  size?: keyof Theme["fontSizes"]; // H1, H2, H3, xxl, xl, lg, md, sm, xs, xxs
  bold?: boolean;
  textAlign?: CSSProperties["textAlign"];
  textTransform?: CSSProperties["textTransform"];
  nowrap?: boolean;
  font?: keyof Theme["fonts"];
};

const baseHeading = ({
  bold = true,
  font = "display",
  textAlign,
  textTransform = "uppercase",
  theme,
}: TextProps & { theme: Theme }) => ({
  ...theme.fonts[font],
  color: theme.colors.displayColor,
  fontWeight: bold ? "bold" : "normal",
  letterSpacing:
    // Only add letter-spacing when using all
    textTransform === "uppercase" ? theme.spacing.letterSpacing : 0,
  lineHeight: 1.1,
  marginTop: 0,
  marginBottom: "1rem",
  textAlign,
  textTransform,
  "*+&": {
    marginTop: "2.5rem",
  },
  "hgroup *+&": {
    marginTop: "inherit",
    marginBottom: "inherit",
  },
});

const H1 = styled.h1<TextProps>(
  baseHeading,
  ({
    textAlign,
    bold = true,
    font = "display",
    size = "h1",
    textTransform = "uppercase",
    nowrap,
    theme,
  }) => ({
    ...(font === "body" && {
      lineHeight: 1.5,
    }),
    fontSize: theme.fontSizes[size],
    fontWeight: bold ? "bold" : "normal",
    ...(nowrap && {
      whiteSpace: "nowrap",
    }),
    textAlign,
    textTransform,
  }),
);

const H2 = styled.h2<TextProps>(
  baseHeading,
  ({
    textAlign,
    bold = true,
    font = "display",
    size = "h2",
    textTransform = "uppercase",
    nowrap,
    theme,
  }) => ({
    ...(font === "body" && {
      lineHeight: 1.5,
    }),
    fontSize: theme.fontSizes[size],
    fontWeight: bold ? "bold" : "normal",
    ...(nowrap && {
      whiteSpace: "nowrap",
    }),
    textAlign,
    textTransform,
  }),
);

const H3 = styled.h3<TextProps>(
  baseHeading,
  ({
    textAlign,
    bold = true,
    font = "display",
    size = "h3",
    textTransform = "uppercase",
    nowrap,
    theme,
  }) => ({
    ...(font === "body" && {
      lineHeight: 1.5,
    }),
    fontSize: theme.fontSizes[size],
    ...(nowrap && {
      whiteSpace: "nowrap",
    }),
    fontWeight: bold ? "bold" : "normal",
    marginBottom: "1rem",
    textAlign,
    textTransform,
  }),
);

const H4 = styled.h4<TextProps>(
  baseHeading,
  ({
    textAlign,
    bold = true,
    font = "display",
    size = "sm",
    textTransform = "uppercase",
    nowrap,
    theme,
  }) => ({
    ...(font === "display" && {
      lineHeight: 1.1,
    }),
    fontSize: theme.fontSizes[size],
    ...(nowrap && {
      whiteSpace: "nowrap",
    }),
    fontWeight: bold ? "bold" : "normal",
    marginBottom: "1rem",
    textAlign,
    textTransform,
  }),
);

const H5 = styled.h5<TextProps>(
  baseHeading,
  ({
    textAlign,
    bold = true,
    font = "display",
    size = "xs",
    textTransform = "uppercase",
    nowrap,
    theme,
  }) => ({
    ...(font === "display" && {
      lineHeight: 1.1,
    }),
    fontSize: theme.fontSizes[size],
    ...(nowrap && {
      whiteSpace: "nowrap",
    }),
    fontWeight: bold ? "bold" : "normal",
    textAlign,
    textTransform,
  }),
);

const H6 = styled.h6<TextProps>(
  baseHeading,
  ({
    textAlign,
    bold = true,
    font = "display",
    size = "xs",
    textTransform = "uppercase",
    nowrap,
    theme,
  }) => ({
    ...(font === "display" && {
      lineHeight: 1.1,
    }),
    fontSize: theme.fontSizes[size],
    ...(nowrap && {
      whiteSpace: "nowrap",
    }),
    fontWeight: bold ? "bold" : "normal",
    textAlign,
    textTransform,
  }),
);

const Text = styled.p<TextProps>(
  ({ textAlign, bold = false, font, size, textTransform, nowrap, theme }) => ({
    ...(font === "display" && {
      ...theme.fonts.display,
      lineHeight: 1.1,
    }),
    fontSize: size ? theme.fontSizes[size] : "inherit",
    fontWeight: bold ? "bold" : "normal",
    ...(nowrap && {
      whiteSpace: "nowrap",
    }),
    textAlign,
    textTransform,
  }),
);

const Address = styled(Text)({
  fontStyle: "normal",
  lineHeight: 1.2,
  "*+*": {
    marginTop: ".25em",
  },
}).withComponent("address");

const Sup = styled.sup({
  verticalAlign: "baseline",
  position: "relative",
  top: "-0.4em",
});
const Sub = styled.sub({
  verticalAlign: "baseline",
  position: "relative",
  top: "0.4em",
});

export { Address, H1, H2, H3, H4, H5, H6, Text, Sup, Sub };
export type { TextProps };
