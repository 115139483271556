import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { Button, FlexRow, H3, IconTrashCan, Link, Text } from "atoms";
import { Thumbnail } from "features/images";
import { paths } from "utils/paths";
import { GalleryItem } from "../types";

type GalleryCardProps = GalleryItem & {
  isEditing?: boolean;
  showDescription?: boolean;
  deleteGallery?: VoidFunction;
};

function GalleryCard({
  ownerId,
  id,
  title,
  description,
  image,
  isEditing,
  showDescription = false,
  deleteGallery = noop,
}: GalleryCardProps) {
  const location = useLocation();
  return (
    <GalleryListItem>
      <GalleryThumbnail src={image} alt={title} loading="lazy" />
      <Description>
        <GalleryTitle textTransform="none">
          <GalleryLink
            to={
              isEditing
                ? paths.artistGalleryEdit(ownerId, id)
                : paths.artistGallery(ownerId, id)
            }
            state={{ from: location }}
          >
            {title}
          </GalleryLink>
        </GalleryTitle>

        {!isEditing && showDescription && description && (
          <TruncatedText size="xxxs">{description}</TruncatedText>
        )}
      </Description>

      {isEditing && (
        <EditButtons>
          {/* <Link
            variant="circle"
            size="sm"
            onClick={addItemsToGallery}
            to={paths.artistGalleryEdit(ownerId, id)}
            aria-label="Add Items to Gallery"
          >
            <IconNewImage aria-hidden />
          </Link> */}
          <Button
            variant="circle"
            size="sm"
            onClick={deleteGallery}
            aria-label="Delete Gallery"
          >
            <IconTrashCan aria-hidden />
          </Button>
        </EditButtons>
      )}
    </GalleryListItem>
  );
}

const GalleryListItem = styled.li(({ theme }) => ({
  borderRadius: theme.borderRadius.md,
  boxShadow: theme.boxShadow.light,
  display: "grid",
  overflow: "hidden",
  position: "relative",
  alignItems: "start",
  gap: "12px",
  gridTemplateColumns: "100%",
  gridTemplateRows: "auto 1fr",
  gridTemplateAreas: `
  "image"
  "desc"
  `,
}));

const GalleryThumbnail = styled(Thumbnail)({
  gridArea: "image",
  aspectRatio: "5 / 3",
  borderRadius: 0,
  img: {
    borderRadius: 0,
    objectPosition: "center",
    height: "100%",
  },
});

const Description = styled.div({
  alignSelf: "stretch",
  gridArea: "desc",
  padding: "0 10px 10px 10px",
  gap: 10,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
});

const GalleryTitle = styled(H3)(({ theme }) => ({
  "&&": { margin: 0 },
  lineHeight: 1,
  fontSize: 14.5,
  [theme.breakpoints.tablet]: {
    fontSize: 16,
  },
}));

const GalleryLink = styled(Link)({
  alignSelf: "start",
  display: "-webkit-box",
  lineClamp: "1",
  overflow: "hidden",
  textOverflow: "ellipsis",
  WebkitLineClamp: "1",
  WebkitBoxOrient: "vertical",
  fontWeight: "bold",
  marginTop: 3,
  position: "static",
  "&, &:hover, &:focus-visible, &:active": {
    transform: "none",
  },
  "&::before": {
    inset: 0,
    transform: "none",
    transition: "none",
  },
  "&::after": {
    content: "''",
    display: "block",
    position: "absolute",
    inset: 0,
    zIndex: 2,
  },
});

const TruncatedText = styled(Text)({
  lineHeight: 1.5,
  overflow: "hidden",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  WebkitLineClamp: "2",
  textOverflow: "ellipsis",
  marginBottom: 0,
});

const EditButtons = styled(FlexRow)(({ theme }) => ({
  padding: theme.spacing.sm,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  paddingBottom: theme.spacing.lg,
  justifyContent: "flex-end",
  gap: 10,
  "a, button": {
    backgroundColor: `${theme.colors.bg}60`,
    transition: ".2s ease all",
    position: "relative",
    zIndex: 2,
  },
  "&::before": {
    backgroundImage:
      "linear-gradient(180deg, #00000080, #00000040, rgba(0,0,0,0))",
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    pointerEvents: "none",
  },
  "li:hover & a, li:hover & button": {
    backgroundColor: `${theme.colors.bg}`,
  },
}));

export { GalleryCard, GalleryThumbnail };
