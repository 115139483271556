import * as React from "react";
import styled from "@emotion/styled";
import {
  useForm,
  useFieldArray,
  type UseFormRegister,
  type FieldErrors,
} from "react-hook-form";
import {
  Alert,
  FieldError,
  Fieldset,
  FlexRow,
  IconExternalLink,
  Input,
  Label,
  Legend,
  Link,
  RequiredText,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import { fieldOptions } from "utils/form";
import { paths } from "utils/paths";
import {
  useUpdateProductVariants,
  type UpdateProductVariantsReq,
} from "../../api/useUpdateProductVariants";
import type { StepProps, TemplateVariant } from "../../types";
import { ProductWizardStep } from "../ProductWizardStep";
import { ArtistAddress } from "../ShippingAddressForm";

function StepShipping({ state, dispatch, moveBack }: StepProps) {
  const { isLoading, isError, error, mutate } = useUpdateProductVariants(
    state.product.id,
  );

  // Has the product ever been published?
  const isPublished = !!state.product.publishedAt;

  // Use the product's template data to create a map of each variant by variant id which we'll use
  // when rendering each row of the pricing table to display minimum and recommended prices.
  const variantMap = React.useMemo(
    () =>
      state.productTemplate.variants.reduce<Record<string, TemplateVariant>>(
        (acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        },
        {},
      ),
    [state.productTemplate],
  );

  const {
    control,
    register,
    formState: { errors, isDirty, isSubmitted },
    setValue,
    handleSubmit,
  } = useForm<UpdateProductVariantsReq>({
    defaultValues: {
      variants: state.product.variants.map((v) => ({
        ...variantMap[v.templateVariantId ?? ""],
        id: v.id,
        variantId: v.templateVariantId,
        weight: v.weight ?? 0,
        initialQuantity: v.initialQuantity ?? 1,
        shipFromAddressId: v.shipFromAddressId,
      })),
    },
  });

  const { fields } = useFieldArray({ control, name: "variants" });

  // Register address field on first variant for validation and handling our own focus on failure
  register("variants.0.shipFromAddressId", fieldOptions.required);
  const addressBtnRef = React.useRef<HTMLButtonElement>(null);

  const updateAddressIdOnSuccess = React.useCallback(
    (shipFromAddressId?: string) => {
      if (shipFromAddressId) {
        fields.forEach((_, index) => {
          setValue(`variants.${index}.shipFromAddressId`, shipFromAddressId, {
            shouldTouch: true,
            shouldDirty: true,
            shouldValidate: !!isSubmitted,
          });
        });
      }
    },
    [fields, setValue, isSubmitted],
  );

  const moveNext = () => dispatch({ type: "moveNext" });
  const onSubmit = handleSubmit(
    (formData) => {
      if (isDirty) {
        mutate(formData, {
          onSuccess: moveNext,
        });
      } else {
        moveNext();
      }
    },
    (validationErrors) => {
      if (validationErrors.variants?.[0]?.shipFromAddressId) {
        // Focus the create address button but after the useForm tries to focus other fields
        setTimeout(() => addressBtnRef.current?.focus(), 1);
      }
    },
  );

  return (
    <ProductWizardStep
      stepTitle="Shipping & Inventory"
      state={state}
      moveBack={moveBack}
      nextStep={onSubmit}
      nextStepDisabled={isLoading}
      isForm
    >
      <Stack gap="md">
        {isPublished && (
          <Alert type="info">
            <Stack>
              <Text size="xs" bold>
                This product has been published and some fields are no longer
                editable.
              </Text>
              <Text size="xs">
                Manage your Inventory and Shipping Origin in the Shop
                Manager&apos;s{" "}
                <Link
                  to={paths.artistShopInventory(state.artistId)}
                  target="_blank"
                >
                  Inventory Tab <IconExternalLink size={12} />
                </Link>
                .
              </Text>
            </Stack>
          </Alert>
        )}

        <Stack
          gap="md"
          style={{
            // If product is published, make the form look and feel disabled
            pointerEvents: isPublished ? "none" : undefined,
            opacity: isPublished ? 0.6 : 1,
          }}
        >
          {fields.map((field, index) => (
            <VariantRow
              key={field.id}
              index={index}
              errors={errors.variants?.[index]}
              register={register}
              isPublished={isPublished}
            />
          ))}

          <div>
            <ArtistAddress
              artistId={state.artistId}
              btnRef={addressBtnRef}
              isDisabled={isPublished}
              onSuccess={updateAddressIdOnSuccess}
            />
            {errors?.variants?.[0]?.shipFromAddressId && (
              <FieldError>
                {errors.variants[0].shipFromAddressId.message}
              </FieldError>
            )}
          </div>
        </Stack>

        {isError && <UnexpectedError error={error} />}
      </Stack>
    </ProductWizardStep>
  );
}

type VariantRowProps<
  TRegister extends UseFormRegister<UpdateProductVariantsReq>,
> = {
  errors?: NonNullable<
    FieldErrors<UpdateProductVariantsReq>["variants"]
  >[number];
  index: number;
  isPublished: boolean;
  register: TRegister;
};

function VariantRow<
  TRegister extends UseFormRegister<UpdateProductVariantsReq>,
>({ register, errors, index, isPublished }: VariantRowProps<TRegister>) {
  return (
    <PricingRow>
      <Stack gap="md">
        <SFieldset>
          <Legend>
            Inventory
            <RequiredText />
            <br />
            <small>
              Enter the quantity available when publishing the product.
              Inventory adjustments can be made at any time once published.
            </small>
          </Legend>

          <FlexRow gap="10px" flexWrap="nowrap">
            <Label>
              Quantity Available
              <Input
                {...register(`variants.${index}.initialQuantity`, {
                  valueAsNumber: true,
                  ...fieldOptions.required,
                  ...fieldOptions.min(1),
                })}
                min={1}
                type="number"
                disabled={isPublished}
              />
              {errors?.initialQuantity?.message && (
                <FieldError>{errors.initialQuantity.message}</FieldError>
              )}
            </Label>
          </FlexRow>
        </SFieldset>

        <SFieldset>
          <Legend>
            Package Weight
            <RequiredText />
            <br />
            <small>
              Weight of the product and its packaging. This cannot be changed
              once the product is published.
              <br />
              <Link
                href="https://www.calculator.net/weight-calculator.html?unitFrom=gram&unitTo=ounce"
                target="_blank"
              >
                Weight / mass conversion tool <IconExternalLink size={12} />
              </Link>
            </small>
          </Legend>

          <FlexRow gap="10px" flexWrap="nowrap">
            <Label>
              Ounces
              <Input
                {...register(`variants.${index}.weight`, {
                  valueAsNumber: true,
                  ...fieldOptions.required,
                  ...fieldOptions.min(1),
                })}
                type="number"
                disabled={isPublished}
              />
              {errors?.weight?.message && (
                <FieldError>{errors.weight.message}</FieldError>
              )}
            </Label>
          </FlexRow>
        </SFieldset>
      </Stack>
    </PricingRow>
  );
}

StepShipping.displayName = "Shipping & Inventory";

const PricingRow = styled.div(({ theme }) => ({
  "&+&": {
    borderTop: "1px solid",
    borderTopColor: theme.colors.fg30,
    marginTop: theme.spacing.lg,
    paddingTop: theme.spacing.lg,
  },
}));

const SFieldset = styled(Fieldset)({
  input: {
    paddingInline: "0.66rem",
    width: 100,
  },
});

export { StepShipping };
