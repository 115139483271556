import { useForm, type UseFormProps } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import { useMutation } from "react-query";
import { api } from "utils/api";
import { isRedirectState } from "utils/routing";
import { useAuth } from "../components/AuthProvider";
import type { AuthWalletResponse, UpdatePasswordFormFields } from "../types";

const postUpdatePassword = ({ password }: UpdatePasswordFormFields) =>
  api.post<AuthWalletResponse>("/auth/email/credentials", {
    body: JSON.stringify({ phrase: password }),
    requireAuth: true,
  });

const useUpdatePasswordForm = (
  formOptions?: UseFormProps<UpdatePasswordFormFields>,
) => {
  const { refreshAuth } = useAuth();
  const form = useForm<UpdatePasswordFormFields>(formOptions);

  const { pathname, state } = useLocation();
  const navigate = useNavigate();

  const mutation = useMutation(postUpdatePassword, {
    onSuccess: (updates) => {
      // Redirect user away from reset password form on success
      if (pathname === "/reset-password") {
        // If we're given redirect state, make sure it isn't going back to the same location
        const to =
          isRedirectState(state) &&
          !state.from.pathname.startsWith("/reset-password")
            ? state.from
            : "/";

        navigate(to);
      }
      refreshAuth(updates);
    },
  });

  return { ...form, mutation };
};

export { useUpdatePasswordForm };
