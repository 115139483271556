import { useForm } from "react-hook-form";
import {
  useMutation,
  useQuery,
  useInfiniteQuery,
  useQueryClient,
} from "react-query";
import { api, paramsToQueryStr } from "utils/api";
import type {
  Artist,
  ArtistApplication,
  ArtistApplicationResponse,
  ArtistApplicationSearchResponse,
  AcceptApplicationRequest,
  UpdateApplicationStatusRequest,
  ArtistApplicationSearchRequest,
} from "../types";
import { applicationKeys } from "./queryKeys";

const getArtistApplications = (params: ArtistApplicationSearchRequest) => {
  const query = paramsToQueryStr(params);

  return api.get<ArtistApplicationSearchResponse>(
    `/artists/applications${query}`,
  );
};

const useArtistApplications = (params?: ArtistApplicationSearchRequest) =>
  useInfiniteQuery(
    applicationKeys.list(params),
    ({ pageParam }) =>
      getArtistApplications({ searchAfter: pageParam, ...params }),
    {
      getNextPageParam: (lastPage) => lastPage.searchAfter,
    },
  );

const getArtistApplication = (applicationId?: string) =>
  applicationId
    ? api.get<ArtistApplicationResponse>(
        `/artists/applications/${applicationId}`,
      )
    : Promise.reject(new Error("Invalid artist application id"));

const useArtistApplication = (applicationId?: string) =>
  useQuery(
    applicationKeys.detail(applicationId),
    () => getArtistApplication(applicationId),
    {
      enabled: !!applicationId,
    },
  );

const acceptArtistApplication = ({
  applicationId,
  slug,
}: AcceptApplicationRequest) =>
  api.post<Artist>(`/artists/applications/${slug}/accept`, {
    body: JSON.stringify({ applicationId }),
    requireAuth: true,
  });

const useAcceptArtistApplication = () => useMutation(acceptArtistApplication);

const updateApplicationStatus = ({
  applicationId,
  status,
}: UpdateApplicationStatusRequest) =>
  api.put<ArtistApplication>(`/artists/applications/${applicationId}`, {
    body: JSON.stringify({ status }),
    requireAuth: true,
  });

const useUpdateApplicationStatus = () => {
  const queryClient = useQueryClient();

  return useMutation(updateApplicationStatus, {
    onSuccess: () => {
      queryClient.invalidateQueries(applicationKeys.base);
    },
  });
};

const useApplicationStatusForm = (application?: ArtistApplication) => {
  const formState = useForm<ArtistApplication>({
    defaultValues: application,
  });
  const mutation = useUpdateApplicationStatus();
  return { ...formState, mutation };
};

export {
  useArtistApplications,
  useAcceptArtistApplication,
  useArtistApplication,
  useApplicationStatusForm,
};
