import type { GalleryItem } from "../types";

type GalleryItemWithImage = GalleryItem & {
  image: NonNullable<GalleryItem["image"]>;
};

const hasImage = (item: GalleryItem): item is GalleryItemWithImage =>
  !!item.image;

export { hasImage };
