import { useAccount, useDisconnect } from "wagmi";

/**
 * Get connected wallet address, connection status, and utilities
 */
const useWalletAuth = () => {
  const { address, connector, isConnected } = useAccount();
  const { disconnect } = useDisconnect();

  return { address, connector, disconnect, isConnected };
};

export { useWalletAuth };
