import * as React from "react";
import type { FallbackProps } from "react-error-boundary";
import { useRollbar } from "@rollbar/react";
import {
  SiteContainer,
  Button,
  H1,
  IconReactionNeedItNow,
  Stack,
  Text,
} from "atoms";

function SiteContentFallback({ error, resetErrorBoundary }: FallbackProps) {
  const rollbar = useRollbar();

  React.useEffect(() => {
    rollbar.error(error);
  }, [error, rollbar]);

  return (
    <SiteContainer role="alert" width="sm">
      <Stack gap="sm">
        <IconReactionNeedItNow width="120" height="120" />

        <H1 size="h2">Oh no, we broke something!</H1>

        <Text>
          Unfortunately we don&apos;t know what happened, but we&apos;ll take a
          look at the error and try to make sure it stops happening. You can go
          back and try again!
        </Text>

        <Button onClick={resetErrorBoundary}>Go back</Button>
      </Stack>
    </SiteContainer>
  );
}

export { SiteContentFallback };
