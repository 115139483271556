import { IconEditItem, Modal } from "atoms";
import { useEditorContext } from "../utils/useEditorContext";
import { GalleryForm } from "./GalleryForm";
import type { GalleryItem } from "../types";

type EditGalleryProps = {
  close: VoidFunction;
  gallery: GalleryItem;
};

function EditGallery({ gallery, close }: EditGalleryProps) {
  const { artist, setEditorAction } = useEditorContext();

  const deleteGallery = () =>
    setEditorAction("delete", { itemId: gallery.id, itemType: "folder" });

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <IconEditItem /> Edit Gallery Details
        </Modal.Title>
      </Modal.Header>

      <GalleryForm
        artistId={artist.id}
        cancel={close}
        deleteGallery={deleteGallery}
        gallery={gallery}
        onSuccess={close}
      />
    </>
  );
}

export { EditGallery };
