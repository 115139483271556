import styled from "@emotion/styled";

interface FieldErrorProps {
  block?: boolean;
}

const FieldError = styled.p<FieldErrorProps>(({ theme, block = false }) => ({
  ...theme.fonts.body,
  alignItems: "center",
  backgroundColor: theme.colors.error,
  borderRadius: theme.borderRadius.sm,
  fontSize: theme.fontSizes.xxs,
  fontWeight: "bold",
  lineHeight: 1.2,
  marginBottom: 0,
  padding: ".5em 1em",
  position: "relative",
  zIndex: 3,
  "&&": { color: theme.colors.white },
  ...(block
    ? {
        display: "flex",
        width: "max-content",
      }
    : {
        display: "inline-flex",
      }),
}));

FieldError.defaultProps = {
  "aria-live": "polite",
  className: "is-error",
};

export { FieldError };
