import * as React from "react";
import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { uniqueId } from "lodash";
import { BlockTag, FlexRow, IconCheckRounded, Stack, Text } from "atoms";
import { GenericImage } from "features/images";

type StepProps = {
  label: string;
  description?: string | React.ReactNode;
  image?: string | React.ReactNode;
};
type StepButtonProps = StepProps & {
  isDisabled?: boolean;
  onClick?: () => void;
};
type StepContentProps = StepProps & {
  type: "input" | "button";
  isDisabled?: boolean;
};
type StepInputProps = StepProps & {
  inputType?: "radio" | "checkbox";
  inputGroupName?: string;
  messageText?: string | React.ReactNode;
  value?: string;
};

function StepButton({
  label,
  description,
  image,
  isDisabled,
  onClick,
  ...props
}: StepButtonProps) {
  return (
    <StepButtonElement
      onClick={isDisabled ? noop : onClick}
      disabled={isDisabled}
      {...props}
    >
      <OptionContent
        label={label}
        description={description}
        image={image}
        type="button"
        isDisabled={isDisabled}
      />
    </StepButtonElement>
  );
}

const stepBaseCss = (theme: Theme) =>
  ({
    appearance: "none",
    backgroundColor: theme.colors.bg,
    borderRadius: theme.borderRadius.sm,
    border: 0,
    padding: 0,
    boxShadow: theme.boxShadow.light,
    cursor: "pointer",
    display: "flex",
    flexDirection: "column",
    flex: 1,
    lineHeight: 1.2,
    overflow: "hidden",
    transition: ".2s ease",
    transitionProperty: "box-shadow, transform",
    "&,&>*": {
      textAlign: "center",
    },
    "&:hover, &:focus-visible": {
      boxShadow: theme.boxShadow.dark,
      transform: "translateY(-2px)",
    },
  }) as const;

const stepInputCss = (theme: Theme) =>
  ({
    ...stepBaseCss(theme),
    border: "1px solid",
    borderColor: theme.colors.fg20,
    "&:hover, &:focus-visible": {
      borderColor: theme.colors.fg,
      boxShadow: theme.boxShadow.dark,
      transform: "translateY(-2px)",
    },
    "input:checked + &": {
      borderColor: theme.colors.fg,
      outline: "2px solid",
      outlineColor: theme.colors.fg,
    },
  }) as const;

const StepButtonElement = styled.button(({ theme }) => ({
  ...stepBaseCss(theme),
  "&[disabled]": {
    pointerEvents: "none",
    opacity: 0.5,
  },
}));

const StepInput = React.forwardRef(
  (
    {
      label,
      description,
      image,
      inputType,
      inputGroupName,
      ...props
    }: StepInputProps,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    const [id] = React.useState(uniqueId());
    return (
      <Stack gap="0">
        <input
          name={inputGroupName}
          id={id}
          type={inputType}
          hidden
          {...props}
          ref={ref}
        />
        <StepInputWrapper htmlFor={id}>
          <OptionContent
            label={label}
            description={description}
            image={image}
            type="input"
          />
        </StepInputWrapper>
      </Stack>
    );
  },
);

const StepInputWrapper = styled.label(({ theme }) => ({
  ...stepInputCss(theme),
}));

function OptionContent({
  label,
  description,
  image,
  isDisabled = false,
  type,
}: StepContentProps) {
  return (
    <OptionMain>
      <OptionImageArea>
        {typeof image === "string" ? <GenericImage src={image} /> : image}
      </OptionImageArea>
      <OptionBody>
        <Stack gap="xxs" style={{ width: "100%" }}>
          <FlexRow
            justifyContent={type === "button" ? "center" : "space-between"}
          >
            <Title>{label}</Title>
            {type === "input" && (
              <FakeInput className="fakeInput">
                <IconCheckRounded />
              </FakeInput>
            )}
          </FlexRow>
          {description && <Description>{description}</Description>}
          {isDisabled && (
            <BlockTag bg="accent1LL" color="fg">
              Coming Soon
            </BlockTag>
          )}
        </Stack>
      </OptionBody>
    </OptionMain>
  );
}

const OptionImageArea = styled.div({
  aspectRatio: 1,
  height: "100%",
  width: "100%",
  maxWidth: "100%",
  overflow: "hidden",
  display: "grid",
  placeContent: "center",
  position: "relative",
});

const OptionMain = styled.div({
  display: "grid",
  gridTemplateRows: "auto 1fr",
  flex: 1,
  flexDirection: "column",
  gap: 0,
  maxWidth: "100%",
});

const Title = styled(Text)(({ theme }) => ({
  ...theme.fonts.body,
  fontWeight: "bold",
  color: theme.colors.fg,
  fontSize: 16,
  margin: 0,
  lineHeight: 1.2,
}));

const Description = styled.div(({ theme }) => ({
  color: theme.colors.fg70,
  fontSize: 13,
}));

const OptionBody = styled.div(({ theme }) => ({
  padding: 10,
  display: "flex",
  flexDirection: "column",
  flex: 1,
  alignItems: "center",
  justifyContent: "center",
  boxShadow: `0 -1px 0 0 ${theme.colors.fg05}`,
}));

const FakeInput = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  display: "grid",
  placeContent: "center",
  width: 18,
  height: 18,
  border: "1px solid",
  borderColor: theme.colors.fg,
  borderRadius: 2,
  pointerEvents: "none",
  svg: {
    color: theme.colors.bg,
    width: 13,
  },
  "input + label:hover &": { backgroundColor: theme.colors.fg10 },
  "input[type='radio'] + label &": {
    borderRadius: theme.borderRadius.round,
  },
  "input:checked + label &": { backgroundColor: theme.colors.fg },
}));

export { StepButton, StepInput };
