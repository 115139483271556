import * as React from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import {
  AppError,
  Box,
  Button,
  Checkbox,
  Container,
  FieldError,
  Fieldset,
  FlexRow,
  Form,
  H1,
  IconHugLogo,
  Input,
  Label,
  Legend,
  Radio,
  RequiredText,
  Stack,
  Text,
} from "atoms";
import { constants } from "config";
import type { User } from "features/auth";
import { fieldOptions, validators } from "utils/form";
import { useUpdateProfile } from "../api/useProfile";
import { useUpdateEmail } from "../api/useUpdateEmail";

const steps = ["settings", "verification", "success"] as const;

type OnboardingFields = {
  name: string;
  email: string;
  verificationCode?: string;
  creatorOptIn?: "in" | "out";
  subscribeWeeklyHug?: boolean;
  subscribeCreatorRoyalties?: boolean;
};

/**
 * Transform boolean/undefined on user object to values matching radio inputs
 */
const transformCreatorOptIn = (
  val?: boolean,
): OnboardingFields["creatorOptIn"] => {
  if (val === undefined) {
    return undefined;
  }
  return val ? "in" : "out";
};

type Props = {
  user: User;
  onComplete: VoidFunction;
};

function EditProfileOnboarding({ user, onComplete }: Props) {
  const [step, setStep] = React.useState<(typeof steps)[number]>(steps[0]);
  const resetStep = () => setStep(steps[0]);

  const updateProfile = useUpdateProfile(false);
  const updateEmail = useUpdateEmail();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    setFocus,
  } = useForm<OnboardingFields>({
    defaultValues: {
      name: user.name,
      email: user.email,
      creatorOptIn: transformCreatorOptIn(user.creatorOptIn),
    },
  });

  // Watch email input value for display on verification screen
  const enteredEmail = watch("email");

  // Check for non-unique display name error updating profile and focus the input
  const isDuplicateName =
    updateProfile.error instanceof Error &&
    updateProfile.error.message.includes("already taken by someone else");
  React.useEffect(() => {
    if (isDuplicateName) {
      setFocus("name", { shouldSelect: true });
    }
  }, [isDuplicateName, setFocus]);

  // Send initial form data in order to update profile, then trigger verification email
  const initialSubmit = async ({
    name,
    creatorOptIn,
    email,
  }: OnboardingFields) => {
    await updateProfile.mutateAsync({
      ...user,
      name,
      creatorOptIn: creatorOptIn === "in",
    });
    // Profile update was successful, now trigger verification email
    updateEmail.mutate(
      { email },
      {
        onSuccess: (resp) => {
          // Verification email was sent, set state to show verification code form
          if (resp.status === "VERIFICATION_SENT") {
            setStep(steps[1]);
          }
        },
      },
    );
  };

  // Send verification code to update email and subscribe to newsletters
  const verifySubmit = ({
    email,
    verificationCode,
    subscribeCreatorRoyalties,
    subscribeWeeklyHug,
  }: OnboardingFields) => {
    updateEmail.mutate(
      {
        email,
        verificationCode,
        subscribeCreatorRoyalties,
        subscribeWeeklyHug,
      },
      {
        onSuccess: () => {
          // Email verification worked, show success message before continuing
          setStep(steps[2]);
        },
      },
    );
  };

  if (step === steps[2]) {
    // figure out how to update onboarding page to go to slides, if needed
    return (
      <Container width="xs">
        <SBox>
          <Header>
            <H1 size="h3">Account Created</H1>

            <Text bold>
              Congratulations! You have successfully created an account and
              earned {constants.pointsEarnedEmailVerification} HUG Points.
            </Text>

            <Text size="xs">
              To earn an additional {constants.pointsEarnedOnboarding} HUG
              Points, continue on through our beginner&apos;s guide so you can
              get the most out of HUG.
            </Text>
          </Header>

          <FlexRow justifyContent="center">
            <Button variant="primary" size="md" onClick={onComplete}>
              Continue
            </Button>
          </FlexRow>
        </SBox>
      </Container>
    );
  }

  if (step === steps[1]) {
    return (
      <Container width="xs">
        <SBox>
          <SForm onSubmit={handleSubmit(verifySubmit)}>
            <Header>
              <Stack gap="sm">
                <Stack>
                  <IconHugLogo size="66px" primary="fg" />
                  <H1 size="h3">Verify Your Account</H1>
                </Stack>

                <Stack>
                  <Text bold>
                    To make sure you&apos;re real, we sent a verification code
                    to {enteredEmail}.
                  </Text>

                  <Text size="xs">
                    Check your inbox and enter the code below to continue and
                    earn your first {constants.pointsEarnedEmailVerification}{" "}
                    HUG Points!
                  </Text>
                </Stack>
              </Stack>
            </Header>

            <Label>
              Verification Code
              <Input
                {...register("verificationCode", { ...fieldOptions.required })}
              />
              {errors?.verificationCode && (
                <FieldError>{errors?.verificationCode.message}</FieldError>
              )}
            </Label>

            <FlexRow justifyContent="center">
              <Button
                type="submit"
                variant="primary"
                size="sm"
                disabled={updateEmail.isLoading}
              >
                Verify and Earn {constants.pointsEarnedEmailVerification}
                &nbsp;Hug&nbsp;Points
              </Button>
            </FlexRow>

            <Text textAlign="center" as="footer">
              <Button variant="text" size="xs" onClick={resetStep}>
                Use a different email address
              </Button>
            </Text>
          </SForm>
        </SBox>
      </Container>
    );
  }

  return (
    <Container width="xs">
      <SBox>
        <SForm onSubmit={handleSubmit(initialSubmit)}>
          <Header>
            <Stack gap="sm">
              <Stack>
                <IconHugLogo size="66px" primary="fg" />
                <H1 size="h3">Create An Account</H1>
              </Stack>
              <Stack>
                <Text bold>
                  Welcome to HUG! Complete the steps below to
                  customize&nbsp;your&nbsp;experience.
                </Text>
                <Text>
                  Creating an account allows you to fully explore HUG, connect
                  with creators more closely, and&nbsp;earn&nbsp;rewards.
                </Text>
              </Stack>
            </Stack>
          </Header>

          <Label>
            Set Your Display Name
            <RequiredText />
            <Input {...register("name", { ...fieldOptions.required })} />
            {errors?.name && <FieldError>{errors?.name.message}</FieldError>}
            {isDuplicateName && (
              <FieldError>Sorry, that display name is taken.</FieldError>
            )}
          </Label>

          <Label>
            Enter Your Email Address
            <RequiredText />
            <Input
              {...register("email", {
                ...fieldOptions.required,
                validate: validators.validEmail,
              })}
            />
            {errors?.email && <FieldError>{errors?.email.message}</FieldError>}
            {updateEmail.isError && (
              <>
                {updateEmail.error instanceof Error ? (
                  <FieldError>{updateEmail.error.message}</FieldError>
                ) : (
                  <AppError>Something went wrong</AppError>
                )}
              </>
            )}
          </Label>

          <Label as="fieldset">
            <Legend>
              Are you an artist?
              <br />
              <Text size="xs" as="span">
                Get access to and be notified of artist-specific features and
                tooling.
              </Text>
            </Legend>

            <FlexRow>
              <Radio
                {...register("creatorOptIn", { ...fieldOptions.required })}
                value="in"
                label="Yes, I'm an Artist"
              />
              <Radio
                {...register("creatorOptIn", { ...fieldOptions.required })}
                value="out"
                label="No, I'm just a Collector"
              />
            </FlexRow>

            {errors?.creatorOptIn && (
              <FieldError>{errors?.creatorOptIn.message}</FieldError>
            )}
          </Label>

          <Fieldset>
            <Legend>Subscribe to HUG&apos;s newsletters</Legend>

            <Checkbox
              {...register("subscribeWeeklyHug")}
              label="Subscribe to Weekly HUGs to hear about new offers, events, and product updates."
            />
            <Checkbox
              {...register("subscribeCreatorRoyalties")}
              label="Subscribe to Creator Royalties for free Web3 educational content and industry news."
            />
          </Fieldset>

          <FlexRow justifyContent="center">
            <Button
              type="submit"
              variant="primary"
              size="sm"
              disabled={updateEmail.isLoading}
              arrow="r"
            >
              Create My Account
            </Button>
          </FlexRow>
        </SForm>
      </SBox>
    </Container>
  );
}

const SBox = styled(Box)(() => ({
  flex: "0 0 auto",
  paddingTop: "2rem",
  paddingBottom: "2rem",
}));
const SForm = styled(Form)(() => ({
  gap: "1.5rem",
}));

const Header = styled.header({
  textAlign: "center",
});

export { EditProfileOnboarding };
