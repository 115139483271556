import {
  Stack,
  Link,
  H2,
  IconExternalLink,
  Text,
  ExpandContent,
  RichText,
} from "atoms";
import { useBreakpoint } from "utils/hooks";
import { formatURL, pluralize } from "utils/text";
import type { GalleryItem } from "../types";
import { GalleryBackButton } from "./GalleryBackButton";

type GalleryDetailsProps = {
  gallery: GalleryItem;
};

function GalleryDetails({ gallery }: GalleryDetailsProps) {
  const isDesktop = useBreakpoint("desktop");
  const domain = gallery.link && new URL(gallery.link).host.replace("www.", "");
  const isExternal = domain !== new URL(window.location.href).host;

  return (
    <Stack gap="xs">
      <Stack gap="xs">
        <GalleryBackButton artistId={gallery.ownerId} />

        <Stack gap="0">
          <H2 size="h3" textTransform="none">
            {gallery.title}
          </H2>
          <Text size="xxs">{pluralize(gallery.folderItemCount, "item")}</Text>
        </Stack>
      </Stack>
      {gallery.description && (
        <ExpandContent
          showLabel="Read more"
          hideLabel="Show less"
          linesVisible={isDesktop ? 6 : 2}
        >
          <RichText value={gallery.description} />
        </ExpandContent>
      )}
      {gallery.link && (
        <Text size="xs">
          <Link href={gallery.link} target="_blank">
            Learn more on {domain ?? formatURL(gallery.link)}{" "}
            {isExternal && <IconExternalLink size=".85em" />}
          </Link>
        </Text>
      )}
    </Stack>
  );
}

export { GalleryDetails };
