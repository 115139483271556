/**
 * Regular expression that matches _most_ email address strings
 *
 * See: https://www.regular-expressions.info/email.html
 */
const EMAIL_REGEX = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i;

/**
 * Regular expression that matches URL safe slugs and ids
 */
const SLUG_REGEX = /^[A-Za-z0-9_-]+$/;

/**
 * Regular Expression that matches slugs from social sites
 * Permissive - we may allow characters now permitted by some platforms.
 * NOTE: YouTube allows Spaces
 */
const HANDLE_REGEX = /^[A-Za-z0-9_.\s-]+$/;

/**
 * Regular expression that matches either a .eth domain or a Ethereum wallet address
 */
const ETH_WALLET_AND_DOMAIN_REGEX =
  /^0x[a-fA-F0-9]{40}$|^(?:[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?\.)+eth$/;
/**
 * Regular expression that matches _most_ URLs and must start with protocol
 */
const URL_REGEX =
  /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._+~#=]{1,256}\.[a-zA-Z0-9()]{1,24}\b(?:[-a-zA-Z0-9()@:%_+.~#?&/=]*)$/;

/**
 * Shared utilities to control form field input options and validation rules
 *
 * Usage:
 * ```js
 * <input {...register("password", { ...fieldOptions.required, ...fieldOptions.minLength(8) })}
 * ```
 */
const fieldOptions = {
  /**
   * Require this form field
   */
  required: { required: "This field is required" },

  /**
   * Ensure a text field is limited to a given character length
   */
  maxLength: (value: number) => ({
    maxLength: {
      value,
      message: `This field is limited to ${value} characters`,
    },
  }),

  /**
   * Ensure a text field is at least a given character length
   */
  minLength: (value: number) => ({
    minLength: {
      value,
      message: `This field requires at least ${value} characters`,
    },
  }),

  /**
   *
   */
  max: (value: number) => ({
    max: { value, message: `This number can't be above ${value}` },
  }),

  /**
   *
   */
  min: (value: number) => ({
    min: { value, message: `This number can't be below ${value}` },
  }),
} as const;

/**
 * Shared custom validators to control form field inputs
 *
 * Single usage:
 * ```js
 * <input {...register("art_tags", { validate: validators.limitTags })} />
 * ```
 *
 * Multiple usage:
 * ```js
 * <input {...register("email", { validate: {
 *   validEmail: validators.validEmail,
 *   other: validators.other,
 * }})}
 * ```
 */
const validators = {
  /**
   * Limit selected tags for Reviews
   */
  limitReviewTags: (value?: string[]) =>
    (value?.length ?? 0) <= 2 || "You can only select up to 2 tags",

  /**
   * Ensure text is not solely composed of whitespace characters
   */
  isNotEmpty: (value?: string) =>
    value === undefined || value.trim() !== "" || "This field can't be empty",

  /**
   * Ensure text matches email regex
   */
  validEmail: (value?: string) =>
    !value || EMAIL_REGEX.test(value) || "Please enter a valid email address",

  /**
   * Ensure text matches password constraints
   */
  validPassword: (value: string) =>
    value.length >= 10 || "Passwords must be at least 10 characters long",

  /**
   * Ensure text matches URL safe regex
   */
  validSlug: (value?: string) =>
    !value ||
    SLUG_REGEX.test(value) ||
    "This field can only contain letters (A-Z, a-z), numbers (0-9), dashes (-), or underscores (_)",

  /**
   * Ensure text matches .eth domain
   */
  validEthWalletOrDomainAddress: (value?: string) =>
    !value ||
    ETH_WALLET_AND_DOMAIN_REGEX.test(value) ||
    "Please enter a valid Ethereum wallet address. (i.e. - your-domain.eth or 0x1234...abcd)",

  /**
   * Ensure text is a valid URL
   */
  validURL: (value?: string) =>
    !value ||
    URL_REGEX.test(value) ||
    "Please enter a valid URL starting with 'https://' (e.g. https://thehug.xyz)",

  /**
   * Create a validator that ensures the entered value is equal to or later than the given
   * start date. If either the start date or entered date are undefined, validation passes.
   */
  validEndDate: (start?: string) => (value?: string) =>
    !start ||
    !value ||
    new Date(start) <= new Date(value) ||
    "End date must be equal to or later than the start date",

  /**
   * Validator for social handles
   */
  validSocialHandle: (value?: string) =>
    !value ||
    HANDLE_REGEX.test(value) ||
    "This field can only contain letters (A-Z, a-z), numbers (0-9), dashes (-), underscores (_), or spaces.",
} as const;

export { fieldOptions, validators };
