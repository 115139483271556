import type { CheckoutSessionRequest, PurchaseHistoryRequest } from "../types";

const checkoutKeys = {
  base: ["checkout"] as const,
  sessions: () => [...checkoutKeys.base, "sessions"] as const,
  session: (request: CheckoutSessionRequest) =>
    [...checkoutKeys.sessions(), request] as const,
  statuses: () => [...checkoutKeys.base, "statuses"] as const,
  status: (id?: string) => [...checkoutKeys.statuses(), id] as const,
};

const purchaseKeys = {
  base: ["purchases"] as const,
  lists: () => [...purchaseKeys.base, "list"] as const,
  list: (params?: PurchaseHistoryRequest) =>
    [...purchaseKeys.lists(), params] as const,
};

const purchaseTotalKeys = {
  base: ["purchaseTotal"] as const,
  details: () => [...purchaseTotalKeys.base, "detail"] as const,
  detail: (productId: string) =>
    [...purchaseTotalKeys.details(), productId] as const,
};

export { checkoutKeys, purchaseKeys, purchaseTotalKeys };
