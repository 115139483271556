/**
 * Generate a thumbnail from video element's current frame using a canvas element
 */
const generateThumbnail = (
  element: HTMLCanvasElement | null,
  video: HTMLVideoElement | null,
) => {
  if (element && video) {
    // Set dimensions of canvas to match src video dimensions
    element.width = video.videoWidth;
    element.height = video.videoHeight;

    // Draw the video frame to canvas context
    const ctx = element.getContext("2d");
    ctx?.drawImage(video, 0, 0, element.width, element.height);

    // Convert the canvas frame to a file and return it
    return new Promise<File>((resolve) => {
      ctx?.canvas.toBlob((blob) => {
        if (blob instanceof Blob) {
          const thumbnailFile = new File([blob], "generated-thumbnail.jpg", {
            type: "image/jpeg",
          });
          resolve(thumbnailFile);
        }
      });
    });
  }
  return null;
};

export { generateThumbnail };
