import { isProduction } from "config";

/** Given a URL from API that could be from AWS upload bucket, ensure that it is being served from our domain */
const parseS3URL = (url: string) => {
  // If url points to AWS S3 bucket, use our domain to access it
  const { pathname, host } = new URL(url);
  if (host.includes("amazonaws.com")) {
    const origin = isProduction
      ? "https://thehug.xyz"
      : "https://thesecrethug.xyz";
    return `${origin}${pathname}`;
  }
  return url;
};

export { parseS3URL };
