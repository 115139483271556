import { useAuth } from "features/auth";
import { useQuery } from "react-query";
import { api } from "utils/api";
import { profileKeys } from "./queryKeys";

type CurationOpportunities = {
  reviews: number;
  applications: number;
  openCalls: number;
  total: number;
};

const getCurationOpportunities = () =>
  api.get<CurationOpportunities>(`/profile/curation`);

const useCurationOpportunities = () => {
  const { user } = useAuth();
  return useQuery(profileKeys.curation, getCurationOpportunities, {
    enabled: !!user,
  });
};

export { useCurationOpportunities };
