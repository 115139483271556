import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { api } from "utils/api";
import type { BannerMakerFormFields, BannerMakerResponse } from "../types";

const postBannerMaker = (
  formFields: Omit<BannerMakerFormFields, "agreed" | "variant">,
) =>
  api.post<BannerMakerResponse>("/banners/twitter", {
    body: JSON.stringify({
      ...formFields,
      images: formFields.images.map(({ url }) => url),
    }),
  });

const useBannerMakerForm = () => {
  const form = useForm<BannerMakerFormFields>({
    defaultValues: {
      images: [],
    },
  });

  const mutation = useMutation(postBannerMaker);

  return {
    ...form,
    mutation,
  };
};

export { useBannerMakerForm };
