import { useInfiniteQuery } from "react-query";
import { ApiError, api } from "utils/api";
import { InstagramMediaFields, InstagramMediaResponse } from "../types";

const fields: InstagramMediaFields[] = [
  "id",
  "caption",
  "media_type",
  "media_url",
  "permalink",
  "timestamp",
  "username",
  // NOTE: These values conditionally exist based on other values, like `media_type`
  "thumbnail_url",
  "is_shared_to_feed",
];

// Note this API will automatically expand albums into their individual images
const getMedia = (authToken: string, searchAfter?: string) =>
  api.post<InstagramMediaResponse>("/social/instagram/media", {
    body: JSON.stringify({ authToken, fields, searchAfter, limit: 8 }),
  });

const useInstagramMedia = (accessToken?: string) =>
  useInfiniteQuery<InstagramMediaResponse, ApiError>(
    ["instagram", accessToken, "media"],
    ({ pageParam }) => getMedia(accessToken ?? "", pageParam),
    {
      enabled: !!accessToken,
      keepPreviousData: true,
      getNextPageParam: (lastPage) => lastPage.paging?.next,
    },
  );

export { useInstagramMedia };
