import type { LineItem } from "../types";

const isLineItem = (line: unknown): line is LineItem =>
  !!line &&
  typeof line === "object" &&
  "id" in line &&
  typeof line.id === "string" &&
  "quantity" in line &&
  typeof line.quantity === "number" &&
  "merchandise" in line &&
  typeof line.merchandise === "object" &&
  "cost" in line &&
  typeof line.cost === "object";

export { isLineItem };
