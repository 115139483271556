import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { parseGid } from "../utils/shopifyId";
import { productAdminKeys, productKeys } from "./queryKeys";
import {
  ProductAdminResp,
  transformAdminProduct,
} from "./useProductAdminQuery";

type UpdateProductOptionsReq = {
  id: string;
  productOptions: {
    name: string;
    values: string[];
  }[];
};

type UpdateProductOptionsResp = {
  shopifyProductId: string;
  product: ProductAdminResp;
};

const putUpdateProductOptions = ({ id, ...rest }: UpdateProductOptionsReq) =>
  api
    .put<UpdateProductOptionsResp>(`/shop/products/${id}/templateVariants`, {
      body: JSON.stringify(rest),
    })
    .then(({ shopifyProductId, product }) => ({
      shopifyProductId,
      product: transformAdminProduct(product),
    }));

const useUpdateProductOptions = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData: UpdateProductOptionsReq) =>
      putUpdateProductOptions({ ...formData, id }),
    {
      onSuccess: ({ shopifyProductId, product }) => {
        queryClient.setQueryData(
          productAdminKeys.detail(parseGid(shopifyProductId).id),
          product,
        );
        queryClient.invalidateQueries(productKeys.detail(product.handle));
      },
    },
  );
};

export type { UpdateProductOptionsReq };
export { useUpdateProductOptions };
