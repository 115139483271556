import styled from "@emotion/styled";
import type { Metaobject } from "@shopify/hydrogen-react/storefront-api-types";
import { Accordion, Stack } from "atoms";
import { ShopifyRichText } from "./ShopifyRichText";

type ProductDetailAccordionProps = {
  details: Partial<Metaobject>[];
};

function ProductDetailAccordion({ details }: ProductDetailAccordionProps) {
  if (!details.length) {
    return null;
  }

  return (
    <Stack gap="0">
      {details.map(({ fields, id }) => {
        const title = fields?.find(({ key }) => key === "title")?.value;
        const value = fields?.find(({ key }) => key === "content")?.value;

        if (typeof title !== "string" || typeof value !== "string") {
          return null;
        }

        return (
          <ProductAccordion key={id} heading={title}>
            <ShopifyRichText value={value} />
          </ProductAccordion>
        );
      })}
    </Stack>
  );
}

const ProductAccordion = styled(Accordion)(({ theme }) => ({
  borderBottomColor: theme.colors.fg20,
  summary: {
    color: theme.colors.fg,
    fontSize: theme.fontSizes.xs,
    padding: "1em 0 0 0",
  },
  "summary + *": {
    fontSize: 16,
  },
  "&:first-of-type": {
    borderTop: "1px solid",
    borderTopColor: theme.colors.fg20,
  },
}));

export { ProductDetailAccordion };
