import { useMutation, useQuery, useQueryClient } from "react-query";
import { useAuth } from "features/auth";
import type { PublicProfile, User } from "features/auth";
import { api, ApiError } from "utils/api";
import type { ProfileResp, RewardBalance } from "../types";
import { profileKeys } from "./queryKeys";

const getProfileById = (id?: string) =>
  id
    ? api.get<PublicProfile>(`/users/${id}`)
    : Promise.reject(new Error("Invalid user id"));

/**
 * Get a user's public profile
 */
const useProfile = (id?: string) =>
  useQuery(profileKeys.detail(id), () => getProfileById(id), {
    enabled: !!id,
  });

const getProfilePoints = (id?: string) =>
  id
    ? api.get<RewardBalance>(`/users/${id}/points`)
    : Promise.reject(new Error("Invalid user id"));

/**
 * Use the specified user or the signed in user
 */
const useProfilePoints = (id?: string) =>
  useQuery<RewardBalance, ApiError>(
    profileKeys.balance(id),
    () => getProfilePoints(id),
    {
      enabled: !!id,
    },
  );

const updateProfile = (updates: User) =>
  api.put<ProfileResp>("/profile", {
    body: JSON.stringify(updates),
  });

/**
 * HACK: shouldRefreshAuth should never be used or set to false outside of the onboarding component!
 *       Prevents auth context from providing new value as prop to onboarding which
 *       remounts the form and breaks the mutation.
 */
const useUpdateProfile = (shouldRefreshAuth = true) => {
  const { refreshAuth } = useAuth();
  const queryClient = useQueryClient();

  const { mutate, ...mutation } = useMutation(updateProfile, {
    onSuccess: (resp) => {
      // Updated profile is the currently logged in user, refresh Auth context in case roles,
      // utilities, or overrides have changed
      if (shouldRefreshAuth) {
        refreshAuth(resp);
        queryClient.setQueryData(profileKeys.detail(resp.user.id), resp.user);
      }
    },
  });

  return {
    updateProfile: mutate,
    ...mutation,
  };
};

const resetStagingProfileBalance = () =>
  api.get<ProfileResp>("/profile/stagingPointsReset");

const useResetStagingProfileBalance = () => {
  const { user, refreshAuth } = useAuth();
  const queryClient = useQueryClient();

  return useMutation(resetStagingProfileBalance, {
    onSuccess: () => {
      refreshAuth();
      queryClient.invalidateQueries(profileKeys.balance(user?.id ?? ""));
    },
  });
};

const stagingAirDropPoints = (points: number) =>
  api.get<ProfileResp>(`/profile/stagingPointsGrant?points=${points}`);

const useStagingAirDropPoints = () => {
  const { user } = useAuth();
  const queryClient = useQueryClient();

  const { mutate, ...mutation } = useMutation(stagingAirDropPoints, {
    onSuccess: () => {
      queryClient.invalidateQueries(profileKeys.balance(user?.id ?? ""));
    },
  });
  return { airDrop: mutate, ...mutation };
};

export {
  useProfilePoints,
  useUpdateProfile,
  useResetStagingProfileBalance,
  useStagingAirDropPoints,
  useProfile,
};
