import { api } from "utils/api";
import { useQuery } from "react-query";
import type { GalleryItem, GalleryItemsForFolderParams } from "../types";
import { galleryKeys } from "./queryKeys";

/**
 * Get a list of gallery items - Pass the ID for ROOT or for Gallery Folder
 */
const getGalleryItems = async ({ galleryId }: GalleryItemsForFolderParams) =>
  api.get<GalleryItem[]>(`/gallery/artists/${galleryId}/items`);

/**
 * Load all gallery items for Folder or Root in the correctly sorted order
 */
const useGalleryItems = ({ galleryId }: GalleryItemsForFolderParams) =>
  useQuery(galleryKeys.items({ galleryId }), () =>
    getGalleryItems({ galleryId })
  );

export { useGalleryItems };
