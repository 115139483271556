import { api } from "utils/api";
import type { MagicLink } from "../types";

/**
 * Async API call to refresh JWT
 */
const getMagicLink = () =>
  api.post<MagicLink>("/profile/magicLink", {
    body: JSON.stringify({}),
  });

export { getMagicLink };
