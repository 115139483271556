import type { SearchRequest } from "../types";

const searchKeys = {
  base: ["search"] as const,
  default: (params: SearchRequest) => [...searchKeys.base, params] as const,
  infinite: (params: SearchRequest) =>
    [...searchKeys.base, "infinite", params] as const,
  incremental: (params: SearchRequest) =>
    [...searchKeys.base, "incremental", params] as const,
};

export { searchKeys };
