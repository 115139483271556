import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { productCreatedByArtistKeys } from "./queryKeys";

type CreateProductReq = {
  artistId: string;
  assetUrl: string;
  productName: string;
  templateId: string;
  mockupUrl?: string;
};

type CreateProductResp = {
  shopifyProductId: string;
};

const postCreateProduct = (formData: CreateProductReq) =>
  api.post<CreateProductResp>("/shop/products", {
    body: JSON.stringify(formData),
  });

/**
 * Create a shopify Product
 */

const useCreateProduct = () => {
  const queryClient = useQueryClient();

  return useMutation(postCreateProduct, {
    onSuccess: (_response, request) => {
      queryClient.invalidateQueries(
        productCreatedByArtistKeys.detail(request.artistId),
      );
    },
  });
};

export type { CreateProductReq };
export { useCreateProduct };
