import * as React from "react";
import { Navigate, useLocation } from "react-router-dom";
import { AppError, H1, Link, LoadingSpinner, Text, AllCaps } from "atoms";
import { useToggle } from "utils/hooks";
import { isRedirectState } from "utils/routing";
import { useAuth } from "./AuthProvider";
import { AuthWrapper } from "./AuthWrapper";
import { ConnectWallet, type ConnectHandler } from "./ConnectWallet";
import { EmailSignIn } from "./EmailSignIn";

type SignInProps = {
  isCreateAccount?: boolean;
};

function SignIn({ isCreateAccount = false }: SignInProps) {
  // Get any redirect state from router
  const location = useLocation();

  const [isConnectingWallet, toggleConnectMethod, setIsConnecting] =
    useToggle(isCreateAccount);

  React.useEffect(() => {
    setIsConnecting(isCreateAccount);
  }, [setIsConnecting, isCreateAccount]);

  const redirectState = isRedirectState(location.state)
    ? location.state
    : undefined;

  const { signIn, error, isError, isLoading, user, roles } = useAuth();

  const onConnect: ConnectHandler = (connection) => {
    signIn(connection);
  };

  // Success. We have a signed in user, figure out where to send them
  if (user) {
    if (!roles.isCollector && user.forceOnboarding) {
      // User needs to complete onboarding, pass along any redirect state for after completion as
      // well as a boolean for whether this is a new account or existing one
      return (
        <Navigate
          to="/onboarding"
          state={{ ...redirectState, isCreateAccount }}
          replace
        />
      );
    }

    // Otherwise send them back where they came from or to the home page
    return <Navigate to={redirectState?.from ?? "/"} replace />;
  }

  return (
    <AuthWrapper
      title="Connect Wallet"
      header={
        isCreateAccount ? (
          <>
            <H1 size="h3">Create an Account by Connecting Your&nbsp;Wallet</H1>

            <Text size="xs" textAlign="center">
              To leave reviews and get the most out of <AllCaps>Hug</AllCaps>
              you will need an account&nbsp;to&nbsp;continue.
            </Text>
          </>
        ) : (
          <>
            <H1 size="h3">
              {isConnectingWallet
                ? "Sign In By Connecting Your Wallet"
                : "Sign In With Email"}
            </H1>

            <Text size="xs" textAlign="center">
              Sign in to start leaving reviews and leveling up
              for&nbsp;your&nbsp;contributions.
            </Text>
          </>
        )
      }
    >
      {isError && <AppError>{error}</AppError>}

      {isConnectingWallet ? (
        <ConnectWallet
          showEmailSignIn={!isCreateAccount ? toggleConnectMethod : undefined}
          onConnect={onConnect}
        />
      ) : (
        <EmailSignIn toggleConnectMethod={toggleConnectMethod} />
      )}

      {isLoading && <LoadingSpinner />}

      {isCreateAccount && isConnectingWallet && (
        <Text size="xs" textAlign="center">
          Already have an account?{" "}
          <Link to="/sign-in" state={redirectState}>
            Sign In
          </Link>
        </Text>
      )}

      {!isCreateAccount &&
        isConnectingWallet &&
        location.pathname !== "create-account" && (
          <Text size="xs" textAlign="center">
            Don&apos;t have an account?{" "}
            <Link to="/register" state={redirectState}>
              Create an account
            </Link>
          </Text>
        )}
    </AuthWrapper>
  );
}

export { SignIn };
