import * as React from "react";
import styled from "@emotion/styled";
import { hexToRGB } from "styles/styleUtils";

type ScrollWrapperProps = {
  /** The pages background color */
  bg?: string;
  /** The minimum width of cells on tablet and mobile. */
  minWidth?: React.CSSProperties["minWidth"];
  /** The width of cells on tablet and mobile. Default: 33vw */
  width?: React.CSSProperties["width"];
};

const ScrollWrapper = styled("div")<ScrollWrapperProps>(
  ({
    bg = "white",
    minWidth = "calc(10em + 12vw)",
    width = "33vw",
    theme,
  }) => ({
    "@media (max-width: 830px)": {
      marginBottom: "2rem",
      marginLeft: "-1rem",
      marginRight: "-1rem",
      position: "relative",
      width: "auto",
      "&::after": {
        content: "''",
        position: "absolute",
        right: 0,
        top: 0,
        bottom: 0,
        width: "2.5rem",
        backgroundImage: !theme.colors[bg]
          ? undefined
          : `linear-gradient(90deg, rgb(255 255 255 / 0) 0%, ${hexToRGB(
              theme.colors[bg],
              0.33,
            )} 15%, ${theme.colors[bg]} 90%)`,
      },
      "&>div": {
        overflow: "auto",
        paddingRight: "2.5rem",
      },
      table: {
        marginBottom: "1rem",
        marginLeft: "1rem",
        minWidth: "100%",
      },
      "td,th": {
        width,
        minWidth,
      },
    },
    "@media (min-width: 600px) and (max-width: 830px)": {
      marginLeft: "-3.2rem",
      marginRight: "-3.2rem",
      maxWidth: "calc(100vw + 6.4rem)",
      "&::after": {
        width: "3.7rem",
      },
      "&>div": {
        paddingRight: "3.7rem",
      },
      table: {
        marginLeft: "3.2rem",
      },
    },

    "@media (min-width: 831px)": {
      width: "100%",
      maxWidth: "100%",
    },
  }),
);

const RegularWrapper = styled.div();

const TableElement = styled("table")(({ theme }) => ({
  borderCollapse: "collapse",
  margin: "2rem 0",
  width: "100%",
  "&, tr, th, td, tbody, thead, tfoot": { fontSize: theme.fontSizes.xxs },
  p: {
    marginBottom: ".5em",
  },
  "th,td": {
    border: `1px solid ${theme.colors.fg20}`,
    padding: theme.spacing.sm,
    textAlign: "left",
    verticalAlign: "top",
  },
}));

type TableProps = {
  className?: string;
  scroll?: boolean;
} & ScrollWrapperProps &
  React.ComponentProps<typeof TableElement>;

function Table({
  children,
  className,
  scroll,
  bg,
  width,
  minWidth,
  ...props
}: TableProps) {
  const Wrapper = scroll ? ScrollWrapper : RegularWrapper;
  const wrapperProps = scroll
    ? { bg, minWidth, width, className }
    : { className };

  return (
    <Wrapper {...wrapperProps}>
      <div>
        <TableElement {...props}>{children}</TableElement>
      </div>
    </Wrapper>
  );
}
const TdNumbers = styled.td({
  "table &": {
    textAlign: "right",
    fontVariantNumeric: "tabular-nums",
  },
});

export { Table, TdNumbers };
