import * as React from "react";
import {
  useLocation,
  useOutletContext,
  useSearchParams,
} from "react-router-dom";
import type { Artist } from "features/reviewable";
import type { GalleryItemType } from "../types";

type EditorMeta = { itemId: string; itemType: GalleryItemType };
type EditorAction = null | "add" | "order" | "edit" | "delete";

/**
 * Manage portfolio editor state and state setter
 */
const useEditorState = () => {
  const [searchParams] = useSearchParams();
  const location = useLocation();

  const [editorAction, setAction] = React.useState<EditorAction>(null);
  const [editorMeta, setMeta] = React.useState<EditorMeta>();

  function setEditorState(
    action: Exclude<EditorAction, "delete" | "edit">,
  ): void;
  function setEditorState(action: "delete", meta: EditorMeta): void;
  function setEditorState(action: "edit", meta?: EditorMeta): void;
  function setEditorState(action: EditorAction, meta?: EditorMeta) {
    setAction(action);
    setMeta(meta);
  }

  React.useEffect(() => {
    if (location.state?.editorAction === "edit") {
      setEditorState("edit");
    } else if (searchParams.get("code")) {
      setEditorState("add");
    }
  }, [location, searchParams, setEditorState]);

  return [editorAction, setEditorState, editorMeta] as const;
};

type EditorContext = {
  artist: Artist;
  editorAction: EditorAction;
  setEditorAction: ReturnType<typeof useEditorState>[1];
} & Partial<EditorMeta>;

/**
 * Access portfolio editor state in child routes
 */
const useEditorContext = () => {
  const context = useOutletContext<EditorContext>();

  if (!context) {
    throw new Error(
      "useEditorContext() may be used only within the context of the <LayoutPortfolioEditor> component",
    );
  }

  return context;
};

export type { EditorContext };
export { useEditorContext, useEditorState };
