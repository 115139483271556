import * as React from "react";
import type { CheckoutSessionRequest, PriceId } from "../types";
import { CHECKOUT_SESSION_ID_PARAM } from "../utils/stripe";
import { isCheckoutSessionRequest } from "../utils/typeGuards";

/**
 * LocalStorage key for persisting items for Checkout between sessions
 */
const HUG_CART = "HUG_CART";

type CartState = CheckoutSessionRequest | null;

/**
 * Safely retrieve persisted request for Checkout, or null
 */
const getPersistedCart = (): CartState => {
  const str = window.localStorage.getItem(HUG_CART) ?? "";
  // JSON.parse might fail or value might have been corrupted, hence try/catch AND type guard
  try {
    const json = JSON.parse(str);
    return isCheckoutSessionRequest(json) ? json : null;
  } catch {
    return null;
  }
};

/**
 * Persist request for Checkout as stringified JSON
 */
const persistCart = (request: CartState) => {
  if (request) {
    window.localStorage.setItem(HUG_CART, JSON.stringify(request));
  } else {
    window.localStorage.removeItem(HUG_CART);
  }
};

type CartAction =
  | {
      type: "createCart";
      cart: Partial<CheckoutSessionRequest>;
    }
  | {
      type: "addItem";
      priceId: PriceId;
    }
  | {
      type: "editItem";
      priceId: PriceId;
      quantity: number;
    }
  | {
      type: "deleteItem";
      priceId: PriceId;
    }
  | {
      type: "resetCart";
    };

const cartReducer: React.Reducer<CartState, CartAction> = (state, action) => {
  let newState: CartState;
  switch (action.type) {
    case "createCart": {
      // Create a cart with some defaults if values are not initially provided
      newState = {
        // After checkout, return to the generic checkout confirmation page with injected sessionId
        returnUrl: `${window.location.origin}/checkout/confirmation?${CHECKOUT_SESSION_ID_PARAM}`,
        // If not provided, create empty cart
        lineItems: [],
        ...action.cart,
      };
      break;
    }
    case "addItem": {
      const { priceId } = action;
      newState = !state
        ? state
        : {
            ...state,
            lineItems: [...state.lineItems, { priceId, quantity: 1 }],
          };
      break;
    }
    case "editItem": {
      const { priceId, quantity } = action;
      newState = !state
        ? state
        : {
            ...state,
            lineItems: state.lineItems.map((lineItem) =>
              lineItem.priceId === priceId ? { priceId, quantity } : lineItem,
            ),
          };
      break;
    }
    case "deleteItem": {
      const { priceId } = action;
      newState = !state
        ? state
        : {
            ...state,
            lineItems: state.lineItems.filter(
              (lineItem) => lineItem.priceId !== priceId,
            ),
          };
      break;
    }
    case "resetCart": {
      newState = null;
      break;
    }
    default: {
      throw new Error("Invalid Cart action type");
    }
  }

  // Persist any changes to cart state as they happen
  persistCart(newState);

  return newState;
};

const CartContext = React.createContext<CartState>(null);
const CartDispatchContext =
  React.createContext<null | React.Dispatch<CartAction>>(null);

function StripeProvider({ children }: { children: React.ReactNode }) {
  const [lineItems, dispatch] = React.useReducer(
    cartReducer,
    getPersistedCart(),
  );

  return (
    <CartDispatchContext.Provider value={dispatch}>
      <CartContext.Provider value={lineItems}>{children}</CartContext.Provider>
    </CartDispatchContext.Provider>
  );
}

const useCart = () => {
  const cartContext = React.useContext(CartContext);
  const dispatch = React.useContext(CartDispatchContext);

  if (!dispatch) {
    throw new Error(
      "useCart() may be used only within the context of a <StripeProvider> component",
    );
  }

  const createCart = (cart: Partial<CheckoutSessionRequest>) =>
    dispatch({ type: "createCart", cart });
  const resetCart = () => dispatch({ type: "resetCart" });
  const addItem = (priceId: PriceId) => dispatch({ type: "addItem", priceId });
  const editItem = (priceId: PriceId, quantity: number) =>
    dispatch({ type: "editItem", priceId, quantity });
  const deleteItem = (priceId: PriceId) =>
    dispatch({ type: "deleteItem", priceId });

  return {
    cart: cartContext,
    createCart,
    resetCart,
    addItem,
    editItem,
    deleteItem,
  };
};

export { StripeProvider, useCart };
