import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { parseGid } from "../utils/shopifyId";
import type { ProductStatus } from "../types";
import {
  productAdminKeys,
  productKeys,
  productMediaAdminKeys,
} from "./queryKeys";

type UpdateProductDetailsReq = {
  handle?: string;
  title?: string;
  descriptionHtml?: string;
  mediaIDsToDelete?: string[];
  mediaIDsToSequence?: string[];
  mediaURLsToAdd?: string[];
  status?: ProductStatus;
  // Maximum Quantity - For NFT and Physical Goods (0 means no maximum)
  maximumQuantity?: string;
  // Other NFT Specific Fields
  tokenName?: string;
  tokenDescription?: string;
  royaltyPercentage?: string;
  contractId?: string;
};

type UpdateProductDetailsResp = {
  shopifyProductId: string;
};

const putUpdateProductDetails = ({
  id,
  ...rest
}: UpdateProductDetailsReq & { id: string }) =>
  api.put<UpdateProductDetailsResp>(`/shop/products/${id}`, {
    body: JSON.stringify({
      // required parameters for this request body
      mediaIDsToDelete: [],
      mediaIDsToSequence: [],
      mediaURLsToAdd: [],
      // actual updates from formdata
      ...rest,
    }),
  });

const useUpdateProductDetails = (id: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData: UpdateProductDetailsReq) =>
      putUpdateProductDetails({ ...formData, id }),
    {
      onSuccess: ({ shopifyProductId }, { handle }) => {
        // Invalidate customer facing product query by handle
        queryClient.invalidateQueries(productKeys.detail(handle));
        // Invalidate admin facing product query by id
        queryClient.invalidateQueries(
          productAdminKeys.detail(parseGid(shopifyProductId).id),
        );
        // Invalidate separate admin facing product query for media
        queryClient.invalidateQueries(productMediaAdminKeys.detail(id));
        // And invalidate the cached products listed for each artist's shop manager screen
        queryClient.invalidateQueries(productAdminKeys.lists());
      },
    },
  );
};

export type { UpdateProductDetailsReq };
export { useUpdateProductDetails };
