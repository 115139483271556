import styled from "@emotion/styled";
import {
  IconEthereumLogo,
  IconSolana,
  IconApecoin,
  IconAvalanche,
  IconBitcoin,
  IconCelo,
  IconCardano,
  IconFlow,
  IconImx,
  IconKlaytn,
  IconKusama,
  IconOmnichain,
  IconPolygon,
  IconTezos,
} from "./icons";

type UnitMap = {
  [key: string]: {
    label: string;
    Icon?: typeof IconEthereumLogo;
    abbr: string;
  };
};

const unitMap: UnitMap = {
  apecoin: {
    label: "Apecoin",
    Icon: IconApecoin,
    abbr: "APE",
  },
  avalanche: {
    label: "Avalanche",
    Icon: IconAvalanche,
    abbr: "AVAX",
  },
  bitcoin: {
    label: "Bitcoin",
    Icon: IconBitcoin,
    abbr: "BTC",
  },
  cardano: {
    label: "Cardano",
    Icon: IconCardano,
    abbr: "ADA",
  },
  celo: {
    label: "Celo",
    Icon: IconCelo,
    abbr: "CELO",
  },
  ethereum: {
    label: "Ethereum",
    Icon: IconEthereumLogo,
    abbr: "ETH",
  },
  flow: {
    label: "Flow",
    Icon: IconFlow,
    abbr: "FLOW",
  },
  imx: {
    label: "Imx",
    Icon: IconImx,
    abbr: "IMX",
  },
  klaytn: {
    label: "Klaytn",
    Icon: IconKlaytn,
    abbr: "KLAY",
  },
  kusama: {
    label: "Kusama",
    Icon: IconKusama,
    abbr: "KSM",
  },
  omnichain: {
    label: "Omnichain",
    Icon: IconOmnichain,
    abbr: "Omnichain",
  },
  polygon: {
    label: "Polygon",
    Icon: IconPolygon,
    abbr: "MATIC",
  },
  solana: {
    label: "Solana",
    Icon: IconSolana,
    abbr: "SOL",
  },
  "ethereum on polygon": {
    label: "Ethereum on Polygon",
    Icon: IconEthereumLogo,
    abbr: "ETH",
  },
  tezos: {
    label: "Tezos",
    Icon: IconTezos,
    abbr: "XTZ",
  },
  // fiat currencies
  usd: {
    label: "US Dollar",
    abbr: "USD",
  },
  // EUR: {
  //   abbr: "EUR",
  // },
  // CAD: {
  //   abbr: "CAD",
  // },
  // GBP: {
  //   abbr: "GBP",
  // },
  // JPY: {
  //   abbr: "JPY",
  // },
  // CNY: {
  //   abbr: "CNY",
  // },
  // KRW: {
  //   abbr: "KRW",
  // },
};

const getUnit = (unit: string) => {
  const mappedItem = unitMap[unit.toLowerCase()];
  return mappedItem ?? { Icon: null, abbr: null };
};

/**
 * Given a unit, return the chain's SVG Icon
 */
function CurrencyIcon({ unit, ...props }: { unit: string }) {
  const { Icon, abbr } = getUnit(unit);
  return Icon ? (
    <Icon aria-label={abbr} title={abbr} role="img" {...props} />
  ) : null;
}

interface CurrencyProps {
  value?: number | string;
  unit?: string;
  defaultValue?: string;
}

/**
 * Given a value and unit, output value prefixed with currency SVG
 *
 * If unit is not recognized, output value suffixed with given unit string
 * NOTE: for accessibility notes, see https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA/Roles/img_role#svg_and_roleimg
 */
function Currency({ value, unit = "", defaultValue = "-" }: CurrencyProps) {
  const { Icon, abbr } = getUnit(unit);

  // Blockchain currency
  if (Icon) {
    return (
      <SCurrency>
        <CurrencyIcon unit={unit} />
        <span>{value ?? defaultValue}</span>
      </SCurrency>
    );
  }

  // Fiat currency
  const valueAsNumber = Number(value ?? 0);
  let stringWithSymbol;
  try {
    const localFormatter = new Intl.NumberFormat([], {
      style: "currency",
      currency: abbr,
    });
    stringWithSymbol = localFormatter.format(valueAsNumber);
  } catch (e) {
    if (
      e instanceof RangeError &&
      e.message.includes("Invalid currency code")
    ) {
      stringWithSymbol = valueAsNumber.toString();
    }
  }

  return <SCurrency>{stringWithSymbol}</SCurrency>;
}

const SCurrency = styled.div({
  alignItems: "center",
  display: "inline-flex",
  svg: {
    marginRight: 2,
    marginTop: -1,
    maxWidth: "1em",
    height: "1em",
    width: "auto",
  },
  span: {
    textTransform: "uppercase",
  },
});

export { Currency, CurrencyIcon, unitMap };
