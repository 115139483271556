import * as React from "react";
import styled from "@emotion/styled";
import { constants } from "config";
import {
  AllCaps,
  Avatar,
  Button,
  Checkbox,
  FlexRow,
  Form,
  IconChatHeart,
  IconHugLogo,
  IconMegaphone,
  IconStars,
  IconUsers,
  Stack,
  Text,
} from "atoms";
import { useAuth } from "features/auth";
import { Slider } from "features/misc";
import { useToggle } from "utils/hooks";
import { useUpdateProfile } from "../api/useProfile";

type OnboardingSliderProps = {
  isHugger: boolean;
};

function OnboardingSlider({ isHugger }: OnboardingSliderProps) {
  const slides = React.useMemo<
    React.ComponentProps<typeof Slider>["slides"]
  >(() => {
    const defaultSlides = [
      {
        id: 1,
        header: (
          <SliderHeaderContent>
            <IconHugLogo primary="white" />
          </SliderHeaderContent>
        ),
        title: (
          <>
            What is <AllCaps>Hug</AllCaps>?
          </>
        ),
        copy: (
          <SliderText>
            <AllCaps>Hug</AllCaps> leverages the power of community to help
            emerging artists get discovered while connecting to collectors who
            love&nbsp;them.
          </SliderText>
        ),
      },
      {
        id: 2,
        header: (
          <SliderHeaderContent>
            <IconMegaphone primary="white" />
          </SliderHeaderContent>
        ),
        title: "Curated by Community",
        copy: (
          <SliderText>
            We believe the future of curation is decentralized and
            community-driven. This means that <AllCaps>Hug</AllCaps> Pass and
            Meta Angels holders vote and decide on which creators get showcased
            on <AllCaps>Hug</AllCaps>, not us! Our community also moderates user
            contributions to make sure the quality of everything you see
            remains&nbsp;high.
          </SliderText>
        ),
      },
      {
        id: 3,
        header: (
          <SliderHeaderContent>
            <IconChatHeart primary="white" />
          </SliderHeaderContent>
        ),
        title: (
          <>
            How to Use <AllCaps>Hug</AllCaps>
          </>
        ),
        copy: (
          <SliderText>
            Use our search and filter functions to discover new artists, and
            uplift them further by leaving thoughtful reviews. Each approved
            review earns you <AllCaps>Hug</AllCaps> Points that can be exchanged
            for&nbsp;rewards.
          </SliderText>
        ),
      },
      {
        id: 4,
        header: (
          <SliderHeaderContent>
            <SampleReview />
          </SliderHeaderContent>
        ),
        title: "What Makes a Good Review",
        copy: (
          <SliderText>
            Good reviews are descriptive (don&apos;t hold back on length!),
            respectful, and true to your own personal experience. Here is an
            example that we&nbsp;love.
          </SliderText>
        ),
      },
      {
        id: 5,
        header: <IconStars primary="white" />,
        title: "Ready to Join Us?",
        copy: <FinalSlide />,
      },
    ];

    const passHolderSlides = [
      {
        id: 6,
        header: (
          <SliderHeaderContent>
            <IconUsers primary="white" />
          </SliderHeaderContent>
        ),
        title: "Curators vs. Senior Curators",
        copy: (
          <SliderText>
            As a member of our community, you serve as a Curator and get to vote
            artists on to <AllCaps>Hug</AllCaps>. Woohoo! After{" "}
            {constants.reviewsForSeniorCurator} reviews, you&apos;ll be promoted
            to Senior Curator, and will also get to approve and reject community
            reviews. All actions are rewarded with <AllCaps>Hug</AllCaps>
            &nbsp;Points.
          </SliderText>
        ),
      },
    ];

    // Splice in extra slides if user is a HUG pass holder
    return isHugger
      ? [
          ...defaultSlides.slice(0, 2),
          ...passHolderSlides,
          ...defaultSlides.slice(2),
        ]
      : defaultSlides;
  }, [isHugger]);

  return <Slider slides={slides} wrap={false} />;
}

function FinalSlide() {
  const [agreed, toggleAgreed] = useToggle(false);

  const { user } = useAuth();
  const { updateProfile, isLoading } = useUpdateProfile();

  // Update the user's profile which should refresh the Auth context, used above
  const completeReviewerTraining: React.FormEventHandler<HTMLFormElement> = (
    event,
  ) => {
    event.preventDefault();

    if (user) {
      updateProfile({
        ...user,
        completedReviewerOnboarding: true,
      });
    }
  };

  return (
    <Form onSubmit={completeReviewerTraining}>
      <SliderText>
        <AllCaps>Hug</AllCaps> is an uplifting, inclusive destination for all
        creators and collectors, regardless of their background.
      </SliderText>

      <SliderText>
        We do not tolerate the use of any hateful language in reviews or
        otherwise, and reserve the right to remove any user who does not abide
        by our code of conduct.
      </SliderText>

      <CheckboxWrapper>
        <Checkbox
          label={
            <>
              I agree to uphold the values of <AllCaps>Hug</AllCaps> in
              empowering my fellow artists and art lovers
            </>
          }
          checked={agreed}
          onChange={toggleAgreed}
        />
      </CheckboxWrapper>

      <SliderText textAlign="center">
        <Button
          type="submit"
          variant="secondary"
          size="sm"
          disabled={!agreed || isLoading}
        >
          Continue and Earn {constants.pointsEarnedOnboarding}
          &nbsp;Hug&nbsp;Points
        </Button>
      </SliderText>
    </Form>
  );
}

const SliderText = styled(Text)(({ theme }) => ({
  color: theme.colors.fg,
  fontSize: theme.fontSizes.sm,
}));

const SliderHeaderContent = styled.div({
  aspectRatio: "12 / 7",
  display: "grid",
  placeItems: "center",
  maxWidth: 400,
  margin: "0 auto",
});

function SampleReview() {
  return (
    <ReviewBox>
      <FlexRow gap="6px" alignItems="center">
        <SAvatar
          size={35}
          src="https://thehug.xyz/assets/users/cc23cb1e-feff-4ebc-b2d1-f1df117989ff/avatar-546f1753-c1d6-451b-a783-79f1611b3762"
        />
        <Text size="xs" bold>
          Aestrild
        </Text>
      </FlexRow>
      <Text size="xxxs">
        <strong>This artist is for you if:</strong> You like modern, abstract,
        paintings with a rainbow color palette, clean geometric patterns, and
        subtle textures. Amber&apos;s paintings are deceptively simple at first,
        but the more you look the more they pull you in. She is able to convey a
        clear feeling with just a few shapes, like the visual arts counterpart
        to poetry.
      </Text>
    </ReviewBox>
  );
}

const SAvatar = styled(Avatar)({
  "body div &": {
    width: 35,
    height: 35,
    maxWidth: 35,
    minWidth: 35,
    maxHeight: 35,
  },
});

const ReviewBox = styled(Stack)(({ theme }) => ({
  borderRadius: theme.borderRadius.xs,
  boxShadow: theme.boxShadow.light,
  backgroundColor: theme.colors.bg,
  padding: "1rem",
  lineHeight: 1.4,
  p: {
    marginBottom: 0,
  },
}));
ReviewBox.defaultProps = { gap: "xs" };

const CheckboxWrapper = styled(SliderText)(({ theme }) => ({
  border: `1px solid ${theme.colors.fg20}`,
  borderRadius: theme.borderRadius.sm,
  fontSize: "16px !important",
  padding: 10,
  "input+div": {
    fontWeight: "bold",
    marginBottom: -3,
    position: "relative",
    top: -3,
  },
})).withComponent("div");

export { OnboardingSlider };
