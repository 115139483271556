const HUGS = [
  {
    // 0
    name: "Parent Hug",
    side: "Left",
    image: "parent_hug_left.gif",
    description: "The hug that immediately makes you feel better.",
  },
  {
    // 1
    name: "Parent Hug",
    side: "Right",
    image: "parent_hug_right.gif",
    description: "The hug that immediately makes you feel better.",
  },
  {
    // 2
    name: "Pug Hug",
    side: "Left",
    image: "pug_hug_left.gif",
    description:
      "A surprise bonus hug for our founding curator club members to commemorate our hug pun brainstorming sessions!",
  },
  {
    // 3
    name: "Pug Hug",
    side: "Right",
    image: "pug_hug_right.gif",
    description:
      "A surprise bonus hug for our founding curator club members to commemorate our hug pun brainstorming sessions!",
  },
  {
    // 4
    name: "Hedge Hug",
    side: "Left",
    image: "hedge_hug_left.gif",
    description:
      "A hug worth investing in. Find another hedge hug to roll away with!",
  },
  {
    // 5
    name: "Hedge Hug",
    side: "Right",
    image: "hedge_hug_right.gif",
    description:
      "A hug worth investing in. Find another hedge hug to roll away with!",
  },
  {
    // 6
    name: "Rug Hug",
    side: "Left",
    image: "rug_hug_left.gif",
    description: "No rugs, just hugs. Even our devices need some love!",
  },
  {
    // 7
    name: "Rug Hug",
    side: "Right",
    image: "rug_hug_right.gif",
    description: "No rugs, just hugs. Even our devices need some love!",
  },
  {
    // 8
    name: "Bear Hug",
    side: "Left",
    image: "bear_hug_left.gif",
    description:
      "A tight, long, hug that makes you feel loved. Let’s see which bear squeezes tighter!",
  },
  {
    // 9
    name: "Bear Hug",
    side: "Right",
    image: "bear_hug_right.gif",
    description:
      "A tight, long, hug that makes you feel loved. Let’s see which bear squeezes tighter!",
  },
  {
    // 10
    name: "Invisible Hug",
    side: "Left",
    image: "invisible_hug_left.gif",
    description:
      "The best type of hug for your online friends. Find your matching hug somewhere in the metaverse!",
  },
  {
    // 11
    name: "Invisible Hug",
    side: "Right",
    image: "invisible_hug_right.gif",
    description:
      "The best type of hug for your online friends. Find your matching hug somewhere in the metaverse!",
  },
  {
    // 12
    name: "Bug Hug",
    side: "Left",
    image: "bug_hug_left.gif",
    description:
      "A wholesome hug between friends. Blink and you may miss your matching hug!",
  },
  {
    // 13
    name: "Bug Hug",
    side: "Right",
    image: "bug_hug_right.gif",
    description:
      "A wholesome hug between friends. Blink and you may miss your matching hug!",
  },
  {
    // 14
    name: "Space Hug",
    side: "Left",
    image: "space_hug_left.gif",
    description:
      "A hug that feels out of this world. Your matching hug is hiding somewhere in the galaxy!",
  },
  {
    // 15
    name: "Space Hug",
    side: "Right",
    image: "space_hug_right.gif",
    description:
      "A hug that feels out of this world. Your matching hug is hiding somewhere in the galaxy!",
  },
  {
    // 16
    name: "Air Hug",
    side: "Left",
    image: "air_hug_left.gif",
    description:
      "The best way to send a hug without physical touch. How far can your hug travel?",
  },
  {
    // 17
    name: "Air Hug",
    side: "Right",
    image: "air_hug_right.gif",
    description:
      "The best way to send a hug without physical touch. How far can your hug travel?",
  },
  {
    // 18
    name: "Tree Hug",
    side: "Left",
    image: "tree_hug_left.gif",
    description:
      "The greenest type of hug. Match your tree and watch them flourish!",
  },
  {
    // 19
    name: "Tree Hug",
    side: "Right",
    image: "tree_hug_right.gif",
    description:
      "The greenest type of hug. Match your tree and watch them flourish!",
  },
  {
    // 20
    name: "Self Hug",
    side: "Left",
    image: "self_hug_left.gif",
    description:
      "The perfect way to show yourself some love. Look no further than yourself to find your better half.",
  },
  {
    // 21
    name: "Self Hug",
    side: "Right",
    image: "self_hug_right.gif",
    description:
      "The perfect way to show yourself some love. Look no further than yourself to find your better half.",
  },
];

export { HUGS };
