import { useInfiniteQuery } from "react-query";
import { ApiError, api } from "utils/api";
import type { GalleryItemSearchRequest, GalleryItemsResponse } from "../types";
import { galleryKeys } from "./queryKeys";

/**
 * Get a list of gallery items
 */
const getGallery = (params: GalleryItemSearchRequest) =>
  api.post<GalleryItemsResponse>(`/gallery/artists/search`, {
    body: JSON.stringify(params),
  });

/**
 * Search for Gallery Items using Elastic Filters
 * Does not return in the artist's preferred order
 */
const useGallery = (
  params: GalleryItemSearchRequest,
  options?: { keepPreviousData: boolean },
) =>
  useInfiniteQuery<GalleryItemsResponse, ApiError>(
    galleryKeys.infinite(params),
    ({ pageParam }) =>
      getGallery({
        searchAfter: pageParam,
        ...params,
      }),
    {
      ...options,
      getNextPageParam: (lastPage: GalleryItemSearchRequest) =>
        lastPage.searchAfter,
    },
  );

export { useGallery };
