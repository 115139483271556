import styled from "@emotion/styled";
import { IconCheckRounded, List } from "atoms";
import type { CreateProductState } from "../types";

type ProductWizardProgressBarProps = {
  state: CreateProductState;
};

function ProductWizardProgressBar({
  state: { productTemplate, completedSteps },
}: ProductWizardProgressBarProps) {
  if (!productTemplate?.steps) {
    return null;
  }

  const currentIndex = completedSteps.length;

  return (
    <Steps>
      {productTemplate.steps.map((step, index) => {
        const isCompleted = completedSteps.includes(step);
        return (
          <Step
            isCurrent={index === currentIndex}
            isCompleted={isCompleted}
            key={step.toString()}
          >
            {isCompleted && <IconCheckRounded size="10px" />}

            {step.displayName}
          </Step>
        );
      })}
    </Steps>
  );
}
type StepProps = {
  isCurrent: boolean;
  isCompleted: boolean;
};

const Steps = styled(List)(({ theme }) => ({
  gap: 1,
  display: "flex",
  alignItems: "stretch",
  justifyContent: "stretch",
  "&&": { fontSize: 10, padding: 0 },
  svg: {
    display: "none",
  },
  [theme.breakpoints.xs]: {
    "&&": { fontSize: 12 },
    svg: {
      display: "block",
    },
  },
}));

const Step = styled.li<StepProps>(({ isCurrent, isCompleted, theme }) => ({
  ...theme.fonts.display,
  alignItems: "center",
  backgroundColor: theme.colors.fg20,
  color: theme.colors.fg,
  display: "flex",
  gap: 5,
  flex: 1,
  justifyContent: "center",
  letterSpacing: ".02em",
  lineHeight: 1,
  paddingBlock: 6,
  paddingInline: 4,
  textAlign: "center",
  textTransform: "uppercase",
  "&&": { margin: 0 },
  "&:first-of-type": {
    borderBottomLeftRadius: theme.borderRadius.round,
    borderTopLeftRadius: theme.borderRadius.round,
    paddingLeft: 6,
  },
  "&:last-of-type": {
    borderBottomRightRadius: theme.borderRadius.round,
    borderTopRightRadius: theme.borderRadius.round,
    paddingRight: 6,
  },
  ...(isCurrent && {
    backgroundColor: theme.colors.fg90,
    color: theme.colors.bg,
  }),
  ...(isCompleted && {
    backgroundColor: theme.colors.fg80,
    color: theme.colors.bg,
  }),
}));
export { ProductWizardProgressBar };
