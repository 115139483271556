import * as React from "react";
import { useSearchParams } from "react-router-dom";
import {
  Button,
  FlexRow,
  LoadingSpinner,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import { ProductWizardStep } from "../ProductWizardStep";
import { ProductDetails } from "../ProductDetails";
import type { StepProps } from "../../types";
import { useUpdateProductDetails } from "../../api/useUpdateProductDetails";
import { useProductAdminQuery } from "../../api/useProductAdminQuery";

function StepConfirmation({ dispatch, state, moveBack }: StepProps) {
  const [, setSearchParams] = useSearchParams();

  const {
    mutate,
    isLoading: isPublishing,
    isError,
    error,
  } = useUpdateProductDetails(state.product.id);

  const isDraft = state.product.status !== "ACTIVE";

  // Only Prodigi Needs Mockups
  const needsGeneratedMockups = state.product.vendor === "HUG-PRODIGI";

  // Do we have low and high resolution mockups?
  const hasLowQualityMockups =
    !!state.product.imageData?.lowResolutionMockupsReady;
  const hasHighQualityMockups =
    !!state.product.imageData?.highResolutionMockupsReady;

  // Is all media "ready" and attached to variants?
  const mediaReady = !!state.product.imageData?.mediaReady;

  // Show the message area if media or mockups aren't complete
  const showMockupMessage =
    !hasLowQualityMockups || !hasHighQualityMockups || !mediaReady;

  // Refresh - Show when low rez mockups are not generated, or when media is not ready
  const showRefreshButton =
    needsGeneratedMockups && (!hasLowQualityMockups || !mediaReady);

  const moveNext = () => dispatch({ type: "moveNext" });

  const onPublish = () => mutate({ status: "ACTIVE" }, { onSuccess: moveNext });

  const {
    refetch,
    isLoading,
    isError: isErrorProduct,
    error: errorProduct,
  } = useProductAdminQuery(state.product.id);

  // Use a timer to make it look like refetch is doing something
  const [isRefetching, setIsRefetching] = React.useState<boolean>(false);

  const onRefresh = () => {
    setIsRefetching(true);
    const timer = setTimeout(() => {
      clearTimeout(timer);
      setSearchParams({});
      refetch();
      setIsRefetching(false);
    }, 1000);
  };

  return (
    <ProductWizardStep
      stepTitle="Preview Product Listing"
      state={state}
      moveBack={moveBack}
      previewNext={moveNext}
      publishProduct={onPublish}
      isUnpublished={isDraft}
    >
      {isPublishing && <LoadingSpinner text="Publishing" />}

      <Stack gap="md">
        <Stack gap="xxs">
          {showMockupMessage && (
            <FlexRow flexWrap="wrap" gap="6px 1em" alignItems="flex-start">
              <Text size="xs" style={{ flex: "1 0 420px", margin: 0 }}>
                {showRefreshButton ? (
                  <Text>
                    Your product mockups are currently being generated. This may
                    take a few moments. Click <strong>Refresh Preview</strong>{" "}
                    to check for updates.
                  </Text>
                ) : (
                  <Text>
                    Your product mockups are ready. They will display as higher
                    resolution images within 24 hours.
                  </Text>
                )}
              </Text>
              {showRefreshButton && (
                <Button
                  variant="secondary"
                  size="xxs"
                  onClick={onRefresh}
                  disabled={isLoading || isRefetching}
                >
                  Refresh Preview
                </Button>
              )}
            </FlexRow>
          )}
        </Stack>
        <ProductDetails
          artistId={state.artistId}
          productId={state.product.id}
          isPreview
        />

        {isError && <UnexpectedError error={error} />}
        {isErrorProduct && <UnexpectedError error={errorProduct} />}
      </Stack>
    </ProductWizardStep>
  );
}

StepConfirmation.displayName = "Preview";

export { StepConfirmation };
