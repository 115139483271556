import { User } from "features/auth";
import { Text } from "atoms";

function ProfileDiscordOverrides({ user }: { user: User | null }) {
  if (user && user.utilitiesOverride && user.utilitiesOverride.length > 0) {
    return (
      <p>
        <Text as="strong" size="xxxs" bold>
          Overrides
        </Text>
        <br />
        <Text as="span" size="xxs">
          {user.utilitiesOverride.sort().join(", ")}
        </Text>
      </p>
    );
  }
  return null;
}

export { ProfileDiscordOverrides };
