import * as React from "react";
import styled from "@emotion/styled";
import { Button, FlexRow, IconArrow, IconNewImage, Modal } from "atoms";
import { pluralize } from "utils/text";
import {
  InstagramAuth,
  useInstagramMedia,
  useInstagramProfile,
} from "features/instagram";
import { SelectImages } from "./SelectImages";

type AddItemsInstagramProps = {
  onBack: VoidFunction;
  setSelectedFiles: (files: File[]) => void;
};

function AddItemsInstagram({
  setSelectedFiles,
  onBack,
}: AddItemsInstagramProps) {
  // Instagram Authentication
  const [accessToken, setAccessToken] = React.useState<string | undefined>(
    undefined,
  );

  // Instagram Data
  const { data, hasNextPage, fetchNextPage } = useInstagramMedia(accessToken);
  const { data: profile } = useInstagramProfile(accessToken);

  const loadMore = () => fetchNextPage();

  // Content type is required but not used - We don't know the content
  // type from Instagram until the images are loaded into blobs
  const media = React.useMemo(
    () =>
      (data?.pages?.flatMap((page) => page.data) ?? []).map((item) => ({
        image: {
          url: item.media_url,
          url300: item.thumbnail_url,
          contentType: "image/jpeg",
          importDate: new Date().toISOString(),
        },
        id: item.id,
        title: item.caption ?? "Image",
      })),
    [data],
  );

  // Tracking for Selected Items
  const [selected, setSelected] = React.useState<typeof media>([]);

  // Load All Items to Blob Images before Upload
  const loadImage = async (item?: (typeof media)[number]) => {
    if (item?.image?.url) {
      const response = await fetch(item.image.url);
      const blob = await response.blob();
      // Give a fake file extension because it will get stripped by the form
      const file = new File([blob], `${item.title ?? "Image"}.png`, {
        type: blob.type,
      });
      return file;
    }
    return undefined;
  };

  const loadAllImagesToFilesObjects = async () => {
    const loadedImages = await Promise.all(selected.map((i) => loadImage(i)));
    setSelectedFiles(loadedImages.filter((r): r is File => !!r));
  };

  const setSelectionComplete = () => {
    loadAllImagesToFilesObjects();
  };

  if (!accessToken) {
    return (
      <>
        <InstagramHeader />
        <InstagramAuth setAccessToken={setAccessToken} />
        <Modal.Footer>
          <FlexRow justifyContent="center">
            <Button variant="secondary" onClick={onBack}>
              <IconArrow dir="l" />
              Back
            </Button>
          </FlexRow>
        </Modal.Footer>
      </>
    );
  }
  // TODO: Show placeholder grid when images are loading
  // if (isLoading) {
  //   return (
  //     <Grid>
  //       <PlaceholderBlock width="100%" height="150" />
  //       <PlaceholderBlock width="100%" height="150" />
  //       <PlaceholderBlock width="100%" height="150" />
  //       <PlaceholderBlock width="100%" height="150" />
  //       <PlaceholderBlock width="100%" height="150" />
  //       <PlaceholderBlock width="100%" height="150" />
  //     </Grid>
  //   );
  // }
  return (
    <>
      <InstagramHeader username={profile?.username} />
      <SelectImages multiple items={media} onChange={setSelected} />
      {hasNextPage && (
        <FlexRow justifyContent="center">
          <SButton variant="secondary" onClick={loadMore}>
            Load More
          </SButton>
        </FlexRow>
      )}
      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button variant="secondary" onClick={onBack}>
            <IconArrow dir="l" />
            Back
          </Button>
          <Button onClick={setSelectionComplete}>
            Add {pluralize(selected.length, "Item")}
          </Button>
        </FlexRow>
      </Modal.Footer>
    </>
  );
}

function InstagramHeader({ username }: { username?: string }) {
  return (
    <Modal.Header>
      <Modal.Title>
        {username ? (
          <span>Instagram Media For: {username} </span>
        ) : (
          <>
            <IconNewImage /> Add From Instagram {username}
          </>
        )}
      </Modal.Title>
    </Modal.Header>
  );
}

const SButton = styled(Button)({
  marginTop: "1rem",
});

export { AddItemsInstagram };
