import * as React from "react";
import { ErrorBoundary } from "react-error-boundary";
import { ReadOnly } from "atoms";

type SRTRoot = {
  type: "root";
  children: SRTChildren[];
};

type SRTText = {
  type: "text";
  value: string;
  bold?: boolean;
  italic?: boolean;
};

type SRTLink = {
  type: "link";
  url: string;
  title?: string;
  target?: string;
  children: SRTChildren[];
};

type SRTHeading = {
  type: "heading";
  level: 1 | 2 | 3 | 4 | 5 | 6;
  children: SRTChildren[];
};

type SRTParagraph = {
  type: "paragraph";
  children: SRTChildren[];
};

type SRTList = {
  type: "list";
  listType: "unordered" | "ordered";
  children: SRTChildren[];
};

type SRTListItem = {
  type: "list-item";
  children: SRTChildren[];
};

type SRTChildren =
  | SRTRoot
  | SRTText
  | SRTParagraph
  | SRTHeading
  | SRTLink
  | SRTList
  | SRTListItem;

type SRTContentProps = SRTChildren & Omit<ShopifyRichTextProps, "value">;

function SRTContent({ className, ...node }: SRTContentProps) {
  // Text nodes are the base case, so stop the recursion and return a leaf text node
  if (node.type === "text") {
    if (node.bold) {
      return <strong>{node.value}</strong>;
    }

    if (node.italic) {
      return <em>{node.value}</em>;
    }

    return <>{node.value}</>;
  }

  // Start the recursion tree with root element and props
  let tag = "div";
  let opts: Record<string, string | undefined> = { className };

  // If we're in a recursive loop, render an inner element and props
  if (node.type === "paragraph") {
    tag = "p";
  } else if (node.type === "list-item") {
    tag = "li";
  } else if (node.type === "list") {
    tag = node.listType === "ordered" ? "ol" : "ul";
  } else if (node.type === "heading") {
    tag = `h${node.level}` as const;
  } else if (node.type === "link") {
    tag = "a";
    opts = { href: node.url, target: node.target, title: node.title };
  }

  // Render root element and then recursively render its children
  /* eslint-disable react/no-array-index-key */
  return React.createElement(
    tag,
    opts,
    "children" in node
      ? node.children.map((child, index) => (
          <SRTContent key={index} {...child} />
        ))
      : undefined,
  );
  /* eslint-enable react/no-array-index-key */
}

type ShopifyRichTextProps = {
  className?: string;
  value?: string;
};

function ShopifyRichText({ value, ...props }: ShopifyRichTextProps) {
  if (!value) {
    return null;
  }

  return (
    <ErrorBoundary fallback={null}>
      <ReadOnly>
        <SRTContent {...props} {...JSON.parse(value)} />
      </ReadOnly>
    </ErrorBoundary>
  );
}

/**
 * Sanitize Shopify HTML value to work with our RichText components
 *
 * HTML strings from Shopify APIs, like a Product's `descriptionHTML` field, often have characters
 * that screw up the way Quill.js interprets markup. So this will hopefully make the two play nice.
 */
const sanitizeShopifyHTML = (html: string) => html.replaceAll(">\n<", "><");

export { ShopifyRichText, sanitizeShopifyHTML };
