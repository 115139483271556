import { useMutation } from "react-query";
import { api } from "utils/api";
import { constants } from "config";

/*
 * Instagram needs a specific redirect token - We will use /auth/instagram
 * We will store the current URL in local storage and /aut/instagram will
 * send them back.
 */
const redirectUrl = `${window.location.origin}/auth/instagram`;
const instagramOauthUrl = `https://api.instagram.com/oauth/authorize?client_id=${constants.instagramClientId}&redirect_uri=${redirectUrl}&response_type=code&scope=user_profile,user_media`;

const getAuthToken = (authCode: string) =>
  api.post<{ accessToken: string }>("/social/instagram/authorize", {
    body: JSON.stringify({ authCode, redirectUrl }),
  });

const useInstagramAuth = () => useMutation(getAuthToken);

export { useInstagramAuth, instagramOauthUrl };
