import * as React from "react";
import styled from "@emotion/styled";
import { IconChevronBold, IconPlay, IconPause } from "atoms";

type VideoState = {
  isPlaying: boolean;
  progress: number;
};

type VideoAction =
  | { type: "play" }
  | { type: "pause" }
  | { type: "setProgress"; progress: number };

const videoReducer: React.Reducer<VideoState, VideoAction> = (
  state,
  action,
) => {
  switch (action.type) {
    case "play": {
      return { ...state, isPlaying: true };
    }
    case "pause": {
      return { ...state, isPlaying: false };
    }
    case "setProgress": {
      const { progress } = action;
      return { ...state, progress };
    }
    default: {
      throw new Error("Invalid action type");
    }
  }
};

const initialState: VideoState = { isPlaying: false, progress: 0 };

const SKIP_PERCENTAGE = 0.1;

const safeRound = (n: number) => Math.round((n + Number.EPSILON) * 100) / 100;

type PreviewVideoProps = Omit<React.ComponentProps<"video">, "src"> & {
  videoRef: React.RefObject<HTMLVideoElement>;
  src: string;
};

function PreviewVideo({ videoRef, src, ...props }: PreviewVideoProps) {
  const [state, dispatch] = React.useReducer(videoReducer, initialState);
  const video = videoRef.current;

  // React to play/pause state changes and update video element
  React.useEffect(() => {
    if (video) {
      if (state.isPlaying) {
        video.play();
      } else {
        video.pause();
      }
    }
  }, [video, state.isPlaying]);

  // Set up event listener to update state when video is ended
  React.useEffect(() => {
    if (video) {
      const stopPlaying = () => dispatch({ type: "pause" });
      video.addEventListener("ended", stopPlaying);
      return () => video.removeEventListener("ended", stopPlaying);
    }
    return noop;
  }, [video]);

  const handlePlayPause = () => {
    dispatch({ type: state.isPlaying ? "pause" : "play" });
  };

  const onTimeUpdate = () => {
    const progress = !video ? 0 : video.currentTime / video.duration;
    dispatch({ type: "setProgress", progress });
  };

  const handleSkipBackward = () => {
    if (video) {
      const currentProgress = video.currentTime / video.duration;
      const progress = safeRound(
        Math.max(0, currentProgress - SKIP_PERCENTAGE),
      );
      video.currentTime = video.duration * progress;
      dispatch({ type: "setProgress", progress });
    }
  };

  const handleSkipForward = () => {
    if (video) {
      const currentProgress = !video ? 0 : video.currentTime / video.duration;
      const progress = safeRound(
        Math.min(1, currentProgress + SKIP_PERCENTAGE),
      );
      video.currentTime = video.duration * progress;
      dispatch({ type: "setProgress", progress });
    }
  };

  return (
    <div>
      <PreviewVideoPlayer
        disableRemotePlayback
        playsInline
        {...props}
        onTimeUpdate={onTimeUpdate}
        ref={videoRef}
        src={src}
      />

      <Controls>
        <Control
          onClick={handleSkipBackward}
          disabled={state.progress === 0}
          aria-label="Skip Backward"
        >
          <IconChevronBold style={{ transform: "rotate(90deg)" }} />
        </Control>

        <Control onClick={handlePlayPause} aria-label="Play/pause">
          {state.isPlaying ? <IconPause /> : <IconPlay />}
        </Control>

        <Control
          onClick={handleSkipForward}
          disabled={state.progress === 1}
          aria-label="Skip Backward"
        >
          <IconChevronBold style={{ transform: "rotate(-90deg)" }} />
        </Control>
      </Controls>
    </div>
  );
}

const PreviewVideoPlayer = styled.video({
  width: "100%",
});

const Controls = styled.div({
  display: "flex",
  gap: "1rem",
  justifyContent: "center",
});

const Control = styled.button(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.gray,
  border: 0,
  cursor: "pointer",
  display: "flex",
  padding: "0.5rem",
  "&:hover, &:focus-visible": {
    backgroundColor: theme.colors.accent1L,
  },
  svg: {
    width: "1rem",
    height: "1rem",
  },
}));
Control.defaultProps = {
  type: "button",
};

export { PreviewVideo };
