import { css, Theme } from "@emotion/react";
import rodgerBl from "../assets/fonts/Rodger-Black.woff";
import rodgerBl2 from "../assets/fonts/Rodger-Black.woff2";
import rodgerBlIt from "../assets/fonts/Rodger-BlackItalic.woff";
import rodgerBlIt2 from "../assets/fonts/Rodger-BlackItalic.woff2";
import roobertBold from "../assets/fonts/Roobert-Bold.woff";
import roobertBold2 from "../assets/fonts/Roobert-Bold.woff2";
import roobertBoldIt from "../assets/fonts/Roobert-BoldItalic.woff";
import roobertBoldIt2 from "../assets/fonts/Roobert-BoldItalic.woff2";
import roobertReg from "../assets/fonts/Roobert-Regular.woff";
import roobertReg2 from "../assets/fonts/Roobert-Regular.woff2";
import roobertRegIt from "../assets/fonts/Roobert-RegularItalic.woff";
import roobertRegIt2 from "../assets/fonts/Roobert-RegularItalic.woff2";
import { hexToRGB } from "./styleUtils";

const WHITE = "#ffffff";
const BLACK = "#262626";
const ORANGE = "#f85a17";
const ORANGEDARK = "#B83700";
const ORANGELIGHT = "#FFBC9D";
const ORANGELIGHTER = "#FFF2EC";
const YELLOW = "#FFEE9B";
const YELLOWLIGHT = "#FBF2C3";
const YELLOWLIGHTER = "#FFFAE2";
const YELLOWDARK = "#E7C000";
const GREEN = "#ceecb5";
const GREENDARK = "#84AA62";
const GREENLIGHT = "#ECFADE";
const PURPLE = "#cca2f8";
const PURPLEDARK = "#C17AFF";
const PURPLELIGHT = "#E3C9FF";
const PINK = "#f7cfda";
const PINKLIGHT = "#FDF3F5";
const PINKDARK = "#ED91AA";

const oldBrand: Theme = {
  colors: {
    // semantic names
    bg: YELLOWLIGHT,
    fg: BLACK,
    bgLight: WHITE,
    primary: ORANGE,
    secondary: YELLOW,
    // straight names,
    white: WHITE,
    black: BLACK,
    orange: ORANGE,
    orangeLight: ORANGELIGHT,
    orangeLighter: ORANGELIGHTER,
    orangeDark: ORANGEDARK,
    yellow: YELLOW,
    yellowDark: YELLOWDARK,
    yellowLight: YELLOWLIGHT,
    yellowLighter: YELLOWLIGHTER,
    green: GREEN,
    greenDark: GREENDARK,
    greenLight: GREENLIGHT,
    purple: PURPLE,
    purpleDark: PURPLEDARK,
    purpleLight: PURPLELIGHT,
    pink: PINK,
    pinkLight: PINKLIGHT,
    pinkDark: PINKDARK,
    error: ORANGEDARK,
    red: ORANGEDARK,
    // grays
    gray10: "#ebebeb",
    gray20: "#d8d8d8",
    gray30: "#a8a8a8",
    gray60: "#7f7f7f",
    gray80: "#535353",
  },
  fonts: {
    display: {
      fontFamily:
        "Rodger, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
      fontStyle: "normal",
      // fontWeight: "bold",
    },
    body: {
      fontFamily:
        "Roobert, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
      fontStyle: "normal",
      // fontWeight: "normal",
    },
  },
  fontSizes: {
    h1: "var(--step-5)",
    h2: "var(--step-3)",
    h3: "var(--step-2)",
    xxxxxl: "var(--step-7)",
    xxxxl: "var(--step-6)",
    xxxl: "var(--step-5)",
    xxl: "var(--step-4)",
    xl: "var(--step-3)",
    lg: "var(--step-2)",
    md: "var(--step-1)",
    sm: "var(--step-0)",
    xs: "var(--step--1)",
    xxs: "var(--step--2)",
    xxxs: "var(--step--3)",
    xxxxs: "var(--step--4)",
  },
  spacing: {
    xxxxs: ".05rem",
    xxxs: ".0625rem",
    xxs: "0.125rem",
    xs: "0.25rem",
    sm: "0.5rem",
    md: "1rem",
    lg: "2rem",
    xl: "3.2rem",
    xxl: "5rem",
    xxxl: "8rem",
    gutter: "var(--site-gutter)",
    gap: "var(--site-gap)",
    letterSpacing: ".02em",
    stack: {
      0: "0",
      xxs: ".25",
      xs: ".5",
      sm: "1",
      md: "2.25",
      lg: "3.5",
      xl: "5",
      xxl: "7.5",
    },
  },
  gradients: {
    radial1: `radial-gradient(circle at 55% 90%, ${PURPLE}, rgba(255, 255, 255, 0) 30%),
        radial-gradient(circle at 5% 15%, ${YELLOW} 10%, rgba(255, 255, 255, 0) 40%),
        radial-gradient(circle at 95% 35%, ${GREEN} 5%, rgba(255, 255, 255, 0) 25%)
        ${PINK}`,
    radial2: `radial-gradient(circle farthest-corner at top center, ${PINK} 5rem, rgba(255, 255, 255, 0) 30rem),
        radial-gradient(circle farthest-corner at top center, ${YELLOW} 50rem, rgba(255, 255, 255, 0) 75rem)
        ${GREEN}`,
    radial3: `radial-gradient(circle at 55% 5%, ${YELLOW} 5%, rgba(255, 255, 255, 0) 20%),
        radial-gradient(circle at 95% 85%, ${GREEN} 5%, rgba(255, 255, 255, 0) 20%),
        radial-gradient(circle at 5% 75%, ${PURPLE} 15%, rgba(255, 255, 255, 0) 40%)
        ${PINK}`,
    radial4: `radial-gradient(circle farthest-corner at 50% -10%, ${PINK} 0rem, rgba(255, 255, 255, 0) 30%),radial-gradient(circle farthest-corner at top center, ${YELLOW} 50rem, rgba(255, 255, 255, 0) 75rem)`,
    radial5: `radial-gradient(circle farthest-corner at 50% 3%, ${hexToRGB(
      PURPLE,
      0.8,
    )} 0rem, rgba(255, 255, 255, 0) 15rem)`,
    linear: `linear-gradient(to right, ${PURPLELIGHT} 10%, ${PINK} 90%)`,
    linear1: `linear-gradient(to right, ${PURPLELIGHT} 10%, ${PINK} 90%)`,
    linear2: `linear-gradient(to right, ${YELLOW} 10%, ${GREEN} 90%)`,
    linear3: `linear-gradient(to right, ${PURPLELIGHT} 10%, ${PINK} 50%, ${GREEN} 90%)`,
    linear4: `linear-gradient(to right, ${ORANGELIGHT} 10%, ${PINK} 90%)`,
    tagFounder: `linear-gradient(70deg, ${PURPLELIGHT} 0%, ${PINK} 100%)`,
    tagMission: `linear-gradient(70deg, ${GREEN} 0%, ${YELLOW} 100%)`,
    tagMedium: `linear-gradient(70deg, ${ORANGELIGHT} 0%, ${PINK} 100%)`,
  },
  boxShadow: {
    light: `0 0 10px rgb(0 0 0 / 10%), 0 0 3px rgb(0 0 0 / 3%)`,
    dark: `0 3px 5px 0px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 20%), 0 0 3px rgb(0 0 0 / 3%)`,
  },
  borderRadius: {
    xs: "4px",
    sm: "8px",
    md: "14px",
    lg: "20px",
    round: 9999,
  },
  breakpoints: {
    maxXs: `@media (max-width: 419px)`,
    xs: `@media (min-width: 420px)`,
    maxTablet: `@media (max-width: 599px)`,
    tablet: `@media (min-width: 600px)`,
    maxDesktop: `@media (max-width: 830px)`,
    desktop: `@media (min-width: 831px)`,
    maxDesktopMedium: `@media (max-width: 1023px)`,
    desktopMedium: `@media (min-width: 1024px)`,
    desktopLarge: `@media (min-width: 1140px)`,
    desktopXLarge: `@media (min-width: 1440px)`,
  },
  global: css`
    @font-face {
      font-family: "Roobert";
      src: url("${roobertReg2}") format("woff2");
      src: url("${roobertReg}") format("woff");
      font-display: swap;
      font-style: normal;
      font-weight: normal;
    }
    @font-face {
      font-family: "Roobert";
      src: url("${roobertRegIt2}") format("woff2");
      src: url("${roobertRegIt}") format("woff");
      font-display: swap;
      font-style: italic;
      font-weight: normal;
    }
    @font-face {
      font-family: "Roobert";
      src: url("${roobertBold2}") format("woff2");
      src: url("${roobertBold}") format("woff");
      font-display: swap;
      font-style: normal;
      font-weight: bold;
    }
    @font-face {
      font-family: "Roobert";
      src: url("${roobertBoldIt2}") format("woff2");
      src: url("${roobertBoldIt}") format("woff");
      font-display: swap;
      font-style: italic;
      font-weight: bold;
    }
    @font-face {
      font-family: "Rodger";
      src: url("${rodgerBl2}") format("woff2");
      src: url("${rodgerBl}") format("woff");
      font-display: swap;
      font-style: normal;
      font-weight: bold;
    }
    @font-face {
      font-family: "Rodger";
      src: url("${rodgerBlIt2}") format("woff2");
      src: url("${rodgerBlIt}") format("woff");
      font-display: swap;
      font-style: italic;
      font-weight: bold;
    }

    html,
    body {
      background: ${WHITE};
      color: ${BLACK};
    }
  `,
  // only here to avoid errors!
  patterns: {
    brush1: {
      backgroundImage: "",
      backgroundPosition: "",
      backgroundSize: "",
      backgroundRepeat: "",
    },
    brush2: {
      backgroundImage: "",
      backgroundPosition: "",
      backgroundSize: "",
      backgroundRepeat: "",
    },
    brush3: {
      backgroundImage: "",
      backgroundPosition: "",
      backgroundSize: "",
      backgroundRepeat: "",
    },
    brush4: {
      backgroundImage: "",
      backgroundPosition: "",
      backgroundSize: "",
      backgroundRepeat: "",
    },
  },
};

export { oldBrand };
