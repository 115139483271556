import styled from "@emotion/styled";
import { Box } from "./Box";

const Card = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.light,
  overflow: "hidden",
  padding: 0,
  position: "relative",
}));

export { Card };
