import * as React from "react";
import styled from "@emotion/styled";
import { uniqueId } from "lodash";
import { Image, Thumbnail } from "features/images";
import { Input, Label, ScreenReaderText, Stack, Text } from "atoms";

interface ItemWithImage {
  id: string;
  title?: string;
  image?: Image;
}

type SelectImagesProps<TItem extends ItemWithImage> = {
  /** Array of items that at minimum should have an id, title, and image */
  items: TItem[];
  /** Handler to update the parent when selection changes */
  onChange: (items: TItem[]) => void;
  /** Optional default for selections */
  defaultValue?: TItem[];
  /** Optional aspect ratio for image display */
  aspectRatio?: React.CSSProperties["aspectRatio"];
  /** Determines if input is group of checkboxes or the default of radio buttons */
  multiple?: boolean;
  /** Optional boolean to show the item's title under the image */
  showTitle?: boolean;
};

function SelectImages<TItem extends ItemWithImage>({
  items,
  onChange,
  defaultValue = [],
  aspectRatio = "auto",
  multiple = false,
  showTitle = false,
}: SelectImagesProps<TItem>) {
  const [name] = React.useState(uniqueId());

  const [selected, setSelected] = React.useState<Record<string, TItem>>(
    defaultValue.reduce((acc, cur) => ({ ...acc, [cur.id]: cur }), {}),
  );

  const handleOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    const { itemId } = event.currentTarget.dataset;
    const item = itemId && items.find(({ id }) => id === itemId);

    if (item && multiple) {
      const newSelection = { ...selected };
      if (item.id in newSelection) {
        delete newSelection[item.id];
      } else {
        newSelection[item.id] = item;
      }
      setSelected(newSelection);
      onChange(Object.values(newSelection));
    } else if (item && !multiple) {
      setSelected({ [item.id]: item });
      onChange([item]);
    }
  };

  return (
    <Stack gap="sm">
      <Grid>
        {items.map(({ id, image, title }) =>
          !image ? null : (
            <GridItem key={id} style={{ aspectRatio }}>
              <Stack gap="xxs">
                <SInput
                  type={multiple ? "checkbox" : "radio"}
                  name={name}
                  id={image.url}
                  data-item-id={id}
                  checked={id in selected}
                  onChange={handleOnChange}
                />

                <SLabel htmlFor={image.url} multiple={multiple}>
                  <ScreenReaderText>{title}</ScreenReaderText>
                </SLabel>

                {showTitle && title && (
                  <ItemTitle size="xxxs" bold>
                    {title}
                  </ItemTitle>
                )}

                <SThumbnail
                  src={image.url300 ?? image.url600 ?? image.url}
                  aria-hidden
                  containImage={aspectRatio === "auto"}
                />
              </Stack>
            </GridItem>
          ),
        )}
      </Grid>
    </Stack>
  );
}

const Grid = styled.div({
  display: "grid",
  alignItems: "start",
  gap: 12,
  gridTemplateColumns: "repeat(auto-fill, minmax(135px, 1fr))",
  height: "fit-content",
});

const GridItem = styled.div(({ theme }) => ({
  // display: "flex",
  // alignSelf: "flex-start",
  borderRadius: theme.borderRadius.sm,
  flex: "0 1 30%",
  position: "relative",
}));

const SInput = styled(Input)(({ theme }) => ({
  // visually hide input element while remaining interactive
  border: 0,
  borderRadius: theme.borderRadius.sm,
  height: 0,
  margin: 0,
  minHeight: 0,
  opacity: 0,
  outline: 0,
  overflow: "hidden",
  position: "absolute",
  width: 0,
  "&:focus, &:focus-visible": {
    outline: 0,
  },
}));

const SLabel = styled(Label)<{ multiple: boolean }>(({ multiple, theme }) => ({
  borderRadius: theme.borderRadius.sm,
  boxShadow: `0 0 0 5px ${theme.colors.transparent}`,
  inset: 0,
  outline: "3px solid",
  outlineColor: theme.colors.transparent,
  outlineOffset: 2,
  position: "absolute",
  transition: ".2s ease all",
  zIndex: 2,
  "&::after": {
    alignItems: "center",
    backgroundColor: theme.colors.bg,
    border: "2px solid",
    borderColor: theme.colors.fg,
    borderRadius: multiple ? theme.borderRadius.sm : theme.borderRadius.round,
    color: theme.colors.fg,
    content: "'✓'",
    display: "flex",
    fontWeight: "bold",
    height: 26,
    justifyContent: "center",
    lineHeight: 1,
    opacity: 0,
    paddingTop: 3,
    pointerEvents: "none",
    position: "absolute",
    right: 5,
    top: 5,
    transition: ".2s ease all",
    width: 26,
  },
  // input interactions
  "input:checked + &": {
    outlineColor: theme.colors.fg,
  },
  "input:checked + &::after": {
    opacity: 1,
  },
  "input:focus-visible + &": {
    boxShadow: `0 0 0 5px ${theme.colors.fg}`,
  },
  "input:checked + &:hover, input:checked:focus-visible + &:hover, &:hover": {
    boxShadow: `0 0 0 5px ${theme.colors.fg70}`,
    outlineColor: theme.colors.fg70,
  },
}));

const SThumbnail = styled(Thumbnail)<{ containImage: boolean }>(
  ({ containImage, theme }) => ({
    "&,img": {
      borderRadius: theme.borderRadius.sm,
      height: "100%",
      objectFit: containImage ? "contain" : "cover",
      objectPosition: "center",
      pointerEvents: "none",
      position: "relative",
      width: "100%",
      zIndex: 0,
    },
  }),
);

const ItemTitle = styled(Text)(({ theme }) => ({
  lineHeight: 1,
  margin: 0,
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textAlign: "left",
  color: theme.colors.fg70,
}));
export { SelectImages };
