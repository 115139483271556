import { rest } from "msw";
import { isDev, constants } from "../config";

if (isDev) {
  /* eslint-disable-next-line no-console */
  console.log("MSW Enabled: mocking calls to", constants.apiUrl);
}
const api = (path = "") => `${constants.apiUrl}${path}`;

const handlers = [
  // Health check
  /* istanbul ignore next */
  rest.get(api(), (_, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({ hug: "hello", deploy: new Date().toLocaleDateString() }),
    ),
  ),

  // Beta Password
  rest.post(api("/beta"), (req, res, ctx) => {
    const { password } = req.body;
    if (password === "badpass") {
      return res(
        ctx.status(422),
        ctx.json({
          errorCode: "ERROR_MESSAGE",
          message: "WRONG_BETA_PASSWORD",
        }),
      );
    }
    return res(ctx.json({ status: "success" }));
  }),

  rest.get(api("/ws"), (_, res, ctx) => res(ctx.status(200))),

  rest.get(api("/reviewables/lists/artist-of-the-month"), (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: [] })),
  ),

  rest.get(api("/reviewables/lists/project-of-the-month"), (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: [] })),
  ),

  rest.get(api("/reviewables/lists/artist-of-the-month"), (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: [] })),
  ),

  rest.get(api("/reviewables/lists/minting-now"), (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: [] })),
  ),

  rest.get(api("/stats"), (req, res, ctx) =>
    res(
      ctx.status(200),
      ctx.json({
        totalProjects: 440,
        totalArtists: 166,
        totalReviews: 1561,
        totalHuggers: 2670,
        topReviewers: [
          {
            name: "davedno",
            reviews: 27,
            avatar: "Unknown",
          },
        ],
      }),
    ),
  ),

  rest.get(api("/rewards/raffles"), (req, res, ctx) =>
    res(ctx.status(200), ctx.json({ data: [] })),
  ),

  // 404 error
  rest.get(api("/non/existing/route"), (_, res, ctx) => res(ctx.status(404))),
];

export { handlers };
