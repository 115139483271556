import styled from "@emotion/styled";
import { FlexRow } from "./FlexRow";
import { IconCheckRounded, IconX } from "./icons";

/**
 * Exposes the min and/or max character count and the current character count of a text field.
 * Checks the current character count againt min and max values.
 */
type CharacterCounterProps = {
  /**
   * Minimum character count
   */
  min?: number;
  /**
   * Maximum character count
   */
  max?: number;
  /**
   * Form field ID
   */
  value: string;
};

function CharacterCounter({ min, max, value }: CharacterCounterProps) {
  const charCount = value?.length ?? 0;
  if (min || max) {
    return (
      <CharCountRow
        justifyContent="space-between"
        gap="10px 1rem"
        itemsFlex="1 1 auto"
        alignItems="flex-start"
        flexWrap="nowrap"
      >
        <FlexRow alignItems="flex-start" gap="3px 10px">
          {min && (
            <FlexRow gap="0 5px">
              {charCount >= 0 && charCount < min ? (
                <CharCountFail />
              ) : (
                <CharCountPass />
              )}
              <span>Min {min} characters</span>
            </FlexRow>
          )}

          {max && (
            <FlexRow gap="0 5px">
              {charCount >= 0 && charCount <= max ? (
                <CharCountPass />
              ) : (
                <CharCountFail />
              )}
              <span>Max {max} characters</span>
            </FlexRow>
          )}
        </FlexRow>
        <strong>{charCount}</strong>
      </CharCountRow>
    );
  }
  return null;
}

const CharCountRow = styled(FlexRow)(() => ({
  fontSize: 12,
  fontWeight: "normal",
  marginTop: 10,
  strong: {
    textAlign: "right",
  },
}));

const CharCountPass = styled(IconCheckRounded)(({ theme }) => ({
  width: "1em",
  height: "1em",
  color: theme.colors.success,
  transform: "translateY(-1px)",
}));

const CharCountFail = styled(IconX)(({ theme }) => ({
  width: "1em",
  height: "1em",
  color: theme.colors.error,
  transform: "translateY(-1px) scale(.75)",
}));

export { CharacterCounter };
