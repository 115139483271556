import { User } from "features/auth";
import { Button, Text } from "atoms";
import { useAdminUserUpdate } from "features/admin";
import { ProfileDiscordOverrides } from "./ProfileDiscordOverrides";

type ProfileDiscordParams = {
  user: User;
  /**
   * Component is rendered by AdminUserDetails, show extra user info for admin
   */
  viewerIsAdmin?: boolean;
};

function ProfileDiscord({ user, viewerIsAdmin = false }: ProfileDiscordParams) {
  const { adminUserResetDiscord, isLoading } = useAdminUserUpdate();
  const resetDiscord = () => adminUserResetDiscord(user);

  return (
    <div>
      <p>
        <Text as="strong" size="xxxs" bold>
          Username
        </Text>
        <br />
        <Text as="strong" size="xxs">
          <span>{user.discord ? user.discord : "Not Connected"}</span>
          <span>
            {!!user.utilities?.length &&
              ` (${user.utilities.sort().join(", ")})`}
          </span>
        </Text>
      </p>

      <ProfileDiscordOverrides user={user} />

      {viewerIsAdmin && (
        <p>
          <Text as="strong" size="xxxs" bold>
            User Id
          </Text>

          <br />

          <Text as="strong" size="xxs">
            {user.discordId || "Not Connected"}
          </Text>

          <br />
          <br />

          {user.discordId && (
            <Button
              size="xs"
              onClick={resetDiscord}
              disabled={isLoading}
              title="Reset Discord Connection"
              variant="secondary"
            >
              Reset
            </Button>
          )}
        </p>
      )}
    </div>
  );
}

export { ProfileDiscord };
