import { createStorefrontClient } from "@shopify/hydrogen-react";
import { constants } from "config";
import { rollbar } from "features/misc";

/**
 * Create client-side Shopify client using public facing tokens
 */
const storefrontClient = createStorefrontClient({
  storeDomain: constants.shopifyDomain,
  publicStorefrontToken: constants.shopifyAccessToken,
});

const client = {
  ...storefrontClient,
  /**
   * Query Shopify Storefront API using GraphQL
   */
  query: async <T>(query: string, variables?: unknown): Promise<T> => {
    const resp = await fetch(client.getStorefrontApiUrl(), {
      body: JSON.stringify({ query, variables }),
      headers: client.getPublicTokenHeaders(),
      method: "POST",
    });

    if (!resp.ok) {
      throw new Error(resp.statusText);
    }

    const json = await resp.json();

    // GraphQL can return errors during a 200 response, so check for errors array
    if (json.errors) {
      // Log errors, but only throw an error for react-query to catch if we don't have data. If we
      // got something back, hopefully it's useful enough to render in the intended component.
      rollbar.error(json.errors);
      if (!json.data) {
        throw new Error(json.errors);
      }
    }

    return json.data;
  },
};

export { client };
