import * as React from "react";
import type {
  CreateProductAction,
  CreateProductState,
  ProductAdminData,
} from "../types";

const moveToPreviousStep = (state: CreateProductState): CreateProductState => {
  // Check if there are any steps completed to move back to
  if (!state.completedSteps.length) {
    return state;
  }
  const [currentStep, ...newCompletedSteps] = state.completedSteps;

  return {
    ...state,
    requiredSteps: [currentStep, ...state.requiredSteps],
    completedSteps: newCompletedSteps,
  };
};

const moveToNextStep = (state: CreateProductState): CreateProductState => {
  // Check if there are any required steps left
  if (!state.requiredSteps.length) {
    return state;
  }

  const [currentStep, ...newRequiredSteps] = state.requiredSteps;

  return {
    ...state,
    requiredSteps: newRequiredSteps,
    completedSteps: [currentStep, ...state.completedSteps],
  };
};

const createProductsReducer = (
  state: CreateProductState,
  action: CreateProductAction,
): CreateProductState => {
  switch (action.type) {
    case "moveBack": {
      return moveToPreviousStep(state);
    }
    case "moveNext": {
      return moveToNextStep(state);
    }
    case "updateProduct": {
      const newState = { ...state, product: action.product };
      return action.moveNext ? moveToNextStep(newState) : newState;
    }
    default: {
      return state;
    }
  }
};

type InitArg = {
  artistId: string;
  product: ProductAdminData;
  productTemplate: CreateProductState["productTemplate"];
};

/**
 * Initialize the wizard reducer state using steps from the product template
 */
const initState = (initArg: InitArg): CreateProductState => ({
  ...initArg,
  productTemplate: initArg.productTemplate,
  requiredSteps: initArg.productTemplate.steps,
  completedSteps: [],
});

const useShopWizardReducer = (initArg: InitArg) =>
  React.useReducer(createProductsReducer, initArg, initState);

export { useShopWizardReducer };
