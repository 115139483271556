import * as React from "react";
import uniqueId from "lodash/uniqueId";
import styled from "@emotion/styled";
import { useToggle } from "utils/hooks";
import { IconChevron } from "./icons";

type DropdownButtonProps = {
  showLabel: React.ReactNode;
  hideLabel?: React.ReactNode;
  chevron?: boolean;
  children: React.ReactNode;
};

function ContentToggle({
  showLabel,
  hideLabel,
  chevron = true,
  children,
  ...props
}: DropdownButtonProps) {
  const [id] = React.useState(uniqueId());
  const [isOpen, toggle] = useToggle(false);
  const hideLabelNew = hideLabel || showLabel;

  return (
    <ContentToggleWrapper {...props}>
      <ContentToggleButton
        type="button"
        onClick={toggle}
        isOpen={isOpen}
        aria-controls={id}
        aria-label={isOpen ? "Hide notifications" : "Show notifications"}
        aria-expanded={isOpen ? "true" : "false"}
      >
        {chevron && <IconChevron size=".75em" className="chevron" />}{" "}
        {isOpen ? hideLabelNew : showLabel}
      </ContentToggleButton>

      <div id={id} hidden={!isOpen}>
        {children}
      </div>
    </ContentToggleWrapper>
  );
}

const ContentToggleWrapper = styled.div({
  position: "relative",
});

const ContentToggleButton = styled.button<{ isOpen: boolean }>(
  ({ isOpen }) => ({
    appearance: "none",
    backgroundColor: "rgb(0 0 0 / 0)",
    border: 0,
    cursor: "pointer",
    padding: 0,
    marginBottom: isOpen ? "1em" : 0,
    ".chevron": {
      transform: isOpen ? "rotate(0)" : "rotate(180deg)",
    },
  }),
);

export { ContentToggle };
