import * as React from "react";
import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import uniqueId from "lodash/uniqueId";
import {
  Address,
  Button,
  FieldError,
  Fieldset,
  FlexRow,
  IconEditItem,
  IconPlus,
  Input,
  Label,
  Legend,
  Modal,
  RequiredText,
  Select,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import {
  useArtistShippingAddresses,
  useUpdateArtistAddress,
  useCreateArtistAddress,
  type ShippingAddress,
  type ShippingAddressReq,
} from "features/reviewable/api/useArtistShippingAddress";

import { countries, countryToCodeMap, codeToCountryMap } from "utils/countries";
import { fieldOptions } from "utils/form";

type ArtistAddressProps = {
  artistId: string;
  btnRef?: React.RefObject<HTMLButtonElement>;
  isDisabled?: boolean;
  onSuccess?: (shipFromAddressId: string) => void;
};

function ArtistAddress({
  artistId,
  btnRef,
  isDisabled,
  onSuccess,
  ...props
}: ArtistAddressProps) {
  const { data, isLoading } = useArtistShippingAddresses(artistId);
  const address = data?.shipFromAddresses[0];

  const { isOpen, open, close } = Modal.useModalState();

  return (
    <Fieldset style={{ marginBottom: 0 }} {...props}>
      <Stack gap="xs">
        <Legend>
          Shipping Origin / Return Address
          {!address && (
            <>
              <br />

              <small>
                Add the address from which your items will be shipped, and where
                items will be returned if they cannot be delivered.
              </small>
            </>
          )}
        </Legend>

        {address?.id && (
          <Address size="xs" style={{ marginBottom: 16 }}>
            {address.name && <div>{address.name}</div>}
            {address.street1 && <div>{address.street1}</div>}
            {address.street2 && <div>{address.street2}</div>}
            <div>
              {address.city && <>{address.city}, </>}
              {address.state && address.state} {address.zip && address.zip}
            </div>
            {address.country && <div>{codeToCountryMap[address.country]}</div>}
          </Address>
        )}

        {!isLoading && !isDisabled && (
          <>
            <Button variant="secondary" size="xxs" onClick={open} ref={btnRef}>
              {!address ? (
                <>
                  Add Address <IconPlus size={10} style={{ marginTop: -2 }} />
                </>
              ) : (
                <>
                  Edit <IconEditItem size={13} style={{ marginTop: -1 }} />
                </>
              )}
            </Button>

            <ShippingAddressForm
              artistId={artistId}
              close={close}
              isOpen={isOpen}
              onSuccess={onSuccess}
              shippingAddress={address}
            />
          </>
        )}
      </Stack>
    </Fieldset>
  );
}

type ShippingAddressFormProps = {
  artistId: string;
  close: VoidFunction;
  isOpen: boolean;
  onSuccess?: (shipFromAddressId: string) => void;
  shippingAddress?: ShippingAddress;
};

function ShippingAddressForm({
  artistId,
  close,
  isOpen,
  onSuccess = noop,
  shippingAddress,
}: ShippingAddressFormProps) {
  const [formId] = React.useState(uniqueId());

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<ShippingAddressReq>({
    defaultValues: { artistId, ...shippingAddress },
  });

  const createMutation = useCreateArtistAddress();
  const updateMutation = useUpdateArtistAddress();

  const isEditing = !!shippingAddress;

  const { mutate, isLoading, isError, error } = isEditing
    ? updateMutation
    : createMutation;

  const onSubmit = handleSubmit((formData) => {
    if (!isDirty) {
      close();
    } else {
      mutate(formData, {
        onSuccess: (resp) => {
          // Update parent component upon save with latest address
          onSuccess(resp.shipFromAddresses[0].id);
          close();
        },
      });
    }
  });

  return (
    <SModal isOpen={isOpen} hideCloseButton onClose={close}>
      <form id={formId} onSubmit={onSubmit}>
        <Stack gap="sm">
          <Stack gap="xxs">
            <Modal.Title>
              <span>{isEditing ? "Edit" : "Add"}</span> Shipping Origin / Return
              Address
            </Modal.Title>

            <Text
              textAlign="center"
              size="xs"
              style={{ textWrap: "balance", lineHeight: 1.2 }}
            >
              This address is used to calculate shipping costs for your
              customers and to return packages when they are not able to be
              delivered.
            </Text>
          </Stack>

          <Label>
            Name / Company
            <RequiredText />
            <br />
            <small>First &amp; Last Name and/or Company Name</small>
            <Input
              {...register("name", {
                ...fieldOptions.required,
              })}
            />
            {errors?.name?.message && (
              <FieldError>{errors?.name?.message}</FieldError>
            )}
          </Label>

          <Label>
            Street Address
            <RequiredText />
            <Input {...register("street1", fieldOptions.required)} />
            {errors?.street1?.message && (
              <FieldError>{errors?.street1?.message}</FieldError>
            )}
          </Label>

          <Label>
            Street Address Line 2
            <br />
            <small>Apartment, suite, unit, building, floor, etc</small>
            <Input {...register("street2")} />
          </Label>

          <Label>
            City
            <RequiredText />
            <Input {...register("city", fieldOptions.required)} />
            {errors?.city?.message && (
              <FieldError>{errors?.city?.message}</FieldError>
            )}
          </Label>

          <FlexRow alignItems="flex-start" itemsFlex="" flexWrap="wrap">
            <Label style={{ flex: "1 0 auto" }}>
              State / Province / Region
              <RequiredText />
              <Input {...register("state", fieldOptions.required)} />
              {errors?.state?.message && (
                <FieldError>{errors?.state?.message}</FieldError>
              )}
            </Label>

            <Label style={{ flex: "0 0 14em" }}>
              ZIP / Postal Code
              <Input {...register("zip")} />
            </Label>
          </FlexRow>

          <Label>
            Country/Region
            <RequiredText />
            <Select {...register("country", fieldOptions.required)}>
              <option value="">Select your country/region</option>
              {countries.map((country) => (
                <option key={country} value={countryToCodeMap[country]}>
                  {country}
                </option>
              ))}
            </Select>
            {errors?.country?.message && (
              <FieldError>{errors?.country?.message}</FieldError>
            )}
          </Label>

          {isError && <UnexpectedError error={error} />}
        </Stack>

        <Modal.Footer>
          <FlexRow justifyContent="center" flexDirection="column" gap="10px">
            <Button size="xs" type="submit" disabled={isLoading} form={formId}>
              Save Address
            </Button>

            <Button
              variant="text"
              size="xs"
              onClick={close}
              disabled={isLoading}
            >
              Cancel
            </Button>
          </FlexRow>
        </Modal.Footer>
      </form>
    </SModal>
  );
}

const SModal = styled(Modal)(({ theme }) => ({
  [theme.breakpoints.desktop]: { maxWidth: "36rem" },
}));

export { ArtistAddress };
