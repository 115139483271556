import * as React from "react";
import styled from "@emotion/styled";
import {
  Button,
  H1,
  Input,
  Label,
  AppError,
  AllCaps,
  Box,
  Container,
  FlexRow,
  IconHugLogo,
  Stack,
} from "atoms";
import { ApiError } from "utils/api";

interface StagingSignInProps {
  stagingSignIn: (password: string) => void;
  isLoading: boolean;
  isError: boolean;
  error: ApiError | null;
}

function StagingSignIn({
  stagingSignIn,
  isLoading,
  isError,
  error,
}: StagingSignInProps) {
  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    const password = new FormData(event.currentTarget).get(
      "password",
    ) as string;
    stagingSignIn(password);
  };

  const errorMessage =
    isError && error?.errorCode === "WRONG_STAGING_PASSWORD"
      ? "Incorrect password"
      : "Sorry, something went wrong";

  return (
    <Main>
      <Container width="xs">
        <Stack gap="sm">
          <FlexRow justifyContent="center" as="h5" aria-hidden="true">
            <IconHugLogo size="60px" color="fg" />
          </FlexRow>
          <Box>
            <form onSubmit={onSubmit}>
              <H1 size="h2" textAlign="center">
                <AllCaps>Hug</AllCaps> Preview
              </H1>

              <SLabel>
                Password
                <Input
                  name="password"
                  type="password"
                  bordered={false}
                  autoCapitalize="none"
                  autoFocus
                />
              </SLabel>

              <ButtonWrapper>
                <Button
                  type="submit"
                  disabled={isLoading}
                  variant="primary"
                  arrow="r"
                >
                  Enter
                </Button>

                {isError && <AppError role="alert">{errorMessage}</AppError>}
              </ButtonWrapper>
            </form>
          </Box>
        </Stack>
      </Container>
    </Main>
  );
}

const Main = styled.main(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  minHeight: "100vh",
  color: theme.colors.fg,
}));

const SLabel = styled(Label)({}, ({ theme }) => ({
  margin: `${theme.spacing.md} 0`,
}));

const ButtonWrapper = styled.div({
  display: "flex",
  flexDirection: "row",
  alignItems: "baseline",
  justifyContent: "center",
});

export { StagingSignIn };
