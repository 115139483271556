import * as React from "react";
import styled from "@emotion/styled";
import { uniqueId } from "lodash";
import { useForm } from "react-hook-form";
import {
  Alert,
  AllCaps,
  Button,
  CharacterCounter,
  Checkbox,
  FakeLabel,
  FieldError,
  FlexRow,
  H2,
  H3,
  IconLogoBase,
  IconPlusThick,
  IconWarningSignAlt,
  Input,
  Label,
  Link,
  List,
  LoadingSpinner,
  Modal,
  Radio,
  RequiredText,
  Select,
  Stack,
  Text,
  TextArea,
  UnexpectedError,
} from "atoms";
import { useAuth } from "features/auth";
import { GenericImage, ImageUpload } from "features/images";
import { sortByCreated } from "utils/date";
import { fieldOptions } from "utils/form";
import { formatWalletAddress } from "utils/wallet";
import { ProductWizardStep } from "../ProductWizardStep";
import type { ArtistOnChainContract, StepProps } from "../../types";
import {
  useUpdateProductDetails,
  type UpdateProductDetailsReq,
} from "../../api/useUpdateProductDetails";
import {
  useArtistOnChainContracts,
  useCreateArtistOnChainContracts,
  type CreateArtistOnChainContractReq,
} from "../../api/useArtistOnChainContracts";
import { contractSymbolFromName } from "../../utils/contractSymbolFromName";
import { DetailColumns, DetailForm, DetailImage } from "./StepDetails";

const ROYALTY_OPTS = [0, 5, 10, 15, 20, 25, 30, 35, 40, 45, 50] as const;

function StepTokenDetails({ state, ...props }: StepProps) {
  // We need to fetch the contracts for the artist _before_ rendering the form, otherwise we can't
  // set default values for contract selection properly.
  const { data, isLoading, isError, isSuccess, error } =
    useArtistOnChainContracts(state.artistId);

  const latestContracts = React.useMemo(
    () => (data ? data.sort(sortByCreated).reverse() : []),
    [data],
  );

  return isSuccess ? (
    <ConnectedStepTokenDetails
      {...props}
      state={state}
      contracts={latestContracts}
    />
  ) : (
    <ProductWizardStep
      state={state}
      stepTitle="Collectible Information"
      moveBack={noop}
      nextStep={noop}
      nextStepDisabled={isLoading}
    >
      {isLoading && <LoadingSpinner />}
      {isError && <UnexpectedError error={error} />}
    </ProductWizardStep>
  );
}

function ConnectedStepTokenDetails({
  contracts,
  dispatch,
  state,
  moveBack,
}: StepProps & { contracts: ArtistOnChainContract[] }) {
  const { isLoading, mutate } = useUpdateProductDetails(state.product.id);

  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const toggleModal = () => setIsModalOpen(!isModalOpen);

  const {
    title,
    description,
    maximumQuantity,
    tokenName,
    tokenDescription,
    royaltyPercentage,
    publishedAt,
  } = state.product;

  // has the product ever been published
  const isPublished = !!publishedAt;

  const {
    register,
    formState: { errors, isDirty },
    handleSubmit,
    setValue,
    watch,
  } = useForm<UpdateProductDetailsReq & { editionType: "0" | "1" | "other" }>({
    defaultValues: {
      tokenName: tokenName?.length ? tokenName : title,
      tokenDescription: tokenDescription?.length
        ? tokenDescription
        : description,
      // Set max quantity field for text input, but set editionType based on it for radio buttons
      maximumQuantity,
      editionType:
        maximumQuantity === "0" || maximumQuantity === "1"
          ? maximumQuantity
          : "other",
      royaltyPercentage: royaltyPercentage ?? "0",
      // Set contract ID to whatever exists on product first, or their first if they have any
      // NOTE: Empty JSON metafield values are empty string not undefined or null
      contractId: state.product.contractId || undefined,
    },
  });

  const editionType = watch("editionType");

  // Register contractId field for validation and handling our own focus on failure
  register("contractId", fieldOptions.required);

  const contractRef = React.useRef<HTMLButtonElement>(null);
  const contractCount = contracts.length;

  // Set contract to view a contract
  const [viewContract, setViewContract] = React.useState<
    ArtistOnChainContract | undefined
  >(undefined);

  // Open the contract form modal, with optional contract
  const openModal = (contract?: ArtistOnChainContract) => {
    setViewContract(contract);
    toggleModal();
  };

  const closeModal = (contract?: ArtistOnChainContract) => {
    setIsModalOpen(false);
    setViewContract(contract);
  };

  const moveNext = () => dispatch({ type: "moveNext" });
  const onSubmit = handleSubmit(
    ({
      editionType: _editionType,
      maximumQuantity: _maximumQuantity,
      ...formData
    }) => {
      if (isDirty) {
        const newMaximumQuantity =
          editionType === "other" ? _maximumQuantity : _editionType;
        mutate(
          {
            maximumQuantity: newMaximumQuantity,
            ...formData,
          },
          {
            onSuccess: moveNext,
          },
        );
      } else {
        moveNext();
      }
    },
    (validationErrors) => {
      if (validationErrors.contractId) {
        // Focus the create collection button but after the useForm tries to focus other fields
        setTimeout(() => contractRef.current?.focus(), 1);
      }
    },
  );

  const createCollectionSuccess = (newContract?: ArtistOnChainContract) => {
    if (newContract) {
      setValue("contractId", newContract.contractId, {
        shouldTouch: true,
        shouldDirty: true,
        shouldValidate: !!errors.contractId,
      });
    }
    toggleModal();
  };

  return (
    <ProductWizardStep
      state={state}
      stepTitle="Collectible Information"
      moveBack={moveBack}
      nextStep={isPublished ? moveNext : onSubmit}
      nextStepDisabled={isLoading}
    >
      <Stack gap="md">
        {isPublished && (
          <Alert type="warning">
            <Text size="xxs" textAlign="center" style={{ margin: 0 }}>
              This product has been published and its onchain information can no
              longer be edited.
            </Text>
          </Alert>
        )}

        <Stack>
          <Text size="xxs">
            To ensure the authenticity and provenance (i.e. record of ownership)
            of your digital collectible, provide additional information about
            your work that you would like to record on the blockchain.
          </Text>

          {!isPublished && (
            <Text size="xxs">
              <strong>
                Please ensure all details are correct before proceeding.
              </strong>{" "}
              Once your digital collectible is collected, this information
              cannot be changed.{" "}
            </Text>
          )}
        </Stack>

        <Stack gap="md">
          <DetailColumns>
            {state.product.featuredImage?.url && (
              <DetailImage>
                <GenericImage
                  src={state.product.featuredImage.url}
                  style={{ border: "1px solid rgb(0 0 0 / .1)" }}
                  sizes="300px"
                />
              </DetailImage>
            )}

            <DetailForm>
              <Stack gap="md">
                <Stack>
                  <FakeLabel>
                    Collection
                    <RequiredText />
                  </FakeLabel>

                  <Stack>
                    {!!contracts.length && (
                      <List type="blank">
                        {contracts.map((c) => (
                          <Li key={c.contractId}>
                            <Radio
                              {...register("contractId", fieldOptions.required)}
                              value={c.contractId}
                              label={
                                <FlexRow gap="6px 1em">
                                  <Text size="xs" bold style={{ margin: 0 }}>
                                    {c.contractName}
                                  </Text>

                                  <Button
                                    onClick={() => openModal(c)}
                                    size="xxs"
                                    variant="text"
                                  >
                                    Show Details
                                  </Button>
                                </FlexRow>
                              }
                            />
                          </Li>
                        ))}
                      </List>
                    )}
                    {contractCount >= 5 ? (
                      <SAlert type="warning">
                        <Text
                          size="xxs"
                          textAlign="center"
                          style={{ margin: 0 }}
                        >
                          <IconWarningSignAlt /> You have reached your limit of
                          5 collections
                        </Text>
                      </SAlert>
                    ) : (
                      <Stack>
                        <div>
                          <Button
                            onClick={() => openModal()}
                            ref={contractRef}
                            size="xxs"
                            variant="secondary"
                            disabled={contractCount >= 5}
                          >
                            Create Collection <IconPlusThick />
                          </Button>
                        </div>
                        {errors.contractId && (
                          <FieldError>{errors.contractId.message}</FieldError>
                        )}
                      </Stack>
                    )}
                  </Stack>
                  <SModal isOpen={isModalOpen} onClose={() => closeModal()}>
                    <CreateCollection
                      // TODO: Pass current contract, if it exists.
                      // TODO: Allow editing if product is not yet published
                      artistId={state.artistId}
                      contract={viewContract}
                      onSuccess={
                        viewContract ? closeModal : createCollectionSuccess
                      }
                      onCancel={closeModal}
                    />
                  </SModal>
                </Stack>
                <Label>
                  Collectible Name
                  <RequiredText />
                  <br />
                  <span>
                    This name will be recorded on the blockchain and cannot be
                    changed once your digital collectible is collected.
                  </span>
                  <Input
                    {...register("tokenName", fieldOptions.required)}
                    disabled={isPublished}
                  />
                  {errors.tokenName && (
                    <FieldError>{errors.tokenName.message}</FieldError>
                  )}
                </Label>
                <Label>
                  Collectible Description
                  <RequiredText />
                  <br />
                  <span>
                    The description of your collectible will be recorded on the
                    blockchain and cannot be changed once your digital
                    collectible is collected.
                  </span>
                  <TextArea
                    {...register("tokenDescription", fieldOptions.required)}
                    disabled={isPublished}
                  />
                  {errors.tokenDescription && (
                    <FieldError>{errors.tokenDescription.message}</FieldError>
                  )}
                </Label>
                <Stack>
                  <FakeLabel>
                    Edition Type
                    <RequiredText />
                  </FakeLabel>
                  <Text size="xxs">
                    <strong>Open Edition</strong> means that there is no limit
                    to how many times your creation can be collected.
                    <br />
                    <strong>Limited Edition</strong> allows you to specify the
                    number of copies available for collection.
                    <br />
                    <strong>1/1</strong> means that your creation is unique and
                    can only ever be collected by one person.
                  </Text>
                  <FlexRow>
                    <Stack>
                      <Radio
                        {...register("editionType", fieldOptions.required)}
                        label={<Text size="xs">Open Edition</Text>}
                        value="0"
                        disabled={isPublished}
                      />
                    </Stack>

                    <Stack>
                      <Radio
                        {...register("editionType", fieldOptions.required)}
                        label={<Text size="xs">Limited Edition</Text>}
                        value="other"
                        disabled={isPublished}
                      />
                    </Stack>

                    <Stack>
                      <Radio
                        {...register("editionType", fieldOptions.required)}
                        label={<Text size="xs">1/1</Text>}
                        value="1"
                        disabled={isPublished}
                      />
                    </Stack>
                  </FlexRow>
                  {errors.editionType && (
                    <FieldError>{errors.editionType.message}</FieldError>
                  )}
                </Stack>
                {editionType === "other" && (
                  <Label>
                    Number of Editions
                    <RequiredText />
                    <Input
                      type="number"
                      {...register("maximumQuantity", {
                        ...fieldOptions.required,
                        ...fieldOptions.max(10000),
                      })}
                      min={1}
                      max={10000}
                      readOnly={isPublished}
                    />
                    {errors.maximumQuantity && (
                      <FieldError>{errors.maximumQuantity.message}</FieldError>
                    )}
                  </Label>
                )}
                <Label>
                  Royalties
                  <RequiredText />
                  <br />
                  <span>
                    When your work is created and sold onchain, you get to earn
                    a percentage of future resales, otherwise known as secondary
                    royalties. Royalties are by default set to 10%, but you are
                    free to select any number from 0 to 50%.
                  </span>
                  <Select
                    {...register("royaltyPercentage", fieldOptions.required)}
                    disabled={isPublished}
                  >
                    {ROYALTY_OPTS.map((value) => (
                      <option key={value} value={value}>
                        {value}%
                      </option>
                    ))}
                  </Select>
                  {errors.royaltyPercentage && (
                    <FieldError>{errors.royaltyPercentage.message}</FieldError>
                  )}
                </Label>
              </Stack>
            </DetailForm>
          </DetailColumns>
        </Stack>
      </Stack>
    </ProductWizardStep>
  );
}

StepTokenDetails.displayName = "Onchain";

type CreateCollectionProps = {
  artistId: string;
  contract?: ArtistOnChainContract | null;
  onSuccess: (contract?: ArtistOnChainContract) => void;
  onCancel: () => void;
};

function CreateCollection({
  artistId,
  contract,
  onSuccess,
  onCancel,
}: CreateCollectionProps) {
  const { user } = useAuth();
  const userWallets = user?.wallets ?? [];

  const { isLoading, mutate } = useCreateArtistOnChainContracts(artistId);

  const [formId] = React.useState(uniqueId());

  const {
    control,
    register,
    formState: { errors },
    handleSubmit,
    watch,
  } = useForm<CreateArtistOnChainContractReq>({
    defaultValues: {
      artistId,
      artistWallet: userWallets[0],
    },
  });

  // TODO: if a contract is passed and product is not yet published, show it's details. Else, show form.
  if (contract) {
    const artistWallet = contract.artistWallet ?? "TBD";
    const contractAddress = contract.address ?? "Not Yet Deployed";

    return (
      <Stack gap="sm">
        <Stack gap="0">
          <Text size="xs" bold>
            Collection Name
          </Text>
          <H3 size="xl">{contract.contractName}</H3>
        </Stack>

        <Stack gap="0">
          <Text size="xs" bold>
            Blockchain
          </Text>

          <FlexRow gap="5px" alignItems="flex-start">
            <IconLogoBase size={22} />
            <Text
              style={{
                margin: 0,
              }}
            >
              <span>
                Base {contract.chainId === "84532" && <span>Sepolia</span>}
              </span>
            </Text>
          </FlexRow>
        </Stack>

        <Stack gap="0">
          <Text size="xs" bold>
            Description
          </Text>
          <Text>{contract.description}</Text>
        </Stack>
        {contract.image && (
          <Stack gap="0">
            <Text size="xs" bold>
              Thumbnail
            </Text>
            <Text>
              <SGenericImage src={contract.image} sizes="200" width="200" />
            </Text>
          </Stack>
        )}

        <Text size="xxs">
          Your contract ({contractAddress}) is connected to your wallet{" "}
          <span>{artistWallet}</span>
        </Text>
      </Stack>
    );
  }

  const onSubmit = handleSubmit((formData) =>
    mutate(
      {
        ...formData,
        contractSymbol: contractSymbolFromName(formData.contractName),
      },
      { onSuccess },
    ),
  );

  const collectionName = watch("contractName");

  const artistWalletProps = register("artistWallet", fieldOptions.required);

  return (
    <form id={formId} onSubmit={onSubmit}>
      <Stack gap="md">
        <Stack>
          <H2 size="lg" textAlign="center" textTransform="none">
            Create New Collection
          </H2>
          <Text textAlign="center" size="xs">
            A collection organizes your creations, similar to an album or folder
            that you can keep adding to. Since each collection is created as its
            own contract on the blockchain, its name cannot be changed after the
            first collectible is collected onchain. <br />
          </Text>
          <SAlert type="error" aria-live="off">
            <Text size="xs" textAlign="center" bold style={{ margin: 0 }}>
              Please ensure all details are correct before proceeding.
            </Text>
          </SAlert>

          <Text textAlign="center" size="xs">
            You may create a maximum of <strong>5</strong> collections.
          </Text>
        </Stack>
        <Stack>
          <FakeLabel>Blockchain</FakeLabel>
          <FlexRow gap="5px">
            <IconLogoBase size={24} />
            <Text style={{ margin: 0 }}>Base</Text>
          </FlexRow>
        </Stack>

        <Label>
          Collection Name
          <RequiredText />
          <Input
            {...register("contractName", {
              ...fieldOptions.required,
              ...fieldOptions.maxLength(32),
            })}
          />
          {errors.contractName && (
            <FieldError>{errors.contractName.message}</FieldError>
          )}
          <CharacterCounter max={32} value={collectionName ?? ""} />
        </Label>

        <Label>
          Collection Description
          <TextArea {...register("description")} />
        </Label>
        <Stack gap="0">
          <ImageUpload
            control={control}
            fieldName="image"
            label="Collection Thumbnail"
            accept="image"
            errorMessage={errors.image?.message}
          />
          <Text size="xxxs">
            <em>
              The collection thumbnail is not recorded onchain and can be
              changed later.
            </em>
          </Text>
        </Stack>

        <Label>
          Wallet
          <RequiredText />
          {userWallets.length > 1 && (
            <Select {...artistWalletProps}>
              {userWallets.map((address) => (
                <option key={address} value={address}>
                  {formatWalletAddress(address)}
                </option>
              ))}
            </Select>
          )}
          {userWallets.length === 1 && (
            <Input {...artistWalletProps} disabled />
          )}
          {userWallets.length === 0 && (
            <>
              <Text>
                You do not have a wallet connected to your{" "}
                <AllCaps>Hug</AllCaps> account.{" "}
                <Link to="/profile/settings">Connect a wallet</Link> to claim
                ownership of this collection onchain as well as to receive
                royalties from future resales.
              </Text>
              <Text>
                Alternatively, check the box to continue without a wallet. You
                will be able to connect a wallet in the future.
              </Text>
              <Checkbox
                {...artistWalletProps}
                label="Create collection with email address"
                value="EMAIL"
              />
            </>
          )}
          {errors.artistWallet && (
            <FieldError>{errors.artistWallet.message}</FieldError>
          )}
        </Label>

        {isLoading && <LoadingSpinner />}
      </Stack>

      <Modal.Footer>
        <FlexRow
          flexDirection="column"
          justifyContent="center"
          alignItems="center"
        >
          <Button size="xs" type="submit" form={formId} disabled={isLoading}>
            Create Collection
          </Button>
          <Button size="xs" variant="text" onClick={onCancel}>
            Cancel
          </Button>
        </FlexRow>
      </Modal.Footer>
    </form>
  );
}

const SModal = styled(Modal)(({ theme }) => ({
  [theme.breakpoints.desktop]: { "&&": { maxWidth: 680 } },
}));
const SAlert = styled(Alert)({
  padding: 5,
  justifyContent: "center",
  marginInline: " auto",
});

const Li = styled.li({ label: { marginBlock: 10 } });

const SGenericImage = styled(GenericImage)({
  width: 200,
  height: "auto",
});

export { StepTokenDetails };
