import * as React from "react";
import uniqueId from "lodash/uniqueId";
import styled from "@emotion/styled";
import { useToggle } from "utils/hooks";
import { IconChevron } from "./icons";

type ExpandContentProps = {
  /** Number of lines that should appear above toggle button */
  linesVisible?: number;
  showLabel?: React.ReactNode;
  hideLabel?: React.ReactNode;
  chevron?: boolean;
  children: React.ReactNode;
};

// Gets element's line height
const getLineHeight = (element: HTMLElement) => {
  if (element.firstElementChild) {
    return window.getComputedStyle(element.firstElementChild).lineHeight;
  }

  return window.getComputedStyle(element).lineHeight;
};

function ExpandContent({
  linesVisible = 3,
  showLabel = "Expand content",
  hideLabel = "Collapse content",
  chevron = true,
  children,
  ...props
}: ExpandContentProps) {
  // Generate Uniue ID for content element
  const [id] = React.useState(uniqueId());
  // Set ref for content element
  const ref = React.useRef<HTMLDivElement | null>(null);

  const [style, setStyle] = React.useState<ExpandContentTextStyle>({});
  const [shouldHide, setShouldHide] = React.useState(false);
  const [isOpen, toggle] = useToggle(false);

  // Use the element's line height Get child line height in pixels
  React.useLayoutEffect(() => {
    if (ref.current) {
      const lineHeight = Number(getLineHeight(ref.current).replace("px", ""));

      if (!Number.isNaN(lineHeight)) {
        const textHeight = ref.current.getBoundingClientRect().height;

        // Set target height for collapsed content
        const targetHeight = lineHeight * linesVisible;

        // Set the threshold height
        const thresholdHeight = targetHeight + lineHeight * 2;

        // Hide extra lines only if content is taller than the threshoold height (more than 2 hidden lines)
        if (textHeight >= thresholdHeight) {
          setShouldHide(true);
          setStyle((old) => ({
            ...old,
            "--lines-visible": linesVisible,
            "--line-height": `${lineHeight}px`,
            maxHeight: `${targetHeight}px`,
          }));
        }
      }
    }
  }, [linesVisible, setShouldHide, setStyle]);

  return (
    <ExpandContentWrapper {...props}>
      <ExpandContentText
        id={id}
        isOpen={isOpen}
        shouldHide={shouldHide}
        style={isOpen ? undefined : style}
      >
        <div ref={ref}>{children}</div>
      </ExpandContentText>

      {shouldHide && (
        <ExpandContentButton
          onClick={toggle}
          isOpen={isOpen}
          aria-controls={id}
          aria-label={isOpen ? "Collapse content" : "Expand content"}
          aria-expanded={isOpen}
          type="button"
        >
          {chevron && <IconChevron size=".75em" className="chevron" />}{" "}
          {isOpen ? hideLabel : showLabel}
        </ExpandContentButton>
      )}
    </ExpandContentWrapper>
  );
}

const ExpandContentWrapper = styled.div({
  position: "relative",
  marginBottom: 5,
});

interface ExpandContentTextStyle extends React.CSSProperties {
  "--line-height"?: string;
  "--lines-visible"?: number;
}

const ExpandContentText = styled.div<{
  isOpen: boolean;
  shouldHide: boolean;
}>(({ isOpen, shouldHide }) => ({
  ...(shouldHide && {
    "&>div": {
      textWrap: "wrap",
      display: "-webkit-box",
      WebkitBoxOrient: "vertical",
      "&,*": {
        lineHeight: "var(--line-height)",
      },
      ...(!isOpen && {
        textOverflow: "ellipsis",
        WebkitLineClamp: "var(--lines-visible)",
        "*:empty, br, hr": { display: "none" },
      }),
    },
  }),
  overflow: "hidden",
  "p:last-of-type, ul:last-of-type": {
    marginBottom: 0,
  },
}));

const ExpandContentButton = styled.button<{ isOpen: boolean }>(
  ({ isOpen, theme }) => ({
    ...theme.fonts.body,
    backgroundColor: "rgb(0 0 0 / 0)",
    border: 0,
    borderRadius: 0,
    color: theme.colors.fg,
    cursor: "pointer",
    fontSize: theme.fontSizes.xxs,
    marginTop: ".5rem",
    opacity: 1,
    padding: 0,
    ".chevron": {
      transform: isOpen ? "rotate(0)" : "rotate(180deg) translateY(-2px)",
    },
    "&:hover": {
      textDecoration: "underline",
    },
  }),
);

export { ExpandContent };
