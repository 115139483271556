import * as React from "react";
import styled from "@emotion/styled";
import { IconChevron } from "./icons";

interface AccordionProps extends React.HTMLAttributes<HTMLDetailsElement> {
  heading: React.ReactNode;
  isOpenOnLoad?: boolean;
}

function Accordion({
  heading,
  isOpenOnLoad = false,
  children,
  ...props
}: AccordionProps) {
  return (
    <Details {...props} open={isOpenOnLoad}>
      <summary>
        <SummaryContent>
          <IconChevron />
          <span>{heading}</span>
        </SummaryContent>
      </summary>

      <Content>{children}</Content>
    </Details>
  );
}

const Details = styled.details(({ theme }) => ({
  borderBottom: `1px solid ${theme.colors.fg}`,
  paddingBottom: ".75rem",
  overflow: "hidden",

  "& > summary": {
    cursor: "pointer",
    fontSize: theme.fontSizes.md,
    padding: "1.5rem 0 .75rem",
    lineHeight: 1.2,
    listStyle: "none",
    marginLeft: -20,

    "@media (prefers-reduced-motion: no-preference)": {
      transition: "color 0.1s ease",
      "& > *": {
        transition: "transform 0.2s ease",
      },
    },
    "&:hover, &:focus-visible": {
      color: theme.colors.fg,
    },
    "& > h1, & > h2, & > h3": {
      margin: 0,
    },
  },
  "&>summary::WebkitDetailsMarker, &>summary::marker": {
    display: "none",
    fontSize: 0,
  },
  "& > summary svg": {
    flex: "0 0 auto",
    margin: 0,
    overflow: "visible",
    width: ".8rem",
    transform: "rotate(180deg)",
    transition: "none",
  },
  "&[open] > summary svg": {
    transform: "rotate(0deg)",
  },
  "& > div": {
    margin: ".5rem 0 1.25rem 0",
    fontSize: theme.fontSizes.sm,
    "& > :last-child": {
      marginBottom: 0,
    },
  },
}));

const SummaryContent = styled.div({
  display: "flex",
  alignItems: "center",
  gap: ".5em",
  paddingLeft: 20,
});

const Content = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.sm,
}));

export { Accordion };
