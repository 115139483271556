import type {
  CheckoutMetadata,
  CheckoutSessionRequest,
  LineItem,
} from "../types";

/**
 * Type guard to determine if value is LineItem
 */
const isLineItem = (arg: unknown): arg is LineItem =>
  !!arg &&
  typeof arg === "object" &&
  typeof (arg as LineItem).priceId === "string" &&
  typeof (arg as LineItem).quantity === "number";

/**
 * Type guard to determine if value is array of LineItems
 */
const isLineItems = (arg: unknown): arg is LineItem[] =>
  !!arg && Array.isArray(arg) && arg.every(isLineItem);

const isCheckoutMetadata = (arg: unknown): arg is CheckoutMetadata =>
  !!arg &&
  typeof arg === "object" &&
  Object.entries(arg).every(
    ([key, value]) =>
      typeof key === "string" &&
      (["string", "number"].includes(typeof value) || value === null),
  );

const isCheckoutSessionRequest = (
  arg: unknown,
): arg is CheckoutSessionRequest =>
  !!arg &&
  typeof arg === "object" &&
  isLineItems((arg as CheckoutSessionRequest).lineItems) &&
  typeof (arg as CheckoutSessionRequest).returnUrl === "string" &&
  (isCheckoutMetadata((arg as CheckoutSessionRequest).metadata) ||
    typeof (arg as CheckoutSessionRequest).metadata === "undefined");

export { isCheckoutSessionRequest };
