import * as React from "react";
import styled from "@emotion/styled";
import "@github/clipboard-copy-element";
import { trackShareCopy } from "contexts/mixpanel";
import { FlexRow } from "./FlexRow";
import { IconCopy } from "./icons";

const animationTime = 0.3;
const focusTime = 2;

type CopyToClipboardProps = {
  value: string;
  successText?: string;
  buttonText?: React.ReactNode;
  sendShareEvent?: boolean;
  variant?: "primary" | "secondary";
  size?: "xs" | "sm";
  onClick?: VoidFunction;
};

function CopyToClipboard({
  buttonText = "Copy",
  successText = "Copied",
  value,
  sendShareEvent,
  onClick,
  ...props
}: CopyToClipboardProps) {
  const [copied, setCopied] = React.useState(false);
  const buttonRef = React.useRef<null | HTMLElement>(null);

  React.useEffect(() => {
    if (copied) {
      const blurTimer = setTimeout(() => {
        buttonRef.current?.blur();
        // Pass through to parent
        if (onClick) {
          onClick();
        }
      }, 1000 * focusTime);
      const resetTimer = setTimeout(
        () => {
          setCopied(false);
        },
        1000 * (focusTime + animationTime),
      );

      return () => {
        clearTimeout(blurTimer);
        clearTimeout(resetTimer);
      };
    }

    return noop;
  }, [copied, onClick]);

  const animateClick = () => {
    setCopied(true);
    if (sendShareEvent) {
      trackShareCopy();
    }
  };
  const animateKeydown = (event: { key: string }) => {
    if (event.key === "Enter") {
      setCopied(true);
      if (sendShareEvent) {
        trackShareCopy();
      }
    }
  };

  return (
    <Wrapper {...props}>
      <CopyButton
        value={value}
        ref={buttonRef}
        onClick={animateClick}
        onKeyDown={animateKeydown}
      >
        <FlexRow gap="6px">
          <SIconCopy />
          {buttonText}
        </FlexRow>
      </CopyButton>
      <Success copied={copied} aria-live="polite">
        {successText}
      </Success>
    </Wrapper>
  );
}

const Wrapper = styled.div<Pick<CopyToClipboardProps, "variant" | "size">>(
  ({ size = "sm", variant, theme }) => ({
    ...theme.fonts.display,
    // textTransform: "uppercase",
    position: "relative",
    cursor: "pointer",
    maxWidth: "100%",
    width: "max-content",
    ...(!!variant && {
      border: "2px solid",
      borderRadius: theme.borderRadius.round,
      letterSpacing: ".02em",
      lineHeight: 1.2,
      padding: "0.45em 1em 0.4em",
    }),
    ...(variant === "primary" && {
      backgroundColor: theme.colors.fg,
      color: theme.colors.bg,
      borderColor: theme.colors.fg,
      "&:hover, &:focus-visible": {
        backgroundColor: theme.colors.bg,
        color: theme.colors.fg,
      },
    }),
    ...(variant === "secondary" && {
      backgroundColor: theme.colors.bg,
      color: theme.colors.fg,
      borderColor: theme.colors.fg,
      "&:hover, &:focus-visible": {
        backgroundColor: theme.colors.fg,
        color: theme.colors.bg,
      },
    }),
    ...(size === "xs" && {
      fontSize: theme.fontSizes.xxs,
      minHeight: 0,
      lineHeight: 1.2,
      padding: "0.45em 1.2em 0.4em",
    }),
  }),
);

const Success = styled.div<{ copied: boolean }>(({ copied, theme }) => ({
  ...theme.fonts.body,
  fontSize: theme.fontSizes.sm,
  textTransform: "none",
  fontWeight: "bold",
  opacity: copied ? 1 : 0,
  backgroundColor: theme.colors.successL,
  color: theme.colors.fg,
  borderRadius: theme.borderRadius.xs,
  border: "2px solid",
  borderColor: theme.colors.success,
  boxShadow: theme.boxShadow.dark,
  padding: "1rem 1.5rem",
  position: "fixed",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  margin: 0,
  transition: ".3s ease opacity",
  textAlign: "center",
  pointerEvents: "none",
  minWidth: 150,
  zIndex: 999,
}));

const CopyButton = styled("clipboard-copy")({});

const SIconCopy = styled(IconCopy)({
  maxWidth: ".9em",
  maxHeight: ".9em",
  position: "relative",
  top: -1,
});

export { CopyToClipboard };
