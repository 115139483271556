import { useMutation, useQueryClient } from "react-query";
import type { GalleryItemFormFields } from "../types";
import { galleryKeys } from "./queryKeys";
import { createGalleryItems } from "./useGalleryItemsCreate";

/**
 * API to create a single gallery item
 */
const createGalleryItem =
  (artistId?: string) => async (item: GalleryItemFormFields) =>
    (await createGalleryItems({ items: [item], artistId }))[0];

/**
 * Mutation to create a single gallery item
 */
const useGalleryItemCreate = (artistId?: string) => {
  const queryClient = useQueryClient();

  return useMutation(createGalleryItem(artistId), {
    onSuccess: () => {
      queryClient.invalidateQueries(galleryKeys.base);
    },
  });
};

export { useGalleryItemCreate };
