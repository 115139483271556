import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

const Hide = styled.div<{ breakpoint: keyof Theme["breakpoints"] }>(
  ({ breakpoint, theme }) => ({
    [theme.breakpoints[breakpoint]]: {
      display: "none",
    },
  }),
);

export { Hide };
