import styled from "@emotion/styled";
import { Img } from "./Img";
import { FlexRow } from "./FlexRow";
import { BrushWrapper, BrushWrapperProps } from "./BrushWrapper";

type CtaCenteredProps = Pick<BrushWrapperProps, "bg"> & {
  children?: React.ReactNode;
  image1?: string;
  image2?: string;
};

function CtaCentered({
  bg,
  children,
  image1,
  image2,
  ...props
}: CtaCenteredProps) {
  return (
    <div {...props}>
      <CtaWrapper bg={bg}>
        <FlexRow justifyContent="center" gap="8vw">
          <PillPhoto1 src={image1} />
          <PillPhoto2 src={image2} />
        </FlexRow>
        <CtaContent>{children}</CtaContent>
      </CtaWrapper>
    </div>
  );
}

const CtaWrapper = styled(BrushWrapper)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  padding: "0 1rem 2rem",
  position: "relative",
  [theme.breakpoints.desktopMedium]: {
    padding: "4rem 0",
  },
}));

const CtaContent = styled.div(({ theme }) => ({
  textAlign: "center",
  [theme.breakpoints.desktopMedium]: {
    paddingLeft: "21%",
    paddingRight: "21%",
  },
}));

const pillWidth = "calc(70px + 12vw)";
const pillWidthLg = "calc(25vw)";

const PillPhoto = styled(Img)(({ theme }) => ({
  zIndex: 0,
  maxWidth: 200,
  width: pillWidth,
  objectFit: "cover",
  objectPosition: "center",
  display: "inline-block",
  marginBottom: "2rem",
  [theme.breakpoints.desktop]: {
    width: pillWidthLg,
  },
  [theme.breakpoints.desktopMedium]: {
    margin: 0,
    width: "14%",
  },
}));

const PillPhoto1 = styled(PillPhoto)(({ theme }) => ({
  borderRadius: `0 0 ${theme.borderRadius.round}px ${theme.borderRadius.round}px`,
  height: 170,
  alignSelf: "flex-start",
  [theme.breakpoints.xs]: {},
  [theme.breakpoints.tablet]: {
    height: 200,
  },
  [theme.breakpoints.desktop]: {
    height: "65%",
  },
  [theme.breakpoints.desktopMedium]: {
    height: "65%",
    position: "absolute",
    top: 0,
    left: "2rem",
  },
}));

const PillPhoto2 = styled(PillPhoto)(({ theme }) => ({
  borderRadius: `0 0 ${theme.borderRadius.round}px ${theme.borderRadius.round}px`,
  height: 150,
  alignSelf: "flex-start",
  [theme.breakpoints.xs]: {},
  [theme.breakpoints.tablet]: { height: 170 },
  [theme.breakpoints.desktopMedium]: {
    borderRadius: `${theme.borderRadius.round}px ${theme.borderRadius.round}px 0 0`,
    position: "absolute",
    bottom: 0,
    right: "2rem",
    height: "65%",
  },
}));

export { CtaCentered };
