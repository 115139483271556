import { useQuery } from "react-query";
import { api } from "utils/api";
import { InstagramProfileResponse } from "../types";

// Note this API will get the instagram username
const getProfile = (authToken: string) =>
  api.post<InstagramProfileResponse>("/social/instagram/profile", {
    body: JSON.stringify({ authToken }),
  });

const useInstagramProfile = (accessToken?: string) =>
  useQuery(
    ["instagram", accessToken, "profile"],
    () => getProfile(accessToken ?? ""),
    { enabled: !!accessToken },
  );

export { useInstagramProfile };
