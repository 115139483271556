import { useForm, type UseFormProps } from "react-hook-form";
import { useMutation } from "react-query";
import { api } from "utils/api";
import { trackEvent } from "contexts/mixpanel";
import type { AuthWalletResponse, EmailSignInFormFields } from "../types";
import { useAuth } from "../components/AuthProvider";

const postEmailSignIn = ({ email, password }: EmailSignInFormFields) => {
  trackEvent({ name: "Form Submit", FormName: "Email Sign In" });
  return api.post<AuthWalletResponse>("/auth/email", {
    body: JSON.stringify({ email, phrase: password }),
  });
};

const useEmailSignInForm = (
  formOptions?: UseFormProps<EmailSignInFormFields>,
) => {
  const { refreshAuth } = useAuth();
  const form = useForm<EmailSignInFormFields>(formOptions);

  const mutation = useMutation(postEmailSignIn, {
    onSuccess: ({ jwt, user }) => {
      refreshAuth({ jwt, user });
      trackEvent({ name: "Sign In", Method: "Email" });
    },
  });

  return {
    ...form,
    mutation,
  };
};

export { useEmailSignInForm };
