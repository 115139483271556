/**
 * Create a contract symbol string from its name
 */
const contractSymbolFromName = (name: string) =>
  name
    .split(/[\s-_]/) // Split into words by spaces, hyphens, and underscores
    .map(([firstChar]) => (firstChar ? firstChar.toUpperCase() : "")) // Map words to capitalized first characters
    .filter((c) => /[A-Z]/.test(c)) // Filter out non-letter characters
    .slice(0, 6) // Limit to six characters
    .join(""); // Concatenate into single string and return

export { contractSymbolFromName };
