import * as React from "react";
import styled from "@emotion/styled";
import { preventForwarding } from "styles/styleUtils";
import { formatURL, getSocialHandle } from "utils/text";
import {
  IconTwitter,
  IconLinkedin,
  IconInstagram,
  IconOpenSea,
  IconDiscord,
  IconTelegram,
  IconFoundation,
  IconMagicEden,
  IconMedium,
  IconReddit,
  IconTikTok,
  IconYouTube,
  IconEtherscan,
  IconAsync,
  IconGlobeAlt,
  IconObjkt,
  IconOrb,
  IconFarcaster,
} from "./icons";
import { FlexRow } from "./FlexRow";
import { Link } from "./Link";
import { Text } from "./Typography";

type SocialIconLinkProps = React.ComponentProps<"a"> & {
  artistName: string;
  className?: string;
  href?: string;
  iconOnly?: boolean;
  linkType: string;
};

function SocialIconLink({
  artistName,
  className,
  href,
  iconOnly,
  linkType,
  ...props
}: SocialIconLinkProps) {
  if (!artistName || !href) {
    return null;
  }

  const prettyUrl = formatURL(href);

  let Icon: typeof IconAsync | typeof IconGlobeAlt;
  const handle = getSocialHandle(href) ?? artistName;
  let text: string;

  switch (linkType) {
    case "async.art":
      Icon = IconAsync;
      text = `${artistName} on Async Art`;
      break;
    case "etherscan":
      Icon = IconEtherscan;
      text = `${artistName} on Etherscan`;
      break;
    case "farcaster":
      text = `${artistName} on FarCaster`;
      Icon = IconFarcaster;
      break;
    case "instagram":
      Icon = IconInstagram;
      text = `${handle} on Instagram`;
      break;
    case "lens":
      text = `${artistName} on lens`;
      Icon = IconOrb;
      break;
    case "linkedin":
      Icon = IconLinkedin;
      text = "LinkedIn";
      break;
    case "openSea":
      Icon = IconOpenSea;
      text = `${artistName} on OpenSea`;
      break;
    case "twitter":
      Icon = IconTwitter;
      text = `${handle} on Twitter`;
      break;
    case "website":
      Icon = IconGlobeAlt;
      text = prettyUrl;
      break;
    case "discord":
      Icon = IconDiscord;
      text = `${artistName} on Discord`;
      break;
    case "telegram":
      Icon = IconTelegram;
      text = `${artistName} on Telegram`;
      break;
    case "foundation":
      Icon = IconFoundation;
      text = `${handle} on Foundation`;
      break;
    case "magicEden":
      Icon = IconMagicEden;
      text = `${artistName} on Magic Eden`;
      break;
    case "medium":
      Icon = IconMedium;
      text = `${handle} on Medium`;
      break;
    case "objkt":
      Icon = IconObjkt;
      text = `${artistName} on OBJKT`;
      break;
    case "reddit":
      Icon = IconReddit;
      text = `${handle} on Reddit`;
      break;
    case "tiktok":
      Icon = IconTikTok;
      text = `${handle} on TikTok`;
      break;
    case "youtube":
      Icon = IconYouTube;
      text = `${handle} on YouTube`;
      break;
    default:
      Icon = IconGlobeAlt;
      text = prettyUrl;
      break;
  }

  return (
    <IconLink
      href={href}
      iconOnly={iconOnly}
      className={`IconLink ${className}`}
      aria-label={text}
      {...props}
    >
      <IconWrapper aria-hidden>
        <Icon accent="fg70" aria-hidden />
      </IconWrapper>
      {iconOnly ? (
        <>
          <TextBubble>{text}</TextBubble>
          <IconLinkArrow>
            <svg viewBox="0 0 12 12">
              <path d="M0 0 L6 6 L12 0 Z" fill="#ffffff" />
            </svg>
          </IconLinkArrow>
        </>
      ) : (
        <Text size="xs" id="text">
          {text}
        </Text>
      )}
    </IconLink>
  );
}

const IconLink = styled(Link, {
  shouldForwardProp: preventForwarding("iconOnly"),
})<{ iconOnly?: boolean }>(({ iconOnly }) => ({
  alignItems: "center",
  display: "inline-flex",
  flex: "0 0 auto",
  flexWrap: "nowrap",
  gap: ".33em",
  textDecoration: "none",
  maxWidth: iconOnly ? "max-content" : "100%",
  position: "relative",
  p: {
    margin: 0,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}));

IconLink.defaultProps = {
  target: "_blank",
  rel: "noreferrer",
  className: "IconLink",
};

const TextBubble = styled.span(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.dark,
  color: theme.colors.fg,
  fontSize: 14,
  fontWeight: "bold",
  left: "50%",
  lineHeight: 1,
  maxWidth: "calc(100vw - 2rem)",
  overflow: "hidden",
  opacity: 0,
  padding: ".66em 1em",
  pointerEvents: "none",
  textAlign: "center",
  textOverflow: "ellipsis",
  transition: ".2s ease opacity",
  whiteSpace: "nowrap",
  //
  position: "fixed",
  transform: "translate(-50%, -125%)",
  minWidth: 210,
  [theme.breakpoints.xs]: {
    minWidth: 0,
    bottom: "100%",
    position: "absolute",
    transform: "translate(-50%, -8px)",
  },
  svg: {
    pointerEvents: "none",
  },
  ".IconLink:hover &, .IconLink:focus-visible &": { opacity: 1 },
}));

const IconLinkArrow = styled.span({
  opacity: 0,
  height: 12,
  width: 12,
  bottom: "100%",
  left: "50%",
  position: "absolute",
  pointerEvents: "none",
  transform: "translate(-50%, -2px)",
  transition: ".2s ease opacity",
  ".IconLink:hover &, .IconLink:focus-visible &": { opacity: 1 },
});

const IconWrapper = styled.span(({ theme }) => ({
  border: `2px solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.round,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  height: 30,
  minHeight: 30,
  padding: ".25rem",
  width: 30,
  minWidth: 30,
}));

const LinkList = styled(FlexRow)({
  gap: 10,
  listStyleType: "none",
  margin: 0,
  padding: 0,
  lineHeight: 1.3,
  "li::before": {
    content: '"\\200B"',
    position: "absolute",
    height: 0,
  },
}).withComponent("ul");
LinkList.defaultProps = {
  gap: "xs",
};

export { SocialIconLink };
