import styled from "@emotion/styled";
import { paths } from "utils/paths";
import {
  Box,
  Button,
  Container,
  containerWidths,
  FlexRow,
  H2,
  Link,
  Stack,
} from "atoms";
import { ProductWizardTemplate } from "./ProductWizardTemplate";
import { ProductWizardProgressBar } from "./ProductWizardProgressBar";
import type { CreateProductState } from "../types";

type ProductWizardStepProps = {
  /** Product wizard state for ProgressBar */
  state: CreateProductState;
  /** Step title appearing above the ProgressBar */
  stepTitle: string;
  /* Sets the width of the box. Default:  */
  stepWidth?: keyof typeof containerWidths;
  /** Optional cancel button onClick handler */
  moveBack?: VoidFunction;
  /** Optional next function for preview step  */
  previewNext?: VoidFunction;
  /** Optional publish function for the preview step */
  publishProduct?: VoidFunction;
  /** optional Boolean to mark if product is unpublished. */
  isUnpublished?: boolean;
  /** Disable the cancel/back button */
  moveBackDisabled?: boolean;
  /** Disable the next/submit button */
  nextStepDisabled?: boolean;
  children: React.ReactNode;
} & (
  | {
      /** Optionally render step as a form element with next button set to type="submit" */
      isForm: true;
      /** Optional next button onClick handler or form onSubmit handler */
      nextStep: React.FormEventHandler<HTMLFormElement>;
    }
  | {
      /** Optionally render step as a form element with next button set to type="submit" */
      isForm?: false;
      /** Optional next button onClick handler or form onSubmit handler */
      nextStep?: VoidFunction;
    }
);

function ProductWizardStep({
  state,
  stepTitle,
  stepWidth,
  moveBack,
  previewNext,
  publishProduct,
  isUnpublished,
  moveBackDisabled = false,
  nextStepDisabled = false,
  children,
  ...rest
}: ProductWizardStepProps) {
  // Determine if we're working with a Form Event or Click Event handler
  let buttonProps: Partial<React.ComponentProps<typeof Button>>;
  let wrapperProps: Partial<React.ComponentProps<typeof Wrapper>>;
  if (rest.isForm) {
    buttonProps = { type: "submit" };
    wrapperProps = { onSubmit: rest.nextStep };
  } else {
    buttonProps = { onClick: rest.nextStep };
    wrapperProps = { as: "div" };
  }

  return (
    <ProductWizardTemplate
      title="Product Builder"
      artistId={state.artistId}
      stepWidth={stepWidth}
    >
      <Wrapper {...wrapperProps}>
        <Stack gap="1.5rem">
          <Stack gap="sm">
            <H2 size="md" textTransform="none" textAlign="center">
              {stepTitle}
            </H2>

            <ProductWizardProgressBar state={state} />
          </Stack>

          <WizardBox fullWidth>
            <div>{children}</div>
          </WizardBox>
        </Stack>
        <ProductWizardFooter>
          {!!state.completedSteps.length && moveBack ? (
            <Button
              size="xs"
              variant="secondary"
              onClick={moveBack}
              disabled={moveBackDisabled}
            >
              Back
            </Button>
          ) : (
            <span />
          )}

          {(rest.isForm || !!rest.nextStep) && (
            <Button size="xs" disabled={nextStepDisabled} {...buttonProps}>
              Save and Continue
            </Button>
          )}
          {(previewNext || publishProduct) && (
            <FlexRow justifyContent="flex-end" gap="1.5rem">
              {isUnpublished ? (
                <>
                  <Link
                    size="xs"
                    variant="blank"
                    to={paths.artistShopEdit(state.artistId)}
                  >
                    Save and Close
                  </Link>

                  <Button size="xs" onClick={publishProduct}>
                    Publish
                  </Button>
                </>
              ) : (
                <Button size="xs" onClick={previewNext}>
                  Finish
                </Button>
              )}
            </FlexRow>
          )}
        </ProductWizardFooter>
      </Wrapper>
    </ProductWizardTemplate>
  );
}

const Wrapper = styled.form({
  display: "flex",
  flex: 1,
  flexDirection: "column",
  justifyContent: "space-between",
  gap: "1.5rem",
});

const WizardBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.maxDesktop]: {
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.gutter,
    border: 0,
    borderRadius: 0,
    boxShadow: "none",
    marginLeft: `calc(${theme.spacing.gutter} * -1)`,
    marginRight: `calc(${theme.spacing.gutter} * -1)`,
    width: `calc(100% + (${theme.spacing.gutter} * 2))`,
  },
}));

const WizardFooter = styled.footer(({ theme }) => ({
  backgroundColor: theme.colors.accent3,
  boxShadow: theme.boxShadow.light,
  paddingBottom: "calc(env(safe-area-inset-bottom, 1rem) + 10px)",
  paddingTop: 10,
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  "&>*": { position: "relative", zIndex: 2 },
  // ".Container & .Container": {
  ".Container & > div": {
    // shame
    paddingInline: `${theme.spacing.gutter} !important`,
  },
}));

type ProductWizardFooterProps = {
  children: React.ReactNode;
};

function ProductWizardFooter({ children, ...props }: ProductWizardFooterProps) {
  return (
    <WizardFooter {...props}>
      <Container width="md">
        <FlexRow justifyContent="space-between">{children}</FlexRow>
      </Container>
    </WizardFooter>
  );
}

export { ProductWizardStep, ProductWizardFooter };
