import styled from "@emotion/styled";
import defaultAvatar from "assets/images/default-avatar.svg";

interface AvatarProps {
  src?: string;
  size?: string | number;
}

/**
 * Image component for User profiles
 *
 * NOTE: This should NOT be used for project images. Use Thumbnail or FeaturedImage which
 * have logic to handle optimized sizes and error states.
 */
const Avatar = styled.img<AvatarProps>(({ size, theme }) => ({
  borderRadius: theme.borderRadius.round,
  height: size ?? 50,
  objectFit: "cover",
  objectPosition: "center",
  width: size ?? 50,
  ...(size && {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: size,
    maxHeight: size,
    maxWidth: size,
  }),
}));

Avatar.defaultProps = {
  alt: "",
  src: defaultAvatar,
  onError: ({ currentTarget }: React.SyntheticEvent<HTMLImageElement>) => {
    // If image fails to load, fall back to default image _once_. If default image fails,
    // don't get stuck in infinite onError loop.
    if (currentTarget.src !== defaultAvatar) {
      currentTarget.src = defaultAvatar;
    }
  },
};

export { Avatar };
