import styled from "@emotion/styled";
import { Button, FlexRow, IconBell, IconDiamond, Text, Link } from "atoms";
import { formatDate, timeSinceDate } from "utils/date";
import { useMarkNotificationsAsRead } from "../api/useNotifications";
import { Notification } from "../types";

interface NotificationContentProps {
  isDismissible?: boolean;
  notification: Notification;
  dismissToast?: () => void;
}

function NotificationContent({
  notification: {
    message,
    readAt,
    createdAt,
    notificationId,
    notificationType,
    linkUrl,
  },
  isDismissible,
  dismissToast,
}: NotificationContentProps) {
  const { mutate, isLoading } = useMarkNotificationsAsRead();
  const handleMarkAsRead = () => {
    mutate([notificationId]);
    if (dismissToast) {
      dismissToast();
    }
  };

  const notificationDate = new Date(createdAt);

  return (
    <NotificationContentWrapper
      className="NotificationContentWrapper"
      isLoading={isLoading}
    >
      {notificationType === "points" ? (
        <IconDiamond accent="accent1" aria-hidden="true" size="18px" />
      ) : (
        <IconBell accent="accent3" aria-hidden="true" size="18px" />
      )}
      <SText isRead={!!readAt}>
        {linkUrl ? <Link href={linkUrl}>{message}</Link> : message}
        <FlexRow as="span">
          <small>
            {timeSinceDate(notificationDate)
              ? timeSinceDate(notificationDate)
              : formatDate(createdAt, {
                  month: "long",
                  day: "numeric",
                })}
          </small>
          {isDismissible && (
            <DismissButton
              onClick={handleMarkAsRead}
              size="xxs"
              variant="blank"
            >
              Mark as read
            </DismissButton>
          )}
        </FlexRow>
      </SText>
      {!isDismissible && <Unread isRead={!!readAt} />}
    </NotificationContentWrapper>
  );
}
const NotificationContentWrapper = styled("div")<{ isLoading: boolean }>(
  ({ isLoading, theme }) => ({
    display: "flex",
    color: theme.colors.fg,
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 10,
    opacity: isLoading ? 0.3 : 1,
  }),
);
const SText = styled(Text)<{ isRead: boolean }>(({ isRead, theme }) => ({
  "&, &>a": { color: theme.colors.fg },
  flex: 1,
  fontSize: theme.fontSizes.xxs,
  margin: 0,
  opacity: isRead ? 0.85 : 1,
  "@media (prefers-reduced-motion: no-preference)": {
    transition: ".3s opacity ease 1s",
  },
  small: {
    fontSize: theme.fontSizes.xxxs,
    display: "block",
    fontWeight: "normal",
  },
}));
const DismissButton = styled(Button)(({ theme }) => ({
  background: "rgb(0 0 0 / 0)",
  borderRadius: 0,
  color: theme.colors.fg,
  fontWeight: "normal",
  padding: 0,
  "&:hover": {
    background: "rgb(0 0 0 / 0)",
    opacity: 1,
  },
}));
const Unread = styled.span<{ isRead: boolean }>(({ isRead, theme }) => ({
  opacity: isRead ? 0 : 1,
  transition: ".3s ease all 1s",
  width: 8,
  height: 8,
  backgroundColor: theme.colors.accent3,
  borderRadius: theme.borderRadius.round,
  marginRight: 5,
}));
export { NotificationContent };
