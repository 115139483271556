import { useQuery } from "react-query";
import { api } from "utils/api";
import type { CheckoutSessionStatusResponse } from "../types";
import { checkoutKeys } from "./queryKeys";

const getCheckoutSessionStatus = (id?: string) =>
  id
    ? api.get<CheckoutSessionStatusResponse>(
        `/commerce/session-status?sessionId=${id}`,
      )
    : Promise.reject(new Error("Invalid checkout session id"));

const useCheckoutSessionStatus = (id?: string) =>
  useQuery(checkoutKeys.status(id), () => getCheckoutSessionStatus(id), {
    enabled: !!id,
  });

export { useCheckoutSessionStatus };
