const formatSize = (size: number) => {
  if (size > 800000) {
    return `${Math.floor(size / 1000000)}MB`;
  }
  if (size > 800) {
    return `${Math.floor(size / 1000)}KB`;
  }
  return `${size}B`;
};

export { formatSize };
