import * as React from "react";
import styled from "@emotion/styled";
import uniqueId from "lodash/uniqueId";
import {
  Box,
  Button,
  Draggable,
  FadeIn,
  FlexRow,
  Form,
  IconArrow,
  IconOrder,
  LoadingEllipses,
  Modal,
  Stack,
  Text,
} from "atoms";
import { useFieldArray, useForm } from "react-hook-form";
import { Thumbnail } from "features/images";
import { useGallery } from "../api/useGallery";
import { GalleryItem } from "../types";
import { useGalleryOrder } from "../api/useGalleryOrder";
import { useEditorContext } from "../utils/useEditorContext";
import { sortBySequence } from "../utils/sortBySequence";

type OrderGalleriesProps = {
  cancel: VoidFunction;
};

function OrderGalleries({ cancel }: OrderGalleriesProps) {
  const [formId] = React.useState(uniqueId());
  const { artist } = useEditorContext();
  const { data } = useGallery({ itemType: "folder", artistIds: [artist.id] });
  const { mutate, isLoading } = useGalleryOrder();

  // Sort items according to their sequence
  const items = React.useMemo(
    () =>
      !data ? [] : data.pages.flatMap((page) => page.data).sort(sortBySequence),
    [data],
  );

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <IconOrder /> Order Galleries
        </Modal.Title>
      </Modal.Header>

      {data && (
        <Stack gap="sm">
          <Text textAlign="center">
            Move your Galleries to change the order they are displayed in your
            Portfolio.
          </Text>
          <OrderForm
            artistId={artist.id}
            items={items}
            id={formId}
            mutate={mutate}
            onSuccess={cancel}
          />
        </Stack>
      )}

      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button onClick={cancel} variant="secondary">
            Cancel
          </Button>
          <Button type="submit" form={formId} disabled={!data || isLoading}>
            {isLoading ? (
              <>
                Saving
                <LoadingEllipses />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </FlexRow>
      </Modal.Footer>
    </>
  );
}

type OrderFormProps = {
  artistId: string;
  id: string;
  items: GalleryItem[];
  mutate: ReturnType<typeof useGalleryOrder>["mutate"];
  onSuccess: VoidFunction;
};

function OrderForm({ artistId, id, items, mutate, onSuccess }: OrderFormProps) {
  const { control, handleSubmit, register } = useForm<
    Pick<OrderFormProps, "items">
  >({
    defaultValues: { items },
  });
  const { fields, move } = useFieldArray({ control, name: "items" });

  const onSubmit = handleSubmit((formData) => {
    mutate(
      { artistId, galleryIds: formData.items.map((item) => item.id) },
      { onSuccess },
    );
  });

  const moveUp = (index: number) => {
    move(index, Math.max(index - 1, 0));
  };
  const moveDown = (index: number) => {
    move(index, Math.min(index + 1, fields.length - 1));
  };

  return (
    <Form id={id} onSubmit={onSubmit}>
      <Stack gap="xs">
        {fields.map((field, index) => (
          <Draggable key={field.id} index={index} handleDrop={move}>
            <SBox small>
              <FlexRow flexWrap="nowrap">
                <Gallery gap="10px" flexWrap="nowrap">
                  <SThumbnail src={field.image} sizes="150px" shape="rounded" />
                  <Title>{field.title}</Title>
                </Gallery>

                <ButtonGroup gap="10px">
                  <SButton
                    variant="default"
                    size="xs"
                    type="button"
                    aria-label="Move item up"
                    disabled={index === 0}
                    onClick={() => moveUp(index)}
                  >
                    <IconArrow dir="u" aria-hidden />
                  </SButton>
                  <SButton
                    variant="default"
                    size="xs"
                    type="button"
                    aria-label="Move item down"
                    disabled={index === fields.length - 1}
                    onClick={() => moveDown(index)}
                  >
                    <IconArrow dir="d" aria-hidden />
                  </SButton>
                </ButtonGroup>

                <input
                  {...register(`items.${index}.sequence`)}
                  value={index}
                  type="hidden"
                  readOnly
                />
              </FlexRow>
            </SBox>
          </Draggable>
        ))}
      </Stack>
    </Form>
  );
}

const SBox = styled(Box)(({ theme }) => ({
  [theme.breakpoints.maxXs]: { padding: 10 },
  cursor: "grab",
  marginInline: "auto",
  maxWidth: 600,
  transition: "background-color ease-in 0.2s",
  "&.dragging": {
    opacity: 0.8,
  },
  "&.dragging-over": {
    backgroundColor: theme.colors.accent1LL,
  },
})).withComponent(FadeIn);

const Gallery = styled(FlexRow)({ flex: "1 1 0%" });
const ButtonGroup = styled(FlexRow)({ marginLeft: "auto" });

const SThumbnail = styled(Thumbnail)({
  flex: "0 0 50px",
  pointerEvents: "none",
});

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.fg,
  margin: 0,
  display: "-webkit-box",
  lineClamp: "2",
  overflow: "hidden",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  flex: 1,
}));
Title.defaultProps = {
  bold: true,
  size: "sm",
};

const SButton = styled(Button)({
  aspectRatio: "1",
});

export { OrderGalleries };
