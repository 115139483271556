/* eslint-disable max-classes-per-file */
import { constants } from "config";

const HUG_API_KEY = "apiKey";

class ApiError extends Error {
  status: number;

  errorCode?: string;

  constructor(message: string, status: number, errorCode?: string) {
    super(message);
    this.errorCode = errorCode;
    this.status = status;
  }
}

/**
 * Path to API endpoint, must start with slash
 */
type RequestPath = `/${string}`;

interface RequestOptions extends RequestInit {
  requireAuth?: boolean;
  headers?: Record<string, string>;
}

const defaultOptions = {
  method: "GET",
  headers: {
    "content-type": "application/json",
    // Get UI's git commit hash, set by webpack, and add as cookie to send with API requests
    "hug-client-version": constants.commitHash,
  },
};

class ApiClient {
  /**
   * Base API URL to prefix request endpoints (Does not contain ending slash)
   */
  readonly baseUrl = constants.apiUrl;

  private token: string | null;

  constructor() {
    this.token = window.localStorage.getItem(HUG_API_KEY);
  }

  getToken() {
    return this.token;
  }

  /**
   * On sign in, update the client with user's token
   */
  setToken(token: string) {
    window.localStorage.setItem(HUG_API_KEY, token);
    this.token = token;
  }

  /**
   * On sign out, update the client with user's token
   */
  removeToken() {
    window.localStorage.removeItem(HUG_API_KEY);
    this.token = null;
  }

  /**
   * Fetch a given endpoint path (Must start with slash)
   */
  async request<T>(
    path: RequestPath,
    { requireAuth, ...options }: RequestOptions = {},
  ): Promise<T> {
    if (requireAuth && !this.token) {
      throw new Error("This request requires authentication");
    }
    const headers: Record<string, string> = {
      ...defaultOptions.headers,
      Authorization: this.token ? `Bearer ${this.token}` : "",
      ...options.headers,
    };

    if (headers["content-type"] === "multipart/form-data") {
      delete headers["content-type"];
    }

    const response = await window.fetch(`${this.baseUrl}${path}`, {
      ...defaultOptions,
      ...options,
      headers,
    });

    if (!response.ok) {
      let error: ApiError;
      try {
        // See if API returned known error message and code
        const { message, errorCode } = await response.json();
        error = new ApiError(message, response.status, errorCode);
      } catch {
        // API didn't return anything useful, so figure it out from HTTP status codes
        error = new ApiError("Server error", response.status);
      }

      // TODO: should we look for 401 authentication errors and remove the API token?
      // if (response.status === 401 && this.token) {
      //   this.removeToken();
      // }

      // NOTE: React-Query will catch this thrown error for use in `useQuery()` status and error
      // value. If api client is used outside useQuery/useMutation, you need to handle thrown error
      // within your own `catch` block.
      throw error;
    }

    return response.json();
  }

  async get<T>(
    path: RequestPath,
    options: Omit<RequestOptions, "method"> = {},
  ) {
    return this.request<T>(path, { method: "GET", ...options });
  }

  async put<T>(
    path: RequestPath,
    options: Omit<RequestOptions, "method"> = {},
  ) {
    return this.request<T>(path, { method: "PUT", ...options });
  }

  async post<T>(
    path: RequestPath,
    options: Omit<RequestOptions, "method"> = {},
  ) {
    return this.request<T>(path, { method: "POST", ...options });
  }
}

// Create a single instance of the ApiClient for the app to use to make requests
const api = new ApiClient();

/**
 * Flatten an object of query params into an array of key-val tuples
 *
 * @example
 * ```javascript
 * flattenParams({ foo: "a", bar: ["b", "c"]})
 * // -> [["foo", "a"], ["bar", "b"], ["bar", "c"]]
 * ```
 */
const flattenParams = (
  params: Record<string, (string | number) | (string | number)[]>,
) =>
  Object.entries(params)
    .filter(([_, val]) => val !== undefined)
    .flatMap(([key, valOrArray]) =>
      Array.isArray(valOrArray)
        ? valOrArray.map((val) => [key, val.toString()])
        : [[key, valOrArray.toString()]],
    );

/**
 * Create URLSearchParams string from shallow object containing primitive values or arrays of primitives
 *
 * @example
 * ```javascript
 * paramsToQueryStr({ id: "foo", perPage: 12, sort: ["name", "createdBy desc"] })
 * // -> "?id=foo&perPage=12&sort=name&sort=createdBy+desc"
 * paramsToQueryStr({})
 * // -> ""
 * ```
 */
const paramsToQueryStr = (
  params: Record<string, (string | number) | (string | number)[]> = {},
) => {
  const flattened = flattenParams(params);
  return flattened.length ? `?${new URLSearchParams(flattened)}` : "";
};

export { api, ApiError, paramsToQueryStr };
/* eslint-enable max-classes-per-file */
