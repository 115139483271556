import * as React from "react";
import type { Image } from "../types";
import { isTaggedNSFW } from "./nsfw";

type ImageProps = Omit<React.ComponentProps<"img">, "src"> & {
  src?: string | Image;
};

const useImgState = ({ src, ...props }: ImageProps) => {
  const [blur, setBlur] = React.useState(
    typeof src === "object" ? `url("${src.blur}")` : undefined,
  );
  const [isError, setIsError] = React.useState(false);

  const isNSFW = isTaggedNSFW(src);

  // If optimized src doesn't exist for whatever reason, fallback to original src
  const onError: React.ReactEventHandler<HTMLImageElement> = (event) => {
    // To prevent endless error loop
    if (!isError) {
      setIsError(true);
    }
    // Call parent onError if exists
    if (props.onError) {
      props.onError(event);
    }
  };

  // To prevent blur showing through transparent images, hide it after src loads
  const onLoad: React.ReactEventHandler<HTMLImageElement> = (event) => {
    setBlur(undefined);
    // Call parent onLoad if exists
    if (props.onLoad) {
      props.onLoad(event);
    }
  };

  return { blur, isError, isNSFW, onError, onLoad };
};

export { useImgState };
