import * as React from "react";
import styled from "@emotion/styled";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Button,
  FlexRow,
  Link,
  Stack,
  Sup,
  Text,
  UnexpectedError,
} from "atoms";
import { constants } from "config";
import { GenericImage } from "features/images";
import { useArtist } from "features/reviewable";
import { useToggle } from "utils/hooks";
import { paths } from "utils/paths";
import {
  useUpdateProductVariants,
  type UpdateProductVariantsReq,
  type VariantField,
} from "../../api/useUpdateProductVariants";
import type { StepProps, TemplateVariant, VariantAdminNode } from "../../types";
import { ArtPrintPreviewPaper } from "../ArtPrintPreview";
import { ProductWizardStep } from "../ProductWizardStep";
import { PricingInfo, VariantRow } from "./StepPricing";

/** String for RegExp constructor that matches any framed variant color */
const FRAME_REGEX = "FRAME-(BLACK|WHITE|NATURAL)$";

/** Sort variants with unframed first, framed last */
const sortVariants = (a: VariantField, b: VariantField) => {
  const aIsFramed = !a.variantId?.endsWith("FRAME-NONE");
  const bIsFramed = !b.variantId?.endsWith("FRAME-NONE");
  if (!aIsFramed && bIsFramed) {
    return -1;
  }
  if (aIsFramed && !bIsFramed) {
    return 1;
  }
  return 0;
};

/** Use a templateVariantId to extract it's frame and size */
const extractSizeFromId = (templateVariantId = "") => {
  // Use the variant's templateVariantId to see if it's framed or not
  const isFramed = new RegExp(FRAME_REGEX).test(templateVariantId);
  // Then extract the dimensions from it, something like "8X12"
  const size = /\d+X\d+/.exec(templateVariantId)?.[0];
  // Create a new regex that matches any other framed variant of the same size, regardless of color
  const sizeMatcher = new RegExp(`${size}-${FRAME_REGEX}`);
  // Create a generic title for this variant
  const genericTitle = `${isFramed ? "Framed" : "Unframed"} ${size?.toLowerCase()}`;

  return { isFramed, size, sizeMatcher, genericTitle };
};

/** Reduce all variants down to select fields that affect price */
const createVariantReducer =
  (variantMap: Record<string, TemplateVariant>) =>
  (acc: VariantField[], cur: VariantAdminNode) => {
    // Extract values from this variant's templateVariantId
    const { isFramed, size, sizeMatcher, genericTitle } = extractSizeFromId(
      cur.templateVariantId,
    );

    if (isFramed && acc.find((v) => sizeMatcher.test(v.variantId ?? ""))) {
      // We already have a framed variant with that size in another color, no need to add it
      return acc;
    }

    // Using the dimensions in the size, find it's aspect ratio
    const [printWidth, printHeight] = (size ?? "1X1").split("X").map(Number);
    const aspectRatio = printWidth / printHeight;

    // This is either an unframed variant or a framed variant in a new size, so convert the variant
    // object to the shape needed for form fields and update hook and append to accumulator
    return [
      ...acc,
      {
        ...variantMap[cur.templateVariantId ?? ""],
        id: cur.id,
        image: cur.image,
        variantId: cur.templateVariantId,
        priceInCents: Number(cur.price) * 100,
        title: genericTitle,
        isFramed,
        aspectRatio,
      },
    ];
  };

/** Expand selected fields that affect price out to all variants */
const expandVariants = (
  pricingFields: VariantField[],
  productVariants: VariantAdminNode[],
): VariantField[] => {
  const genericVariantByTitle = pricingFields.reduce<
    Record<string, VariantField>
  >((acc, cur) => {
    acc[cur.title] = cur;
    return acc;
  }, {});

  return productVariants.map(({ templateVariantId, ...variantAdminNode }) => {
    // Use the variant's templateVariantId to get it's generic title
    const { genericTitle } = extractSizeFromId(templateVariantId);

    return {
      ...variantAdminNode,
      ...genericVariantByTitle[genericTitle],
      variantId: templateVariantId,
    };
  });
};

function StepArtPrintPricing({ state, dispatch, moveBack }: StepProps) {
  const { isLoading, isError, error, mutate } = useUpdateProductVariants(
    state.product.id,
  );

  const { data: artist } = useArtist(state.artistId);

  const hugFee = artist?.shopHugFee ?? constants.defaultShopFee;
  // Gets readable, whole-number percentage of the artist's HUG fee
  const hugFeePretty = hugFee * 100;

  const [isInfoShowing, toggleIsInfoShowing] = useToggle(false);

  // Use the product's template data to create a map of each variant by variant id which we'll use
  // when rendering each row of the pricing table to display minimum and recommended prices.
  const variantMap = React.useMemo(
    () =>
      state.productTemplate.variants.reduce<Record<string, TemplateVariant>>(
        (acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        },
        {},
      ),
    [state.productTemplate],
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<UpdateProductVariantsReq>({
    defaultValues: {
      variants: state.product.variants
        .reduce<VariantField[]>(createVariantReducer(variantMap), [])
        .sort(sortVariants),
    },
  });

  const { fields } = useFieldArray({ control, name: "variants" });

  const moveNext = () => dispatch({ type: "moveNext" });
  const onSubmit = handleSubmit((formData) => {
    if (isDirty) {
      mutate(
        {
          ...formData,
          variants: expandVariants(formData.variants, state.product.variants),
        },
        {
          onSuccess: moveNext,
        },
      );
    } else {
      moveNext();
    }
  });

  // Create a fallback image using the uploaded image and the first frame option we can find in
  // case the mockups generated by Prodigi haven't generated yet.
  const previewImage =
    state.product.imageData?.image?.url300 ??
    state.product.imageData?.image?.url ??
    state.product.assetUrl;
  const frameColor = state.product.options
    .find((opt) => opt.name === "Frame Color")
    ?.values?.find((c) => c !== "No Frame");

  return (
    <ProductWizardStep
      stepTitle="Pricing"
      state={state}
      moveBack={moveBack}
      moveBackDisabled={isDirty}
      nextStep={onSubmit}
      nextStepDisabled={isLoading}
      isForm
    >
      <Stack gap="md">
        <FlexRow
          justifyContent="space-between"
          alignItems="flex-start"
          itemsFlex="1"
        >
          <Stack>
            <Text size="xs" bold>
              As you set your pricing, the amount you make for the sale of each
              product will update automatically.
            </Text>
            <Text size="xs">
              To learn more about how pricing calculations are done, click the
              &ldquo;About Pricing&rdquo; button.
            </Text>
          </Stack>
          <Button
            variant="secondary"
            size="xs"
            style={{ flex: "0 0 auto" }}
            onClick={toggleIsInfoShowing}
            aria-label="toggle pricing information"
            aria-controls="#about-pricing"
          >
            About Pricing
          </Button>
        </FlexRow>

        {isInfoShowing && (
          <PricingInfo
            isPrintOnDemand
            hugFee={hugFeePretty}
            close={toggleIsInfoShowing}
          />
        )}

        <div>
          {fields.map(({ aspectRatio, isFramed, ...field }, index) => (
            <VariantRow
              key={field.id}
              {...field}
              index={index}
              image={
                <PricingArtPrintPreviewPaper
                  aspectRatio={aspectRatio}
                  frameColor={frameColor}
                  isFramed={isFramed}
                >
                  <GenericImage src={previewImage} />
                </PricingArtPrintPreviewPaper>
              }
              error={errors.variants?.[index]?.priceInCents?.message}
              control={control}
              hugFee={hugFee}
            />
          ))}
        </div>

        {isError && <UnexpectedError error={error} />}

        <Text size="xxs">
          <Sup>*</Sup>Not inclusive of third-party processing fees. See{" "}
          <Link to={paths.shopTerms} target="_blank">
            Seller Terms of Service
          </Link>{" "}
          for details.
        </Text>
      </Stack>
    </ProductWizardStep>
  );
}

StepArtPrintPricing.displayName = "Pricing";

const PricingArtPrintPreviewPaper = styled(ArtPrintPreviewPaper)<{
  isFramed?: boolean;
  frameColor?: string;
}>(({ isFramed, frameColor }) =>
  isFramed && frameColor
    ? {
        "&>div": {
          border: "6px solid",

          ...(frameColor === "Black" && {
            borderColor: "#262626",
          }),

          ...(frameColor === "White" && {
            borderColor: "#efefef",
          }),

          ...(frameColor === "Natural" && {
            borderColor: "#D9CEB7",
          }),
        },
      }
    : {},
);

export { StepArtPrintPricing };
