/**
 * Categorized filters available for searching products by type tag
 */
const productFilters = {
  art: [
    { label: "Art Prints", value: "Print" },
    { label: "Paintings", value: "Painting" },
    { label: "Other Art", value: "Other art" },
  ],
  apparel: [
    { label: "T-shirts", value: "T-shirt" },
    { label: "Hoodies", value: "Hoodie" },
    { label: "Hats", value: "Hat" },
    { label: "Other Apparel", value: "Other apparel" },
  ],
  homeGoods: [
    { label: "Cushions", value: "Cushion" },
    { label: "Mugs", value: "Mug" },
    { label: "Puzzles", value: "Puzzle" },
    { label: "Other Home Goods", value: "Other home good" },
  ],
  stationery: [
    { label: "Journals", value: "Journal" },
    { label: "Notebooks", value: "Notebook" },
    { label: "Stickers", value: "Sticker" },
    { label: "Greeting Cards", value: "Greeting card" },
    { label: "Other Stationery", value: "Other stationery" },
  ],
  accessories: [
    { label: "Phone Cases", value: "Phone case" },
    { label: "Scarves", value: "Scarf" },
    { label: "Jewelry", value: "Jewelry" },
    { label: "Keychains", value: "Keychain" },
    { label: "Pins", value: "Pin" },
    { label: "Bags", value: "Bag" },
    { label: "Other Accessories", value: "Other accessory" },
  ],
  onchainCollectible: [
    { label: "1/1", value: "1/1" },
    { label: "Limited Edition", value: "Limited edition" },
    { label: "Open Edition", value: "Open edition" },
  ],
  downloads: [{ label: "Digital Downloads", value: "Digital download" }],
  education: [{ label: "Education", value: "Education" }],
  // price: ["0-25", "25-50", "50-100", "100-250", "250 and up"],
};

export { productFilters };
