import { SelectedOption } from "@shopify/hydrogen-react/storefront-api-types";
import { type ShopOrdersSearchRequest } from "../types/shopOrderSearchRequest";

const shopOrderKeys = {
  base: ["shopOrder"] as const,
  lists: () => [...shopOrderKeys.base, "list"] as const,
  list: (params: ShopOrdersSearchRequest) =>
    [...shopOrderKeys.lists(), params] as const,
  details: () => [...shopOrderKeys.base, "detail"] as const,
  detail: (id?: string) => [...shopOrderKeys.details(), id] as const,
};

const shopDataQueryKey = ["shopData"];

const productKeys = {
  base: ["product"] as const,
  details: () => [...productKeys.base, "detail"] as const,
  detail: (handle?: string | null, options?: SelectedOption[]) =>
    [...productKeys.details(), handle, options] as const,
};

const productAdminKeys = {
  base: ["product-admin"] as const,
  details: () => [...productAdminKeys.base, "detail"] as const,
  detail: (id?: string | null) => [...productAdminKeys.details(), id] as const,
  lists: () => [...productAdminKeys.base, "list"] as const,
  list: (params: { artistId?: string; status?: string }) =>
    [...productAdminKeys.lists(), params] as const,
};

const artistOnChainContractKeys = {
  base: ["artist-on-chain-contracts"] as const,
  details: () => [...artistOnChainContractKeys.base, "detail"] as const,
  detail: (artistId?: string | null) =>
    [...artistOnChainContractKeys.details(), artistId] as const,
};

const productMediaAdminKeys = {
  base: ["product-media"] as const,
  details: () => [...productMediaAdminKeys.base, "detail"] as const,
  detail: (id?: string) => [...productMediaAdminKeys.details(), id] as const,
};

const productCreatedByArtistKeys = {
  base: ["artistProductCount"] as const,
  details: () => [...productCreatedByArtistKeys.base, "detail"] as const,
  detail: (id?: string) =>
    [...productCreatedByArtistKeys.details(), id] as const,
};

export {
  shopOrderKeys,
  shopDataQueryKey,
  productKeys,
  productAdminKeys,
  artistOnChainContractKeys,
  productMediaAdminKeys,
  productCreatedByArtistKeys,
};
