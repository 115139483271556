import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import {
  AllCaps,
  Button,
  Checkbox,
  FakeLabel,
  FieldError,
  FlexRow,
  Form,
  Label,
  Link,
  LoadingEllipses,
  RequiredText,
  Select,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import { countries, sanctionedCountries } from "utils/countries";
import { fieldOptions } from "utils/form";
import { paths } from "utils/paths";
import { useUpdateArtist } from "../api/useArtistForm";
import type { Artist, ArtistFormFields } from "../types";

const validateCountry = (value?: string) =>
  !value ||
  !sanctionedCountries.includes(value) ||
  "The selected country is currently subject to United States sanctions and trade restrictions. As a US-based company, HUG must comply with current legal regulations, and is not able to conduct business with individuals residing in sanctioned countries.";

type ShopTermsForm = ArtistFormFields & {
  agreeToCountryOfResidence?: boolean;
  agreeToTerms?: boolean;
  agreeToArtworkRights?: boolean;
};

type ShopTermsFormProps = {
  artist: Artist;
};

function ArtistShopTermsForm({ artist }: ShopTermsFormProps) {
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<ShopTermsForm>();

  const { mutate, isLoading, isError, error } = useUpdateArtist();

  // Create form submit handler
  const onSubmit = handleSubmit(({ country }) =>
    mutate({
      ...artist,
      country,
      shopTermsAcceptedAt: new Date().toISOString(),
    }),
  );

  return (
    <Form onSubmit={onSubmit} style={{ maxWidth: 420, marginInline: "auto" }}>
      <Label>
        Country of Residence
        <RequiredText />
        <Select
          {...register("country", {
            ...fieldOptions.required,
            validate: validateCountry,
          })}
          isInvalid={!!errors.country}
          aria-invalid={!!errors.country}
        >
          <option value="">Choose country</option>
          {countries.map((country) => (
            <option key={country} value={country}>
              {country}
            </option>
          ))}
        </Select>
        {errors.country && <FieldError>{errors.country.message}</FieldError>}
      </Label>

      <Text size="xs" as="div" style={{ textWrap: "pretty" }}>
        <Stack>
          <FakeLabel as="legend">
            Agree to Terms
            <RequiredText />
          </FakeLabel>

          <Stack gap="sm">
            <div>
              <SCheckbox
                label={
                  <div>
                    <div>
                      I certify that I do not reside in nor am I doing business
                      from a country that is currently subject to United States
                      sanctions and trade restrictions, namely Cuba, Iran, North
                      Korea, Russia, Sudan, and Syria.
                    </div>
                    {errors.agreeToCountryOfResidence && (
                      <FieldError>
                        {errors.agreeToCountryOfResidence?.message}
                      </FieldError>
                    )}
                  </div>
                }
                {...register(
                  "agreeToCountryOfResidence",
                  fieldOptions.required,
                )}
              />
            </div>

            <div>
              <SCheckbox
                label={
                  <div>
                    <div>
                      I have read and agreed to <AllCaps>HUG</AllCaps>
                      &apos;s{" "}
                      <Link to={paths.shopTerms} target="_blank">
                        Seller Terms of Service
                      </Link>
                      .
                    </div>
                    {errors.agreeToTerms && (
                      <FieldError>{errors.agreeToTerms?.message}</FieldError>
                    )}
                  </div>
                }
                {...register("agreeToTerms", fieldOptions.required)}
              />
            </div>
          </Stack>
        </Stack>
      </Text>

      <FlexRow justifyContent="center">
        <Button type="submit" disabled={isLoading}>
          <span>Start Selling</span>
          {isLoading && <LoadingEllipses />}
        </Button>
      </FlexRow>

      {isError && <UnexpectedError error={error} />}
    </Form>
  );
}

const SCheckbox = styled(Checkbox)(({ theme }) => ({
  "&:has(.is-error)": {
    color: theme.colors.accent4,
  },
}));

export { ArtistShopTermsForm };
