import styled from "@emotion/styled";
import type { CSSObject } from "@emotion/styled";

interface FlexRowProps {
  alignItems?: CSSObject["alignItems"];
  flexWrap?: CSSObject["flexWrap"];
  flexDirection?: CSSObject["flexDirection"];
  justifyContent?: CSSObject["justifyContent"];
  itemsFlex?: CSSObject["flex"];
  gap?: CSSObject["gap"] | "site";
}

const FlexRow = styled.div<FlexRowProps>(
  ({
    alignItems = "center",
    flexWrap = "wrap",
    flexDirection = "row",
    justifyContent = "normal",
    itemsFlex = "0 0 auto",
    gap = "1rem",
    theme,
  }) => ({
    alignItems,
    display: "flex",
    flexDirection,
    flexWrap,
    gap: gap === "site" ? theme.spacing.gap : gap,
    justifyContent,
    "&>*": {
      flex: itemsFlex,
      maxWidth: "100%",
    },
  }),
);

export { FlexRow };
