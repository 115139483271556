import styled from "@emotion/styled";
import {
  AppError,
  Button,
  FlexRow,
  Grid,
  List,
  LoadingSpinner,
  UnexpectedError,
} from "atoms";
import { Head } from "features/misc";
import { useArtist } from "features/reviewable";
import socialImage from "assets/images/HUG-artist-shop.png";
import { ProductCard } from "./ProductCard";
import { useCollectionInfiniteQuery } from "../api/useCollectionQuery";

type CollectionDetailsProps = {
  handle: string;
  availableOnly?: boolean;
};

function CollectionDetails({
  handle,
  availableOnly = true,
}: CollectionDetailsProps) {
  const filters = availableOnly ? [{ available: true }] : [];
  const {
    data,
    error,
    isError,
    isSuccess,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
  } = useCollectionInfiniteQuery({ handle, filters, numProducts: 48 });
  const { data: artist } = useArtist(handle);

  const loadMore = () => fetchNextPage();

  if (data) {
    return (
      <>
        <Head
          title={`Shop ${artist?.name} on HUG`}
          description={`Discover and shop a variety of digital and physical creations from ${artist?.name}.`}
          socialTitle={`Shop ${artist?.name} on HUG`}
          socialDescription={`Discover and shop a variety of digital and physical creations from ${artist?.name}.`}
          socialImageAltText={`Shop creations by ${artist?.name}.`}
          socialImage={
            artist?.cardImage?.url ?? artist?.heroImage.url ?? socialImage
          }
        />

        <ProductList type="blank">
          {data.products.map((product) => (
            <ProductCard key={product.id} product={product} showArtistName />
          ))}
        </ProductList>

        {hasNextPage && (
          <FlexRow justifyContent="center" style={{ marginTop: "2rem" }}>
            <Button onClick={loadMore} disabled={isFetchingNextPage}>
              Load More
            </Button>
          </FlexRow>
        )}
      </>
    );
  }

  if (isError) {
    return <UnexpectedError error={error} />;
  }

  if (isSuccess && !data) {
    return (
      <AppError>Sorry, we had some trouble finding this collection.</AppError>
    );
  }

  return <LoadingSpinner />;
}

const minSize = "calc(150px + 7vw)";
const ProductList = styled(Grid)(({ theme }) => ({
  [theme.breakpoints.xs]: {
    gridTemplateColumns: `repeat(auto-fill, minmax(min(${minSize}, 50% - 1rem), 1fr))`,
  },
  "@media (min-width: 1040px)": {
    gridTemplateColumns: `repeat(4, 1fr)`,
  },
})).withComponent(List);

export { CollectionDetails };
