import { OwnedContractTokenRequest } from "../types";

const ownedContractKeys = {
  base: ["ownedContractKeys"],
  details: () => [...ownedContractKeys.base, "detail"] as const,
  detail: (ownerId?: string) =>
    [...ownedContractKeys.details(), ownerId] as const,
};

const ownedTokenKeys = {
  base: ["ownedTokenKeys"],
  lists: () => [...ownedTokenKeys.base, "list"] as const,
  infinite: (params: OwnedContractTokenRequest) =>
    [...ownedTokenKeys.lists(), "infinite", params] as const,
};

export { ownedContractKeys, ownedTokenKeys };
