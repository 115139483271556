type LinkedTokenIdsParams = {
  tokenIds: number[] | undefined;
};

function LinkedTokenIds({ tokenIds }: LinkedTokenIdsParams) {
  if (!tokenIds || tokenIds.length === 0) {
    return <span>None</span>;
  }
  const sorted = (tokenIds || []).sort((a, b) => a - b);
  return (
    <span>
      {sorted.map((tokenId: number) => (
        <span key={tokenId}>
          <a
            target="_blank"
            href={`https://opensea.io/assets/ethereum/0xfa2dbc4eb68ca6e20be05c8a3d0a6ebeaedc169d/${tokenId}`}
            rel="noreferrer"
          >
            {tokenId}
          </a>{" "}
        </span>
      ))}
    </span>
  );
}

export { LinkedTokenIds };
