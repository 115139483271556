import { parseGid } from "@shopify/hydrogen-react";

/**
 * Prefix Shopify Global ID with protocol if it doesn't already include it
 *
 * NOTE: This is the opposite of `parseGid` from @shopify/hydrogen-react
 *
 * ex: "12345" -> "gid://shopify/Product/12345"
 * ex: "gid://shopify/Product/12345" -> no change
 */
const prefixGid = (id: string, entity = "Product") =>
  id.startsWith("gid://") ? id : `gid://shopify/${entity}/${id}`;

export { parseGid, prefixGid };
