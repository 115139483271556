import * as React from "react";
import styled from "@emotion/styled";
import uniqueId from "lodash/uniqueId";
import type { MutateOptions } from "react-query";
import {
  AppError,
  Button,
  FakeLabel,
  FieldError,
  FlexRow,
  Form,
  IconTrashCan,
  Input,
  Label,
  LoadingEllipses,
  Modal,
  RequiredText,
  RichTextEditor,
  Stack,
  Text,
} from "atoms";
import type { Image } from "features/images";
import { fieldOptions, validators } from "utils/form";
import { useToggle } from "utils/hooks";
import type { GalleryItem, GalleryItemFormFields } from "../types";
import { useGalleryItemForm } from "../api/useGalleryItemForm";
import { GalleryThumbnail } from "./GalleryCard";
import { ThumbnailSelector } from "./ThumbnailSelector";

// Character Counter for Description Field
const descriptionMaxLength = 2000;

type GalleryFormProps = {
  artistId: string;
  cancel: VoidFunction;
  gallery?: GalleryItem;
  deleteGallery?: VoidFunction;
} & MutateOptions<GalleryItem, unknown, GalleryItemFormFields>;

function GalleryForm({
  artistId,
  cancel,
  deleteGallery,
  gallery,
  onError,
  onSettled,
  onSuccess,
}: GalleryFormProps) {
  const [formId] = React.useState(uniqueId());

  const isEditing = !!gallery;
  const submitLabel: string = isEditing ? "Save" : "Create Gallery";
  const [isThumbnailSelect, toggleThumbnailSelect] = useToggle(false);

  const {
    control,
    formState: { errors },
    mutation: { isLoading, isError, error },
    onSubmit,
    register,
    setValue,
    watch,
  } = useGalleryItemForm({
    artistId,
    galleryItem: gallery,
    defaultValues: { itemType: "folder" },
    onError,
    onSettled,
    onSuccess,
  });

  const currentImage = watch("image");

  if (isEditing && isThumbnailSelect) {
    const setThumbnail = (img: Image) => {
      toggleThumbnailSelect();
      setValue("image", img, { shouldTouch: true, shouldDirty: true });
    };

    return (
      <ThumbnailSelector
        cancel={toggleThumbnailSelect}
        currentImage={currentImage}
        gallery={gallery}
        setThumbnail={setThumbnail}
      />
    );
  }

  return (
    <Form id={formId} onSubmit={onSubmit}>
      {isEditing && (
        <CoverImage>
          <FakeLabel>
            Cover Image <RequiredText />
          </FakeLabel>

          <FlexRow>
            <Text>Image Preview</Text>
            <Button onClick={toggleThumbnailSelect} size="xs" variant="blank">
              Replace Image
            </Button>
          </FlexRow>

          <GalleryThumbnail src={currentImage} />
        </CoverImage>
      )}

      <Label>
        Gallery Name
        <RequiredText />
        <Input {...register("title", fieldOptions.required)} />
        {errors.title && <FieldError>{errors.title.message}</FieldError>}
      </Label>

      <Label>
        Description
        <RichTextEditor
          control={control}
          name="description"
          maxLength={descriptionMaxLength}
          showCharacterCounter
        />
        {errors.description && (
          <FieldError>{errors.description?.message}</FieldError>
        )}
      </Label>

      <Label>
        Link to Learn More
        <Input {...register("link", { validate: validators.validURL })} />
        {errors.title && <FieldError>{errors.title.message}</FieldError>}
      </Label>

      {deleteGallery && (
        <div>
          <Button onClick={deleteGallery} variant="blank">
            <IconTrashCan /> Delete Gallery
          </Button>
        </div>
      )}

      <Modal.Footer>
        <FlexRow justifyContent="center">
          <FlexRow justifyContent="center">
            <Button variant="secondary" onClick={cancel}>
              Cancel
            </Button>

            <Button type="submit" form={formId} disabled={isLoading}>
              {isLoading ? (
                <>
                  Saving
                  <LoadingEllipses />
                </>
              ) : (
                submitLabel
              )}
            </Button>
          </FlexRow>
        </FlexRow>

        {isError && (
          <AppError>
            {error instanceof Error ? error.message : "Something went wrong"}
          </AppError>
        )}
      </Modal.Footer>
    </Form>
  );
}

const CoverImage = styled(Stack)(({ theme }) => ({
  gap: "10px",
  p: {
    color: theme.colors.gray60,
    margin: 0,
  },
  "&>div:last-of-type": {
    maxWidth: 300,
    width: "100%",
  },
}));

export { GalleryForm };
