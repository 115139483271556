import * as React from "react";
import styled from "@emotion/styled";
import { Button, FlexRow, H3, IconSearch, Input, Stack } from "atoms";
import { useDebouncer } from "utils/hooks";
import { useGallery } from "../api/useGallery";
import { SelectImages } from "./SelectImages";
import type { GalleryItem } from "../types";

type SelectFromPortfolioProps = Pick<
  React.ComponentProps<typeof SelectImages>,
  "multiple" | "showTitle"
> & {
  /** Load portfolio items from this artist */
  artistId: string;
  /** Change event handler to update the selected GalleryItems */
  onChange: (items: GalleryItem[]) => void;
  /** Option filter function to exclude certain items from being selected */
  filter?: (item: GalleryItem) => boolean;
};

function SelectFromPortfolio({
  artistId,
  filter = () => true,
  multiple,
  onChange,
  showTitle,
}: SelectFromPortfolioProps) {
  // Set up state for search input's value and debounce it for the query hook
  const [searchVal, setSearchVal] = React.useState("");
  const searchText = useDebouncer(searchVal, 300);
  const onSearchChange: React.ChangeEventHandler<HTMLInputElement> = (event) =>
    setSearchVal(event.target.value);

  // Data for the image selection
  const { data, hasNextPage, fetchNextPage } = useGallery(
    {
      artistIds: [artistId],
      itemType: "item",
      ...(searchText ? { searchText } : undefined),
    },
    { keepPreviousData: true },
  );

  const items = React.useMemo(
    () => data?.pages.flatMap((page) => page.data).filter(filter) ?? [],
    [data, filter],
  );

  const loadMore = () => fetchNextPage();

  return (
    <Stack gap="sm" style={{ marginBlock: 20 }}>
      <H3>Select Images</H3>
      <InputWrapper>
        <IconSearch aria-hidden />
        <FlexRow gap="10px" flexWrap="nowrap">
          <SearchInput
            value={searchVal}
            onChange={onSearchChange}
            placeholder="Search for an item in your Portfolio"
          />
          <Button size="xxs" variant="text" onClick={() => setSearchVal("")}>
            Reset Search
          </Button>
        </FlexRow>
      </InputWrapper>

      <SelectImages
        items={items}
        onChange={onChange}
        multiple={multiple}
        showTitle={showTitle}
      />

      {hasNextPage && (
        <FlexRow justifyContent="center">
          <Button variant="secondary" size="xs" onClick={loadMore}>
            Load More
          </Button>
        </FlexRow>
      )}
    </Stack>
  );
}

const InputWrapper = styled.div({
  position: "relative",
  "&>svg": {
    position: "absolute",
    left: ".75rem",
    top: "50%",
    transform: "translateY(-50%)",
    zIndex: 10,
  },
});

const SearchInput = styled(Input)(({ theme }) => ({
  borderRadius: theme.borderRadius.round,
  border: "2px solid",
  borderColor: theme.colors.fg,
  maxWidth: 320,
  flex: "0 1 320px",
  paddingLeft: "2.125rem",
}));

export { SelectFromPortfolio };
