const formatCentsToDollars = (
  cents: number,
  currency = "USD",
  locale = "en-US",
) => {
  const getDollars = new Intl.NumberFormat(locale, {
    currency,
    currencyDisplay: "narrowSymbol",
    style: "currency",
  });

  return getDollars.format(cents / 100);
};

export { formatCentsToDollars };
