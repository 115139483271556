const profileKeys = {
  /**
   * All profiles and associated reward balances
   */
  base: ["profiles"] as const,
  details: () => [...profileKeys.base, "detail"] as const,
  /**
   * A single profile, either the current user or viewed using a given id
   */
  detail: (id?: string) => [...profileKeys.details(), id] as const,
  /**
   * A single user's reward balance, either the current user or viewed using a given id
   */
  balance: (id?: string) => [...profileKeys.detail(id), "balance"] as const,
  /**
   * Current user's curation opportunities
   */
  curation: ["profileCuration"] as const,
};

export { profileKeys };
