import { useQuery } from "react-query";
import { api } from "utils/api";
import type { GalleryItem } from "../types";
import { galleryKeys } from "./queryKeys";

type GetParams = {
  artistId?: string;
  itemId?: string;
};

/**
 * Get a portfolio items for an artist
 */
const getArtistGalleryItem = ({ itemId }: GetParams) =>
  api.get<GalleryItem>(`/gallery/artists/${itemId}`);

/**
 * Custom hook to get a portfolio item for an artist
 */
const useGalleryItem = ({ artistId, itemId }: GetParams) =>
  useQuery(
    galleryKeys.detail("artist", artistId, itemId),
    () => getArtistGalleryItem({ itemId }),
    {
      enabled: !!artistId && !!itemId,
    },
  );

export { useGalleryItem };
