import * as React from "react";
import styled from "@emotion/styled";
import { Text, TextProps } from "./Typography";

type ParagraphProps = {
  text?: string;
} & TextProps &
  React.HTMLAttributes<HTMLDivElement>;

/* eslint-disable react/no-array-index-key */
function Paragraphs({ className, text, ...props }: ParagraphProps) {
  return !text ? null : (
    <Content className={className}>
      {text.split(/\r?\n/).map((str, index) =>
        str ? (
          <Text {...props} key={index}>
            {str}
          </Text>
        ) : null,
      )}
    </Content>
  );
}
/* eslint-enable react/no-array-index-key */

const Content = styled.div({
  "p:last-of-type": {
    marginBottom: 0,
  },
});

export { Paragraphs };
