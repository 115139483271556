import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { profileKeys } from "features/profile";
import { api } from "utils/api";
import { applicationKeys } from "./queryKeys";
import type {
  ArtistApplicationVoteFields,
  ArtistApplicationVoteResponse,
} from "../types";

const updateApplicationVote = (formFields: ArtistApplicationVoteFields) =>
  api.put<ArtistApplicationVoteResponse>("/artists/applications/react", {
    body: JSON.stringify(formFields),
  });

const useUpdateApplicationVote = () => {
  const queryClient = useQueryClient();

  return useMutation(updateApplicationVote, {
    onSuccess: ({ application }) => {
      queryClient.setQueryData(
        applicationKeys.detail(application.id),
        application,
      );
      queryClient.invalidateQueries(profileKeys.curation);
    },
  });
};

const useArtistApplicationVoteForm = () => {
  const form = useForm<ArtistApplicationVoteFields>();

  const mutation = useUpdateApplicationVote();

  return {
    ...form,
    mutation,
  };
};

export { useArtistApplicationVoteForm };
