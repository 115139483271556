/**
 * Format a URL for display by removing protocol and truncating length
 *
 * Example:
 * formatURL("https://www.somedomain.com/collections/some-really-long-path")
 * // "somedomain.com/collections/some-really-lon..."
 */
const formatURL = (url: string, maxLength = 45) =>
  (url.length <= maxLength ? url : `${url.slice(0, maxLength - 3)}...`)
    .replace(/(https?:\/\/)?(www\.)?/, "")
    .replace(/\/+$/, "");

/**
 * Extract username from social url
 */
const getSocialHandle = (url: string) => {
  if (!url) {
    return null;
  }
  const urlObj = new URL(url);
  const path = urlObj.pathname
    .replace("/", "")
    // Removing "/user" is to target Reddit usernames
    .replace("/user/", "/")
    .replace("@", "");
  const handle = path.split("/")[0];
  return `@${handle}`;
};

/**
 * Convert a string to a URL safe slug
 */
const slugify = (str: string) =>
  str
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");

/**
 * Convert a slug to a readable string
 */
const unslugify = (str: string) => str.replaceAll("-", " ");

/**
 * Truncate a filename down to requested length while preserving extension
 *
 * Example:
 * truncate("superlongfilename.jpeg", 14);
 * // "sup...ame.jpeg"  (14 characters)
 */
const formatFilename = (filename: string, maxLength = 25) => {
  if (filename.length <= maxLength) {
    return filename;
  }
  const parts = filename.split(".");
  const ext = parts.length > 1 ? `.${parts.pop()}` : "";
  const name = parts.join("");
  const remaining = maxLength - ext.length;
  if (remaining < 4) {
    return `${name.slice(0, 3)}..${ext}`;
  }
  const isEven = remaining % 2 === 0;
  const index = Math.floor((remaining - (isEven ? 2 : 3)) / 2);
  return `${name.slice(0, index)}${isEven ? ".." : "..."}${name.slice(
    name.length - index,
  )}${ext}`;
};

/**
 * Remove filename extension
 */
const removeFileExt = (filename: string) =>
  filename.slice(0, filename.lastIndexOf("."));

const emailRegex =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const validateEmail = (email: string) => email.match(emailRegex);

/**
 * Given a count, return count and label with proper pluralization, suffix defaults to "s"
 */
const pluralize = (
  count = 0,
  singular = "Item",
  plural: string | null = null,
  prefix = true,
) => {
  const pluralLabel = plural ?? `${singular}s`;
  const label = count === 1 ? singular : pluralLabel;
  return prefix ? `${count} ${label}` : label;
};

export {
  formatFilename,
  formatURL,
  getSocialHandle,
  pluralize,
  slugify,
  unslugify,
  removeFileExt,
  validateEmail,
};
