import * as React from "react";
import styled from "@emotion/styled";
import { parseGid } from "@shopify/hydrogen-react";
import { useNavigate } from "react-router-dom";
import {
  AllCaps,
  Box,
  Button,
  Checkbox,
  Container,
  FieldError,
  FlexRow,
  H2,
  IconCloudDownload,
  Link,
  LoadingMessage,
  Modal,
  RequiredText,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import {
  acceptTypes,
  FileInput,
  formatSize,
  GenericImage,
  parseS3URL,
  usePrintUpload,
} from "features/images";
import { type GalleryItem, SelectFromPortfolio } from "features/gallery";
import { useJobQuery, useToggle } from "utils/hooks";
import { paths } from "utils/paths";
import { removeFileExt } from "utils/text";
import {
  type CreateProductReq,
  useCreateProduct,
} from "../../api/useCreateProduct";
import type { CreateStepProps } from "../../types";
import { ProductWizardTemplate } from "../ProductWizardTemplate";
import { ProductWizardFooter } from "../ProductWizardStep";
import { DesignPreview, type DesignPreviewMethods } from "./DesignPreview";

/** Prevent videos from being selected from portfolio */
const filterPortfolioItems = (item: GalleryItem) => !item.image?.videoUrl;

function CreateStepDesignPreview({
  artistId,
  options = {},
  productTemplateName,
  productTemplate,
  moveBack,
}: CreateStepProps) {
  // Selected file or portfolio item url for DesignPreview
  const [design, setDesign] = React.useState<{
    file: File | string;
    name: string;
  }>();
  // Ref for DesignPreview so we can imperatively call its save method
  const designPreviewRef = React.useRef<DesignPreviewMethods | null>(null);

  // Modal state for SelectingFromPortfolio modal
  const [isSelecting, toggleIsSelecting] = useToggle(false);

  const uploadMutation = usePrintUpload();

  if (!("printArea" in options)) {
    throw new Error(
      "This product template needs a print area to render DesignPreview",
    );
  }

  const {
    acceptType = "stillImage",
    allowFromPortfolio = true,
    createStepIntro = `Upload the image that will appear on the ${productTemplateName}.`,
    designPreviewInstructions,
    designTemplateSrc,
    downloadableTemplateSrc,
    emptyMockupSrc,
    maxFileSize = 100 * 1000 * 1000, // 100MB
    printArea,
  } = options;

  // Once a file is selected from OS, update the form
  const onFileChange = (file: File) => {
    setDesign({ file, name: removeFileExt(file.name) });
  };

  // Once a gallery item is selected, update the form
  const onSelectionChange = ([item]: GalleryItem[]) => {
    if (item?.image) {
      setDesign({ file: item.image.url, name: item.title });
      toggleIsSelecting();
    }
  };

  const isSelectDesignStep = !design;
  const isDesignPreviewStep = uploadMutation.isIdle && !isSelectDesignStep;
  const isConfirmationStep = !uploadMutation.isIdle && !isSelectDesignStep;

  // Handle back button based on what step we're on
  const goBack = () => {
    if (isSelectDesignStep) {
      // go back to ChooseTemplate to select different product to create
      moveBack();
    } else if (isDesignPreviewStep) {
      // reset this component to upload/select different design
      setDesign(undefined);
    } else if (isConfirmationStep) {
      // see DesignPreview to edit design and submit different asset
      uploadMutation.reset();
    }
  };

  // Submit the user's design to create their asset and a product mockup
  const generateImage = () => {
    if (designPreviewRef.current) {
      const { asset, ...rest } = designPreviewRef.current.getState();

      uploadMutation.mutate({
        artistId,
        templateId: productTemplate.id,
        location: asset.coordinates,
        scale: asset.scale,
        ...rest,
      });
    }
  };

  return (
    <ProductWizardTemplate
      title={`Design Your ${productTemplateName}`}
      artistId={artistId}
      stepWidth={isDesignPreviewStep ? "xl" : undefined}
    >
      <Container width={isDesignPreviewStep ? "xl" : "md"}>
        {isConfirmationStep && (
          <Stack gap="sm">
            <Stack gap="xxs">
              <H2 size="lg" textTransform="none" textAlign="center">
                Product Mockup
              </H2>

              <Text size="xs" textAlign="center">
                We&apos;re generating a mockup of your product now. If you like
                the final design, continue on to the next steps where you can
                configure your product&apos;s details, options, and pricing.
                We&apos;ll create higher resolution versions in the background
                while you&apos;re working.
              </Text>
            </Stack>

            <DesignConfirmation
              artistId={artistId}
              emptyMockupSrc={emptyMockupSrc}
              goBack={goBack}
              productName={design.name}
              templateId={productTemplate.id}
              uploadMutation={uploadMutation}
            />
          </Stack>
        )}

        {isDesignPreviewStep && (
          <Stack gap="sm">
            <Stack gap="0">
              <H2 size="lg" textTransform="none" textAlign="center">
                Design Preview
              </H2>

              <Text size="xs" textAlign="center">
                {designPreviewInstructions ??
                  "Your artwork should fill the entire print area."}
              </Text>
            </Stack>

            <DesignPreview
              ref={designPreviewRef}
              design={design.file}
              printArea={printArea}
              designTemplateSrc={designTemplateSrc}
            />
          </Stack>
        )}

        {isSelectDesignStep && (
          <Stack gap="md">
            <Stack gap="xs" style={{ textAlign: "center" }}>
              <H2 size="lg" textTransform="none" textAlign="center">
                Upload Your Design
              </H2>

              <Text size="xs">
                <span>{createStepIntro} </span>
                {!!downloadableTemplateSrc && (
                  <span>
                    We recommend using the provided template in order to provide
                    accurate artwork.
                  </span>
                )}{" "}
                <span>
                  You will be able to view mockups and select options in
                  subsequent steps.
                </span>
              </Text>
            </Stack>
            <Box style={{ textAlign: "center" }}>
              <Stack gap="sm">
                <Stack>
                  <Text size="xxs">
                    Print Area Dimensions: <span>{printArea.width}</span>{" "}
                    &times; <span>{printArea.height}</span>
                    <br />
                    Accepted File Types:{" "}
                    <span>{acceptTypes[acceptType].labels.join(", ")}</span>
                    <br />
                    Max File Size: <span>{formatSize(maxFileSize)}</span>
                  </Text>

                  {!!downloadableTemplateSrc && (
                    <div>
                      <DownloadLink
                        href={downloadableTemplateSrc}
                        variant="blank"
                        size="xxs"
                        download
                        target="_blank"
                      >
                        <span>
                          Download <span>{productTemplateName}</span> Template
                        </span>
                        <IconCloudDownload />
                      </DownloadLink>
                    </div>
                  )}
                </Stack>
                <UploadBox>
                  <UploadButtons>
                    <FileInput
                      text="Upload From Device"
                      onChange={onFileChange}
                      accept={acceptType}
                      maxFileSize={maxFileSize}
                      hideRequirements
                    />

                    {allowFromPortfolio && (
                      <Button
                        size="xs"
                        variant="secondary"
                        onClick={toggleIsSelecting}
                      >
                        Select From Portfolio
                      </Button>
                    )}
                  </UploadButtons>
                </UploadBox>
              </Stack>
            </Box>
          </Stack>
        )}
      </Container>

      <Modal isOpen={isSelecting} onClose={toggleIsSelecting}>
        <SelectFromPortfolio
          artistId={artistId}
          onChange={onSelectionChange}
          filter={filterPortfolioItems}
        />
      </Modal>

      {!isConfirmationStep && (
        <ProductWizardFooter>
          <Button
            variant="secondary"
            size="xs"
            onClick={goBack}
            disabled={uploadMutation.isLoading}
          >
            Back
          </Button>

          {isDesignPreviewStep && (
            <Button
              size="xs"
              onClick={generateImage}
              disabled={uploadMutation.isLoading}
            >
              Generate Product Image
            </Button>
          )}
        </ProductWizardFooter>
      )}
    </ProductWizardTemplate>
  );
}

const DownloadLink = styled(Link)({
  borderBottom: "1px solid currentColor",
  opacity: 0.8,
  "&:hover, &:focus-visible": {
    borderWidth: 2,
    opacity: 1,
  },
});

const UploadBox = styled.div(({ theme }) => ({
  alignSelf: "flex-start",
  display: "grid",
  placeItems: "center",
  borderRadius: theme.borderRadius.md,
  backgroundColor: theme.colors.gray10,
  margin: "0 auto",
  padding: "calc(2rem + 2vw)",
  position: "relative",
  width: "100%",
  [theme.breakpoints.desktop]: {
    flex: "0 0 250px",
  },
  label: {
    position: "static",
  },
  "label::before": {
    content: "''",
    position: "absolute",
    inset: 0,
  },
}));

const UploadButtons = styled.div(({ theme }) => ({
  alignItems: "center",
  justifyContent: "center",
  display: "flex",
  flexWrap: "wrap",
  maxWidth: 300,
  gap: 10,
  [theme.breakpoints.desktop]: {
    flexDirection: "column",
    justifyContent: "center",
    gap: 20,
    padding: 40,
    "*": {
      width: "100%",
    },
  },
  "&>button, &>div label": {
    minWidth: 200,
    [theme.breakpoints.desktop]: {},
  },
}));

type DesignConfirmationProps = {
  goBack: VoidFunction;
  emptyMockupSrc?: string;
  uploadMutation: ReturnType<typeof usePrintUpload>;
} & Omit<CreateProductReq, "assetUrl">;

function DesignConfirmation({
  emptyMockupSrc,
  goBack,
  uploadMutation,
  ...req
}: DesignConfirmationProps) {
  const navigate = useNavigate();

  const [formState, setFormState] = React.useState({
    agreed: false,
    error: false,
  });

  const { data, isSuccess, ...jobQuery } = useJobQuery<{
    assetUrl: string;
    mockupUrl: string;
  }>(uploadMutation.data?.jobKey);

  const { mutate, ...productMutation } = useCreateProduct();

  // Merge all loading and error states
  const isLoading =
    uploadMutation.isLoading || jobQuery.isLoading || productMutation.isLoading;
  const isError =
    uploadMutation.isError || jobQuery.isError || productMutation.isError;
  const error = uploadMutation.error ?? jobQuery.error ?? productMutation.error;

  // Double check that user as agreed to TOS before creating product
  const createProduct = () => {
    if (!data || !formState.agreed) {
      setFormState({ ...formState, error: true });
    } else {
      mutate(
        { ...req, assetUrl: data.assetUrl, mockupUrl: data.mockupUrl },
        {
          onSuccess: ({ shopifyProductId }) => {
            // On successful product creation, navigate to Product Wizard to configure it
            navigate(
              `${paths.artistShopProductEdit(req.artistId, parseGid(shopifyProductId).id)}?new=true`,
            );
          },
        },
      );
    }
  };

  const onChange = () =>
    setFormState({ agreed: !formState.agreed, error: false });

  return (
    <>
      <Box style={{ textAlign: "center" }}>
        <Stack gap="md">
          {isError && <UnexpectedError error={error} />}

          <div style={{ position: "relative" }}>
            {isLoading && (
              <>
                {uploadMutation.isLoading && (
                  <PreviewLoadingMessage>
                    <H2 as="span" size="md">
                      Uploading <br />
                      Design
                    </H2>
                  </PreviewLoadingMessage>
                )}
                {jobQuery.isLoading && (
                  <PreviewLoadingMessage>
                    <H2 as="span" size="md">
                      Generating
                      <br />
                      Mockup
                    </H2>
                  </PreviewLoadingMessage>
                )}
                {productMutation.isLoading && (
                  <LoadingMessage showBackdrop>
                    Creating Your Product
                  </LoadingMessage>
                )}
              </>
            )}
            {(isSuccess || !!emptyMockupSrc) && (
              <MockupImage
                src={isSuccess ? parseS3URL(data.mockupUrl) : emptyMockupSrc}
                alt=""
                sizes="300px"
              />
            )}{" "}
          </div>

          {isSuccess && (
            <FlexRow flexDirection="column" justifyContent="center" gap={0}>
              <Text size="xs" bold style={{ margin: 0 }}>
                Confirm Artwork Ownership <RequiredText />
              </Text>

              <Checkbox
                onChange={onChange}
                checked={formState.agreed}
                label={
                  <Text
                    as="span"
                    size="xxs"
                    textAlign="left"
                    style={{ display: "inline-block", lineHeight: 1.2 }}
                  >
                    I confirm that I created and own this original artwork, and
                    that it does not violate <AllCaps>Hug</AllCaps>
                    &apos;s{" "}
                    <Link to="/terms-of-service" target="_blank">
                      Terms&nbsp;of&nbsp;Service
                    </Link>
                    .
                  </Text>
                }
              />
              {formState.error && (
                <FieldError>This field is required.</FieldError>
              )}
            </FlexRow>
          )}
        </Stack>
      </Box>

      <ProductWizardFooter>
        <Button
          variant="secondary"
          size="xs"
          onClick={goBack}
          disabled={isLoading}
        >
          Back
        </Button>

        <Button size="xs" onClick={createProduct} disabled={isLoading}>
          Create Product
        </Button>
      </ProductWizardFooter>
    </>
  );
}

const MockupImage = styled(GenericImage)({
  maxWidth: 300,
  margin: "0 auto",
});

const PreviewLoadingMessage = styled(LoadingMessage)(({ theme }) => ({
  backgroundColor: theme.colors.transparent,
  lineHeight: 1.2,
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
  width: 200,
  zIndex: 9,
  "&>div": {
    backgroundColor: theme.colors.transparent,
    boxShadow: "none",
    padding: 0,
  },
  ".LoadingMessagePause": {
    display: "none",
  },
}));

export { CreateStepDesignPreview };
