import styled from "@emotion/styled";
import { BlockTag } from "atoms";
import type { ShopOrderStatus } from "../types";

type StatusMap = {
  [status in ShopOrderStatus]: {
    bg: string;
    color: string;
    label: string;
  };
};
// Lower-cased versions of our known Product types
const statusMap: StatusMap = {
  INPROGRESS: { label: "Processing", color: "fg", bg: "accent1LL" },
  "WAITING-VENDOR": { label: "Processing", color: "fg", bg: "accent1LL" },
  "WAITING-USER": { label: "Pending Claim", color: "fg", bg: "accent1LL" },
  SHIPPED: { label: "Shipped", color: "fg", bg: "accent2LL" },
  COMPLETE: { label: "Complete", color: "fg", bg: "successL" },
  ERROR: { label: "Error", color: "fg", bg: "accent4LL" },
  UNKNOWN: { label: "Unknown", color: "fg", bg: "accent4LL" },
  "UNDER-REVIEW": { label: "Under Review", color: "fg", bg: "accent4LL" },
  CANCELED: { label: "Canceled", color: "fg", bg: "fg10" },
  REFUNDED: { label: "Refunded", color: "fg", bg: "fg10" },
  "WAITING-ARTIST": { label: "Ready to Ship", color: "fg", bg: "accent3LL" },
};

type OrderTagProps = {
  status: ShopOrderStatus;
};

function OrderTag({ status }: OrderTagProps) {
  const { label, color, bg } = statusMap[status] ?? statusMap.UNKNOWN;

  return (
    <SBlockTag bg={bg} color={color} size="xs">
      {label}
    </SBlockTag>
  );
}

const SBlockTag = styled(BlockTag)({
  display: "inline-block",
  alignSelf: "start",
  justifySelf: "start",
  textAlign: "center",
  minWidth: "8em",
  width: "auto",
});

export { OrderTag };
