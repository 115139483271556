import { useQuery } from "react-query";
import { api } from "utils/api";
import { purchaseTotalKeys } from "./queryKeys";

const getPurchaseTotal = (productId: string) =>
  api.get<number>(`/commerce/${productId}/purchased`);

const usePurchaseTotal = (productId: string) =>
  useQuery(purchaseTotalKeys.detail(productId), () =>
    getPurchaseTotal(productId),
  );

export { usePurchaseTotal };
