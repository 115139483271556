import { useLocation } from "react-router-dom";
import {
  Button,
  FlexRow,
  H2,
  Link,
  IconNewImage,
  LoadingSpinner,
  MasonryGrid,
  Stack,
  Text,
  IconOrder,
} from "atoms";
import { paths } from "utils/paths";
import { pluralize } from "utils/text";
import type { DeleteItemFunction, GalleryItem } from "../types";
import { GalleryItemCard } from "./GalleryItemCard";
import { useGalleryItems } from "../api/useGalleryItems";
import { rootGalleryId } from "../utils/rootGallery";

type GalleryAllItemsListProps = {
  artistId: string;
  isEditing?: boolean;
  addItems?: VoidFunction;
  deleteItem?: DeleteItemFunction;
};

function GalleryAllItemsList({
  artistId,
  addItems = noop,
  deleteItem = noop,
  isEditing,
}: GalleryAllItemsListProps) {
  const location = useLocation();
  const { data: items, isLoading } = useGalleryItems({
    galleryId: rootGalleryId(artistId),
  });
  const numItems = items?.length ?? 0;

  if (isLoading || !items) {
    return <LoadingSpinner />;
  }

  const createDeleteHandler =
    ({ id, itemType }: GalleryItem) =>
    () =>
      deleteItem({ itemId: id, itemType });

  return (
    <Stack gap="sm">
      <FlexRow alignItems="baseline" gap="10px">
        <Stack gap="0">
          <H2 size="h3" style={{ margin: 0 }}>
            All Work
          </H2>
          <Text as="small" size="xxs">
            {pluralize(numItems, "item")}
          </Text>
        </Stack>

        {isEditing && (
          <FlexRow gap="10px" style={{ transform: "translateY(-4px)" }}>
            <Button
              size="xs"
              variant="secondary"
              onClick={addItems}
              aria-label="Add New Item to Portfolio"
            >
              <span>Add</span>
              <IconNewImage />
            </Button>
            <Link
              to={paths.artistPortfolioReorder(artistId)}
              size="xs"
              variant="secondary"
              state={{ from: location }}
            >
              <span>Reorder</span>
              <IconOrder />
            </Link>
          </FlexRow>
        )}
      </FlexRow>

      <MasonryGrid
        items={items}
        renderItem={(item) => (
          <GalleryItemCard
            key={item.id}
            {...item}
            isEditing={isEditing}
            deleteItem={createDeleteHandler(item)}
          />
        )}
      />
    </Stack>
  );
}

export { GalleryAllItemsList };
