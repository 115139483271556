import { useForm } from "react-hook-form";
import { useMutation, UseMutationOptions } from "react-query";
import { trackEvent } from "contexts/mixpanel";
import { api } from "utils/api";
import type {
  CreateAccountFormFields,
  CreateAccountFormResponse,
} from "../types";
import { useAuth } from "../components/AuthProvider";

const postCreateAccount = (fields: CreateAccountFormFields) => {
  const FormContext = fields.verificationCode ? "Verification Code" : "Fields";
  trackEvent({ name: "Form Submit", FormName: "Email Sign Up", FormContext });
  return api.post<CreateAccountFormResponse>("/auth/email/register", {
    body: JSON.stringify(fields),
  });
};

type UseCreateAccountFormArgs = UseMutationOptions<
  CreateAccountFormResponse,
  unknown,
  CreateAccountFormFields
>;

const useCreateAccountForm = (opts: UseCreateAccountFormArgs) => {
  const form = useForm<CreateAccountFormFields>();
  const { refreshAuth } = useAuth();

  const mutation = useMutation(postCreateAccount, {
    ...opts,
    onSuccess: (resp, vars, ctx) => {
      if (resp.status === "EMAIL_UPDATED") {
        trackEvent({ name: "Sign Up", Method: "Email" });

        refreshAuth(resp);

        if (opts.onSuccess) {
          opts.onSuccess(resp, vars, ctx);
        }
      }
    },
  });

  return { ...form, mutation };
};

export { useCreateAccountForm };
