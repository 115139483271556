import * as React from "react";
import styled from "@emotion/styled";
import { useNewNotifications } from "../api/useNotifications";
import { NotificationContent } from "./NotificationContent";

const animationDelay = 0.5; // seconds
const animationDuration = 0.4; // seconds
const animationTotal = (animationDelay + animationDuration) * 1000; // milliseconds

function NotificationToast() {
  const { newNotifications, markReadById } = useNewNotifications();
  const [hidden, setHidden] = React.useState<string[]>([]);

  const createCloseHandler = React.useCallback(
    (id: string) => () => {
      setHidden((prev) => [...prev, id]);

      setTimeout(() => {
        setHidden((prev) => prev.filter((p) => p !== id));
        markReadById(id);
      }, animationTotal);
    },
    [setHidden, markReadById],
  );

  React.useEffect(() => {
    const oldest = newNotifications[0];

    if (oldest && !hidden.includes(oldest.notificationId)) {
      // TODO: this interval isn't actually running every 10 seconds
      // If new notifications come in or are removed, this effect cleans itself up and restarts
      // Maybe we can filter the notifications list by time created? if date is older than
      // some seconds, remove it from the list.
      const interval = setInterval(() => {
        createCloseHandler(oldest.notificationId)();
      }, 1000 * 10);

      return () => clearInterval(interval);
    }

    return noop;
  }, [newNotifications, hidden, createCloseHandler]);

  return (
    <NotificationToastWrapper>
      {newNotifications.map((notification) => (
        <li key={notification.notificationId}>
          <ToastWrapper isHiding={hidden.includes(notification.notificationId)}>
            <Toast
              aria-live="polite"
              isHiding={hidden.includes(notification.notificationId)}
            >
              <NotificationContent
                isDismissible
                notification={notification}
                dismissToast={createCloseHandler(notification.notificationId)}
              />
            </Toast>
          </ToastWrapper>
        </li>
      ))}
    </NotificationToastWrapper>
  );
}

const NotificationToastWrapper = styled.ul({
  bottom: "1rem",
  display: "flex",
  flexDirection: "column",
  left: "1rem",
  position: "fixed",
  zIndex: 200,
  listStyleType: "none",
  padding: 0,
  margin: 0,
  width: 350,
  maxWidth: "calc(100% - 2rem)",
  "&>*::before": {
    content: '"\\200B"',
    position: "absolute",
  },
});

const ToastWrapper = styled.div<ToastProps>(({ isHiding }) => ({
  animationDuration: ".4s",
  animationTimingFunction: "ease",
  animationFillMode: "forwards",
  ...(isHiding
    ? {
        animationName: "in",
        animationDelay: ".25s",
        paddingTop: 10,
      }
    : {
        animationName: "out",
        animationDelay: "0s",
      }),
  "@keyframes out": {
    "0%": {
      maxHeight: 0,
      paddingTop: 0,
    },
    "100%": {
      maxHeight: 400,
      paddingTop: 10,
    },
  },
  "@keyframes in": {
    "0%": {
      maxHeight: 400,
      paddingTop: 10,
    },
    "100%": {
      maxHeight: 0,
      paddingTop: 0,
    },
  },
}));

type ToastProps = { isHiding: boolean };
const onScreen = "translateX(0)";
const offScreen = "translateX(calc(-100% - 1rem))";

const Toast = styled.div<ToastProps>(({ theme, isHiding }) => ({
  "@media (prefers-reduced-motion: no-preference)": {
    animation: `
      ${isHiding ? "toast-out" : "toast-in"}
      ${animationDuration}s cubic-bezier(.44,1.32,.64,1)
      ${isHiding ? "0s" : ".25s"}
      forwards`,
  },
  backgroundColor: "rgba(255,255,255,0.75)",
  backdropFilter: "blur(10px)",
  borderRadius: theme.borderRadius.xs,
  boxShadow: theme.boxShadow.light,
  display: "flex",
  flexDirection: "column",
  fontSize: theme.fontSizes.xs,
  gap: 5,
  lineHeight: "1.2",
  padding: "1.125em 1.125em 1.125em 1em",
  position: "relative",
  transform: isHiding ? onScreen : offScreen,
  ".NotificationContentWrapper": {
    img: {
      flex: "0 0 40px",
      width: 40,
      height: "auto",
    },
    p: {
      fontSize: theme.fontSizes.xxs,
    },
  },
  "@keyframes toast-in": {
    "0%": { transform: offScreen },
    "100%": { transform: onScreen },
  },
  "@keyframes toast-out": {
    "0%": { transform: onScreen },
    "100%": { transform: offScreen },
  },
}));

export { NotificationToast };
