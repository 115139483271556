import {
  Stack,
  H3,
  Text,
  Link,
  ItemCountCircle,
  FlexRow,
  NoBreak,
  AllCaps,
} from "atoms";
import { constants } from "config";
import { Head } from "features/misc";
import { useNewArtistApplication } from "../api/useNewArtistApplication";

function ApplicationVoting({ isHugger }: { isHugger: boolean }) {
  const { data } = useNewArtistApplication();
  const applicationsAvailable = data?.notReactedAvailable ?? 0;

  return (
    <Stack gap="sm">
      <Head title="Voting and Curation Tasks" />
      <H3 size="sm">Artist Application Voting</H3>
      {isHugger ? (
        <>
          {applicationsAvailable ? (
            <>
              <Stack gap="xs">
                <FlexRow justifyContent="center">
                  <ItemCountCircle count={applicationsAvailable} />
                </FlexRow>
                <Text size="xs">
                  applications awaiting <NoBreak>your vote.</NoBreak>
                </Text>
              </Stack>
              <Link to="/artist-voting" variant="primary" size="xs">
                Vote on Artists
              </Link>
            </>
          ) : (
            <Stack gap="xs">
              <H3 size="md">We&apos;re all caught up!</H3>
              <Text size="xs">
                All pending Artist Applications have been voted on
              </Text>
            </Stack>
          )}
        </>
      ) : (
        <Stack gap="xs">
          <Text size="xs">
            Want to join in on the curation process? Purchase a{" "}
            <Link href={constants.openseaURL} target="_blank">
              <AllCaps>Hug</AllCaps>&nbsp;Pass
            </Link>{" "}
            or{" "}
            <Link href={constants.openseaMetaAngels} target="_blank">
              Meta&nbsp;Angels&nbsp;membership
            </Link>{" "}
            to get started and unlock more perks.
          </Text>
        </Stack>
      )}
    </Stack>
  );
}
export { ApplicationVoting };
