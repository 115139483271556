import * as React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Button,
  FlexRow,
  Link,
  Stack,
  Sup,
  Text,
  UnexpectedError,
} from "atoms";
import { constants } from "config";
import { GenericImage } from "features/images";
import { useArtist } from "features/reviewable";
import { useToggle } from "utils/hooks";
import { paths } from "utils/paths";
import {
  useUpdateProductVariants,
  type UpdateProductVariantsReq,
  type VariantField,
} from "../../api/useUpdateProductVariants";
import type { StepProps, TemplateVariant } from "../../types";
import { ProductWizardStep } from "../ProductWizardStep";
import { PricingInfo, VariantRow } from "./StepPricing";

/** Sort variants with graph first (Option Name Alphabetical) */
const sortVariants = (a: VariantField, b: VariantField) => {
  const aIsGraph = !a.variantId?.includes("GRAPH");
  const bIsGraph = !b.variantId?.includes("GRAPH");
  if (!aIsGraph && bIsGraph) {
    return 1;
  }
  if (aIsGraph && !bIsGraph) {
    return -1;
  }
  return 0;
};

function StepSpiralNotebookPricing({ state, dispatch, moveBack }: StepProps) {
  const { isLoading, isError, error, mutate } = useUpdateProductVariants(
    state.product.id,
  );

  const { data: artist } = useArtist(state.artistId);

  const hugFee = artist?.shopHugFee ?? constants.defaultShopFee;
  // Gets readable, whole-number percentage of the artist's HUG fee
  const hugFeePretty = hugFee * 100;

  const [isInfoShowing, toggleIsInfoShowing] = useToggle(false);

  // Use the product's template data to create a map of each variant by variant id which we'll use
  // when rendering each row of the pricing table to display minimum and recommended prices.
  const variantMap = React.useMemo(
    () =>
      state.productTemplate.variants.reduce<Record<string, TemplateVariant>>(
        (acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        },
        {},
      ),
    [state.productTemplate],
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<UpdateProductVariantsReq>({
    defaultValues: {
      variants: state.product.variants
        .reduce<VariantField[]>((acc, cur) => {
          if (
            cur.templateVariantId?.includes("LINED") ||
            cur.templateVariantId?.includes("PLAIN")
          ) {
            // We already have a graph notebook or journal, no need to add it
            return acc;
          }

          // This is either an A4 or A5, so convert the variant
          // object to the shape needed for form fields and update hook and append to accumulator
          return [
            ...acc,
            {
              ...variantMap[cur.templateVariantId ?? ""],
              id: cur.id,
              image: cur.image ?? state.product.featuredImage,
              variantId: cur.templateVariantId,
              priceInCents: Number(cur.price) * 100,
              title: cur.title,
            },
          ];
        }, [])
        .sort(sortVariants),
    },
  });

  const { fields } = useFieldArray({ control, name: "variants" });

  const moveNext = () => dispatch({ type: "moveNext" });
  const onSubmit = handleSubmit((formData) => {
    if (isDirty) {
      mutate(formData, {
        onSuccess: ({ product }) =>
          dispatch({ type: "updateProduct", moveNext: true, product }),
      });
    } else {
      moveNext();
    }
  });

  return (
    <ProductWizardStep
      stepTitle="Pricing"
      state={state}
      moveBack={moveBack}
      moveBackDisabled={isDirty}
      nextStep={onSubmit}
      nextStepDisabled={isLoading}
      isForm
    >
      <Stack gap="md">
        <FlexRow
          justifyContent="space-between"
          alignItems="flex-start"
          itemsFlex="1"
        >
          <Stack>
            <Text size="xs" bold>
              As you set your pricing, the amount you make for the sale of each
              product will update automatically.
            </Text>
            <Text size="xs">
              To learn more about how pricing calculations are done, click the
              &ldquo;About Pricing&rdquo; button.
            </Text>
          </Stack>
          <Button
            variant="secondary"
            size="xs"
            style={{ flex: "0 0 auto" }}
            onClick={toggleIsInfoShowing}
            aria-label="toggle pricing information"
            aria-controls="#about-pricing"
          >
            About Pricing
          </Button>
        </FlexRow>

        {isInfoShowing && (
          <PricingInfo
            isPrintOnDemand
            hugFee={hugFeePretty}
            close={toggleIsInfoShowing}
          />
        )}

        <div>
          {fields.map(({ ...field }, index) => (
            <VariantRow
              key={field.id}
              {...field}
              index={index}
              title={fields.length === 1 ? undefined : field.title}
              image={
                <GenericImage
                  src={state.product.featuredImage?.url}
                  sizes="140px"
                />
              }
              error={errors.variants?.[index]?.priceInCents?.message}
              control={control}
              hugFee={hugFee}
            />
          ))}
        </div>

        {isError && <UnexpectedError error={error} />}

        <Text size="xxs">
          <Sup>*</Sup>Not inclusive of third-party processing fees. See{" "}
          <Link to={paths.shopTerms} target="_blank">
            Seller Terms of Service
          </Link>{" "}
          for details.
        </Text>
      </Stack>
    </ProductWizardStep>
  );
}

StepSpiralNotebookPricing.displayName = "Pricing";

export { StepSpiralNotebookPricing };
