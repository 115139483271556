import styled from "@emotion/styled";
import {
  Container,
  FlexRow,
  IconInstagram,
  IconOpenSea,
  IconOrb,
  IconTwitter,
  Link,
  Stack,
  Text,
} from "atoms";
import { constants } from "config";

function SiteFooter() {
  return (
    <Footer>
      <FooterMain>
        <Container width="sm">
          <Stack gap="sm">
            <FlexRow as="nav" justifyContent="center">
              <SocialLink
                href={constants.twitterURL}
                aria-label="Hug on Twitter"
                target="_blank"
              >
                <IconTwitter
                  primary="black"
                  accent="fg60"
                  aria-hidden="true"
                  focusable="false"
                />
              </SocialLink>

              <SocialLink
                href={constants.instagramURL}
                aria-label="Hug on Instagram"
                target="_blank"
              >
                <IconInstagram
                  primary="black"
                  accent="fg60"
                  aria-hidden="true"
                  focusable="false"
                />
              </SocialLink>

              <SocialLink
                href={constants.openseaURL}
                aria-label="Hug on OpenSea"
                target="_blank"
              >
                <IconOpenSea
                  primary="black"
                  accent="fg60"
                  aria-hidden="true"
                  focusable="false"
                />
              </SocialLink>

              <SocialLink
                href={constants.hugHeyUrl}
                aria-label="Hug on Hey"
                target="_blank"
              >
                <IconOrb
                  primary="black"
                  accent="fg60"
                  aria-hidden="true"
                  focusable="false"
                />
              </SocialLink>
            </FlexRow>

            <TermsText size="xxxs">
              <Link to="/about">About Us</Link>
              {" | "}
              <Link to="/faq">FAQs</Link>
              {" | "}
              <Link to="/support">Get Support</Link>
              {" | "}
              <Link to="/privacy-policy">Privacy Policy</Link>
              {" | "}
              <Link to="/terms-of-service">Terms of Service</Link>
            </TermsText>
            <TermsText size="xxxs">
              Copyright &copy; Assemble Stream, Inc. All rights reserved.
            </TermsText>
          </Stack>
        </Container>
      </FooterMain>
    </Footer>
  );
}

const Footer = styled.footer(({ theme }) => ({
  backgroundColor: theme.colors.black,
  paddingBottom: "env(safe-area-inset-bottom)",
}));
const FooterMain = styled.div(({ theme }) => ({
  color: theme.colors.white80,
  a: {
    color: theme.colors.white,
    "&:hover, &:focus-visible": {
      color: theme.colors.white80,
    },
  },
  padding: `${theme.spacing.xxl} 0 ${theme.spacing.xl}`,
}));

const SocialLink = styled(Link)(({ theme }) => ({
  ...theme.fonts.body,
  alignItems: "center",
  backgroundColor: theme.colors.white,
  borderRadius: theme.borderRadius.round,
  boxShadow: `0 0 0 0 ${theme.colors.white}`,
  display: "flex",
  fontSize: 24,
  height: 40,
  justifyContent: "center",
  width: 40,
  position: "relative",
  overflow: "hidden",
  transition: ".2s ease box-shadow",
  svg: {
    pointerEvents: "none",
    position: "relative",
    zIndex: 2,
  },
  "&:hover, &:focus-visible": {
    borderColor: theme.colors.white,
    boxShadow: `0 0 0 2px ${theme.colors.white}`,
  },
}));

const TermsText = styled(Text)({
  textAlign: "center",
  maxWidth: "600px",
  marginLeft: "auto",
  marginRight: "auto",
  display: "block",
  a: {
    whiteSpace: "nowrap",
  },
});

export { SiteFooter };
