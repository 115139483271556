import * as React from "react";
import styled from "@emotion/styled";
import {
  Button,
  CopyToClipboard,
  DropdownMenu,
  Hr,
  IconShare,
  Link,
  List,
  Stack,
  Text,
} from "atoms";
import { Artist } from "../types";

interface InviteMenuProps {
  reviewable: Artist;
  isAdmin: boolean;
  isArtist: boolean | undefined;
  isOpen: boolean;
  toggle: () => void;
}

function InviteMenu({
  reviewable,
  isAdmin,
  isArtist,
  isOpen,
  toggle,
}: InviteMenuProps) {
  const [isCopyUrlSuccess, setIsCopyUrlSuccess] = React.useState(false);

  const profileUrl = `https://thehug.xyz/artists/${reviewable.id}`;

  React.useEffect(() => {
    if (isCopyUrlSuccess === true) {
      const timeout = setTimeout(() => {
        setIsCopyUrlSuccess(false);
      }, 1000 * 6);
      return () => clearTimeout(timeout);
    }
    return noop;
  }, [isCopyUrlSuccess]);

  if (!isAdmin && !isArtist) {
    return null;
  }

  return (
    <InviteMenuWrapper>
      <InviteMenuToggle
        type="button"
        size="xxxs"
        onClick={toggle}
        aria-label={`${isOpen ? "Close" : "Open"} invite menu`}
        aria-controls="#invite"
        aria-expanded={isOpen}
        variant="secondary"
      >
        Invite{" "}
        {!isAdmin && (
          <>
            Followers <IconShare />
          </>
        )}
      </InviteMenuToggle>

      <InviteMenuDropdown isOpen={isOpen} id="invite">
        <Stack gap="sm">
          {isArtist && (
            <>
              <Stack gap="xs">
                <Text bold size="xxxs" textTransform="uppercase">
                  Invite Followers
                </Text>
                <List type="blank">
                  <Li>
                    <CopyToClipboard
                      buttonText="Copy Invite Link"
                      value={`${profileUrl}?follow`}
                      sendShareEvent
                    />
                  </Li>
                  <Li>
                    <CopyToClipboard
                      buttonText="Copy Invite Link with Message"
                      value={`Follow me on HUG to hear about drops, announcements, and other updates. ${profileUrl}?follow`}
                      sendShareEvent
                    />
                  </Li>
                </List>
              </Stack>
              <SHr aria-hidden />
            </>
          )}
          {(isArtist || isAdmin) && (
            <Stack>
              <Text bold size="xxxs" textTransform="uppercase">
                Invite Artists
              </Text>
              <List type="blank">
                {isArtist && (
                  <Li>
                    <Link to="/artist-invites">
                      Invite an Artist to Apply for a Profile
                    </Link>
                  </Li>
                )}
                {isAdmin && (
                  <Li>
                    <Link to={`/artist-invite/${reviewable.id}`}>
                      Invite an Artist to Claim this Profile
                    </Link>
                  </Li>
                )}
              </List>
            </Stack>
          )}
        </Stack>
      </InviteMenuDropdown>
    </InviteMenuWrapper>
  );
}

const InviteMenuWrapper = styled.div({
  right: 0,
  display: "block",
  position: "relative",
  width: "min-content",
});

interface WithIsOpen {
  isOpen: boolean;
}

const InviteMenuToggle = styled(Button)({
  alignItems: "center",
  display: "block",
  justifyContent: "center",
  paddingLeft: 10,
  paddingRight: 10,
  lineHeight: 1.2,
  whiteSpace: "nowrap",
  svg: {
    pointerEvents: "none",
    fontSize: "1em",
    transform: "scale(1.2)",
  },
});

const InviteMenuDropdown = styled(DropdownMenu)<WithIsOpen>({
  // maxWidth: "200px",
});

const SHr = styled(Hr)({
  margin: 0,
});

const Li = styled.li({
  "&+&": {
    marginTop: ".25rem",
  },
});
export { InviteMenu };
