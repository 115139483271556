import { useMutation, type UseMutationOptions } from "react-query";
import { isDev } from "config";
import { api, ApiError } from "utils/api";
import { sleep } from "utils/hooks";
import type { UploadFileResp } from "../types";

/**
 * Given an array of content type strings, fetch an array of signed URLs to upload a file of each type
 */
const getFileUploadUrls = async (types: string[]) =>
  api.get<{ urls: string[] }>(
    `/upload_url?${new URLSearchParams(
      types.map((type) => ["contentTypes", type]),
    )}`,
  );

/**
 * Mutation to get batched array of signed upload URLs, useful for multi-file upload form
 */
const useFileUploadUrls = () => useMutation(getFileUploadUrls);

type UploadFileReq = { file: File; url?: string };

/**
 * Upload a given file to the provided URL, resolving in the file and its final URL
 *
 * If no URL is provided, fetch a new signed URL according to the file's type.
 */
const putFileUpload = async ({
  file,
  url: providedUrl,
}: UploadFileReq): Promise<UploadFileResp> => {
  const url = providedUrl ?? (await getFileUploadUrls([file.type])).urls[0];

  // TODO: remove this, just for testing upload errors
  if (isDev && file.name.includes("fail")) {
    await sleep(7000);
    throw new ApiError("File upload failed", 500);
  }

  const resp = await fetch(url, {
    method: "PUT",
    body: file,
  });

  if (!resp.ok) {
    throw new ApiError("File upload failed", resp.status);
  }

  return {
    file,
    // Remove query string from AWS S3 URL
    url: url.indexOf("?") > -1 ? url.slice(0, url.indexOf("?")) : url,
  };
};

/**
 * Mutation to upload a single file
 */
const useFileUpload = (
  options?: UseMutationOptions<UploadFileResp, unknown, UploadFileReq>,
) => useMutation(putFileUpload, options);

/**
 * Upload a list of given files, resolving in a list of objects with the file and its final URL
 */
const putMultipleFileUpload = async (files: File[]) => {
  const { urls } = await getFileUploadUrls(files.map((f) => f.type));

  return Promise.all(
    urls.map((url, index) => putFileUpload({ file: files[index], url })),
  );
};

/**
 * Single mutation to handle uploading a list of files
 */
const useMultipleFileUpload = () => useMutation(putMultipleFileUpload);

type PrintUploadReq = {
  file?: File;
  url?: string;
  printArea: Dimensions;
  location: Coordinates;
  scale: number;
  artistId: string;
  templateId: string;
};

type PrintUploadResp = {
  jobKey?: string;
  file?: File;
};

const postPrintUpload = async ({
  file,
  artistId,
  templateId,
  url,
  ...crop
}: PrintUploadReq) => {
  const formData = new FormData();
  // NOTE: order of fields before file is important
  formData.append("artistId", artistId);
  formData.append("templateId", templateId);
  formData.append("crop", JSON.stringify(crop));
  if (file) {
    formData.append("file", file);
  }
  if (url) {
    formData.append("url", url);
  }

  const resp = await api.post<Omit<PrintUploadResp, "file">>("/shop/print", {
    body: formData,
    headers: {
      "content-type": "multipart/form-data",
    },
  });

  return { ...resp, file };
};

const usePrintUpload = (
  options?: UseMutationOptions<PrintUploadResp, unknown, PrintUploadReq>,
) => useMutation(postPrintUpload, options);

export {
  usePrintUpload,
  useFileUpload,
  useFileUploadUrls,
  useMultipleFileUpload,
};
