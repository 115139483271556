import { createSearchParams } from "react-router-dom";
import physicalArt from "assets/images/product-category-art-print.jpg";
import onchainCollectibles from "assets/images/product-category-onchain-collectible.jpg";
import merch from "assets/images/product-category-phone-case.jpg";
import apparel from "assets/images/product-category-apparel.jpg";
import homeGoods from "assets/images/product-category-mug.jpg";
import stationery from "assets/images/product-category-journal.jpg";
import downloads from "assets/images/product-category-digital-download.jpg";
// import education from "assets/images/link-fine-art-prints.jpg";
import { productFilters } from "features/search/data/productFilters";

const createCategoryURL = (tags: { value: string }[]) =>
  `/shop?${createSearchParams({ tags: tags.map(({ value }) => value) })}`;

const productCategories = [
  {
    label: "Physical Art",
    url: createCategoryURL(productFilters.art),
    image: physicalArt,
  },
  {
    label: "Apparel",
    url: createCategoryURL(productFilters.apparel),
    image: apparel,
  },
  {
    label: "Stationery",
    url: createCategoryURL(productFilters.stationery),
    image: stationery,
  },
  {
    label: "Home Goods",
    url: createCategoryURL(productFilters.homeGoods),
    image: homeGoods,
  },
  {
    label: "Accessories",
    url: createCategoryURL(productFilters.accessories),
    image: merch,
  },
  {
    label: "Onchain Collectibles",
    url: createCategoryURL(productFilters.onchainCollectible),
    image: onchainCollectibles,
  },
  {
    label: "Digital Downloads",
    url: createCategoryURL(productFilters.downloads),
    image: downloads,
  },
  // {
  //   label: "Education",
  //   url: createCategoryURL(productFilters.education),
  // image: "",
  // },
];

export { productCategories };
