import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import {
  Button,
  FieldError,
  Form,
  Input,
  Label,
  Link,
  RequiredText,
  Text,
} from "atoms";
import { fieldOptions } from "utils/form";
import type { CheckoutSessionRequest } from "../types";
import { useCart } from "./CartProvider";
import { catalog } from "../data/catalog";
import { trackAddToCart } from "../utils/analytics";

// Hard coded Stripe catalog ids for Art Licensing Webinar
const { priceId, title, returnUrl } = catalog.webinarFFCArtLicensing;

const cart: CheckoutSessionRequest = {
  lineItems: [
    {
      priceId,
      quantity: 1,
    },
  ],
  returnUrl,
  checkoutOpts: {
    title,
    from: `/licensingmasterclass`,
  },
};

type RegistrationForm = {
  email: string;
  createdAt: string;
  name: string;
};

function WebinarFFCArtLicensingForm({ email, ...props }: RegistrationForm) {
  const navigate = useNavigate();
  const { createCart } = useCart();
  const {
    handleSubmit,
    register,
    formState: { errors },
  } = useForm<RegistrationForm>({ defaultValues: { email, ...props } });

  const onSubmit = handleSubmit((formData) => {
    createCart({
      ...cart,
      metadata: formData,
    });
    trackAddToCart(cart.lineItems);
    navigate("/checkout");
  });

  return (
    <Form onSubmit={onSubmit}>
      <Label>
        Email Address <RequiredText />
        <Input
          {...register("email", fieldOptions.required)}
          disabled={!!email}
        />
        {email && (
          <Text size="xxs" as="small">
            To change your email address, go to your{" "}
            <Link to="/profile/edit#email">account settings</Link>.
          </Text>
        )}
        {errors.email && <FieldError>{errors.email.message}</FieldError>}
      </Label>

      <Label>
        Preferred Name <RequiredText />
        <Input {...register("name", fieldOptions.required)} />
        {errors.name && <FieldError>{errors.name.message}</FieldError>}
      </Label>
      <Button type="submit">Register</Button>
    </Form>
  );
}

export { WebinarFFCArtLicensingForm };
