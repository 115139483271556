import * as React from "react";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import {
  Button,
  Callout,
  CloseButton,
  Container,
  AppError,
  FieldError,
  Fieldset,
  FlexRow,
  Form,
  H1,
  H2,
  H3,
  IconExternalLink,
  Label,
  Legend,
  Link,
  List,
  Paragraphs,
  Radio,
  RequiredText,
  ScreenReaderText,
  Select,
  Tag,
  TagList,
  Text,
  TextArea,
  AllCaps,
  PageHeader,
  Box,
  IconTwitter,
  IconInstagram,
  IconGlobeAlt,
  Stack,
  H4,
} from "atoms";
import { constants } from "config";
import { FeaturedImage, GenericImage } from "features/images";
import { fieldOptions } from "utils/form";
import { useToggle } from "utils/hooks";
import { scrollToTop } from "utils/scroll";
import { formatURL } from "utils/text";
import { useArtistApplicationVoteForm } from "../api/useArtistApplicationVoteForm";
import type { ArtistApplication, ArtistApplicationVoteFields } from "../types";

interface ArtistApplicationVotingIntroProps {
  userHasAgreed: boolean;
  onSubmit: VoidFunction;
}

function ArtistApplicationVotingIntro({
  userHasAgreed,
  onSubmit,
}: ArtistApplicationVotingIntroProps) {
  const title = "Vote on Artist Applications";
  // TODO: Replace instances of "HUG" with <AllCaps>Hug</AllCaps>
  const introText =
    "Your opinion matters! HUG is a community-curated platform. That means every creator that is showcased here is chosen by fellow artists and collectors like you.";
  const voteText = `Rate each artist on a scale of 1 to 6 in terms of how excited you are to see them on HUG. ${constants.pointsEarnedApplicationVote} HUG Points are awarded to each individual vote.
You will review and vote for one prospective artist at a time.`;

  const [showHowTo, toggleHowTo] = useToggle(false);

  if (!userHasAgreed) {
    return (
      <>
        <PageHeader overlap>
          <Container width="sm">
            <H1>{title}</H1>
          </Container>
        </PageHeader>
        <Container width="sm" style={{ paddingBottom: "3rem" }}>
          <Box fullWidth>
            <H2 textAlign="center">How to Vote</H2>
            <Paragraphs bold text={introText} textAlign="center" />
            <Paragraphs text={voteText} textAlign="center" />
            <Text textAlign="center">
              <Button
                onClick={onSubmit}
                type="button"
                variant="primary"
                size="sm"
              >
                I&apos;m Ready to Vote
              </Button>
            </Text>
          </Box>
        </Container>
      </>
    );
  }
  return (
    <Container width="md">
      <Title>
        <H1 size="h2">{title}</H1>
        {!showHowTo && (
          <Button size="xs" variant="secondary" onClick={toggleHowTo}>
            How to Vote
          </Button>
        )}
      </Title>

      {showHowTo && (
        <Intro justifyContent="space-between" gap="0 1rem">
          <HowToVotePopup>
            <H2 size="h3">How to Vote</H2>
            <Button size="xs" variant="secondary" onClick={toggleHowTo}>
              Close
            </Button>
            <Text size="xs">{introText}</Text>
            <Paragraphs size="xs" text={voteText} />
          </HowToVotePopup>
        </Intro>
      )}
    </Container>
  );
}

interface BodyProps {
  application?: ArtistApplication;
  applicationsAvailable: number;
  isReportFormShowing: boolean;
  toggleReportForm: VoidFunction;
  onReportSuccess: VoidFunction;
}

function ArtistApplicationVotingBody({
  application,
  applicationsAvailable,
  isReportFormShowing,
  toggleReportForm,
  onReportSuccess,
}: BodyProps) {
  return (
    <Container width="md">
      <VotingMain>
        {!application ? (
          <Box>
            <CaughtUpFlexRow>
              <div>
                <H2>We&apos;re all caught up!</H2>
                <Text>All pending applications have been voted&nbsp;on.</Text>
                <Link variant="primary" to="/profile/your-actions">
                  See your Voting &amp; Curation Tasks
                </Link>
              </div>
            </CaughtUpFlexRow>
          </Box>
        ) : (
          <>
            <ArtistCount size="xs" bold>
              There are <strong>{applicationsAvailable}</strong> artists waiting
              for your vote.
            </ArtistCount>

            {isReportFormShowing && (
              <ArtistApplicationReportForm
                applicationId={application.id}
                close={toggleReportForm}
                onSuccess={onReportSuccess}
              />
            )}

            <Application gap="md" aria-live="polite">
              <ApplicationIntro>
                <ImageContainer>
                  <FeaturedImage
                    src={application.profileImage}
                    alt="Artist profile image from artist application"
                    shape="circle"
                  />
                </ImageContainer>
                <ArtistName>
                  <H2 size="lg">{application.artistName}</H2>
                </ArtistName>
                <ArtistTags>
                  {application.artMedium.map((tag) => (
                    <Tag bg="yellowLight" key={tag}>
                      {tag}
                    </Tag>
                  ))}
                  {application.artistTags.map((tag) => (
                    <Tag bg="pinkLight" key={tag}>
                      {tag}
                    </Tag>
                  ))}
                </ArtistTags>
              </ApplicationIntro>

              <ApplicationBody>
                <Stack gap="0">
                  <H3 size="xs">Bio</H3>
                  <Paragraphs size="xs" text={application.description} />
                </Stack>
                {!application.links[0] ? (
                  <Text size="xs" bold as="em">
                    No links included
                  </Text>
                ) : (
                  <Stack>
                    <H3 size="xs">Links</H3>
                    <LinkList type="blank">
                      {application.links.map((link) => (
                        <Text size="xxs" as="li" key={link.url}>
                          <Link
                            href={link.url}
                            target="_blank"
                            size="xs"
                            aria-label={`Opens ${formatURL(
                              link.url,
                            )} in a new tab`}
                          >
                            <FlexRow gap="6px" flexWrap="nowrap" as="span">
                              {link.type === "website" && <IconGlobeAlt />}
                              {link.type === "instagram" && <IconInstagram />}
                              {link.type === "twitter" && <IconTwitter />}
                              <span>{formatURL(link.url)}</span>
                            </FlexRow>
                          </Link>
                        </Text>
                      ))}
                    </LinkList>
                  </Stack>
                )}
              </ApplicationBody>

              <FeaturedCollection>
                <Stack gap="xs">
                  <H4 size="xxxs">Featured Artwork</H4>
                  <CollectionImage
                    src={application.artistCollections[0].collectionImage}
                    shape="rounded"
                    alt={`Featured NFT from ${application.artistCollections[0].collectionName}`}
                  />
                  <Stack gap="xs">
                    <CollectionName size="sm">
                      {application.artistCollections[0].collectionName}
                    </CollectionName>

                    {application.artistCollections[0].collectionLinks[0]
                      .url && (
                      <Link
                        href={
                          application.artistCollections[0].collectionLinks[0]
                            .url
                        }
                        variant="blank"
                        size="xxs"
                        target="_blank"
                        aria-label={`Opens ${application.artistCollections[0].collectionLinks[0].type} link in a new tab`}
                      >
                        View on{" "}
                        {application.artistCollections[0].collectionLinks[0].type
                          .slice(0, 1)
                          .toUpperCase()}
                        {application.artistCollections[0].collectionLinks[0].type.slice(
                          1,
                        )}{" "}
                        <IconExternalLink
                          width=".8em"
                          height=".8em"
                          aria-hidden="true"
                        />
                      </Link>
                    )}
                  </Stack>
                </Stack>
              </FeaturedCollection>
            </Application>
          </>
        )}
      </VotingMain>
    </Container>
  );
}

const reportCategories = [
  "Insufficient or missing information",
  "Inappropriate content",
  "Looks like spam",
  "Other (please explain)",
];

interface ArtistApplicationReportFormProps {
  applicationId: string;
  close: VoidFunction;
  onSuccess: VoidFunction;
}

function ArtistApplicationReportForm({
  applicationId,
  close,
  onSuccess,
}: ArtistApplicationReportFormProps) {
  const {
    register,
    handleSubmit,
    formState: { errors },
    mutation: { mutate, isLoading, isError, isSuccess },
  } = useArtistApplicationVoteForm();

  // Scroll to top on first render, it means the form was toggled and is now mounted
  React.useLayoutEffect(() => {
    scrollToTop();
  }, []);

  const onSubmit = handleSubmit((formData: ArtistApplicationVoteFields) =>
    mutate({
      ...formData,
      applicationId,
      reaction: "REPORTED",
    }),
  );

  // After form submit success, close the report form and refetch a new application
  const closeSuccessMessage = () => {
    close();
    onSuccess();
  };

  return (
    <Callout>
      <Container width="xs">
        {isSuccess ? (
          <ReportSuccess
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
            itemsFlex="1"
            flexDirection="column"
          >
            <H2 size="md" as="h3" textTransform="uppercase" textAlign="center">
              Thank you for telling&nbsp;us!
            </H2>

            <Button onClick={closeSuccessMessage} size="sm" variant="secondary">
              View Next Artist
            </Button>
          </ReportSuccess>
        ) : (
          <Form onSubmit={onSubmit}>
            <FlexRow
              justifyContent="space-between"
              alignItems="flex-start"
              flexWrap="nowrap"
              itemsFlex="1"
            >
              <H2 size="md" as="h3" textTransform="uppercase">
                Uh Oh! Report a problem with this&nbsp;application
              </H2>

              <CloseButton aria-label="close report form" onClick={close} />
            </FlexRow>

            <Label>
              Category
              <RequiredText />
              <Select {...register("flagCategory", fieldOptions.required)}>
                {reportCategories.map((category) => (
                  <option key={category} value={category}>
                    {category}
                  </option>
                ))}
              </Select>
              {errors.flagCategory && (
                <FieldError>{errors.flagCategory.message}</FieldError>
              )}
            </Label>

            <Label>
              Notes <small>(optional)</small>
              <TextArea
                {...register("flagFeedback", fieldOptions.maxLength(2000))}
              />
              {errors.flagFeedback && (
                <FieldError>{errors.flagFeedback.message}</FieldError>
              )}
            </Label>

            <FlexRow>
              <Button size="md" variant="secondary" onClick={close}>
                Cancel
              </Button>

              <Button
                size="md"
                variant="primary"
                type="submit"
                disabled={isLoading}
                arrow="r"
              >
                Submit
              </Button>
            </FlexRow>

            {isError && (
              <AppError>
                Sorry, we had trouble saving your feedback. Try again?
              </AppError>
            )}
          </Form>
        )}
      </Container>
    </Callout>
  );
}

type ArtistApplicationVotingFormProps = {
  applicationId: string;
  toggleReportForm: VoidFunction;
  skipApplication: VoidFunction;
};

const countdownDuration = 6;
const ratingValues = [...Array(6)].map((_, i) => i + 1);

function ArtistApplicationVotingForm({
  applicationId,
  toggleReportForm,
  skipApplication,
}: ArtistApplicationVotingFormProps) {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
    mutation: { mutate, isSuccess },
  } = useArtistApplicationVoteForm();

  const onSubmit = (formData: ArtistApplicationVoteFields) =>
    mutate(
      {
        ...formData,
        applicationId,
      },
      {
        onSuccess: () => {
          skipApplication();
          reset();
        },
      },
    );

  return (
    <Form onSubmit={handleSubmit(onSubmit)}>
      {isSuccess && (
        <SuccessMessage aria-live="polite">
          <Text size="xs" nowrap textAlign="center">
            Thanks for your vote!
          </Text>

          <Text size="xs" nowrap textAlign="center">
            You get {constants.pointsEarnedApplicationVote}{" "}
            <AllCaps>Hug</AllCaps> Points.
          </Text>
        </SuccessMessage>
      )}

      <FooterFlexRow>
        <VoteInput isInvalid={!!errors.reaction}>
          <Fieldset>
            <Legend>
              How excited would you be
              to&nbsp;see&nbsp;this&nbsp;artist&nbsp;on&nbsp;
              <AllCaps>Hug</AllCaps>?
            </Legend>

            <RadioFlexRow>
              <Text size="xxxs" aria-label="Least excited">
                Least
              </Text>

              {ratingValues.map((rating) => (
                <VoteRadio
                  {...register("reaction", fieldOptions.required)}
                  key={rating}
                  value={rating}
                  label={rating}
                />
              ))}

              <Text size="xxxs" aria-label="Most excited">
                Most
              </Text>
            </RadioFlexRow>
          </Fieldset>

          {!!errors.reaction && (
            <ScreenReaderText>{errors.reaction.message}</ScreenReaderText>
          )}
        </VoteInput>

        <VoteButtons
          alignItems="center"
          justifyContent="center"
          gap="10px 1rem"
        >
          <FlexRow flexWrap="nowrap">
            <Button type="submit" variant="primary" size="xs">
              Submit Vote
            </Button>

            <Button variant="blank" size="xs" onClick={skipApplication}>
              Skip
            </Button>

            <Button size="xs" variant="blank" onClick={toggleReportForm}>
              Report
            </Button>
          </FlexRow>
        </VoteButtons>
      </FooterFlexRow>
    </Form>
  );
}

const Title = styled.div(({ theme }) => ({
  paddingTop: "2rem",
  [theme.breakpoints.desktop]: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    h1: {
      marginBottom: 0,
    },
  },
}));

const Intro = styled(FlexRow)(({ theme }) => ({
  position: "relative",
  alignItems: "flex-start",
  marginTop: "1rem",
  [theme.breakpoints.tablet]: {
    "&>p": {
      flex: 1,
    },
  },
}));

const HowToVotePopup = styled.div(({ theme }) => ({
  background: theme.colors.fg05,
  padding: "1.5rem 1rem",
  borderRadius: theme.borderRadius.md,
  position: "relative",
  [theme.breakpoints.desktop]: {
    padding: "1.5rem",
    paddingRight: "7rem",
  },
  img: {
    width: "60%",
    maxWidth: 220,
    marginRight: "auto",
    [theme.breakpoints.desktop]: {
      margin: "0 auto",
    },
  },
  button: {
    position: "absolute",
    top: "1rem",
    right: "1rem",
  },
}));

const VotingMain = styled.div({
  margin: "2rem 0",
  display: "flex",
  flexDirection: "column",
  gap: "1rem",
});

const ArtistCount = styled(Text)(({ theme }) => ({
  margin: 0,
  color: theme.colors.fg,
  strong: {
    ...theme.fonts.display,
    borderRadius: theme.borderRadius.round,
    background: theme.colors.accent3L,
    padding: "2px 4px",
    lineHeight: 1,
  },
}));

const CaughtUpFlexRow = styled(FlexRow)(({ theme }) => ({
  alignItems: "center",
  textAlign: "center",
  justifyContent: "center",
  gap: "1rem 3rem",
  [theme.breakpoints.desktop]: {
    textAlign: "left",
  },
  img: {
    maxWidth: 150,
    margin: "0 auto",
    [theme.breakpoints.desktop]: {
      margin: 0,
      maxWidth: 190,
    },
  },
  div: {
    maxWidth: 450,
    margin: "0 auto",
    [theme.breakpoints.desktop]: {
      flex: 1,
      margin: 0,
    },
  },
}));

const Application = styled(Stack)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0 2rem",

  '&[aria-hidden="true"]': {
    opacity: 0,
    visibility: "hidden",
    transform: "translateY(100px)",
    maxHeight: 0,
    margin: 0,
    padding: 0,
    transition:
      ".3s ease all 0s, 0s ease max-height .3s, 0s ease padding .3s, 0s ease margin 0s",
  },

  maxHeight: "9999vh",
  overflow: "hidden",
  transform: "translateY(0)",
  transition:
    ".3s ease all 0s, .3s ease max-height .3s, 0s ease padding 0s, 0s ease margin 0s",
  visibility: "visible",
  borderRadius: theme.borderRadius.md,
  border: `1px solid ${theme.colors.fg20}`,
  padding: theme.spacing.gap,
  [theme.breakpoints.desktop]: {
    padding: "2rem",
  },
})).withComponent("article");

const ApplicationIntro = styled.div(({ theme }) => ({
  flex: "0 0 100%",
  alignItems: "center",
  gridArea: "title",
  display: "grid",
  gap: "10px 1rem",
  gridTemplateAreas: `
    "img name"
    "tags tags"
  `,
  gridTemplateColumns: "55px 1fr",
  [theme.breakpoints.xs]: {
    gridTemplateColumns: "66px 1fr",
    gridTemplateAreas: `
    "img name"
    "img tags"
    `,
  },
  [theme.breakpoints.tablet]: {
    gridTemplateColumns: "100px 1fr",
  },
}));

const ImageContainer = styled.div(({ theme }) => ({
  alignSelf: "flex-start",
  gridArea: "img",
  width: 55,
  img: {
    border: `1px solid ${theme.colors.fg20}`,
  },
  [theme.breakpoints.xs]: {
    width: 66,
  },
  [theme.breakpoints.tablet]: {
    width: 100,
  },
}));

const ArtistName = styled.div({
  gridArea: "name",
  "*": {
    margin: 0,
  },
});
const ArtistTags = styled(TagList)({
  gridArea: "tags",
});

const ApplicationBody = styled.div(({ theme }) => ({
  flex: "1 0 350px",
  alignItems: "start",
  display: "grid",
  gap: "1rem",
  gridArea: "body",
  gridAutoRows: "min-content",
  gridTemplateColumns: "1fr",
  [theme.breakpoints.desktop]: {
    order: 999,
    gridTemplateColumns: "1fr",
  },
  ul: {
    lineHeight: 1.3,
  },
  a: {
    textDecoration: "none",
    fontWeight: "bold",
  },
}));

const LinkList = styled(List)({
  flexDirection: "column",
  li: {
    display: "block",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    a: {
      display: "inline-flex",
    },
  },
});

const FeaturedCollection = styled(Box)({
  flex: "0 1 250px",
  gridArea: "collection",
  gap: ".5rem",
  padding: "1rem",
  maxWidth: "100%",
  width: "max-content",
  minWidth: 250,
});

const CollectionImage = styled(GenericImage)(({ theme }) => ({
  border: `1px solid ${theme.colors.fg20}`,
  borderRadius: theme.borderRadius.md,
  height: "auto",
  maxWidth: 300,
  img: {
    height: "auto",
  },
}));

const CollectionName = styled(Text)(({ theme }) => ({
  ...theme.fonts.display,
  color: theme.colors.fg,
}));

const ReportSuccess = styled(FlexRow)({
  margin: "1rem auto",
  maxWidth: 340,
  "h2,h3,p": {
    margin: 0,
  },
  svg: {
    margin: "-1rem 0 -1.5rem",
  },
});

const FooterFlexRow = styled(FlexRow)(({ theme }) => ({
  color: theme.colors.fg,
  gap: "1.25rem 3rem",
  justifyContent: "center",
  textAlign: "center",
}));

const VoteInput = styled.div<{ isInvalid: boolean }>(
  ({ isInvalid, theme }) => ({
    [theme.breakpoints.maxTablet]: {
      justifyContent: "center",
      textAlign: "center",
    },
    fieldset: {
      margin: 0,
      legend: {
        fontSize: theme.fontSizes.xs,
        lineHeight: 1.2,
        marginBottom: 6,
        color: isInvalid ? theme.colors.primary : undefined,
      },
    },
  }),
);

const RadioFlexRow = styled(FlexRow)(({ theme }) => ({
  "@media (max-width: 400px)": {
    margin: "0 -.5rem",
  },
  [theme.breakpoints.maxTablet]: {
    justifyContent: "space-between",
    gap: 0,
  },
  gap: 10,
  justifyContent: "flex-start",
  "&>p": {
    margin: 0,
  },
}));

const VoteRadio = styled(Radio)(({ theme }) => ({
  position: "relative",
  margin: 0,
  color: theme.colors.fg,
  input: {
    width: 32,
    height: 32,
    backgroundColor: theme.colors.bg,
    border: `2px solid ${theme.colors.fg}`,
    padding: 0,
    margin: 0,
    opacity: 0.5,
  },
  "input + *": {
    opacity: 0.75,
  },
  "input:checked": {
    backgroundColor: theme.colors.fg,
    opacity: 1,
  },
  "input:checked + *": {
    color: theme.colors.bg,
    opacity: 1,
  },
  "&:hover input, &:focus input": {
    transform: "none",
    opacity: 1,
    "& + *": {
      opacity: 1,
    },
  },
  "input::before": {
    content: "none",
  },
  div: {
    ...theme.fonts.display,
    position: "absolute",
    left: "50%",
    top: "50%",
    transform: "translate(-50%, -50%)",
  },
}));

const VoteButtons = styled(FlexRow)(({ theme }) => ({
  flex: "1 1 auto",
  justifyContent: "center",
  flexDirection: "column",
  "@media (min-width: 945px)": {
    flexDirection: "row",
    flex: "0 0 auto",
  },
  button: {
    fontWeight: "bold !important",
    display: "flex",
    justifyContent: "center",
    whiteSpace: "nowrap",
    [theme.breakpoints.desktop]: {
      flex: "0 1 auto",
    },
  },
}));

const messageInOut = keyframes({
  "0%, 100%": {
    opacity: 0,
    transform: "translate(-50%, 100%)",
  },
  "5%, 95%": {
    opacity: 1,
    transform: "translate(-50%, 0)",
  },
});

const SuccessMessage = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: 5,
  backgroundColor: theme.colors.green,
  borderRadius: theme.borderRadius.round,
  border: `2px solid ${theme.colors.greenDark}`,
  boxShadow: theme.boxShadow.dark,
  color: theme.colors.fg,
  fontSize: theme.fontSizes.xxs,
  lineHeight: 1.3,
  marginBottom: 0,
  padding: ".4em 1em .5em",
  position: "absolute",
  left: "50%",
  bottom: "calc(100% + .5rem)",
  transform: "translate(-50%, 0)",
  minWidth: 250,
  maxWidth: "calc(100vw - 2rem)",
  zIndex: 3,
  animation: `${countdownDuration}s ease 0s forwards ${messageInOut}`,
  svg: {
    width: 13,
  },
  p: {
    margin: 0,
  },
}));

export {
  ArtistApplicationVotingBody,
  ArtistApplicationVotingIntro,
  ArtistApplicationVotingForm,
};
