import { AuthUser, PlatformRoles } from "../types";

const getPlatformRoles = (user?: AuthUser): PlatformRoles => {
  // Create default user roles as boolean states for convenience
  const roles: Omit<
    PlatformRoles,
    | "isHugger"
    | "showAdminFeatures"
    | "showQAFeatures"
    | "canCurate"
    | "canModerate"
    | "canReview"
    | "canPost"
  > = {
    isSuperAdmin: false,
    isAdmin: false,
    isQA: false,
    isCollector: false,
    isCurator: false,
    isSrCurator: false,
    isPoster: false,
  };

  // Iterate through a user's role strings and set the corresponding boolean
  user?.roles.forEach((role) => {
    if (role === "superAdmin") {
      roles.isSuperAdmin = true;
    } else if (role === "admin") {
      roles.isAdmin = true;
    } else if (role === "qa") {
      roles.isQA = true;
    } else if (role === "collector") {
      roles.isCollector = true;
    } else if (role === "curator") {
      roles.isCurator = true;
    } else if (role === "senior-curator") {
      roles.isSrCurator = true;
    } else if (role === "poster") {
      roles.isPoster = true;
    }
  });

  // We are no longer checking for overrides or for guest passes, just the utilities provided for
  // access to the Discord
  const isHugger = !!user?.utilities?.find((u) => u === "Hugger");

  // Create access hierarchy based on roles
  const showAdminFeatures = roles.isAdmin || roles.isSuperAdmin;
  const showQAFeatures = roles.isQA || showAdminFeatures;
  const canCurate = roles.isSrCurator || showAdminFeatures;
  const canModerate = roles.isCurator || canCurate;
  const canReview = roles.isCollector || canModerate;
  const canPost = roles.isPoster || showAdminFeatures;

  return {
    ...roles,
    isHugger,
    showAdminFeatures,
    showQAFeatures,
    canCurate,
    canModerate,
    canReview,
    canPost,
  };
};

export { getPlatformRoles };
