import * as React from "react";
import uniqueId from "lodash/uniqueId";
import type { MutateOptions } from "react-query";
import {
  AppError,
  Button,
  FieldError,
  FlexRow,
  Form,
  IconEditItem,
  IconTrashCan,
  Input,
  Label,
  LoadingEllipses,
  Modal,
  MultiSelect,
  RequiredText,
  RichTextEditor,
} from "atoms";
import { ImageUpload } from "features/images";
import { fieldOptions, validators } from "utils/form";
import { constants } from "config";
import type { CreateGalleryItemsRequest, GalleryItem } from "../types";
import { useGalleryItemForm } from "../api/useGalleryItemForm";
import { galleryMediums } from "../data";
import { useGallery } from "../api/useGallery";

// Create array of options for MultiSelect field
const tagOptions = galleryMediums.map((value) => ({ value }));

// Character Counter for Description Field
const descriptionMaxLength = 2000;

type GalleryItemFormProps = {
  artistId: string;
  cancel: VoidFunction;
  galleryItem?: GalleryItem;

  deleteItem?: VoidFunction;
  defaultValues?: Partial<GalleryItem>;
  /**
   * If given a file for a new gallery item, upload it automatically
   */
  fileToUpload?: File;
  hideReplaceButton?: boolean;
} & MutateOptions<GalleryItem[], unknown, CreateGalleryItemsRequest>;

function GalleryItemForm({
  artistId,
  cancel,
  defaultValues,
  fileToUpload,
  galleryItem,
  deleteItem,
  onError = noop,
  onSettled = noop,
  onSuccess = noop,
  hideReplaceButton,
}: GalleryItemFormProps) {
  const [formId] = React.useState(uniqueId());

  const isEditing = !!galleryItem;
  const {
    control,
    register,
    onSubmit,
    formState: { errors },
    mutation: { isLoading, isError, error },
  } = useGalleryItemForm({
    artistId,
    defaultValues,
    galleryItem,
    // NOTE: To allow <AddItems> component to handle single and multi uploads, this form has to
    // accept onSuccess/onError/onSettled handlers for multiple items at once. This form only deals
    // with one at a time, so wrap the successful response and the formdata in arrays to satisfy
    // types.
    onSuccess: (data, vars, ctx) => onSuccess([data], { items: [vars] }, ctx),
    onError: (err, vars, ctx) => onError(err, { items: [vars] }, ctx),
    onSettled: (data, err, vars, ctx) =>
      onSettled(data && [data], err, { items: [vars] }, ctx),
  });

  const { data, isLoading: galleriesLoading } = useGallery({
    artistIds: [artistId],
    itemType: "folder",
  });

  const galleryOptions = React.useMemo(
    () =>
      !data
        ? []
        : data.pages.flatMap((page) =>
            page.data.map((gallery) => ({
              label: gallery.title,
              value: gallery.id,
            })),
          ),
    [data],
  );

  return (
    <Form onSubmit={onSubmit} id={formId} style={{ marginBottom: 60 }}>
      <Modal.Header>
        <Modal.Title>
          <IconEditItem /> <span>{isEditing ? "Edit" : "Add"}</span> Item
          Details
        </Modal.Title>
      </Modal.Header>

      <ImageUpload
        control={control}
        fieldName="image"
        accept="imageOrVideo"
        errorMessage={errors?.image?.url?.message}
        fileToUpload={fileToUpload}
        maxFileSize={constants.maxArtworkUploadSize}
        label={
          <>
            Upload Item <RequiredText />
          </>
        }
        src={galleryItem?.image}
        required
        previewWidth={250}
        hideReplaceButton={hideReplaceButton}
      />

      <Label>
        Name <RequiredText />
        <Input {...register("title", fieldOptions.required)} />
        {errors.title && <FieldError>{errors.title.message}</FieldError>}
      </Label>

      <Label>
        Description
        <RichTextEditor
          control={control}
          name="description"
          maxLength={descriptionMaxLength}
          showCharacterCounter
        />
        {errors.description && (
          <FieldError>{errors.description?.message}</FieldError>
        )}
      </Label>

      <Label>
        Link to Learn More
        <Input {...register("link", { validate: validators.validURL })} />
        {errors.link && <FieldError>{errors.link.message}</FieldError>}
      </Label>

      <MultiSelect
        control={control}
        label="Tags"
        name="tags"
        options={tagOptions}
        placeholder="Start typing to search for tags"
        isStatic
      />

      <MultiSelect
        control={control}
        description={
          galleryOptions.length === 0
            ? "You have 0 galleries. Create new galleries to organize the items in your portfolio."
            : "Select galleries to display this item in"
        }
        isLoading={galleriesLoading}
        label="Galleries"
        name="galleryFolderIds"
        options={galleryOptions}
        placeholder="Start typing to search for galleries"
        disabled={galleryOptions.length === 0}
        isStatic
      />

      {deleteItem && (
        <div>
          <Button onClick={deleteItem} variant="blank">
            <IconTrashCan /> Delete Item
          </Button>
        </div>
      )}

      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button variant="secondary" onClick={cancel}>
            Cancel
          </Button>
          <Button
            type="submit"
            form={formId}
            variant="primary"
            disabled={isLoading}
          >
            {isLoading ? (
              <>
                Saving
                <LoadingEllipses />
              </>
            ) : (
              "Save"
            )}
          </Button>
        </FlexRow>

        {isError && (
          <AppError>
            {error instanceof Error ? error.message : "Something went wrong"}
          </AppError>
        )}
      </Modal.Footer>
    </Form>
  );
}

export { GalleryItemForm };
