import { isProduction } from "config";

// Google Analytics Property ID for `thehug.xyz`
const GA_ID = isProduction ? "G-CMRHHCDZTQ" : "G-HRYFEDGTJZ";

/**
 * Add additional configuration info to a target
 */
const config = (
  params: Gtag.ConfigParams | Gtag.ControlParams | Gtag.CustomParams,
) => {
  if (window.gtag) {
    window.gtag("config", GA_ID, params);
  }
};

/**
 * Send a specific event to Google Analytics
 */
const recordEvent = (event: Gtag.EventNames, params: Gtag.EventParams) => {
  if (window.gtag) {
    window.gtag("event", event, params);
  }
};

export { config, recordEvent };
