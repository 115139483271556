import * as React from "react";

/**
 * Create formatted date and time string from timestamp
 * By default, date and short time should render "Jan 1, 1990, 12:00 AM" in "en" locale
 */
const formatDate = (
  timestamp: string | undefined | null,
  options: Intl.DateTimeFormatOptions = {
    dateStyle: "medium",
    timeStyle: "short",
  },
  defaultText = "Never",
) => {
  if (!timestamp) {
    return defaultText;
  }

  try {
    const date = new Date(timestamp);
    // Passing an empty array as locales param uses browser's default locale
    return new Intl.DateTimeFormat([], options).format(date);
  } catch {
    return null;
  }
};

/**
 * Detect valid Date instance
 */
function isValidDate(date: Date | unknown): date is Date {
  return date instanceof Date && !Number.isNaN(date.getTime());
}

function timeSinceDate(date: number | Date) {
  const seconds = Math.floor((new Date().valueOf() - date.valueOf()) / 1000);

  let interval = seconds / 86400; // days

  if (interval > 90) {
    // more than 3 months
    return formatDate(date.toString(), {
      day: "numeric",
      month: "long",
      year: "numeric",
    });
  }
  if (interval > 7) {
    // more than 1 week
    return formatDate(date.toString(), {
      day: "numeric",
      month: "long",
    });
  }
  if (interval > 1) {
    // more than 1 day
    return `${Math.floor(interval)} day${
      Math.floor(interval) !== 1 ? "s" : ""
    } ago`;
  }
  interval = seconds / 3600; // hours
  if (interval > 1) {
    return `${Math.floor(interval)} hour${
      Math.floor(interval) !== 1 ? "s" : ""
    } ago`;
  }
  interval = seconds / 60; // minutes
  if (interval > 1) {
    return `${Math.floor(interval)} minute${
      Math.floor(interval) !== 1 ? "s" : ""
    } ago`;
  }
  return "moments ago"; // less than 1 minute
}

/**
 * Given a yyyy-mm-dd string from an input[type=date] element, convert it to UTC ISO string
 *
 * If `isEOD` is true, set the time to be the last possible millisecond on the entered date,
 * otherwise set time to be the first possible millisecond.
 *
 * Why is this needed? Take the example of a user in NYC during EDT (UTC-4:00) entering the value
 * "12-31-2023" for an end date. The resulting timestamp should be "2024-01-01T04:59:59.999Z" which
 * will be stored in the database as UTC. Note the 5 hour difference as the entered date will be in
 * EST (UTC-5:00).
 */
const convertLocalizedInputToUTC = (value: string, isEOD = false) => {
  const withTime = `${value}T${isEOD ? "23:59:59.999" : "00:00:00"}`;
  return new Date(withTime).toISOString();
};

/**
 * Given a UTC timestamp, convert it to localized date string for form input value
 *
 * Why is this needed? See note in convertLocalizedInputToUTC. The timestamp's date string of
 * "2024-01-01" is not what they originally entered. Convert to local time, then format the output
 * to expected format of input[type=date] of YYYY-MM-DD
 */
const convertUTCToLocalizedInput = (timestamp: string) => {
  const local = new Date(timestamp);
  const year = local.getFullYear();
  // Months are 0-indexed, so add 1. Pad single digits with leading zero.
  const month = (local.getMonth() + 1).toString().padStart(2, "0");
  const date = local.getDate().toString().padStart(2, "0");

  return `${year}-${month}-${date}`;
};

const msPerSec = 1000;
const msPerMin = 60 * msPerSec;
const msPerHour = 60 * msPerMin;
const msPerDay = 24 * msPerHour;

/**
 * Creates a countdown-like object of the time remaining until the specified date: {days, hours, minutes, seconds}
 */
function useTimeUntilDate(date: Date) {
  // Store current time and update every second
  const [now, setNow] = React.useState(new Date());
  React.useEffect(() => {
    const tick = setInterval(() => setNow(new Date()), 1000);
    return () => clearInterval(tick);
  }, []);

  const endDate = new Date(date);
  const remaining = endDate.getTime() - now.getTime();

  const days = Math.floor(remaining / msPerDay);
  const hours = Math.floor((remaining % msPerDay) / msPerHour);
  const minutes = Math.floor((remaining % msPerHour) / msPerMin);
  const seconds = Math.floor((remaining % msPerMin) / msPerSec);

  const isZero = days === 0 && hours === 0 && minutes === 0 && seconds === 0;
  const isNegative = days < 0 && hours < 0 && minutes < 0 && seconds < 0;

  return { days, hours, minutes, seconds, isNegative, isZero };
}

/**
 * Sort function for objects by createdAt date
 */
function sortByCreated<T extends { createdAt: string }>(a: T, b: T) {
  const dateA = new Date(a.createdAt);
  const dateB = new Date(b.createdAt);

  if (dateA < dateB) {
    return -1;
  }
  if (dateA > dateB) {
    return 1;
  }
  return 0;
}

export {
  convertLocalizedInputToUTC,
  convertUTCToLocalizedInput,
  formatDate,
  isValidDate,
  timeSinceDate,
  sortByCreated,
  useTimeUntilDate,
};
