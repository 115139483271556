import * as React from "react";
import defaultSRC from "assets/images/default-img.svg";
import type { Image } from "../types";
import { useImgState } from "../utils/useImgState";
import { ImgBackground } from "./GenericImage";
import { Img } from "./Img";
import { NSFWOverlay } from "./NSFWOverlay";

type ThumbnailProps = Omit<React.ComponentProps<typeof Img>, "src"> & {
  src?: string | Image;
};

const createImgAttributes = (src?: string | Image) => {
  if (!src) {
    return { src: defaultSRC };
  }
  if (typeof src === "string") {
    return { src };
  }

  let srcSet: string | undefined;
  if (src.url150 || src.url300 || src.url600) {
    srcSet = [
      src.url150 ? `${src.url150} 150w` : null,
      src.url300 ? `${src.url300} 300w` : null,
      src.url600 ? `${src.url600} 600w` : null,
      src.url && src.width ? `${src.url} ${src.width}w` : null,
    ]
      .filter(Boolean)
      .join(", ");
  }

  return {
    src: src.url300 ?? src.url,
    srcSet,
    sizes: srcSet ? "(min-width: 800px) 25vw, 96vw" : undefined,
  };
};

/**
 * ReviewableCard thumbnail image component
 */
function Thumbnail({ src, className, shape, ...props }: ThumbnailProps) {
  const { blur, isError, isNSFW, onError, onLoad } = useImgState({
    src,
    ...props,
  });

  // Build <img> attributes depending on `src` type
  const attrs = createImgAttributes(src);

  return (
    <ImgBackground className={className} style={{ "--bg-img": blur }}>
      {isNSFW && <NSFWOverlay />}

      <Img
        shape={shape}
        {...attrs}
        src={isError ? defaultSRC : attrs.src}
        {...props}
        onError={onError}
        onLoad={onLoad}
      />
    </ImgBackground>
  );
}

export { Thumbnail };
