import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import {
  AllCaps,
  AppError,
  Button,
  FieldError,
  FlexRow,
  Form,
  Input,
  Label,
  Link,
  PasswordInput,
  RequiredText,
  Stack,
  Text,
} from "atoms";
import { fieldOptions, validators } from "utils/form";
import { useEmailSignInForm } from "../api/useEmailSignInForm";
import { ConnectButton, Dash } from "./ConnectWallet";

type EmailSignProps = {
  toggleConnectMethod: VoidFunction;
};

function EmailSignIn({ toggleConnectMethod }: EmailSignProps) {
  const { state } = useLocation();

  const {
    register,
    handleSubmit,
    formState: { errors },
    watch,
    mutation: { mutate, isLoading, error },
  } = useEmailSignInForm({
    defaultValues: { email: state?.enteredEmail ?? "" },
  });

  // Preserve any redirect state for successful login on another auth page. Also, provide entered
  // email address to auto-fill email input when switching between forms
  const enteredEmail = watch("email");
  const redirectState = { ...state, enteredEmail };

  const onSubmit = handleSubmit((fields) => mutate(fields));

  return (
    <SForm onSubmit={onSubmit}>
      <Label>
        Email <RequiredText />
        <Input
          {...register("email", {
            ...fieldOptions.required,
            validate: validators.validEmail,
          })}
          autoComplete="username"
          autoCapitalize="off"
        />
        {errors.email && <FieldError>{errors.email.message}</FieldError>}
      </Label>

      <PasswordInput
        register={register}
        fieldName="password"
        error={errors.password}
      />

      <FlexRow justifyContent="center">
        <Button type="submit" disabled={isLoading}>
          Sign In
        </Button>
      </FlexRow>

      <Stack style={{ width: "max-content", margin: "0 auto" }}>
        <FlexRow>
          <Dash />
          <Text textAlign="center" size="xxs" bold textTransform="uppercase">
            or
          </Text>
          <Dash />
        </FlexRow>

        <ConnectButton variant="secondary" onClick={toggleConnectMethod}>
          Sign In with Wallet
        </ConnectButton>
      </Stack>

      {error instanceof Error && (
        <AppError className="AppError">
          {error.message.includes("Unable to authorize")
            ? "Incorrect email or password"
            : error.message}
        </AppError>
      )}

      <FlexRow justifyContent="center" gap="10px">
        <Link to="/forgot-password" size="xs" state={redirectState}>
          Forgot password?
        </Link>

        <Text size="xs" as="span">
          |
        </Text>

        <Link to="/register" size="xs" state={redirectState}>
          New to <AllCaps>Hug</AllCaps>? Create an Account
        </Link>
      </FlexRow>
    </SForm>
  );
}

const SForm = styled(Form)(({ theme }) => ({
  textAlign: "left",
  marginBottom: theme.spacing.lg,
  ".AppError": {
    margin: 0,
  },
}));

export { EmailSignIn, SForm };
