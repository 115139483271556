import * as React from "react";
import styled from "@emotion/styled";
import { UseFieldArrayMove, UseFieldArrayRemove } from "react-hook-form";
import { preventForwarding } from "styles/styleUtils";
import { Button } from "./Button";
import { FadeIn } from "./FadeIn";
import { IconClose, IconChevron } from "./icons";
import { ScreenReaderText } from "./ScreenReaderText";

type RepeaterFieldsProps = {
  children: React.ReactNode;
  handleAppend: () => void;
  rowLabel: string;
  showAppend?: boolean;
};

function RepeaterFields({
  children,
  rowLabel,
  handleAppend,
  showAppend = true,
  ...props
}: RepeaterFieldsProps) {
  return (
    <RepeaterWrapper>
      <StyledRepeaterFields {...props}>{children}</StyledRepeaterFields>

      {showAppend && (
        <AppendButton
          onClick={handleAppend}
          title={`Add ${rowLabel}`}
          size="xs"
        >
          Add {rowLabel}
        </AppendButton>
      )}
    </RepeaterWrapper>
  );
}

const RepeaterWrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  borderRadius: theme.spacing.xs,
  border: "1px solid",
  borderColor: theme.colors.fg30,
  overflow: "hidden",
}));

type RepeaterFieldProps = {
  children: React.ReactNode;
  order: number;
  rowLabel: string;
  handleDelete?: () => void;
  handleMoveUp?: () => void;
  handleMoveDown?: () => void;
};

function RepeaterField({
  order,
  children,
  rowLabel,
  handleDelete,
  handleMoveUp,
  handleMoveDown,
  ...props
}: RepeaterFieldProps) {
  return (
    <StyledRepeaterField {...props}>
      <Actions>
        <RepeaterCount>
          <RepeaterLabel>{rowLabel}&nbsp;</RepeaterLabel>
          {order + 1}
        </RepeaterCount>

        <MoveButton
          dir="up"
          onClick={handleMoveUp}
          disabled={!handleMoveUp}
          title={`Move ${rowLabel} up one`}
        >
          <ScreenReaderText>Move item up one row</ScreenReaderText>
          <IconChevron size=".75em" aria-label="hidden" />
        </MoveButton>

        <MoveButton
          dir="down"
          onClick={handleMoveDown}
          disabled={!handleMoveDown}
          title={`Move ${rowLabel} down one`}
        >
          <ScreenReaderText>Move item down one row</ScreenReaderText>
          <IconChevron size=".75em" aria-label="hidden" />
        </MoveButton>

        {handleDelete && (
          <DeleteButton onClick={handleDelete} title={`Delete ${rowLabel}`}>
            <ScreenReaderText>Delete row</ScreenReaderText>
            <IconClose aria-label="hidden" />
          </DeleteButton>
        )}
      </Actions>

      <Inputs>{children}</Inputs>
    </StyledRepeaterField>
  );
}

/**
 * Create event handlers for each RepeaterField's ActionButtons
 *
 * @param index - Current index of fields[] element
 * @param length - useFieldArray's fields.length
 * @param move - useFieldArray's move function
 * @param remove - useFieldArray's remove function
 */
RepeaterField.createHandlers = (
  index: number,
  length: number,
  move: UseFieldArrayMove,
  remove: UseFieldArrayRemove,
) => ({
  handleDelete: () => remove(index),
  handleMoveUp: index > 0 ? () => move(index, index - 1) : undefined,
  handleMoveDown: index < length - 1 ? () => move(index, index + 1) : undefined,
});

const StyledRepeaterFields = styled.ul(({ theme }) => ({
  listStyleType: "none",
  padding: 0,
  margin: 0,
  "li+li": {
    borderTop: "1px solid",
    borderTopColor: theme.colors.fg30,
  },
  "li:last-of-type": {
    borderBottom: "1px solid",
    borderBottomColor: theme.colors.fg30,
  },
  fieldset: {
    marginBottom: 0,
  },
}));

const AppendButton = styled(Button)(({ theme }) => ({
  margin: "1rem",
  fontSize: theme.fontSizes.xs,
}));
AppendButton.defaultProps = {
  variant: "primary",
};

const StyledRepeaterField = styled(FadeIn)(({ theme }) => ({
  alignItems: "stretch",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
  [theme.breakpoints.desktop]: {
    flexDirection: "row",
  },
  "label, legend": {
    fontSize: theme.fontSizes.xs,
  },
})).withComponent("li");

const Actions = styled.div(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  gap: "0.5rem",
  padding: "0.5rem 1rem",
  position: "relative",
  [theme.breakpoints.maxDesktop]: {
    "&::after": {
      content: "''",
      position: "absolute",
      left: "1rem",
      right: "1rem",
      bottom: 0,
      height: 1,
      backgroundColor: theme.colors.fg20,
    },
  },
  [theme.breakpoints.desktop]: {
    backgroundColor: theme.colors.fg01,
    borderRight: `1px solid ${theme.colors.fg10}`,
    flexDirection: "column",
    justifyContent: "flex-start",
    padding: "1rem 0.5rem",
  },
}));

const RepeaterCount = styled.div(({ theme }) => ({
  alignItems: "center",
  color: theme.colors.fg70,
  display: "flex",
  flex: "1",
  fontSize: theme.fontSizes.xs,
  fontWeight: "bold",
  [theme.breakpoints.desktop]: {
    flex: "0 0 auto",
    textAlign: "center",
    marginBottom: 5,
  },
}));

const RepeaterLabel = styled.div(({ theme }) => ({
  flex: "0 0 auto",
  [theme.breakpoints.desktop]: {
    display: "none",
  },
}));

const ActionButton = styled.button(({ theme }) => ({
  alignItems: "center",
  backgroundColor: "rgb(0 0 0 / 0)",
  borderColor: theme.colors.fg,
  borderRadius: theme.borderRadius.round,
  border: `1px solid`,
  display: "flex",
  justifyContent: "center",
  height: 24,
  margin: 0,
  padding: 0,
  width: 24,
  justifySelf: "flex-end",
  "&[disabled]": {
    opacity: ".2",
  },
  svg: {
    height: "auto",
  },
  "&:hover, &:focus-visible": {
    boxShadow: `0 0 0 1px ${theme.colors.fg}`,
  },
}));
ActionButton.defaultProps = { type: "button" };

type MoveButtonProps = { dir: "up" | "down" };

const MoveButton = styled(ActionButton, {
  shouldForwardProp: preventForwarding<MoveButtonProps>("dir"),
})<MoveButtonProps>(({ dir, theme }) => ({
  borderColor: theme.colors.fg,
  color: theme.colors.fg,
  cursor: "pointer",
  svg: {
    marginBottom: dir === "down" ? -2 : 2,
    transform: dir === "down" ? "rotate(180deg)" : undefined,
    width: 12,
  },
}));

const DeleteButton = styled(ActionButton)(({ theme }) => ({
  borderColor: theme.colors.red,
  color: theme.colors.red,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
  "&:hover, &:focus-visible": {
    boxShadow: `0 0 0 1px ${theme.colors.red}`,
  },
  svg: {
    width: 10,
  },
  [theme.breakpoints.desktop]: {
    marginTop: 7,
  },
}));

const Inputs = styled.div(() => ({
  flex: 1,
  display: "flex",
  gap: "1rem",
  flexDirection: "column",
  overflow: "hidden",
  padding: "1rem",
  paddingBottom: "1.25rem",
  label: {
    lineHeight: 1.3,
  },
  "input, textarea, select": {
    padding: ".5rem",
    minHeight: "2rem",
    marginTop: 6,
  },
  select: {
    paddingRight: "3em",
    marginTop: 0,
  },
}));

export { RepeaterFields, RepeaterField };
