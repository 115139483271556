import * as React from "react";
import { useQuery } from "react-query";
import { useLocation } from "react-router-dom";
import { api } from "utils/api";
import type { SocialMetaData } from "../types";

const defaultMetaData: SocialMetaData = {
  url: "*",
  pageTitle: "HUG | Showcase, sell, and discover art",
  socialTitle: "HUG | A Home for Your Art",
  pageDescription:
    "HUG is an inclusive global community for creatives to showcase, sell & connect over their art. Join to access one-of-a-kind opportunities and education.",
  socialDescription:
    "Join our global creative community to showcase & sell your art, connect with others, and access creator-friendly grants and education.",
  socialImage: "https://thehug.xyz/assets/sitepreview-thehugxyz.jpg",
  socialImageAltText:
    "Discover artists on HUG written above a browser window displaying eight different categories of art.",
};

const getSocialMetaData = () => api.get<SocialMetaData[]>(`/system/metadata`);

const useSocialMetaDataForRoute = () => {
  const { pathname } = useLocation();
  const { data } = useQuery(["system", "social-meta-data"], getSocialMetaData, {
    staleTime: Infinity,
  });

  const metaData = React.useMemo(
    () => data?.find((row) => row.url === pathname) ?? defaultMetaData,
    [data, pathname],
  );

  return metaData;
};

export { useSocialMetaDataForRoute, defaultMetaData };
