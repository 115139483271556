import * as React from "react";
import { useForm, useFieldArray } from "react-hook-form";
import {
  Button,
  FlexRow,
  Link,
  Stack,
  Sup,
  Text,
  UnexpectedError,
} from "atoms";
import { constants } from "config";
import { GenericImage } from "features/images";
import { useArtist } from "features/reviewable";
import { useToggle } from "utils/hooks";
import { paths } from "utils/paths";
import {
  useUpdateProductVariants,
  type UpdateProductVariantsReq,
  type VariantField,
} from "../../api/useUpdateProductVariants";
import type { StepProps, VariantAdminNode } from "../../types";
import { ProductWizardStep } from "../ProductWizardStep";
import { PricingInfo, VariantRow } from "./StepPricing";

function StepPhoneCasePricing({ state, dispatch, moveBack }: StepProps) {
  const { isLoading, isError, error, mutate } = useUpdateProductVariants(
    state.product.id,
  );

  const { data: artist } = useArtist(state.artistId);

  const hugFee = artist?.shopHugFee ?? constants.defaultShopFee;
  // Gets readable, whole-number percentage of the artist's HUG fee
  const hugFeePretty = hugFee * 100;

  const [isInfoShowing, toggleIsInfoShowing] = useToggle(false);

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<UpdateProductVariantsReq>({
    defaultValues: {
      variants: state.product.variants.slice(0, 1).map((v) => ({
        id: v.id,
        image: v.image ?? state.product.featuredImage,
        variantId: v.templateVariantId,
        priceInCents: Number(v.price) * 100,
        title: "Phone Case",
        selectedOptions: v.selectedOptions,
        basePriceCents: state.productTemplate.variants[0].basePriceCents,
      })),
    },
  });

  /** Expand selected fields that affect price out to all variants */
  const expandVariants = (
    pricingFields: VariantField[],
    productVariants: VariantAdminNode[],
  ): VariantField[] =>
    // Will only return 100 variants per product.
    // There are close to 100 phone case variants.
    productVariants.map(({ templateVariantId, ...variantAdminNode }) => ({
      ...variantAdminNode,
      ...pricingFields[0],
      id: variantAdminNode.id,
      variantId: templateVariantId,
    }));

  const { fields } = useFieldArray({ control, name: "variants" });

  const moveNext = () => dispatch({ type: "moveNext" });
  const onSubmit = handleSubmit((formData) => {
    if (isDirty) {
      mutate(
        {
          ...formData,
          variants: expandVariants(formData.variants, state.product.variants),
        },
        {
          onSuccess: moveNext,
        },
      );
    } else {
      moveNext();
    }
  });

  return (
    <ProductWizardStep
      stepTitle="Pricing"
      state={state}
      moveBack={moveBack}
      moveBackDisabled={isDirty}
      nextStep={onSubmit}
      nextStepDisabled={isLoading}
      isForm
    >
      <Stack gap="md">
        <FlexRow
          justifyContent="space-between"
          alignItems="flex-start"
          itemsFlex="1"
        >
          <Stack>
            <Text size="xs" bold>
              As you set your pricing, the amount you make for the sale of each
              product will update automatically.
            </Text>
            <Text size="xs">
              To learn more about how pricing calculations are done, click the
              &ldquo;About Pricing&rdquo; button.
            </Text>
          </Stack>
          <Button
            variant="secondary"
            size="xs"
            style={{ flex: "0 0 auto" }}
            onClick={toggleIsInfoShowing}
            aria-label="toggle pricing information"
            aria-controls="#about-pricing"
          >
            About Pricing
          </Button>
        </FlexRow>

        {isInfoShowing && (
          <PricingInfo
            isPrintOnDemand
            hugFee={hugFeePretty}
            close={toggleIsInfoShowing}
          />
        )}

        <div>
          {fields.map(({ ...field }, index) => (
            <VariantRow
              key={field.id}
              {...field}
              index={index}
              title={fields.length === 1 ? undefined : field.title}
              image={
                <GenericImage
                  src={state.product.featuredImage?.url}
                  sizes="140px"
                />
              }
              error={errors.variants?.[index]?.priceInCents?.message}
              control={control}
              hugFee={hugFee}
            />
          ))}
        </div>

        {isError && <UnexpectedError error={error} />}

        <Text size="xxs">
          <Sup>*</Sup>Not inclusive of third-party processing fees. See{" "}
          <Link to={paths.shopTerms} target="_blank">
            Seller Terms of Service
          </Link>{" "}
          for details.
        </Text>
      </Stack>
    </ProductWizardStep>
  );
}

StepPhoneCasePricing.displayName = "Pricing";

export { StepPhoneCasePricing };
