import styled from "@emotion/styled";
import { Money, useCart } from "@shopify/hydrogen-react";
import { Button, FlexRow, H2, Link, Modal, Stack, Text } from "atoms";
import { paths } from "utils/paths";
import { pluralize } from "utils/text";
import { isLineItem } from "../utils/isLineItem";
import { ProductImage } from "./ProductImage";
import { isDefaultSelectedOption } from "../utils/isDefaultOption";

type ItemAddedToCartProps = {
  close: VoidFunction;
};

function ItemAddedToCart({ close }: ItemAddedToCartProps) {
  const { lines, totalQuantity = 0, checkoutUrl } = useCart();

  // Shopify cart line items are sorted most to least recent, so newest item is first
  // TODO:  newest line might not be correct!
  // qty could be updated on old item or item might not be added if variant is unavailable
  const newestLine = lines?.[0];
  if (!isLineItem(newestLine)) {
    return null;
  }

  const {
    attributes,
    cost,
    merchandise: { image, product, selectedOptions },
    quantity,
  } = newestLine;

  const artistName = attributes?.find(
    ({ key }) => key === "_artistName",
  )?.value;

  return (
    <ModalOverlay onClose={close} preventScrollLock>
      <ModalContent hideCloseButton>
        <Stack gap="sm">
          <H2 size="sm">Item Added to Your Cart</H2>

          <FlexRow flexWrap="nowrap" gap="10px">
            {image && <ProductImage data={image} width={90} />}

            <Stack gap="xxs" style={{ flex: 1 }}>
              <Stack gap="0">
                <Title font="display" size="xs">
                  {product.title}
                </Title>
                {artistName && <Text size="xxs">by {artistName}</Text>}

                <SText size="xxs">
                  {selectedOptions
                    .filter((opt) => !isDefaultSelectedOption(opt))
                    .map(({ value }) => value)
                    .join(" | ")}
                </SText>

                <Text size="xxs">QTY: {quantity}</Text>
              </Stack>

              <MoneyWrapper size="xs">
                <Money data={cost.totalAmount} as="span" />
              </MoneyWrapper>
            </Stack>
          </FlexRow>

          <Stack gap="sm">
            <Stack gap="10px">
              <Link
                size="xs"
                to={paths.shopCart}
                variant="primary"
                style={{ width: "100%" }}
                onClick={close}
              >
                View Cart ({pluralize(totalQuantity, "item")})
              </Link>

              <Link
                size="xs"
                href={checkoutUrl}
                variant="secondary"
                style={{ width: "100%" }}
                onClick={close}
              >
                Check out
              </Link>
            </Stack>

            <FlexRow justifyContent="center">
              <Button size="xs" variant="text" onClick={close}>
                Continue Shopping
              </Button>
            </FlexRow>
          </Stack>
        </Stack>
      </ModalContent>
    </ModalOverlay>
  );
}

const ModalOverlay = styled(Modal.Overlay)({
  background: "unset",
  backdropFilter: "unset",
});

const ModalContent = styled(Modal.Content)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  bottom: "unset",
  boxShadow: theme.boxShadow.dark,
  height: "auto",
  left: "50%",
  maxWidth: "calc(100% - 30px)",
  right: "unset",
  top: 15,
  transform: "translate(-50%, 0)",
  width: "calc(100% - 30px)",
  "&>div": {
    padding: theme.spacing.md,
  },
  [theme.breakpoints.tablet]: {
    left: "unset",
    right: theme.spacing.md,
    transform: "unset",
    width: 400,
  },
  [theme.breakpoints.desktop]: {
    bottom: "unset",
    minHeight: "unset",
    position: "fixed",
  },
}));

const Title = styled(Text)(({ theme }) => ({
  color: theme.colors.fg,
}));

const SText = styled(Text)({
  margin: 0,
});

const MoneyWrapper = styled(Text)(({ theme }) => ({
  fontVariantNumeric: "tabular-nums",
  margin: 0,
  minWidth: 60,
  flex: "1 1 60px",
  color: theme.colors.fg,
}));

export { ItemAddedToCart };
