import { Helmet } from "react-helmet-async";
import { AppProviders } from "contexts";
import { GlobalErrorBoundary } from "features/misc";
import { StagingSignIn, useStagingSignIn } from "features/stagingSignIn";
import { Router } from "./Router";

function App() {
  const { showStagingSignIn, ...props } = useStagingSignIn();

  return (
    <GlobalErrorBoundary>
      <AppProviders>
        <Helmet defaultTitle="HUG | Showcase, sell, and discover art">
          <meta
            name="description"
            content="HUG is an inclusive global community for creatives to showcase, sell & connect over their art. Join to access one-of-a-kind opportunities and education."
          />
          <meta property="og:type" content="website" />
          <meta property="og:title" content="HUG | A Home for Your Art" />
          <meta
            property="og:description"
            content="Join our global creative community to showcase & sell your art, connect with others, and access creator-friendly grants and education."
          />
          <meta
            property="og:image"
            content="https://thehug.xyz/assets/sitepreview-thehugxyz.jpg"
          />
          <meta
            property="og:image:alt"
            content="Discover artists on HUG written above a browser window displaying eight different categories of art."
          />
          <meta name="twitter:card" content="summary_large_image" />
          <meta name="twitter:site" content="@thehugxyz" />
          <meta name="twitter:title" content="HUG | A Home for Your Art" />
          <meta
            name="twitter:description"
            content="Join our global creative community to showcase & sell your art, connect with others, and access creator-friendly grants and education."
          />
          <meta
            name="twitter:image"
            content="https://thehug.xyz/assets/sitepreview-thehugxyz.jpg"
          />
          <meta
            name="twitter:image:alt"
            content="Discover artists on HUG written above a browser window displaying eight different categories of art."
          />
        </Helmet>
        {showStagingSignIn ? <StagingSignIn {...props} /> : <Router />}
      </AppProviders>
    </GlobalErrorBoundary>
  );
}

export { App };
