import styled from "@emotion/styled";
import { Image } from "@shopify/hydrogen-react";

const ProductImage = styled(Image)(({ theme }) => ({
  height: "auto",
  border: "1px solid",
  borderColor: theme.colors.fg10,
  display: "block",
  margin: 0,
  borderRadius: theme.borderRadius.sm,
}));

export { ProductImage };
