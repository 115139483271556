import * as React from "react";
import styled from "@emotion/styled";
import { useNavigate, useLocation } from "react-router-dom";
import { Text, Link, LoadingSpinner } from "atoms";
import { constants } from "config";
import { useAuth } from "features/auth";
import { useDiscordConnect } from "../api/useDiscordConnect";
import { ProfileDiscord } from "./ProfileDiscord";

function DiscordConnect() {
  const location = useLocation();
  const navigate = useNavigate();
  const params = new URLSearchParams(location.hash);
  const accessToken = params.get("access_token");
  const tokenType = "Bearer";

  const { isLoading, isSuccess } = useDiscordConnect(tokenType, accessToken);
  const { user } = useAuth();

  React.useEffect(() => {
    if (isSuccess) {
      navigate("/profile");
    }
  }, [isSuccess, navigate]);

  if (isLoading) {
    return <LoadingSpinner />;
  }
  if (user?.discord) {
    return <ProfileDiscord user={user} />;
  }
  return (
    <div>
      <Text size="xxs">
        Connect to Discord to get roles for your&nbsp;HUG&nbsp;NFTs.
      </Text>

      <SLink href={constants.discordConnectUrl} size="xxs" variant="secondary">
        Connect to Discord
      </SLink>
    </div>
  );
}

const SLink = styled(Link)({
  minHeight: "1rem",
  padding: ".4rem .8rem .3rem",
  textTransform: "none",
});

export { DiscordConnect };
