import { useNavigate } from "react-router-dom";
import { Button, H1, H2, IconArrow, SiteContainer, Stack, Text } from "atoms";
import { Head } from "features/misc";
import { GlobalSearch } from "features/search";
import styled from "@emotion/styled";

function NotFound() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Main>
      <Head title="Not Found" />
      <SiteContainer width="xs">
        <Stack gap="md">
          <Stack>
            <H1>Sorry!</H1>
            <Text size="lg">We can’t find what you’re looking for... 😥</Text>
          </Stack>
          <div>
            <H2 size="md">Try searching artists and projects</H2>
            <GlobalSearch />
          </div>

          <Button variant="blank" onClick={goBack}>
            <IconArrow dir="l" /> Go Back
          </Button>
        </Stack>
      </SiteContainer>
    </Main>
  );
}

const Main = styled.main({
  padding: "2rem 0 4rem",
});

export default NotFound;
