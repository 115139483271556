import * as React from "react";
import styled from "@emotion/styled";
import { preventForwarding } from "styles/styleUtils";
import { Button } from "./Button";
import { ScreenReaderText } from "./ScreenReaderText";

type ScrollRowProps = {
  children: React.ReactNode;
  /** Accessible text for prev/next buttons, defaults to "page" */
  label?: string;
};

/** Gap between items in row, used for scroll math and styling */
const gapDesktop = 16;

function ScrollRow({ children, label = "page", ...props }: ScrollRowProps) {
  const wrapperRef = React.useRef<HTMLDivElement | null>(null);
  const scrollRef = React.useRef<HTMLDivElement | null>(null);

  // Determine number of child elements to control display of paging buttons at each grid size
  const itemCount = Array.isArray(children) ? children.length : 0;
  const isOverThree = itemCount > 3;
  const isOverFour = itemCount > 4;

  // Control which button is disabled depending on scroll position, initially disables previous
  const [disabled, setDisabled] = React.useState<"next" | "prev" | null>(
    "prev",
  );

  const createScrollHandler = (dir: "next" | "prev") => () => {
    if (wrapperRef.current && scrollRef.current) {
      // Distance we want to scroll to reveal new page
      const scrollBy = wrapperRef.current.clientWidth + gapDesktop;
      // Current scroll position of container
      const scrollPos = scrollRef.current.scrollLeft;
      // Max we want to scroll to show last page
      const maxScroll = scrollRef.current.scrollWidth - scrollBy;
      // Increment/decrement current position by page distance based on direction
      const newPos =
        dir === "next" ? scrollPos + scrollBy : scrollPos - scrollBy;
      // Determine actual position bounded by min/max scroll positions
      const left = Math.min(Math.max(0, newPos), maxScroll);

      // Scroll to new position
      scrollRef?.current?.scrollTo({
        top: 0,
        behavior: "smooth",
        left,
      });

      // Update disabled button, if any
      if (left === 0) {
        setDisabled("prev");
      } else if (left === maxScroll) {
        setDisabled("next");
      } else {
        setDisabled(null);
      }
    }
  };

  return (
    <ScrollRowWrapper {...props} ref={wrapperRef}>
      <PrevButton
        onClick={createScrollHandler("prev")}
        arrow="l"
        disabled={disabled === "prev"}
        isOverThree={isOverThree}
        isOverFour={isOverFour}
      >
        <ScreenReaderText>Previous {label}</ScreenReaderText>
      </PrevButton>

      <ScrollRowContent ref={scrollRef}>{children}</ScrollRowContent>

      <NextButton
        onClick={createScrollHandler("next")}
        arrow="r"
        disabled={disabled === "next"}
        isOverThree={isOverThree}
        isOverFour={isOverFour}
      >
        <ScreenReaderText>Next {label}</ScreenReaderText>
      </NextButton>
    </ScrollRowWrapper>
  );
}

const ScrollRowWrapper = styled.div(({ theme }) => ({
  position: "relative",
  scrollSnapType: "y mandatory",
  scrollPadding: theme.spacing.gap,
  [theme.breakpoints.tablet]: {
    "&::before, &::after": {
      bottom: "10px",
      content: "''",
      opacity: 1,
      position: "absolute",
      top: 0,
      width: theme.spacing.gutter,
      zIndex: 9,
      backgroundImage: `
      linear-gradient(to right, 
        rgba(255, 255, 255, 1.000) 0%,
        rgba(255, 255, 255, 0.738) 15%, 
        rgba(255, 255, 255, 0.541) 30%, 
        rgba(255, 255, 255, 0.382) 47%,
        rgba(255, 255, 255, 0.278) 56.5%, 
        rgba(255, 255, 255, 0.194) 65%, 
        rgba(255, 255, 255, 0.126) 73%,
        rgba(255, 255, 255, 0.075) 80.2%, 
        rgba(255, 255, 255, 0.042) 86.1%, 
        rgba(255, 255, 255, 0.021) 91%,
        rgba(255, 255, 255, 0.008) 95.2%, 
        rgba(255, 255, 255, 0.002) 98.2%,
        rgba(255, 255, 255, 0.000) 100%
      )`,
    },
    "&::before": {
      right: "calc(100% + 1px)",
    },
    "&::after": {
      left: "calc(100% + 1px)",
      transform: "scale(-1,1)",
    },
  },
}));

const ScrollRowContent = styled.div(({ theme }) => ({
  alignItems: "stretch",
  display: "flex",
  flexWrap: "nowrap",
  gap: 10,
  margin: "-1rem",
  overflow: "auto",
  scrollSnapAlign: "start",
  scrollSnapType: "x proximity",
  scrollbarColor: "rgba(0,0,0,.2) rgba(0,0,0,0)",
  scrollbarWidth: "none",
  scrollPadding: theme.spacing.gutter,
  marginLeft: `calc(${theme.spacing.gutter} * -1)`,
  marginRight: `calc(${theme.spacing.gutter} * -1)`,
  padding: `1rem ${theme.spacing.gutter}`,
  "&>*": {
    flexGrow: 0,
    flexShrink: 0,
    flexBasis: "43vw",
    maxWidth: "43vw",
    scrollSnapAlign: "start",
  },
  "@media (min-width: 420px)": {
    "&>*": {
      flexBasis: "40vw",
      maxWidth: "40vw",
    },
  },
  [theme.breakpoints.xs]: {
    "&>*": {
      flexBasis: `calc((100% - (10px * 2)) / 2.5)`,
      maxWidth: `calc((100% - (10px * 2)) / 2.5)`,
    },
  },
  [theme.breakpoints.tablet]: {
    gap: gapDesktop,
    "&>*": {
      flexBasis: `calc((100% - (${gapDesktop}px * 2)) / 3)`,
      maxWidth: `calc((100% - (${gapDesktop}px * 2)) / 3)`,
    },
  },
  [theme.breakpoints.desktopMedium]: {
    "&>*": {
      flexBasis: `calc((100% - (${gapDesktop}px * 3)) / 4)`,
      maxWidth: `calc((100% - (${gapDesktop}px * 3)) / 4)`,
    },
  },
  "&::WebkitScrollbar": {
    display: "none",
    height: "4px",
  },
  "&::WebkitScrollbar-thumb": {
    backgroundColor: "rgba(0,0,0,.2)",
    borderLeft: "solid white",
    borderRight: "solid white",
    borderRightWidth: theme.spacing.gutter,
    borderLeftWidth: theme.spacing.gutter,
    height: "6px",
  },
}));

const PrevButton = styled(Button, {
  shouldForwardProp: preventForwarding("isOverThree", "isOverFour"),
})<{ isOverThree: boolean; isOverFour: boolean }>(
  ({ isOverThree, isOverFour, theme }) => ({
    position: "absolute",
    zIndex: 10,
    padding: 0,
    placeItems: "center",
    right: "100%",
    top: "1rem",
    bottom: "1rem",
    width: theme.spacing.gutter,
    borderRadius: 0,
    border: 0,
    transition: ".2s ease opacity .3s",
    opacity: 0,
    pointerEvents: "none",
    "&, &:hover, &:focus-visible": {
      backgroundColor: theme.colors.transparent,
      color: theme.colors.bg,
      outline: "none",
    },
    "&:hover::before, &:focus-visible::before": {
      transform: "scale(1.15)",
    },
    "&:focus-visible::before": {
      outline: "2px solid black",
      outlineOffset: 2,
    },
    "&[disabled]": {
      opacity: 0,
    },
    svg: {
      position: "relative",
      zIndex: 10,
      "&,*": {
        pointerEvents: "none",
      },
    },
    [theme.breakpoints.tablet]: {
      display: isOverThree ? "grid" : "none",
      opacity: 1,
      pointerEvents: "auto",
    },
    [theme.breakpoints.desktopMedium]: {
      display: isOverFour ? "grid" : "none",
    },
    "&::before": {
      borderRadius: theme.borderRadius.round,
      content: "''",
      position: "absolute",
      width: 32,
      height: 32,
      background: theme.colors.fg,
      transition: ".2s ease transform",
      zIndex: 8,
    },
  }),
);

const NextButton = styled(PrevButton)({
  left: "100%",
  right: "unset",
});

export { ScrollRow, ScrollRowWrapper, ScrollRowContent };
