import styled from "@emotion/styled";
import type { Theme } from "@emotion/react";

interface InputProps {
  bordered?: boolean;
  isInvalid?: boolean;
}

const inputCSS = (theme: Theme, { bordered, isInvalid }: InputProps) =>
  ({
    background: theme.colors.bgLight,
    ...(bordered && {
      borderColor: theme.colors.fg30,
      borderBottomColor: theme.colors.fg60,
      borderStyle: "solid",
      borderWidth: 1,
    }),
    borderRadius: 0,
    ...theme.fonts.body,
    color: theme.colors.bodyColor,
    // NOTE: padding and font size are specially defined to match dimensions of
    // buttons even though they use different font-families by default
    fontSize: 16,
    lineHeight: 1.3,
    // NOTE: Top margin separates the input element from its label
    margin: "0.2rem 0 0",
    minHeight: "2.5rem",
    padding: "0.5rem 1.2rem",
    position: "relative",
    width: "100%",
    zIndex: 1,
    "&:focus": { color: theme.colors.fg },
    "&::placeholder": {
      color: theme.colors.fg60,
    },
    "&:disabled": {
      backgroundColor: theme.colors.gray10,
      opacity: 0.6,
      cursor: "not-allowed",
    },
    "&:invalid": {
      borderColor: theme.colors.error,
      boxShadow: `inset 0 0 0 1px ${theme.colors.error}`,
    },
    ...(isInvalid
      ? {
          borderColor: theme.colors.error,
          boxShadow: `inset 0 0 0 1px ${theme.colors.error}`,
        }
      : {}),
  }) as const;

const Input = styled.input<InputProps>(
  ({ bordered = true, isInvalid = false, theme }) => ({
    ...inputCSS(theme, { bordered, isInvalid }),
    display: "flex",
    alignItems: "center",
    minHeight: "2.4rem",
    position: "relative",
    "&[readonly]": {
      backgroundColor: theme.colors.gray05,
    },
  }),
);

const InputPre = styled.div(({ theme }) => ({
  display: "none",
  alignItems: "center",
  alignSelf: "stretch",
  border: `1px solid ${theme.colors.fg30}`,
  borderBottomColor: theme.colors.fg60,
  borderRight: "none",
  backgroundColor: theme.colors.fg05,
  flex: "0 0 auto",
  fontSize: 14,
  fontWeight: "normal",
  lineHeight: 1.3,
  padding: "0.5rem .75rem",
  marginTop: ".2rem",
  [theme.breakpoints.xs]: { display: "flex" },
  "& + input": {
    flex: "1 0 60%",
    marginTop: ".2rem",
  },
}));

export { Input, InputPre, inputCSS };
