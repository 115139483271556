import * as React from "react";
import defaultSRC from "assets/images/default-img.svg";
import type { Image } from "../types";
import { useImgState } from "../utils/useImgState";
import { Img } from "./Img";
import { ImgBackground } from "./GenericImage";

// NOTE: Optimization techniques taken from:
// - https://css-tricks.com/a-guide-to-the-responsive-images-syntax-in-html/#using-srcset
// - https://www.11ty.dev/docs/plugins/image/
// - https://jakearchibald.com/2021/serving-sharp-images-to-high-density-screens/

type FeaturedImageProps = Omit<React.ComponentProps<typeof Img>, "src"> & {
  src?: string | Image;
};

const createImgAttributes = (src?: string | Image) => {
  if (!src) {
    return { src: defaultSRC };
  }
  if (typeof src === "string") {
    return { src };
  }

  let srcSet: string | undefined;
  if (src.url300 || src.url600 || src.url1200) {
    srcSet = [
      src.url300 ? `${src.url300} 300w` : null,
      src.url600 ? `${src.url600} 600w` : null,
      src.url1200 ? `${src.url1200} 1200w` : null,
      src.url && src.width ? `${src.url} ${src.width}w` : null,
    ]
      .filter(Boolean)
      .join(", ");
  }

  return {
    src: src.url1200 ?? src.url,
    srcSet,
    sizes: srcSet ? "150px" : undefined,
  };
};

/**
 * ReviewableTemplate featured image component
 */
function FeaturedImage({
  src,
  shape,
  className,
  ...props
}: FeaturedImageProps) {
  const { blur, isError, onError, onLoad } = useImgState({ src, ...props });

  // Build <img> attributes depending on `src` type
  const attrs = createImgAttributes(src);

  return (
    <ImgBackground className={className} style={{ "--bg-img": blur }}>
      <Img
        shape={shape}
        {...attrs}
        src={isError ? defaultSRC : attrs.src}
        {...props}
        aspectRatio="1 / 1"
        onError={onError}
        onLoad={onLoad}
      />
    </ImgBackground>
  );
}

export { FeaturedImage };
