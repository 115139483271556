import styled from "@emotion/styled";
import {
  FlexRow,
  Grid,
  H2,
  Link,
  NamedAvatar,
  ShowOnDesktop,
  ShowOnMobile,
  Stack,
} from "atoms";
import { useArtist } from "features/reviewable";
import { paths } from "utils/paths";

type ShopPageArtistsProps = { featuredArtists: string[] };

function ShopPageArtists({ featuredArtists }: ShopPageArtistsProps) {
  if (!featuredArtists) {
    return null;
  }

  return (
    <Stack gap="sm">
      <FlexRow justifyContent="space-between">
        <H2 size="lg" style={{ margin: 0 }}>
          Featured Artists
        </H2>

        <ShowOnDesktop>
          <Link variant="primary" size="xs" to={paths.shopArtists()}>
            Explore All Artists with Shops
          </Link>
        </ShowOnDesktop>
      </FlexRow>

      <SGrid>
        {featuredArtists.slice(0, 12).map((artistId: string) => (
          <ArtistWithShop artistId={artistId} key={artistId} />
        ))}
      </SGrid>
      <ShowOnMobile>
        <Link variant="primary" size="xs" to={paths.shopArtists()}>
          Explore All Artists with Shops
        </Link>
      </ShowOnMobile>
    </Stack>
  );
}
type ArtistsWithShopProps = {
  artistId: string;
};
function ArtistWithShop({ artistId }: ArtistsWithShopProps) {
  const { data: artist } = useArtist(artistId);
  if (!artist) {
    return null;
  }
  return (
    <SNamedAvatar
      name={artist.name}
      src={artist.heroImage.url150 ?? artist.heroImage.url}
      link={paths.artistShop(artistId)}
    />
  );
}

const SGrid = styled(Grid)({
  gap: "1rem",
  "@media (min-width: 400px)": {
    gridTemplateColumns: `repeat(auto-fill, minmax(min(calc(150px + 7vw), 50% - 1rem), 1fr))`,
  },
  "@media (min-width: 1040px)": {
    gridTemplateColumns: `repeat(4, 1fr)`,
  },
  "@media (min-width: 1200px)": {
    gridTemplateColumns: `repeat(6, 1fr)`,
  },
});

const SNamedAvatar = styled(NamedAvatar)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.light,
  padding: 10,
  transition: ".2s ease",
  transitionProperty: "box-shadow, transform",
  willChange: "box-shadow, transform",
  width: "100%",
  "&:hover, &:focus-visible": {
    boxShadow: theme.boxShadow.dark,
  },
}));
export { ShopPageArtists };
