import * as React from "react";
import styled from "@emotion/styled";
import { preventForwarding } from "styles/styleUtils";
import { Button } from "./Button";
import { ScreenReaderText } from "./ScreenReaderText";
import { Stack } from "./Stack";
import { H2 } from "./Typography";

type Tab = {
  title: string;
  /**
   * Use the <OpenCallSubmissionTabs/> component
   */
  content: React.ReactNode;
  /**
   * Provide rules for when the tab section should be displayed
   */
  isShowing?: boolean;
};

type TabsProps = {
  tabs: Tab[];
};

function Tabs({ tabs }: TabsProps) {
  const [currentTab, setCurrentTab] = React.useState(0);

  const visible = tabs.filter((tab) => tab.isShowing);

  if (visible.length === 0) {
    return null;
  }

  if (visible.length === 1) {
    return (
      <Stack gap="sm">
        <H2 size="md">{visible[0].title}</H2>
        {visible[0].content}
      </Stack>
    );
  }

  return (
    <div>
      <TabRow role="tablist">
        {visible.map((tab, index) => {
          const tabId = stringToSlug(tab.title);
          return (
            <TabLi key={tabId}>
              <TabButton
                onClick={() => setCurrentTab(index)}
                isCurrent={currentTab === index}
                role="tab"
                aria-selected={currentTab === index}
                aria-expanded={currentTab === index}
                aria-controls={tabId}
                id={tabId}
              >
                {tab.title}
              </TabButton>
            </TabLi>
          );
        })}
      </TabRow>

      {/* map list of tabs, as a section containing the title and content */}
      {visible.map((tab, index) => (
        <TabPanel
          key={tab.title}
          title={tab.title}
          content={tab.content}
          aria-hidden={currentTab !== index}
        />
      ))}
    </div>
  );
}

function stringToSlug(text: string) {
  return text
    .toLowerCase()
    .trim()
    .replace(/[^\w\s-]/g, "")
    .replace(/[\s_-]+/g, "-")
    .replace(/^-+|-+$/g, "");
}

const TabRow = styled.div(({ theme }) => ({
  display: "flex",
  gap: "1rem",
  borderBottom: "2px solid",
  borderColor: theme.colors.fg20,
}));

const TabLi = styled.li({
  position: "relative",
  transform: "translateY(3px)",
  display: "inline-block",
  border: 0,
  padding: 0,
  button: {
    position: "static",
    "&, &:hover, &:focus": { border: 0 },
    paddingTop: ".66em",
  },
});

type TabButtonProps = { isCurrent: boolean };

const TabButton = styled(Button, {
  shouldForwardProp: preventForwarding<TabButtonProps>("isCurrent"),
})<TabButtonProps>(({ theme, isCurrent }) => ({
  color: theme.colors.fg70,
  fontSize: theme.fontSizes.xs,
  lineHeight: 1.2,
  position: "static",
  "&, &:hover, &:focus": { border: 0 },
  paddingTop: ".66em",
  paddingBottom: ".5em !important",
  [theme.breakpoints.desktop]: {
    fontSize: theme.fontSizes.sm,
  },
  "&::after": {
    content: '""',
    display: "block",
    position: "absolute",
    left: -2,
    right: -2,
    bottom: 0,
    height: 0,
    borderRadius: theme.borderRadius.round,
  },
  "&:hover, &:focus-visible": {
    color: theme.colors.fg,
  },

  "&:hover::after, &:focus-visible::after": {
    backgroundColor: theme.colors.fg30,
    height: 4,
  },
  ...(isCurrent && {
    color: theme.colors.fg,
    "&::after,:hover::after, &:focus-visible::after": {
      backgroundColor: theme.colors.fg,
      height: 4,
    },
  }),
}));
TabButton.defaultProps = {
  size: "xs",
  variant: "blank",
};

type TabPanelProps = {
  title?: string;
  content: React.ReactNode;
};

function TabPanel({ title, content, ...props }: TabPanelProps) {
  if (!title || !content) {
    return null;
  }

  return (
    <TabPanelElement role="tabpanel" id={stringToSlug(title)} {...props}>
      <ScreenReaderText as="h3">{title}</ScreenReaderText>
      {content}
    </TabPanelElement>
  );
}

const TabPanelElement = styled.section({
  padding: "1.5rem 0",
  '&[aria-hidden="true"]': {
    display: "none",
  },
});

export { Tabs };
