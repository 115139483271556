import * as React from "react";
import { PropsOf } from "@emotion/react";
import styled from "@emotion/styled";
import { Button, Input, ScreenReaderText } from "atoms";

const SearchBar = React.forwardRef(
  (
    { className, ...props }: PropsOf<typeof StyledSearchInput>,
    ref: React.Ref<HTMLInputElement>,
  ) => (
    <SearchGroup className={className}>
      <ScreenReaderText as="label">Search</ScreenReaderText>
      <StyledSearchInput ref={ref} autoComplete="off" {...props} />
      <SearchButton
        form={props.form}
        type="submit"
        variant="primary"
        size="sm"
        arrow="r"
      >
        Search
      </SearchButton>
    </SearchGroup>
  ),
);

const SearchGroup = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  border: `2px solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.round,
  boxShadow: `  0   3px   20px   2px   rgb(0 0 0 / 25%), 
                0   3px   60px  4px   rgb(0 0 0 / 50%), 
                0   0     6px   1px   rgb(0 0 0 / 12%)`,
  position: "relative",
  display: "flex",
}));

const StyledSearchInput = styled(Input)(({ theme }) => ({
  border: 0,
  borderRadius: theme.borderRadius.round,
  "&&": { color: theme.colors.fg },
  margin: 0,
  minHeight: "2.4rem",
  paddingLeft: "1rem",
  paddingRight: 0,
  fontSize: theme.fontSizes.md,
  [theme.breakpoints.tablet]: {
    paddingLeft: "1.66rem",
  },
  [theme.breakpoints.tablet]: {
    minHeight: "3.4rem",
  },
  "::placeholder": {
    color: theme.colors.fg,
  },
  "&:focus, &:focus-visible": {
    outline: 0,
  },
}));

const SearchButton = styled(Button)(({ theme }) => ({
  margin: 6,
  alignSelf: "stretch",
  fontSize: theme.fontSizes.xs,
  [theme.breakpoints.tablet]: {
    fontSize: theme.fontSizes.sm,
  },
}));

export { SearchBar };
