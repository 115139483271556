import styled from "@emotion/styled";
import {
  Columns,
  Container,
  FlexRow,
  LoadingSpinner,
  PlaceholderBlock,
} from "atoms";

type ReviewableTemplatePlaceholderProps = {
  isLoading: boolean;
};

function ReviewableTemplatePlaceholder({
  isLoading,
}: ReviewableTemplatePlaceholderProps) {
  const isArtist = window.location.pathname.startsWith("/artists/");

  return (
    <>
      <BannerPlaceholderBlock />
      <Container width="lg">
        {isLoading && <LoadingSpinner />}

        <ReviewableGrid isArtist={isArtist}>
          <FeaturedImageWrapper>
            <FeaturedImagePlaceholderBlock isArtist={isArtist} />
          </FeaturedImageWrapper>

          <ReviewableTitle />

          <ReviewCount>
            <FlexRow>
              <RatingsFlexRow>
                {[...Array(3).keys()].map((key) => (
                  <PlaceholderBlock
                    key={key}
                    width="2.75rem"
                    height="2.75rem"
                    circle
                  />
                ))}
              </RatingsFlexRow>
              <PlaceholderBlock width="120px" height="1em" />
            </FlexRow>
          </ReviewCount>

          <TagList>
            <FlexRow gap="0 .6rem">
              {[100, 150, 120].map((width) => (
                <PlaceholderBlock
                  key={width}
                  width={`${width}px`}
                  height="1.5rem"
                  rounded
                  inline
                />
              ))}
            </FlexRow>
          </TagList>

          <Sidebar>
            <PlaceholderBlock width="130px" height="1.25rem" />
            {[130, 125, 150, 131, 126].map((width) => (
              <FlexRow key={width}>
                <PlaceholderBlock width="1rem" height="1rem" circle />
                <PlaceholderBlock width={`${width}px`} height=".8rem" />
              </FlexRow>
            ))}
          </Sidebar>

          <Description>
            {!isArtist && (
              <>
                <br />
                <FlexRow>
                  {[...Array(6).keys()].map((key) => (
                    <div key={key}>
                      <PlaceholderBlock width="50px" height=".6rem" />
                      <PlaceholderBlock width="100px" height="1rem" />
                    </div>
                  ))}
                </FlexRow>
              </>
            )}

            <div>
              <br />
              {[90, 100, 80].map((width) => (
                <PlaceholderBlock
                  key={width}
                  width={`${width}%`}
                  height="0.9em"
                />
              ))}
            </div>
          </Description>

          <CollectionsOrRoadmap>
            <br />
            <PlaceholderBlock width="200px" height="2rem" />
            <Columns cols={3}>
              {[...Array(6).keys()].map((key) => (
                <PlaceholderBlock
                  key={key}
                  width="100%"
                  height="calc(100px + 10vw)"
                />
              ))}
            </Columns>
          </CollectionsOrRoadmap>
        </ReviewableGrid>
      </Container>
    </>
  );
}

const ReviewableGrid = styled.div<{
  isArtist: boolean;
}>(({ isArtist, theme }) => ({
  "&>*": {
    marginBottom: "1.2rem",
  },
  display: "grid",
  minHeight: "100vh",
  position: "relative",
  gridTemplateColumns: "110px 1fr",
  gridTemplateAreas: `
  "image actions"
  "title title"
  "review-count review-count"
  "tags tags"
  "profile-forms profile-forms"
  "project-specs project-specs"
  "description description"
  "roadmap roadmap"
  "team team"
  "collections collections"
  "sidebar sidebar"`,
  ...(isArtist && {
    gridTemplateAreas: `
      "image actions"
      "title title"
      "review-count review-count"
      "tags tags"
      "announcements announcements"
      "profile-forms profile-forms"
      "video video"
      "description description"
      "collections collections"
      "sidebar sidebar"`,
  }),
  [theme.breakpoints.tablet]: {
    gridTemplateColumns: "auto 1fr",
  },
  [theme.breakpoints.desktop]: {
    gap: "0 3rem",
    gridTemplateColumns: "auto 1fr auto",
    gridTemplateAreas: `
      "image actions sidebar"
      "title title sidebar"
      "review-count review-count sidebar"
      "tags tags sidebar"
      "profile-forms profile-forms sidebar"
      "project-specs project-specs sidebar"
      "description description sidebar"
      "roadmap roadmap sidebar"
      "team team sidebar"
      "collections collections sidebar"`,
    ...(isArtist && {
      gridTemplateAreas: `
          "image actions sidebar"
          "title title sidebar"
          "review-count review-count sidebar"
          "tags tags sidebar"
          "announcements announcements sidebar"
          "profile-forms profile-forms sidebar"
          "video video sidebar"
          "description description sidebar"
          "collections collections sidebar"`,
    }),
  },
  [theme.breakpoints.desktopLarge]: {
    gridTemplateColumns: "160px 1fr auto",
  },
}));

const BannerPlaceholderBlock = styled(PlaceholderBlock)({
  width: "100vw",
  aspectRatio: "4",
  maxHeight: 320,
  margin: 0,
  opacity: 0.5,
});
const FeaturedImageWrapper = styled.div(({ theme }) => ({
  gridArea: "image",
  marginTop: "calc(-110px / 2)",
  [theme.breakpoints.desktop]: {
    marginTop: "calc(-135px / 2)",
  },
  [theme.breakpoints.desktopLarge]: {
    marginTop: "calc(-160px / 2)",
  },
}));

const FeaturedImagePlaceholderBlock = styled(PlaceholderBlock)<{
  isArtist: boolean;
}>(({ isArtist, theme }) => ({
  margin: 0,
  display: "block",
  maxWidth: "100%",
  objectFit: "cover",
  aspectRatio: "1 / 1",
  ...(isArtist && {
    borderRadius: theme.borderRadius.round,
  }),
  height: 110,
  width: 110,
  [theme.breakpoints.desktop]: {
    height: 135,
    width: 135,
  },
  [theme.breakpoints.desktopLarge]: {
    height: 160,
    width: 160,
  },
}));

const ReviewableTitle = styled(PlaceholderBlock)(({ theme }) => ({
  gridArea: "title",
  height: theme.fontSizes.h1,
  width: "45%",
}));
const ReviewCount = styled.div({
  gridArea: "review-count",
});
const RatingsFlexRow = styled(FlexRow)(({ theme }) => ({
  gap: 0,
  "*": {
    boxShadow: theme.boxShadow.light,
  },
  "*+*": {
    marginLeft: "-1rem",
  },
}));

const TagList = styled.div({
  gridArea: "tags",
});
const CollectionsOrRoadmap = styled.div({
  gridArea: "collections",
  marginBottom: "2rem",
  "div+div>*": {
    margin: 0,
  },
});
const Sidebar = styled.div({
  gridArea: "sidebar",
  backgroundColor: "#f9f9f9",
  padding: "2rem 1rem 1rem",
  alignSelf: "start",
  borderRadius: "0 0 10px 10px",
});

const Description = styled.div({
  gridArea: "description",
});

export { ReviewableTemplatePlaceholder };
