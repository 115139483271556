import { Text, LoadingSpinner, Button } from "atoms";
import type { ArtistApplication } from "../types";
import { useTriggerArtistApplicationWorkflow } from "../api/useArtistApplicationWorkflow";

type Props = {
  app: ArtistApplication;
};

function ArtistApplicationWorkflowControls({ app }: Props) {
  const { triggerWorkflow, isLoading } = useTriggerArtistApplicationWorkflow();

  const showSendAccepted =
    !app.acceptedEmailSentAt && !app.autoClaimEmailSentAt;

  const showSendReminder1 =
    !showSendAccepted && !app.acceptedReminder1EmailSentAt;

  const showSendReminder2 =
    !showSendReminder1 && !app.acceptedReminder2EmailSentAt;

  if (isLoading) {
    return <LoadingSpinner />;
  }

  const createHandler = (status: Parameters<typeof triggerWorkflow>[1]) => () =>
    triggerWorkflow(app.id, status);

  if (showSendAccepted) {
    return (
      <Text>
        <Button variant="primary" onClick={createHandler("ACCEPTED")}>
          Send Accepted Email
        </Button>
      </Text>
    );
  }
  if (showSendReminder1) {
    return (
      <Button variant="primary" onClick={createHandler("REMINDER1")}>
        Send First Reminder Now
      </Button>
    );
  }
  if (showSendReminder2) {
    return (
      <Button variant="primary" onClick={createHandler("REMINDER2")}>
        Send Second Reminder Now
      </Button>
    );
  }
  return null;
}

export { ArtistApplicationWorkflowControls };
