import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import type { GalleryAddPortfolioItemsRequest, GalleryItem } from "../types";
import { galleryKeys } from "./queryKeys";

/**
 * API to create an array of gallery items
 */
const addPortfolioItems = ({
  artistId,
  galleryId,
  itemIds,
}: GalleryAddPortfolioItemsRequest) =>
  !!artistId && !!galleryId
    ? api.post<GalleryItem[]>(
        `/gallery/artists/${artistId}/${galleryId}/items`,
        {
          body: JSON.stringify({ itemIds }),
        },
      )
    : Promise.reject(new Error("Invalid artist or gallery id"));

/**
 * Mutation to add existing items to a gallery folder
 */
const useGalleryAddPortfolioItems = () => {
  const queryClient = useQueryClient();

  return useMutation(addPortfolioItems, {
    onSuccess: () => {
      queryClient.invalidateQueries(galleryKeys.base);
    },
  });
};

export { useGalleryAddPortfolioItems };
