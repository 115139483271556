import styled from "@emotion/styled";
import {
  Container,
  FlexRow,
  GenericShareMenu,
  H3,
  Link,
  Stack,
  Text,
} from "atoms";
import newProductSrc from "assets/images/create-new-product.jpg";
import { useAuth } from "features/auth";
import { GenericImage } from "features/images";
import { paths } from "utils/paths";
import type { StepProps } from "../../types";
import { ProductWizardTemplate } from "../ProductWizardTemplate";

function StepDone({ state }: StepProps) {
  const tweetButtonValue = `This could be yours! ${state.product.title} is currently available in my @thehugxyz shop. Check it out now: thehug.xyz${paths.artistShopProduct(state.artistId, state.product.handle)}`;

  const copyLinkValue = `https://thehug.xyz${paths.artistShopProduct(
    state.artistId,
    state.product.handle,
  )}`;
  const {
    roles: { isPoster },
  } = useAuth();

  return (
    <ProductWizardTemplate title="Product Builder" artistId={state.artistId}>
      <Container>
        <Stack gap="md">
          <H3 size="lg" bold textAlign="center" textTransform="none">
            {state.product.title} is live in your Shop!
          </H3>

          <CardGrid>
            <Card>
              <CardImage src={state.product.featuredImage?.url} />

              <CardBody>
                <CardTitle>{state.product.title}</CardTitle>

                <FlexRow gap="10px" justifyContent="center">
                  {isPoster && (
                    <Link
                      variant="secondary"
                      size="xs"
                      to={paths.artistUpdateCreateForProduct(
                        state.artistId,
                        state.product.handle,
                      )}
                    >
                      Post Update
                    </Link>
                  )}

                  <GenericShareMenu
                    variant="secondary"
                    size="xs"
                    shareMenuToggleLabel="Share Product"
                    copyButtonLabel="Copy Product Link"
                    copyButtonValue={copyLinkValue}
                    tweetButtonLabel="Share via Tweet"
                    tweetButtonValue={tweetButtonValue}
                  />
                </FlexRow>
              </CardBody>
            </Card>

            <Card>
              <CardImage src={newProductSrc} />
              <CardBody>
                <CardTitle>Create a New Product</CardTitle>

                <Text size="xxs" style={{ margin: 0, lineHeight: 1.2 }}>
                  Choose a different product type to grow and diversify your
                  creative revenue.
                </Text>

                <FlexRow gap="10px" justifyContent="center">
                  <Link
                    size="xs"
                    variant="primary"
                    to={paths.artistShopProductCreate(state.artistId)}
                  >
                    Create Another Product
                  </Link>
                </FlexRow>
              </CardBody>
            </Card>
          </CardGrid>
        </Stack>
      </Container>
    </ProductWizardTemplate>
  );
}

StepDone.displayName = "Done";

const CardGrid = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: "2.5rem 1.5rem",
  maxWidth: 650,
  marginInline: "auto",
  [theme.breakpoints.xs]: {
    flexDirection: "row",
  },
}));

const Card = styled.div(({ theme }) => ({
  alignItems: "flex-start",
  flexDirection: "column",
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.light,
  display: "flex",
  flexWrap: "wrap",
  backgroundColor: theme.colors.bg,
  maxWidth: 330,
  padding: 0,
  position: "relative",
  margin: 0,
  flex: 1,
  "&>div:first-of-type": {
    aspectRatio: 1,
    maxHeight: 330,
    height: "auto",
  },
  "&::marker": {
    visibility: "hidden",
    position: "absolute",
    opacity: 0,
  },
}));

const CardImage = styled(GenericImage)(({ theme }) => ({
  aspectRatio: 1,
  width: "100%",
  height: "100%",
  border: 0,
  borderRadius: `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`,
  display: "block",
  objectFit: "cover",
}));

const CardBody = styled.div({
  boxShadow: "inset 0 1px 0 0 rgb(0 0 0 / .05)",
  display: "flex",
  gap: 10,
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
  paddingInline: "4%",
  paddingBlock: "4%",
  textAlign: "center",
  width: "100%",
});

const CardTitle = styled(Text)(({ theme }) => ({
  ...theme.fonts.display,
  color: theme.colors.fg,
  fontSize: theme.fontSizes.sm,
  lineHeight: 1,
  display: "-webkit-box",
  lineClamp: "2",
  margin: 0,
  overflow: "hidden",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
}));

export { StepDone };
