import { useQuery } from "react-query";
import { api } from "utils/api";
import type { ShopDataResp } from "../types";
import { shopDataQueryKey } from "./queryKeys";

const getShopData = () => api.get<ShopDataResp>("/shop_config");

const useShopData = () => useQuery(shopDataQueryKey, getShopData);

export { useShopData };
