import styled from "@emotion/styled";
import { preventForwarding } from "styles/styleUtils";
import { Button } from "./Button";
import { FadeIn } from "./FadeIn";

type WithIsOpen = {
  isOpen: boolean;
};

const TagDropdownWrapper = styled.div({
  flex: "0 0 auto",
  position: "relative",
  zIndex: 5,
});

const TagDropdownToggle = styled(Button, {
  shouldForwardProp: preventForwarding<WithIsOpen>("isOpen"),
})<WithIsOpen>(({ isOpen }) => ({
  alignItems: "center",
  display: "flex",
  flex: "0 0 auto",
  minHeight: 0,
  whiteSpace: "nowrap",
  span: {
    fontSize: "80%",
    textTransform: "none",
    "&::before": {
      content: '"-"',
      display: "inline-block",
      marginRight: ".8ch",
    },
  },
  svg: {
    height: "auto",
    transform: isOpen ? "rotate(0deg)" : "rotate(180deg)",
  },
}));

const TagDropdownNav = styled(FadeIn)(({ theme }) => ({
  background: theme.colors.bg,
  boxShadow: theme.boxShadow.dark,
  borderRadius: theme.borderRadius.md,
  "&& a, && button": { fontSize: 18 },
  padding: "1rem",
  position: "absolute",
  right: 0,
  top: "calc(100% + 7px)",
  textAlign: "left",
  width: "max-content",
  zIndex: 12,
  ul: {
    listStyleType: "none",
    margin: 0,
    padding: 0,
  },
  "ul li + li": {
    marginTop: 10,
  },
  "ul > li::before": {
    content: '"\\200B"',
    position: "absolute",
  },
})).withComponent("nav");

type WithIsActive = { isActive: boolean };

const TagDropdownItem = styled(Button, {
  shouldForwardProp: preventForwarding<WithIsActive>("isActive"),
})<WithIsActive>(({ theme, isActive }) => ({
  borderRadius: theme.borderRadius.xs,
  border: "1px solid rgb(0 0 0 / 0)",
  color: theme.colors.bodyColor,
  fontSize: theme.fontSizes.xs,
  textDecoration: "none",
  fontWeight: isActive ? "bold" : "normal",
  padding: "0.33rem .5rem",
  display: "grid",
  width: "100%",
  gap: ".5em",
  gridTemplateColumns: "7em 1fr",
  textAlign: "left",
  justifyContent: "start",
  borderColor: isActive ? theme.colors.fg20 : "rgb(0 0 0 / 0)",
  "small, span": {
    fontSize: "1em",
  },
  "&:hover, &:focus-visible": {
    backgroundColor: theme.colors.fg05,
    borderColor: isActive ? theme.colors.fg20 : "rgb(0 0 0 / 0)",
    color: theme.colors.fg,
    small: {
      color: "inherit",
    },
  },
}));

export {
  TagDropdownItem,
  TagDropdownNav,
  TagDropdownToggle,
  TagDropdownWrapper,
};
