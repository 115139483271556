import * as React from "react";
import { useQuery } from "react-query";
import { api } from "utils/api";
import { applicationKeys } from "./queryKeys";
import type { NewArtistApplicationResponse } from "../types";

const getNewArtistApplication = (lastApplicationId?: string) => {
  if (lastApplicationId) {
    return api.get<NewArtistApplicationResponse>(
      `/artists/applications/new?skipApplicationId=${lastApplicationId}`,
    );
  }
  return api.get<NewArtistApplicationResponse>("/artists/applications/new");
};

// Save the last application id and pass on subsequent calls so we don't get
// the same app twice in a row (unless there is only one left)
const useNewArtistApplication = () => {
  const [lastApplicationId, setLastApplicationId] = React.useState<
    string | undefined
  >();

  return useQuery(
    applicationKeys.new(),
    () => getNewArtistApplication(lastApplicationId),
    {
      refetchOnWindowFocus: false,
      onSuccess: (data) => {
        if (data.data[0]) {
          setLastApplicationId(data.data[0].id);
        } else {
          setLastApplicationId(undefined);
        }
      },
    },
  );
};

export { useNewArtistApplication };
