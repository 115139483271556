import styled from "@emotion/styled";
import { List } from "./List";
import { Link } from "./Link";
import { Text } from "./Typography";
/**
 *  <BadgeList>
 *    {array.map((badge) => (
 *      <li>
 *        <BadgeLink to={...}>
 *          <Thumbnail src={badge.image} />
 *          <BadgeTitle>{badge.title}</BadgeTitle>
 *        </BadgeLink>
 *      </li>
 *    ))}
 *  </BadgeList>
 */

const BadgeList = styled(List)(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
  gap: theme.spacing.md,
  [theme.breakpoints.xs]: {
    gridTemplateColumns: "repeat(auto-fill, minmax(120px, 1fr))",
  },
  img: {
    borderRadius: theme.borderRadius.sm,
  },
}));
BadgeList.defaultProps = {
  type: "blank",
};

const BadgeLink = styled(Link)({
  display: "grid",
  gridTemplateRows: "auto 1fr",
  alignItems: "center",
  fontSize: 13,
  flexWrap: "nowrap",
  lineHeight: 1.1,
  textDecoration: "none",
  textAlign: "center",
  small: {
    fontSize: 10,
    opacity: 0.8,
  },
});

const BadgeTitle = styled(Text)({
  fontSize: 13,
  fontWeight: "bold",
  margin: 0,
  "*+&": {
    marginTop: ".5rem",
  },
});
const BadgeSubtitle = styled(Text)(({ theme }) => ({
  color: theme.colors.fg70,
  fontWeight: "normal",
  margin: 0,
  size: 12,
  "*+&": {
    marginTop: ".125rem",
  },
}));

export { BadgeList, BadgeLink, BadgeTitle, BadgeSubtitle };
