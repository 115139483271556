import {
  useFieldArray,
  UseFieldArrayProps,
  UseFormReturn,
  FieldArray,
  FieldValues,
} from "react-hook-form";
import {
  FieldError,
  Input,
  Label,
  Legend,
  RepeaterFields,
  RepeaterField,
} from "atoms";
import { SelectLinkType } from "./SelectLinkType";

type LinksInputProps<TFields extends FieldValues> = {
  control: UseFieldArrayProps<TFields>["control"];
  /* eslint-disable @typescript-eslint/no-explicit-any */
  errors?: any;
  /* eslint-enable @typescript-eslint/no-explicit-any */
  name: UseFieldArrayProps<TFields>["name"];
  // Optional custom <Legend>. Defaults to "Links".
  legend?: React.ReactNode;
  register: UseFormReturn<TFields>["register"];
};

function LinksInput<TFields extends FieldValues>({
  control,
  errors,
  name,
  legend,
  register,
  ...props
}: LinksInputProps<TFields>) {
  const { fields, append, move, remove } = useFieldArray({
    control,
    name,
  });

  const handleAppend = () => append({} as FieldArray<TFields>);

  // Build the registered name for each nested input within a repeated field using the <TFields> name prefix
  const createRegister = (index: number, input: string) =>
    `${name}.${index}.${input}` as Parameters<typeof register>[0];

  return (
    <Label as="fieldset" {...props}>
      {legend ?? <Legend>Links</Legend>}

      <RepeaterFields handleAppend={handleAppend} rowLabel="Link">
        {fields.map((item, linkIndex) => (
          <RepeaterField
            order={linkIndex}
            rowLabel="Link"
            key={item.id}
            {...RepeaterField.createHandlers(
              linkIndex,
              fields.length,
              move,
              remove,
            )}
          >
            <Label>
              Type
              <SelectLinkType
                {...register(createRegister(linkIndex, "type"))}
              />
            </Label>

            <Label>
              URL
              <Input
                {...register(createRegister(linkIndex, "url"), {
                  pattern: {
                    value: /^https:\/\//,
                    message: "URL needs to include https://",
                  },
                })}
              />
              {errors?.[name]?.[linkIndex]?.url && (
                <FieldError block>
                  {errors?.[name]?.[linkIndex]?.url.message}
                </FieldError>
              )}
            </Label>
          </RepeaterField>
        ))}
      </RepeaterFields>
    </Label>
  );
}

export { LinksInput };
