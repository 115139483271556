import * as React from "react";
import styled from "@emotion/styled";
import { inputCSS } from "./Input";
import chevronSvg from "./icons/chevron.svg";

type SelectProps = {
  isInvalid?: boolean;
};

const SelectControl = styled.select<SelectProps>(
  ({ theme, isInvalid = false }) => ({
    ...inputCSS(theme, { bordered: true, isInvalid }),
    appearance: "none",
    cursor: "pointer",
    marginTop: 0,
    paddingRight: "3em",
    color: theme.colors.fg,
  }),
);

const SelectWrapper = styled.div({
  position: "relative",
  margin: "0.2rem 0 0",
  maxWidth: "100%",
  zIndex: 1,
  "&::after": {
    backgroundImage: `url('${chevronSvg}')`,
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    backgroundSize: "contain",
    content: "''",
    height: "16px",
    pointerEvents: "none",
    position: "absolute",
    right: "1em",
    top: "50%",
    transform: "translate(0, -50%) scale(1, -1)",
    width: "16px",
    zIndex: 1,
  },
});

type Props = {
  children: React.ReactNode;
} & React.ComponentProps<typeof SelectControl>;

const Select = React.forwardRef(
  ({ children, ...props }: Props, ref: React.Ref<HTMLSelectElement>) => (
    <SelectWrapper>
      <SelectControl {...props} ref={ref}>
        {children}
      </SelectControl>
    </SelectWrapper>
  ),
);

export { Select };
