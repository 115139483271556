import { Theme } from "@emotion/react";
import styled from "@emotion/styled";
import { Tag } from "./Tag";

const reviewTagCSS = (theme: Theme) =>
  ({
    alignItems: "center",
    background: theme.colors.bgLight,
    borderRadius: theme.borderRadius.sm,
    border: 0,
    color: theme.colors.fg,
    display: "inline-flex",
    fontSize: theme.fontSizes.xxxs,
  }) as const;

const variants = {
  founder: "accent3LL",
  community: "accent2LL",
  roadmap: "accent4LL",
  art: "accent1LL",
  default: "fg10",
};

interface ReviewTagProps {
  count?: number;
  variant: keyof typeof variants;
}

const ReviewTag = styled(Tag)<ReviewTagProps>(({ variant, theme }) => ({
  ...reviewTagCSS(theme),
  backgroundColor: theme.colors[variants[variant]],
}));

export { ReviewTag, reviewTagCSS };
