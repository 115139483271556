import * as React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Container,
  IconHugLogo,
  Link,
  ScreenReaderText,
  Stack,
  Text,
} from "atoms";
import { Head } from "features/misc";

type AuthWrapperProps = {
  title: string;
  children: React.ReactNode;
  header: React.ReactNode;
};
function AuthWrapper({ title, header, children }: AuthWrapperProps) {
  return (
    <main>
      <Head title={title} />

      <Container width="xs">
        <FormBox>
          <Content gap="md">
            <Header gap="sm">
              <Link to="/">
                <IconHugLogo
                  size="66px"
                  aria-hidden="true"
                  style={{ display: "block", margin: "1rem auto" }}
                />
                <ScreenReaderText>Home</ScreenReaderText>
              </Link>

              {header}
            </Header>

            {children}
          </Content>
        </FormBox>

        <Text size="xxs" textAlign="center">
          By signing in or connecting a wallet, you&nbsp;agree to&nbsp;our{" "}
          <Link to="/terms-of-service" target="_blank">
            Terms&nbsp;of&nbsp;Service
          </Link>
          &nbsp;and&nbsp;
          <Link to="/privacy-policy" target="_blank">
            Privacy&nbsp;Policy
          </Link>
          .
        </Text>
      </Container>
    </main>
  );
}

const FormBox = styled(Box)(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  margin: "2rem 0 1rem",
  "&,&+*": {
    position: "relative",
    zIndex: 2,
  },
}));

const Header = styled(Stack)({
  textAlign: "center",
});

const Content = styled(Stack)(({ theme }) => ({
  marginBottom: theme.spacing.lg,
  marginTop: theme.spacing.sm,
}));

export { AuthWrapper };
