import { css, Theme } from "@emotion/react";

import atkinson from "../assets/fonts/Atkinson-Hyperlegible-Regular.woff";
import atkinson2 from "../assets/fonts/Atkinson-Hyperlegible-Regular.woff2";
import atkinsonEOT from "../assets/fonts/Atkinson-Hyperlegible-Regular.eot";
import atkinsonTTF from "../assets/fonts/Atkinson-Hyperlegible-Regular.ttf";
import atkinsonItal from "../assets/fonts/Atkinson-Hyperlegible-Italic.woff";
import atkinsonItal2 from "../assets/fonts/Atkinson-Hyperlegible-Italic.woff2";
import atkinsonItalEOT from "../assets/fonts/Atkinson-Hyperlegible-Italic.eot";
import atkinsonItalTTF from "../assets/fonts/Atkinson-Hyperlegible-Italic.ttf";
import atkinsonBold from "../assets/fonts/Atkinson-Hyperlegible-Bold.woff";
import atkinsonBold2 from "../assets/fonts/Atkinson-Hyperlegible-Bold.woff2";
import atkinsonBoldEOT from "../assets/fonts/Atkinson-Hyperlegible-Bold.eot";
import atkinsonBoldTTF from "../assets/fonts/Atkinson-Hyperlegible-Bold.ttf";
import atkinsonBoldItal from "../assets/fonts/Atkinson-Hyperlegible-BoldItalic.woff";
import atkinsonBoldItal2 from "../assets/fonts/Atkinson-Hyperlegible-BoldItalic.woff2";
import atkinsonBoldItalEOT from "../assets/fonts/Atkinson-Hyperlegible-BoldItalic.eot";
import atkinsonBoldItalTTF from "../assets/fonts/Atkinson-Hyperlegible-BoldItalic.ttf";
import gtWalsheimCB from "../assets/fonts/GTWalsheim-CondensedBold.woff";
import gtWalsheimCB2 from "../assets/fonts/GTWalsheim-CondensedBold.woff2";
import brush1 from "../assets/images/hug-brush-1.svg";
import brush2 from "../assets/images/hug-brush-2.svg";
import brush3 from "../assets/images/hug-brush-3.svg";
import brush4 from "../assets/images/hug-brush-4.svg";
import { hexToRGB, opaqueHexOpacity } from "./styleUtils";

const BLACK = "#262626";
const WHITE = "#ffffff";

const BACKGROUND = WHITE;
const FOREGROUND = BLACK;

const ACCENT1 = "#54AFC0"; // blue
const ACCENT1L = "#A9D7DF";
const ACCENT1LL = "#D6ECF0";
const ACCENT2 = "#4B3E68"; // purple
const ACCENT2L = "#D3CAE9";
const ACCENT2LL = "#EAE5F3";
const ACCENT3 = "#E5B63F"; // yellow
const ACCENT3L = "#EEC96B";
const ACCENT3LL = "#F7E9C5";
const ACCENT4 = "#D3462E"; // red
const ACCENT4L = "#EE7661";
const ACCENT4LL = "#F7DED9";

// red for acquisition banner
const ACCENT5 = "#CE442C";

const SUCCESS = "#6ec97b";
const SUCCESSALT = "#6DBF7B";
const ERROR = ACCENT4;
const INFO = ACCENT1;
const WARNING = ACCENT3;

const ORANGE = "#f85a17";
const ORANGEDARK = "#B83700";
const ORANGELIGHT = "#FFBC9D";
const ORANGELIGHTER = "#FFF2EC";
const YELLOW = "#FFEE9B";
const YELLOWLIGHT = "#FBF2C3";
const YELLOWLIGHTER = "#FFFAE2";
const YELLOWDARK = "#E7C000";
const GREEN = "#ceecb5";
const GREENDARK = "#84AA62";
const GREENLIGHT = "#ECFADE";
const GREENLIGHTEST = "#E2F4E5";
const PURPLE = "#cca2f8";
const PURPLEDARK = "#C17AFF";
const PURPLELIGHT = "#E3C9FF";
const PINK = "#f7cfda";
const PINKLIGHT = "#FDF3F5";
const PINKDARK = "#ED91AA";

const themePrototype: Theme = {
  colors: {
    black: BLACK,
    white: WHITE,
    //
    fg: FOREGROUND,
    bg: BACKGROUND,
    //
    accent1: ACCENT1,
    accent1L: ACCENT1L,
    accent1LL: ACCENT1LL,
    accent2: ACCENT2,
    accent2L: ACCENT2L,
    accent2LL: ACCENT2LL,
    accent3: ACCENT3,
    accent3L: ACCENT3L,
    accent3LL: ACCENT3LL,
    accent4: ACCENT4,
    accent4L: ACCENT4L,
    accent4LL: ACCENT4LL,
    accent5: ACCENT5,
    //
    success: SUCCESS,
    successL: opaqueHexOpacity(SUCCESS, WHITE, 0.2),
    successD: opaqueHexOpacity(SUCCESS, BLACK, 0.45),
    successDAlt: SUCCESSALT,
    error: ERROR,
    errorL: opaqueHexOpacity(ERROR, WHITE, 0.2),
    errorD: opaqueHexOpacity(ERROR, BLACK, 0.7),
    info: INFO,
    infoL: opaqueHexOpacity(INFO, WHITE, 0.25),
    infoD: opaqueHexOpacity(INFO, BLACK, 0.4),
    warning: WARNING,
    warningL: opaqueHexOpacity(WARNING, WHITE, 0.25),
    warningD: opaqueHexOpacity(WARNING, BLACK, 0.4),
    // grays
    gray80: "#535353",
    gray60: "#7f7f7f",
    gray30: "#a8a8a8",
    gray20: "#d8d8d8",
    gray10: "#ebebeb",
    gray05: "#f2f2f2",
    //
    black90: opaqueHexOpacity(BLACK, WHITE, 0.95),
    black80: opaqueHexOpacity(BLACK, WHITE, 0.85),
    black70: opaqueHexOpacity(BLACK, WHITE, 0.72),
    black60: opaqueHexOpacity(BLACK, WHITE, 0.54),
    black30: opaqueHexOpacity(BLACK, WHITE, 0.32),
    black20: opaqueHexOpacity(BLACK, WHITE, 0.18),
    black10: opaqueHexOpacity(BLACK, WHITE, 0.1),
    black05: opaqueHexOpacity(BLACK, WHITE, 0.06),
    //
    white90: opaqueHexOpacity(WHITE, BLACK, 0.95),
    white80: opaqueHexOpacity(WHITE, BLACK, 0.85),
    white70: opaqueHexOpacity(WHITE, BLACK, 0.72),
    white60: opaqueHexOpacity(WHITE, BLACK, 0.54),
    white30: opaqueHexOpacity(WHITE, BLACK, 0.32),
    white20: opaqueHexOpacity(WHITE, BLACK, 0.18),
    white10: opaqueHexOpacity(WHITE, BLACK, 0.1),
    white05: opaqueHexOpacity(WHITE, BLACK, 0.06),
    white03: opaqueHexOpacity(WHITE, BLACK, 0.04),
    white01: opaqueHexOpacity(WHITE, BLACK, 0.02),
    //
    fg90: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.95),
    fg80: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.85),
    fg70: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.705),
    fg60: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.54),
    fg30: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.32),
    fg20: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.18),
    fg10: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.1),
    fg05: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.06),
    fg03: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.04),
    fg01: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.02),
    //
    bg90: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.95),
    bg80: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.85),
    bg70: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.705),
    bg60: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.54),
    bg30: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.32),
    bg20: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.18),
    bg10: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.1),
    bg05: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.06),
    bg03: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.04),
    bg01: opaqueHexOpacity(BACKGROUND, FOREGROUND, 0.02),
    //
    transparent: "rgb(0 0 0 / 0)",
    //
    bodyColor: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.75),
    displayColor: FOREGROUND,
    linkColor: FOREGROUND,
    linkHoverColor: opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.75),
    // semantic names
    // bg: YELLOWLIGHT,
    // fg: BLACK,
    bgLight: BACKGROUND,
    primary: ORANGE,
    accent: YELLOW,
    // straight names,
    orange: ORANGE,
    orangeLight: ORANGELIGHT,
    orangeLighter: ORANGELIGHTER,
    orangeDark: ORANGEDARK,
    yellow: YELLOW,
    yellowDark: YELLOWDARK,
    yellowLight: YELLOWLIGHT,
    yellowLighter: YELLOWLIGHTER,
    green: GREEN,
    greenDark: GREENDARK,
    greenLight: GREENLIGHT,
    greenLightest: GREENLIGHTEST,
    purple: PURPLE,
    purpleDark: PURPLEDARK,
    purpleLight: PURPLELIGHT,
    pink: PINK,
    pinkLight: PINKLIGHT,
    pinkDark: PINKDARK,
    red: ORANGEDARK,
  },
  fonts: {
    display: {
      fontFamily:
        "GT Walsheim, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
      fontStyle: "normal",
    },
    body: {
      fontFamily:
        "Atkinson, -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif",
      fontStyle: "normal",
    },
  },
  fontSizes: {
    h1: "var(--step-5)", // 2.73rem |  ~60px to ~44px
    h2: "var(--step-4)", // 1.6rem |  ~35px to ~26px
    h3: "var(--step-2)", // 1.18rem |  ~26px to ~19px
    xxxxxl: "var(--step-7)",
    xxxxl: "var(--step-6)",
    xxxl: "var(--step-5)",
    xxl: "var(--step-4)",
    xl: "var(--step-3)", // 1.27em |  ~28px to ~20px
    lg: "var(--step-2)", // 1.18rem |  ~26px to ~19px
    md: "var(--step-1)", // 1.1rem |  ~24px to ~18px
    sm: "var(--step-0)", // 1rem | Root size based on screen width: 22px max to 16px min
    xs: "var(--step--1)", // 0.8rem" | ~18px to ~13px
    xxs: "var(--step--2)", // 0.7rem |  ~17px to ~12px
    xxxs: "var(--step--3)",
    xxxxs: "var(--step--4)",
  },
  spacing: {
    xxxxs: ".05rem",
    xxxs: ".0625rem",
    xxs: "0.125rem",
    xs: "0.25rem",
    sm: "0.5rem",
    md: "1rem",
    lg: "2rem",
    xl: "3.2rem",
    xxl: "5rem",
    xxxl: "8rem",
    gutter: "var(--site-gutter)",
    gap: "var(--site-gap)",
    letterSpacing: ".02em",
    stack: {
      0: "0",
      xxs: ".25",
      xs: ".5",
      sm: "1",
      md: "2.25",
      lg: "3.5",
      xl: "5",
      xxl: "7.5",
    },
  },
  gradients: {
    radial1: `radial-gradient(circle at 55% 90%, ${PURPLE}, rgba(255, 255, 255, 0) 30%),
        radial-gradient(circle at 5% 15%, ${YELLOW} 10%, rgba(255, 255, 255, 0) 40%),
        radial-gradient(circle at 95% 35%, ${GREEN} 5%, rgba(255, 255, 255, 0) 25%)
        ${PINK}`,
    radial2: `radial-gradient(circle farthest-corner at top center, ${PINK} 5rem, rgba(255, 255, 255, 0) 30rem),
        radial-gradient(circle farthest-corner at top center, ${YELLOW} 50rem, rgba(255, 255, 255, 0) 75rem)
        ${GREEN}`,
    radial3: `radial-gradient(circle at 55% 5%, ${YELLOW} 5%, rgba(255, 255, 255, 0) 20%),
        radial-gradient(circle at 95% 85%, ${GREEN} 5%, rgba(255, 255, 255, 0) 20%),
        radial-gradient(circle at 5% 75%, ${PURPLE} 15%, rgba(255, 255, 255, 0) 40%)
        ${PINK}`,
    radial4: `radial-gradient(circle farthest-corner at 50% -10%, ${PINK} 0rem, rgba(255, 255, 255, 0) 30%),radial-gradient(circle farthest-corner at top center, ${YELLOW} 50rem, rgba(255, 255, 255, 0) 75rem)`,
    radial5: `radial-gradient(circle farthest-corner at 50% 3%, ${hexToRGB(
      PURPLE,
      0.8,
    )} 0rem, rgba(255, 255, 255, 0) 15rem)`,
    linear: `linear-gradient(to right, ${PURPLELIGHT} 10%, ${PINK} 90%)`,
    linear1: `linear-gradient(to right, ${PURPLELIGHT} 10%, ${PINK} 90%)`,
    linear2: `linear-gradient(to right, ${YELLOW} 10%, ${GREEN} 90%)`,
    linear3: `linear-gradient(to right, ${PURPLELIGHT} 10%, ${PINK} 50%, ${GREEN} 90%)`,
    linear4: `linear-gradient(to right, ${ORANGELIGHT} 10%, ${PINK} 90%)`,
    tagFounder: `linear-gradient(70deg, ${PURPLELIGHT} 0%, ${PINK} 100%)`,
    tagMission: `linear-gradient(70deg, ${GREEN} 0%, ${YELLOW} 100%)`,
    tagMedium: `linear-gradient(70deg, ${ORANGELIGHT} 0%, ${PINK} 100%)`,
  },
  boxShadow: {
    light: `0 0 10px rgb(0 0 0 / 10%), 0 0 3px rgb(0 0 0 / 3%)`,
    dark: `0 3px 5px 0px rgb(0 0 0 / 10%), 0 3px 20px rgb(0 0 0 / 20%), 0 0 3px rgb(0 0 0 / 3%)`,
  },
  borderRadius: {
    xs: "4px",
    sm: "4px", // "8px",
    md: "4px", // "14px",
    lg: "20px", // "20px",
    round: 9999,
  },
  breakpoints: {
    maxXs: `@media (max-width: 419px)`,
    xs: `@media (min-width: 420px)`,
    maxTablet: `@media (max-width: 599px)`,
    tablet: `@media (min-width: 600px)`,
    maxDesktop: `@media (max-width: 830px)`,
    desktop: `@media (min-width: 831px)`,
    maxDesktopMedium: `@media (max-width: 1023px)`,
    desktopMedium: `@media (min-width: 1024px)`,
    desktopLarge: `@media (min-width: 1140px)`,
    desktopXLarge: `@media (min-width: 1440px)`,
  },
  patterns: {
    brush1: {
      backgroundImage: `url('${brush1}')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "norepeat",
    },
    brush2: {
      backgroundImage: `url('${brush2}')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "norepeat",
    },
    brush3: {
      backgroundImage: `url('${brush3}')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "norepeat",
    },
    brush4: {
      backgroundImage: `url('${brush4}')`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "norepeat",
    },
  },
  global: css`
    @font-face {
      font-family: "Atkinson";
      src: url("${atkinsonEOT}");
      src:
        url("${atkinsonEOT}?#iefix") format("embedded-opentype"),
        url("${atkinson2}") format("woff2"),
        url("${atkinson}") format("woff"),
        url("${atkinsonTTF}") format("truetype");
      font-display: swap;
      font-style: normal;
      font-weight: 400;
    }
    @font-face {
      font-family: "Atkinson";
      src: url("${atkinsonItalEOT}");
      src:
        url("${atkinsonItalEOT}?#iefix") format("embedded-opentype"),
        url("${atkinsonItal2}") format("woff2"),
        url("${atkinsonItal}") format("woff"),
        url("${atkinsonItalTTF}") format("truetype");
      font-display: swap;
      font-style: italic;
      font-weight: 400;
    }
    @font-face {
      font-family: "Atkinson";
      src: url("${atkinsonBoldEOT}");
      src:
        url("${atkinsonBoldEOT}?#iefix") format("embedded-opentype"),
        url("${atkinsonBold2}") format("woff2"),
        url("${atkinsonBold}") format("woff"),
        url("${atkinsonBoldTTF}") format("truetype");
      font-display: swap;
      font-style: normal;
      font-weight: bold;
    }
    @font-face {
      font-family: "Atkinson";
      src: url("${atkinsonBoldItalEOT}");
      src:
        url("${atkinsonBoldItalEOT}?#iefix") format("embedded-opentype"),
        url("${atkinsonBoldItal2}") format("woff2"),
        url("${atkinsonBoldItal}") format("woff"),
        url("${atkinsonBoldItalTTF}") format("truetype");
      font-display: swap;
      font-style: italic;
      font-weight: bold;
    }
    @font-face {
      font-family: "GT Walsheim";
      src: url("${gtWalsheimCB2}") format("woff2");
      src: url("${gtWalsheimCB}") format("woff");
      font-display: swap;
      font-style: normal;
      font-weight: 800;
    }
    @font-face {
      font-family: "GT Walsheim";
      src: url("${gtWalsheimCB2}") format("woff2");
      src: url("${gtWalsheimCB}") format("woff");
      font-display: swap;
      font-style: italic;
      font-weight: 800;
    }
    html,
    body {
      background: ${BACKGROUND};
      color: ${opaqueHexOpacity(FOREGROUND, BACKGROUND, 0.75)};
    }
  `,
};

export { themePrototype };
