import * as React from "react";
import { Select } from "atoms";

const SelectLinkType = React.forwardRef<HTMLSelectElement>(
  ({ ...props }, ref) => (
    <Select {...props} ref={ref}>
      <option value="website">Personal website</option>
      <option value="instagram">Instagram</option>
      <option value="twitter">Twitter</option>
      <option value="youtube">YouTube</option>
      <option value="tiktok">TikTok</option>
      <option value="linkedin">LinkedIn</option>
      <option value="foundation">Foundation</option>
      <option value="openSea">OpenSea</option>
      <option value="objkt">Objkt</option>
      <option value="other">Other</option>
    </Select>
  ),
);

export { SelectLinkType };
