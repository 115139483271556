import styled from "@emotion/styled";
import { CopyToClipboard, Link, Stack, Text } from "atoms";
import { pluralize } from "utils/text";
import { useReferredApplications } from "../api/useReferredApplications";

const getInviteUrl = (id: string) => {
  const inviteUrl = `${window.location.origin}/artist-application?code=${id}`;
  return {
    inviteUrl,
    inviteMessage: `I'd love to invite you to join @thehugxyz's global creative community to start showcasing and selling your work! Can't wait to connect with you there.`,
  };
};

function ArtistInvitesSidebar({ id }: { id: string }) {
  const { data } = useReferredApplications();
  const totalAccepted = data?.totalAccepted ?? 0;
  const totalApplications = data?.totalApplications ?? 0;

  const { inviteUrl } = getInviteUrl(id);

  return (
    <Stack gap="sm">
      <Stack gap="xs">
        <CopyInviteButton
          buttonText={
            <Text size="xxxxs" style={{ margin: 0 }}>
              Copy invite link
            </Text>
          }
          value={inviteUrl}
          sendShareEvent
        />
        <Text size="xxs">
          <strong>{totalApplications} </strong>
          <span>{pluralize(totalApplications, "artist", null, false)}</span>
          <span> applied</span>
        </Text>
        <Text size="xxs">
          <strong>{totalAccepted} </strong>
          <span>
            {pluralize(totalAccepted, "artist application", null, false)}
          </span>
          <span> accepted</span>
        </Text>
      </Stack>

      <Text size="xxs">
        <Link to="/artist-invites">View all invites</Link>
      </Text>
    </Stack>
  );
}

const CopyInviteButton = styled(CopyToClipboard)(({ theme }) => ({
  marginLeft: "auto",
  marginRight: "auto",
  textAlign: "center",
  display: "inline-block",
  "clipboard-copy": {
    padding: "0.45em 1.2em 0.4em",
    border: `2px solid ${theme.colors.fg}`,
    borderRadius: theme.borderRadius.round,
    backgroundColor: theme.colors.fg,
    color: theme.colors.bg,
    fontSize: theme.fontSizes.xs,
    display: "flex",
  },
  "&:hover clipboard-copy, &:focus clipboard-copy": {
    backgroundColor: theme.colors.bg,
    color: theme.colors.fg,
  },
  svg: {
    order: 99,
  },
}));

export { ArtistInvitesSidebar, CopyInviteButton, getInviteUrl };
