import * as React from "react";
import { constants } from "config";
import { useAuth } from "features/auth";

type SocketContextValue = {
  ws: WebSocket | undefined;
  isConnected: boolean;
};

const SocketContext = React.createContext<SocketContextValue>({
  ws: undefined,
  isConnected: false,
});

interface SocketProviderProps {
  children: React.ReactNode;
}

function SocketProvider({ children }: SocketProviderProps) {
  const { user, refreshAuth } = useAuth();

  const ws = React.useRef<SocketContextValue["ws"]>(undefined);
  const [isConnected, setIsConnected] =
    React.useState<SocketContextValue["isConnected"]>(false);

  React.useEffect(() => {
    if (user && !ws.current) {
      const socket = new WebSocket(constants.wsUrl);

      socket.addEventListener("open", () => {
        setIsConnected(true);
        socket.send(user.id);
      });
      socket.addEventListener("message", (event) => {
        const data = JSON.parse(event.data);
        if (data.type === "TOKEN") {
          refreshAuth({ jwt: data.jwt, user: data.user });
        }
      });
      socket.addEventListener("error", (event) => {
        // eslint-disable-next-line
        console.log(`WS ERROR: ${JSON.stringify(event, null, 2)}`);
      });
      socket.addEventListener("close", (event) => {
        // eslint-disable-next-line
        console.log(`WS CLOSE: ${JSON.stringify(event, null, 2)}`);
        setIsConnected(false);
      });

      ws.current = socket;

      return isConnected ? () => socket.close() : noop;
    }
    return noop;
  }, [user, refreshAuth, isConnected]);

  const value = React.useMemo(
    () => ({
      ws: ws.current,
      isConnected,
    }),
    [isConnected],
  );

  return (
    <SocketContext.Provider value={value}>{children}</SocketContext.Provider>
  );
}

const useSocket = () => {
  const context = React.useContext(SocketContext);
  if (!context) {
    throw new Error(
      "useSocket() may be used only within the context of a <SocketProvider> component",
    );
  }
  return context;
};

/**
 * Connect a new handler to websocket message event
 *
 * Takes a string to only run on specified `event.data.type` messages and a callback function which
 * will be given the entire `event.data` object. If the callback will change because of state or
 * props, make sure you wrap it in a `React.useCallback()` hook and this will cleanup the prior
 * handler and add the new handler.
 *
 * @example
 * ```
 * const { user } = useAuth();
 * // create callback specific to user
 * const handler = React.useCallback(data => doSomething(data, user), [user]);
 * useSocketMessageHandler("TEST", handler);
 * ```
 */
function useSocketMessageHandler<T>(type: string, cb: (data: T) => void) {
  const { ws, isConnected } = useSocket();

  React.useEffect(() => {
    if (ws && isConnected) {
      const handleMessage = (message: MessageEvent<string>) => {
        const data = JSON.parse(message.data);

        if (data.type === type) {
          cb(data as T);
        }
      };

      ws.addEventListener("message", handleMessage);

      return () => {
        ws.removeEventListener("message", handleMessage);
      };
    }
    return noop;
  }, [ws, isConnected, type, cb]);
}

export { SocketProvider, useSocket, useSocketMessageHandler };
