import * as React from "react";
import * as langs from "../langs";

type I18nMessageFn = (...x: string[]) => string;
type I18nMessage = string | I18nMessageFn;
interface I18nLang {
  [key: string]: I18nMessage;
}

const I18nContext = React.createContext<I18nLang | null>(null);

function I18nProvider({
  children,
}: React.PropsWithChildren<Record<string, unknown>>) {
  // TODO: read browser locale or cookies set from API Accept-Language
  const locale = "en";

  // Use language file from locale defaulting to English if a locale isn't set or there is no translation for it
  const lang = langs[locale] ?? langs.en;

  return <I18nContext.Provider value={lang}>{children}</I18nContext.Provider>;
}

const useI18n = () => {
  const context = React.useContext(I18nContext);

  if (!context) {
    throw new Error(
      "useI18n() may be used only within the context of a <I18nProvider> component",
    );
  }

  return context;
};

export { I18nProvider, useI18n };
