type Dimensions = { height: number; width: number };

/**
 * Load image from source URL and return promise that resolves in its dimensions
 */
const getDimensionsFromUrl = (src?: string) =>
  new Promise<Dimensions>((resolve, reject) => {
    if (!src) {
      resolve({ height: 0, width: 0 });
    } else {
      const img = new Image();
      img.onload = () => {
        resolve({
          height: img.height,
          width: img.width,
        });
      };
      img.onerror = () => {
        reject(new Error("Could not get dimensions from URL"));
      };
      img.src = src;
    }
  });

export { getDimensionsFromUrl };
