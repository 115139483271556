const shopOnChainClaimKeys = {
  base: ["artist-on-chain-contracts"] as const,
  details: () => [...shopOnChainClaimKeys.base, "detail"] as const,
  detail: (artistId?: string, fulfillmentLineItemId?: string) =>
    [
      ...shopOnChainClaimKeys.details(),
      artistId,
      fulfillmentLineItemId,
    ] as const,
};

export { shopOnChainClaimKeys };
