import { parseGid } from "@shopify/hydrogen-react";
import { isProduction } from "config";

const PRODIGI_ADMIN = isProduction
  ? "https://dashboard.prodigi.com"
  : "https://sandbox-beta-dashboard.pwinty.com";

const SHOPIFY_ADMIN = isProduction
  ? "https://admin.shopify.com/store/thehugxyz"
  : "https://admin.shopify.com/store/hug-dev";

/**
 * Collection of client-side route factories
 */
const paths = {
  // ------------
  // ARTIST PATHS
  // ------------
  artistProfile: (artistId: string) => `/artists/${artistId}`,
  artistProfileEdit: (artistId: string) =>
    `${paths.artistProfile(artistId)}/edit`,
  artistProfileEditBeta: (artistId: string) =>
    `${paths.artistProfile(artistId)}/profile/edit`,
  artistFeaturedEdit: (artistId: string) =>
    `${paths.artistProfile(artistId)}/featured/edit`,
  artistFollowers: (artistId: string) =>
    `${paths.artistProfile(artistId)}/followers`,

  // -------------------
  // Artist Portfolio
  // -------------------
  artistPortfolio: (artistId: string) =>
    `${paths.artistProfile(artistId)}/portfolio`,
  artistPortfolioEdit: (artistId: string) =>
    `${paths.artistPortfolio(artistId)}/edit`,
  artistPortfolioReorder: (artistId: string) =>
    `${paths.artistPortfolio(artistId)}/reorder`,
  artistGallery: (artistId: string, galleryId: string) =>
    `${paths.artistPortfolio(artistId)}/gallery/${galleryId}`,
  artistGalleryCreate: (artistId: string) =>
    `${paths.artistPortfolio(artistId)}/gallery/new`,
  artistGalleryEdit: (artistId: string, galleryId: string) =>
    `${paths.artistGallery(artistId, galleryId)}/edit`,
  artistGalleryReorder: (artistId: string, galleryId: string) =>
    `${paths.artistGallery(artistId, galleryId)}/reorder`,
  artistGalleryItem: (artistId: string, itemId: string) =>
    `${paths.artistPortfolio(artistId)}/item/${itemId}`,
  artistGalleryItemEdit: (artistId: string, itemId: string) =>
    `${paths.artistGalleryItem(artistId, itemId)}/edit`,

  // -------------------
  // Artist Profile Tabs
  // -------------------
  artistAbout: (artistId: string) => `${paths.artistProfile(artistId)}/about`,
  artistAbout_bio: (artistId: string) => `${paths.artistAbout(artistId)}#bio`,
  artistReviews: (artistId: string) =>
    `${paths.artistProfile(artistId)}/reviews`,
  artistReviewCreate: (artistId: string) =>
    `${paths.artistProfile(artistId)}/review`,
  artistUpdates: (artistId: string) =>
    `${paths.artistProfile(artistId)}/updates`,
  artistUpdateDetails: (artistId: string, updateId: string) =>
    `${paths.artistUpdates(artistId)}/${updateId}`,
  // TODO: change to /artists/:id/updates/new to match our pattern?
  artistUpdateCreate: (artistId: string) =>
    `${paths.artistProfile(artistId)}/create-update`,
  artistUpdateCreateForProduct: (artistId: string, productHandle: string) =>
    `${paths.artistUpdateCreate(artistId)}?product=${productHandle}`,

  // ---------------
  // SHOP PATHS
  // ---------------
  shopWaitlist: "https://go.thehug.xyz/storewaitlist",
  shop: "/shop",
  shopCart: "/shop/cart",
  shopTerms: "/seller-terms",
  shopHelp: "https://help.thehug.xyz",
  shopRequestShipItYourselfAccess: "https://go.thehug.xyz/siy",
  shopHelpEarningsPayments:
    "https://help.thehug.xyz/when-and-how-do-i-get-paid",
  shopTaxFormUS: "https://app.hellosign.com/s/61hrkg95",
  shopTaxFormInternational: "https://app.hellosign.com/s/ZekL44Dv",
  shopArtists: () => `${paths.shop}/artists`,
  shopAllProducts: () => `${paths.shop}/all`,
  shopCollection: (handle: string) => `${paths.shop}/collection/${handle}`,

  // External Admin Links
  prodigiAdminOrder: (orderId: string) =>
    `${PRODIGI_ADMIN}/orders/${orderId.replace("ord_", "")}/detail`,
  shopifyAdminOrder: (orderId: string) =>
    `${SHOPIFY_ADMIN}/orders/${parseGid(orderId).id}`,

  // ---------------
  // ARTIST SHOP PATHS
  // ---------------
  artistShop: (artistId: string) => `${paths.artistProfile(artistId)}/shop`,
  artistShopProduct: (artistId: string, handle: string) =>
    `${paths.artistShop(artistId)}/${handle}`,
  artistShopProductVariant: (
    artistId: string,
    handle: string,
    options: [string, string][] = [],
  ) =>
    `${paths.artistShopProduct(artistId, handle)}?${new URLSearchParams(options)}`,
  artistShopEdit: (artistId: string) =>
    `${paths.artistProfile(artistId)}/shop/edit`,
  artistShopEditOrders: (artistId: string) =>
    `${paths.artistShopEdit(artistId)}/orders`,
  artistShopEditInventory: (artistId: string) =>
    `${paths.artistShopEdit(artistId)}/inventory`,
  artistShopProductCreate: (artistId: string) =>
    `${paths.artistShopEdit(artistId)}/products`,
  artistShopProductEdit: (artistId: string, productId: string) =>
    `${paths.artistShopProductCreate(artistId)}/${productId}`,
  artistShopInventory: (artistId: string) =>
    `${paths.artistShopEdit(artistId)}/inventory`,
  artistProductBuilderDisabled: (artistId: string) =>
    `${paths.artistShopEdit(artistId)}/builder-disabled`,

  // ---------------
  // ADMIN SHOP PATHS
  // ---------------
  adminShopArtistAccess: () => `/admin/shop/artist-access`,
  adminShopOrders: () => `/admin/shop/orders`,
  adminShopProducts: () => `/admin/shop/products`,
  adminShopTaxDocuments: () => `/admin/shop/tax-documents`,

  // ---------------
  // ARTIST INVITES
  // ---------------
  artistInvite: () => "/artist-invites",
  artistClaimInvitation: (artistId: string) => `/artist-invite/${artistId}`,

  // ----------------
  // COLLECTIVE PATHS
  // ----------------
  collectives: () => "/collectives",
  collectiveDetails: (collectiveId: string) =>
    `${paths.collectives()}/${collectiveId}`,
  collectiveEdit: (collectiveId: string) =>
    `${paths.collectiveDetails(collectiveId)}/edit`,
  collectiveUpdates: (collectiveId: string) =>
    `${paths.collectiveDetails(collectiveId)}/updates`,
  badges: () => "/badges",
  badgeDetails: (badgeId: string) => `${paths.badges()}/${badgeId}`,

  // ---------------
  // OPEN CALL PATHS
  // ---------------
  openCalls: () => "/open-calls",
  openCallDetails: (openCallId: string) => `${paths.openCalls()}/${openCallId}`,
  openCallCurate: (openCallId: string) =>
    `${paths.openCallDetails(openCallId)}/curate`,
  openCallSubmit: (openCallId: string) =>
    `${paths.openCallDetails(openCallId)}/submit`,
  openCallSubmission: (openCallId: string, submissionId: string) =>
    `${paths.openCallDetails(openCallId)}/submissions/${submissionId}`,
  openCallSubmissionEdit: (openCallId: string, submissionId: string) =>
    `${paths.openCallSubmission(openCallId, submissionId)}/edit`,
  openCallVote: (openCallId: string) =>
    `${paths.openCallDetails(openCallId)}/vote`,

  // ---------------
  // OPEN CALL PATHS
  // ---------------
  artistApplication: () => "/artist-application",
  artistProfileBenefits: () => "/artist-profile-benefits",
};

/**
 * Prefix "/admin" to route factory result
 */
/* eslint-disable @typescript-eslint/no-explicit-any */
const admin =
  <T extends (...args: any[]) => string>(fn: T) =>
  (...args: Parameters<T>) =>
    `/admin${fn(...args)}`;
/* eslint-enable @typescript-eslint/no-explicit-any */

/**
 * Collection of client-side route factories for Admin specific pages
 */
const adminPaths = {
  // -----------------
  // APPLICATION PATHS
  // -----------------
  applications: () => "/admin/applications",
  applicationDetails: (appId: string) =>
    `${adminPaths.applications()}/${appId}`,
  applicationEdit: (appId: string) =>
    `${adminPaths.applicationDetails(appId)}/edit`,
  // ----------------
  // COLLECTIVE PATHS
  // ----------------
  collectives: admin(paths.collectives),
  collectiveCreate: () => `${adminPaths.collectives()}/new`,
  collectiveDetails: admin(paths.collectiveDetails),
  collectiveEdit: admin(paths.collectiveEdit),
  // ---------------
  // OPEN CALL PATHS
  // ---------------
  openCalls: admin(paths.openCalls),
  openCallCreate: () => `${adminPaths.openCalls()}/new`,
  openCallDetails: admin(paths.openCallDetails),
  openCallEdit: (openCallId: string) =>
    `${adminPaths.openCallDetails(openCallId)}/edit`,
  openCallExport: (openCallId: string) =>
    `${adminPaths.openCallDetails(openCallId)}/export`,
  // -----------
  // OTHER PATHS
  // -----------
  createUpdate: () => "/admin/create-update",
  raffles: () => "/admin/raffles",
  reports: () => "/admin/reports",
};

// -----------
// FULL URLS
// -----------

const ROOT_PATH = isProduction
  ? "https://thehug.xyz"
  : "https://thesecrethug.xyz";

const fullPath = (path: string) => `${ROOT_PATH}${path}`;

export { adminPaths, paths, fullPath };
