import { centerCrop as ricCenterCrop, makeAspectCrop } from "react-image-crop";

const centerCrop = (width: number, height: number, aspect: number) =>
  ricCenterCrop(
    makeAspectCrop(
      {
        unit: "%",
        width: 90,
      },
      aspect,
      width,
      height,
    ),
    width,
    height,
  );

export { centerCrop };
