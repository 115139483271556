import { useInfiniteQuery } from "react-query";
import { api } from "utils/api";
import { shopOrderKeys } from "./queryKeys";
import type { ShopOrdersSearchResponse } from "../types";
import { ShopOrdersSearchRequest } from "../types/shopOrderSearchRequest";

const getArtistOrders = (params: ShopOrdersSearchRequest) =>
  api.post<ShopOrdersSearchResponse>(`/shop/orders/artist/${params.artistId}`, {
    body: JSON.stringify(params),
  });

// Artist ID must be passed within params
const useArtistOrders = (params: ShopOrdersSearchRequest = {}) =>
  useInfiniteQuery(
    shopOrderKeys.list(params),
    ({ pageParam }) => getArtistOrders({ ...params, searchAfter: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.searchAfter,
      enabled: !!params.artistId,
    },
  );

export { useArtistOrders };
