import styled from "@emotion/styled";
import {
  Button,
  FlexRow,
  IconClose,
  LoadingEllipses,
  LoadingSpinner,
  Modal,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import { GenericImage } from "features/images";
import { useGalleryItem } from "../api/useGalleryItem";
import { useGalleryItemDelete } from "../api/useGalleryItemDelete";
import { useGalleryItemUpdate } from "../api/useGalleryItemUpdate";
import { useEditorContext } from "../utils/useEditorContext";

type RemoveItemFromGalleryProps = {
  galleryId: string;
  galleryTitle: string;
  close: VoidFunction;
};

function RemoveItemFromGallery({
  close,
  galleryId,
  galleryTitle,
}: RemoveItemFromGalleryProps) {
  const { artist, itemId, itemType } = useEditorContext();
  const artistId = artist.id;

  const { data, ...query } = useGalleryItem({ artistId, itemId });

  const deleteMutation = useGalleryItemDelete();
  const updateMutation = useGalleryItemUpdate(artistId, itemId);

  if (!itemId || !itemType) {
    throw new Error("Incorrect use of editor context");
  }

  const deleteItem = () =>
    deleteMutation.mutate({ artistId, itemId }, { onSuccess: close });

  const removeItem = !data
    ? noop
    : () =>
        updateMutation.mutate(
          {
            ...data,
            galleryFolderIds: data.galleryFolderIds.filter(
              (id) => id !== galleryId,
            ),
          },
          { onSuccess: close },
        );

  const isDeleting = deleteMutation.isLoading;
  const isRemoving = updateMutation.isLoading;
  const error = deleteMutation.error ?? updateMutation.error;

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <IconClose /> Remove From Gallery
        </Modal.Title>
      </Modal.Header>

      <Stack gap="sm">
        {query.isLoading && <LoadingSpinner />}
        <SGenericImage src={data?.image} sizes="280px" />
        <Text textAlign="center" bold>
          Are you sure you want to remove this item from {galleryTitle}?
        </Text>
        <Text textAlign="center">
          It will remain in your Portfolio unless deleted.
        </Text>
        <FlexRow gap="10px" justifyContent="center" flexDirection="column">
          {!isDeleting && (
            <Button onClick={removeItem} disabled={isRemoving}>
              {isRemoving ? (
                <>
                  Removing
                  <LoadingEllipses />
                </>
              ) : (
                "Remove From Gallery"
              )}
            </Button>
          )}
          {!isRemoving && (
            <Button
              variant="secondary"
              onClick={deleteItem}
              disabled={isDeleting}
            >
              {isDeleting ? (
                <>
                  Deleting
                  <LoadingEllipses />
                </>
              ) : (
                "Delete From Portfolio"
              )}
            </Button>
          )}
        </FlexRow>
      </Stack>

      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button onClick={close} variant="primary">
            Cancel
          </Button>

          {!!error && <UnexpectedError error={error} />}
        </FlexRow>
      </Modal.Footer>
    </>
  );
}

const SGenericImage = styled(GenericImage)(({ theme }) => ({
  width: "max(20vw, 250px)",
  height: "min(10vw, 250px)",
  maxHeight: 250,
  maxWidth: 250,
  margin: "0 auto",
  borderRadius: theme.borderRadius.sm,
  overflow: "hidden",
  img: {
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "100%",
    margin: "0 auto",
    borderRadius: theme.borderRadius.sm,
  },
}));

export { RemoveItemFromGallery };
