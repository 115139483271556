import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "utils/api";
import type { Image } from "features/images";
import { artistOnChainContractKeys } from "./queryKeys";
import type { ArtistOnChainContract } from "../types";

type ArtistOnChainContractResponse = {
  data: ArtistOnChainContract[];
};

type CreateArtistOnChainContractReq = {
  artistId: string;
  artistWallet: string;
  contractName: string;
  contractSymbol: string;
  description?: string;
  image?: Image;
};

const getArtistOnChainContracts = (artistId: string) =>
  api.get<ArtistOnChainContractResponse>(
    `/artists/${artistId}/shop/onChainContracts`,
  );

const postArtistOnChainContract = (formData: CreateArtistOnChainContractReq) =>
  api.post<ArtistOnChainContract>(
    `/artists/${formData.artistId}/shop/onChainContracts`,
    {
      body: JSON.stringify(formData),
    },
  );

const useArtistOnChainContracts = (artistId: string) =>
  useQuery(
    artistOnChainContractKeys.detail(artistId),
    () => getArtistOnChainContracts(artistId),
    {
      select: ({ data }) => data,
    },
  );

const useCreateArtistOnChainContracts = (artistId: string) => {
  const queryClient = useQueryClient();

  return useMutation(
    (formData: CreateArtistOnChainContractReq) =>
      postArtistOnChainContract({ ...formData, artistId }),
    {
      onSuccess: () => {
        queryClient.invalidateQueries(
          artistOnChainContractKeys.detail(artistId),
        );
      },
    },
  );
};

export {
  useArtistOnChainContracts,
  useCreateArtistOnChainContracts,
  type CreateArtistOnChainContractReq,
};
