import styled from "@emotion/styled";
import { FlexRow } from "./FlexRow";

type LoadingSpinnerProps = {
  text?: string;
};

function LoadingSpinner({ text }: LoadingSpinnerProps) {
  return (
    <LoadingScrim>
      <LoadingWrapper>
        {text ? (
          <LoadingText gap="2px" alignItems="baseline" flexWrap="nowrap">
            {text}
            <EllipsesDot>.</EllipsesDot>
            <EllipsesDot>.</EllipsesDot>
            <EllipsesDot>.</EllipsesDot>
          </LoadingText>
        ) : (
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="228"
            height="41"
            fill="none"
            viewBox="0 0 228 41"
          >
            <Letter>
              <path
                fill="#262626"
                d="M0 2.087h13.102v26.31h7.968v11.121H0V2.088Z"
              />
            </Letter>
            <Letter>
              <path
                fill="#262626"
                d="M28.564 35.561c-3.209-3.493-4.813-8.413-4.813-14.758 0-6.346 1.604-11.264 4.813-14.759C31.773 2.551 36.3.804 42.148.804c5.847 0 10.322 1.747 13.53 5.24 3.21 3.495 4.813 8.413 4.813 14.759 0 6.345-1.604 11.265-4.813 14.758-3.208 3.495-7.72 5.24-13.53 5.24s-10.375-1.747-13.584-5.24Zm10.027-8.528c.767 1.373 1.951 2.058 3.557 2.058 3.136 0 4.706-2.762 4.706-8.288 0-5.527-1.57-8.29-4.706-8.29-1.604 0-2.79.688-3.557 2.06-.766 1.373-1.15 3.448-1.15 6.23 0 2.781.384 4.856 1.15 6.23Z"
              />
            </Letter>
            <Letter>
              <path
                fill="#262626"
                d="M70.979 2.087h15.83l10.374 37.431H83.92l-.802-4.277h-8.45l-.801 4.277H60.603L70.98 2.088Zm10.428 24.33L78.894 13.1 76.38 26.416h5.027Z"
              />
            </Letter>
            <Letter>
              <path
                fill="#262626"
                d="M100.825 2.087H115.8c3.637 0 6.801.767 9.493 2.3 2.692 1.532 4.751 3.706 6.178 6.522 1.424 2.817 2.139 6.114 2.139 9.892 0 3.778-.713 7.13-2.139 9.945-1.427 2.817-3.486 4.983-6.178 6.497-2.692 1.517-5.875 2.273-9.546 2.273h-14.92V2.087h-.002Zm14.547 26.95c1.497 0 2.674-.676 3.529-2.032.855-1.354 1.283-3.422 1.283-6.202s-.428-4.847-1.283-6.203c-.855-1.354-2.032-2.031-3.529-2.031h-1.444v16.47h1.444v-.002Z"
              />
            </Letter>
            <Letter>
              <path
                fill="#262626"
                d="M137.518 2.087h13.103v37.431h-13.103V2.088Z"
              />
            </Letter>
            <Letter>
              <path
                fill="#262626"
                d="M156.186 2.087h11.819l7.273 16.684V2.087h12.139v37.431h-11.872l-7.272-16.576v16.576h-12.087V2.088Z"
              />
            </Letter>
            <Letter>
              <path
                fill="#262626"
                d="M195.954 35.48c-3.12-3.546-4.679-8.475-4.679-14.783 0-6.31 1.559-11.203 4.679-14.679 3.119-3.475 7.603-5.214 13.45-5.214 5.17 0 9.216 1.169 12.14 3.503 2.922 2.336 4.457 5.678 4.6 10.026H212.88c-.143-2.14-1.32-3.208-3.529-3.208-1.57 0-2.692.74-3.37 2.22-.677 1.479-1.015 3.752-1.015 6.817 0 3.529.365 6.088 1.096 7.674.731 1.587 1.898 2.38 3.503 2.38 1.069 0 1.952-.313 2.647-.936.696-.623 1.097-1.525 1.203-2.7h-3.37v-8.395h17.435v8.395h-1.818c-.249 4.635-1.817 8.163-4.706 10.588-2.888 2.424-6.775 3.636-11.658 3.636-5.775 0-10.224-1.772-13.344-5.32v-.003Z"
              />
            </Letter>
          </svg>
        )}
      </LoadingWrapper>
    </LoadingScrim>
  );
}

const LoadingScrim = styled.div({
  backdropFilter: "blur(1px)",
  backgroundColor: "rgb(255 255 255 / .25)",
  bottom: 0,
  left: 0,
  position: "fixed",
  right: 0,
  top: 0,
  zIndex: 10000,
  svg: {
    overflow: "visible",
    maxWidth: "40vw",
  },
});

const LoadingWrapper = styled.div(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.bg,
  borderRadius: theme.borderRadius.md,
  boxShadow: theme.boxShadow.light,
  display: "flex",
  justifyContent: "center",
  padding: "2rem",
  position: "absolute",
  left: "50%",
  top: "50%",
  transform: "translate(-50%, -50%)",
}));

const LoadingText = styled(FlexRow)(({ theme }) => ({
  ...theme.fonts.display,
  textTransform: "uppercase",
  color: theme.colors.fg,
  fontSize: "3em",
  whiteSpace: "nowrap",
}));

const EllipsesDot = styled.span({
  "@keyframes dotJump": {
    "0%, 30%, 100%": {
      transform: "translateY(0)",
    },
    "15%": {
      transform: "translateY(-.2em)",
    },
  },
  "@media (prefers-reduced-motion: no-preference)": {
    animation: "dotJump 1s cubic-bezier(.3, .7, .3, .6) 0s forwards infinite",
  },
  "&:nth-of-type(1)": { animationDelay: "0s" },
  "&:nth-of-type(2)": { animationDelay: ".06s" },
  "&:nth-of-type(3)": { animationDelay: ".12s" },
});

const Letter = styled.g({
  "@keyframes jump": {
    "0%, 20%, 40%, 100%": {
      transform: "translateY(0)",
    },
    "30%": {
      transform: "translateY(-1.25rem)",
    },
  },
  "@media (prefers-reduced-motion: no-preference)": {
    animation: "jump 1.5s cubic-bezier(.3, .7, .3, .6) 0s forwards infinite",
  },
  "&:nth-of-type(1)": { animationDelay: "0s" },
  "&:nth-of-type(2)": { animationDelay: ".075s" },
  "&:nth-of-type(3)": { animationDelay: ".15s" },
  "&:nth-of-type(4)": { animationDelay: ".225s" },
  "&:nth-of-type(5)": { animationDelay: ".3s" },
  "&:nth-of-type(6)": { animationDelay: ".375s" },
  "&:nth-of-type(7)": { animationDelay: ".45s" },
});

export { LoadingSpinner };
