import * as React from "react";
import styled from "@emotion/styled";
import { useToggle } from "utils/hooks";
import { IconX } from "./icons";
import { FadeIn } from "./FadeIn";

type TooltipProps = {
  "aria-label"?: string;
  label: React.ReactNode;
  labelOpen?: React.ReactNode;
  positionX?: "left" | "right" | "center";
  positionY?: "top" | "bottom";
  children: React.ReactNode;
  allowsHover?: boolean;
};

function Tooltip({
  label,
  labelOpen,
  positionX,
  positionY,
  children,
  allowsHover = false,
  ...props
}: TooltipProps) {
  const [isOpen, toggle, setIsOpen] = useToggle(false);
  React.useEffect(() => {
    setIsOpen(false);
  }, [children, setIsOpen]);

  return (
    <TooltipWrapper {...props}>
      <TooltipButton
        type="button"
        onClick={toggle}
        isOpen={isOpen}
        allowsHover={allowsHover}
        aria-label={props["aria-label"]}
      >
        {isOpen && labelOpen !== undefined ? labelOpen : label}
      </TooltipButton>
      <TooltipPopup
        hidden={!isOpen}
        positionX={positionX}
        positionY={positionY}
        aria-live="polite"
        className="TooltipContent"
      >
        {isOpen && (
          <Close type="button" onClick={toggle} aria-label="Close tool tip">
            <IconX />
          </Close>
        )}
        <TooltipPopupContent>{children}</TooltipPopupContent>
      </TooltipPopup>
    </TooltipWrapper>
  );
}
const TooltipWrapper = styled.div({
  maxWidth: "100%",
  position: "relative",
  width: "max-content",
});
TooltipWrapper.defaultProps = {
  className: "Tooltip",
};

const TooltipButton = styled.button<{ isOpen: boolean; allowsHover: boolean }>(
  ({ isOpen, allowsHover, theme }) => ({
    ...theme.fonts.body,
    appearance: "none",
    backgroundColor: "rgb(0 0 0 / 0)",
    border: 0,
    cursor: "pointer",
    padding: 0,
    zIndex: 4,
    "&::after": {
      display: isOpen ? "block" : "none",
      content: "''",
      position: "fixed",
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    ...(allowsHover && {
      "&:hover + *, & + *:hover": {
        display: "block",
      },
    }),
  }),
);

const TooltipPopup = styled(FadeIn)<{
  positionX?: "left" | "right" | "center";
  positionY?: "top" | "bottom";
}>(({ positionX, positionY, theme }) => ({
  backgroundColor: theme.colors.fg05,
  border: `1px solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.sm,
  bottom: "calc(100% + 4px)",
  boxShadow: theme.boxShadow.dark,
  fontSize: theme.fontSizes.xs,
  left: -10,
  maxWidth: "75vw",
  minWidth: 255,
  padding: "1.33rem 1rem 1rem 1rem",
  position: "absolute",
  width: "max-content",
  zIndex: 600,
  ...(positionY === "bottom" && {
    bottom: "auto",
    top: "calc(100% + 8px)",
  }),
  ...(positionX === "right" && {
    left: "auto",
    right: -10,
  }),
  ...(positionX === "center" && {
    left: "50%",
    transform: "translateX(-50%)",
    textAlign: "center",
    "*": { justifyContent: "center" },
  }),
  "p:last-of-type": {
    marginBottom: 0,
  },
  "&::before, &::after": {
    top: "100%",
    left: "30px",
    border: "solid rgb(0 0 0 / 0)",
    content: "''",
    height: 0,
    width: 0,
    position: "absolute",
    pointerEvents: "none",
    ...(positionY === "bottom" && {
      transform: "translateY(-100%)",
      top: "auto",
      bottom: "100%",
    }),
    ...(positionX === "right" && {
      left: "calc(100% - 30px)",
    }),
    ...(positionX === "center" && {
      left: "50%",
      transform: "translateX(-50%)",
    }),
  },
  "&::before": {
    borderColor: "rgb(0 0 0 / 0)",
    borderTopColor: theme.colors.fg,
    borderWidth: 8.25,
    marginLeft: "-8.25px",
    ...(positionY === "bottom" && {
      borderTopColor: "rgb(0 0 0 / 0)",
      borderBottomColor: theme.colors.fg,
    }),
    ...(positionX === "center" && {
      margin: 0,
    }),
  },
  "&::after": {
    borderColor: "rgb(0 0 0 / 0)",
    borderTopColor: theme.colors.fg10,
    borderWidth: 8,
    marginLeft: "-8px",
    top: "calc(100% - 1px)",
    ...(positionY === "bottom" && {
      borderTopColor: "rgb(0 0 0 / 0)",
      borderBottomColor: theme.colors.fg10,
      top: "auto",
      bottom: "calc(100% - 1px)",
    }),
    ...(positionX === "center" && {
      margin: 0,
    }),
  },
}));
const TooltipPopupContent = styled.div(({ theme }) => ({
  color: theme.colors.fg,
}));
const Close = styled.button(({ theme }) => ({
  appearance: "none",
  backgroundColor: "rgb(0 0 0 / 0)",
  border: 0,
  borderRadius: theme.borderRadius.round,
  color: theme.colors.fg,
  cursor: "pointer",
  display: "inline-flex",
  justifyContent: "center",
  alignItems: "center",
  opacity: 0.7,
  padding: 0,
  position: "absolute",
  top: 0,
  right: 0,
  width: 24,
  height: 24,
  margin: 0,
  svg: {
    display: "block",
    margin: 0,
    width: 10,
    height: 10,
  },
  "&:hover, &:focus": {
    opacity: 1,
  },
}));

export { Tooltip };
