import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { shopDataQueryKey } from "./queryKeys";
import { ShopDataResp } from "../types";

type ArtistShopUpdateResponse = {
  shopConfig: ShopDataResp;
};

type PutArtistShopParams = {
  featuredArtist: string;
  enableOnChainFulfillment: boolean;
};

const putShopShopConfig = (
  params: PutArtistShopParams,
): Promise<ArtistShopUpdateResponse> =>
  api.put("/shop/config", {
    body: JSON.stringify(params),
  });

const useShopConfigForm = (shopConfig: ShopDataResp) => {
  const queryClient = useQueryClient();
  const form = useForm<PutArtistShopParams>({ defaultValues: shopConfig });

  const mutation = useMutation(putShopShopConfig, {
    onSuccess: (response) => {
      queryClient.setQueryData(shopDataQueryKey, response.shopConfig);
    },
  });

  return {
    ...form,
    mutation,
  };
};

export { useShopConfigForm };
