import type { Image } from "../types";

const isTaggedNSFW = (src: string | Image | undefined) =>
  src &&
  typeof src === "object" &&
  !!src.tags
    ?.filter((tag) => !src.tagOverrides?.includes(tag))
    .includes("NSFW");

export { isTaggedNSFW };
