import styled from "@emotion/styled";
import { Button, FlexRow, Stack, Text } from "atoms";
import { useToggle } from "utils/hooks";

function NSFWOverlay() {
  // TODO: get user preferences
  const showSensitiveContent = false;

  const [showOverlay, toggleShowOverlay] = useToggle(!showSensitiveContent);

  if (!showOverlay) {
    return null;
  }

  return (
    <OverlayMain>
      <Stack gap="sm">
        <Stack gap="xs">
          <span>⚠</span>
          <Text bold size="xxs">
            This image may contain sensitive&nbsp;content
          </Text>
        </Stack>
        <FlexRow justifyContent="center" gap="10px">
          <Button variant="secondary" size="xxs" onClick={toggleShowOverlay}>
            Reveal image
          </Button>
          {/* <Button variant="secondary" size="xxs" onClick={toggleShowOverlay}>
            Always show these images
          </Button> */}
        </FlexRow>
      </Stack>
    </OverlayMain>
  );
}

const OverlayMain = styled.div(({ theme }) => ({
  backdropFilter: "blur(10px)",
  position: "absolute",
  padding: "1rem",
  display: "flex",
  fontSize: theme.fontSizes.sm,
  lineHeight: 1.3,
  justifyContent: "center",
  textAlign: "center",
  alignItems: "center",
  color: theme.colors.bg,
  backgroundColor: `rgba( 0 0 0 / .8)`,
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 3,
}));

export { NSFWOverlay };
