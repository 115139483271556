import type {
  ProductOption,
  SelectedOption,
} from "@shopify/hydrogen-react/storefront-api-types";

/**
 * Check if selected Product option is the Shopify default for having no variants
 */
const isDefaultSelectedOption = ({ name, value }: SelectedOption) =>
  name === "Title" && value === "Default Title";

/**
 * Check if Product option is the Shopify default for having no variants
 */
const isDefaultOption = ({ name, values }: ProductOption) =>
  values.length === 1 && isDefaultSelectedOption({ name, value: values[0] });

export { isDefaultOption, isDefaultSelectedOption };
