/** @jsxImportSource @emotion/react */
import { Link } from "./Link";
import { Tag } from "./Tag";

const variants = {
  founderAttributes: "founder",
  missionOrCause: "mission",
  medium: "medium",
} as const;

type TagLinkProps = {
  tag: string;
  variant: keyof typeof variants;
  analyticsLocation?: string;
};

/*
 * Create a Google Analytics Event for Tag Clicks. The parameters will
 * be the tag name and an optional location for the tag. This will help
 * us better understand how the tag links are being used by users
 */
const createTagLinkClickEvent =
  (tag: string, analyticsLocation?: string) => () => {
    if (window.gtag) {
      window.gtag("event", "TagClick", {
        tag,
        location: analyticsLocation ?? "Unknown",
      });
    }
  };

function TagLink({ tag, variant, analyticsLocation, ...props }: TagLinkProps) {
  const link = `/search?${variant}=${encodeURIComponent(tag)}`;
  const tagBg = variants[variant];

  return (
    <Link
      to={link}
      {...props}
      onClick={createTagLinkClickEvent(tag, analyticsLocation)}
    >
      <Tag bg={tagBg}>{tag}</Tag>
    </Link>
  );
}

export { TagLink };
