import { useNavigate } from "react-router-dom";
import { IconNewItem, Modal } from "atoms";
import { paths } from "utils/paths";
import { GalleryForm } from "./GalleryForm";
import { useEditorContext } from "../utils/useEditorContext";
import type { GalleryItem } from "../types";

type AddGalleryProps = {
  cancel: VoidFunction;
};

function AddGallery({ cancel }: AddGalleryProps) {
  const { artist, setEditorAction } = useEditorContext();
  const navigate = useNavigate();

  const onSuccess = (item: GalleryItem) => {
    setEditorAction("add");
    navigate(paths.artistGalleryEdit(item.ownerId, item.id));
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <IconNewItem /> Add New Gallery
        </Modal.Title>
      </Modal.Header>

      <GalleryForm artistId={artist.id} cancel={cancel} onSuccess={onSuccess} />
    </>
  );
}

export { AddGallery };
