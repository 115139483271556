import styled from "@emotion/styled";
import {
  Box,
  Container,
  FlexRow,
  H2,
  IconDiamond,
  Link,
  ScreenReaderText,
  Stack,
  Text,
} from "atoms";
import { constants } from "config";

type ProfileProgressProps = {
  currentLevel: number;
  isHugger: boolean;
  isSrCurator: boolean;
  lifetimePoints: number;
};

const pointsPerLevel = [
  0, 250, 500, 1250, 2250, 4250, 7500, 10000, 15000, 25000, 40000, 65000,
  100000,
];

function ProfileProgress({
  currentLevel,
  isHugger,
  isSrCurator,
  lifetimePoints,
}: ProfileProgressProps) {
  const maxLevel = pointsPerLevel.length - 1;

  // Check if the user has reached the max level
  const isMaxLevel = currentLevel === maxLevel;

  const currentLevelPoints = !isMaxLevel
    ? pointsPerLevel[currentLevel]
    : pointsPerLevel[currentLevel - 1];
  const nextLevel = isMaxLevel ? maxLevel : currentLevel + 1;
  const nextLevelPoints = isMaxLevel
    ? pointsPerLevel[pointsPerLevel.length - 1]
    : pointsPerLevel[nextLevel];
  const currentLevelProgressPoints = lifetimePoints - currentLevelPoints;
  const progressPercent = !isMaxLevel
    ? Math.floor(
        (currentLevelProgressPoints / (nextLevelPoints - currentLevelPoints)) *
          100,
      )
    : 100;
  const progressDifference = nextLevelPoints - lifetimePoints;

  if (!isHugger) {
    return (
      <Box>
        <Container width="xs">
          <Stack gap="sm">
            <H2 size="xl" textAlign="center">
              Claim Your {lifetimePoints} HUG Points
            </H2>

            <Text textAlign="center">
              You do not have a{" "}
              <Link href={constants.openseaURL} target="_blank">
                HUG&nbsp;Pass
              </Link>{" "}
              or{" "}
              <Link
                href="https://opensea.io/collection/metaangelsnft"
                target="_blank"
              >
                Meta&nbsp;Angels
              </Link>{" "}
              NFT connected to this&nbsp;account. Get&nbsp;one to claim your
              hard-earned HUG&nbsp;Points&nbsp;today!
            </Text>

            <Text textAlign="center">
              <Link
                href={constants.openseaURL}
                target="_blank"
                variant="primary"
                size="sm"
              >
                Get a HUG Pass
              </Link>
            </Text>

            <Text size="xxxs" textAlign="center">
              Do you have one in another wallet?{" "}
              <Link href="mailto:hello@thehug.xyz">Contact support</Link>.
            </Text>
          </Stack>
        </Container>
      </Box>
    );
  }

  return (
    <Box>
      <Stack gap="sm">
        <Stack gap="sm">
          <FlexRow
            justifyContent="space-between"
            alignItems="baseline"
            gap="0px"
          >
            <SH2>Level {currentLevel}</SH2>

            <SText size="xxs" textAlign="center" as="div">
              <FlexRow gap="3px">
                <strong>Lifetime Earnings:</strong>

                <FlexRow gap="3px">
                  <IconDiamond size="16px" accent="accent1" />

                  {lifetimePoints.toLocaleString()}
                </FlexRow>
              </FlexRow>
            </SText>
          </FlexRow>
          {!isMaxLevel && (
            <Text size="xs">
              You only need {progressDifference} HUG Points to reach Level&nbsp;
              {nextLevel}.{" "}
              <span>
                {isSrCurator
                  ? "Continue earning HUG Points by leaving reviews and voting for artists!"
                  : "Continue earning HUG Points by leaving and moderating reviews, and voting for artists!"}
              </span>
            </Text>
          )}
        </Stack>

        <Stack gap="xs">
          {!isMaxLevel && (
            <SText>
              <strong>
                {progressDifference} more HUG Points to reach Level {nextLevel}.
              </strong>{" "}
              You&apos;re&nbsp;{progressPercent}
              %&nbsp;of&nbsp;the&nbsp;way&nbsp;there.
            </SText>
          )}
          {isMaxLevel && (
            <SText>
              Congratulations, you have reached the maximum HUG level!
            </SText>
          )}

          <Progress aria-hidden>
            <Bar progressPercent={progressPercent}>&nbsp;</Bar>
          </Progress>

          <FlexRow justifyContent="space-between" alignItems="flex-start">
            <SText size="xxs" textAlign="center" as="div">
              <strong>
                Level {!isMaxLevel ? currentLevel : currentLevel - 1}
              </strong>

              <FlexRow gap="3px">
                <IconDiamond size="16px" accent="accent1" />
                {currentLevelPoints.toLocaleString()}
              </FlexRow>
            </SText>

            <SText size="xxs" textAlign="center" as="div">
              <strong>Level {nextLevel}</strong>

              <FlexRow gap="3px">
                <IconDiamond size="16px" accent="accent1" />
                {nextLevelPoints.toLocaleString()}
              </FlexRow>
            </SText>
          </FlexRow>
        </Stack>

        <Text size="xxs">
          <em>
            Lifetime Earnings refer to all HUG Points ever earned. If you have
            submitted a review or have redeemed HUG Points, your Lifetime
            Earnings will be higher than your current balance.
          </em>
        </Text>
      </Stack>
    </Box>
  );
}

const Progress = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.accent1L,
  borderRadius: theme.borderRadius.round,
  boxShadow: `0 0 0 1px ${theme.colors.fg20}`,
  border: "3px solid white",
  height: "1.25em",
  overflow: "hidden",
  width: "100%",
  position: "relative",
}));

const Bar = styled(ScreenReaderText)<{ progressPercent: number }>(
  ({ progressPercent, theme }) => ({
    backgroundColor: theme.colors.bg,
    display: "block",
    width: "auto",
    height: "auto",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    transform: `translateX(${progressPercent}%)`,
    "@media (prefers-reduced-motion: no-preference)": {
      animation: "barIn .7s 1s ease forwards",
      transform: "translateX(0)",
      transition: ".4s ease translateX",
    },
    "@keyframes barIn": {
      "0%": {
        transform: "translateX(0)",
      },
      "100%": {
        transform: `translateX(${progressPercent}%)`,
      },
    },
  }),
);

const SText = styled(Text)({
  margin: 0,
});

const SH2 = styled(H2)({
  margin: 0,
});

export { ProfileProgress };
