import * as React from "react";
import { useTheme } from "@emotion/react";
import { useQuery } from "react-query";
import type { ThemeBreakpoints } from "../types";
import { api } from "./api";

/**
 * Hook to create safe useReducer dispatch function
 *
 * This only runs a dispatch if the component is still mounted, otherwise it
 * returns undefined to prevent memory leaks from async functions, for example.
 */
const useSafeDispatch = <TAction>(dispatch: React.Dispatch<TAction>) => {
  // The component is not rendered yet when hook is first called in the component logic
  const isMounted = React.useRef(false);

  React.useLayoutEffect(() => {
    // Now that a layout effect has run, the component has been mounted into the DOM
    isMounted.current = true;
    // This cleanup function will run when the component is unmounted
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Return a safe dispatch function
  return React.useCallback(
    (action: TAction) => (isMounted.current ? dispatch(action) : undefined),
    [dispatch],
  );
};

type UseAsyncState<TData, TError> = {
  status: "idle" | "loading" | "error" | "success";
  data: TData | undefined;
  error: TError | null;
};

/**
 * Hook to track async function state and result
 *
 * Similar to react-query's useQuery hook, but returns a `run` function that accepts
 * any Promise which resolves into `data` or rejects into `error`. For example:
 * ```
 * const { data, error, status, run } = useAsync();
 * const onClick = () => run(fetchUser("foo"));
 * ```
 */
const useAsync = <TData, TError = Error>(
  initialState: Partial<UseAsyncState<TData, TError>> = {},
) => {
  const initialStateRef = React.useRef<UseAsyncState<TData, TError>>({
    status: "idle",
    data: undefined,
    error: null,
    ...initialState,
  });

  const [{ status, data, error }, dispatch] = React.useReducer(
    // Simple reducer to merge new values from action into existing state
    (
      state: UseAsyncState<TData, TError>,
      action: Partial<UseAsyncState<TData, TError>>,
    ) => ({ ...state, ...action }),
    initialStateRef.current,
  );

  const safeDispatch = useSafeDispatch(dispatch);
  const setData = React.useCallback(
    (_data: TData) =>
      safeDispatch({ data: _data, status: "success", error: null }),
    [safeDispatch],
  );
  const setError = React.useCallback(
    (_error: TError) => safeDispatch({ error: _error, status: "error" }),
    [safeDispatch],
  );

  // Wrap the async function call and handle setting status and result/error.
  const run = React.useCallback(
    (promise: Promise<TData>) => {
      safeDispatch({ status: "loading" });

      return promise
        .then((result: TData) => {
          setData(result);
          return result;
        })
        .catch((err) => {
          setError(err);
          return err;
        });
    },
    [safeDispatch, setData, setError],
  );

  return {
    run,
    data,
    error,
    status,
    isIdle: status === "idle",
    isLoading: status === "loading",
    isSuccess: status === "success",
    isError: status === "error",
  };
};

/**
 * Hook to observe if an element has scrolled into/out of view
 *
 * Returns ref to attach to element to be observed and the resulting boolean.
 * const { scrollRef, isVisible } = useScrollObserver();
 * <div ref={scrollRef}>{isVisible ? "hello" : "goodbye"}</div>
 */
const useScrollObserver = (initialValue = false) => {
  const [element, setElement] = React.useState<Element | null>(null);
  const [isVisible, setIsVisible] = React.useState(initialValue);

  // Create callback ref because useRef is mutable and doesn’t notify us about
  // changes to its current value.
  const scrollRef = React.useCallback((node: Element | null) => {
    if (node !== null) {
      setElement(node);
    }
  }, []);

  // Layout effects wait until DOM mutations, ensuring we have the element reference
  React.useLayoutEffect(() => {
    // If there is no element yet, do nothing
    if (!element) {
      return noop;
    }

    const scrollObserver = new IntersectionObserver((entries) => {
      // Get element position and dimensions
      const { y, height } = entries[0].boundingClientRect;
      // Verify the bottom of the element is below the top of the viewport
      const belowViewportTop = y + height > 0;
      // Verify the top of the element is above the bottom of the viewport
      const aboveViewportBottom = y < window.innerHeight;
      // If both are true, the element is visible
      setIsVisible(belowViewportTop && aboveViewportBottom);
    });

    // Attach the observer to the DOM node
    scrollObserver.observe(element);

    // Stop observer when the component is unmounted
    return () => {
      scrollObserver.disconnect();
    };
  }, [element]);

  return { scrollRef, isVisible };
};

/**
 * Hook that debounces a value that changes too frequently
 * @param value - Value that should be updated after timeout
 * @param delay - Delay for effect in milliseconds
 */
const useDebouncer = <T>(value: T, delay: number) => {
  const [debounced, setDebounced] = React.useState<T>(value);
  React.useEffect(() => {
    const timeout = setTimeout(() => {
      setDebounced(value);
    }, delay);
    return () => clearTimeout(timeout);
  }, [value, delay]);
  return debounced;
};

/**
 * Hook that sends async or event handler error to nearest Error Boundary
 *
 * Errors in render or lifecycle methods trigger error boundaries, but async code or event handlers
 * run separately in the JS event loop and need to be handled individually. But we can use this
 * hook to throw that error during a setState lifecycle method.
 * See: https://github.com/facebook/react/issues/14981#issuecomment-468460187
 *
 * Usage:
 * ```
 * const throwAsyncError = useThrowAsyncError();
 * const onClick = (event) => {
 *   try {
 *     // do something with event
 *   } catch (e) {
 *     // Bubble error up to nearest error boundary
 *     throwAsyncError(e)
 *   }
 * }
 * ```
 */
const useThrowAsyncError = () => {
  const setState = React.useState()[1];

  return (error: Error | unknown) => {
    setState(() => {
      throw error;
    });
  };
};

/**
 * Get scroll progress through an Element
 */
const getScrollProgress = (el: Element, offset = 0) => {
  // Get Element's bounding box, we only care how about its height and how far away the bottom is
  const { bottom, height } = el.getBoundingClientRect();
  // Get the y-coordinate of bottom of viewport as reference
  const viewportHeight = document.documentElement.clientHeight;
  // Determine how much of the element is below the bottom of the viewport, plus any offset
  const progress = (bottom - viewportHeight + offset) / height;
  // Invert that fraction to see how much has been scrolled through already
  const inverted = 1 - progress;
  // If element is out of the viewport, bound the fraction to numbers that make sense (e.g. no negatives)
  const bounded = Math.max(0, Math.min(1, inverted));
  // Turn fraction into percentage
  const percent = bounded * 100;
  return percent;
};

/**
 * Create a ref for an Element and track scroll progress through it
 * @param offset - Optional offset in pixels (positive = above viewport bottom, negative = below)
 */
const useScrollProgress = <TElement extends HTMLElement>(offset = 0) => {
  // State to store the scroll progress in unrounded percentage value
  const [progress, setProgress] = React.useState(0);
  // Ref to store the target element
  const scrollRef = React.useRef<TElement | null>(null);

  React.useEffect(() => {
    // Create a timeout to store waiting animation frame
    let timeout: number;

    // Create handler to attach to window scroll event
    const scrollHandler = () => {
      // Cancel waiting outdated animation frame to replace with new one
      if (timeout) {
        window.cancelAnimationFrame(timeout);
      }
      // Request animation frame so we don't block the main thread, we'll wait for a repaint
      timeout = window.requestAnimationFrame(() => {
        // We can only check position if the element has been mounted
        if (scrollRef.current) {
          setProgress(getScrollProgress(scrollRef.current, offset));
        }
      });
    };

    window.addEventListener("scroll", scrollHandler);
    return () => window.removeEventListener("scroll", scrollHandler);
  }, [offset, progress, setProgress]);

  return { scrollRef, progress };
};

/**
 * Simple state hook for a toggleable boolean
 *
 * Returns an array containing the boolean, a function to toggle it, and a state setter
 * to set the value directly if needed.
 */
const useToggle = (
  initialValue: boolean,
): [boolean, () => void, React.Dispatch<React.SetStateAction<boolean>>] => {
  const [state, setState] = React.useState(initialValue);
  const toggle = React.useCallback(() => setState((oldState) => !oldState), []);
  return [state, toggle, setState];
};

const sleep = (ms: number, shouldReject = false) =>
  new Promise((resolve, reject) => {
    setTimeout(shouldReject ? reject : resolve, ms);
  });

/**
 * Given a ref to an element, return its dimensions and update on resize events
 *
 * NOTE: you might want to combine with useDebouncer to prevent too many renders on resize.
 */
const useElementDimensions = (
  ref: React.MutableRefObject<HTMLElement | null>,
) => {
  const [dimensions, setDimensions] = React.useState({ height: 0, width: 0 });

  React.useLayoutEffect(() => {
    const getDimensions = () => ({
      height: ref.current?.offsetHeight ?? 0,
      width: ref.current?.offsetWidth ?? 0,
    });

    const handleResize = () => setDimensions(getDimensions());

    if (ref.current) {
      setDimensions(getDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, [ref]);

  return dimensions;
};

/* Track multiple item selection */
const useSelectedIdsMultiple = () => {
  const [selectedIds, setSelectedIds] = React.useState<Set<string>>(
    new Set<string>(),
  );

  const isSelected = (key: string) => selectedIds.has(key);

  const toggleSelected = (key: string) => {
    if (isSelected(key)) {
      selectedIds.delete(key);
      setSelectedIds(new Set(selectedIds));
    } else {
      selectedIds.add(key);
      setSelectedIds(new Set(selectedIds));
    }
  };

  return {
    isSelected,
    toggleSelected,
    selectedIds,
    numSelected: selectedIds.size,
  };
};

/**
 * Hook to use a single theme breakpoint in component logic
 */
const useBreakpoint = (breakpoint: keyof ThemeBreakpoints) => {
  const { breakpoints } = useTheme();
  const query = breakpoints[breakpoint].replace("@media ", "");

  const [matches, setMatches] = React.useState(
    window.matchMedia(query).matches,
  );

  React.useLayoutEffect(() => {
    const media = window.matchMedia(query);
    const handleChange = () => {
      setMatches(window.matchMedia(query).matches);
    };
    handleChange();

    media.addEventListener("change", handleChange);
    return () => media.removeEventListener("change", handleChange);
  }, [query, setMatches]);

  return matches;
};

type JobResp<TValue> =
  | {
      status: "PROCESSING";
      value: null;
    }
  | {
      status: "COMPLETE";
      value: TValue;
    };

const getJob = async <TValue>(jobKey?: string) => {
  if (!jobKey) {
    return Promise.reject(new Error("Invalid job key"));
  }

  const { status, value } = await api.get<JobResp<TValue>>(`/jobs/${jobKey}`);

  // If status is complete, resolve with the job's resulting value. Otherwise, reject with the job's
  // status and let the query's retry logic determine what to do next.
  return status === "COMPLETE" ? value : Promise.reject(status);
};

/**
 * Poll the API until a background job is complete, finally resolving with data of provided type
 */
const useJobQuery = <TData>(
  jobKey?: string,
  { retryDelay = 1000 }: { retryDelay?: number } = {},
) =>
  useQuery(["job", jobKey], () => getJob<TData>(jobKey), {
    enabled: !!jobKey,
    retryDelay,
    // If `getJob` promise rejects because it's still processing, keep retrying
    retry: (_, error) => error === "PROCESSING",
  });

export {
  useAsync,
  useBreakpoint,
  useDebouncer,
  useElementDimensions,
  useJobQuery,
  useSafeDispatch,
  useSelectedIdsMultiple,
  useScrollObserver,
  useScrollProgress,
  useThrowAsyncError,
  useToggle,
  sleep,
};
