import styled from "@emotion/styled";
import { BlockTag, H2, Link, PlaceholderBlock, RichText, Stack } from "atoms";
import { paths } from "utils/paths";
import { GenericImage } from "features/images";
import { useCollectionQuery } from "../api/useCollectionQuery";

type ShopFeaturedHeaderProps = {
  featured: string;
};

function ShopFeaturedHeader({ featured }: ShopFeaturedHeaderProps) {
  const { data } = useCollectionQuery({ handle: featured });

  if (data) {
    return (
      <Header style={{ "--bg-img": `url("${data.image?.url}")` }}>
        <HeaderCopy>
          <Stack gap="xxs">
            <BlockTag color="bg" bg="fg">
              Featured
            </BlockTag>
            <H2>{data.title}</H2>
          </Stack>

          <RichText value={data.descriptionHtml} />

          <Link variant="secondary" to={paths.artistShop(featured)}>
            Shop Now
          </Link>
        </HeaderCopy>

        <SGenericImage src={data.image?.url} sizes="100vw" />
      </Header>
    );
  }

  return <LoadingHeader />;
}

function LoadingHeader() {
  return (
    <Header>
      <HeaderCopy>
        <Stack gap="sm">
          <Stack gap="xs">
            <PlaceholderBlock width="100px" height="20px" />
            <PlaceholderBlock width="90%" height="50px" />
          </Stack>
          <Stack gap="xs">
            <PlaceholderBlock width="77.7%" height="20px" />
            <PlaceholderBlock width="66.6%" height="20px" />
          </Stack>

          <PlaceholderBlock width="175px" height="40px" rounded />
        </Stack>
      </HeaderCopy>
      <SGenericImage src="" sizes="100vw" />
    </Header>
  );
}

const Header = styled.header(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  backgroundPosition: "center right",
  backgroundSize: "cover",
  [theme.breakpoints.desktop]: {
    display: "flex",
    alignItems: "center",
    aspectRatio: "1280 / 550",
    backgroundColor: theme.colors.fg20,
    backgroundImage: "var(--bg-img)",
    minHeight: "min-content",
    maxHeight: 450,
    borderRadius: 0,
    width: "100%",
  },
  [theme.breakpoints.desktopXLarge]: {
    borderRadius: theme.borderRadius.sm,
    marginInline: "auto",
    width: 1331,
  },
}));

const overlap = 40;
const HeaderCopy = styled(Stack)(({ theme }) => ({
  "&,div": { color: theme.colors.fg, textWrap: "balance", lineHeight: 1.2 },
  paddingTop: theme.spacing.lg,
  paddingBottom: 2,
  paddingInline: theme.spacing.gutter,
  position: "relative",
  zIndex: 2,
  "&+*": {
    position: "relative",
    zIndex: 1,
  },
  [theme.breakpoints.desktop]: {
    paddingBlock: theme.spacing.lg,
    paddingRight: 0,
    width: "50%",
  },
  [theme.breakpoints.maxDesktop]: {
    marginBottom: -overlap,
    "&::after": {
      content: "''",
      display: "block",
      position: "absolute",
      top: `calc(100% - ${overlap}px)`,
      left: 0,
      right: 0,
      height: "15vw",
      backgroundImage: `
      linear-gradient(
        to bottom,
        #ffffff 2px,
        #ffffffd1 30%,
        #ffffff00 100%
        )
        `,
      pointerEvents: "none",
      zIndex: -1,
    },
  },
}));

const SGenericImage = styled(GenericImage)(({ theme }) => ({
  aspectRatio: "43 / 33",
  img: {
    objectFit: "cover",
    objectPosition: "right",
    width: "100%",
    height: "100%",
  },
  [theme.breakpoints.desktop]: {
    aspectRatio: "1280 / 550",
    display: "none",
  },
}));

export { ShopFeaturedHeader };
