import * as React from "react";
import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import {
  FadeIn,
  Input,
  Link,
  Text,
  ScreenReaderText,
  Hr,
  Label,
  Button,
  FlexRow,
} from "atoms";
import { Thumbnail } from "features/images";
import type { Artist } from "features/reviewable/types";
import { trackEvent } from "contexts/mixpanel";
import { useDebouncer } from "utils/hooks";
import { paths } from "utils/paths";
import { useIncrementalSearch } from "../api/useSearch";

const createTrackOpenSearch =
  (ButtonDestination: string, Resource: string) => () => {
    trackEvent({
      name: "Button Click",
      ButtonName: "Search Results",
      ButtonContext: "Global Search Result",
      ButtonDestination,
      Resource,
    });
  };

function GlobalSearch() {
  // Search Form
  const [value, setValue] = React.useState("");
  const onChange: React.ChangeEventHandler<HTMLInputElement> = (event) => {
    setValue(event.target.value);
  };

  // Debounce the input value so we only query once user finishes typing
  const query = useDebouncer(value, 300);

  // Run query and manage state
  const { isFetching, isIdle, data } = useIncrementalSearch(query, 5);
  const showResults = !!query && !isIdle;

  const location = useLocation();
  const navigate = useNavigate();

  // Reset on navigation
  React.useEffect(() => {
    setValue("");
  }, [location]);

  const searchUrl = `/search?name=${value}`;
  const onSubmit: React.FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();
    if (value) {
      navigate(searchUrl);
    }
  };
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  };

  return (
    <Wrapper className="GlobalSearch">
      <FlexRow>
        <SearchForm id="GlobalSearchForm" onSubmit={onSubmit}>
          <Label>
            <ScreenReaderText>Search Artists</ScreenReaderText>
            <SearchInput
              name="search"
              placeholder="Search for artists"
              value={value}
              onChange={onChange}
              onKeyDown={onKeyDown}
              autoFocus
              autoComplete="off"
            />
          </Label>
        </SearchForm>
      </FlexRow>

      {showResults && (
        <ResultsModal>
          {data?.length === 0 && (
            <SearchResultMessage>
              <em>No projects match your search.</em>
            </SearchResultMessage>
          )}

          <SearchResults isFetching={isFetching || value !== query}>
            {data?.map((result, index) => (
              <React.Fragment key={result.id}>
                <SearchResult
                  delay={index * 50}
                  result={result}
                  searchString={query}
                />
                <SHr />
              </React.Fragment>
            ))}
          </SearchResults>

          <Explore>
            <Text>Can&apos;t find what you&apos;re looking&nbsp;for?</Text>
            <Button
              type="submit"
              form="GlobalSearchForm"
              variant="primary"
              size="xs"
            >
              Search All Artists
            </Button>
          </Explore>
        </ResultsModal>
      )}
    </Wrapper>
  );
}

// Time for initial animations/tranisitions to expand the search bar.
// Results modal animations will be delayed until this completes.
const ANIMATION_TIME = "0.25s";

const Wrapper = styled.div(({ theme }) => ({
  flex: 1,
  position: "relative",
  zIndex: 100,
  "@media (prefers-reduced-motion: no-preference)": {
    transitionDuration: ANIMATION_TIME,
    transitionTimingFunction: "ease",
    transitionProperty: "left",
  },
  [theme.breakpoints.desktop]: {
    alignItems: "center",
    justifyContent: "flex-end",
    padding: 0,
  },
}));

const SearchForm = styled.form(({ theme }) => ({
  margin: 0,
  position: "relative",
  flex: "0 0 100%",
  zIndex: 1,
  input: {
    paddingRight: "2.5rem",
    color: theme.colors.fg,
    "&:placeholder": {
      color: theme.colors.fg,
      opacity: 1,
    },
    "&:focus-within": {
      outline: 0,
    },
  },
}));

GlobalSearch.inputHeight = 50;

const SearchInput = styled(Input)(({ theme }) => ({
  borderRadius: theme.borderRadius.round,
  border: `2px solid ${theme.colors.fg}`,
  position: "relative",
  margin: 0,
  display: "flex",
  alignItems: "center",
  lineHeight: 1.6,
  fontSize: 16,
  maxHeight: GlobalSearch.inputHeight - 10,
  "&::placeholder": {
    color: theme.colors.fg80,
    opacity: 1,
  },
  "@media (prefers-reduced-motion: no-preference)": {
    transitionDuration: ANIMATION_TIME,
    transitionTimingFunction: "ease",
    transitionProperty: "left",
  },
  [theme.breakpoints.tablet]: {
    width: 350,
  },
  [theme.breakpoints.desktop]: {
    height: 36,
    minHeight: 36,
    margin: 0,
    lineHeight: 1,
    paddingTop: 2,
    paddingBottom: 0,
  },
}));

const SearchButton = styled(Button)({
  alignItems: "center",
  display: "inline-flex",
  justifyContent: "center",
  fontSize: 18,
  padding: "0 .66rem",
  position: "absolute",
  bottom: 4,
  right: 4,
  top: 4,
  zIndex: 2,
  "form &": {
    minHeight: 0,
  },
});
SearchButton.defaultProps = {
  variant: "primary",
};

const ResultsModal = styled(FadeIn)({
  animationDelay: "0s",
  padding: "1.5rem .5rem .5rem",
  width: "100%",
  minWidth: "250px",
});

const SearchResultMessage = styled(FadeIn)({
  fontSize: "1rem",
  margin: 0,
}).withComponent("p");

const SearchResults = styled.ul<{ isFetching: boolean }>(({ isFetching }) => ({
  display: "flex",
  flexDirection: "column",
  gap: 10,
  listStyleType: "none",
  margin: 0,
  opacity: isFetching ? 0.6 : 1,
  padding: 0,
  "@media (prefers-reduced-motion: no-preference)": {
    transition: "min-height ease 0.4s",
  },
}));

interface SearchResultProps {
  result: Artist;
  delay: number;
  searchString: string;
}

function SearchResult({ result, delay, searchString }: SearchResultProps) {
  const { id, name, heroImage } = result;
  const url = paths.artistProfile(id);

  return (
    <ListItem delay={delay}>
      <FlexRow flexWrap="nowrap" gap="10px">
        <SThumbnail src={heroImage} alt="" shape="circle" />
        <SLink to={url} onClick={createTrackOpenSearch(url, searchString)}>
          <Title size="xs">{name}</Title>
        </SLink>
      </FlexRow>
    </ListItem>
  );
}

const SHr = styled(Hr)(({ theme }) => ({
  backgroundColor: theme.colors.fg,
  opacity: ".15",
  margin: "2px 0",
  height: 1,
}));

const ListItem = styled(FadeIn)({
  listStyleType: "none",
  padding: 0,
  margin: 0,
  position: "relative",
}).withComponent("li");

const SThumbnail = styled(Thumbnail)({
  width: 40,
  height: 40,
  flex: "0 0 40px",
});

const SLink = styled(Link)({
  textDecoration: "none",
  display: "inline-block",
  lineHeight: "1.3",
  "&::after": {
    content: "''",
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
  },
});

const Title = styled(Text)(({ theme }) => ({
  fontWeight: "bold",
  margin: 0,
  small: {
    fontWeight: "normal",
    letterSpacing: theme.spacing.letterSpacing,
    margin: 0,
  },
}));

const Explore = styled.div({
  marginTop: "2rem",
  textAlign: "center",
  p: {
    textTransform: "unset",
    marginBottom: 5,
  },
});

export { GlobalSearch };
