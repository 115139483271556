import { useSocialMetaDataForRoute } from "features/system";
import { Helmet } from "react-helmet-async";

type OpenGraphArticle = {
  type: "article";
  tags?: string[];
  modifiedTime?: string;
};

type OpenGraphProfile = {
  type: "profile";
  username?: string;
};

type OpenGraphWebsite = {
  type?: "website";
};

type HeadProps = {
  /**
   * HTML page title seen in tabs, bookmarks, search results, etc.
   */
  title?: string;
  /**
   * HTML page description seen in tabs, bookmarks, search results, etc.
   */
  description?: string;
  /**
   * Optional title for social share embeds that might differ from the page title
   */
  socialTitle?: string;
  /**
   * Optional description for social share embeds that might differ from page description
   */
  socialDescription?: string;
  /**
   * Social share embed asset source
   */
  socialImage?: string;
  socialImageAltText?: string;
  /**
   * Social share embed link location
   */
  url?: string;
  /**
   * HTML robots meta tag content
   */
  robots?: string;
} & (OpenGraphArticle | OpenGraphProfile | OpenGraphWebsite);

const fileTypes = ["jpg", "jpeg", "png", "webp", "gif"];

const isValidImageType = (src: string) => {
  const parts = src.split(".");
  const ext = parts[parts.length - 1];
  return fileTypes.includes(ext.toLowerCase());
};

/**
 * Insert <meta> tags into the document <head> for SEO and sharing
 *
 * NOTE: Twitter only supports certain image types at certain resolutions under a maximum size. For
 * example, SVGs are not supported and animated GIFs will only use first frame. See more:
 * https://developer.twitter.com/en/docs/twitter-for-websites/cards/overview/summary-card-with-large-image
 */
function Head({
  title,
  description,
  socialTitle,
  socialDescription,
  socialImage,
  socialImageAltText,
  robots,
  url = window.location.href,
  ...openGraph
}: HeadProps) {
  const data = useSocialMetaDataForRoute();

  // eslint-disable-next-line no-param-reassign
  openGraph.type = openGraph.type ?? "website";

  // Determine Twitter Card type from given props
  const image = socialImage ?? data.socialImage;
  const cardType =
    !image && isValidImageType(image) ? "summary_large_image" : "summary";

  return (
    <Helmet>
      {/* HTML Meta Tags */}
      <title>{title ?? data.pageTitle}</title>
      <meta name="description" content={description ?? data.pageDescription} />
      {robots && <meta name="robots" content={robots} />}
      {/* Open Graph Protocol */}
      <meta property="og:type" content={openGraph.type} />
      <meta property="og:title" content={socialTitle ?? data.socialTitle} />
      <meta
        property="og:description"
        content={socialDescription ?? data.socialDescription}
      />
      <meta property="og:url" content={url} />
      {image && <meta property="og:image" content={image} />}
      {image && (
        <meta
          property="og:image:alt"
          content={socialImageAltText ?? data.socialImageAltText}
        />
      )}
      {openGraph.type === "article" && !!openGraph.modifiedTime && (
        <meta
          property="article:modified_time"
          content={openGraph.modifiedTime}
        />
      )}
      {openGraph.type === "article" &&
        !!openGraph.tags?.length &&
        openGraph.tags.map((tag) => (
          <meta property="article:tag" content={tag} key={tag} />
        ))}
      {openGraph.type === "profile" && !!openGraph.username && (
        <meta property="profile:username" content={openGraph.username} />
      )}

      {/* Twitter Card */}
      <meta name="twitter:card" content={cardType} />
      <meta name="twitter:site" content="@thehugxyz" />
      <meta name="twitter:title" content={socialTitle ?? data.socialTitle} />
      <meta
        name="twitter:description"
        content={socialDescription ?? data.socialDescription}
      />
      {image && <meta name="twitter:image" content={image} />}
      {image && (
        <meta
          name="twitter:image:alt"
          content={socialImageAltText ?? data.socialImageAltText}
        />
      )}
    </Helmet>
  );
}

export { Head };
