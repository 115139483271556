import styled from "@emotion/styled";
// import sparkleSrc from "assets/images/sparkles.svg";
import { Text } from "./Typography";

type YouTubeEmbedProps = {
  embedId: string;
  text?: string;
};

function YouTubeEmbed({ embedId, text }: YouTubeEmbedProps) {
  return (
    <EmbedWrapper>
      {text && (
        <figcaption>
          <Text>{text}</Text>
        </figcaption>
      )}
      <Iframe
        src={`https://www.youtube.com/embed/${embedId}?rel=0&modestbranding=1&fs=0`}
        frameBorder="0"
        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
        allowFullScreen
        title="Embedded youtube"
      />
    </EmbedWrapper>
  );
}

const EmbedWrapper = styled.figure(({ theme }) => ({
  display: "block",
  margin: 0,
  position: "relative",
  width: "100%",
  figcaption: {
    textAlign: "center",
    p: {
      fontSize: theme.fontSizes.xs,
      fontWeight: "bold",
      "@media (min-width: 600px)": {
        marginBottom: "2rem",
        fontSize: theme.fontSizes.sm,
      },
      "@media (min-width: 900px)": {
        marginTop: "-1.2rem",
      },
    },
  },
}));
const Iframe = styled.iframe({
  aspectRatio: "16 / 9",
  display: "block",
  width: "100%",
});

export { YouTubeEmbed };
