import styled from "@emotion/styled";

type StatusBadgeProps = {
  status?: string;
};

function StatusBadge({ status }: StatusBadgeProps) {
  const statusLower = status?.toLowerCase();

  if (statusLower) {
    return <Badge status={statusLower}>{statusLower}</Badge>;
  }
  return null;
}

const Badge = styled.span<{ status?: string }>(({ status, theme }) => ({
  ...theme.fonts.display,
  backgroundColor: theme.colors.accent3LL,
  ...(status === "new" && {
    backgroundColor: theme.colors.accent3LL,
  }),
  ...(status === "accepted" && {
    backgroundColor: theme.colors.accent2LL,
  }),
  ...(status === "rejected" && {
    backgroundColor: theme.colors.accent4LL,
  }),
  borderRadius: theme.borderRadius.xs,
  color: theme.colors.black,
  display: "inline-block",
  letterSpacing: ".02em",
  lineHeight: 1.2,
  fontSize: 11,
  margin: 0,
  padding: "2px .5em",
  textDecoration: "none",
  width: "max-content",
  minWidth: "min-content",
  textTransform: "uppercase",
}));

export { StatusBadge };
