import * as React from "react";
import { parseGid } from "@shopify/hydrogen-react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import {
  AllCaps,
  Box,
  Button,
  Checkbox,
  Container,
  FakeLabel,
  FieldError,
  FlexRow,
  Form,
  Link,
  LoadingSpinner,
  NoBreak,
  RequiredText,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import { FileInput, useFileUpload, HiddenInput } from "features/images";
import { paths } from "utils/paths";
import { removeFileExt } from "utils/text";
import { fieldOptions } from "utils/form";
import { CreateProductReq, useCreateProduct } from "../../api/useCreateProduct";
import type { CreateStepProps } from "../../types";
import { ProductWizardTemplate } from "../ProductWizardTemplate";
import { ProductWizardFooter } from "../ProductWizardStep";

type UploadArtworkForm = CreateProductReq & { affirm: boolean };

function CreateStepUploadAsset({ artistId, productTemplate }: CreateStepProps) {
  const navigate = useNavigate();
  const uploadMutation = useFileUpload();
  const productMutation = useCreateProduct();

  const {
    formState: { errors },
    handleSubmit,
    register,
    setValue,
  } = useForm<UploadArtworkForm>({
    defaultValues: {
      artistId,
      templateId: productTemplate.id,
    },
  });
  const [fileName, setFileName] = React.useState<string | undefined>(undefined);

  // Once a file is selected, upload it
  const onChange = (file: File) =>
    uploadMutation.mutate(
      { file },
      {
        onSuccess: ({ url }) => {
          setFileName(file.name);
          setValue("assetUrl", url, { shouldDirty: true, shouldTouch: true });
          setValue("productName", removeFileExt(file.name));
        },
      },
    );

  // Create product after validation
  const onSubmit = handleSubmit(({ affirm: _affirm, ...formData }) =>
    productMutation.mutate(formData, {
      onSuccess: ({ shopifyProductId }) => {
        navigate(
          `${paths.artistShopProductEdit(artistId, parseGid(shopifyProductId).id)}?new=true`,
        );
      },
    }),
  );

  // Merge states of both mutations to simplify UI logic
  const isLoading = uploadMutation.isLoading || productMutation.isLoading;
  const isError = uploadMutation.isError || productMutation.isError;
  const error = uploadMutation.error ?? productMutation.error;

  // TODO: Replace with constant when merged
  // 100mb = mb * kb * b
  const MAX_UPLOAD_SIZE = 100 * 1000 * 1000;
  return (
    <Form onSubmit={onSubmit}>
      <ProductWizardTemplate
        title="Product Builder"
        subtitle="Upload Product File"
        artistId={artistId}
      >
        <Stack gap="lg">
          <Container width="md">
            <Box fullWidth>
              <Stack gap="md">
                <Stack gap="xs">
                  <Stack gap="sm">
                    <Stack gap="xxs">
                      <Text size="sm" bold style={{ color: "#262626" }}>
                        Digital Download File
                        <RequiredText />
                      </Text>
                      <Text size="xs">
                        Upload the file that you would like to sell as a digital
                        download. See <em>File Guidelines</em> for examples.
                      </Text>
                    </Stack>
                    <FlexRow>
                      {fileName && (
                        <Text style={{ margin: 0 }}>{fileName}</Text>
                      )}
                      <FileInput
                        onChange={onChange}
                        accept="any"
                        maxFileSize={MAX_UPLOAD_SIZE}
                        text={`${fileName ? "Replace" : "Upload"} File`}
                        hideRequirements
                      />
                    </FlexRow>
                    <HiddenInput
                      {...register("assetUrl", fieldOptions.required)}
                    />
                    {errors.assetUrl && (
                      <FieldError>You must upload a file</FieldError>
                    )}
                  </Stack>
                </Stack>
                {!fileName && (
                  <Stack gap="xs">
                    <FakeLabel>File Guidelines</FakeLabel>
                    <Text size="xs">
                      To upload multiple files, kindly zip them together using
                      .zip compression. The .zip format can be opened by tools
                      built in on a Mac or PC. We also recommend that you
                      include a tutorial, help file, FAQ or similar to provide a
                      better experience for those buying and using your product.
                    </Text>
                    <Text size="xs">
                      For reference, here are some recommended file formats
                      based on some commonly offered digital download
                      categories:
                    </Text>
                    <Text size="xs" as="ul">
                      <Stack gap="xxs">
                        <li>JPG, PNG, GIF, PSD, PDF, SVG, EPS, AI</li>
                        <li>TTF, OTF</li>
                        <li>JS, XML, CSS, HTML, INDD, IDML, DOCX, PPTX</li>
                        <li>EPUB, PDF</li>
                      </Stack>
                    </Text>
                    <Text size="xs">Maximum file size: 100MB</Text>
                    <Text>
                      <Link href={paths.shopHelp} target="_blank">
                        Learn more about selling digital downloads.
                      </Link>
                    </Text>
                  </Stack>
                )}
                <Stack gap="xxs">
                  <FakeLabel>
                    Confirm IP Ownership <RequiredText />
                  </FakeLabel>

                  <Checkbox
                    {...register("affirm", fieldOptions.required)}
                    label={
                      <Text as="span" size="xxs">
                        I confirm that I created and own the intellectual
                        property associated with this media, and that it does
                        not violate{" "}
                        <NoBreak>
                          <AllCaps>Hug</AllCaps>
                          &apos;s{" "}
                          <Link to="/terms-of-service" target="_blank">
                            Terms of Service
                          </Link>
                          .
                        </NoBreak>
                      </Text>
                    }
                  />
                  {errors.affirm && (
                    <FieldError>{errors.affirm.message}</FieldError>
                  )}
                </Stack>
              </Stack>
            </Box>
          </Container>

          {isLoading && <LoadingSpinner text="Uploading" />}

          {isError && <UnexpectedError error={error} />}
        </Stack>
      </ProductWizardTemplate>

      <ProductWizardFooter>
        <Button
          variant="secondary"
          size="xs"
          onClick={() => window.location.reload()}
        >
          Back
        </Button>
        <Button size="xs" type="submit" disabled={isLoading}>
          Next
        </Button>
      </ProductWizardFooter>
    </Form>
  );
}

export { CreateStepUploadAsset };
