import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { productAdminKeys } from "./queryKeys";

type DeleteProductResp = {
  status: string;
};

const putDeleteDraftProduct = (productId: string, artistId: string) =>
  api.post<DeleteProductResp>(`/shop/products/delete`, {
    body: JSON.stringify({ productId, artistId }),
  });

const useDeleteDraftProduct = (productId: string, artistId: string) => {
  const queryClient = useQueryClient();

  return useMutation(() => putDeleteDraftProduct(productId, artistId), {
    onSuccess: () => {
      queryClient.invalidateQueries(
        productAdminKeys.list({ artistId, status: "DRAFT" }),
      );
    },
  });
};

export { useDeleteDraftProduct };
