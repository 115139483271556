import * as React from "react";
import styled from "@emotion/styled";
import {
  Button,
  FlexRow,
  H1,
  IconChevron,
  Link,
  List,
  ScreenReaderText,
  Stack,
} from "atoms";
import { Outlet, useLocation } from "react-router-dom";
import { useToggle } from "utils/hooks";

type NavigationLink = {
  linkLabel: string;
  path?: string;
  children?: NavigationLink[];
};
type SidebarNavProps = {
  /**
   * One or two words to describe the navigation section, e.g. "Admin".
   * The title also appears in the sidebar nav's mobile toggle as "{title} menu"
   */
  title: string;
  /** Add text or component(s) to the top of the sidebar.
  Default: `title` prop as text. */
  sidebarHeader?: React.ReactNode;
  links: NavigationLink[];
};

function LayoutSidebarNavigation({
  title,
  links,
  sidebarHeader,
}: SidebarNavProps) {
  const { pathname } = useLocation();

  const menuLabel =
    links.find((link) => link.path === pathname)?.linkLabel ?? title;

  const [isOpen, toggleIsOpen, setIsOpen] = useToggle(false);

  React.useEffect(() => {
    setIsOpen(false);
  }, [pathname, setIsOpen]);

  return (
    <Wrapper>
      <NavWrapper>
        <Nav>
          <Stack gap="sm">
            <SidebarHeader>
              {sidebarHeader ?? <H1 size="h3">{title}</H1>}
            </SidebarHeader>

            <NavToggleButton
              aria-expanded={isOpen}
              aria-controls="admin-nav-list"
              onClick={toggleIsOpen}
            >
              <NavPageLabel aria-hidden>{menuLabel}</NavPageLabel>
              <FlexRow gap="4px">
                <ScreenReaderText>{title}</ScreenReaderText>Menu{" "}
                <IconChevron rotate={isOpen ? 0 : 180} />
              </FlexRow>
            </NavToggleButton>
          </Stack>

          <NavList type="blank" id="admin-nav-list" isOpen={isOpen}>
            {links.map(({ linkLabel, path, children }) => {
              if (!children) {
                if (!path) {
                  return null;
                }
                return (
                  <li key={linkLabel}>
                    <NavLink
                      to={path}
                      isActive={pathname === path}
                      // onClick={() => setMenuLabel(linkLabel)}
                    >
                      {linkLabel}
                    </NavLink>
                  </li>
                );
              }
              return (
                <AdminSubMenu
                  toggleLabel={linkLabel}
                  links={children}
                  pathname={pathname}
                  key={linkLabel}
                />
              );
            })}
          </NavList>
        </Nav>
      </NavWrapper>

      <OutletWrapper>
        <Outlet />
      </OutletWrapper>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  [theme.breakpoints.desktop]: {
    display: "flex",
  },
  [theme.breakpoints.desktopXLarge]: {
    maxWidth: `calc(1440px - (${theme.spacing.gutter} * 2))`,
    marginLeft: "auto",
    marginRight: "auto",
  },
}));

const SidebarHeader = styled.div(({ theme }) => ({
  maxWidth: "100%",
  overflow: "hidden",
  paddingBottom: ".66rem",
  width: "100%",
  [theme.breakpoints.desktop]: {
    paddingLeft: "1rem",
  },
}));

const NavToggleButton = styled.button(({ theme }) => ({
  ...theme.fonts.display,
  backgroundColor: theme.colors.bg,
  display: "flex",
  alignItems: "center",
  fontWeight: "bold",
  textTransform: "uppercase",
  padding: 10,
  appearance: "none",
  border: "1px solid",
  borderColor: theme.colors.fg30,
  whiteSpace: "nowrap",
  justifyContent: "space-between",
  borderRadius: 2,
  width: "100%",
  [theme.breakpoints.desktop]: { display: "none" },
}));

const NavPageLabel = styled.span(({ theme }) => ({
  fontSize: theme.fontSizes.md,
  textTransform: "none",
}));

const childPadding = "0.5rem 0.5rem 0.5rem 1rem";

const NavWrapper = styled.div(({ theme }) => ({
  [theme.breakpoints.desktop]: {
    boxShadow: theme.boxShadow.light,
    borderRight: "1px solid rgb(0 0 0 / .1)",
  },
  [theme.breakpoints.desktopXLarge]: {
    border: 0,
    boxShadow: "none",
  },
}));

const Nav = styled.nav(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  flex: "0 1 240px",
  gap: "0 10px",
  padding: `1.5rem ${theme.spacing.gutter} 0`,
  position: "relative",
  h1: { margin: 0 },
  [theme.breakpoints.desktop]: {
    border: 0,
    minWidth: 240,
    "h1+button": { display: "none" },
    padding: "2rem 0 0 0",
    position: "sticky",
    top: "2rem",
  },
  [theme.breakpoints.desktopXLarge]: {
    alignSelf: "flex-start",
    border: 0,
    borderBottom: 0,
    borderColor: theme.colors.transparent,
    borderRadius: theme.borderRadius.sm,
    boxShadow: theme.boxShadow.light,
    marginTop: "2rem",
    marginBottom: "2rem",
    paddingTop: "1rem",
  },
}));

const NavList = styled(List, {
  shouldForwardProp: (prop) => prop !== "isOpen",
})<{ isOpen: boolean }>(({ isOpen, theme }) => ({
  border: 0,
  // borderTop: "1px solid",
  // borderTopColor: theme.colors.fg10,
  margin: "10px 0 0 auto",
  padding: 0,
  width: "100%",
  display: "block",
  [theme.breakpoints.maxDesktop]: {
    backgroundColor: theme.colors.bg,
    border: 0,
    borderRadius: theme.borderRadius.sm,
    boxShadow: theme.boxShadow.dark,
    margin: 0,
    maxWidth: `calc(100vw - ( ${theme.spacing.gutter} * 2))`,
    width: 320,
    position: "absolute",
    right: theme.spacing.gutter,
    top: "100%",
    zIndex: 5,
    ...(isOpen
      ? {
          display: "block",
        }
      : {
          display: "none",
        }),
  },
  // "&>li>a, &>li>button": {
  // boxShadow: `inset 0 -1px 0 0 ${theme.colors.fg10}`,
  // },
}));

type WithIsActive = { isActive: boolean };

const NavLink = styled(Link, {
  shouldForwardProp: (prop) => prop !== "isActive",
})<WithIsActive>(({ isActive, theme }) => ({
  ...(isActive && {
    border: 0,
    borderLeft: "3px solid rgba(0,0,0,0)",
    backgroundColor: theme.colors.accent1LL,
    borderColor: theme.colors.accent1,
    boxShadow: "none",
  }),
  color: theme.colors.bodyColor,
  display: "block",
  fontSize: 15,
  fontWeight: "bold",
  padding: "0.5rem 0.75rem",
  textDecoration: "none",
  ...(isActive && {
    backgroundColor: `${theme.colors.accent1LL}66`,
    border: 0,
    color: theme.colors.fg,
  }),
  [theme.breakpoints.desktop]: {
    padding: childPadding,
  },
  [theme.breakpoints.desktopXLarge]: {
    "li:last-of-type &": {
      boxShadow: "none",
    },
  },
  "&&:hover": {
    // color: theme.colors.fg,
    backgroundColor: theme.colors.accent1LL,
    // ...(isActive && {
    //   backgroundColor: `${theme.colors.accent1}87`,
    // }),
  },
}));

const OutletWrapper = styled.div(({ theme }) => ({
  flex: "1 1 auto",
  padding: `2rem ${theme.spacing.gutter} 4rem`,
  minHeight: "75vh",
}));

// Creates an array of child link paths
function getLinkPaths(links: NavigationLink[]) {
  const paths = links.map(({ path }) => {
    if (!path) {
      return null;
    }
    return path;
  });
  return paths;
}

function AdminSubMenu({
  toggleLabel,
  links,
  pathname,
}: {
  toggleLabel: string;
  links: NavigationLink[];
  pathname: string;
}) {
  // Gets child link paths
  const paths = getLinkPaths(links);
  // If one of the paths is the current path, a child is active...
  const isChildActive = paths.includes(pathname);
  // ...so show the child menu :)
  const [isOpen, toggleIsOpen, setIsOpen] = useToggle(isChildActive);

  React.useEffect(() => {
    setIsOpen(isChildActive);
  }, [setIsOpen, isChildActive]);

  const Id = `${toggleLabel}-menu`;
  return (
    <li>
      <SButton
        variant="text"
        size="xs"
        aria-controls={`#${Id}`}
        onClick={toggleIsOpen}
        isActive={isChildActive}
        isOpen={isOpen}
      >
        <strong>{toggleLabel}</strong> <IconChevron />
      </SButton>
      <AdminSubNav hidden={!isOpen} type="blank" id={Id}>
        {links.map(({ linkLabel, path }) => {
          if (!path) {
            return null;
          }
          return (
            <li key={linkLabel}>
              <NavLink to={path} isActive={pathname.startsWith(path)}>
                {linkLabel}
              </NavLink>
            </li>
          );
        })}
      </AdminSubNav>
    </li>
  );
}

const SButton = styled(Button, {
  shouldForwardProp: (prop: string) => !prop.startsWith("is"),
})<{ isActive: boolean; isOpen: boolean }>(({ theme, isActive, isOpen }) => ({
  border: 0,
  color: theme.colors.bodyColor,
  fontSize: 15,
  padding: "0.5rem 0.75rem",
  width: "100%",
  justifyContent: "space-between",
  textAlign: "left",
  "&, &&:hover, &&:focus-visible": {
    textDecoration: "none",
  },
  "&&:hover": {
    color: theme.colors.fg,
    backgroundColor: `${theme.colors.fg}22`,
  },
  ...(isActive && {
    backgroundColor: isOpen ? theme.colors.bg : `${theme.colors.fg}11`,
    ...(!isOpen && {
      boxShadow: `inset 0 -1px 0 0 ${theme.colors.fg30}`,
    }),
  }),
  ...(isOpen && {
    "&&": {
      boxShadow: "none",
    },
  }),
  [theme.breakpoints.desktop]: {
    border: 0,
    padding: childPadding,
  },
  svg: {
    fontSize: 13,
    transform: `rotate(${isOpen ? "0deg" : "180deg"})`,
  },
}));
const AdminSubNav = styled(List)(({ theme }) => ({
  padding: "5px 0 10px",
  boxShadow: `inset 0 -1px 0 0 ${theme.colors.fg10}`,
  a: {
    fontSize: 13,
    lineHeight: 1.2,
  },
  "a::before": {
    color: theme.colors.fg60,
    content: "'\\21B3'",
    fontSize: 10,
    paddingRight: 4,
  },
}));

const LayoutSidebarContentHeader = styled(Stack)({
  marginBottom: "2rem",
}).withComponent("header");
LayoutSidebarContentHeader.defaultProps = { gap: "xs" };

export type { NavigationLink };
export { LayoutSidebarNavigation, LayoutSidebarContentHeader };
