import * as React from "react";
import styled from "@emotion/styled";
import { inputCSS } from "atoms/Input";

type DatePickerProps = {
  bordered?: boolean;
  isInvalid?: boolean;
  preventCalendar?: boolean;
};

const DatePickerInput = styled.input<DatePickerProps>(
  ({ bordered = true, isInvalid = false, preventCalendar = false, theme }) => ({
    ...inputCSS(theme, { bordered, isInvalid }),
    display: "block",
    fontVariantNumeric: "tabular-nums",
    // textAlign: "center",
    width: "auto",
    "&::placeholder": {
      fontVariantNumeric: "tabular-nums",
    },
    "&::WebkitCalendarPickerIndicator": {
      display: preventCalendar ? "none" : undefined,
    },
    "&::WebkitDatetimeEditText": {
      color: theme.colors.fg60,
      padding: "0 0.3em",
    },
    "&::WebkitDatetimeEditDayField, &::WebkitDatetimeEditMonthField, &::WebkitDatetimeEditYearField":
      {
        color: theme.colors.fg,
      },
  }),
);

/**
 * Input component with type="date" and event handler to prevent date picker popup
 *
 * NOTE: HTML value must be in "yyyy-mm-dd" format, but will be rendered according to user's locale.
 * React-hook-form allows `valueAsDate` register option, but you will need to manually convert from
 * ISO date string with time and zone to expected format to populate field, then manually convert
 * back to valid date object or timestamp as needed. For example:
 * ```
 * const inputValue = project.mintDate.split("T")[0];
 * project.mintDate = new Date(inputValue).toISOString();
 * ```
 */
const DatePicker = React.forwardRef(
  (
    props: DatePickerProps & React.InputHTMLAttributes<HTMLInputElement>,
    ref: React.Ref<HTMLInputElement>,
  ) => {
    // Create event handler that prevents default date picking calendar popup on pressing spacebar
    const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
      if (
        props.preventCalendar &&
        (event.key === " " || event.key === "Spacebar")
      ) {
        event.preventDefault();
      }
    };

    return (
      <DatePickerInput onKeyDown={onKeyDown} {...props} type="date" ref={ref} />
    );
  },
);

export { DatePicker };
