import * as React from "react";
import { useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { useAuth } from "features/auth";
import { api } from "utils/api";
import { trackEvent } from "contexts/mixpanel";
import type { ArtistApplicationFormFields, ArtistApplication } from "../types";

/**
 * API call to create new ArtistApplication
 */
const createArtistApplication = ({
  applicationBypassId,
  referralId,
  ...application
}: ArtistApplicationFormFields) =>
  api.post<ArtistApplication>(`/artists/applications`, {
    body: JSON.stringify({
      application,
      applicationBypassId,
      referralId,
    }),
  });

/**
 * API call to update existing new ArtistApplication
 */
const updateArtistApplication = (body: ArtistApplication) =>
  api.put<ArtistApplication>(`/artists/applications/edit/${body.id}`, {
    body: JSON.stringify(body),
  });

const useArtistApplicationForm = ({
  application,
  applicationBypassId,
  referralId,
}: {
  application?: ArtistApplication;
  applicationBypassId?: string;
  referralId?: string;
}) => {
  const { user } = useAuth();

  const { handleSubmit, ...form } = useForm<
    ArtistApplicationFormFields & { agreeToTerms: boolean }
  >({
    defaultValues: application ?? {
      artistName: user?.name ?? "",
      email: user?.email ?? "",
      artMedium: [],
      artistTags: [],
      privateLocation: true,
    },
  });

  // Sometimes form loads before auth provider has full user info. Watch user object
  // and update form input if it's still empty.
  const { getValues, setValue } = form;
  React.useEffect(() => {
    const { email } = getValues();
    if (!!user?.email && !email) {
      setValue("email", user.email);
    }
  }, [user?.email, getValues, setValue]);

  const isEdit = !!application;

  // Track Successful Posts to Mix Panel (New only; not edits)
  const trackSuccess = () => {
    trackEvent({
      name: "Form Submit",
      FormName: "Artist Application",
      FormContext: user ? "With Account" : "Without Account",
    });
  };

  const { mutate: create, ...createMutation } = useMutation(
    createArtistApplication,
    { onSuccess: trackSuccess },
  );
  const { mutate: update, ...updateMutation } = useMutation(
    updateArtistApplication,
  );

  const mutation = isEdit ? updateMutation : createMutation;

  // Create form submit handler that validates then posts data to API
  // NOTE: agreeToTerms value was required to submit and is handled in validation, but not sent
  // along with the request
  const onSubmit = handleSubmit(
    ({ agreeToTerms: _agreeToTerms, links, ...formData }) => {
      const transformed = {
        ...formData,
        // Filter out empty strings from hardcoded link fields, submitting only what was entered
        links: links.filter(({ url }) => !!url),
      };
      return isEdit
        ? update({ ...application, ...transformed })
        : create({ ...transformed, referralId, applicationBypassId });
    },
  );

  return {
    ...form,
    isEdit,
    mutation,
    onSubmit,
  };
};

export { useArtistApplicationForm };
