import { Draggable, LoadingSpinner, MasonryGrid, UnexpectedError } from "atoms";
import { GalleryItemCard } from "./GalleryItemCard";
import type { DeleteItemFunction, GalleryItem } from "../types";
import { useGalleryItemOrder } from "../api/useGalleryItemUpdate";

type GalleryItemsListProps = {
  artistId: string;
  galleryId: string;
  isEditing?: boolean;
  isOrdering?: boolean;
  deleteItem?: DeleteItemFunction;
  onSuccess?: VoidFunction;
};

/** Component for displaying a gallery's items */
function GalleryItemsList({
  artistId,
  deleteItem = noop,
  galleryId,
  isEditing = false,
  isOrdering = false,
  onSuccess = noop,
}: GalleryItemsListProps) {
  const { items, reorderMove, mutation, query } = useGalleryItemOrder(
    artistId,
    galleryId,
    { onSuccess },
  );

  const renderItemNormal = (item: GalleryItem) => (
    <GalleryItemCard
      key={item.id}
      {...item}
      isEditing={isEditing}
      deleteItem={createDeleteHandler(item)}
    />
  );

  const renderItemReorder = (item: GalleryItem, index: number) => (
    <Draggable key={item.id} index={index} handleDrop={reorderMove}>
      <GalleryItemCard
        key={item.id}
        {...item}
        isEditing={isEditing}
        isOrdering
        deleteItem={createDeleteHandler(item)}
      />
    </Draggable>
  );

  if (query.isLoading) {
    return <LoadingSpinner />;
  }

  const createDeleteHandler =
    ({ id, itemType }: GalleryItem) =>
    () =>
      deleteItem({ itemId: id, itemType });

  return (
    <>
      {mutation.isError ||
        (query.isError && (
          <UnexpectedError error={mutation.error ?? query.error} />
        ))}

      <MasonryGrid
        items={items ?? []}
        renderItem={isOrdering ? renderItemReorder : renderItemNormal}
        isSmallGrid={!!galleryId && !isOrdering}
      />
    </>
  );
}

export { GalleryItemsList };
