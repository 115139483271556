import type { ArtistsRequest, ArtistApplicationSearchRequest } from "../types";

const artistKeys = {
  base: ["ARTIST"] as const,
  details: () => [...artistKeys.base, "detail"] as const,
  detail: (id?: string) => [...artistKeys.details(), id] as const,
  lists: () => [...artistKeys.base, "list"] as const,
  list: (params: ArtistsRequest) => [...artistKeys.lists(), params] as const,
  claimCode: (id?: string) => [...artistKeys.detail(id), "CLAIM-CODE"] as const,
};

const applicationKeys = {
  base: ["artistApplications"] as const,
  details: () => [...applicationKeys.base, "detail"] as const,
  detail: (id?: string) => [...applicationKeys.details(), id] as const,
  lists: () => [...applicationKeys.base, "list"] as const,
  list: (request?: ArtistApplicationSearchRequest) =>
    [...applicationKeys.lists(), request] as const,
  new: () => [...applicationKeys.lists(), "new"] as const,
};

export { artistKeys, applicationKeys };
