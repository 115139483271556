import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import type { GalleryItem } from "../types";
import { galleryKeys } from "./queryKeys";

/**
 * API to create an array of gallery items
 */
const orderGalleries = (params: { galleryIds: string[]; artistId?: string }) =>
  params.artistId
    ? api.put<GalleryItem[]>(`/gallery/artists/order`, {
        body: JSON.stringify(params),
      })
    : Promise.reject(new Error("Invalid artist id"));

/**
 * Mutation to create an array of gallery items
 */
const useGalleryOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(orderGalleries, {
    onSuccess: () => {
      queryClient.invalidateQueries(galleryKeys.base);
    },
  });
};

export { useGalleryOrder };
