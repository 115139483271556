import styled from "@emotion/styled";
import {
  Button,
  FadeIn,
  FlexRow,
  H3,
  IconChevron,
  Link,
  NoBreak,
  ScreenReaderText,
  Stack,
  Text,
} from "atoms";
import { formatDate } from "utils/date";
import { useToggle } from "utils/hooks";
import { formatCentsToDollars } from "utils/formatCentsToDollars";
import { useArtist } from "features/reviewable";
import { paths } from "utils/paths";
import { OrderTag } from "./OrderTag";
import type { ShopOrder } from "../types";
import { useAdminApproveOrder } from "../api/useAdminApproveOrder";
import { getProductTypeFromTemplateId } from "../utils/getProductTypeFromTemplateId";

type ShopOrderItemProps = {
  order: ShopOrder;
  showExpandDetails?: boolean;
};

function ShopOrderItem({ order, showExpandDetails }: ShopOrderItemProps) {
  const { mutate: approveOrder, isLoading } = useAdminApproveOrder();
  const [isOpen, toggleIsOpen] = useToggle(false);
  const artist = useArtist(order.artistId);
  const productType = getProductTypeFromTemplateId(order.templateId);

  const { fulfillmentOrderId, fulfillmentLineItemId } = order;

  return (
    <OrderRow
      id={`${order.productId}-${fulfillmentLineItemId}-${order.orderNumber}`}
    >
      <Stack gap="xs">
        <FlexRow
          justifyContent="space-between"
          gap=".66rem"
          style={{ flex: "1 1 100%" }}
        >
          <OrderTag status={order.status} />
          <FlexRow gap="8px">
            <small>{formatDate(order.createdAt)}</small> <small>|</small>
            <small>Order {order.orderNumber}</small>
          </FlexRow>
        </FlexRow>

        <FlexRow justifyContent="space-between" alignItems="flex-start">
          <Stack gap="xxs" style={{ flex: 1 }}>
            <FlexRow gap="0 6px" alignItems="baseline">
              <Title size="sm" textTransform="none" style={{ margin: 0 }}>
                {order.productTitle}
              </Title>
              {showExpandDetails && (
                <SText>
                  by{" "}
                  <Link to={paths.artistProfile(order.artistId)}>
                    {artist.data?.name}
                  </Link>
                </SText>
              )}
            </FlexRow>

            <FlexRow alignItems="flex-start" gap="0 10px">
              <SText>
                {productType}

                {!!order.variantOptions.length &&
                  order.variantOptions.map((o) => (
                    <>
                      , <NoBreak>{o.value}</NoBreak>
                    </>
                  ))}
              </SText>
            </FlexRow>
          </Stack>
          <FlexRow gap="10px 1.5rem">
            {order.shippingLabelUrl && (
              <Link
                size="xxxs"
                variant="secondary"
                to={order.shippingLabelUrl}
                target="_blank"
                download
              >
                Download Shipping Label{" "}
                <ScreenReaderText>
                  for order {order.orderNumber}
                </ScreenReaderText>
              </Link>
            )}
            {order.discountedPriceInCents && (
              <Stack gap="0">
                <Text
                  textAlign="right"
                  style={{ lineHeight: 1, marginTop: -3 }}
                >
                  <small>Price</small>
                </Text>
                <Text
                  size="sm"
                  textAlign="right"
                  bold
                  font="display"
                  style={{ fontVariantNumeric: "tabular-nums" }}
                >
                  {formatCentsToDollars(order.discountedPriceInCents)}
                </Text>
              </Stack>
            )}
          </FlexRow>
        </FlexRow>

        {showExpandDetails && (
          <FlexRow>
            {order.templateId && (
              <>
                <Text size="xxxs" style={{ margin: 0 }}>
                  {order.templateId}
                </Text>
                <small> | </small>
              </>
            )}
            {order.prodigiTracking?.prodigiOrderId && (
              <>
                <Link
                  href={paths.prodigiAdminOrder(
                    order.prodigiTracking?.prodigiOrderId,
                  )}
                  target="_blank"
                  size="xxs"
                >
                  View In Prodigi
                </Link>
                <small> | </small>
              </>
            )}
            {order.orderId && (
              <>
                <Link
                  href={paths.shopifyAdminOrder(order.orderId)}
                  target="_blank"
                  size="xxs"
                >
                  View In Shopify
                </Link>
                <small> | </small>
              </>
            )}
            {showExpandDetails && (
              <Button size="xxs" variant="text" onClick={toggleIsOpen}>
                <FlexRow gap="4px">
                  <IconChevron rotate={isOpen ? 0 : 180} />
                  <span>Details</span>
                </FlexRow>
              </Button>
            )}
          </FlexRow>
        )}

        <FadeIn
          hidden={!isOpen}
          style={{
            padding: 10,
            backgroundColor: "#efefef",
            borderRadius: 4,
            marginTop: 10,
          }}
        >
          <small>
            {fulfillmentOrderId && order.status === "UNDER-REVIEW" && (
              <Button
                onClick={() => approveOrder({ fulfillmentOrderId })}
                disabled={isLoading}
              >
                Approve Order
              </Button>
            )}
            <pre>{JSON.stringify(order, null, 2)}</pre>
          </small>
        </FadeIn>
      </Stack>
    </OrderRow>
  );
}

const OrderRow = styled.li(({ theme }) => ({
  paddingBlock: "1.75rem",
  borderBottom: "1px solid",
  borderBottomColor: theme.colors.fg20,
  marginTop: -1,
  "&:last-of-type": {
    borderBottom: 0,
  },
}));

const SText = styled(Text)({
  fontSize: 14,
  margin: 0,
});
const Title = styled(H3)({
  overflow: "hidden",
  lineClamp: "2",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
  display: "-webkit-box",
});

export { ShopOrderItem };
