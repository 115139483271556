import { useQuery } from "react-query";
import { api } from "utils/api";
import { productMediaAdminKeys } from "./queryKeys";
import { prefixGid } from "../utils/shopifyId";

type AdminProductMediaResponse = {
  media: {
    id: string;
    status: string;
    url?: string;
  }[];
};
const getProductMediaAdmin = (id?: string) =>
  id
    ? api.post<AdminProductMediaResponse>(`/shop/product-media`, {
        body: JSON.stringify({ productId: prefixGid(id) }),
      })
    : Promise.reject(new Error("Invalid product id"));

const useProductMediaAdminQuery = (id?: string) =>
  useQuery(productMediaAdminKeys.detail(id), () => getProductMediaAdmin(id), {
    enabled: !!id,
  });

export { useProductMediaAdminQuery };
