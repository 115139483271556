import styled from "@emotion/styled";
import * as React from "react";

interface WordBreakProps {
  value: string;
  on?: string | string[] | RegExp;
}

function WordBreak({ value, on = "-" }: WordBreakProps) {
  if (!value.length) {
    return <>{value}</>;
  }

  let regex: RegExp;
  // Allow for overriding regular capture behavior with custom regex
  if (on instanceof RegExp) {
    regex = on;
  } else {
    // Convert string separator to regex that will split string into array
    // while _also_ keeping the separator values inside the array
    const captures = typeof on === "string" ? [on] : on;
    regex = new RegExp(`([${captures.join("")}])`);
  }

  const pieces = value.split(regex);

  /* eslint-disable react/no-array-index-key */
  return (
    <>
      {pieces.map((piece, index) => (
        <React.Fragment key={index}>
          {piece}
          {!!piece.match(regex) && <wbr />}
        </React.Fragment>
      ))}
    </>
  );
  /* eslint-enable react/no-array-index-key */
}
const NoBreak = styled.span({
  whiteSpace: "nowrap",
});

export { WordBreak, NoBreak };
