import * as React from "react";
import styled from "@emotion/styled";
import { Image } from "@shopify/hydrogen-react";
import {
  BlockTag,
  Button,
  FakeLabel,
  FieldError,
  FlexRow,
  H3,
  IconTrashCan,
  LoadingEllipses,
  LoadingSpinner,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import {
  acceptTypes,
  HiddenInput,
  parseS3URL,
  useMultipleFileUpload,
} from "features/images";
import {
  useUpdateProductDetails,
  type UpdateProductDetailsReq,
} from "../../api/useUpdateProductDetails";
import { ProductWizardStep } from "../ProductWizardStep";
import type { StepProps } from "../../types";
import { useProductMediaAdminQuery } from "../../api/useProductMediaAdminQuery";

const maxMedia = 6;

function StepProductMedia({ dispatch, state, moveBack }: StepProps) {
  const { isLoading, isError, error, mutate } = useUpdateProductDetails(
    state.product.id,
  );

  const { refetch: refreshProductMedia, data: productMediaData } =
    useProductMediaAdminQuery(state.product.id);

  const uploadMutation = useMultipleFileUpload();

  // Create ref to focus the input button on failed validation and state to store an error message
  const inputRef = React.useRef<HTMLInputElement>(null);
  const [validationError, setValidationError] = React.useState<string>();

  // Create a single helper function to mutate the product with all required default values
  const update = (updates: UpdateProductDetailsReq) => mutate(updates);

  // Create a delete handler for each media given their ID
  const createDeleteHandler = (id: string) => () =>
    update({ mediaIDsToDelete: [id] });

  const moveNext = () => {
    // If this template has validation for this step, run it
    const validate = state.productTemplate.validate.productMedia;
    if (validate) {
      const result = validate(state.product.media);

      if (
        Array.isArray(result) ||
        typeof result === "string" ||
        result === false
      ) {
        // If validation failed, show the error and exit early
        setValidationError(
          (Array.isArray(result) ? result[0] : result) ||
            "This step is required.",
        );
        inputRef.current?.focus();
        return;
      }
    }
    // If validation passed, or there is none, move to next step
    dispatch({ type: "moveNext" });
  };

  const addFiles: React.ChangeEventHandler<HTMLInputElement> = async ({
    currentTarget: { files },
  }) => {
    if (files) {
      uploadMutation.mutate([...files].slice(0, numberRemaining), {
        onSuccess: (resp) => {
          // We've successfully uploaded media so update the Product with their urls
          update({
            mediaURLsToAdd: resp.map(({ url }) => parseS3URL(url)),
          });
        },
      });
    }
  };

  const productMedia = React.useMemo(
    () => productMediaData?.media ?? [],
    [productMediaData?.media],
  );

  const mediaQuantity = productMedia?.length;
  const isProcessing = productMedia.find((m) => m.status !== "READY");

  React.useEffect(() => {
    if (isProcessing) {
      const timeout = setTimeout(() => refreshProductMedia(), 500);
      return () => clearTimeout(timeout);
    }
    return noop;
  }, [isProcessing, refreshProductMedia]);

  const isUploading = isLoading || uploadMutation.isLoading;

  // TODO: filter product media by unattached media
  const numberRemaining = 6 - state.product.media.length;
  const itemsRemaining = [
    ...Array(numberRemaining === 0 ? 0 : numberRemaining - 1).keys(),
  ];
  const showUploadButton = !isUploading && numberRemaining > 0;

  return (
    <ProductWizardStep
      stepTitle="Product Images"
      state={state}
      moveBack={moveBack}
      nextStep={moveNext}
      nextStepDisabled={isUploading}
    >
      <Stack gap="sm">
        <Stack>
          <FakeLabel>
            {mediaQuantity}/{maxMedia} Images Added
          </FakeLabel>
          <Stack gap="0">
            <Text size="xs">
              Add images to your product listing to better showcase or describe
              what you are selling.
            </Text>
            <Text size="xxs">
              Supports {acceptTypes.image.labels.join(", ")} files. 10MB max
              file size.
            </Text>
          </Stack>
          {isUploading && <LoadingSpinner text="Updating" />}
        </Stack>
        <AdditionalMediaGrid>
          {!!productMedia.length &&
            productMedia.map((media, index) => (
              <MediaItem key={media.id}>
                {media.url ? (
                  <Image
                    data={media}
                    aspectRatio="1"
                    sizes="(min-width: 830px) 300px, 50vw"
                    height="300"
                  />
                ) : (
                  <div
                    style={{
                      display: "grid",
                      placeContent: "center",
                      height: "100%",
                    }}
                  >
                    <H3 size="sm" style={{ margin: 0 }}>
                      <FlexRow gap="0px" flexWrap="nowrap">
                        Processing
                        <LoadingEllipses />
                      </FlexRow>
                    </H3>
                  </div>
                )}
                {index === 0 && (
                  <FeaturedTag bg="accent3LL" color="fg" size="xs">
                    Featured Image
                  </FeaturedTag>
                )}
                <DeleteButton
                  variant="circle"
                  aria-label="Delete Image"
                  onClick={createDeleteHandler(media.id)}
                  disabled={isLoading}
                >
                  <IconTrashCan />
                </DeleteButton>
              </MediaItem>
            ))}
          {showUploadButton && (
            <MediaItem
              style={{
                backgroundColor: "#efefef",
                display: "grid",
                placeContent: "center",
              }}
            >
              <AddMediaButton as="label" variant="secondary">
                Upload Image
                <HiddenInput
                  type="file"
                  onChange={addFiles}
                  accept={acceptTypes.image.values}
                  multiple
                  ref={inputRef}
                />
              </AddMediaButton>
            </MediaItem>
          )}

          {/*
          {itemsRemaining.map((n) => (
            // LoadingPlaceholder component can be used in the image spot when images are loading
            <LoadingPlaceholder key={n}>
              <LoadingEllipses />
            </LoadingPlaceholder>
          ))}
          */}
          {itemsRemaining.map((n) => (
            <MediaPlaceholder key={n} />
          ))}
        </AdditionalMediaGrid>

        {validationError && <FieldError>{validationError}</FieldError>}

        {/* {uploadMutation.isLoading && (
          <p>
            uploading files <LoadingEllipses />
          </p>
        )} */}
        {uploadMutation.isError && (
          <UnexpectedError error={uploadMutation.error} />
        )}

        {isError && <UnexpectedError error={error} />}
      </Stack>
    </ProductWizardStep>
  );
}

StepProductMedia.displayName = "Images";

const AdditionalMediaGrid = styled.div(({ theme }) => ({
  display: "grid",
  gap: theme.spacing.sm,
  gridTemplateColumns: "1fr",
  [theme.breakpoints.xs]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  [theme.breakpoints.desktop]: {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
  "&>div:nth-of-type(1n+7)": {
    display: "none",
  },
}));

const MediaItem = styled.div(({ theme }) => ({
  aspectRatio: 1,
  overflow: "hidden",
  // display: "grid",
  // placeContent: "center",
  position: "relative",
  border: "1px solid",
  borderColor: theme.colors.fg20,
  borderRadius: theme.borderRadius.sm,
  padding: 10,
  img: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
}));
const FeaturedTag = styled(BlockTag)({
  position: "absolute",
  top: 14,
  left: 14,
});

const MediaPlaceholder = styled.div(({ theme }) => ({
  aspectRatio: 1,
  border: "1px dashed",
  borderColor: theme.colors.fg20,
  borderRadius: theme.borderRadius.sm,
}));

// const LoadingPlaceholder = styled(MediaPlaceholder)(({ theme }) => ({
//   display: "grid",
//   placeItems: "center",
//   fontSize: 60,
//   fontWeight: "bold",
//   lineHeight: 1,
//   color: theme.colors.fg30,
//   borderStyle: "solid",
//   backgroundColor: theme.colors.fg01,
// }));

const DeleteButton = styled(Button)(({ theme }) => ({
  position: "absolute",
  top: 5,
  right: 5,
  border: "1px solid",
  borderColor: theme.colors.fg20,
  transition: ".2s ease all",
  "&:hover": {
    borderColor: theme.colors.transparent,
  },
}));

const AddMediaButton = styled(Button)({
  position: "static",
  "&::before": {
    content: "''",
    position: "absolute",
    left: 0,
    right: 0,
    top: 0,
    bottom: 0,
  },
});

export { StepProductMedia };
