import * as React from "react";

type DraggableProps = {
  children: React.ReactElement;
  index: number;
  handleDrop: (indexFrom: number, indexTo: number) => void;
};

/**
 * Turn any element into a draggable element
 *
 * Provide the item's index in a list, usually from the map function, and a function to handle
 * the onDrop event. This takes the index of the dragged item and the index of the item dropped
 * upon and handles the logic to rearrange as needed. See the `move` or `swap` methods from
 * `useFieldArray` hook.
 *
 * Usage:
 * ```
 * {items.map((item, index) => (
 *   <Draggable key={item.id} index={index} handleDrop={move}>
 *     <li>{item.name}</li>
 *   </Draggable>
 * ))}
 * ```
 */
function Draggable({ children, index, handleDrop }: DraggableProps) {
  /**
   * Handle marking an element when it starts being dragged
   */
  const onDragStart: React.DragEventHandler<HTMLElement> = (event) => {
    const { classList } = event.currentTarget;
    classList.add("drag-start", "dragging");
    setTimeout(() => classList.remove("drag-start"), 1);
    // Store this item's index in event data to be used by another element's drop event
    event.dataTransfer.setData("text/plain", index.toString());
    event.dataTransfer.dropEffect = "move";
  };

  /**
   * Handle marking when it is being dragged over by another element
   */
  const onDragOver: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
    event.currentTarget.classList.add("dragging-over");
  };

  /**
   * Handle dragging element entering bounding box of drop zone
   * NOTE: empty but needed for drop event to work correctly
   */
  const onDragEnter: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
  };

  /**
   * Handle dragging element leaving bounding box of drop zone
   */
  const onDragLeave: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove("dragging-over");
  };

  /**
   * Handle releasing dragged element, needed to cancel drag event or for drop event
   */
  const onDragEnd: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove("dragging");
  };

  /**
   * Handle dropping element in dropzone
   */
  const onDrop: React.DragEventHandler<HTMLElement> = (event) => {
    event.preventDefault();
    event.currentTarget.classList.remove("dragging-over");
    // Get the dragging item's index from event data
    const fromIndex = event.dataTransfer.getData("text/plain");
    handleDrop(fromIndex ? Number(fromIndex) : index, index);
  };

  // Clone the single child element and add the new props for drag and drop events
  return React.cloneElement(children, {
    draggable: true,
    onDragStart,
    onDragOver,
    onDragEnter,
    onDragLeave,
    onDragEnd,
    onDrop,
  });
}

export { Draggable };
