import * as React from "react";
import styled from "@emotion/styled";

const Label = styled.label(({ theme }) => ({
  ...theme.fonts.body,
  alignItems: "baseline",
  cursor: "pointer",
  display: "flex",
  fontSize: theme.fontSizes.sm,
  gap: theme.spacing.sm,
  marginBottom: theme.spacing.md,
  marginTop: theme.spacing.sm,
  maxWidth: "max-content",
  fontWeight: "700",
  userSelect: "none",
  div: {
    fontWeight: "400",
    "p:last-of-type": {
      marginBottom: 0,
    },
  },
  "&:has([disabled])": {
    cursor: "not-allowed",
    "input + *": {
      opacity: 0.6,
    },
  },
}));

const borderWidth = 2;

const RadioControl = styled.input(({ theme }) => ({
  appearance: "none",
  background: theme.colors.bg,
  border: `${borderWidth}px solid currentColor`,
  borderRadius: theme.borderRadius.round,
  color: "currentcolor",
  cursor: "pointer",
  display: "grid",
  font: "inherit",
  height: "1.25em",
  margin: 0,
  placeContent: "center",
  width: "1.25em",
  "&::before": {
    content: "''",
    // Fallback for high-contrast mode
    backgroundColor: "CanvasText",
    // Better results than background which might be removed (e.g. printing)
    boxShadow: "inset .9em .9em currentColor",
    // For the checkmark shape
    clipPath: "polygon(43% 71%, 84% 14%, 95% 24%, 43% 95%, 11% 54%, 23% 45%)",
    height: ".9em",
    // Start hidden, only visible when [checked]
    opacity: 0,
    width: ".9em",
  },
  "&:checked": {
    background: theme.colors.fg,
    borderColor: theme.colors.fg,
    color: theme.colors.bg,
    "&::before": {
      opacity: 1,
    },
  },
  "&[disabled]": {
    opacity: 0.6,
    cursor: "not-allowed",
  },
}));

type RadioProps = {
  label: React.ReactNode;
  className?: string;
} & React.ComponentProps<typeof RadioControl>;

const Radio = React.forwardRef<HTMLInputElement, RadioProps>(
  ({ label, className, ...props }, ref) => (
    <Label className={className}>
      <RadioControl type="radio" {...props} ref={ref} />
      <div>{label}</div>
    </Label>
  ),
);

export { Radio };
