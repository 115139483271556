import styled from "@emotion/styled";

type PortfolioColumnsProps = {
  sidebar: React.ReactNode;
  children: React.ReactNode;
};

function PortfolioColumns({
  sidebar,
  children,
  ...props
}: PortfolioColumnsProps) {
  return (
    <Wrapper {...props}>
      <Sidebar>{sidebar}</Sidebar>
      <Content>{children}</Content>
    </Wrapper>
  );
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "1rem",
  [theme.breakpoints.desktop]: {
    flexWrap: "nowrap",
  },
}));
const Sidebar = styled.div(({ theme }) => ({
  flex: "0 0 100%",
  [theme.breakpoints.desktop]: {
    flex: "0 0 250px",
  },
  [theme.breakpoints.desktopLarge]: {
    flex: "0 0 24%",
  },
}));
const Content = styled.div(({ theme }) => ({
  flex: "0 0 100%",
  position: "relative",
  overflow: "hidden",
  borderRadius: theme.borderRadius.sm,
  [theme.breakpoints.desktop]: {
    flex: "1",
  },
}));

export { PortfolioColumns };
