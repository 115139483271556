import styled from "@emotion/styled";
import { FlexRow } from "./FlexRow";
import { Avatar } from "./Avatar";
import { Text } from "./Typography";
import { Link } from "./Link";

type NamedAvatarProps = {
  name: string;
  src:
    | string
    | { url150?: string; url300?: string; url600?: string; url: string };
  link?: string;
};

function NamedAvatar({ name, src, link, ...props }: NamedAvatarProps) {
  let srcStr: string;
  if (typeof src === "string") {
    srcStr = src;
  } else {
    srcStr = src.url150 ?? src.url300 ?? src.url600 ?? src.url;
  }

  return (
    <SFlexRow gap="6px" flexWrap="nowrap" {...props}>
      {link && (
        <SLink translate="no" to={link}>
          {name}
        </SLink>
      )}
      <Avatar src={srcStr} size={32} aria-hidden />
      <Name translate="no" aria-hidden={!!link}>
        {name}
      </Name>
    </SFlexRow>
  );
}

const SFlexRow = styled(FlexRow)({
  display: "inline-flex",
  overflow: "hidden",
  position: "relative",
  textOverflow: "ellipsis",
  transition: ".2s ease all",
}).withComponent("span");

const SLink = styled(Link)({
  display: "block",
  borderRadius: "inherit",
  opacity: 0,
  position: "absolute",
  textDecoration: "none",
  content: "''",
  inset: 0,
  zIndex: 3,
});

const Name = styled(Text)(({ theme }) => ({
  ...theme.fonts.display,
  color: theme.colors.fg,
  fontSize: theme.fontSizes.xs,
  margin: 0,
  flex: "1 1 auto",
  lineClamp: "2",
  WebkitLineClamp: "2",
  lineHeight: 1,
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitBoxOrient: "vertical",
  wordBreak: "break-word",
  "a &": {
    textDecoration: "underline",
  },
}));

export { NamedAvatar };
