import type { GalleryItem } from "../types";

/**
 * Sort method to order gallery array by their sequence value, handling undefined values
 */
const sortBySequence = (a: GalleryItem, b: GalleryItem) =>
  (a.sequence ?? Number.MAX_SAFE_INTEGER) -
  (b.sequence ?? Number.MAX_SAFE_INTEGER);

export { sortBySequence };
