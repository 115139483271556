import * as React from "react";
import styled from "@emotion/styled";
import uniqueId from "lodash/uniqueId";
import {
  useController,
  type Control,
  type FieldPathByValue,
  type FieldValues,
} from "react-hook-form";
import { fieldOptions } from "utils/form";
import { useToggle } from "utils/hooks";
import { pluralize } from "utils/text";
import { IconChevron, IconClose } from "./icons";
import { Label, RequiredText } from "./Label";
import { FieldError } from "./FieldError";

type OptionValue = string | number;
type OptionLabel = string;
type ValueArray<T> = T[] | (T[] | undefined);

type OptionType<TOptionValue extends OptionValue> = {
  label?: OptionLabel;
  value: TOptionValue;
};

type MultiSelectProps<
  TFieldValues extends FieldValues,
  TOptionValue extends OptionValue,
  TPath extends FieldPathByValue<TFieldValues, ValueArray<TOptionValue>>,
> = {
  /** Control object provided from `useForm()` hook */
  control: Control<TFieldValues>;
  /** Text for the field's description */
  description?: React.ReactNode;
  /** Prevent input change events */
  disabled?: boolean;
  /** Display message if still loading async options */
  isLoading?: boolean;
  /** Text for the field's label element */
  label: React.ReactNode;
  /** Name of the field, will be registered using useForm control */
  name: TPath;
  /** Array of options to select from */
  options: OptionType<TOptionValue>[];
  /** Optional placeholder text when empty */
  placeholder?: string;
  /** Is field required for form validation */
  required?: boolean;
  /** Error message if field failed form validation */
  error?: string;
  /**
   * Prevent dropdown from "floating" over content below it.
   *
   * Instead, this keeps the options in normal flow and pushes subsequent content lower. Helpful
   * for when the options dropdown gets cut off or hidden.
   */
  isStatic?: boolean;
};

/**
 * Faux focus state for this component
 *
 * NOTE: We aren't tracking the DOM's actively focused element, that is handled separately.
 */
type FocusState = {
  /** What portion of the component is the user focused on */
  on: null | "input" | "option" | "selection";
  /** Index of focused option or selection */
  index: number;
};

const initialFocus: FocusState = { on: null, index: 0 };

/**
 * Form input for selecting from an array of strings with autocomplete and keyboard controls
 *
 * NOTE: We're providing keyboard accessible events via the input element we use for searching, so
 * there is no need to duplicate those keyboard event handlers on all of the elements strictly using
 * mouse events (e.g. the options to select in the dropdown). Hence all the ESLint disabling. These
 * comments are strictly placed and scoped to limit false negatives. Be careful!
 */
function MultiSelect<
  TFieldValues extends FieldValues,
  TOptionValue extends OptionValue,
  TPath extends FieldPathByValue<TFieldValues, ValueArray<TOptionValue>>,
>({
  control,
  description,
  disabled = false,
  error,
  isLoading = false,
  label,
  name,
  options,
  placeholder = "Select...",
  required,
  isStatic = false,
  // TODO: add props for more validation options: min, max, custom?
  ...props
}: MultiSelectProps<TFieldValues, TOptionValue, TPath>) {
  // Connect this field to useForm control
  const { field } = useController({
    control,
    name,
    rules: required ? fieldOptions.required : {},
  });

  // Check if options array contains options
  const hasOptions = options.length > 0;

  // Set up our own state to hold selections purely for UI elements within this component
  const [selections, setSelections] = React.useState<OptionValue[]>(
    field.value ?? [],
  );

  /** Ref for search input element */
  const inputRef = React.useRef<HTMLInputElement | null>(null);

  // Merge our input ref with one provided by useController so useForm can focus it on validation
  // errors. Don't use directly. Instead use inputRef for interactions within this component.
  const mergedRef = React.useCallback(
    (node: HTMLInputElement) => {
      inputRef.current = node;
      field.ref(node);
    },
    [field],
  );

  // State to hold value of text search input
  const [search, setSearch] = React.useState("");
  // TODO: debounce search value?

  // Unique id for this component's list of options for aria controls
  const [listBoxId] = React.useState(`multiselect-${uniqueId()}`);

  /** Ref for focused option element to control scroll visibility */
  const focusedRef = React.useRef<HTMLDivElement | null>(null);

  // Set up state to track "focused" element via index of the option or the selection
  const [focus, setFocus] = React.useState<FocusState>(initialFocus);

  // Track last action for announcing changes for accessibility
  const [lastAction, setLastAction] = React.useState("");

  /**
   * Utility to help map options' labels by their value
   */
  const labelMap = React.useMemo(
    () =>
      options.reduce<Record<OptionValue, OptionLabel>>(
        (acc, cur) => ({
          ...acc,
          [cur.value]: cur.label ?? cur.value.toString(),
        }),
        {},
      ),
    [options],
  );

  /**
   * Array of selectable values based on current selections and search matching
   */
  const filteredValues = React.useMemo(() => {
    // Find options that aren't selected
    const unselected = options.filter(
      (option) => !selections.includes(option.value),
    );

    // If there's a search value, find any that match without case sensitivity
    // TODO: better fuzzy search?
    const searchStr = search.trim().toLowerCase();
    const filtered = searchStr
      ? unselected.filter((option) =>
          (option.label ?? option.value.toString())
            .toLowerCase()
            .includes(searchStr),
        )
      : unselected;

    // Return array of options' values. Use `labelMap` helper for displaying label to user.
    return filtered.map(({ value }) => value);
  }, [options, selections, search]);

  /**
   * Arrays of labels for accessibility
   */
  const a11yLabels = React.useMemo(
    () => ({
      options: filteredValues.map((o) => labelMap[o]),
      selections: selections.map((s) => labelMap[s]),
    }),
    [labelMap, filteredValues, selections],
  );

  // State to track visibility of selectable options
  const [optionsOpen, toggleOptions, setOptionsOpen] = useToggle(false);
  /** Options have been toggled open by user interaction or there is an entered search value */
  const showingOptions = optionsOpen || !!search;

  /** All options have been selected */
  const noMoreOptions = selections.length === options.length;

  // Ensure focus index is never out of bounds if search value reduces number of filtered options
  React.useEffect(() => {
    if (focus.on === "option" && focus.index >= filteredValues.length) {
      setFocus({ on: "option", index: Math.max(filteredValues.length - 1, 0) });
    }
  }, [filteredValues, focus.index, focus.on, setFocus]);

  // Make sure options list scrolls to next focused element
  React.useLayoutEffect(() => {
    if (focus.on === "option" && focusedRef.current) {
      focusedRef.current.scrollIntoView({ block: "nearest", inline: "start" });
    }
  }, [focus.on, focus.index]);

  /**
   * Event handler for the _entire_ component losing focus
   *
   * NOTE: For this to work, the wrapper needs to have tabIndex of -1. If tabIndex wasn't defined,
   * then relatedTarget or activeElement might not be correct. But we don't want the wrapper to be
   * focusable itself, hence the -1 value.
   */
  const onBlur: React.FocusEventHandler<HTMLDivElement> = ({
    currentTarget,
    relatedTarget,
  }) => {
    // Wait for next tick in event loop for blur to finish and new focus to be set
    setTimeout(() => {
      // Check if the new focus is outside of this component
      if (!currentTarget.contains(relatedTarget ?? document.activeElement)) {
        setSearch("");
        setOptionsOpen(false);
        setFocus({ on: null, index: 0 });
        setLastAction("");
        field.onBlur();
      }
    }, 0);
  };

  /**
   * Update form state and UI state with new set of selections resulting from user interaction
   */
  const onChange = (newSelections: OptionValue[]) => {
    setSelections(newSelections);
    field.onChange(newSelections);
    field.onBlur();
  };

  /**
   * Add a value to selections
   */
  const addSelection = (value: OptionValue) => {
    onChange(Array.from(new Set([...selections, value])));
    setLastAction(`option ${labelMap[value]} selected`);
  };

  /**
   * Remove a value from selections
   */
  const removeSelection = (value: OptionValue) => {
    onChange(selections.filter((selection) => selection !== value));
    setLastAction(`option ${labelMap[value]} deselected`);
  };

  /**
   * Clear all selections
   */
  const clearSelections = (event: React.MouseEvent) => {
    event.preventDefault();
    onChange([]);
    setLastAction("All selected options have been cleared");
    inputRef.current?.focus();
  };

  /** Are there any selections? */
  const hasSelections = selections.length > 0;

  /**
   * Reset menu to initial state
   */
  const resetMenu = () => {
    if (search) {
      setSearch("");
    }
    setOptionsOpen(false);
    setFocus({ on: "input", index: 0 });
  };

  /**
   * Event handler for keyboard controls, attached to wrapper element
   */
  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    // Based on focus area and index, find value from options or selections if it exists
    let focusedValue: OptionValue | undefined;
    if (focus.on === "option") {
      focusedValue = filteredValues[focus.index];
    }
    if (focus.on === "selection") {
      focusedValue = selections[focus.index];
    }

    switch (event.key) {
      case "Enter": {
        if (focus.on === "selection" && focusedValue) {
          // Delete focused selection, if any
          removeSelection(focusedValue);
          // Prevent form submission since we're removing a selection
          event.preventDefault();
        } else if (focus.on === "option" && showingOptions && focusedValue) {
          // Select focused option and clear any search value to allow typing next value
          addSelection(focusedValue);
          resetMenu();
          // Prevent form submission since we're adding a selection
          event.preventDefault();
        } else if (!focusedValue) {
          event.preventDefault();
        } else {
          // Event will propogate and probably submit a form, so close options to show UI change
          setOptionsOpen(false);
        }
        break;
      }
      case "Tab": {
        // Allow shift-tab to move focus to previous fields
        if (event.shiftKey) {
          break;
        }
        if (focus.on === "option" && showingOptions && focusedValue) {
          // Prevent focus from shifting away since we're adding a selection
          event.preventDefault();
          addSelection(focusedValue);
          resetMenu();
        }
        break;
      }
      // Space key
      case " ": {
        if (focus.on === "selection" && focusedValue) {
          removeSelection(focusedValue);
          event.preventDefault();
        } else if (
          focusedValue &&
          showingOptions &&
          (!search || filteredValues.length === 1)
        ) {
          // There is a focused option and no search input yet, or they've filtered down to one
          addSelection(focusedValue);
          resetMenu();
          // Prevent adding space character to input value since we're adding a selection
          event.preventDefault();
        }
        break;
      }
      case "Delete":
      case "Backspace": {
        if (focus.on === "selection" && selections[focus.index]) {
          // Delete focused selection, if any
          removeSelection(selections[focus.index]);
        } else if (!search && hasSelections) {
          // Search value is empty and there are selections, remove last selection
          onChange(selections.slice(0, -1));
        }
        break;
      }
      case "ArrowDown": {
        // Set focus to next option, wrapping around to first, and make sure they're showing
        const lastIndex = filteredValues.length - 1;
        const nextIndex = focus.on === "option" ? focus.index + 1 : 0;
        setFocus({
          on: "option",
          index: nextIndex > lastIndex ? 0 : nextIndex,
        });
        setOptionsOpen(true);
        // prevent cursor changing postion in search input
        event.preventDefault();
        break;
      }
      case "ArrowUp": {
        // Set focus to previous option, wrapping around to last, and make sure they're showing
        const lastIndex = filteredValues.length - 1;
        const prevIndex = focus.on === "option" ? focus.index - 1 : lastIndex;
        setFocus({
          on: "option",
          index: prevIndex < 0 ? lastIndex : prevIndex,
        });
        setOptionsOpen(true);
        // prevent cursor changing postion in search input
        event.preventDefault();
        break;
      }
      case "ArrowLeft": {
        // If not searching, set focus to previous selection, if any
        if (!search) {
          // If we weren't on a selection, start on the last. Otherwise, decrement selection index.
          const prevIndex =
            focus.on !== "selection" ? selections.length - 1 : focus.index - 1;
          // Prevent wrapping if at first selection
          setFocus({ on: "selection", index: Math.max(prevIndex, 0) });
        }
        break;
      }
      case "ArrowRight": {
        // If not searching, set focus to next selection, if any
        if (!search) {
          // If we're on last selection, move focus to input
          const nextIndex = focus.index + 1;
          if (nextIndex >= selections.length) {
            setFocus({ on: "input", index: 0 });
          } else {
            setFocus({ on: "selection", index: nextIndex });
          }
        }
        break;
      }
      default:
        break;
    }
  };

  /**
   * Create event handler to add a new selection on click
   */
  const createSelectHandler =
    (value: OptionValue) => (event: React.MouseEvent) => {
      event.preventDefault();
      addSelection(value);
      resetMenu();
      inputRef.current?.focus();
    };

  /**
   * Create event handler to remove a selection on click
   */
  const createRemoveHandler =
    (value: OptionValue) => (event: React.MouseEvent) => {
      // Default action for a click would change focus, prevent that to keep the search input in focus
      event.preventDefault();
      removeSelection(value);
      inputRef.current?.focus();
    };

  /**
   * Create event handler to "focus" a selection or option on hover
   */
  const createMouseOverHandler =
    (on: FocusState["on"], index: number) => () => {
      setFocus({ ...focus, on, index });
    };

  /**
   * Event handler for search input element changing
   */
  const searchOnChange: React.ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    // Prevent continued attempts at searching if there aren't any substring matches
    if (!noMoreOptions) {
      setSearch(event.target.value);
      // If focus wasn't already on options, move focus to first option
      if (focus.on !== "option") {
        setFocus({ on: "option", index: 0 });
      }
    }
  };

  /**
   * Event handler for opening options on click
   */
  const searchOnClick = (event: React.MouseEvent) => {
    // Handler is attached to search input, but clicking associated label will trigger a click event
    // on the input element as well. We only want to toggle options list once per click, not a few
    // times as the event propagates which might cancel them out.
    if (event.target === event.currentTarget) {
      toggleOptions();
      setFocus({ on: "option", index: 0 });
    }
  };

  /**
   * Event handler for the search input gaining focus
   */
  const searchOnFocus = () => setFocus({ ...focus, on: "input" });

  let noOptionsText = "No matching options";
  if (isLoading) {
    noOptionsText = "Loading options...";
  } else if (noMoreOptions) {
    noOptionsText = "No options left";
  }

  return (
    <Wrapper {...props} onBlur={onBlur} onKeyDown={onKeyDown} tabIndex={-1}>
      <Label>
        {label}
        {required && typeof label === "string" && <RequiredText />}
        {description && <Description>{description}</Description>}

        <AriaLiveRegion
          {...focus}
          {...a11yLabels}
          lastAction={lastAction}
          search={search}
          showingOptions={showingOptions}
        />
        <InputGroup disabled={disabled}>
          <SelectionsList>
            {/* See NOTE on keyboard accessibility */}
            {/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events, jsx-a11y/interactive-supports-focus */}
            {selections.map((selection, index) => (
              <Selection
                aria-label={`Remove ${labelMap[selection]}`}
                isFocused={focus.on === "selection" && focus.index === index}
                key={selection}
                onClick={createRemoveHandler(selection)}
                role="button"
              >
                <SelectionLabel>{labelMap[selection]}</SelectionLabel>

                <IconClose aria-hidden />
              </Selection>
            ))}
            {/* eslint-ensable jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events, jsx-a11y/interactive-supports-focus */}

            <Search
              aria-autocomplete="list"
              aria-controls={listBoxId}
              aria-expanded={showingOptions}
              aria-haspopup="true"
              autoCapitalize="none"
              autoComplete="off"
              autoCorrect="off"
              disabled={disabled}
              isFocused={
                !!search || focus.on === "input" || focus.on === "option"
              }
              isFullWidth={!hasSelections}
              onChange={searchOnChange}
              onClick={searchOnClick}
              onFocus={searchOnFocus}
              placeholder={placeholder}
              ref={mergedRef}
              role="combobox"
              size={search || !hasSelections ? search.length : 1}
              spellCheck="false"
              tabIndex={0}
              type="text"
              value={search}
            />
          </SelectionsList>

          <IconList>
            {hasSelections && (
              <>
                <IconButtonClear aria-hidden onClick={clearSelections}>
                  <IconClose />
                </IconButtonClear>
                <IconSeparator />
              </>
            )}
            <IconButton aria-hidden>
              <IconChevron rotate={180} opacity={hasOptions ? 1 : 0.33} />
            </IconButton>
          </IconList>
        </InputGroup>
      </Label>

      {showingOptions && (
        <OptionsList
          aria-multiselectable
          id={listBoxId}
          role="listbox"
          isStatic={isStatic}
        >
          {filteredValues.length ? (
            filteredValues.map((value, index) => {
              const isFocused = focus.on === "option" && focus.index === index;
              /* See NOTE on keyboard accessibility */
              /* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events */
              return (
                <Option
                  aria-label={`Select ${labelMap[value]}`}
                  aria-selected={false}
                  isFocused={isFocused}
                  key={value}
                  onClick={createSelectHandler(value)}
                  onMouseOver={createMouseOverHandler("option", index)}
                  ref={isFocused ? focusedRef : undefined}
                  role="option"
                  tabIndex={-1}
                >
                  {labelMap[value]}
                </Option>
              );
              /* eslint-enable jsx-a11y/click-events-have-key-events, jsx-a11y/mouse-events-have-key-events */
            })
          ) : (
            <NoOption>{noOptionsText}</NoOption>
          )}
        </OptionsList>
      )}

      {error && !showingOptions && <FieldError>{error}</FieldError>}
    </Wrapper>
  );
}

const Wrapper = styled.div({
  position: "relative",
});

const InputGroup = styled.div<{ disabled: boolean }>(({ disabled, theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.bg,
  border: `1px solid ${theme.colors.fg30}`,
  borderBottomColor: theme.colors.fg60,
  display: "flex",
  justifyContent: "space-between",
  margin: "0.2rem 0 0",
  minHeight: "2.5rem",
  minWidth: 0,
  "&,&*": {
    cursor: disabled ? "default !important" : "pointer",
  },
  "&:focus-within": {
    outline: `2px solid ${theme.colors.fg}`,
    outlineOffset: 2,
  },
}));

const SelectionsList = styled.div({
  display: "flex",
  alignItems: "center",
  flex: "1 1 0%",
  flexWrap: "wrap",
  gap: 8,
  padding: "0.5rem 10px .5rem 1.2rem",
  minWidth: 0,
});

/** Element has props to style our faux focused state */
type Focusable = {
  isFocused: boolean;
};

const Selection = styled.div<Focusable>(({ isFocused, theme }) => ({
  ...theme.fonts.body,
  alignItems: "center",
  backgroundColor: isFocused ? theme.colors.bg : theme.colors.accent1LL,
  border: `2px solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.round,
  color: isFocused ? theme.colors.fg : "currentColor",
  cursor: "pointer",
  display: "flex",
  fontSize: theme.fontSizes.xs,
  fontWeight: "normal",
  gap: 8,
  userSelect: "none",
  padding: "2px 8px 1px",
  minWidth: 0,

  svg: {
    color: isFocused ? theme.colors.accent4 : "currentColor",
    width: 10,
    height: 10,
    flex: "0 0 10px",
    transform: "translateY(-1px)",
  },
  "&:hover": {
    backgroundColor: theme.colors.fg05,
    svg: {
      color: theme.colors.accent4,
    },
  },
}));

const SelectionLabel = styled.span({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  minWidth: 0,
});

const Search = styled.input<Focusable & { isFullWidth: boolean }>(
  ({ isFocused, isFullWidth, theme }) => ({
    border: 0,
    backgroundColor: theme.colors.transparent,
    fontSize: 16,
    padding: 0,
    margin: 0,
    // NOTE: width is controlled by `size` attribute which will match the length of its value
    minWidth: 2,
    width: isFullWidth ? "100%" : "unset",
    opacity: isFocused ? 1 : 0,
    "&:focus": {
      outline: 0,
    },
    "&::placeholder": {
      opacity: isFullWidth ? 0.6 : 0,
      userSelect: "none",
    },
  }),
);

const IconList = styled.div({
  display: "flex",
  flexDirection: "row",
  alignSelf: "stretch",
});

// See NOTE on accessibility, this is intentionally not a button
const IconButton = styled.div({
  alignItems: "center",
  display: "flex",
  padding: 12,
});

const IconButtonClear = styled(IconButton)(({ theme }) => ({
  "&:hover svg": {
    color: theme.colors.accent4,
  },
}));

const IconSeparator = styled.span(({ theme }) => ({
  background: theme.colors.gray20,
  width: 1,
  margin: "8px 0",
}));

const OptionsList = styled.div<{ isStatic: boolean }>(
  ({ isStatic, theme }) => ({
    background: theme.colors.bg,
    borderRadius: `0 0 ${theme.borderRadius.sm} ${theme.borderRadius.sm}`,
    margin: "0 0 8px 0",
    maxHeight: 350,
    overflowY: "auto",
    ...(isStatic
      ? {
          position: "relative",
          border: "1px solid",
          borderColor: theme.colors.fg30,
          borderTop: 0,
        }
      : {
          boxShadow: theme.boxShadow.dark,
          position: "absolute",
          top: "100%",
          width: "100%",
          zIndex: 10,
        }),
  }),
);

const BaseOption = styled.div({
  padding: "0.5rem 1.2rem",
  userSelect: "none",
});

const Option = styled(BaseOption)<Focusable>(({ isFocused, theme }) => ({
  backgroundColor: isFocused
    ? theme.colors.accent1LL
    : theme.colors.transparent,
  color: isFocused ? theme.colors.fg : "currentColor",
  cursor: "pointer",
}));

const NoOption = styled(BaseOption)(({ theme }) => ({
  color: theme.colors.gray60,
}));

type AriaLiveRegionProps = FocusState & {
  lastAction: string;
  options: string[];
  search: string;
  selections: string[];
  showingOptions: boolean;
};

function AriaLiveRegion({
  index,
  lastAction,
  on,
  options,
  search,
  selections,
  showingOptions,
}: AriaLiveRegionProps) {
  const values = on === "option" ? options : selections;
  const value = values[index];
  const valueText = on === "selection" ? `Value ${value} focused` : value;
  const ordinal = index + 1;
  const total = values.length;
  const searchText = search ? ` for search term ${search}` : "";
  const showingLiveRegion = on === "selection" || showingOptions || lastAction;
  const selectionsJoined = selections.join(", ");

  return (
    <>
      <AriaText>
        {on === "input" && selections.length > 0 && (
          <span>
            {pluralize(selections.length, "Option")} {selectionsJoined}{" "}
            selected.
          </span>
        )}
        {on === "input" && (
          <span>
            Select is focused, type to filter options, press down to open menu
            or left to focus on selected values.
          </span>
        )}
      </AriaText>

      <AriaText
        aria-live="polite"
        aria-atomic="false"
        aria-relevant="additions text"
        role="log"
      >
        {showingLiveRegion && (
          <>
            <span>{!!lastAction && `${lastAction}. `}</span>
            {on !== "input" && !!value && (
              <span>
                {valueText}, {ordinal} of {total}.{" "}
              </span>
            )}
            {on === "option" && (
              <span>
                {options.length} results available for {searchText}.{" "}
              </span>
            )}
            {on === "selection" && (
              <span>
                Use left and right to choose selected value, press Delete or
                Backspace to remove the focused value.
              </span>
            )}
            {on === "option" && (
              <span>
                Use up and down keys to choose options, press Enter or Tab to
                select the focused option, press Escape to close the menu.
              </span>
            )}
          </>
        )}
      </AriaText>
    </>
  );
}

const AriaText = styled.span({
  border: 0,
  clip: "rect(1px,1px,1px,1px)",
  height: 1,
  padding: 0,
  position: "absolute",
  overflow: "hidden",
  whiteSpace: "nowrap",
  width: 1,
  zIndex: 9999,
});
const Description = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xxs,
  fontWeight: "normal",
}));

export { MultiSelect };
