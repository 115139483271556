import * as React from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "utils/paths";
import { useShopWizardReducer } from "../utils/useShopWizardReducer";
import { StepDone } from "./productWizardSteps/StepDone";

type ProductWizardProps = Parameters<typeof useShopWizardReducer>[0];

function ProductWizard({
  artistId,
  product,
  productTemplate,
}: ProductWizardProps) {
  const navigate = useNavigate();

  // Initialize wizard reducer state with fetched and validated data from parent
  const [state, dispatch] = useShopWizardReducer({
    artistId,
    product,
    productTemplate,
  });

  // Every time the product changes in query cache, update it in reducer's state
  React.useEffect(() => {
    dispatch({ type: "updateProduct", product });
  }, [dispatch, product]);

  const CurrentStep = state.requiredSteps[0] ?? StepDone;

  const exit = () => navigate(paths.artistShopEdit(artistId));
  const moveBack = state.completedSteps.length
    ? () => dispatch({ type: "moveBack" })
    : exit;

  return <CurrentStep state={state} dispatch={dispatch} moveBack={moveBack} />;
}

export { ProductWizard };
