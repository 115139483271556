import { Theme } from "@emotion/react";
import styled from "@emotion/styled";

type Gap = keyof Theme["spacing"]["stack"];

type StackProps = {
  gap?: keyof Theme["spacing"]["stack"] | string;
  children: React.ReactNode;
};

const Stack = styled.div<StackProps>(({ gap = "xs", theme }) => ({
  "&&>*": {
    marginTop: 0,
    marginBottom: 0,
  },
  "&&>*+*": {
    marginTop:
      gap in theme.spacing.stack
        ? `${theme.spacing.stack[gap as Gap]}rem`
        : gap,
  },
}));

export { Stack };
