import * as React from "react";
import { useInfiniteQuery, useQuery } from "react-query";
import { useAuth } from "features/auth";
import type { Artist } from "features/reviewable/types";
import { ApiError, api } from "utils/api";
import { searchKeys } from "./queryKeys";
import type { SearchRequest, SearchResponse } from "../types";

// SORT - Pass an array of field names to sort. Add "desc" after to sort descending
// sort: ['mintStatus', 'mintDate desc']

const postSearch = (request?: SearchRequest) => {
  if (!request) {
    return Promise.resolve({ searchAfter: "", data: [], totalResults: 0 });
  }
  return api.post<SearchResponse>("/projects", {
    body: JSON.stringify({
      perPage: 4,
      ...request,
      type: "ARTIST",
    }),
  });
};

/**
 * Custom hook to search for Reviewables with infinite pagination and manage state
 */
const useInfiniteSearch = (params: SearchRequest) =>
  useInfiniteQuery<SearchResponse, ApiError>(
    searchKeys.infinite(params),
    ({ pageParam }) =>
      postSearch({
        searchAfter: pageParam,
        ...params,
      }),
    {
      getNextPageParam: (lastPage: SearchResponse) => lastPage.searchAfter,
    },
  );

/**
 * Custom hook to search for Reviewable by name incrementally and manage state
 *
 * NOTE: If `name` is user input, make sure to debounce value with `useDebouncer()` util hook
 */
const useIncrementalSearch = (name: string, perPage = 4) =>
  useQuery<SearchResponse, ApiError, SearchResponse["data"]>(
    searchKeys.incremental({ name, perPage }),
    () =>
      postSearch({
        incremental: true,
        name,
        perPage,
      }),
    {
      enabled: !!name,
      select: (res) => res.data,
      keepPreviousData: true,
    },
  );

const useArtistsByIds = ({ ids }: { ids: string[] }) =>
  useQuery(
    searchKeys.default({ ids }),
    () => postSearch({ ids, perPage: 999 }),
    {
      enabled: ids.length > 0,
      select: ({ data }) => data,
    },
  );

type UsersShopAccess = {
  hasShopEnabled: boolean;
  hasShopBuilderEnabled: boolean;
  withShopEnabled: Artist[];
  withShopBuilderEnabled: Artist[];
  withoutAccess: Artist[];
};

const useUsersShopAccess = () => {
  const { user } = useAuth();
  const { data } = useArtistsByIds({
    ids: user?.claimedArtistPageIds ?? [],
  });

  return React.useMemo<UsersShopAccess>(() => {
    const { withShopEnabled, withShopBuilderEnabled, withoutAccess } = (
      data ?? []
    ).reduce<Omit<UsersShopAccess, "hasShopEnabled" | "hasShopBuilderEnabled">>(
      (acc, cur) => {
        if (cur.shopEnabled) {
          acc.withShopEnabled.push(cur);
        } else if (cur.shopBuilderEnabled) {
          acc.withShopBuilderEnabled.push(cur);
        } else {
          acc.withoutAccess.push(cur);
        }

        return acc;
      },
      { withShopEnabled: [], withShopBuilderEnabled: [], withoutAccess: [] },
    );

    return {
      hasShopEnabled: !!withShopEnabled.length,
      hasShopBuilderEnabled:
        !!withShopBuilderEnabled.length || !!withShopEnabled.length,
      withShopEnabled,
      withShopBuilderEnabled,
      withoutAccess,
    };
  }, [data]);
};

export {
  useIncrementalSearch,
  useInfiniteSearch,
  useArtistsByIds,
  useUsersShopAccess,
};
