import * as React from "react";
import styled from "@emotion/styled";
import type {
  FieldPathByValue,
  FieldValues,
  UseFormRegister,
  FieldError as TFieldError,
} from "react-hook-form";
import { useToggle } from "utils/hooks";
import { fieldOptions, validators } from "utils/form";
import { Button } from "./Button";
import { FieldError } from "./FieldError";
import { Input } from "./Input";
import { Label, RequiredText } from "./Label";
import { NoBreak } from "./WordBreak";

type PasswordInputProps<
  TFieldValues extends FieldValues,
  TFieldName extends string,
> = React.ComponentProps<"input"> & {
  /** Name of input to register with useForm hook */
  fieldName: TFieldName;
  /** Register function returned from useForm */
  register: UseFormRegister<TFieldValues>;
  /** Custom validation options for register function returned from useForm */
  registerOptions?: Parameters<UseFormRegister<TFieldValues>>[1];
  /** FieldError returned from useForm's formState.errors[fieldName] */
  error?: TFieldError;
  label?: React.ReactNode;
  showReqs?: boolean;
  /**
   * Browser's input auto-complete attribute options:
   * - "current-password" for when the user is entering their password to login
   * - "new-password" to prevent auto-complete current password for reset form or confirmation input
   * - "off" to prevent auto-complete entirely
   */
  autoComplete?: "current-password" | "new-password" | "off";
};

const defaultOptions = {
  ...fieldOptions.required,
  validate: validators.validPassword,
};

function PasswordInput<
  TFieldValues extends FieldValues,
  TFieldName extends FieldPathByValue<TFieldValues, string | undefined>,
>({
  autoComplete = "current-password",
  error,
  fieldName,
  label = "Password",
  register,
  registerOptions = defaultOptions,
  showReqs = true,
  ...props
}: PasswordInputProps<TFieldValues, TFieldName>) {
  const [isShowing, toggleIsShowing] = useToggle(false);

  return (
    <PwdWrapper {...props}>
      <Label>
        <NoBreak as="strong">
          {label}
          <RequiredText />
        </NoBreak>
        {showReqs && <Reqs>Must contain at least 10 characters</Reqs>}
        <PwdInputWrapper>
          <Input
            {...register(fieldName, registerOptions)}
            type={isShowing ? "text" : "password"}
            autoComplete={autoComplete}
            autoCapitalize="none"
          />
          <PwdToggleButton variant="blank" size="xxs" onClick={toggleIsShowing}>
            {isShowing ? "Hide" : "Show"}
          </PwdToggleButton>
        </PwdInputWrapper>
        {error && <FieldError>{error.message}</FieldError>}
      </Label>
    </PwdWrapper>
  );
}

const PwdWrapper = styled.div({});

const PwdInputWrapper = styled.div({
  position: "relative",
});

const PwdToggleButton = styled(Button)(() => ({
  border: 0,
  opacity: 0.6,
  fontSize: 13,
  padding: "4px 3px 4px 10px",
  position: "absolute",
  right: 0,
  bottom: "100%",
  "&:hover,&:focus": {
    opacity: 1,
    border: 0,
  },
}));

const Reqs = styled.small(({ theme }) => ({
  color: theme.colors.fg70,
  display: "block",
  fontSize: ".8rem",
  fontWeight: "normal",
}));

export { PasswordInput };
