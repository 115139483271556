import styled from "@emotion/styled";

type ProgressBarProps = {
  value: number;
  max: number;
};

const ProgressBar = styled.div<ProgressBarProps>(({ value, max, theme }) => ({
  backgroundColor: theme.colors.fg20,
  borderRadius: theme.borderRadius.xs,
  height: "20px",
  overflow: "hidden",
  width: "100%",

  "&>span": {
    backgroundColor: theme.colors.primary,
    borderRadius: theme.borderRadius.xs,
    display: "block",
    height: "20px",
    width: `calc((${value} / ${max}) * 100%)`,
    maxWidth: "100%",
    "&::after": {
      backgroundColor: theme.colors.primary,
      content: "''",
      position: "absolute",
      top: 0,
      bottom: 0,
      left: "-50vw",
      right: "99%",
    },
    "@media (prefers-reduced-motion: no-preference)": {
      animation: "barIn .7s .5s cubic-bezier(.38, 1.33, .64, 1) forwards",
      transform: "translateX(-101%)",
      transition: ".4s cubic-bezier(.38, 1.33, .64, 1) width",
    },
    "@keyframes barIn": {
      "0%": {
        transform: "translateX(-100%)",
      },
      "100%": {
        transform: "translateX(0%)",
      },
    },
  },
}));
export { ProgressBar };
