import styled from "@emotion/styled";

type ImageProps = {
  aspectRatio?: React.CSSProperties["aspectRatio"];
  shape?: "rounded" | "circle";
};

const Img = styled.img<ImageProps>(({ aspectRatio, shape, theme }) => ({
  aspectRatio,
  display: "block",
  objectFit: "cover",
  width: "100%",
  maxWidth: "100%",
  position: "relative",
  ...(shape && {
    borderRadius:
      shape === "circle" ? theme.borderRadius.round : theme.borderRadius.xs,
  }),
}));

export { Img };
