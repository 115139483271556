import { useQuery } from "react-query";
import { api } from "utils/api";
import type { CheckoutSessionRequest, CheckoutSessionResponse } from "../types";
import { checkoutKeys } from "./queryKeys";

const getCheckoutSession = (request: CheckoutSessionRequest) =>
  api.post<CheckoutSessionResponse>("/commerce/create-checkout-session", {
    body: JSON.stringify(request),
  });

const useCheckoutSession = (request: CheckoutSessionRequest) =>
  useQuery(checkoutKeys.session(request), () => getCheckoutSession(request), {
    staleTime: Infinity,
  });

export { useCheckoutSession };
