import styled from "@emotion/styled";
import { Container } from "./Container";
import { PlaceholderBlock } from "./PlaceholderBlock";
import { Stack } from "./Stack";
import { LoadingSpinner } from "./LoadingSpinner";

function PlaceholderLayout() {
  return (
    <Stack gap="lg">
      <LoadingSpinner />
      <PlaceholderBlock width="100%" height="40vh" />
      <Container>
        <Stack gap="md">
          <PlaceholderRow>
            <Stack gap="sm">
              <Stack>
                <PlaceholderBlock width="90%" height="1.75em" />
                <PlaceholderBlock width="60%" height="1.75em" />
              </Stack>
              <Stack>
                <PlaceholderBlock width="95%" height=".9em" />
                <PlaceholderBlock width="80%" height=".9em" />
                <PlaceholderBlock width="87%" height=".9em" />
              </Stack>
              <PlaceholderBlock width="150px" height="2.25em" rounded />
            </Stack>
            <PlaceholderBlock
              width="90%"
              height="50vh"
              style={{ placeSelf: "center" }}
            />
          </PlaceholderRow>

          <PlaceholderRow>
            <Stack gap="sm">
              <Stack>
                <PlaceholderBlock width="90%" height="1.75em" />
                <PlaceholderBlock width="60%" height="1.75em" />
              </Stack>
              <Stack>
                <PlaceholderBlock width="95%" height=".9em" />
                <PlaceholderBlock width="80%" height=".9em" />
                <PlaceholderBlock width="87%" height=".9em" />
              </Stack>
              <PlaceholderBlock width="150px" height="2.25em" rounded />
            </Stack>
            <PlaceholderBlock
              width="90%"
              height="50vh"
              style={{ placeSelf: "center" }}
            />
          </PlaceholderRow>
        </Stack>
      </Container>
    </Stack>
  );
}

const PlaceholderRow = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateRows: "1fr",
  width: "100%",
  gap: "2rem",
  alignItems: "center",
  [theme.breakpoints.tablet]: {
    gridTemplateColumns: "2fr 3fr",
  },
}));
export { PlaceholderLayout };
