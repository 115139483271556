import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { FlexRow, H3, Link, LoadingSpinner, Stack } from "atoms";
import { paths } from "utils/paths";
import { GalleryItem } from "../types";
import { GalleryItemCard } from "./GalleryItemCard";
import { useGalleryItem } from "../api/useGalleryItem";
import { useGallery } from "../api/useGallery";

type GalleryPreviewProps = {
  artistId: string;

  /** The current item's ID */
  itemId?: string;

  /** use "all" to get a preview of all portfolio items */
  galleryId: string;
};

function GalleryPreview({ artistId, galleryId, itemId }: GalleryPreviewProps) {
  const location = useLocation();
  const { data: gallery } = useGalleryItem({ artistId, itemId: galleryId });
  const { data: galleryItems, isLoading } = useGallery({
    artistIds: [artistId],
    galleryFolderIds: [galleryId],
    perPage: 5,
    itemType: "item",
  });
  const { data: allItems, isLoading: allIsLoading } = useGallery({
    artistIds: [artistId],
    perPage: 5,
    itemType: "item",
  });

  const itemsFlat = galleryItems?.pages?.flatMap((page) => page.data) ?? [];
  const items = itemsFlat?.filter((item) => item.id !== itemId).slice(0, 4);

  const portfolioItemsFlat =
    allItems?.pages?.flatMap((page) => page.data) ?? [];
  const portfolioItems = portfolioItemsFlat
    ?.filter((item) => item.id !== itemId)
    .slice(0, 4);
  const hasGallery = galleryId !== "all";

  if (hasGallery && items?.length) {
    return (
      <Stack gap="sm">
        <FlexRow gap="6px 1rem" justifyContent="space-between">
          <H3 size="sm" style={{ margin: 0 }}>
            Explore More from <span>{gallery?.title ?? "gallery"}</span>
          </H3>
          <Link
            to={paths.artistGallery(artistId, galleryId)}
            size="xxs"
            variant="secondary"
            state={{ from: location }}
          >
            View Gallery
          </Link>
        </FlexRow>
        {isLoading ? (
          <LoadingSpinner />
        ) : (
          <PreviewGrid>
            {items.map((item: GalleryItem) => (
              <GalleryItemCard {...item} key={item.id} cropToSquare />
            ))}
          </PreviewGrid>
        )}
      </Stack>
    );
  }
  return (
    <Stack gap="sm">
      <FlexRow gap="6px 1rem" justifyContent="space-between">
        <H3 size="md" style={{ margin: 0 }}>
          Explore More
        </H3>
        <Link
          to={paths.artistPortfolio(artistId)}
          size="xxs"
          variant="secondary"
        >
          View All Work
        </Link>
      </FlexRow>
      {allIsLoading ? (
        <LoadingSpinner />
      ) : (
        <PreviewGrid>
          {portfolioItems.map((item: GalleryItem) => (
            <GalleryItemCard {...item} key={item.id} cropToSquare />
          ))}
        </PreviewGrid>
      )}
    </Stack>
  );
}

const PreviewGrid = styled.div(({ theme }) => ({
  display: "grid",
  gridTemplateColumns: "repeat(2, 1fr)",
  gap: theme.spacing.md,
  [theme.breakpoints.tablet]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));

export { GalleryPreview };
