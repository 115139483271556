import styled from "@emotion/styled";
import { FlexRow } from "./FlexRow";

type LoadingEllipsesProps = {
  text?: string;
};

function LoadingEllipses({ text }: LoadingEllipsesProps) {
  return (
    <LoadingText gap="1px" alignItems="baseline" flexWrap="nowrap">
      {text}
      <EllipsesDot>.</EllipsesDot>
      <EllipsesDot>.</EllipsesDot>
      <EllipsesDot>.</EllipsesDot>
    </LoadingText>
  );
}

const LoadingText = styled(FlexRow)({
  whiteSpace: "nowrap",
  flexWrap: "nowrap",
}).withComponent("span");

const EllipsesDot = styled.span({
  "@keyframes dotJump": {
    "0%, 40%, 100%": {
      transform: "translateY(0)",
    },
    "20%": {
      transform: "translateY(-.3em)",
    },
  },
  "@media (prefers-reduced-motion: no-preference)": {
    animation: "dotJump .8s cubic-bezier(.3, .7, .3, .6) 0s forwards infinite",
  },
  "&:nth-of-type(1)": { animationDelay: "0s" },
  "&:nth-of-type(2)": { animationDelay: ".07s" },
  "&:nth-of-type(3)": { animationDelay: ".14s" },
});

export { LoadingEllipses };
