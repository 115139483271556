import { useQuery } from "react-query";
import { api } from "utils/api";
import { artistKeys } from "./queryKeys";
import type { ArtistsRequest, ArtistsResponse } from "../types";

const getCollectiveArtists = ({ collectiveId }: ArtistsRequest) =>
  collectiveId
    ? api.get<ArtistsResponse>(`/collective/${collectiveId}/artists`)
    : Promise.reject(new Error("Invalid collection id"));

const useCollectiveArtists = (collectiveId?: string) =>
  useQuery(artistKeys.list({ collectiveId }), () =>
    getCollectiveArtists({ collectiveId }),
  );

export { useCollectiveArtists };
