import styled from "@emotion/styled";
import type { Theme } from "@emotion/react";

const variants = {
  default: () => ({}),
  rounded: (theme: Theme) => ({
    borderRadius: theme.borderRadius.sm,
  }),
  circle: (theme: Theme) => ({
    aspectRatio: "1 / 1",
    borderRadius: theme.borderRadius.round,
    objectFit: "cover",
  }),
} as const;

type ImgVariant = keyof typeof variants;

/**
 * A simple styled <img> component with circle and rounded variants
 *
 * NOTE: Do NOT use this atom with an Image object returned from the API (e.g. Reviewable hero image,
 * User avatar, etc.). Instead, use a component in the features/images module that has logic to
 * handle optimized sizes and fallback states, such as FeaturedImage or Thumbnail.
 */
const Img = styled.img<{ variant?: ImgVariant }>(
  ({ theme, variant = "default" }) => ({
    width: "100%",
    ...variants[variant](theme),
  }),
);

export { Img };
