import styled from "@emotion/styled";
import { useForm } from "react-hook-form";
import {
  Stack,
  Label,
  RequiredText,
  Input,
  FlexRow,
  FakeLabel,
  Text,
  AllCaps,
  FieldError,
  RichTextEditor,
  InputPre,
} from "atoms";
import { GenericImage } from "features/images";
import { fieldOptions } from "utils/form";
import {
  useUpdateProductDetails,
  type UpdateProductDetailsReq,
} from "../../api/useUpdateProductDetails";
import type { StepProps } from "../../types";
import { ProductTag } from "../ProductTag";
import { ProductWizardStep } from "../ProductWizardStep";

function StepDetails({ dispatch, state, moveBack }: StepProps) {
  const { id, handle, title, descriptionHtml } = state.product;
  const { isLoading, mutate } = useUpdateProductDetails(id);

  const {
    control,
    register,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<UpdateProductDetailsReq>({
    defaultValues: {
      handle,
      title,
      descriptionHtml,
    },
  });

  const moveNext = () => dispatch({ type: "moveNext" });
  const onSubmit = handleSubmit((formData) => {
    if (isDirty) {
      mutate(formData, {
        onSuccess: moveNext,
      });
    } else {
      moveNext();
    }
  });

  // Try to use the single image mockups if available
  // because new products might not yet have the featuredImage
  const imageUrl =
    state.product.imageData?.singleMockupHigh ??
    state.product.imageData?.singleMockupLow ??
    state.product.featuredImage?.url;

  return (
    <ProductWizardStep
      stepTitle="Product Details"
      state={state}
      moveBack={moveBack}
      moveBackDisabled={isDirty}
      nextStep={onSubmit}
      nextStepDisabled={isLoading}
      isForm
    >
      <Stack gap="md">
        <DetailColumns>
          <DetailImage>
            <GenericImage src={imageUrl} sizes="300px" />
          </DetailImage>

          <DetailForm>
            <Stack gap="md">
              <Label>
                Product Title
                <RequiredText />
                <Input {...register("title", fieldOptions.required)} />
                {errors.title && (
                  <FieldError>{errors.title.message}</FieldError>
                )}
              </Label>

              <Label>
                Product Listing URL
                <RequiredText />
                <FlexRow gap="0" flexWrap="nowrap">
                  <InputPre>{`/${state.artistId}/shop/`}</InputPre>
                  <Input {...register("handle", fieldOptions.required)} />
                </FlexRow>
                {errors.handle && (
                  <FieldError>{errors.handle.message}</FieldError>
                )}
              </Label>

              <Label>
                Product Description
                <RequiredText />
                <RichTextEditor
                  control={control}
                  name="descriptionHtml"
                  required
                />
                {errors.descriptionHtml && (
                  <FieldError>{errors.descriptionHtml.message}</FieldError>
                )}
              </Label>

              <Stack>
                <Stack gap="0">
                  <FakeLabel>Product Category</FakeLabel>

                  <Text size="xxs">
                    <em>
                      These tags are set by <AllCaps>Hug</AllCaps> for discovery
                      and cannot be changed.
                    </em>
                  </Text>
                </Stack>

                <FlexRow gap="10px">
                  <ProductTag type={state.product.productType} size="sm" />
                  {state.productTemplate.name === "Art Print" && (
                    <ProductTag type="Art Print" size="sm" />
                  )}
                </FlexRow>
              </Stack>
            </Stack>
          </DetailForm>
        </DetailColumns>
      </Stack>
    </ProductWizardStep>
  );
}

StepDetails.displayName = "Details";

const DetailColumns = styled.div({
  display: "flex",
  flexDirection: "column",
  gap: "2rem",
  "@media (min-width: 720px)": {
    flexDirection: "row-reverse",
  },
});

const DetailImage = styled.div(({ theme }) => ({
  maxWidth: 300,
  "@media (min-width: 720px)": {
    flex: "0 0 30%",
    maxWidth: "none",
  },
  [theme.breakpoints.desktopLarge]: {
    flex: "0 0 300px",
  },
}));

const DetailForm = styled.div({
  flex: "1 0 0%",
  maxWidth: 660,
  marginRight: "auto",
});

export { StepDetails, DetailColumns, DetailImage, DetailForm };
