import * as React from "react";
import styled from "@emotion/styled";
import uniqueId from "lodash/uniqueId";
import { trackShareTweet } from "contexts/mixpanel";
import { useToggle } from "utils/hooks";
import { Link } from "./Link";
import { Button, buttonSizes, buttonVariants } from "./Button";
import { CopyToClipboard } from "./CopyToClipboard";
import { DropdownMenu } from "./DropdownMenu";
import { List } from "./List";
import { Stack } from "./Stack";
import { IconShare, IconTwitter } from "./icons";

interface ShareMenuProps {
  shareMenuToggleLabel?: string;
  copyButtonLabel?: string;
  copyButtonValue?: string;
  tweetButtonLabel?: string;
  tweetButtonValue?: string;
  variant?: keyof typeof buttonVariants;
  size?: keyof typeof buttonSizes;
}

function GenericShareMenu({
  shareMenuToggleLabel,
  copyButtonLabel = "Copy URL",
  copyButtonValue,
  tweetButtonLabel = "Share via Tweet",
  tweetButtonValue,
  variant = "primary",
  size = "xs",
  ...props
}: ShareMenuProps) {
  const [id] = React.useState(uniqueId());

  const [isMenuOpen, toggleMenu, setMenuOpen] = useToggle(false);

  if (!copyButtonValue && !tweetButtonValue) {
    return null;
  }

  const onClickTweet = () => {
    setMenuOpen(false);
    trackShareTweet();
  };

  const tweetUrl = `https://twitter.com/intent/tweet?text=${tweetButtonValue && encodeURIComponent(tweetButtonValue)}`;

  return (
    <ShareMenuWrapper>
      <ShareMenuToggle
        type="button"
        size={size}
        onClick={toggleMenu}
        aria-label={`${isMenuOpen ? "Close" : "Open"} share menu`}
        aria-controls={`#${id}`}
        aria-expanded={isMenuOpen}
        variant={variant}
        {...props}
      >
        {shareMenuToggleLabel ?? <IconShare aria-hidden="true" />}
      </ShareMenuToggle>

      <ShareMenuDropdown isOpen={isMenuOpen} id={id}>
        <Stack gap="sm">
          <List type="blank">
            <Stack gap="xxs">
              {!!copyButtonValue && (
                <div>
                  <li>
                    <CopyToClipboard
                      buttonText={copyButtonLabel}
                      value={copyButtonValue}
                      sendShareEvent
                      onClick={toggleMenu}
                    />
                  </li>
                </div>
              )}

              {!!tweetButtonValue && (
                <div>
                  <li>
                    <SLink
                      href={tweetUrl}
                      type="button"
                      variant="blank"
                      onClick={onClickTweet}
                      target="_blank"
                    >
                      <IconTwitter />
                      {tweetButtonLabel}
                    </SLink>
                  </li>
                </div>
              )}
            </Stack>
          </List>
        </Stack>
      </ShareMenuDropdown>
    </ShareMenuWrapper>
  );
}

const ShareMenuWrapper = styled.div({
  right: 0,
  display: "block",
  position: "relative",
  width: "min-content",
});

const ShareMenuToggle = styled(Button)({
  alignItems: "center",
  justifyContent: "center",
  whiteSpace: "nowrap",
  svg: {
    pointerEvents: "none",
    fontSize: "1em",
    transform: "scale(1.2)",
  },
});

const ShareMenuDropdown = styled(DropdownMenu)({
  maxWidth: "200px",
  top: "auto",
  bottom: "calc(100% + 11px)",
  "&+.DropdownMenuArrow": {
    top: "auto",
    bottom: "100%",
    transform: "translate(-50%, -4px) scale(-1)",
  },
});

const SLink = styled(Link)({
  textTransform: "none",
});

export { GenericShareMenu };
