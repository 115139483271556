import styled from "@emotion/styled";
import { Outlet, useLocation } from "react-router-dom";
import { ErrorBoundary } from "react-error-boundary";
import { SiteHeader } from "./SiteHeader";
import { SiteFooter } from "./SiteFooter";
import { SiteContentFallback } from "./SiteContentFallback";

function LayoutSite() {
  const location = useLocation();
  const goBack = () => window.history.back();

  return (
    <Site>
      <SiteHeader />
      <SiteContent>
        <ErrorBoundary
          FallbackComponent={SiteContentFallback}
          resetKeys={[location]}
          onReset={goBack}
        >
          <Outlet />
        </ErrorBoundary>
      </SiteContent>
      {/* <NotificationToast /> */}
      <SiteFooter />
    </Site>
  );
}
const Site = styled.div({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
});
const SiteContent = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  flex: 1,
}));

export { LayoutSite };
