import { useAuth } from "features/auth";
import { useQuery } from "react-query";
import { api } from "utils/api";
import { ReferredApplicationResponse } from "../types";

const queryKeys = {
  base: ["referredApplications"] as const,
};

const getReferredApplications = () =>
  api.get<ReferredApplicationResponse>("/artists/applications/referred", {
    requireAuth: true,
  });

const useReferredApplications = () => {
  const { user } = useAuth();
  return useQuery(queryKeys.base, getReferredApplications, {
    enabled: !!user,
  });
};

export { useReferredApplications };
