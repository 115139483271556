import { keyframes } from "@emotion/react";
import styled from "@emotion/styled";

const fadeIn = keyframes({
  from: {
    opacity: 0,
  },
  to: {
    opacity: 1,
  },
});

interface FadeInProps {
  delay?: number;
  duration?: number;
}

/**
 * Wrapper component that fades in contents on mount
 *
 * Simple usage:
 *
 * ```
 * <FadeIn><h1>Hello</h1></FadeIn>
 * ```
 *
 * Recommended usage to avoid extra markup:
 *
 * ```
 * const CustomFadeInImg = styled(FadeIn)({ margin: "1rem" }).withComponent('img')
 *
 * <CustomFadeInImg src={...} duration={1000} />
 * ```
 */
const FadeIn = styled.div<FadeInProps>(
  {
    "@media (prefers-reduced-motion: no-preference)": {
      animationName: fadeIn,
      animationFillMode: "backwards",
    },
  },
  ({ duration, delay }) => ({
    animationDuration: `${duration ?? 500}ms`,
    animationDelay: `${delay ?? 0}ms`,
  }),
);

export { FadeIn };
