import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import type { CreateGalleryItemsRequest, GalleryItem } from "../types";
import { galleryKeys } from "./queryKeys";

/**
 * API to create an array of gallery items
 */
const createGalleryItems = ({ items, artistId }: CreateGalleryItemsRequest) =>
  artistId
    ? api.post<GalleryItem[]>(`/gallery/artists/${artistId}`, {
        body: JSON.stringify({ items }),
      })
    : Promise.reject(new Error("Invalid artist id"));

/**
 * Mutation to create an array of gallery items
 */
const useGalleryItemsCreate = () => {
  const queryClient = useQueryClient();

  return useMutation(createGalleryItems, {
    onSuccess: () => {
      queryClient.invalidateQueries(galleryKeys.base);
    },
  });
};

export { createGalleryItems, useGalleryItemsCreate };
