import * as React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  FlexRow,
  H2,
  H3,
  IconArrow,
  IconNewImage,
  Link,
  LoadingSpinner,
  Modal,
  Stack,
  Text,
} from "atoms";
import { useAuth } from "features/auth";
import { Thumbnail } from "features/images";
import {
  OwnedContractFields,
  OwnedContractList,
  OwnedContractListResponse,
  OwnedContractTokenRequest,
  useGetOwnedContractTokens,
  useOwnedContracts,
  useSyncOwnedContracts,
} from "features/ownedContracts";
import { pluralize } from "utils/text";
import { SelectImages } from "./SelectImages";

type AddItemsFromWalletProps = {
  setSelectedFiles: (files: File[]) => void;
  onClick: VoidFunction;
};

function AddItemsFromWallet({
  setSelectedFiles,
  onClick,
}: AddItemsFromWalletProps) {
  const { user } = useAuth();

  const [contractTitle, setContractTitle] = React.useState("");
  const handleSetContractTitle = (title: string) => {
    setContractTitle(title);
  };

  // List of owned contracts the user can choose from
  const { data: ownedContracts, isLoading: isOwnedContractsLoading } =
    useOwnedContracts({ ownerId: user?.id });

  // Track the Selected Wallet and Contract
  const [selectedContract, setSelectedContract] = React.useState<
    OwnedContractFields | undefined
  >();
  const [selectedWallet, setSelectedWallet] = React.useState<
    string | undefined
  >();

  const toggleSelectedContract = (
    walletAddress: string,
    item: OwnedContractFields,
  ) => {
    setSelectedWallet(walletAddress);
    setSelectedContract(item);
  };

  // List of Tokens the user can choose from
  const tokenParams: OwnedContractTokenRequest =
    selectedWallet && selectedContract
      ? {
          walletId: selectedWallet,
          contractAddress: selectedContract?.contractAddress,
          chainId: selectedContract?.chainId,
        }
      : {};
  const {
    data: ownedTokens,
    hasNextPage,
    fetchNextPage,
  } = useGetOwnedContractTokens(tokenParams);
  const loadMore = () => fetchNextPage();

  // Content type is required but not used - We don't know the content
  // type from Instagram until the images are loaded into blobs
  const media = React.useMemo(
    () =>
      (ownedTokens?.pages?.flatMap((page) => page.tokens) ?? []).map(
        (token) => ({
          id: `${token.id}~${token.tokenId}`,
          image: token.image,
          title: token.title ?? `Token ${token.tokenId}`,
        }),
      ),
    [ownedTokens],
  );

  const [selected, setSelected] = React.useState<typeof media>([]);

  const [step, setStep] = React.useState<1 | 2>(1);
  // const onContractSelectionComplete = () => setStep(2);

  // Image Selection Done
  // Load All Items to Blob Images before Upload
  const loadImage = async (item?: (typeof media)[number]) => {
    if (item?.image?.url) {
      // TODO - Cross Origin Requests fail when we use the alchemy URL directly.
      // The url1200 comes from the HUG back end and has already been through image
      // compression. It would be nice to have the original full resolution image if
      // we can get alchemy to cooperate and if it's not too slow.
      const url = item.image.url1200 ?? item.image.url;
      const response = await fetch(url);
      const blob = await response.blob();
      const type = blob.type?.length ? blob.type : item.image?.contentType;
      // Give a fake file extension because it will get stripped by the form
      const file = new File([blob], `${item.title ?? "Image"}.png`, { type });
      return file;
    }
    return undefined;
  };

  const loadAllImagesToFilesObjects = async () => {
    const loadedImages = await Promise.all(selected.map((i) => loadImage(i)));
    setSelectedFiles(loadedImages.filter((r): r is File => !!r));
  };

  const onImageSelectionComplete = () => {
    loadAllImagesToFilesObjects();
  };

  const { mutate: syncContracts, isLoading: isRefreshing } =
    useSyncOwnedContracts();

  const refreshContracts = () => {
    if (user) {
      syncContracts({ userId: user.id });
    }
  };

  if (isOwnedContractsLoading) {
    return <LoadingSpinner />;
  }

  if (!user?.wallets?.length) {
    return (
      <>
        <Modal.Header>
          <Modal.Title>
            <IconNewImage /> Import From Wallet
          </Modal.Title>
        </Modal.Header>
        <Text>
          A crypto wallet allows users to store and access assets on the
          blockchain, including cryptocurrencies and non-fungible tokens (NFTs).
          If you have work created on-chain, you can import them as items to
          your Portfolio by adding your wallet(s) to your Account.
        </Text>
        <Text>
          <strong>You currently do not have any wallets connected.</strong>
        </Text>
        <Text>
          Add a wallet to your Account to start importing items you have created
          on-chain.
        </Text>
        <Link size="xs" variant="secondary" to="/profile/edit#wallet">
          Add Wallet
        </Link>
      </>
    );
  }

  if (user?.wallets?.length && !ownedContracts?.length) {
    return (
      <>
        <Modal.Header>
          <Modal.Title>
            <IconNewImage /> Import From Wallet
          </Modal.Title>
        </Modal.Header>
        <Text>We were unable to find contracts created by this wallet.</Text>
        <FlexRow gap="10px">
          <Button size="xs" variant="secondary" onClick={refreshContracts}>
            Refresh Wallets
          </Button>
          <Link size="xs" variant="secondary" to="/profile/edit#wallet">
            Add Wallet
          </Link>
        </FlexRow>
      </>
    );
  }

  // TODO: Get total token count, not current paginated count
  const tokenCount = Number(media.length);

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <IconNewImage /> Import From Wallet
        </Modal.Title>
      </Modal.Header>

      {isRefreshing && <LoadingSpinner />}

      {!isRefreshing && step === 1 && !!ownedContracts && (
        <SelectContracts
          refreshContracts={refreshContracts}
          toggleSelectedContract={toggleSelectedContract}
          data={ownedContracts}
          setContractTitle={handleSetContractTitle}
          setStep={() => setStep(2)}
        />
      )}

      {!isRefreshing && step === 2 && !!media && (
        <Stack gap="md">
          <H3 size="sm" textTransform="none">
            {pluralize(tokenCount, "Token", null, false)} in Contract
            {contractTitle && <span> {contractTitle}</span>}
          </H3>
          {media.length ? (
            <SelectImages
              multiple
              items={media}
              onChange={setSelected}
              showTitle
            />
          ) : (
            <Text>
              <em>No tokens found for contract</em>
            </Text>
          )}
          {hasNextPage && (
            <FlexRow justifyContent="center">
              <Button variant="secondary" size="xs" onClick={loadMore}>
                Load More
              </Button>
            </FlexRow>
          )}
        </Stack>
      )}

      <Modal.Footer>
        <FlexRow justifyContent="center">
          {step === 1 && !!ownedContracts && (
            <Button variant="secondary" onClick={onClick}>
              <IconArrow dir="l" />
              Back
            </Button>
          )}
          {step === 2 && !!media && (
            <>
              <Button variant="secondary" onClick={() => setStep(1)}>
                <IconArrow dir="l" />
                Back
              </Button>
              <Button onClick={onImageSelectionComplete}>
                Import Selected Images
              </Button>
            </>
          )}
        </FlexRow>
      </Modal.Footer>
    </>
  );
}

type SelectContractsProps = {
  refreshContracts: VoidFunction;
  toggleSelectedContract: (
    walletAddress: string,
    item: OwnedContractFields,
  ) => void;
  setStep: VoidFunction;
  setContractTitle: (title: string) => void;
  data: OwnedContractListResponse;
};

function SelectContracts({
  refreshContracts,
  toggleSelectedContract,
  setStep,
  setContractTitle,
  data,
}: SelectContractsProps) {
  return (
    <Stack gap="md">
      <Stack>
        <Text size="xs">
          Your on-chain collections and/or artworks can be located according to
          the contract they have been created on.
        </Text>

        <Text size="xs">
          <strong>Is something missing?</strong> Refresh your wallet connection
          or add a new wallet to select contracts from.
          <br />
          <strong>Note:</strong> Shared contracts (e.g. OpenSea) are not
          currently supported. Only contracts deployed from your wallet will be
          displayed.
        </Text>
      </Stack>

      <Stack gap="sm">
        <FlexRow gap="10px 1rem">
          <H2 size="md" style={{ margin: 0 }}>
            Your Wallets
          </H2>
          <FlexRow gap="10px" flexWrap="nowrap">
            <Button size="xxs" variant="secondary" onClick={refreshContracts}>
              Refresh Wallets
            </Button>
            <Link size="xxs" variant="secondary" to="/profile/edit#wallet">
              Add Wallet
            </Link>
          </FlexRow>
        </FlexRow>

        {data?.map((wallet) => (
          <Wallet
            key={`${wallet.wallet}~${wallet.chainId}`}
            wallet={wallet}
            toggleSelectedContract={toggleSelectedContract}
            setStep={setStep}
            setContractTitle={setContractTitle}
          />
        ))}
      </Stack>
    </Stack>
  );
}

type WalletProps = {
  wallet: OwnedContractList;
  toggleSelectedContract: (
    walletAddress: string,
    item: OwnedContractFields,
  ) => void;
  setStep: VoidFunction;
  setContractTitle: (title: string) => void;
};

function Wallet({
  toggleSelectedContract,
  setContractTitle,
  wallet: { wallet, contracts, chainId },
  setStep,
}: WalletProps) {
  const emptyContracts = contracts.filter(
    (contract) => !contract.tokenIds.length,
  );
  const validContracts = contracts.filter(
    (contract) => !!contract.tokenIds.length,
  );

  return (
    <Box small>
      <Stack>
        <Address label="Contracts on" address={wallet} chainId={chainId} />
        {contracts.length === 0 && (
          <Text size="xs" style={{ margin: 0 }}>
            <em>This wallet does not have any contracts</em>
          </Text>
        )}
        <Stack gap="md">
          {validContracts.length !== 0 && (
            <Stack>
              <Text size="xs" bold style={{ color: "#262626" }}>
                Contracts
              </Text>
              <Grid>
                {validContracts.map((c) => {
                  const selectContract = () => {
                    toggleSelectedContract(wallet, c);
                    setStep();
                    setContractTitle(c.name ?? "");
                  };
                  return (
                    <GridItem key={`${c.contractAddress}~${c.chainId}`}>
                      <ItemButton onClick={selectContract}>
                        <Stack gap="xxs">
                          {c.name ? (
                            <ContractTitle size="xxxs" bold>
                              {c.name}
                            </ContractTitle>
                          ) : (
                            <Address address={c.contractAddress} />
                          )}
                          <SThumbnail src={c.heroImage} aria-hidden />
                        </Stack>
                      </ItemButton>
                    </GridItem>
                  );
                })}
              </Grid>
            </Stack>
          )}
          {emptyContracts.length !== 0 && (
            <Stack>
              <Text size="xs" bold style={{ color: "#262626" }}>
                Contracts without tokens
              </Text>
              <FlexRow flexWrap="wrap" gap="10px">
                {emptyContracts.map((c) => (
                  <span key={`${c.contractAddress}~${c.chainId}`}>
                    {c.name ? (
                      <ContractTitle size="xxs">{c.name}</ContractTitle>
                    ) : (
                      <Address address={c.contractAddress} />
                    )}
                  </span>
                ))}
              </FlexRow>
            </Stack>
          )}
        </Stack>
      </Stack>
    </Box>
  );
}
const ContractTitle = styled(Text)(({ theme }) => ({
  lineHeight: 1,
  margin: 0,
  minWidth: 0,
  overflow: "hidden",
  textOverflow: "ellipsis",
  whiteSpace: "nowrap",
  textAlign: "left",
  color: theme.colors.fg70,
}));
const WalletAddress = styled.div(({ theme }) => ({
  fontWeight: "bold",
  color: theme.colors.fg70,
  display: "flex",
  span: {
    flex: "0 0 auto",
  },
  "span:first-of-type": {
    flex: "0 1 auto",
    overflow: "hidden",
    textOverflow: "ellipsis",
  },
}));

const SThumbnail = styled(Thumbnail)(({ theme }) => ({
  "&,img": {
    borderRadius: theme.borderRadius.sm,
    position: "relative",
    zIndex: 0,
    pointerEvents: "none",
    userSelect: "none",
  },
}));

const GridItem = styled.div(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
  position: "relative",
  aspectRatio: "auto",
}));
const ItemButton = styled.button(({ theme }) => ({
  appearance: "none",
  WebkitAppearance: "none",
  MozAppearance: "none",
  backgroundColor: theme.colors.bg,
  border: 0,
  borderRadius: theme.borderRadius.sm,
  display: "block",
  padding: 0,
  maxWidth: "100%",
  cursor: "pointer",
  outline: "3px solid",
  outlineColor: theme.colors.transparent,
  outlineOffset: 2,
  transition: ".2s ease all",
  "&:hover, &:focus-visible": {
    outlineColor: theme.colors.fg,
  },
}));

function Address({
  label,
  address,
  chainId,
}: {
  label?: string;
  address: string;
  chainId?: string | number;
}) {
  return (
    <WalletAddress>
      {label && <span style={{ marginRight: "4px" }}>{label}</span>}
      <span>{address.slice(0, 6)}</span>
      <span>...</span>
      <span>{address.substring(address.length - 6)}</span>
      {chainId && <span> (Chain {chainId})</span>}
    </WalletAddress>
  );
}

const Grid = styled.div({
  display: "grid",
  gap: 12,
  gridTemplateColumns: "repeat(auto-fill, minmax(135px, 1fr))",
  alignItems: "start",
});

export { AddItemsFromWallet };
