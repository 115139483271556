import { PublicProfile, User } from "features/auth";

/**
 * Displays a user's status as a string
 * @param user
 * @returns Artist | Curator, Level 6
 */
function UserStatus(user: PublicProfile | User) {
  let featuredRole = "Collector";
  if (user?.roles.includes("senior-curator")) {
    featuredRole = "Senior Curator";
  } else if (user?.roles.includes("curator")) {
    featuredRole = "Curator";
  }
  const userIsArtist =
    user.claimedArtistPageIds && !!user.claimedArtistPageIds.length;

  return `${userIsArtist ? "Artist | " : ""}${featuredRole}, Level ${
    user.curatorLevel
  }`;
}

export { UserStatus };
