/* eslint-disable prefer-template */
function RGBAToHexA(rgba: string, forceRemoveAlpha = true) {
  return (
    "#" +
    rgba
      .replace(/^rgba?\(|\s+|\)$/g, "")
      .split(",")
      .filter((_: string, index: number) => !forceRemoveAlpha || index !== 3)
      .map((string: string, index: number) => {
        const float = parseFloat(string);
        const roundedFloat = index === 3 ? Math.round(float * 255) : float;
        const strRoundedFloat = roundedFloat.toString(16);
        const padded =
          string.length === 1 ? "0" + strRoundedFloat : strRoundedFloat;
        return padded;
      })
      .join("")
  );
}

/* This function doesn't work with rgba values */
function isColorLight(color: string) {
  let lightOrDarkColor = color;

  if (color.startsWith("rgb")) {
    lightOrDarkColor = RGBAToHexA(lightOrDarkColor);
  }

  const hex = lightOrDarkColor.replace("#", "");
  const cr = parseInt(hex.substring(0, 0 + 2), 16);
  const cg = parseInt(hex.substring(2, 2 + 2), 16);
  const cb = parseInt(hex.substring(4, 4 + 2), 16);
  // const co = parseInt(hex.substring(6, 6 + 2), 16);
  // const hasAlpha = Number.isNaN(co); //

  const brightness = (cr * 299 + cg * 587 + cb * 114) / 1000;
  return brightness > 127.5;
}

export { isColorLight };
