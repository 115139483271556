import styled from "@emotion/styled";
import type { ProductPriceRange } from "@shopify/hydrogen-react/storefront-api-types";
import {
  AbsoluteLink,
  BlockTag,
  FlexRow,
  ScreenReaderText,
  Stack,
  Text,
} from "atoms";
import { paths } from "utils/paths";
import type { CollectionQueryProductData } from "../api/useCollectionQuery";
import { isOnSale } from "../utils/isOnSale";
import { ProductImage } from "./ProductImage";
import { ProductTag } from "./ProductTag";
import { SaleMoney, StrikeMoney } from "./ProductCard";

type ProductCardProps = {
  product: CollectionQueryProductData;
  artistName?: string;
  showPrice?: boolean;
  showProductType?: boolean;
};

function ProductSummary({
  product: {
    artistId,
    availableForSale,
    compareAtPriceRange,
    featuredImage,
    handle,
    priceRange,
    productType,
    title,
  },
  artistName,
  showPrice,
  showProductType,
}: ProductCardProps) {
  const productIsOnSale = isOnSale({ compareAtPriceRange, priceRange });
  if (artistId) {
    return (
      <Summary>
        <AbsoluteLink to={paths.artistShopProduct(artistId, handle)}>
          {title}
        </AbsoluteLink>
        {featuredImage ? (
          <CardImage data={featuredImage} aspectRatio="1" sizes="65px" />
        ) : (
          <div
            style={{
              borderRadius: 4,
              backgroundColor: "#efefef",
              width: 65,
              height: 65,
            }}
          />
        )}
        <CardBody>
          {artistId && (
            <ScreenReaderText>
              {!availableForSale && <span>, Sold out</span>}
            </ScreenReaderText>
          )}

          <Stack gap="xxxs">
            <CardTitle aria-hidden>{title}</CardTitle>
            <ArtistName>{artistName}</ArtistName>
          </Stack>

          <FlexRow
            gap="0 5px"
            flexWrap="nowrap"
            itemsFlex="0 1 auto"
            alignItems="flex-end"
          >
            {showPrice && (
              <Price>
                <ProductCardPrice
                  priceRange={priceRange}
                  compareAtPriceRange={compareAtPriceRange}
                  productIsOnSale={productIsOnSale}
                />
              </Price>
            )}
            {productType && showProductType && (
              <ProductTag type={productType} size="xxs" />
            )}

            {showPrice && (!availableForSale || productIsOnSale) && (
              <FlexRow gap="4px">
                {productIsOnSale && (
                  <BlockTag bg="error" color="bg" size="xxs">
                    Sale
                  </BlockTag>
                )}

                {!availableForSale && (
                  <BlockTag bg="error" color="bg" size="xxs">
                    Sold out
                  </BlockTag>
                )}
              </FlexRow>
            )}
          </FlexRow>
        </CardBody>
      </Summary>
    );
  }

  return null;
}

const Summary = styled.div({
  borderRadius: 4,
  overflow: "hidden",
  display: "grid",
  gap: 10,
  gridTemplateColumns: "65px 1fr",
  alignItems: "center",
  padding: 0,
  position: "relative",
});

const CardImage = styled(ProductImage)(({ theme }) => ({
  alignSelf: "start",
  borderRadius: `${theme.borderRadius.sm} ${theme.borderRadius.sm} 0 0`,
  display: "block",
  objectFit: "cover",
}));

const CardBody = styled.div({
  display: "flex",
  gap: 5,
  flexDirection: "column",
  justifyContent: "space-between",
  flex: 1,
  overflow: "hidden",
  gridColumnEnd: 3,
});

const CardTitle = styled(Text)(({ theme }) => ({
  ...theme.fonts.display,
  color: theme.colors.fg,
  fontSize: 15,
  lineHeight: 1.2,
  display: "-webkit-box",
  lineClamp: "2",
  margin: 0,
  overflow: "hidden",
  WebkitLineClamp: "2",
  WebkitBoxOrient: "vertical",
  textOverflow: "ellipsis",
}));

const ArtistName = styled(Text)({
  fontSize: 12,
  margin: 0,
  lineHeight: 1,
});

const Price = styled.div({
  fontSize: 15,
  opacity: 0.8,
});

type ProductCardPriceProps = {
  priceRange: ProductPriceRange;
  compareAtPriceRange: ProductPriceRange;
  productIsOnSale: boolean;
};

function ProductCardPrice({
  priceRange,
  compareAtPriceRange,
  productIsOnSale,
}: ProductCardPriceProps) {
  const minPrice = priceRange.minVariantPrice.amount;
  const maxPrice = priceRange.maxVariantPrice.amount;
  const hasPriceRange = maxPrice !== minPrice;

  return (
    <ProductPrice>
      <FlexRow gap="0 3px">
        {hasPriceRange && <small>from</small>}
        <SaleMoney
          data={priceRange.minVariantPrice}
          productIsOnSale={productIsOnSale}
          withoutTrailingZeros
        />
      </FlexRow>

      {productIsOnSale && (
        <>
          <ScreenReaderText>marked down from</ScreenReaderText>
          <StrikeMoney
            data={compareAtPriceRange.minVariantPrice}
            withoutTrailingZeros
          />
        </>
      )}
    </ProductPrice>
  );
}

const ProductPrice = styled.div(({ theme }) => ({
  alignItems: "center",
  display: "flex",
  fontSize: 14,
  lineHeight: 1,
  flexWrap: "wrap",
  color: theme.colors.fg,
  gap: "0 4px",
}));

export { ProductSummary };
