import { isProduction } from "config";

/**
 * Convert URL pointing to temporary upload AWS bucket to one accessible by the current domain
 */
const getTempPreviewUrl = (url: string) => {
  const { pathname } = new URL(url);

  if (!pathname.startsWith("/uploads/temp")) {
    return url;
  }

  const origin = isProduction
    ? "https://thehug.xyz"
    : "https://thesecrethug.xyz";

  return `${origin}${pathname}`;
};

export { getTempPreviewUrl };
