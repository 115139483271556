import styled from "@emotion/styled";
import {
  Container,
  containerWidths,
  FlexRow,
  H1,
  H2,
  Link,
  Stack,
} from "atoms";
import { Head } from "features/misc";
import { paths } from "utils/paths";

type ProductWizardTemplateProps = {
  title: string;
  subtitle?: string;
  stepWidth?: keyof typeof containerWidths;
  artistId: string;
  children: React.ReactNode;
};

function ProductWizardTemplate({
  title,
  subtitle,
  children,
  artistId,
  stepWidth,
}: Partial<ProductWizardTemplateProps> & { artistId: string }) {
  return (
    <Wrapper>
      <Head title="Product Builder | HUG" />
      <HeaderBar>
        <FlexRow>
          <SLink size="xs" variant="blank" to={paths.artistShopEdit(artistId)}>
            Exit
          </SLink>

          <Title>{title}</Title>
        </FlexRow>
      </HeaderBar>

      <SContainer width={stepWidth ?? "md"}>
        <Stack
          gap="md"
          style={{ display: "flex", flexDirection: "column", flex: 1 }}
        >
          {subtitle && (
            <H2 size="lg" textTransform="none" textAlign="center">
              {subtitle}
            </H2>
          )}

          {/*
          <Modal isOpen={showWarning} hideCloseButton>
            <Stack gap="sm">
              <Stack gap="xs">
                <H2 size="lg" textAlign="center" textTransform="none">
                  Leave Page?
                </H2>
                <Text textAlign="center">You have unsaved changes.</Text>
              </Stack>
              <FlexRow justifyContent="center">
                <Link
                  variant="secondary"
                  to={paths.artistShopEdit(artistId ?? "")}
                  onClick={() => setShowWarning(false)}
                >
                  Discard Changes and Exit
                </Link>
                <Button onClick={() => setShowWarning(false)}>
                  Continue Editing
                </Button>
              </FlexRow>
            </Stack>
          </Modal>
          */}

          {children}
        </Stack>
      </SContainer>
    </Wrapper>
  );
}

const Wrapper = styled.div({
  minHeight: "100dvh",
  display: "flex",
  flexDirection: "column",
  paddingBottom: 80,
  position: "relative",
  zIndex: 0,
  "&& form": {
    marginTop: 0,
  },
});

const HeaderBar = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.fg,
  color: theme.colors.bg,
  paddingBlock: "1em",
  paddingInline: theme.spacing.gutter,
}));

const SLink = styled(Link)(({ theme }) => ({
  "&,&:hover,&:focus-visible": { color: "white" },
  position: "absolute",
  left: theme.spacing.gutter,
}));

const SContainer = styled(Container)({
  paddingTop: "2rem",
  flex: 1,
  display: "flex",
  flexDirection: "column",
});

const Title = styled(H1)(({ theme }) => ({
  marginBlock: "0 !important",
  fontSize: theme.fontSizes.md,
  color: theme.colors.bg,
  textAlign: "center",
  flex: 1,
}));

export { ProductWizardTemplate };
