import * as React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import * as analytics from "contexts/mixpanel";
import { api } from "utils/api";
import { useAsync } from "utils/hooks";
import { getAuthRefresh } from "../api/getAuthRefresh";
import { getProfile } from "../api/getProfile";
import { postWalletAuth } from "../api/postWalletAuth";
import type { User, PlatformRoles, AuthWalletConnection } from "../types";
import { getPlatformRoles } from "../utils/getPlatformRoles";
import { useWalletAuth } from "../utils/useWalletAuth";

type SignInFunction = (connection: AuthWalletConnection) => Promise<void>;
type SignOutFunction = (redirect?: boolean) => void;
type RefreshAuthFunction = (updates?: { user: User; jwt: string }) => void;

/**
 * Context to provide all state and actions related to authentication
 */
const AuthContext = React.createContext<
  | {
      signIn: SignInFunction;
      signOut: SignOutFunction;
      refreshAuth: RefreshAuthFunction;
      user?: User;
      roles: PlatformRoles;
      isLoading: boolean;
      isSuccess: boolean;
      isError: boolean;
      error: null | string;
    }
  | undefined
>(undefined);

const decodeAuthError = (error: Error | null) => {
  if (!error) {
    return null;
  }

  const { message } = error;

  if (message.match(/denied message signature/i)) {
    return "The signature request was denied. Please reload this page and try again.";
  }
  if (message.match(/signature/i)) {
    return "The wallet signature verification failed. Please reload this page and try again.";
  }
  // TODO: check API Error message for more info on failed auth call
  if (message.match(/authentication/i)) {
    return "Sorry, it looks like you don't have access. Make sure you connected the correct wallet holding your HUG membership pass.";
  }

  return "Something went wrong. Disconnect from your wallet and try again.";
};

function AuthProvider({ children }: { children: React.ReactNode }) {
  // Get token from URL Search Params for MagicLink sign in
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();
  const { isConnected, disconnect } = useWalletAuth();

  React.useEffect(() => {
    const urlToken = searchParams.get("token");

    if (urlToken) {
      // If it exists, store it in local storage and remove from URL
      api.setToken(urlToken);
      searchParams.delete("token");
      setSearchParams(searchParams);

      analytics.trackEvent({
        name: "Sign In",
        Method: "Magic Link",
      });
    }
  }, [searchParams, setSearchParams, navigate]);

  // Async state for sign in function
  const {
    run,
    data: user,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useAsync<User>({
    // If we have a token, start in loading state. See next useEffect hook.
    status: api.getToken() ? "loading" : "idle",
  });

  // Update MixPanel when user changes on sign in
  React.useEffect(() => {
    if (user) {
      analytics.setUser(user);
    }
  }, [user]);

  // If we have a token, confirm it with the API and sign in automatically
  React.useEffect(() => {
    if (api.getToken()) {
      run(getProfile());
    }
    // Only run once on mount
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, []);

  /**
   * Authenticate using wallet address and update Auth context
   */
  const signIn: SignInFunction = React.useCallback(
    async (connection: AuthWalletConnection) =>
      // Wrap the user data in a promise to resolve in useAsync's data property
      run(
        // Authenticate address and signature via the API
        postWalletAuth(connection)
          // Handle new API token and resolve with full User object
          .then((resp) => {
            if (!resp.jwt) {
              // Reject useAsync with error
              throw new Error("Authentication failed");
            }

            // Store the token response in storage
            api.setToken(resp.jwt);

            // Track Sign In
            if (resp.isNewUser) {
              analytics.trackEvent({ name: "Sign Up", Method: "Wallet" });
            } else {
              analytics.trackEvent({ name: "Sign In", Method: "Wallet" });
            }

            return resp.user;
          })
          .catch((e) => {
            // Authentication failed somewhere along this chain of promises, so remove API token if
            // it exists and handle the error within useAsync hook
            api.removeToken();
            throw e;
          }),
      ),
    [run],
  );

  /**
   * Refresh the auth context's user value and stored token with new values from given updates
   * or by fetching them from the API
   */
  const refreshAuth: RefreshAuthFunction = React.useCallback(
    async (updates) => {
      let { jwt, user: updatedUser } = updates ?? {};
      if (!jwt) {
        // If updates don't contain a new token, re-authenticate via the API
        const refresh = await getAuthRefresh();
        jwt = refresh.jwt;
        updatedUser = refresh.user;
      }

      if (updatedUser) {
        // Update Mix Panel
        analytics.setUser(updatedUser);
        // Store the token response in storage
        api.setToken(jwt);
        // Wrap the user data in a promise to resolve in useAsync's data property
        run(Promise.resolve(updatedUser));
      } else {
        run(Promise.reject(new Error("Refresh authentication failed")));
      }
    },
    [run],
  );

  const signOut: SignOutFunction = React.useCallback(
    (redirect = true) => {
      analytics.removeUser();
      api.removeToken();
      if (isConnected) {
        disconnect();
      }
      // Reload by navigating to home page
      window.location.href = redirect
        ? window.location.origin
        : window.location.href;
    },
    [isConnected, disconnect],
  );

  // Get platform roles based on user data
  const roles = React.useMemo(() => getPlatformRoles(user), [user]);

  // Memoize constructed value or it will update every render
  const value = React.useMemo(
    () => ({
      signIn,
      signOut,
      refreshAuth,
      user,
      roles,
      error: decodeAuthError(error),
      isLoading,
      isSuccess,
      isError,
    }),
    [
      signIn,
      signOut,
      refreshAuth,
      user,
      roles,
      error,
      isLoading,
      isSuccess,
      isError,
    ],
  );

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

const useAuth = () => {
  const context = React.useContext(AuthContext);

  if (!context) {
    throw new Error(
      "useAuth() may be used only within the context of a <AuthProvider> component",
    );
  }

  return context;
};

export { AuthProvider, useAuth };
