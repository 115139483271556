import styled from "@emotion/styled";

const ShowOnMobile = styled.div(({ theme }) => ({
  display: "block",
  [theme.breakpoints.desktop]: {
    display: "none",
  },
}));

export { ShowOnMobile };
