import styled from "@emotion/styled";
import { useNavigate } from "react-router-dom";
import { Button, H1, Text } from "atoms";

function NotAuthorized() {
  const navigate = useNavigate();
  const goBack = () => navigate(-1);

  return (
    <Container aria-live="polite">
      <H1>Uh-oh!</H1>

      <Text size="lg">
        Looks like you don&apos;t have access to this page...😥
      </Text>

      <Text>
        <Button variant="primary" onClick={goBack}>
          Go Back?
        </Button>
      </Text>

      <br />

      <Text size="sm">
        <em>
          Make sure you are signed in with the correct wallet. You can sign out
          and sign back in again to try with a different account.
        </em>
      </Text>
    </Container>
  );
}

const Container = styled.div({
  margin: "6rem auto",
  minHeight: "60vh",
  maxWidth: "40rem",
});

export { NotAuthorized };
