import * as React from "react";
import { useRollbar } from "@rollbar/react";
import styled from "@emotion/styled";
import { ApiError } from "utils/api";
import { Text } from "./Typography";

const AppError = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.errorL,
  color: theme.colors.errorD,
  fontWeight: "bold",
  textAlign: "center",
  margin: theme.spacing.md,
  padding: theme.spacing.md,
  border: `1px solid ${theme.colors.error}`,
  borderRadius: theme.borderRadius.xs,
}));

AppError.defaultProps = {
  "aria-live": "polite",
};

const AppSuccess = styled(AppError)(({ theme }) => ({
  backgroundColor: theme.colors.successL,
  border: `1px solid ${theme.colors.success}`,
  color: theme.colors.successD,
  textAlign: "center",
}));

function UnexpectedError({ error }: { error: unknown }) {
  const { rollbar } = useRollbar();

  // We only want to show error message if it's friendly, i.e. from the API
  const isApiError = error instanceof ApiError;

  React.useEffect(() => {
    // But we want to log any error we get, including non-friendly messages
    if (isApiError || error instanceof Error) {
      rollbar.error(error);
    }
  }, [rollbar, error, isApiError]);

  const errorMessage =
    isApiError && !!error.message ? error.message : "Something has gone wrong";

  return (
    <AppError>
      <Text bold style={{ margin: 0 }}>
        {errorMessage}
      </Text>
    </AppError>
  );
}

export { AppError, AppSuccess, UnexpectedError };
