import { useMutation, useQuery, useQueryClient } from "react-query";
import { api } from "utils/api";

type ShippingAddressParams = {
  id: string;
  name?: string;
  street1?: string;
  street2?: string;
  city?: string;
  state?: string;
  zip?: string;
  country?: string;
};

type ShippingAddress = ShippingAddressParams & {
  easyPostId: string;
};

type ShippingAddressReq = ShippingAddressParams & {
  artistId: string;
};

type ShippingAddressResp = {
  shipFromAddresses: ShippingAddress[];
};

const shippingQueryKeys = {
  base: ["shippingAddress"] as const,
  lists: () => [...shippingQueryKeys.base, "list"] as const,
  list: (artistId?: string) =>
    [...shippingQueryKeys.lists(), artistId] as const,
  details: () => [...shippingQueryKeys.base, "detail"] as const,
  detail: (addressId?: string) =>
    [...shippingQueryKeys.details(), addressId] as const,
};

/**
 * Get Artist Addresses
 */
const getArtistAddresses = (artistId?: string) =>
  artistId
    ? api.get<ShippingAddressResp>(`/artists/${artistId}/addresses`)
    : Promise.reject(new Error("Invalid artist id"));

const useArtistShippingAddresses = (artistId?: string) =>
  useQuery(
    shippingQueryKeys.list(artistId),
    () => getArtistAddresses(artistId),
    {
      enabled: !!artistId,
    },
  );

/**
 * Create Artist Address
 */
const createArtistAddress = ({ artistId, ...params }: ShippingAddressReq) =>
  api.post<ShippingAddressResp>(`/artists/${artistId}/addresses`, {
    body: JSON.stringify(params),
  });

const useCreateArtistAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(createArtistAddress, {
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(shippingQueryKeys.list(vars.artistId));
    },
  });
};

/**
 * Update Artist Address
 */
const updateArtistAddress = ({ artistId, ...params }: ShippingAddressReq) =>
  api.put<ShippingAddressResp>(`/artists/${artistId}/addresses/${params.id}`, {
    body: JSON.stringify(params),
  });

const useUpdateArtistAddress = () => {
  const queryClient = useQueryClient();

  return useMutation(updateArtistAddress, {
    onSuccess: (_, vars) => {
      queryClient.invalidateQueries(shippingQueryKeys.list(vars.artistId));
    },
  });
};

export type { ShippingAddress, ShippingAddressReq };
export {
  useArtistShippingAddresses,
  useCreateArtistAddress,
  useUpdateArtistAddress,
};
