/** @jsxImportSource @emotion/react */
import * as React from "react";
import styled from "@emotion/styled";
import type { Theme } from "@emotion/react";
import { IconArrow, IconArrowProps, IconX } from "atoms/icons";

const borderSize = "2px";

const buttonCSS = (theme: Theme) => ({
  ...theme.fonts.display,
  alignItems: "center",
  border: `${borderSize} solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.round,
  color: theme.colors.fg,
  cursor: "pointer",
  display: "inline-flex",
  fontWeight: "bold",
  gap: 6,
  justifyContent: "center",
  fontSize: theme.fontSizes.sm,
  letterSpacing: theme.spacing.letterSpacing,
  lineHeight: 1.2,
  // minHeight: "2.5rem",
  padding: "0.45em 1.2em 0.4em",
  position: "relative" as const,
  textAlign: "center" as const,
  textDecoration: "none",
  textTransform: "uppercase" as const,
  svg: {
    maxHeight: "1em",
    maxWidth: "1em",
    color: "currentColor",
  },
  "&:hover, &:focus-visible": {
    textDecoration: "none",
    color: theme.colors.fg,
  },
  "&[disabled]": {
    opacity: 0.5,
    pointerEvents: "none" as const,
  },
});

// const buttonColors = {
//   default: (theme: Theme) => ({
//     bg: theme.colors.bg,
//     fg: theme.colors.fg,
//   }),
//   accent1: (theme: Theme) => ({
//     bg: theme.colors.accent1,
//     fg: theme.colors.fg,
//   }),
//   accent2: (theme: Theme) => ({
//     bg: theme.colors.accent2,
//     fg: theme.colors.bg,
//   }),
//   accent3: (theme: Theme) => ({
//     bg: theme.colors.accent3,
//     fg: theme.colors.fg,
//   }),
//   accent4: (theme: Theme) => ({
//     bg: theme.colors.accent4,
//     fg: theme.colors.bg,
//   }),
// };

const buttonVariants = {
  primary: (theme: Theme) => ({
    background: theme.colors.fg,
    borderColor: theme.colors.fg,
    "body &": {
      color: theme.colors.bg,
    },
    letterSpacing: theme.spacing.letterSpacing,
    "&:hover, &:focus-visible": {
      background: theme.colors.bg,
      borderColor: theme.colors.fg,
      color: theme.colors.fg,
    },
    "&:focus-visible": {
      outlineColor: theme.colors.fg,
    },
  }),
  secondary: (theme: Theme) => ({
    background: theme.colors.bg,
    borderColor: theme.colors.fg,
    "body &": {
      color: theme.colors.fg,
    },
    letterSpacing: theme.spacing.letterSpacing,
    "&:hover, &:focus-visible": {
      background: theme.colors.fg,
      borderColor: theme.colors.fg,
      color: theme.colors.bg,
    },
    "&:focus-visible": {
      outlineColor: theme.colors.fg,
    },
  }),
  default: (theme: Theme) =>
    ({
      ...theme.fonts.body,
      background: theme.colors.fg10,
      border: 0,
      "body &": {
        color: theme.colors.fg,
      },
      fontSize: theme.fontSizes.sm,
      fontWeight: "bold",
      paddingLeft: ".9em",
      paddingRight: ".9em",
      "&:hover, &:focus-visible": {
        transform: "unset",
        backgroundColor: theme.colors.fg20,
        color: theme.colors.fg,
      },
      "&:focus-visible": {
        outlineColor: theme.colors.fg,
      },
      "&:active": {
        transform: "unset",
      },
      "&::before": {
        content: "unset",
      },
    }) as const,
  blank: (theme: Theme) =>
    ({
      color: theme.colors.fg,
      background: "none",
      border: 0,
      borderBottom: `2px solid ${theme.colors.transparent}`,
      borderTop: `2px solid ${theme.colors.transparent}`,
      borderRadius: 0,
      fontWeight: "bold",
      lineHeight: 1.3,
      minHeight: 0,
      padding: 0,
      textDecoration: "none",
      "&:hover, &:focus-visible": {
        transform: "unset",
        color: theme.colors.linkHover,
        textDecoration: "none",
        borderBottomColor: theme.colors.fg,
      },
      "&:focus-visible": {
        outlineColor: theme.colors.fg,
      },
      "&:active": {
        transform: "unset",
      },
      "&::before": {
        content: "unset",
      },
    }) as const,
  circle: (theme: Theme) =>
    ({
      aspectRatio: "1",
      color: theme.colors.fg,
      background: theme.colors.bg,
      boxShadow: "none",
      border: 0,
      minHeight: 0,
      padding: ".75rem",
      textDecoration: "none",
      transition: `.2s ease box-shadow`,
      "&:hover, &:focus-visible": {
        transform: "unset",
        color: theme.colors.fg,
        textDecoration: "none",
        borderBottomColor: theme.colors.fg,
        boxShadow: `0 0 0 2px ${theme.colors.bg}, 0 0 0 4px ${theme.colors.fg}`,
      },
      "&:focus-visible": {
        outlineColor: theme.colors.fg,
      },
      "&:active": {
        transform: "unset",
      },
      "&::before": {
        content: "unset",
      },
      svg: {
        width: "1.125rem",
        height: "1.125rem",
        maxWidth: "1.125rem",
        maxHeight: "1.125rem",
      },
      [theme.breakpoints.desktop]: {
        padding: ".5rem",
        svg: {
          width: "1rem",
          height: "1rem",
          maxWidth: "1rem",
          maxHeight: "1rem",
        },
      },
    }) as const,
  text: (theme: Theme) =>
    ({
      ...theme.fonts.body,
      color: theme.colors.fg,
      background: "none",
      border: 0,
      borderRadius: 0,
      minHeight: 0,
      padding: 0,
      textDecoration: "underline",
      textTransform: "none",
      fontWeight: "inherit",
      "&&:hover, &&:focus-visible": {
        color: theme.colors.fg70,
        textDecoration: "underline",
        transform: "unset",
      },
      "&:active": {
        transform: "unset",
      },
      "&::before": {
        content: "unset",
      },
    }) as const,
};

const buttonSizes = {
  inherit: {
    fontSize: "inherit",
    minHeight: 0,
  },
  xxs: (theme: Theme) => ({
    fontSize: theme.fontSizes.xxxs,
    minHeight: 0,
  }),
  xs: (theme: Theme) => ({
    fontSize: theme.fontSizes.xxs,
    minHeight: 0,
  }),
  xxxs: () => ({
    fontSize: 12,
    minHeight: 0,
    padding: "4px 10px 3px",
  }),
  sm: (theme: Theme) => ({
    fontSize: theme.fontSizes.sm,
    minHeight: 0,
    "form &": {
      minHeight: "2.5rem",
    },
  }),
  md: (theme: Theme) => ({
    // default
    fontSize: theme.fontSizes.md,
    minHeight: 0,
    "form &": {
      minHeight: "2.5rem",
    },
  }),
  lg: (theme: Theme) => ({
    fontSize: theme.fontSizes.lg,
  }),
};

const arrowCSS = {
  fontSize: ".8em",
};

interface ButtonProps extends React.ButtonHTMLAttributes<HTMLButtonElement> {
  children: React.ReactNode;
  type?: "submit" | "button";
  variant?: keyof typeof buttonVariants;
  // color?: keyof typeof buttonColors;
  as?: React.ElementType;
  size?: keyof typeof buttonSizes;
  arrow?: null | IconArrowProps["dir"];
  arrowProps?: Omit<IconArrowProps, "dir">;
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  (
    {
      children,
      // color = "default",
      variant = "primary",
      type = "button",
      size = "sm",
      as: Element = "button",
      arrow = null,
      arrowProps,
      ...props
    }: ButtonProps,
    ref,
  ) => (
    <Element
      css={[buttonCSS, buttonVariants[variant], buttonSizes[size]]}
      type={type}
      {...props}
      ref={ref}
    >
      {arrow ? (
        <>
          {children}
          {variant !== "blank" && (
            <IconArrow dir={arrow} css={arrowCSS} {...arrowProps} />
          )}
        </>
      ) : (
        children
      )}
    </Element>
  ),
);
// jsx(
//   as,
//   {
//     css: [buttonCSS, buttonVariants[variant], buttonSizes[size]],
//     type,
//     ...props,
//   },
//   arrow ? (
//     <>
//       {children}
//       {variant !== "blank" && (
//         <IconArrow dir={arrow} css={arrowCSS} {...arrowProps} />
//       )}
//     </>
//   ) : (
//     children
//   ),
// );

function CloseButton({ ...props }) {
  return (
    <CloseButtonElement {...props}>
      <IconX />
    </CloseButtonElement>
  );
}

const CloseButtonElement = styled(Button)(({ theme }) => ({
  width: 30,
  maxWidth: 30,
  minWidth: 30,
  "form &": {
    height: 30,
    maxHeight: 30,
    minHeight: 30,
  },
  flexBasis: 30,
  border: `2px solid ${theme.colors.fg}`,
  borderRadius: theme.borderRadius.round,
  color: theme.colors.fg,
  opacity: 0.66,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  padding: 0,
  backgroundColor: "transparent",
  transition: ".2s ease opacity",
  "&:hover, &:focus-visible": {
    backgroundColor: "transparent",
    color: theme.colors.fg,
    opacity: 1,
  },
  svg: {
    width: ".7em",
  },
}));
CloseButtonElement.defaultProps = {
  variant: "secondary",
};

export {
  Button,
  CloseButton,
  buttonCSS,
  // buttonColors,
  buttonVariants,
  buttonSizes,
};
