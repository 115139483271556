import * as React from "react";
import styled from "@emotion/styled";
import { Money } from "@shopify/hydrogen-react";
import type {
  CartLine,
  CartLineCost,
  Image,
  ProductVariant,
} from "@shopify/hydrogen-react/storefront-api-types";
import { Button, FlexRow, Hr, Link, QuantityInput, Stack, Text } from "atoms";
import { paths } from "utils/paths";
import { ProductImage } from "./ProductImage";
import { isDefaultSelectedOption } from "../utils/isDefaultOption";
import { useProductQuery } from "../api/useProductQuery";

type CartLineItemProps = {
  attributes?: CartLine["attributes"];
  cost: CartLineCost;
  merchandise: ProductVariant;
  quantity: number;
  remove: VoidFunction;
} & Pick<React.ComponentProps<typeof QuantityInput>, "onChange">;

function CartLineItem({
  attributes,
  cost,
  merchandise,
  onChange,
  quantity,
  remove,
}: CartLineItemProps) {
  // NOTE: line item attributes prefixed with underscore are hidden on Checkout page
  const artistId =
    attributes?.find(({ key }) => key === "_artistId")?.value ?? "";
  const artistName = attributes?.find(
    ({ key }) => key === "_artistName",
  )?.value;

  const { data: product } = useProductQuery(merchandise?.product?.handle ?? "");

  const templateId = product?.templateId;

  // Don't show the quantity controls for digital downloads and on chain assets
  // as only one can be purchased at a time
  const forceQuantityOne = templateId === "HUG-DIGITAL-DOWNLOADS";

  // Force Quantity to 1 for Digital Downloads and On Chain Assets
  // We removed the quantity controls but the user might have clicked
  // "Add To Cart" twice
  React.useEffect(() => {
    if (merchandise && product && forceQuantityOne && quantity !== 1) {
      onChange(1);
    }
  }, [forceQuantityOne, merchandise, onChange, product, quantity]);

  if (!merchandise) {
    return null;
  }

  const variantPath = paths.artistShopProductVariant(
    artistId,
    merchandise.product.handle,
    merchandise.selectedOptions.map(({ name, value }) => [name, value]),
  );

  return (
    <>
      <FlexRow flexWrap="nowrap" gap="10px">
        <ProductImageWrapper>
          <Link to={variantPath}>
            <ProductImage data={merchandise.image as Image} sizes="90px" />
          </Link>
        </ProductImageWrapper>

        <ProductDetails>
          <ItemInfo gap="xxs">
            <Stack gap="0">
              <Title>
                <Link to={variantPath}>{merchandise.product.title}</Link>
              </Title>

              {artistName && (
                <Text size="xxxs">
                  by <Link to={paths.artistShop(artistId)}>{artistName}</Link>
                </Text>
              )}
            </Stack>

            {merchandise.selectedOptions && (
              <Text size="xxs">
                {merchandise.selectedOptions
                  .filter((opt) => !isDefaultSelectedOption(opt))
                  .map(({ name, value }) => `${name}: ${value}`)
                  .join(", ")}
              </Text>
            )}

            {forceQuantityOne && (
              <Text size="xs">
                You can only add one of this item per order.
              </Text>
            )}
          </ItemInfo>

          <Quantity>
            <QuantityInput
              defaultValue={quantity}
              onChange={onChange}
              min={1}
              max={merchandise?.quantityAvailable ?? Number.MAX_SAFE_INTEGER}
              disabled={forceQuantityOne}
            />

            <Button
              size="xxs"
              variant="text"
              onClick={remove}
              aria-label="Remove product from cart"
            >
              Remove
            </Button>
          </Quantity>

          <LineItemCost data={cost.totalAmount} />
        </ProductDetails>
      </FlexRow>

      <Hr />
    </>
  );
}

const ProductImageWrapper = styled.div(({ theme }) => ({
  alignSelf: "flex-start",
  height: "auto",
  width: 90,
  maxWidth: "25%",
  aspectRatio: 1,
  img: {
    borderRadius: theme.borderRadius.sm,
  },
}));

const ProductDetails = styled.div(({ theme }) => ({
  display: "grid",
  flex: 1,
  gap: 10,
  gridTemplateColumns: "1fr auto",
  gridTemplateAreas: `"info info" "quantity price"`,
  [theme.breakpoints.xs]: {
    gridTemplateAreas: ` "info price" "quantity price"`,
  },
}));
const Quantity = styled(FlexRow)({
  gap: 10,
  gridArea: "quantity",
});
const ItemInfo = styled(Stack)({
  gridArea: "info",
  flex: 1,
});

const Title = styled(Text)(({ theme }) => ({
  ...theme.fonts.display,
  margin: 0,
  fontSize: theme.fontSizes.sm,
  lineHeight: 1.2,
  color: theme.colors.fg,
  fontWeight: "bold",
}));

const LineItemCost = styled(Money)(({ theme }) => ({
  fontSize: theme.fontSizes.xs,
  fontVariantNumeric: "tabular-nums",
  gridArea: "price",
  letterSpacing: "-.02em",
  color: theme.colors.fg,
  textAlign: "right",
  flex: "0 0 auto",
  justifySelf: "end",
  alignSelf: "end",
  minWidth: "max-content",
  [theme.breakpoints.xs]: {
    alignSelf: "start",
  },
}));

export { CartLineItem };
