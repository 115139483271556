import { Navigate, useParams } from "react-router-dom";
import { LoadingSpinner, UnexpectedError } from "atoms";
import { useAuth } from "features/auth";
import NotFound from "pages/NotFound";
import { ApiError } from "utils/api";
import { paths } from "utils/paths";
import { useArtist } from "../api/useArtist";

const requireShopBuilderAccess = <TProps extends {}>(
  Component: React.FC<TProps>,
) => {
  // Omit AuthProps from required props of wrapped component because they will be injected automatically
  function RequireShopBuilderAccess(props: TProps) {
    const { artistId } = useParams<{ artistId: string }>();
    const { user, roles } = useAuth();
    const { data, isError, error } = useArtist(artistId);

    if (!artistId) {
      throw new Error("Invalid use of component: requires artistId in path");
    }

    if (isError) {
      return error instanceof ApiError && error.status === 404 ? (
        <NotFound />
      ) : (
        <UnexpectedError error={error} />
      );
    }

    if (data && user) {
      const userHasAccess =
        user.claimedArtistPageIds?.includes(artistId) ||
        roles.showAdminFeatures;

      if (!data.shopBuilderEnabled || !userHasAccess) {
        return <Navigate to={paths.artistProfile(artistId)} replace />;
      }

      return <Component {...props} />;
    }

    return <LoadingSpinner />;
  }

  return RequireShopBuilderAccess;
};

export { requireShopBuilderAccess };
