import { useMutation, useQueryClient } from "react-query";
import { type User, useAuth } from "features/auth";
import { api } from "utils/api";

type AdminWalletDeleteRequest = {
  userId: string;
  walletId: string;
};

const postAdminWalletDelete = (req: AdminWalletDeleteRequest) =>
  api.post<User>("/admin/wallet/delete", {
    body: JSON.stringify(req),
  });

const useAdminWalletDelete = () => {
  const { user, refreshAuth } = useAuth();
  const queryClient = useQueryClient();

  const mutation = useMutation(postAdminWalletDelete, {
    onSuccess: (response) => {
      if (user?.id === response.id) {
        refreshAuth();
      }

      queryClient.invalidateQueries(["admin-user", response.id]);
    },
  });
  return mutation;
};

export { useAdminWalletDelete };
