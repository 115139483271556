import { trackEvent } from "contexts/mixpanel";
import { catalog } from "../data/catalog";
import { LineItem } from "../types";

const itemToContent = ({ priceId: id, ...rest }: LineItem) => ({ id, ...rest });

const trackAddToCart = (items: LineItem[]) =>
  trackEvent({ name: "AddToCart", contents: items.map(itemToContent) });

const trackPurchase = (items: LineItem[]) =>
  trackEvent({
    name: "Purchase",
    currency: "USD",
    contents: items.map(itemToContent),
    value: items.reduce(
      (acc, { priceId, quantity }) =>
        acc +
        ((Object.values(catalog).find((item) => item.priceId === priceId)
          ?.costInCents ?? 0) /
          100) *
          quantity,
      0,
    ),
  });

export { trackAddToCart, trackPurchase };
