const formatWalletAddress = (
  wallet?: string,
  defaultValue = "Unnamed Hugger",
) => {
  if (!wallet) {
    return defaultValue;
  }
  return `${wallet.slice(0, 8)}...${wallet.slice(
    wallet.length - 6,
    wallet.length,
  )}`;
};

export { formatWalletAddress };
