import styled from "@emotion/styled";

type BlockTagSize = "xxs" | "xs" | "sm" | "md";
const BlockTag = styled.span<{
  // any named color from the theme
  bg?: string;
  // any named color from the theme
  color?: string;
  size?: BlockTagSize;
}>(({ theme, bg = "successL", color = "fg90", size = "xxs" }) => ({
  ...theme.fonts.display,
  backgroundColor: theme.colors[bg],
  borderRadius: theme.borderRadius.sm,
  color: theme.colors[color],
  display: "inline-block",
  fontWeight: "bold",
  letterSpacing: ".03em",
  lineHeight: 1.2,
  textTransform: "uppercase",
  ...(size === "xxs" && {
    fontSize: 10.5,
    paddingBlock: "3px 2px",
    paddingInline: bg === "transparent" ? 0 : 6,
  }),
  ...(size === "xs" && {
    fontSize: 12,
    paddingBlock: "4px 3px",
    paddingInline: bg === "transparent" ? 0 : 7,
  }),
  ...(size === "sm" && {
    fontSize: 12,
    paddingBlock: ".5em",
    paddingInline: bg === "transparent" ? 0 : "1em",
  }),
  ...(size === "md" && {
    fontSize: 14,
    paddingBlock: ".5em",
    paddingInline: bg === "transparent" ? 0 : "1em",
  }),
}));
export type { BlockTagSize };
export { BlockTag };
