import * as React from "react";
import type {
  ProductOption,
  SelectedOption,
} from "@shopify/hydrogen-react/storefront-api-types";
import { useSearchParams } from "react-router-dom";
import { FakeLabel, List, Select, Stack, Text } from "atoms";
import { isDefaultOption } from "../utils/isDefaultOption";

type ProductOptionsProps = {
  options?: ProductOption[];
  selectedOptions?: SelectedOption[];
};

type SelectedMap = Record<string, string>;

function ProductOptions({
  options = [],
  selectedOptions = [],
}: ProductOptionsProps) {
  const [searchParams, setSearchParams] = useSearchParams();

  // Create a map of selected option values indexed on the option name
  const selected = React.useMemo(
    () =>
      selectedOptions.reduce<SelectedMap>(
        (acc, cur) => ({
          ...acc,
          [cur.name]: cur.value,
        }),
        {},
      ),
    [selectedOptions],
  );

  // On first load of product, set the URL search params with a default variant
  React.useEffect(() => {
    // Use any params already provided in the URL to build up defaults
    const defaultParams = new URLSearchParams(searchParams);

    // For each available option in the product, verify or set the default value
    options.forEach(({ name, values }) => {
      // If the provided value doesn't exist for this option, or it isn't a valid option, overwrite
      // it with a proper default
      if (!values.includes(defaultParams.get(name) ?? "")) {
        // TODO: use a given initial variant id or filter out-of-stock options to set in-stock default?
        defaultParams.set(name, values[0]);
      }
    });
    setSearchParams(defaultParams, { replace: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Filter available options and split into two arrays: options with single vs many values
  const [single, multiple] = React.useMemo(
    () =>
      options.reduce<[ProductOption[], ProductOption[]]>(
        (acc, cur) => {
          // Convert boolean into 0 or 1 for tuple index (i.e. single or multiple)
          const index = Number(cur.values.length > 1);

          // Filter out the default option if product only has one variant
          if (index === 0 && isDefaultOption(cur)) {
            return acc;
          }

          // Add the option to the correct single/multiple array
          acc[index].push(cur);
          return acc;
        },
        [[], []],
      ),
    [options],
  );

  // Check if there's only one variant and no need to select a different option
  if (!single.length && !multiple.length) {
    return null;
  }

  // When a new value is selected, get the option name off of the select element's dataset and use
  // the selected option element's value to update the URL search parameters to fetch a new variant
  const onChange: React.ChangeEventHandler<HTMLSelectElement> = (e) => {
    const { name } = e.currentTarget.dataset;
    const { value } = e.target;

    if (name && value) {
      const newParams = new URLSearchParams(searchParams);
      newParams.set(name, value);
      setSearchParams(newParams, { replace: true });
    }
  };

  return (
    <>
      <SingleProductOptions options={single} />
      <MultipleProductOptions
        onChange={onChange}
        options={multiple}
        selected={selected}
      />
    </>
  );
}

function SingleProductOptions({
  options = [],
}: Pick<ProductOptionsProps, "options">) {
  return !options.length ? null : (
    <Text size="xs" as="div">
      <Stack gap="sm">
        <List type="blank">
          {options.map(({ name, values }) => (
            <li key={name}>
              <strong>{name}:</strong> {values[0]}
            </li>
          ))}
        </List>
      </Stack>
    </Text>
  );
}

type MultipleProductOptionsProps = Pick<ProductOptionsProps, "options"> & {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  selected: SelectedMap;
};

function MultipleProductOptions({
  onChange,
  options = [],
  selected,
}: MultipleProductOptionsProps) {
  return !options.length ? null : (
    <Stack gap="sm">
      {options.map(({ name, values }) => (
        <Stack gap="xxs" key={name}>
          <FakeLabel>{name}</FakeLabel>

          <Select onChange={onChange} value={selected[name]} data-name={name}>
            {values.map((value) => (
              <option key={value}>{value}</option>
            ))}
          </Select>
        </Stack>
      ))}
    </Stack>
  );
}

export { ProductOptions };
