import { BlockTag, type BlockTagSize } from "atoms";

// Lower-cased versions of our known Product types
const bgMap = new Map([
  ["digital download", "successL"],
  ["live session", "accent3LL"],
  ["onchain collectible", "accent2LL"],
  ["physical item", "accent1LL"],
  ["artist ships", "accent1LL"],
  ["art print", "accent1LL"],
]);

type ProductTagProps = {
  type: string;
  size?: BlockTagSize;
};

function ProductTag({ size = "xxs", type, ...props }: ProductTagProps) {
  const bg = bgMap.get(type.toLowerCase()) ?? "fg10";
  const label = type.toLowerCase() === "artist ships" ? "physical item" : type;

  return (
    <BlockTag bg={bg} color="fg80" size={size} {...props}>
      {label}
    </BlockTag>
  );
}

export { ProductTag };
