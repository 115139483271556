import * as React from "react";
import { Text } from "atoms";
import { formatDate } from "utils/date";
import type { ArtistApplication } from "../types";

type Props = {
  app: ArtistApplication;
};

const formatDateOnly = (date?: string) =>
  formatDate(date, { month: "short", day: "numeric", year: "numeric" });

function ArtistApplicationWorkflowStatus({ app }: Props) {
  const responses: string[] = React.useMemo(() => {
    const list: string[] = [];
    if (app.autoClaimEmailSentAt) {
      list.push(
        `Accepted Email Sent (Auto Claimed): ${formatDateOnly(
          app.autoClaimEmailSentAt,
        )}`,
      );
    } else if (app.acceptedEmailSentAt) {
      list.push(
        `Accepted Email Sent: ${formatDateOnly(app.acceptedEmailSentAt)}`,
      );
      if (app.acceptedReminder1EmailSentAt) {
        list.push(
          `First Reminder Sent: ${formatDateOnly(
            app.acceptedReminder1EmailSentAt,
          )}`,
        );
        if (app.acceptedReminder2EmailSentAt) {
          list.push(
            `Second Reminder Sent: ${formatDateOnly(app.acceptedEmailSentAt)}`,
          );
        } else {
          list.push(
            `Second Reminder Scheduled: ${formatDateOnly(
              addSevenDays(app.acceptedReminder1EmailSentAt),
            )}`,
          );
        }
      } else {
        list.push(
          `First Reminder Scheduled: ${formatDate(
            addSevenDays(app.acceptedEmailSentAt),
          )}`,
        );
      }
    } else {
      list.push("Accepted Email Not Sent");
    }
    return list;
  }, [app]);

  return (
    <>
      {responses.map((message: string) => (
        <Text size="xxs" key={message}>
          {message}
        </Text>
      ))}
    </>
  );
}

const addSevenDays = (dateString?: string) => {
  if (dateString) {
    const date = new Date(dateString);
    date.setDate(date.getDate() + 7);
    return date.toISOString();
  }
  return undefined;
};

export { ArtistApplicationWorkflowStatus };
