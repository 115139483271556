import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { AllCaps, Container, FlexRow, Link, Text } from "atoms";
import { instagramOauthUrl, useInstagramAuth } from "../api/useInstagramAuth";

type InstagramAuthProps = {
  setAccessToken: (accessToken: string) => void;
};

/**
 * InstagramAuth - Component to authenticate to the user's
 * instagram account. It will call the setAccessToken callback
 * when complete.
 *
 * @param setAccessToken - Callback to set the access token
 * @returns
 */
function InstagramAuth({ setAccessToken }: InstagramAuthProps) {
  const [params, setParams] = useSearchParams();
  const code = params.get("code");

  const {
    mutate: authorize,
    data: authData,
    ...authQuery
  } = useInstagramAuth();

  // Save the current URL to be used after the redirect
  React.useEffect(() => {
    if (params) {
      window.localStorage.setItem(
        "HUG_INSTAGRAM_REDIRECT",
        `${window.location.pathname}`,
      );
    }
  }, [params]);

  // Remove the code from the URL after authorization
  React.useEffect(() => {
    if (authQuery.isIdle && code) {
      authorize(code, {
        onSuccess: (data) => {
          params.delete("code");
          setParams(params, { replace: true });
          setAccessToken(data.accessToken);
        },
      });
    }
  }, [authorize, authQuery.isIdle, code, params, setParams, setAccessToken]);

  const showMustAuthorize = !code && !authData?.accessToken;

  return (
    <Container>
      <FlexRow justifyContent="center" flexDirection="column">
        {showMustAuthorize && (
          <Text textAlign="center">
            By continuing, you authorize <AllCaps>HUG</AllCaps> to use the media
            you&apos;ve posted to your Instagram account.
            <br />
            <Link
              variant="primary"
              href={instagramOauthUrl}
              style={{ marginTop: 10 }}
            >
              Authorize and Continue
            </Link>
          </Text>
        )}
        {authQuery.isError && (
          <pre>{JSON.stringify(authQuery.error, undefined, 2)}</pre>
        )}
      </FlexRow>
    </Container>
  );
}

export { InstagramAuth };
