import styled from "@emotion/styled";
import { useTheme } from "@emotion/react";
import { preventForwarding } from "styles/styleUtils";
import { isColorLight } from "utils/isColorLight";
import { BrushWrapper } from "./BrushWrapper";
import { Container } from "./Container";

type PageHeaderProps = React.ComponentProps<typeof BrushWrapper> & {
  overlap?: boolean;
  image1?: string;
  image2?: string;
  width?: React.ComponentProps<typeof Container>["width"];
};

function PageHeader({
  bg = "black",
  brush = "1",
  overlap,
  children,
  image1,
  image2,
  width = "md",
  ...props
}: PageHeaderProps) {
  const theme = useTheme();

  const bgColor = theme.colors[bg];
  const bgIsLight = isColorLight(bgColor);
  const hasImages = !!image1 && !!image2;

  return (
    <SBrushWrapper
      brush={brush}
      bg={bg}
      overlap={overlap}
      bgIsLight={bgIsLight}
      {...props}
    >
      <SContainer width={width} hasImages={hasImages}>
        {children}
      </SContainer>
      {hasImages && (
        <div>
          <PageHeaderImages width="lg" aria-hidden>
            <img src={image1} alt="" />
            <img src={image2} alt="" />
          </PageHeaderImages>
        </div>
      )}
    </SBrushWrapper>
  );
}

const SBrushWrapper = styled(BrushWrapper)<{
  overlap?: boolean;
  bgIsLight: boolean;
}>(({ overlap, bgIsLight, theme }) => ({
  ...(!bgIsLight && {
    color: theme.colors.white,
  }),
  overflow: "hidden",
  padding: `${theme.spacing.lg} 0`,
  textAlign: "center",
  ...(!bgIsLight && {
    "&::before": {
      filter: "invert",
    },
  }),
  ...(overlap && {
    paddingBottom: `calc(${theme.spacing.lg} * 4)`,
    "&& + *": {
      paddingTop: 0,
      position: "relative",
      zIndex: 2,
      marginTop: `calc(${theme.spacing.lg} * -3)`,
    },
  }),
  "&>.Container:first-of-type": {
    position: "relative",
    zIndex: 2,
  },
}));

const SContainer = styled(Container, {
  shouldForwardProp: preventForwarding("hasImages"),
})<{ hasImages: boolean }>(({ hasImages }) => ({
  ...(hasImages && {
    "@media (min-width: 831px) and (max-width: 1000px)": {
      paddingInline: "18vw",
    },
  }),
}));

const PageHeaderImages = styled(Container)(({ theme }) => ({
  display: "none",
  "@media (min-width: 831px)": {
    display: "block",
  },
  left: "50%",
  transform: "translateX(-50%)",
  position: "absolute",
  top: 0,
  bottom: 0,
  width: "100%",
  img: {
    objectFit: "cover",
    maxWidth: 240,
    maxHeight: "65%",
    width: "16vw",
    height: "22vw",
  },
  "img:first-of-type": {
    position: "absolute",
    bottom: 0,
    borderRadius: `${theme.borderRadius.round}px ${theme.borderRadius.round}px 0 0 `,
    left: `calc(${theme.spacing.gutter} / 2)`,
    [theme.breakpoints.desktopLarge]: {
      left: theme.spacing.gutter,
    },
  },
  "img:last-of-type": {
    position: "absolute",
    top: 0,
    borderRadius: `0 0 ${theme.borderRadius.round}px ${theme.borderRadius.round}px`,
    right: `calc(${theme.spacing.gutter} / 2)`,
    [theme.breakpoints.desktopLarge]: {
      right: theme.spacing.gutter,
    },
  },
}));

export { PageHeader };
