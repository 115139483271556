/** @jsxImportSource @emotion/react */
import * as React from "react";
import styled from "@emotion/styled";
import type { Theme } from "@emotion/react";
import {
  Link as RouterLink,
  LinkProps as RouterLinkProps,
} from "react-router-dom";
import { buttonCSS, buttonVariants, buttonSizes } from "./Button";
import { IconArrow, IconArrowProps } from "./icons";

const linkCSS = {
  inherit: (theme: Theme) => ({
    color: theme.colors.linkColor,
    textDecoration: "underline",
    "&:hover, &:focus-visible": {
      color: theme.colors.linkHoverColor,
    },
  }),
  default: [buttonCSS, buttonVariants.default],
  blank: [buttonCSS, buttonVariants.blank],
  primary: [buttonCSS, buttonVariants.primary],
  secondary: [buttonCSS, buttonVariants.secondary],
  circle: [buttonCSS, buttonVariants.circle],
};

type LinkVariant = keyof typeof linkCSS;

type BaseLinkProps = {
  variant?: LinkVariant;
  size?: keyof typeof buttonSizes;
  arrow?: IconArrowProps["dir"];
  arrowProps?: Omit<IconArrowProps, "dir">;
};

type LinkProps = BaseLinkProps & RouterLinkProps;
type AnchorProps = BaseLinkProps &
  React.AnchorHTMLAttributes<HTMLAnchorElement>;

function Link({
  arrow,
  arrowProps,
  children,
  variant,
  size,
  ...props
}: LinkProps | AnchorProps) {
  const css = [linkCSS[variant ?? "inherit"], buttonSizes[size ?? "inherit"]];

  if ("to" in props) {
    return (
      <RouterLink css={css} {...props}>
        {children}
        {arrow && <SIconArrow dir={arrow} {...arrowProps} />}
      </RouterLink>
    );
  }

  return (
    <a css={css} {...props}>
      {children}
      {arrow && <SIconArrow dir={arrow} {...arrowProps} />}
    </a>
  );
}

const SIconArrow = styled(IconArrow)({
  // marginLeft: "0.8rem",
});

export { Link, type LinkProps, type AnchorProps };
