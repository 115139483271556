import styled from "@emotion/styled";

type ListProps = {
  type?: "blank" | "bulleted" | "numbered";
};

function List({
  children,
  type = "bulleted",
  ...props
}: React.ComponentProps<typeof ListElement>) {
  return (
    <ListElement as={type === "numbered" ? "ol" : "ul"} type={type} {...props}>
      {children}
    </ListElement>
  );
}

const ListElement = styled.div<ListProps>(({ type = "bulleted" }) => ({
  "&, li": {
    fontSize: "inherit",
  },
  ...(type === "numbered" || type === "bulleted"
    ? {
        lineHeight: 1.3,
        paddingLeft: "1.5em",
        "&>*+*": {
          marginTop: ".75em",
        },
      }
    : {
        listStyleType: "none",
        margin: 0,
        padding: 0,
        width: "100%",
        "&>*::before": {
          content: `"\\200B"`,
          position: "absolute",
        },
      }),
}));

export { List };
