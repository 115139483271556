import * as React from "react";
import { BrowserRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import { LoadingSpinner } from "atoms";
import { AuthProvider } from "features/auth";
import { StripeProvider } from "features/commerce";
import { I18nProvider } from "./i18n";
import { QueryProvider } from "./query";
import { ShopProvider } from "./shop";
import { SocketProvider } from "./socket";
import { ThemeProvider } from "./theme";
import { WalletProvider } from "./wallet";

interface AppProvidersProps {
  children: React.ReactNode;
}

function AppProviders({ children }: AppProvidersProps) {
  return (
    <BrowserRouter>
      <HelmetProvider>
        <WalletProvider>
          <AuthProvider>
            <QueryProvider>
              <SocketProvider>
                <I18nProvider>
                  <ThemeProvider>
                    <ShopProvider>
                      <StripeProvider>
                        <React.Suspense fallback={<LoadingSpinner />}>
                          {children}
                        </React.Suspense>
                      </StripeProvider>
                    </ShopProvider>
                  </ThemeProvider>
                </I18nProvider>
              </SocketProvider>
            </QueryProvider>
          </AuthProvider>
        </WalletProvider>
      </HelmetProvider>
    </BrowserRouter>
  );
}

/**
 * High-order function to wrap any component with any context provider
 *
 * Example:
 * ```
 * export default withProvider(ReCaptchaProvider)(AnonymousForm);
 * ```
 */
const withProvider =
  (Provider: React.ElementType) =>
  (Component: React.ElementType) =>
  // eslint-disable-next-line react/function-component-definition
  (props: React.ComponentProps<typeof Component>) => (
    <Provider>
      <Component {...props} />
    </Provider>
  );

export { ReCaptchaProvider, useReCaptcha } from "./recaptcha";
export { AppProviders, withProvider };
