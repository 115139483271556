import type {
  GalleryItemSearchRequest,
  GalleryItemsForFolderParams,
  GalleryOwnerType,
} from "../types";

const galleryKeys = {
  base: ["gallery"],
  details: () => [...galleryKeys.base, "detail"] as const,
  detailOwner: (ownerType?: GalleryOwnerType, ownerId?: string) =>
    [...galleryKeys.details(), ownerType, ownerId] as const,
  detail: (ownerType?: GalleryOwnerType, ownerId?: string, itemId?: string) =>
    [...galleryKeys.detailOwner(ownerType, ownerId), itemId] as const,
  lists: () => [...galleryKeys.base, "list"] as const,
  items: (params: GalleryItemsForFolderParams) =>
    [...galleryKeys.lists(), params] as const,
  infinite: (params: GalleryItemSearchRequest) =>
    [...galleryKeys.lists(), params] as const,
};

export { galleryKeys };
