import * as React from "react";
import { createRoot } from "react-dom/client";
import "utils/polyfills";
import { App } from "./App";
import { featureFlags } from "./config";
import "./styles/global.css";

if (featureFlags.enableAPIMocking) {
  /* eslint-disable-next-line */
  const { worker } = require("./mocks/browser");
  worker.start();
}

const container = document.getElementById("root");

if (!container) {
  throw new Error("Cannot render without #root element");
}

const root = createRoot(container);
root.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
);
