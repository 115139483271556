/**
 * Scroll to top of window
 */
const scrollToTop = () => window.scrollTo(0, 0);

/**
 * Get element associated with URL hash
 */
const getElementByURLHash = () =>
  document.getElementById(window.location.hash.slice(1));

/**
 * Scroll to hash in URL
 */
const scrollToHash = () => {
  const element = getElementByURLHash();
  if (element) {
    element.scrollIntoView({ behavior: "smooth" });
  }
};

/**
 * Wait for element to appear in DOM, then scroll to hash in URL
 */
const asyncScrollToHash = () => {
  const recursiveScrollTo = (timeout = 0) => {
    const element = getElementByURLHash();
    if (element || timeout > 2000) {
      scrollToHash();
    } else {
      setTimeout(() => recursiveScrollTo(timeout + 250), timeout);
    }
  };

  recursiveScrollTo();
};

/**
 * Check if an element is visible within the viewport
 */
const isInViewport = (element: HTMLElement) => {
  if (!element) {
    return null;
  }
  const rect = element.getBoundingClientRect();
  return (
    rect.top >= 0 &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
};

export { isInViewport, asyncScrollToHash, scrollToHash, scrollToTop };
