import styled from "@emotion/styled";
import { GenericImage, Video } from "features/images";
import {
  Stack,
  Link,
  H2,
  FlexRow,
  IconExternalLink,
  RichText,
  Text,
  Button,
  IconExpand,
  ExpandContent,
  IconArrow,
} from "atoms";
import { formatURL } from "utils/text";
import { useToggle } from "utils/hooks";
import type { GalleryItem } from "../types";
import { PortfolioColumns } from "./Portfolio2ColLayout";
import { GalleryBackButton } from "./GalleryBackButton";

const content = (item: GalleryItem, size: "sm" | "lg") => {
  const domain = item.link && new URL(item.link).host.replace("www.", "");
  const isExternal = domain !== new URL(window.location.href).host;
  return (
    <Stack gap="sm">
      {item.description && (
        <ExpandContent
          showLabel="Read more"
          hideLabel="Show less"
          linesVisible={size === "sm" ? 2 : 6}
        >
          <RichText value={item.description} />
        </ExpandContent>
      )}

      {item.link && (
        <Text size="xs">
          <Link href={item.link} target="_blank">
            <FlexRow as="span" gap="5px">
              Learn more on {domain ?? formatURL(item.link)}
              {isExternal && <IconExternalLink size=".85em" />}
            </FlexRow>
          </Link>
        </Text>
      )}
    </Stack>
  );
};

type GalleryItemDetailsProps = { item: GalleryItem };
// Renders an item's details (media, description, link, etc)
function GalleryItemDetails({ item }: GalleryItemDetailsProps) {
  const [isExpanded, toggle] = useToggle(false);

  const itemTitle = (
    <Stack gap="xs">
      <GalleryBackButton artistId={item.ownerId} />
      <ItemTitle size="h3" textTransform="none">
        {item.title}
      </ItemTitle>
    </Stack>
  );

  return (
    <SPortfolioColumns
      isExpanded={isExpanded}
      sidebar={
        <>
          {!isExpanded && itemTitle}
          <ShowLarge>{content(item, "lg")}</ShowLarge>
        </>
      }
    >
      {isExpanded && itemTitle}

      <Stack gap="sm">
        <ItemContainer>
          {item.image?.videoUrl ? (
            <SVideo
              src={item.image?.videoUrl ?? item.image?.url ?? ""}
              poster={item.image?.url1200 ?? item.image?.url}
            />
          ) : (
            <ItemImage
              loading="eager"
              src={item.image}
              width={item.image?.width}
              height={item.image?.height}
              sizes="(max-width: 831px) 100vw,
            (max-width: 991px) calc(100vw - 6.4rem),
            1080px"
            />
          )}

          <Button
            variant="circle"
            size="xs"
            style={{ position: "absolute", inset: "10px 10px auto auto" }}
            onClick={toggle}
            aria-label={isExpanded ? "Shrink image " : "Enlarge image"}
          >
            {isExpanded ? (
              <IconArrow dir="ur" aria-hidden />
            ) : (
              <IconExpand aria-hidden />
            )}
          </Button>
        </ItemContainer>

        <ShowSmall>{content(item, "sm")}</ShowSmall>
      </Stack>
    </SPortfolioColumns>
  );
}

const SPortfolioColumns = styled(PortfolioColumns)<{
  isExpanded: boolean;
}>(({ isExpanded, theme }) => ({
  [theme.breakpoints.desktop]: {
    flexDirection: isExpanded ? "column-reverse" : "row",
    "& > div:first-of-type": {
      flex: "0 0 33%",
    },
  },
  [theme.breakpoints.desktopLarge]: {
    gap: theme.spacing.gap,
  },
}));

const ItemTitle = styled(H2)(({ theme }) => ({
  marginBottom: 0,
  [theme.breakpoints.desktop]: {
    marginBottom: theme.spacing.gap,
  },
}));

const ItemContainer = styled.div(({ theme }) => ({
  position: "relative",
  button: {
    opacity: 0.5,
    transition: ".2s ease all",
    [theme.breakpoints.maxDesktop]: {
      display: "none",
    },
  },
  "&:hover button": {
    opacity: 1,
  },
}));

const ItemImage = styled(GenericImage)(({ theme }) => ({
  img: {
    borderRadius: theme.borderRadius.sm,
    height: "auto",
  },
}));

const SVideo = styled(Video)(({ theme }) => ({
  borderRadius: theme.borderRadius.sm,
}));

const ShowSmall = styled.div(({ theme }) => ({
  [theme.breakpoints.desktop]: { display: "none" },
}));

const ShowLarge = styled.div(({ theme }) => ({
  display: "none",
  [theme.breakpoints.desktop]: {
    display: "block",
  },
}));

export { GalleryItemDetails };
