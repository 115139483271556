import type {
  ExternalVideo,
  Image,
  MediaImage,
  Model3d,
  Video,
} from "@shopify/hydrogen-react/storefront-api-types";

type ShopifyImage = Pick<MediaImage, "id" | "mediaContentType"> & {
  image: Image;
};

const isMediaImage = (
  media: MediaImage | ExternalVideo | Video | Model3d,
): media is ShopifyImage =>
  media.mediaContentType === "IMAGE" && "image" in media;

export { isMediaImage };
