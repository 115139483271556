import styled from "@emotion/styled";
import { Link } from "./Link";

const AbsoluteLink = styled(Link)(({ theme }) => ({
  display: "block",
  borderRadius: "inherit",
  opacity: 0,
  position: "absolute",
  textDecoration: "none",
  content: "''",
  inset: 0,
  transition: `.2s ease all`,
  zIndex: 3,
  "*:has( > & )": {
    transition: ".2s ease all",
  },
  "*:has( > & ):has(a:hover, a:focus-visible, button:hover, [aria-live]:hover)":
    {
      boxShadow: theme.boxShadow.dark,
      transform: "translateY(-2px)",
    },
}));

export { AbsoluteLink };
