import {
  Connector,
  useConnect,
  useAccount,
  useSignMessage,
  useDisconnect,
} from "wagmi";
import styled from "@emotion/styled";
import { keyframes } from "@emotion/react";
import {
  AppError,
  Button,
  FlexRow,
  IconCoinbase,
  IconMetamask,
  IconOkx,
  IconWalletConnect,
  Stack,
  Text,
} from "atoms";
import { trackEvent } from "contexts/mixpanel";
import { recordEvent } from "utils/analytics";
import { preventForwarding } from "styles/styleUtils";
import type { AuthWalletConnection } from "../types";

/**
 * Wallet Signature Message
 */
const message =
  "Signing this message proves ownership of my wallet address to https://www.thehug.xyz and represents an agreement to our terms and conditions.";

/**
 * Show a user-friendly error if something goes wrong during connection or signing
 */
const decodeError = (error: Error | null) => {
  if (!error) {
    return null;
  }
  if (
    /user rejected the request/i.test(error.message) || // metamask
    /user denied account authorization/i.test(error.message) || // coinbase
    /connection request reset/i.test(error.message) || // wallet connect - closed modal
    /user disapproved requested chains/i.test(error.message) // wallet connect - declined after scanning code
  ) {
    // User declined connecting with their selected wallet or didn't submit their password.
    // Don't show error message in case they just want to connect with a different wallet.
    return null;
  }

  if (/user denied message signature/i.test(error.message)) {
    return "Looks like you rejected the signature request. We need that to verify you own the wallet. Please retry to continue.";
  }

  // eslint-disable-next-line
  console.error("[ConnectWallet]", error);
  return "Something went wrong, reload the page and try again.";
};

/**
 * Handler that runs after successful wallet connection
 */
type ConnectHandler = (connection: AuthWalletConnection) => void;

type ConnectWalletProps = {
  children?: React.ReactNode;
  /**
   * Optional function to toggle showing the Sign In by Email form
   *
   * If not provided, the Sign In by Email button is not displayed
   */
  showEmailSignIn?: VoidFunction;
  /**
   * Option function to run after successfully connecting a wallet
   */
  onConnect?: ConnectHandler;
};

function ConnectWallet({
  children,
  showEmailSignIn,
  onConnect,
  ...props
}: ConnectWalletProps) {
  const {
    connectAsync,
    connectors,
    error: connectError,
    isLoading: isConnecting,
    pendingConnector,
  } = useConnect();
  const {
    signMessageAsync,
    error: signError,
    isLoading: isSigning,
  } = useSignMessage();

  const { address, isConnected, connector: activeConnector } = useAccount();
  const { disconnect } = useDisconnect();

  // Create a click handler when iterating over each connector
  const createConnectHandler = (connector: Connector) => async () => {
    try {
      // Log sign in attempt in analytics
      trackEvent({
        name: "Button Click",
        ButtonName: "Connect Wallet",
        ButtonContext: connector.name,
      });

      // Attempt to connect to Wallet
      const connection = await connectAsync({ connector });

      // Sign message to prove ownership
      const signature = await signMessageAsync({ message });

      // Log sign in method for metrics
      recordEvent("login", { method: connector.name });

      // Run given handler on success
      if (onConnect) {
        onConnect({
          walletAddress: connection.account,
          signature,
          chainId: connection.chain.id,
        });
      }
    } catch (e) {
      // eslint-disable-next-line
      console.log(e);
    }
  };

  // If user declined to sign message, or it failed, show option to try again
  const signHandler = async () => {
    const signature = await signMessageAsync({ message });
    if (onConnect && address && activeConnector) {
      onConnect({
        walletAddress: address,
        signature,
        chainId: await activeConnector.getChainId(),
      });
    }
  };

  const error = decodeError(connectError ?? signError);

  return (
    <Wrapper {...props}>
      {children}

      {error && <AppError>{error}</AppError>}

      {isConnected ? (
        <FlexRow flexDirection="column" alignItems="center">
          <Stack>
            <Text as="span" bold>
              Connected to {activeConnector?.name} using address:
            </Text>
            <Text>{address}</Text>
          </Stack>

          <ConnectButton onClick={signHandler} disabled={isSigning}>
            Sign Message
          </ConnectButton>

          <ConnectButton variant="secondary" onClick={() => disconnect()}>
            <ConnectIcon label={activeConnector?.name ?? ""} /> Disconnect
          </ConnectButton>
        </FlexRow>
      ) : (
        <Buttons>
          {connectors.map((connector) => (
            <ConnectButton
              key={connector.id}
              variant="secondary"
              disabled={!connector.ready || isConnecting}
              isConnecting={
                isConnecting && pendingConnector?.id === connector.id
              }
              onClick={createConnectHandler(connector)}
            >
              <ConnectIcon label={connector.name} /> {connector.name}
            </ConnectButton>
          ))}

          {showEmailSignIn && (
            <Stack>
              <FlexRow>
                <Dash />
                <Text
                  textAlign="center"
                  size="xxs"
                  bold
                  textTransform="uppercase"
                >
                  or
                </Text>
                <Dash />
              </FlexRow>

              <ConnectButton variant="secondary" onClick={showEmailSignIn}>
                Sign In with Email
              </ConnectButton>
            </Stack>
          )}
        </Buttons>
      )}
    </Wrapper>
  );
}

function ConnectIcon({ label }: { label: string }) {
  if (label === "MetaMask") {
    return <IconMetamask />;
  }
  if (label === "Coinbase Wallet") {
    return <IconCoinbase />;
  }
  if (label === "OKX Wallet") {
    return <IconOkx />;
  }
  if (label === "WalletConnect") {
    return <IconWalletConnect />;
  }
  return null;
}

const Wrapper = styled.div(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap: "1rem",
  marginBottom: theme.spacing.lg,
}));

const loading = keyframes({
  "0%": {
    transform: "translateX(-100%)",
  },
  "66%, 100%": {
    transform: "translateX(200%)",
  },
});

type ConnectButtonProps = { isConnecting?: boolean };

const ConnectButton = styled(Button, {
  shouldForwardProp: preventForwarding<ConnectButtonProps>("isConnecting"),
})<ConnectButtonProps>(({ theme, isConnecting }) => ({
  fontSize: theme.fontSizes.sm,
  fontWeight: "bold",
  alignItems: "center",
  gap: 10,
  paddingLeft: "2rem",
  paddingRight: "2.2rem",
  overflow: "hidden",
  position: "relative",
  width: "100%",
  "&>svg": {
    width: "1em",
    height: "auto",
  },
  ...(isConnecting
    ? {
        borderColor: theme.colors.accent1,
        background: theme.colors.accent1LL,
        "&[disabled]": {
          opacity: 1,
        },
        "&::before": {
          animation: `${loading} 1.6s infinite`,
          content: "''",
          display: "block",
          top: 0,
          bottom: 0,
          left: 0,
          width: "50%",
          position: "absolute",
          backgroundImage:
            "linear-gradient(to right, rgb(0 0 0 / 0) 0%, rgb(255 255 255 / .4) 50%, rgb(0 0 0 / 0) 100%)",
        },
      }
    : {}),
}));

const Buttons = styled.div(({ theme }) => ({
  display: "inline-flex",
  flexDirection: "column",
  alignItems: "center",
  "& > *": {
    width: "100%",
  },
  "& > * + *": {
    marginTop: theme.spacing.md,
  },
}));

const Dash = styled.span({
  height: 2,
  backgroundColor: "currentColor",
  opacity: 0.2,
  flex: 1,
  "&+p": { margin: 0 },
});

export type { ConnectHandler };
export { ConnectWallet, Dash, ConnectButton };
