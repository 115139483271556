/**
 * Saves new cookie value and duration
 */
const setCookie = (key: string, value: string, duration: number) => {
  document.cookie = `${key}=${value}; max-age=${duration}`;
};

/**
 * Get cookie value by key
 */
const getCookie = (name: string): string | undefined => {
  const cookie = document.cookie
    .split("; ")
    .find((c) => c.startsWith(`${name}=`));
  return !cookie ? undefined : cookie.split("=")[1];
};

type Preference = "SHOW_ADVANCED_FILTERS";

const preferenceKey = (key: Preference) => `HUG_PREFERENCE_${key}`;

/**
 * Get stored user preference from local storage
 */
const getPreference = (key: Preference) =>
  window.localStorage.getItem(preferenceKey(key));

/**
 * Set user preference in local storage
 */
const setPreference = (key: Preference, value: string) =>
  window.localStorage.setItem(preferenceKey(key), value);

/**
 * Delete user preference from local storage
 */
const deletePreference = (key: Preference) =>
  window.localStorage.removeItem(preferenceKey(key));

export { setCookie, getCookie, getPreference, setPreference, deletePreference };
