import * as React from "react";
import styled from "@emotion/styled";
import { Container } from "atoms";

interface CurationProps {
  intro: React.ReactNode;
  body: React.ReactNode;
  footer: React.ReactNode;
  showFooter: boolean;
}

function LayoutCuration({
  intro,
  body,
  footer,
  showFooter,
  ...props
}: CurationProps) {
  return (
    <div {...props}>
      <div>
        {intro}
        {body}
      </div>
      <Footer aria-hidden={!showFooter}>
        <Container width="lg">{footer}</Container>
      </Footer>
    </div>
  );
}

const Footer = styled.div(({ theme }) => ({
  position: "sticky",
  bottom: 0,
  opacity: 1,
  padding: "1rem 0 calc(1rem + env(safe-area-inset-bottom))",
  visibility: "visible",
  transition: ".3s ease all",
  zIndex: 10,
  "&>*": {
    position: "relative",
    zIndex: 3,
  },
  "&::before, &::after": {
    backgroundColor: theme.colors.bg,
    content: "''",
    position: "absolute",
    top: 0,
    left: "50%",
    width: "100%",
    bottom: 0,
    transform: "translateX(-50%)",
    zIndex: 2,
  },
  "&::before": {
    bottom: "50%",
    boxShadow: theme.boxShadow.dark,
    zIndex: 1,
  },
  '&[aria-hidden="true"]': {
    opacity: 0,
    visibility: "hidden",
  },
}));

export { LayoutCuration };
