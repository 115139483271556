import * as React from "react";
import type { Location } from "react-router-dom";

/**
 * Returns the correct link prop (`to` or `href`) depending on the link URL
 */
const getLinkProp = (url: string) =>
  url.startsWith(window.location.origin)
    ? { to: url.replace(window.location.origin, "") }
    : { href: url };

/**
 * Standardized shape to pass previous location to routes that will redirect back
 */
type RedirectState = {
  from: Location;
};

/**
 * Custom type guard for location.state redirect object
 *
 * Make sure state is not null/undefined and it has a `from` key containing a Partial<Path> object
 */
const isRedirectState = (state: unknown): state is RedirectState =>
  !!state &&
  !!(state as RedirectState).from &&
  ("pathname" in (state as RedirectState).from ||
    "hash" in (state as RedirectState).from ||
    "search" in (state as RedirectState).from);

/**
 * Session storage key for `safeDynamicImport` refresh state
 */
const REFRESHED_KEY = "HUG_force_refreshed";

/* eslint-disable @typescript-eslint/no-explicit-any, consistent-return */
type LazyImporter<T extends React.ComponentType<any>> = () => Promise<{
  default: T;
}>;

/**
 * Lazy load module imports safely, refreshing on ChunkLoadErrors to get new version of chunk
 */
const importRetry = <T extends React.ComponentType<any>>(
  componentImport: LazyImporter<T>,
) =>
  new Promise<{ default: T }>((resolve, reject) => {
    const hasBeenRefreshed =
      window.sessionStorage.getItem(REFRESHED_KEY) === "true";

    componentImport()
      .then((component) => {
        window.sessionStorage.setItem(REFRESHED_KEY, "false");
        resolve(component);
      })
      .catch((error) => {
        if (!hasBeenRefreshed) {
          // We haven't forced a refresh, assume they're not on latest version so refresh to get it
          window.sessionStorage.setItem(REFRESHED_KEY, "true");
          return window.location.reload();
        }
        // We refreshed but are still getting an import error, assume they're on latest version
        // and raise whatever error we're seeing to the closest Error Boundary for logging
        reject(error);
      });
  });
/* eslint-enable @typescript-eslint/no-explicit-any, consistent-return */

export type { RedirectState };
export { getLinkProp, isRedirectState, importRetry };
