import { useInfiniteQuery } from "react-query";
import { api } from "utils/api";
import { shopOrderKeys } from "./queryKeys";
import type { ShopOrdersSearchResponse } from "../types";
import { ShopOrdersSearchRequest } from "../types/shopOrderSearchRequest";

const getAdminOrders = (params: ShopOrdersSearchRequest) =>
  api.post<ShopOrdersSearchResponse>("/shop/orders/admin", {
    body: JSON.stringify(params),
  });

const useAdminOrders = (params: ShopOrdersSearchRequest = {}) =>
  useInfiniteQuery(
    shopOrderKeys.list(params),
    ({ pageParam }) => getAdminOrders({ ...params, searchAfter: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.searchAfter,
    },
  );

export { useAdminOrders };
