import { Buffer } from "buffer";

window.Buffer = window.Buffer || Buffer;

// Setup global no-op for rest of project
globalThis.noop = () => {};

/**
 * Make Safari's Focus Events work like other Browsers
 *
 * Source: https://itnext.io/fixing-focus-for-safari-b5916fef1064
 */
(function fixSafariFocusEvents() {
  const capturedEvents: Event[] = [];
  let capturing = false;

  function getEventTarget(event: Event) {
    const target = event.composedPath()[0] || event.target;
    return target instanceof HTMLElement ? target : null;
  }

  function captureEvent(e: Event) {
    if (capturing) {
      e.preventDefault();
      e.stopPropagation();
      e.stopImmediatePropagation();
      capturedEvents.unshift(e);
    }
  }

  function hiddenOrInert(element: HTMLElement) {
    let el: HTMLElement | null = element;
    while (el) {
      if (el.style.display === "none" || el.hasAttribute("inert")) {
        return true;
      }
      el = el.parentElement;
    }
    return false;
  }

  /*
   * The only mousedown events we care about here are ones emanating from
   * (A) anchor links with href attribute,
   * (B) non-disabled buttons,
   * (C) non-disabled textarea,
   * (D) non-disabled inputs of type "button", "reset", "checkbox", "radio", "submit"
   * (E) non-interactive elements (button, a, input, textarea, select) that have a tabindex with a numeric value
   * (F) audio elements
   * (G) video elements with controls attribute
   * (H) any element with the contenteditable attribute
   */
  function isFocusableElement(el: HTMLElement) {
    const tag = el.tagName;
    return (
      !hiddenOrInert(el) &&
      ((/^a$/i.test(tag) && (el as HTMLAnchorElement).href != null) || // (A)
        (/^(button|textarea)$/i.test(tag) &&
          (el as HTMLButtonElement).disabled !== true) || // (B) (C)
        (/^input$/i.test(tag) &&
          /^(button|reset|submit|radio|checkbox)$/i.test(
            (el as HTMLInputElement).type,
          ) &&
          !(el as HTMLInputElement).disabled) || // (D)
        (!/^(button|input|textarea|select|a)$/i.test(tag) &&
          !Number.isNaN(
            Number.parseFloat(el.getAttribute("tabindex") ?? ""),
          )) || // (E)
        /^audio$/i.test(tag) || // (F)
        (/^video$/i.test(tag) && (el as HTMLVideoElement).controls === true) || // (G)
        el.getAttribute("contenteditable") != null) // (H)
    );
  }

  function getLabelledElement(labelElement: HTMLLabelElement) {
    const forId = labelElement.getAttribute("for");
    return forId
      ? document.querySelector(`#${forId}`)
      : labelElement.querySelector("button, input, keygen, select, textarea");
  }

  function getFocusableElement(e: Event) {
    let currentElement: Node | null = getEventTarget(e);
    let focusableElement;
    while (
      !focusableElement &&
      currentElement !== null &&
      currentElement instanceof HTMLElement
    ) {
      if (isFocusableElement(currentElement)) {
        focusableElement = currentElement;
      } else if (/^label$/i.test(currentElement.tagName)) {
        const labelledElement = getLabelledElement(
          currentElement as HTMLLabelElement,
        );
        if (
          labelledElement instanceof HTMLElement &&
          isFocusableElement(labelledElement)
        ) {
          focusableElement = labelledElement;
        }
      }
      currentElement =
        currentElement.parentElement || currentElement.parentNode;
    }
    return focusableElement;
  }

  function handler(e: MouseEvent) {
    const focusableElement = getFocusableElement(e);

    if (focusableElement) {
      if (focusableElement === document.activeElement) {
        // mousedown is happening on the currently focused element
        // do not fire the 'focus' event in this case AND
        // call preventDefault() to stop the browser from transferring
        // focus to the body element
        e.preventDefault();
      } else {
        // start capturing possible out-of-order mouse events
        capturing = true;

        /*
         * enqueue the focus event _after_ the current batch of events, which
         * includes any blur events but before the mouseup and click events.
         * The correct order of events is:
         *
         * [this element] MOUSEDOWN               <-- this event
         * [previously active element] BLUR
         * [previously active element] FOCUSOUT
         * [this element] FOCUS                   <-- forced event
         * [this element] FOCUSIN                 <-- triggered by forced event
         * [this element] MOUSEUP                 <-- possibly captured event (it may have fired _before_ the FOCUS event)
         * [this element] CLICK                   <-- possibly captured event (it may have fired _before_ the FOCUS event)
         */
        setTimeout(() => {
          // stop capturing possible out-of-order mouse events
          capturing = false;

          // trigger focus event
          focusableElement?.focus();

          // re-dispatch captured mouse events in order
          while (capturedEvents.length > 0) {
            const event = capturedEvents.pop() as Event;
            getEventTarget(event)?.dispatchEvent(
              new MouseEvent(event.type, event),
            );
          }
        }, 0);
      }
    }
  }

  if (/apple/i.test(navigator.vendor)) {
    window.addEventListener("mousedown", handler, { capture: true });
    window.addEventListener("mouseup", captureEvent, { capture: true });
    window.addEventListener("click", captureEvent, { capture: true });
  }
})();

/**
 * Add Array.prototype.flatMap for older browsers
 *
 * It's identical to a `map()` followed by a `flat()` of depth 1 but more efficient than calling
 * them separately.
 */
(function addFlatMap() {
  if (!Array.prototype.flatMap) {
    // eslint-disable-next-line no-extend-native
    Object.defineProperty(Array.prototype, "flatMap", {
      value<T>(cb: (value: unknown, index: number, array: unknown[]) => T[]) {
        return this.map(cb).flat();
      },
    });
  }
})();

// NOTE: This file includes IIFE Polyfills and should only be imported once in src/index.ts
export {};
