import { productTemplateSteps } from "../data/productTemplates";

const getProductTypeFromTemplateId = (templateId: string) => {
  const productTypeName = productTemplateSteps.find(
    (template) => template.id === templateId,
  )?.name;

  let productType = productTypeName;

  if (productTypeName === "HUG Contract") {
    productType = "Onchain Collectible";
  }
  if (templateId === "HUG-ARTIST-TIP") {
    productType = "Artist Tip";
  }
  return productType;
};

export { getProductTypeFromTemplateId };
