import * as React from "react";
import styled from "@emotion/styled";
import uniqueId from "lodash/uniqueId";
import type { MutateOptions } from "react-query";
import {
  useController,
  useFieldArray,
  useForm,
  type Control,
  type UseFormRegister,
} from "react-hook-form";
import {
  AppError,
  Button,
  Draggable,
  FadeIn,
  FlexRow,
  Form,
  IconNewImage,
  IconVideo,
  Img,
  Input,
  Label,
  Modal,
  PlaceholderBlock,
  RequiredText,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import {
  generateThumbnail,
  getDimensionsFromUrl,
  getTempPreviewUrl,
  useFileUpload,
  useFileUploadUrls,
  type Image,
  type UploadFileResp,
} from "features/images";
import { fieldOptions } from "utils/form";
import { removeFileExt } from "utils/text";
import { useGalleryItemsCreate } from "../api/useGalleryItemsCreate";
import type {
  CreateGalleryItemsRequest,
  GalleryItem,
  GalleryItemFormFields,
} from "../types";

type GalleryItemsFormProps = {
  artistId: string;
  defaultValues?: Partial<GalleryItem>;
  filesToUpload: File[];
  cancel: VoidFunction;
  retry: VoidFunction;
} & MutateOptions<GalleryItem[], unknown, CreateGalleryItemsRequest>;

type GalleryItemsFormType = {
  items: GalleryItemFormFields[];
};

type FieldProp = {
  file: File;
  url: string;
};

function GalleryItemsForm({
  artistId,
  defaultValues,
  filesToUpload,
  cancel,
  onError,
  onSettled,
  onSuccess,
  retry,
}: GalleryItemsFormProps) {
  // Set up a unique form id to connect submit button outside of nested DOM structure
  const [formId] = React.useState(uniqueId());

  // Using files, and eventual upload urls, create a matching array of props for each field in
  // field array. If upload fails, we can remove elements from this array to match the changing
  // field array and keep indexes in sync since we can't mutate `filesToUpload` from props or
  // the upload url mutation data.
  const [fieldProps, setFieldProps] = React.useState<FieldProp[]>(
    filesToUpload.map((file) => ({ file, url: "" })),
  );

  // Mutation hook to get array of upload urls for each file to upload
  const {
    mutate: getUrls,
    data: urlData,
    ...urlMutation
  } = useFileUploadUrls();

  // Run the mutation immediately
  React.useEffect(
    () =>
      getUrls(
        filesToUpload.map((f) => f.type),
        {
          onSuccess: (resp) => {
            // Once we have upload arrays, merge them into the field prop array
            setFieldProps((old) =>
              old.map((prop, index) => ({ ...prop, url: resp.urls[index] })),
            );
          },
        },
      ),
    [filesToUpload, getUrls, setFieldProps],
  );

  // Form hook with default values to start with a matching field array for each fileToUpload
  const { control, handleSubmit, register, watch } =
    useForm<GalleryItemsFormType>({
      defaultValues: {
        items: filesToUpload.map((file) => ({
          // Set required defaults first
          itemType: "item",
          status: "PUBLISHED",
          tags: [],
          galleryFolderIds: [],
          pinnedItemIds: [],
          itemIds: [],
          // Override with any values passed in from parent component
          ...defaultValues,
          // Finally, use this file's name to create the title
          title: removeFileExt(file.name),
        })),
      },
    });

  // Setup field array for all items, require at least 1 item for submit validation
  const { fields, remove, move } = useFieldArray<GalleryItemsFormType>({
    control,
    name: "items",
    shouldUnregister: true,
    rules: fieldOptions.minLength(1),
  });

  // Mutation for batch creating gallery items on form submit
  const { mutate: createItems, ...createMutation } = useGalleryItemsCreate();

  // Store shared header component
  const modalHeader = (
    <Modal.Header>
      <Modal.Title>
        <IconNewImage /> Add Item Details
      </Modal.Title>
    </Modal.Header>
  );

  // If we failed getting upload urls, are still loading them, or failed uploading all images,
  // don't render the form. Show the appropriate message and an option to go back.
  if (urlMutation.isError || !urlData || fields.length === 0) {
    return (
      <>
        {modalHeader}

        {urlMutation.isError && <UnexpectedError error={urlMutation.error} />}
        {!urlData && <div>Starting upload...</div>}
        {fields.length === 0 && (
          <Stack>
            <Text>Go back and try to upload them again.</Text>
          </Stack>
        )}

        <Modal.Footer>
          {!urlMutation.isLoading && (
            <Button variant="secondary" onClick={retry}>
              Try Again
            </Button>
          )}
        </Modal.Footer>
      </>
    );
  }

  // Create submit handler and close modal on success
  const onSubmit = handleSubmit((formData) => {
    createItems({ artistId, ...formData }, { onSuccess, onError, onSettled });
  });

  // Create handler to remove item from field array on upload failure AND remove its props too
  const createRemoveHandler = (index: number) => () => {
    remove(index);
    setFieldProps((old) => [...old.slice(0, index), ...old.slice(index + 1)]);
  };

  // If field array length differs from files passed in by props, some have failed to upload
  const hasFailedUploads = filesToUpload.length !== fields.length;

  // If all items in field array have a url text field, then they've all settled their promises
  const items = watch("items");
  const allSettled = items.every((item) => !!item?.image?.url);

  // Disable form submission if submit mutation is loading or uploads are still loading
  const disabled = createMutation.isLoading || !allSettled;

  return (
    <Form onSubmit={onSubmit} id={formId}>
      {modalHeader}
      <Stack gap="0">
        {fields.map((field, index) => (
          <Draggable key={field.id} index={index} handleDrop={move}>
            <GalleryItemUpload
              index={index}
              {...fieldProps[index]}
              register={register}
              control={control}
              onError={createRemoveHandler(index)}
            />
          </Draggable>
        ))}

        {hasFailedUploads && <AppError>Some files failed to upload.</AppError>}
      </Stack>

      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button variant="secondary" onClick={cancel}>
            Cancel
          </Button>
          <Button type="submit" form={formId} disabled={disabled}>
            Add {fields.length} Items
          </Button>
        </FlexRow>
      </Modal.Footer>
    </Form>
  );
}

type GalleryItemUploadProps = {
  control: Control<GalleryItemsFormType>;
  file: File;
  index: number;
  onError: VoidFunction;
  register: UseFormRegister<GalleryItemsFormType>;
  url: string;
};

function GalleryItemUpload({
  control,
  file,
  index,
  onError,
  register,
  url,
  ...props
}: GalleryItemUploadProps) {
  const {
    field: { onChange, value },
  } = useController({ control, name: `items.${index}.image` });

  const [preview, setPreview] = React.useState<Image>();

  const { mutate, isIdle } = useFileUpload();
  const thumbnailMutation = useFileUpload();

  const videoRef = React.useRef<HTMLVideoElement>(null);
  const thumbnailRef = React.useRef<HTMLCanvasElement>(null);
  const timerRef = React.useRef<number>();

  // We have a successful upload, convert it to Image shape for preview
  const handleSuccess = React.useCallback(
    async (resp: UploadFileResp) => {
      // Check file type for possible video file
      const isVideo = resp.file.type.startsWith("video");
      // Convert uploaded url to one accessible from this domain, if needed
      const previewUrl = getTempPreviewUrl(resp.url);
      // TODO: create thumbnail for video

      // Using preview (or thumbnail) determine dimensions on client side to use immediately
      // within masonry grid and other layouts
      const dimensions = isVideo
        ? undefined
        : await getDimensionsFromUrl(previewUrl);

      // Create
      const img: Image = {
        url: isVideo ? "" : resp.url,
        videoUrl: isVideo ? resp.url : undefined,
        contentType: resp.file.type,
        importDate: new Date().toISOString(),
        ...dimensions,
      };
      setPreview({
        ...img,
        url: isVideo ? "" : previewUrl,
        videoUrl: isVideo ? previewUrl : undefined,
      });
      onChange(img);
    },
    [setPreview, onChange],
  );

  React.useEffect(() => {
    if (!isIdle) {
      return noop;
    }

    return mutate(
      { file, url },
      {
        onSuccess: async (resp) => {
          await handleSuccess(resp);
        },
        // If upload fails, remove the item from form's field array
        onError,
      },
    );
  }, [isIdle, mutate, file, url, onError, handleSuccess]);

  const handleGenerateThumb = async () => {
    const thumbFile = await generateThumbnail(
      thumbnailRef.current,
      videoRef.current,
    );
    if (thumbFile) {
      thumbnailMutation.mutate(
        { file: thumbFile },
        {
          onSuccess: async (result) => {
            const thumbUrl = getTempPreviewUrl(result.url);
            const dimensions = await getDimensionsFromUrl(thumbUrl);
            setPreview((old) => old && { ...old, url: thumbUrl });
            onChange({
              ...value,
              url: result.url,
              ...dimensions,
            });
          },
        },
      );
    }
  };

  // Once video has enough data to start playing, there should be a first frame showing. Make
  // doubly sure by adding a timeout. Then generate the first thumbnail of the preview video.
  const onCanPlay = () => {
    // Save timeout in case we need to clean it up on unmount
    timerRef.current = window.setTimeout(handleGenerateThumb, 500);
  };

  if (preview) {
    /* eslint-disable jsx-a11y/media-has-caption */
    return (
      <Item {...props}>
        {preview.videoUrl ? (
          <HiddenVideoWrapper>
            <video
              ref={videoRef}
              src={preview.videoUrl ?? ""}
              onCanPlay={onCanPlay}
              disableRemotePlayback
              crossOrigin="anonymous"
              playsInline
            />
            <canvas ref={thumbnailRef} />
            {!preview.url && <ImgPlaceholder />}
            <IconVideo />
          </HiddenVideoWrapper>
        ) : (
          <SImg
            src={preview.url}
            alt={`Upload preview of ${file.name}`}
            variant="rounded"
          />
        )}

        <Label>
          Name
          <RequiredText />
          <Input {...register(`items.${index}.title`, fieldOptions.required)} />
        </Label>
      </Item>
    );
    /* eslint-enable jsx-a11y/media-has-caption */
  }

  return (
    <Item delay={index * 250}>
      <ImgPlaceholder />
      <div>
        <PlaceholderBlock height="2rem" />
      </div>
    </Item>
  );
}

const Item = styled(FadeIn)(({ theme }) => ({
  alignItems: "center",
  backgroundColor: theme.colors.bg,
  cursor: "grab",
  display: "grid",
  gap: 10,
  gridTemplateColumns: "70px 1fr",
  paddingBlock: theme.spacing.md,
  transition: "background-color ease 0.3s",
  [theme.breakpoints.xs]: {
    gridTemplateColumns: "100px 1fr",
  },
  [theme.breakpoints.tablet]: {
    gridTemplateColumns: "120px 1fr",
  },
  [theme.breakpoints.desktop]: {
    gridTemplateColumns: "140px 1fr",
  },
  "&.dragging": {
    opacity: 0.1,
  },
  "&.dragging-over": {
    backgroundColor: theme.colors.accent1LL,
  },
  "&+&": {
    borderTop: "1px solid",
    borderTopColor: theme.colors.fg20,
  },
}));

const SImg = styled(Img)({
  aspectRatio: "1",
  objectFit: "contain",
  objectPosition: "center",
  pointerEvents: "none",
});

const ImgPlaceholder = styled(PlaceholderBlock)({
  aspectRatio: "1",
  pointerEvents: "none",
});

const HiddenVideoWrapper = styled.div({
  aspectRatio: "1",
  objectFit: "contain",
  objectPosition: "center",
  pointerEvents: "none",
  position: "relative",
  "&>*": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%,-50%)",
    width: "100%",
  },
  video: {
    opacity: 0,
    width: "100%",
  },
  svg: {
    width: "30%",
  },
});

export { GalleryItemsForm };
