import * as React from "react";
import { Button, FlexRow, IconNewImage, Modal } from "atoms";
import { pluralize } from "utils/text";
import { useEditorContext } from "../utils/useEditorContext";
import { useGalleryAddPortfolioItems } from "../api/useGalleryAddPortfolioItems";
import type { GalleryItem } from "../types";
import { SelectFromPortfolio } from "./SelectFromPortfolio";

type AddItemsFromPortfolioProps = {
  galleryId: string;
  closeModal: VoidFunction;
};

function AddItemsFromPortfolio({
  galleryId,
  closeModal,
}: AddItemsFromPortfolioProps) {
  const { artist } = useEditorContext();

  // Tracking for Selected Items
  const [selected, setSelected] = React.useState<GalleryItem[]>([]);

  // Mutation to Update Data
  const { mutate, isLoading } = useGalleryAddPortfolioItems();

  const onSave = () => {
    mutate(
      {
        artistId: artist.id,
        galleryId,
        itemIds: selected.map(({ id }) => id),
      },
      {
        onSuccess: closeModal,
      },
    );
  };

  return (
    <>
      <Modal.Header>
        <Modal.Title>
          <IconNewImage /> Add From Portfolio
        </Modal.Title>
      </Modal.Header>

      <SelectFromPortfolio
        artistId={artist.id}
        onChange={setSelected}
        multiple
      />

      <Modal.Footer>
        <FlexRow justifyContent="center">
          <Button variant="secondary" onClick={closeModal}>
            Cancel
          </Button>
          <Button onClick={onSave} disabled={isLoading}>
            {isLoading
              ? "Saving..."
              : `Add ${pluralize(selected.length, "Item", null, selected.length > 1)}`}
          </Button>
        </FlexRow>
      </Modal.Footer>
    </>
  );
}

export { AddItemsFromPortfolio };
