import * as React from "react";
import styled from "@emotion/styled";
import { CSSTransition } from "react-transition-group";
import brush from "assets/images/hug-brush-1.svg";
import { Button, FlexRow, H2, Img, SliderWave, Stack } from "atoms";
import { preventForwarding } from "styles/styleUtils";

const slideGap = "var(--slide-gap)";
const TIMEOUT = 600;
const footerHeight = "calc(4rem + var(--site-gap))";

/**
 * Number of pixels needed to swipe to trigger next/prev slide
 */
const swipeThreshold = 50;
/**
 * State when no swipe event is currently in progress
 */
const defaultSwipeState = { xPos: 0, offset: 0, isSwiping: false };

type SwipeState = typeof defaultSwipeState;

type Slide = {
  /**
   * A unique number for each slide to keep track of transitions
   */
  id: number;
  header: React.ReactNode;
  title: React.ReactNode;
  copy: React.ReactNode;
};

type SliderProps = {
  slides: Slide[];
  /**
   * Allow slides to wrap around infinitely
   */
  wrap?: boolean;
};

/**
 * This slider is a full-screen component
 */
function Slider({ slides, wrap = false }: SliderProps) {
  const [activeIndex, setActiveIndex] = React.useState(0);

  const [slideDirection, setSlideDirection] = React.useState<"left" | "right">(
    "left",
  );

  const [swipeState, setSwipeState] =
    React.useState<SwipeState>(defaultSwipeState);

  const showPrev = wrap || activeIndex > 0;
  const handlePrev = () => {
    setSlideDirection("left");
    setActiveIndex((activeIndex - 1 + slides.length) % slides.length);
  };

  const showNext = wrap || activeIndex < slides.length - 1;
  const handleNext = () => {
    setSlideDirection("right");
    setActiveIndex((activeIndex + 1) % slides.length);
  };

  /**
   * Mark original position of mouse/swipe on x axis
   */
  const handleSwipeStart = (xPos: number) => {
    setSwipeState({ ...swipeState, xPos, isSwiping: true });
  };
  /**
   * Update offset from original position during swipe
   */
  const handleSwipe = (xPos: number) => {
    if (swipeState.isSwiping) {
      setSwipeState({ ...swipeState, offset: xPos - swipeState.xPos });
    }
  };
  /**
   * Trigger next slide after the swipe is done
   */
  const handleSwipeEnd = () => {
    // Make sure the swipe is intentional, i.e. they moved enough pixels and not just an accidental small amount
    if (Math.abs(swipeState.offset) > swipeThreshold) {
      // A positive delta is swiping from left to right, make sure we can go to previous and go there
      if (swipeState.offset > 0 && showPrev) {
        handlePrev();
      }
      // A negative delta is swiping from right to left, make sure we can go to next and go there
      else if (swipeState.offset < 0 && showNext) {
        handleNext();
      }
    }
    // Reset to default to prep for next swipe event
    setSwipeState(defaultSwipeState);
  };

  const onTouchStart: React.TouchEventHandler = (event) => {
    handleSwipeStart(event.targetTouches[0].clientX);
  };

  const onMouseDown: React.MouseEventHandler = (event) => {
    handleSwipeStart(event.clientX);
  };

  const onTouchMove: React.TouchEventHandler = (event) => {
    handleSwipe(event.targetTouches[0].clientX);
  };

  const onMouseMove: React.MouseEventHandler = (event) => {
    handleSwipe(event.clientX);
  };

  const onTouchEnd: React.TouchEventHandler = () => {
    handleSwipeEnd();
  };

  const onMouseUp: React.MouseEventHandler = () => {
    handleSwipeEnd();
  };

  return (
    <>
      <SliderContainer
        onTouchStart={onTouchStart}
        onTouchMove={onTouchMove}
        onTouchEnd={onTouchEnd}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseUp={onMouseUp}
      >
        {slides.map((slide, index) => (
          <SlideTransition
            key={slide.id}
            activeIndex={activeIndex}
            index={index}
            slide={slide}
            slideCount={slides.length}
            slideDirection={slideDirection}
          />
        ))}

        <Footer>
          <SlideWrapper>
            <FlexRow justifyContent="space-between" flexWrap="nowrap">
              <SliderButton
                onClick={handlePrev}
                className="prev"
                aria-label="previous slide"
                disabled={!showPrev}
                variant="secondary"
              >
                Prev
              </SliderButton>
              <FlexRow alignItems="center" gap="10px">
                {slides.map((slide, index) => (
                  <Dot
                    onClick={() => setActiveIndex(index)}
                    isActive={activeIndex === index}
                    key={slide.id}
                    type="button"
                    aria-label={`Go to slide ${index}`}
                  />
                ))}
              </FlexRow>
              <SliderButton
                onClick={handleNext}
                disabled={!showNext}
                aria-label="next slide"
                variant="primary"
                arrow="r"
              >
                Next
              </SliderButton>
            </FlexRow>
          </SlideWrapper>
        </Footer>
        <BackgroundFade activeSlide={activeIndex} slides={slides} />
      </SliderContainer>
      <BrushStroke src={brush} aria-hidden="true" />
      <BackgroundFade activeSlide={activeIndex} slides={slides} />
    </>
  );
}

type SlideTransitionProps = {
  slide: Slide;
  activeIndex: number;
  index: number;
  slideCount: number;
  slideDirection: "left" | "right";
};

function SlideTransition({
  activeIndex,
  index,
  slide,
  slideCount,
  slideDirection,
}: SlideTransitionProps) {
  const slideRef = React.useRef<null | HTMLDivElement>(null);

  return (
    <CSSTransition
      timeout={TIMEOUT}
      key={index}
      nodeRef={slideRef}
      in={index === activeIndex}
      appear
    >
      <SlideContainer slideDirection={slideDirection} ref={slideRef}>
        <Header>
          <SlideWrapper>{slide.header}</SlideWrapper>
        </Header>

        <Content>
          <SlideSliderWave
            slideCount={slideCount}
            activeIndex={activeIndex}
            slideIndex={index}
          />

          <ContentMain>
            <ContentSlideWrapper>
              <Stack gap="sm">
                <SlideTitle>{slide.title}</SlideTitle>

                {slide.copy}
              </Stack>
            </ContentSlideWrapper>
          </ContentMain>
        </Content>
      </SlideContainer>
    </CSSTransition>
  );
}

const SliderContainer = styled.div(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "flex-end",
  paddingBottom: "env(safe-area-inset-bottom)",
  overflowY: "auto",
  overflowX: "hidden",
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  zIndex: 10,
  "@media (min-width: 831px) and (orientation: portrait)": {
    position: "fixed",
    top: "50%",
    right: "auto",
    bottom: "auto",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: theme.borderRadius.sm,
    boxShadow: theme.boxShadow.dark,
    width: "min(100vw - 2rem, 600px)",
    height: "min(100vh - 2rem, 900px)",
  },
  "@media (min-width: 1024px) and (orientation: landscape)": {
    position: "fixed",
    top: "50%",
    right: "auto",
    bottom: "auto",
    left: "50%",
    transform: "translate(-50%, -50%)",
    borderRadius: theme.borderRadius.sm,
    boxShadow: theme.boxShadow.dark,
    width: "min(100vw - 4rem, 1000px)",
    height: "min(100vh - 4rem, 700px)",
    overflow: "hidden",
  },
}));

const SlideWrapper = styled.div({
  maxWidth: 600,
  margin: "0 auto",
});

const SlideContainer = styled.div<{
  slideDirection: "left" | "right";
}>(({ slideDirection }) => ({
  opacity: 0,
  position: "absolute",
  overflowY: "auto",
  overflowX: "hidden",
  top: 0,
  right: 0,
  bottom: footerHeight,
  left: 0,
  flex: 1,
  display: "flex",
  flexDirection: "column",
  zIndex: 3,
  pointerEvents: "none",
  "@media (orientation: landscape)": {
    flexDirection: "row",
    overflow: "hidden",
    bottom: 0,
  },

  "&>*": {
    position: "relative",
    zIndex: 9,
  },
  "&.enter": {
    opacity: 1,
    transform:
      slideDirection === "left" ? "translateX(-100%)" : "translateX(100%)",
  },
  "&.enter-active": {
    opacity: 1,
    transition: `opacity ${TIMEOUT}ms ease, transform ${TIMEOUT}ms ease`,
    transform: "translateX(0)",
  },
  "&.enter-done": {
    opacity: 1,
    transform: "translateX(0)",
    pointerEvents: "auto",
  },
  "&.exit": {
    opacity: 1,
    transform: "translateX(0)",
  },
  "&.exit-active": {
    opacity: 1,
    transition: `opacity ${TIMEOUT}ms ease, transform ${TIMEOUT}ms ease`,
    transform:
      slideDirection === "left" ? "translateX(100%)" : "translateX(-100%)",
  },
}));

const Header = styled.div({
  position: "relative",
  flex: "0 0 auto",
  paddingTop: `calc(${slideGap} + env(safe-area-inset-top) + 1vh)`,
  paddingRight: `calc(${slideGap} + env(safe-area-inset-right) + 1rem)`,
  paddingBottom: `calc(${slideGap} + 1.5rem + 1vh)`,
  paddingLeft: `calc(${slideGap} + env(safe-area-inset-left) + 1rem)`,
  img: {
    display: "block",
    minWidth: "100%",
    maxWidth: "100%",
    height: "auto",
  },
  svg: {
    display: "block",
    margin: "0 auto",
    minWidth: 120,
    maxWidth: 240,
    width: "18vw",
  },
  "svg .accent": {
    opacity: 0.6,
  },
  "@media (orientation: landscape)": {
    flex: "0 0 50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    paddingBottom: `calc(${footerHeight} + env(safe-area-inset-bottom) + 1vh)`,
    paddingRight: `calc(${slideGap} + 1.5rem)`,
    paddingLeft: `calc(${slideGap} + env(safe-area-inset-left))`,
  },
});

const Content = styled.div(({ theme }) => ({
  flex: 1,
  backgroundColor: theme.colors.bg,
  display: "flex",
  alignItems: "stretch",
  position: "relative",
  "@media (orientation: landscape)": {
    flex: "0 0 50%",
  },
}));

const ContentMain = styled.div({
  marginRight: `${slideGap}`,
  marginBottom: 0,
  marginLeft: `${slideGap}`,
  position: "relative",
  flex: 1,
});

// Container that allows scrolling on overflow
const ContentSlideWrapper = styled(SlideWrapper)(({ theme }) => ({
  flex: 1,
  display: "flex",
  alignItems: "center",
  paddingTop: `calc(${slideGap} + 2vh)`,
  paddingBottom: `calc(1rem + 2vh)`,
  "@media (orientation: portrait) and (min-height: 831px)": {},
  "@media (orientation: landscape)": {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: "100%",
    paddingTop: `calc(${slideGap} + 1vh)`,
    paddingBottom: `calc(${footerHeight} + env(safe-area-inset-bottom) + 1vh)`,
    maxHeight: "100%",
    overflow: "auto",
    "&>*": {
      alignSelf: "self-start",
    },
    WebkitScrollbar: {
      width: 3,
      borderRadius: 9,
    },
    WebkitScrollbarTrack: {
      background: theme.colors.bg,
      borderRadius: 9,
    },
    WebkitScrollbarThumb: {
      background: theme.colors.fg20,
      borderTop: "1rem solid white",
      borderBottom: "1rem solid white",
      borderRadius: 9,
    },
    "WebkitScrollbarThumb:hover": {
      background: theme.colors.fg20,
      borderRadius: 9,
    },
  },
  form: {
    gap: "1rem",
  },
}));

const SlideTitle = styled(H2)(({ theme }) => ({
  fontSize: theme.fontSizes.xxxl,
  lineHeight: 1.1,
  "@media (min-width: 600px)": {
    fontSize: theme.fontSizes.xxl,
  },
}));

type SlideSliderWaveProps = {
  slideCount: number;
  activeIndex: number;
  slideIndex: number;
};

const SlideSliderWave = styled(SliderWave, {
  shouldForwardProp: preventForwarding<SlideSliderWaveProps>(
    "slideCount",
    "activeIndex",
    "slideIndex",
  ),
})<SlideSliderWaveProps>(({ slideCount, slideIndex, activeIndex }) => ({
  position: "absolute",
  bottom: "calc(100% - 2px)",
  height: "2rem",
  left: 0,
  width: "100%",
  transform: `translateX(-${slideIndex * 100}%) scaleX(${slideCount})`,
  transformOrigin: "bottom left",
  zIndex: 3,
  "@media (orientation: landscape)": {
    left: 1,
    transform: `rotate(90deg) translateX(-${
      activeIndex * 100
    }%) scaleX(${slideCount}) scaleY(-0.5)`,
  },
}));

const Footer = styled(FlexRow)(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  justifyContent: "stretch",
  justifySelf: "flex-end",
  flex: "0 0 auto",
  width: "100%",
  height: footerHeight,
  padding: `calc(${slideGap} / 2)`,
  position: "relative",
  zIndex: 9,
  "&>*": {
    flex: 1,
    maxWidth: 600,
    backgroundColor: theme.colors.bg,
    "@supports (backdrop-filter: blur(10px))": {
      backgroundColor: "rgb(255 255 255 / .85)",
    },
    padding: `calc(${slideGap} / 2)`,
    borderRadius: theme.borderRadius.round,
  },
  "@media (orientation: landscape)": {
    width: "50%",
    background: "rgb(255 255 255 / 0)",
    height: "auto",
    padding: `10px calc(${slideGap} / 2)`,
    "&>*": {
      backdropFilter: "blur(5px)",
      padding: `10px calc(${slideGap} / 2)`,
    },
  },
  ".enter-active + &>*": {
    "@media (orientation: landscape)": {
      // boxShadow: theme.boxShadow.light,
    },
  },
  ".enter-active + & button, .exit-active + & button": {
    pointerEvents: "none",
  },

  "@media (orientation: portrait) and (max-height: 700px)": {
    boxShadow: theme.boxShadow.light,
  },
  "@media (orientation: landscape) and (max-height: 500px)": {
    "&>*": {
      boxShadow: theme.boxShadow.light,
    },
  },
}));

// const FooterSpacer = styled.div(({ theme }) => ({
//   backgroundColor: theme.colors.fg20,
//   flex: 1,
//   height: 1,
//   display: "none",
// [theme.breakpoints.xs]: {
//   display: "inline-block",
//   // },
//   "@media (orientation: landscape) and (max-width: 830px)": {
//     display: "none",
//   },
// }));

const Dot = styled.button<{ isActive: boolean }>(({ isActive, theme }) => ({
  appearance: "none",
  border: 0,
  borderRadius: theme.borderRadius.round,
  width: 10,
  height: 10,
  padding: 0,
  backgroundColor: theme.colors.fg20,
  transition: `${TIMEOUT}ms ease all`,
  position: "relative",
  overflow: "hidden",
  ...(isActive && {
    pointerEvents: "none",
  }),

  "&:nth-of-type(1)::before, &:nth-of-type(5)::before": {
    content: "''",
    inset: 0,
    opacity: 0,
    position: "absolute",
    transition: `${TIMEOUT}ms ease all`,
    backgroundColor: theme.colors.accent2,
    borderRadius: theme.borderRadius.round,
    ...(isActive && {
      opacity: 1,
    }),
  },

  "&:nth-of-type(2)::before, &:nth-of-type(6)::before": {
    content: "''",
    inset: 0,
    opacity: 0,
    position: "absolute",
    transition: `${TIMEOUT}ms ease all`,
    backgroundColor: theme.colors.accent3,
    borderRadius: theme.borderRadius.round,
    ...(isActive && {
      opacity: 1,
    }),
  },

  "&:nth-of-type(3)::before, &:nth-of-type(7)::before": {
    content: "''",
    inset: 0,
    opacity: 0,
    position: "absolute",
    transition: `${TIMEOUT}ms ease all`,
    backgroundColor: theme.colors.accent4,
    borderRadius: theme.borderRadius.round,
    ...(isActive && {
      opacity: 1,
    }),
  },

  "&:nth-of-type(4)::before, &:nth-of-type(8)::before": {
    content: "''",
    inset: 0,
    opacity: 0,
    position: "absolute",
    transition: `${TIMEOUT}ms ease all`,
    backgroundColor: theme.colors.accent1,
    borderRadius: theme.borderRadius.round,
    ...(isActive && {
      opacity: 1,
    }),
  },
  ...(isActive && {
    width: 10 * 2.5,
  }),
}));

const SliderButton = styled(Button)(({ theme }) => ({
  minHeight: 0,
  position: "relative",
  transition: ".2s ease all",
  "&:active": {
    top: 1,
  },
  "&[disabled]": {
    opacity: 0.4,
  },
  [theme.breakpoints.desktop]: {
    fontSize: theme.fontSizes.sm,
    padding: ".2em 1em",
  },
}));

const BrushStroke = styled(Img)({
  bottom: 0,
  height: "100vh",
  maxWidth: "none",
  opacity: 0.1,
  position: "fixed",
  left: 0,
  top: 0,
  transform: "scale(1.4) translate(-45%, -7%)",
  width: "auto",
  zIndex: 2,
});

type BackgroundFadeProps = {
  activeSlide: number;
  slides: Slide[];
};

function BackgroundFade({ slides, activeSlide }: BackgroundFadeProps) {
  return (
    <BackgroundContainer>
      {slides.map((slide, index) => (
        <Background activeSlide={activeSlide} position={index} key={slide.id} />
      ))}
    </BackgroundContainer>
  );
}

const BackgroundContainer = styled.div({
  " body &, body &>div": {
    position: "fixed",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 1,
  },
});

const Background = styled.div<{ activeSlide: number; position: number }>(
  ({ activeSlide, position, theme }) => ({
    opacity: position === activeSlide ? 1 : 0,
    transitionDelay: position === activeSlide ? `${TIMEOUT}ms` : "0s",
    transition: `${TIMEOUT}ms ease opacity`,
    backgroundColor: theme.colors.accent2,
    ...(position % 4 === 0 && {
      backgroundColor: theme.colors.accent2,
    }),
    ...(position % 4 === 1 && {
      backgroundColor: theme.colors.accent3,
    }),
    ...(position % 4 === 2 && {
      backgroundColor: theme.colors.accent4,
    }),
    ...(position % 4 === 3 && {
      backgroundColor: theme.colors.accent1,
    }),
  }),
);

export { Slider };
