import * as React from "react";
import mixpanel from "mixpanel-browser";
import { useLocation } from "react-router-dom";
import { isDev, constants } from "config";

/**
 * Safe wrapper for Meta Pixel tracking in case FB script doesn't run or is overridden
 *
 * See list of standard events here: https://developers.facebook.com/docs/meta-pixel/reference/
 */
const metaPixel = (...args: Parameters<typeof window.fbq>) => {
  if (window.fbq) {
    window.fbq(...args);
  }
};

// Configure mixpanel client
mixpanel.init(constants.mixPanelToken, {
  // Ignore DNT or we won't capture any events
  ignore_dnt: true,
  // Prefer localStorage to cookies so we don't hit size limits
  persistence: "localStorage",
  // Turn on debug mode in development
  debug: isDev,
});

/**
 * The method used to Sign In or Sign Up
 */
type AuthMethod = "Wallet" | "Email" | "Magic Link";

/**
 * Tracked button clicks
 */
type ButtonEvent =
  | "Art Medium"
  | "Artist Collection"
  | "Buy Additional Submissions"
  | "Connect Wallet"
  | "Download"
  | "Home Hero CTA"
  | "Open Call Submit Edit"
  | "Open Call Submit"
  | "Open Call Vote"
  | "Post Link"
  | "Search Open"
  | "Search Results"
  | "Sign In"
  | "Sign Up"
  | "Skip Application"
  | "Social Link";

/**
 * Tracked form submissions
 */
type FormEvent =
  | "Email Sign In"
  | "Email Sign Up"
  | "Artist Application"
  | "Open Call Submission"
  | "Open Call Submission Edit"
  | "Open Call Partner Curation"
  | "Open Call Vote";

/**
 * Tracked cart contents for commerce events
 */
type CartContents = { id: string; quantity: number }[];

type TrackedEvent =
  | {
      name: "Sign In" | "Sign Up";
      Method: AuthMethod;
    }
  | {
      name: "Button Click";
      ButtonName: ButtonEvent;
      ButtonContext: string;
      Resource?: string;
      ButtonDestination?: string;
    }
  | {
      name: "Form Submit";
      FormName: FormEvent;
      FormContext?: string;
      ArtistId?: string;
      ApplicationId?: string;
      OpenCallId?: string;
    }
  | {
      name: "Menu Click";
      MenuName: string;
    }
  | {
      name: "Share";
      ShareType: "Tweet" | "Copy";
    }
  | {
      name: "AddToCart";
      contents: CartContents;
    }
  | {
      name: "Purchase";
      contents: CartContents;
      currency: string;
      value: number;
    }
  | {
      name: "AddToCartShopify";
      variantId: string | undefined | null;
      productType: string | undefined | null;
      editionType: string | undefined | null;
      templateId: string | undefined | null;
      price: number | null;
    }
  | {
      name: "CheckoutShopify";
      cartSubtotal: number;
    };

/**
 * Track a user event with MixPanel and Meta Pixel
 */
const trackEvent = (event: TrackedEvent) => {
  if (event.name === "Form Submit") {
    if (event.FormName === "Artist Application") {
      metaPixel("track", "SubmitApplication");
    }
  } else if (event.name === "AddToCart" || event.name === "Purchase") {
    metaPixel("track", event.name, { contents: event.contents });
  } else if (event.name === "Sign Up") {
    metaPixel("track", "CompleteRegistration", { content_name: event.Method });
  }

  const { name, ...options } = event;
  mixpanel.track(name, { ...options, Location: window.location.href });
};

/**
 * Set User for Mix Panel tracking session
 */
const setUser = (user: {
  roles: string[];
  id: string;
  name?: string;
  email?: string;
  claimedArtistPageIds?: string[];
}) => {
  const showAdminFeatures =
    user && user.roles.find((r) => r === "admin" || r === "superAdmin");
  mixpanel.identify(user.id);

  mixpanel.people.set({
    user_id: user.id,
    name: user.name,
    email: user.email,
    HasArtistProfile: `${!!user?.claimedArtistPageIds?.length}`,
    IsAdmin: showAdminFeatures,
  });
};

/**
 * Remove user from Mix Panel tracking session
 *
 * NOTE: don't call too frequently, see:
 * https://docs.mixpanel.com/docs/tracking/reference/javascript#call-reset-at-logout
 */
const removeUser = () => {
  mixpanel.reset();
};

/**
 * Log page view in MixPanel
 *
 * Without this hook in the Router, MixPanel would only log initial load of SPA, not client routing
 */
const usePageTracking = () => {
  const location = useLocation();

  React.useEffect(() => {
    mixpanel.track_pageview(location);
  }, [location]);
};

const trackShareTweet = () =>
  trackEvent({
    name: "Share",
    ShareType: "Tweet",
  });
const trackShareCopy = () =>
  trackEvent({
    name: "Share",
    ShareType: "Copy",
  });

export {
  setUser,
  removeUser,
  trackEvent,
  usePageTracking,
  trackShareTweet,
  trackShareCopy,
};
