import {
  type IconProps,
  IconReactionMustHug,
  IconReactionChefsKiss,
  IconReactionMindBlowing,
  IconReactionNeedItNow,
  IconReactionObsessed,
  IconReactionOutOfThisWorld,
  IconReactionTakeMyMoney,
  IconReactionThoughtProvoking,
} from "./icons";

const artistReactionsMap = {
  "Must Hug": IconReactionMustHug,
  "Chef's kiss": IconReactionChefsKiss,
  "Mind-blowing": IconReactionMindBlowing,
  "Need it now": IconReactionNeedItNow,
  Obsessed: IconReactionObsessed,
  "Out of this world": IconReactionOutOfThisWorld,
  "Take my money": IconReactionTakeMyMoney,
  "Thought-provoking": IconReactionThoughtProvoking,
} as const;
type ArtistReaction = keyof typeof artistReactionsMap;

type RatingIconProps = IconProps & {
  rating: ArtistReaction;
};

function RatingIcon({ rating, ...props }: RatingIconProps) {
  const MappedIcon = artistReactionsMap[rating];
  return MappedIcon ? <MappedIcon {...props} /> : null;
}

export type { ArtistReaction };
export { RatingIcon };
