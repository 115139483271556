import styled from "@emotion/styled";

const ShowOnDesktop = styled.div(({ theme }) => ({
  display: "none",
  [theme.breakpoints.desktop]: {
    display: "block",
  },
}));

export { ShowOnDesktop };
