import styled from "@emotion/styled";
import { To } from "react-router-dom";
import { Container, H1, IconArrow, Link, Text } from "atoms";

type ArtistNotFoundProps = {
  type: React.ReactNode;
  children?: React.ReactNode;
  linkTo: To;
  linkText: React.ReactNode;
};

function ArtistNotFound({
  type,
  children,
  linkTo,
  linkText,
}: ArtistNotFoundProps) {
  return (
    <Main>
      <H1>Sorry, that {type} doesn&apos;t exist...</H1>

      {typeof children === "string" ? (
        <Text size="lg">{children}</Text>
      ) : (
        children
      )}

      <Link to={linkTo} variant="primary">
        <IconArrow dir="l" />
        {linkText}
      </Link>
    </Main>
  );
}

const Main = styled(Container)({
  alignItems: "flex-start",
  display: "flex",
  justifyContent: "center",
  flexDirection: "column",
  minHeight: "80vh",
}).withComponent("main");

export { ArtistNotFound };
