import * as React from "react";
import styled from "@emotion/styled";
import type { ProductOption } from "@shopify/hydrogen-react/storefront-api-types";
import { useForm } from "react-hook-form";
import { useSearchParams } from "react-router-dom";
import {
  FieldError,
  FlexRow,
  H2,
  IconError,
  IconOk,
  IconWarningSignAlt,
  // NotebookPreview,
  RequiredText,
  Stack,
  Text,
} from "atoms";
import { GenericImage, getDimensionsFromUrl } from "features/images";
import {
  useUpdateProductOptions,
  type UpdateProductOptionsReq,
} from "../../api/useUpdateProductOptions";
import type { StepProps } from "../../types";
import { ProductWizardStep } from "../ProductWizardStep";
import { StepInput } from "../WizardStepOption";

// type NotebookMockupProps = { src: string; isA4: boolean };
// function NotebookMockup({ src, isA4 }: NotebookMockupProps) {
//   return (
//     <MockupWrapper>
//       <SNotebookPreview isA4={isA4}>
//         <GenericImage src={src} />
//       </SNotebookPreview>
//     </MockupWrapper>
//   );
// }

function StepSpiralNotebookOptions({ dispatch, state, moveBack }: StepProps) {
  const { isLoading, mutate } = useUpdateProductOptions(state.product.id);

  // Brand new products will get this param - When present don't select
  // any of the variants so the user has to choose.
  const [searchParams, setSearchParams] = useSearchParams();
  const newProduct = searchParams.get("new");

  // Create filter callback to hide options for this template type
  const filterHiddenOpts = React.useMemo(() => {
    const hiddenOpts = state.productTemplate.options
      .filter((o) => o.hideInShopBuilder)
      .map((o) => o.key);
    return (opt: ProductOption) => !hiddenOpts.includes(opt.name);
  }, [state.productTemplate]);

  const {
    register,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
  } = useForm<UpdateProductOptionsReq>({
    defaultValues: {
      // Initialize form with product's selected option values, but filter out any options that
      // should be hidden during the builder. If this product was just created, require a selection
      // for each option by initializing with empty array of values.
      productOptions: state.product.options.filter(filterHiddenOpts).map((o) =>
        newProduct
          ? {
              ...o,
              values: [],
            }
          : o,
      ),
    },
  });

  // Remove the search param - It has already impacted defaultValues above
  // by this time. Worst case is a page-refresh loses the param and we have
  // a default selection
  React.useEffect(() => {
    if (newProduct) {
      setSearchParams(
        (prev) => {
          prev.delete("new");
          return prev;
        },
        { replace: true },
      );
    }
  }, [newProduct, setSearchParams]);

  const [assetWidth, setAssetWidth] = React.useState(0);
  const [assetHeight, setAssetHeight] = React.useState(0);

  React.useEffect(() => {
    if (state.product.assetUrl) {
      getDimensionsFromUrl(state.product.assetUrl).then(({ height, width }) => {
        setAssetWidth(width);
        setAssetHeight(height);
      });
    }
  }, [state.product.assetUrl, assetWidth, assetHeight]);

  const onSubmit = handleSubmit(({ id, productOptions }) => {
    if (isDirty) {
      mutate(
        { id, productOptions },
        {
          onSuccess: ({ product }) =>
            dispatch({ type: "updateProduct", moveNext: true, product }),
        },
      );
    } else {
      dispatch({ type: "moveNext" });
    }
  });

  const createValidator = (index: number) => () => {
    const vals = getValues("productOptions")[index]?.values ?? [];
    return vals.length >= 1;
  };

  return (
    <ProductWizardStep
      stepTitle="Product Options"
      state={state}
      moveBack={moveBack}
      nextStep={onSubmit}
      nextStepDisabled={isLoading}
    >
      <Stack gap="lg">
        {state.productTemplate.options
          .filter((o) => o.key === "Size")
          .map(({ key: option, values }, optIndex) => (
            <Stack key={option}>
              <Stack>
                <H2 size="md" textTransform="none">
                  Notebook Size
                  <RequiredText />
                </H2>
                <Text size="xs">
                  Select the notebook sizes you would like to offer. You must
                  select at least one size.
                </Text>
              </Stack>

              <Grid3x>
                {values.map((value) => {
                  const isA4 = value.includes("A4");
                  const dpiX = Math.floor(assetWidth / (isA4 ? 8.67 : 5.83));
                  const dpiY = Math.floor(assetHeight / (isA4 ? 11.69 : 8.27));
                  const dpi = Math.min(dpiX, dpiY);

                  const quality = getQuality(dpi);
                  const qualityMessage =
                    dpi > 0 ? (
                      <FlexRow gap="5px" flexWrap="nowrap">
                        <div style={{ color: quality.color }}>
                          {quality.icon}
                        </div>
                        <DpiText>
                          <span>{quality.text}</span> image quality:{" "}
                          <span>{dpi > 299 ? "300" : dpi}</span> dpi
                        </DpiText>
                      </FlexRow>
                    ) : undefined;

                  return (
                    <StepInput
                      value={value}
                      key={`${option}-${value}`}
                      label={isA4 ? "A4 (8.7 x 11.7 in)" : "A5 (5.8 x 8.7 in)"}
                      description={qualityMessage}
                      inputType="checkbox"
                      image={
                        <GenericImage
                          src={state.product.featuredImage?.url}
                          sizes="300px"
                          style={{
                            transform: isA4 ? "none" : "scale(.7)",
                            transformOrigin: "50% 90%",
                          }}
                        />
                      }
                      {...register(`productOptions.${optIndex}.values`, {
                        validate: createValidator(optIndex),
                      })}
                    />
                  );
                })}
              </Grid3x>
              {errors.productOptions?.[optIndex] && (
                <FieldError>
                  You need to select at least one value for this option.
                </FieldError>
              )}
            </Stack>
          ))}
      </Stack>
    </ProductWizardStep>
  );
}

// const MockupWrapper = styled.div({
//   position: "absolute",
//   inset: 0,
// });

// const SNotebookPreview = styled(NotebookPreview)<{
//   isA4?: boolean;
//   transformOrigin?: "top" | "bottom";
// }>(({ isA4, transformOrigin = "bottom" }) => ({
//   height: "90%",
//   width: "auto",
//   margin: "6% auto 4%",
//   ...(!isA4 &&
//     transformOrigin === "top" && {
//       marginBottom: "-40%",
//     }),
//   transformOrigin: transformOrigin === "top" ? "50% 0%" : "50% 95%",
//   transform: isA4 ? "none" : "scale(.7)",
// }));

const Grid3x = styled.div(({ theme }) => ({
  display: "grid",
  marginInline: "auto",
  gap: theme.spacing.gap,
  [theme.breakpoints.xs]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (min-width: 660px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));

const getQuality = (dpi: number) => {
  if (dpi >= 0 && dpi < 225) {
    return {
      text: "Poor",
      color: "#D3462E",
      icon: <IconError size={18} />,
    };
  }
  if (dpi >= 225 && dpi < 280) {
    return {
      text: "Average",
      color: "#d67d00",
      icon: <IconWarningSignAlt size={18} />,
    };
  }
  if (dpi >= 280) {
    return {
      text: "Good",
      color: "#34b146",
      icon: <IconOk size={18} />,
    };
  }
  return { text: undefined, color: undefined };
};

const DpiText = styled.div(({ theme }) => ({
  color: theme.colors.fg,
  flex: 1,
  fontSize: 13,
  lineHeight: 1.1,
  textAlign: "left",
}));

StepSpiralNotebookOptions.displayName = "Options";

export { StepSpiralNotebookOptions };
