import * as React from "react";
import styled from "@emotion/styled";
import FocusLock from "react-focus-lock";
import { RemoveScroll } from "react-remove-scroll";
import { useToggle } from "utils/hooks";
import { Button } from "./Button";
import { FadeIn } from "./FadeIn";
import { IconX } from "./icons";
import { Portal } from "./Portal";
import { H2 } from "./Typography";

type ModalProps = {
  /**
   * Rendered content within the modal
   */
  children: React.ReactNode;
  /**
   * Optional element to focus on when modal opens
   *
   * Defaults to first focusable element in ModalContent
   */
  initialFocusRef?: React.RefObject<HTMLElement>;
  /**
   * Control whether the modal is open from parent
   *
   * Defaults to true for the use case of rendering conditionally via:
   * {isOpen && <Modal onClose={onClose} />}
   */
  isOpen?: boolean;
  /**
   * Optional handler to run when closing modal
   */
  onClose?: (event: React.MouseEvent | React.KeyboardEvent) => void;
  /**
   * Optional boolean to hide close button in top-right corner, defaults to false
   */
  hideCloseButton?: boolean;
  /**
   * Optional boolean to allow background scrolling while open, defaults to false
   */
  preventScrollLock?: boolean;
  /**
   * Optional element to place the portal within. Defauly: body
   */
  containerRef?: React.ComponentProps<typeof Portal>["containerRef"];
};

/**
 * Low-level component to handle effects of mounting the overlay. Do not use on its own.
 */
function ModalOverlayInner({ onClose = noop, ...props }: ModalProps) {
  const mouseDownRef = React.useRef<EventTarget | null>(null);
  const overlayRef = React.useRef<HTMLDivElement | null>(null);

  // When overlay is rendered, imperatively add aria-hidden to modal's sibling elements to prevent
  // screen reader from reading anything under the modal. Clean-up effect will return each sibling's
  // aria-hidden attribute to what it was originally, if it existed, or remove it.
  React.useEffect(() => createAriaHider(overlayRef.current), []);

  // Set up event handlers to close the modal when clicking outside of the ModalContent area or when
  // pressing the Escape button
  const onClick: React.MouseEventHandler = (event) => {
    // Make sure user is clicking the overlay or the default modal close button (i.e. "X" icon)
    if (
      mouseDownRef.current === event.currentTarget ||
      (event.target as HTMLElement)?.dataset?.modalCloseButton
    ) {
      event.stopPropagation();
      onClose(event);
    }
  };

  const onMouseDown: React.MouseEventHandler = (event) => {
    mouseDownRef.current = event.target;
  };

  const onKeyDown: React.KeyboardEventHandler = (event) => {
    if (event.key === "Escape") {
      event.stopPropagation();
      onClose(event);
    }
  };

  return (
    <StyledOverlay
      {...props}
      ref={overlayRef}
      onClick={onClick}
      onKeyDown={onKeyDown}
      onMouseDown={onMouseDown}
    />
  );
}

const StyledOverlay = styled(FadeIn)(({ theme }) => ({
  backgroundColor: `${theme.colors.fg}e0`,
  backdropFilter: "blur(1px)",
  bottom: 0,
  display: "grid",
  placeItems: "center",
  left: 0,
  overflow: "hidden",
  position: "fixed",
  right: 0,
  top: 0,
  zIndex: 500, // SiteHeader has a zIndex of 500
}));

/**
 * Low-level overlay component if you need more control than the standard Modal
 *
 * You'll need to use the other low-level ModalContent component as well, for example
 * @example
 * ```
 * <StyledModalOverlay isOpen={isOpen}>
 *   <ModalContent>
 *     <p>some content...</p>
 *   </ModalContent>
 * </StyledModalOverlay>
 * ```
 */
function ModalOverlay({
  containerRef,
  initialFocusRef,
  isOpen = true,
  preventScrollLock = false,
  ...props
}: ModalProps) {
  // If we're given a ref for initial focus, do so as soon as Modal shows and FocusLock activates
  const onActivation = React.useCallback(() => {
    if (initialFocusRef?.current) {
      initialFocusRef.current.focus();
    }
  }, [initialFocusRef]);

  return isOpen ? (
    <Portal containerRef={containerRef}>
      <FocusLock
        disabled={!isOpen}
        autoFocus
        returnFocus
        onActivation={onActivation}
      >
        <RemoveScroll enabled={!preventScrollLock && isOpen} allowPinchZoom>
          <ModalOverlayInner {...props} />
        </RemoveScroll>
      </FocusLock>
    </Portal>
  ) : null;
}

/**
 * Given an open modal overlay element, hide its siblings from screen readers
 */
const createAriaHider = (overlayEl: HTMLElement | null) => {
  if (!overlayEl) {
    return noop;
  }

  // Store any modified element and its original value in an array of tuples for cleanup function
  const modified: [HTMLElement, string | null][] = [];
  // Find the overlay's top-most ancester (i.e. overlay -> scroll-lock -> focus-lock -> *portal*)
  const modalEl = overlayEl.parentNode?.parentNode?.parentNode;

  Array.prototype.forEach.call(
    overlayEl.ownerDocument.querySelectorAll("body > *"),
    (el) => {
      // Skip the modal's element
      if (el === modalEl) {
        return;
      }

      // Get attribute value and skip if element is already hidden
      const val = el.getAttribute("aria-hidden");
      const isHidden = val !== null && val !== "false";
      if (isHidden) {
        return;
      }

      // Add aria-hidden to element and store it and its original value in array
      el.setAttribute("aria-hidden", "true");
      modified.push([el, val]);
    },
  );

  // To cleanup, iterate over any modified elements and undo the aria-hidden modification. If there
  // was no attribute value before, remove it. Otherwise, reset it to its original value.
  return () => {
    modified.forEach(([el, val]) => {
      if (val === null) {
        el.removeAttribute("aria-hidden");
      } else {
        el.setAttribute("aria-hidden", val);
      }
    });
  };
};

type ModalContentProps = React.ComponentProps<typeof StyledContent> &
  Pick<ModalProps, "hideCloseButton">;

/**
 * Low-level content component if you need more control than the standard Modal
 *
 * You'll need to nest within the other low-level ModalOverlay component, for example:
 * @example
 * ```
 * <ModalOverlay isOpen={isOpen}>
 *   <StyledModalContent>
 *     <p>some content...</p>
 *   </StyledModalContent>
 * </ModalOverlay>
 * ```
 */
function ModalContent({
  children,
  hideCloseButton = false,
  ...props
}: ModalContentProps) {
  // NOTE: The close button in top-right corner does not need a click handler. The event will bubble
  // up to the ModalOverlay's click handler and will be handled there, checking the button's
  // data attribute to close if itself or this button triggered the event. This button's content
  // needs to have `pointerEvents: "none"` set so the event is triggered by the button element and
  // not any of it's child elements (e.g. <svg>, <path>, etc.).
  return (
    <StyledContent
      aria-modal="true"
      role="dialog"
      {...props}
      data-modal-content
    >
      {!hideCloseButton && (
        <CloseButton variant="secondary" data-modal-close-button title="Close">
          <IconX size="10px" />
        </CloseButton>
      )}

      <ContentScrollWrapper>
        <div>{children}</div>
      </ContentScrollWrapper>
    </StyledContent>
  );
}

const StyledContent = styled.div(({ theme }) => ({
  background: theme.colors.bg,
  height: "100dvh",
  minHeight: 300,
  position: "fixed",
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
  display: "flex",
  flexDirection: "column",
  flexWrap: "nowrap",
  "@media (min-width: 831px) and (min-height: 450px)": {
    borderRadius: theme.borderRadius.sm,
    boxShadow: theme.boxShadow.dark,
    height: "auto",
    maxHeight: "90vh",
    margin: "0 auto",
    maxWidth: 1100,
    overflow: "hidden",
    position: "relative",
    width: "90%",
  },
  "& > header, & > footer": {
    backgroundColor: theme.colors.bg,
    boxShadow: theme.boxShadow.light,
    margin: 0,
    paddingBlock: theme.spacing.md,
    paddingInline: theme.spacing.md,
    [theme.breakpoints.tablet]: {
      paddingInline: theme.spacing.lg,
      paddingBlock: theme.spacing.md,
    },
    "*": {
      margin: 0,
    },
  },
  "& > header": {
    paddingRight: "3.2rem",
    paddingTop: `calc(${theme.spacing.md} + env(safe-area-inset-top))`,
    position: "relative",
    zIndex: 20,
  },
  "& > footer": {
    paddingBottom: `calc(${theme.spacing.md} + env(safe-area-inset-bottom))`,
    position: "relative",
    zIndex: 20,
  },
}));

const CloseButton = styled(Button)(({ theme }) => ({
  backgroundColor: `${theme.colors.bg}80`,
  borderRadius: theme.borderRadius.round,
  color: theme.colors.fg,
  width: 28,
  height: 28,
  padding: 3,
  position: "absolute",
  top: ".8rem",
  right: theme.spacing.md,
  zIndex: 30,
  // NOTE: This is necessary, see the note in ModalContent
  "& *": {
    pointerEvents: "none",
  },
}));

const ContentScrollWrapper = styled.div(({ theme }) => ({
  display: "grid",
  alignItems: "center",
  flex: 1,
  paddingBlock: theme.spacing.lg,
  paddingInline: theme.spacing.md,
  overflowY: "auto",
  overflowX: "hidden",
  [theme.breakpoints.tablet]: {
    paddingInline: theme.spacing.lg,
  },
  paddingTop: "3.2rem",
  position: "relative",
  zIndex: 10,
  // if the modal header is present, reduce top padding on the content
  "header + button + &, header + &": { paddingTop: theme.spacing.lg },
}));

/**
 * High-level modal component that renders its content over a standard overlay and prevents
 * accessibility to anything below it, trapping focus and locking scrolling elements below,
 * appending the content as the last child of the document body to avoid z-index issues.
 */
function Modal({
  initialFocusRef,
  isOpen,
  onClose = noop,
  ...props
}: ModalProps & ModalContentProps) {
  return (
    <ModalOverlay
      initialFocusRef={initialFocusRef}
      isOpen={isOpen}
      onClose={onClose}
    >
      <ModalContent {...props} />
    </ModalOverlay>
  );
}

const useModalState = (initialValue = false) => {
  const [isOpen, toggle, setIsOpen] = useToggle(initialValue);

  const open = React.useCallback(() => setIsOpen(true), [setIsOpen]);
  const close = React.useCallback(() => setIsOpen(false), [setIsOpen]);

  const value = React.useMemo(
    () => ({ isOpen, open, close, toggle }),
    [isOpen, open, close, toggle],
  );

  return value;
};

// Package low-level components and hooks within the Modal for easier imports and usage
Modal.Content = ModalContent;
Modal.Overlay = ModalOverlay;
Modal.useModalState = useModalState;

const createModalPortal = (tag: "header" | "footer") =>
  function ModalPortal({ children }: { children: React.ReactNode }) {
    const [container, setContainer] =
      React.useState<React.RefObject<HTMLElement>>();

    const callbackRef = React.useCallback(
      (etherealNode: HTMLDivElement | null) => {
        if (!etherealNode) {
          return;
        }

        const traverseParent = (element: HTMLElement | null): HTMLElement => {
          if (!element || element === document.body) {
            return etherealNode.parentElement ?? document.body;
          }
          if (element.dataset.modalContent) {
            return element;
          }
          return traverseParent(element.parentElement);
        };

        const modalEl = traverseParent(etherealNode);

        setContainer({ current: modalEl });
      },
      [],
    );

    return !container ? (
      <div ref={callbackRef} />
    ) : (
      <Portal containerRef={container} as={tag} prepend={tag === "header"}>
        {children}
      </Portal>
    );
  };

Modal.Header = createModalPortal("header");
Modal.Footer = createModalPortal("footer");

/** For use within Modal.Header */
Modal.Title = styled(H2)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-start",
  justifyContent: "center",
  flexWrap: "nowrap",
  fontSize: theme.fontSizes.md,
  gap: ".33em",
  svg: { flex: "0 0 1em" },
}));

/** Creates a container for an image or video */
const ModalMedia = styled.div(({ theme }) => ({
  flex: 1,
  display: "flex",
  [theme.breakpoints.desktopMedium]: {
    maxHeight: `calc(90vh - ((${theme.spacing.gutter} * 2) + 100px))`,
  },
  "&>div": {
    display: "flex",
    maxHeight: 700,
    objectFit: "none",
    "&::before": {
      backgroundSize: "contain",
      backgroundPosition: "center",
      filter: "none",
    },
  },
  "img, video": {
    borderRadius: theme.borderRadius.sm,
    objectFit: "contain",
    objectPosition: "center",
    width: "100%",
    height: "100%",
  },
  video: {
    backgroundColor: theme.colors.fg,
    maxHeight: `calc(90vh - ((${theme.spacing.gutter} * 2) + 100px))`,
  },
}));

export { Modal, ModalMedia };
