import styled from "@emotion/styled";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, IconArrow } from "atoms";
import { paths } from "utils/paths";
import { isRedirectState } from "utils/routing";

type GalleryBackButtonProps = {
  artistId: string;
};

function GalleryBackButton({ artistId }: GalleryBackButtonProps) {
  const navigate = useNavigate();
  const { pathname, state } = useLocation();

  // Is current page a Portfolio Editing page?
  const isEditing =
    pathname.includes("/portfolio") &&
    (pathname.endsWith("/edit") || pathname.endsWith("/reorder"));

  // Set default text and behavior of back button to return to portfolio or portfolio edit screen
  let buttonText = "Portfolio";
  let goBack = () =>
    navigate(
      isEditing
        ? paths.artistPortfolioEdit(artistId)
        : paths.artistPortfolio(artistId),
    );

  // If we were given router state, tweak the button text and behavior accordingly
  if (isRedirectState(state)) {
    const fromPath = state.from.pathname;

    if (fromPath.includes("/item")) {
      buttonText = "Item";
    } else if (fromPath.includes("/gallery")) {
      buttonText = "Gallery";
    }

    if (fromPath.includes("/portfolio")) {
      goBack = () => navigate(-1);
    }
  }

  return (
    <BackButton size="xxs" variant="blank" aria-label="Back" onClick={goBack}>
      <IconArrow dir="l" aria-hidden /> Back to {buttonText}
    </BackButton>
  );
}

const BackButton = styled(Button)({
  borderColor: "transparent",
  justifyContent: "flex-start",
  gap: 6,
  "&&:hover, &&:focus-visible": { borderColor: "transparent" },
});

export { GalleryBackButton };
