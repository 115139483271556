import styled from "@emotion/styled";

const Form = styled.form({
  display: "flex",
  flexDirection: "column",
  gap: "1.5rem",
  lineHeight: 1.3,
  "&>*": {
    margin: 0,
  },
});

export { Form };
