import * as React from "react";
import styled from "@emotion/styled";
import {
  Box,
  Button,
  FadeIn,
  FlexRow,
  Link,
  LoadingSpinner,
  Stack,
  Text,
  UnexpectedError,
} from "atoms";
import { useToggle } from "utils/hooks";
import { formatWalletAddress } from "utils/wallet";
import { useAdminWalletDelete } from "../api/useAdminWalletDelete";

type WalletProps = {
  wallets: string[];
  userId: string;
  canDeleteWallet?: boolean;
  canAddWallet?: boolean;
};

function WalletList({
  wallets,
  userId,
  canAddWallet,
  canDeleteWallet,
  ...props
}: WalletProps) {
  const [showDeletePrompt, toggleShowDeletePrompt, setShowDeletePrompt] =
    useToggle(false);
  const [currentWallet, setCurrentWallet] = React.useState<string>("");

  const hideDeleteConfirmation = () => {
    setCurrentWallet("");
    setShowDeletePrompt(false);
  };

  const onDeleteClick = (wallet: string) => () => {
    if (showDeletePrompt) {
      toggleShowDeletePrompt();
    } else {
      setCurrentWallet(wallet);
      setShowDeletePrompt(true);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <Stack>
        {wallets.length === 0 ? (
          <Text size="xxs">No wallets connected</Text>
        ) : (
          <StyledWallets {...props}>
            {wallets.map((wallet) => (
              <li key={wallet}>
                <StyledWallet>
                  {formatWalletAddress(wallet)}

                  {canDeleteWallet && (
                    <Button
                      size="xxs"
                      variant="text"
                      onClick={onDeleteClick(wallet)}
                    >
                      Delete
                    </Button>
                  )}
                </StyledWallet>
              </li>
            ))}
          </StyledWallets>
        )}

        {canAddWallet && (
          <Link to="/profile/edit#wallet" size="xs">
            Add a wallet
          </Link>
        )}
      </Stack>

      {canDeleteWallet && (
        <DeleteConfirmBox
          isOpen={showDeletePrompt}
          close={hideDeleteConfirmation}
          userId={userId}
          walletAddress={currentWallet}
        />
      )}
    </div>
  );
}

type DeleteProps = {
  isOpen: boolean;
  walletAddress: string;
  userId: string;
  close: VoidFunction;
};

function DeleteConfirmBox({
  isOpen,
  close,
  walletAddress,
  userId,
}: DeleteProps) {
  const { isLoading, isError, error, mutate } = useAdminWalletDelete();

  const onClick = () =>
    mutate(
      { userId, walletId: walletAddress },
      {
        onSuccess: close,
      },
    );

  return !isOpen ? null : (
    <ConfirmBox aria-live="assertive">
      <Stack gap="sm">
        <Text textAlign="center" bold style={{ lineHeight: 1.2 }}>
          Are you sure you want to delete wallet{" "}
          {formatWalletAddress(walletAddress)}?
        </Text>

        <FlexRow justifyContent="center" gap="10px" flexWrap="nowrap">
          <Button size="xxs" onClick={onClick} disabled={isLoading}>
            Delete
          </Button>

          <Button size="xxs" variant="secondary" onClick={close}>
            Cancel
          </Button>
        </FlexRow>

        {isError && <UnexpectedError error={error} />}
      </Stack>

      {isLoading && <LoadingSpinner />}
    </ConfirmBox>
  );
}

const StyledWallets = styled.ul({
  listStyleType: "none",
  margin: 0,
  padding: 0,
  position: "relative",
  li: {
    marginBottom: ".25rem",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    "&::marker": {
      overflow: "auto",
    },
  },
  "li::before": {
    content: '"\\200B"',
    position: "absolute",
  },
});

const StyledWallet = styled.div(({ theme }) => ({
  fontSize: theme.fontSizes.xxs,
  display: "flex",
  paddingBlock: 2,
  justifyContent: "space-between",
  "&:has(button:hover, button:focus-visible)": {
    backgroundColor: theme.colors.fg05,
  },
}));

const ConfirmBox = styled(Box)(({ theme }) => ({
  boxShadow: theme.boxShadow.dark,
  display: "grid",
  placeContent: "center",
  padding: "1rem",
  position: "absolute",
  margin: 0,
  left: 0,
  right: 0,
  top: 0,
  whiteSpace: "nowrap",
  zIndex: 20,
  maxWidth: 400,
  minHeight: "100%",
})).withComponent(FadeIn);

export { WalletList };
