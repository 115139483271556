import { useQuery } from "react-query";
import { api } from "utils/api";
import type { ProductTemplate } from "../types";

const getShopProductTemplates = () =>
  api.get<ProductTemplate[]>("/shop/templates");

const useShopProductTemplates = () =>
  useQuery(["shopBuilder", "templates"], getShopProductTemplates);

export { useShopProductTemplates };
