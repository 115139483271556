import * as React from "react";
import styled from "@emotion/styled";
import {
  useForm,
  useFieldArray,
  type Control,
  useController,
} from "react-hook-form";
import {
  AllCaps,
  Button,
  Callout,
  FadeIn,
  FieldError,
  FlexRow,
  H2,
  H3,
  Hr,
  Input,
  InputPre,
  Link,
  NoBreak,
  ScreenReaderText,
  Stack,
  Sup,
  Text,
  UnexpectedError,
} from "atoms";
import { paths } from "utils/paths";
import { GenericImage } from "features/images";
import { useArtist } from "features/reviewable";
import { constants } from "config";
import { useToggle } from "utils/hooks";
import {
  useUpdateProductVariants,
  type UpdateProductVariantsReq,
} from "../../api/useUpdateProductVariants";
import type { ProductUpdate, StepProps, TemplateVariant } from "../../types";
import { ProductWizardStep } from "../ProductWizardStep";

function StepPricing({ state, dispatch, moveBack }: StepProps) {
  const { isLoading, isError, error, mutate } = useUpdateProductVariants(
    state.product.id,
  );

  const { data: artist } = useArtist(state.artistId);

  const hugFee = artist?.shopHugFee ?? constants.defaultShopFee;
  // Gets readable, whole-number percentage of the artist's HUG fee
  const hugFeePretty = hugFee * 100;

  const [isInfoShowing, toggleIsInfoShowing] = useToggle(false);

  // Use the product's template data to create a map of each variant by variant id which we'll use
  // when rendering each row of the pricing table to display minimum and recommended prices.
  const variantMap = React.useMemo(
    () =>
      state.productTemplate.variants.reduce<Record<string, TemplateVariant>>(
        (acc, cur) => {
          acc[cur.id] = cur;
          return acc;
        },
        {},
      ),
    [state.productTemplate],
  );

  const {
    control,
    formState: { errors, isDirty },
    handleSubmit,
  } = useForm<UpdateProductVariantsReq>({
    defaultValues: {
      variants: state.product.variants.map((v) => ({
        ...variantMap[v.templateVariantId ?? ""],
        id: v.id,
        image: v.image ?? state.product.featuredImage,
        variantId: v.templateVariantId,
        priceInCents: Number(v.price) * 100,
        title:
          state.product.variants.length === 1 ? state.product.title : v.title,
        selectedOptions: v.selectedOptions,
      })),
    },
  });

  const { fields } = useFieldArray({ control, name: "variants" });

  const moveNext = () => dispatch({ type: "moveNext" });
  const onSubmit = handleSubmit((formData) => {
    if (isDirty) {
      mutate(formData, {
        onSuccess: moveNext,
      });
    } else {
      moveNext();
    }
  });

  return (
    <ProductWizardStep
      stepTitle="Pricing"
      state={state}
      moveBack={moveBack}
      moveBackDisabled={isDirty}
      nextStep={onSubmit}
      nextStepDisabled={isLoading}
      isForm
    >
      <Stack gap="md">
        <FlexRow
          justifyContent="space-between"
          alignItems="flex-start"
          itemsFlex="1"
        >
          <Stack>
            <Text size="xs" bold>
              As you set your pricing, the amount you make for the sale of each
              product will update automatically.
            </Text>
            <Text size="xs">
              To learn more about how pricing calculations are done, click the
              &lsquo;About Pricing&rsquo; button.
            </Text>
          </Stack>
          <Button
            variant="secondary"
            size="xs"
            style={{ flex: "0 0 auto" }}
            onClick={toggleIsInfoShowing}
            aria-label="toggle pricing information"
            aria-controls="#about-pricing"
          >
            About Pricing
          </Button>
        </FlexRow>

        {isInfoShowing && (
          <PricingInfo hugFee={hugFeePretty} close={toggleIsInfoShowing} />
        )}

        <div>
          {fields.map((field, index) => (
            <VariantRow
              key={field.id}
              {...field}
              index={index}
              error={errors.variants?.[index]?.priceInCents?.message}
              control={control}
              hugFee={hugFee}
              image={
                <GenericImage
                  src={field.image?.url}
                  {...priceItemImageDefaults}
                />
              }
            />
          ))}
        </div>

        {isError && <UnexpectedError error={error} />}
      </Stack>
    </ProductWizardStep>
  );
}

type PricingInfoProps = {
  isPrintOnDemand?: boolean;
  hugFee: number;
  close: VoidFunction;
};

function PricingInfo({ isPrintOnDemand, hugFee, close }: PricingInfoProps) {
  return (
    <FadeIn id="about-pricing" aria-live="assertive">
      <Callout>
        <Stack gap="sm">
          <FlexRow
            justifyContent="space-between"
            alignItems="center"
            flexWrap="nowrap"
          >
            <H2
              size="md"
              as="h3"
              textTransform="uppercase"
              textAlign="center"
              style={{ margin: 0 }}
            >
              About Pricing
            </H2>

            <Button onClick={close} size="xxs" variant="secondary">
              Close
            </Button>
          </FlexRow>
          <Stack>
            <Text size="xs">
              {isPrintOnDemand && (
                <span>
                  All Print on Demand products have a base price to cover
                  manufacturing costs paid to a third-party manufacturer.{" "}
                </span>
              )}
              <span>
                We encourage you to price your product in a way that allows you
                to make a profit that fairly reflects your time, effort, and
                craft.
              </span>
            </Text>
            <Text size="xs">
              In addition, <AllCaps>Hug</AllCaps> charges a {hugFee}%
              transaction fee on the sale of each item. The amount you make for
              the sale of each product is calculated as follows:
            </Text>
            <PriceEquation>
              <span>&nbsp;&nbsp;</span>
              <span>
                Product Price{" "}
                <em style={{ fontWeight: "normal", fontSize: ".85em" }}>
                  (what you set)
                </em>
              </span>
              <br />
              <span>&minus;&nbsp;Base Price</span> <br />
              <span>&minus;&nbsp;{hugFee}% of Product Price</span>
              <Hr />
              <strong>
                =&nbsp;The amount you&apos;ll make<Sup>*</Sup>
              </strong>
            </PriceEquation>
            <Text size="xxs">
              <em>
                * Note that the amount displayed does not include third-party
                payment processing fees.
              </em>
              <br />
              <NoBreak>
                <Link
                  to={`${paths.shopTerms}#pricing-and-payment`}
                  target="_blank"
                >
                  Learn more about our seller terms
                </Link>
                .
              </NoBreak>
            </Text>
          </Stack>
        </Stack>
      </Callout>
    </FadeIn>
  );
}

const PriceEquation = styled.pre(({ theme }) => ({
  fontSize: theme.fontSizes.xxs,
  maxWidth: "max-content",
  paddingBlock: 5,
  hr: {
    marginBlock: 5,
  },
}));
// Create a formatter to always show number as USD without the dollar sign
const formatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  minimumFractionDigits: 2,
  useGrouping: false,
});

const formatCents = (num: number) =>
  formatter
    .format(num / 100)
    .replace("$", "")
    .replace("NaN", "-");

const getArtistProfitCents = (
  value: number,
  hugFee: number,
  basePriceCents: number,
) => {
  const hugEarns = value * hugFee;
  const artistEarns = value - hugEarns - basePriceCents;
  return artistEarns;
};

type VariantRowProps<TControl extends Control<UpdateProductVariantsReq>> = Omit<
  ProductUpdate["variants"][number],
  "image"
> &
  Pick<
    TemplateVariant,
    "basePriceCents" | "minimumPriceCents" | "recommendedPriceCents"
  > & {
    error?: string;
    image?: React.ReactNode;
    index: number;
    control: TControl;
    title?: string;
    hugFee: number;
  };

function VariantRow<TControl extends Control<UpdateProductVariantsReq>>({
  control,
  error,
  image,
  index,
  priceInCents,
  title,
  basePriceCents,
  minimumPriceCents,
  recommendedPriceCents,
  hugFee,
}: VariantRowProps<TControl>) {
  // If basePriceCents is 0, then calculate and display using minimumPriceCents
  const hasBasePrice = !!basePriceCents;
  const minValue = hasBasePrice ? basePriceCents : minimumPriceCents;

  const {
    field: { onBlur, onChange, ref },
  } = useController({
    control,
    name: `variants.${index}.priceInCents`,
    rules: {
      min: {
        value: minValue,
        message: `Please enter a price of at least $${formatCents(minValue)}`,
      },
      validate: (value) => !Number.isNaN(value) || "This value is not a number",
    },
  });

  const [profitCents, setProfitCents] = React.useState(
    getArtistProfitCents(priceInCents, hugFee, basePriceCents),
  );

  const parseOnChange: React.ChangeEventHandler<HTMLInputElement> = ({
    currentTarget,
  }) => {
    // Convert input string to number in cents, but in case of empty string, substitute with our own
    // invalid value instead because we want empty value to get caught by validation instead of
    // being okay with a zero. `Number("") === 0`
    const value =
      Number(currentTarget.value === "" ? "NaN" : currentTarget.value) * 100;
    // Update form hook with new value in cents, let validation trigger if value is NaN
    onChange(value);
    // Update profit value in cents, formatter will handle if value is NaN
    setProfitCents(getArtistProfitCents(value, hugFee, basePriceCents));
  };

  const onKeyDown: React.KeyboardEventHandler<HTMLInputElement> = (event) => {
    // If the user is trying to enter a single character that isn't a digit or a decimal point, and
    // they aren't holding a modifier key (e.g. `CMD-a` to select all, `CTRL-p` to paste a value),
    // then prevent the event. This effectively turns the text input into a less aggressively
    // limited number input to be as accessible as possible. Form validation should handle anything
    // else that sneaks through this filter.
    if (/^[^\d.]$/.test(event.key) && !(event.ctrlKey || event.metaKey)) {
      event.preventDefault();
    }
  };

  return (
    <PricingRow>
      <Stack>
        <H3 size="sm" textTransform="none">
          {title === "Default Title" || title === undefined
            ? "Product Price"
            : title}
        </H3>

        <PriceItemGrid>
          {image}

          <PriceRowMain>
            <MinPrice>
              {`${hasBasePrice ? "Base" : "Minimum"} price: $${formatCents(minValue)}`}
            </MinPrice>

            <FlexRow gap="0" title={error} as="label">
              <ScreenReaderText>Price adjustment</ScreenReaderText>

              <InputPre style={{ display: "flex", alignItems: "center" }}>
                $
              </InputPre>

              <PriceInput
                autoFocus={index === 0}
                defaultValue={formatCents(priceInCents)}
                isInvalid={!!error}
                inputMode="decimal"
                placeholder={formatCents(recommendedPriceCents)}
                onBlur={onBlur}
                onChange={parseOnChange}
                onKeyDown={onKeyDown}
                ref={ref}
              />
            </FlexRow>

            {!!error?.length && <FieldError>{error}</FieldError>}

            <YoullMake>
              You&apos;ll Make:
              {profitCents >= 0 ? (
                <span>{formatCents(profitCents)}</span>
              ) : (
                <span>$0.00</span>
              )}
            </YoullMake>
          </PriceRowMain>
        </PriceItemGrid>
      </Stack>
    </PricingRow>
  );
}

StepPricing.displayName = "Pricing";

const PricingRow = styled.div(({ theme }) => ({
  "&+&": {
    borderTop: "1px solid",
    borderTopColor: theme.colors.fg30,
    marginTop: theme.spacing.lg,
    paddingTop: theme.spacing.lg,
  },
}));

const PriceItemGrid = styled.div(({ theme }) => ({
  display: "grid",
  flexWrap: "nowrap",
  alignItems: "start",
  gridTemplateColumns: "90px 1fr",
  gap: "1rem",
  [theme.breakpoints.tablet]: {
    gridTemplateColumns: "140px 1fr",
  },
}));

const PriceRowMain = styled.div({
  flex: 1,
  maxWidth: 375,
});

const priceItemImageDefaults = {
  sizes: "140px",
  width: "140px",
  height: "140px",
};

// type PriceItemImageProps = {
//   image: Image | ShopifyImageType | React.ReactNode;
// };

// function PriceItemImage({ image }: PriceItemImageProps) {
//   if (image === null) {
//     return null;
//   }

//   let child: React.ReactNode;
//   if (isShopifyImage(image)) {
//     child = <ShopifyImage data={image} {...priceItemImageDefaults} />;
//   } else if (isHugImage(image)) {
//     child = <GenericImage src={image} {...priceItemImageDefaults} />;
//   } else {
//     child = image;
//   }

//   return <ItemImageWrapper>{child}</ItemImageWrapper>;
// }

// const ItemImageWrapper = styled.div({
//   alignSelf: "top",
//   img: {
//     aspectRatio: "auto",
//     display: "block",
//   },
// });

const MinPrice = styled(Text)({
  fontSize: 13,
  fontStyle: "italic",
  fontVariantNumeric: "tabular-nums",
  lineHeight: 1.2,
  margin: 0,
});

const PriceInput = styled(Input)({
  fontVariantNumeric: "tabular-nums",
  paddingInline: 10,
});

const YoullMake = styled(Text)(({ theme }) => ({
  display: "flex",
  flexWrap: "wrap",
  gap: "0 10px",
  justifyContent: "space-between",
  alignItems: "center",
  backgroundColor: theme.colors.successL,
  borderRadius: 2,
  color: theme.colors.fg,
  fontSize: 14,
  fontVariantNumeric: "tabular-nums",
  fontWeight: "bold",
  margin: 0,
  marginTop: 10,
  padding: "3px 5px 1px",
  span: {
    whiteSpace: "nowrap",
  },
  small: {
    fontSize: 12,
    fontStyle: "italic",
    color: theme.colors.bodyColor,
    fontWeight: "normal",
    whiteSpace: "nowrap",
  },
}));

export { StepPricing, VariantRow, PricingInfo };
