import styled from "@emotion/styled";
import { H2, Table } from "atoms";
import { formatDate } from "utils/date";
import { RewardLedgerResponse } from "../types";

type Props = {
  rewards: RewardLedgerResponse;
};

function ProfileAdminRewards({ rewards }: Props) {
  return (
    <>
      <SH2 size="sm" id="rewards-ledger">
        Rewards Ledger
      </SH2>
      <STable scroll>
        <thead>
          <tr>
            <th>Timestamp</th>
            <th>Status</th>
            <th>Reason</th>
            <th>Earned</th>
            <th>Spent</th>
            <th>New Points Balance</th>
            <th>New Lifetime Earnings</th>
          </tr>
        </thead>
        <tbody>
          {rewards?.data?.map((reward) => (
            <tr key={reward.createdAt}>
              <td>{formatDate(reward.createdAt)}</td>
              <td>{reward.status?.toLowerCase()}</td>
              <td>{reward.reasonComments || reward.reasonCode}</td>
              <td>{reward.earned}</td>
              <td>{reward.spent}</td>
              <td>{reward.newPointsBalance}</td>
              <td>{reward.newLifetimeEarnings}</td>
            </tr>
          ))}
        </tbody>
      </STable>
    </>
  );
}

const STable = styled(Table)(({ theme }) => ({
  marginBottom: "5rem",
  "& th,& td": {
    borderColor: theme.colors.fg20,
    fontSize: theme.fontSizes.xxs,
    maxWidth: "max-content",
    padding: 7,
    "@media (max-width: 830px)": {
      minWidth: "calc(7em + 5vw)",
    },
  },
  "&::after": {
    backgroundImage: "linear-gradient(90deg, rgb(0 0 0 / 0) 0%, white 95%)",
    bottom: "1rem",
  },
}));

const SH2 = styled(H2)(({ theme }) => ({
  ...theme.fonts.body,
  fontWeight: "bold",
  marginBottom: 0,
  marginTop: "0 !important",
  borderBottom: "1px solid rgba(0,0,0,.2)",
  paddingBottom: ".5rem",
}));

export { ProfileAdminRewards };
