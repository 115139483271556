import styled from "@emotion/styled";
import { useCart } from "@shopify/hydrogen-react";
import { IconCart, Link } from "atoms";
import { useCartNotifications } from "contexts/shop";
import { paths } from "utils/paths";
import { pluralize } from "utils/text";
import { ItemAddedToCart } from "./ItemAddedToCart";

function CartButton() {
  const { totalQuantity = 0 } = useCart();
  const { isOpen, close } = useCartNotifications();

  return (
    <>
      <CartButtonIcon
        to={paths.shopCart}
        aria-label={`View cart containing ${pluralize(totalQuantity, "item")}`}
      >
        <IconCart aria-hidden />
        {!!totalQuantity && (
          <NotificationCount aria-hidden>{totalQuantity}</NotificationCount>
        )}
      </CartButtonIcon>

      {isOpen && <ItemAddedToCart close={close} />}
    </>
  );
}

const CartButtonIcon = styled(Link)(({ theme }) => ({
  ...theme.fonts.display,
  appearance: "none",
  backgroundColor: theme.colors.black,
  border: 0,
  borderRadius: theme.borderRadius.round,
  display: "grid",
  placeItems: "center",
  fontWeight: "400",
  minWidth: 36,
  padding: 0,
  svg: {
    color: `${theme.colors.bg} !important`,
  },
  [theme.breakpoints.tablet]: {
    alignItems: "center",
    padding: 0,
  },
  position: "relative",
  width: 36,
  height: 36,
  flex: "0 0 36px",
  fontSize: 18,
  ":hover &, :focus &, :focus-visible &, :active &": {
    backgroundColor: theme.colors.fg,
    color: theme.colors.bg,
  },
}));

const NotificationCount = styled.span(({ theme }) => ({
  ...theme.fonts.body,
  alignItems: "center",
  backgroundColor: theme.colors.accent3L,
  borderRadius: theme.borderRadius.round,
  display: "flex",
  letterSpacing: "-.02em",
  textAlign: "center",
  fontSize: 12,
  fontWeight: "bold",
  height: 20,
  justifyContent: "center",
  minWidth: 20,
  padding: "1px 2px 0px 3px",
  maxWidth: "max-content",
  color: "black",
  position: "absolute",
  top: 0,
  right: 0,
  transform: "translate(33%, -40%)",
}));

export { CartButton };
