import { useForm } from "react-hook-form";
import { MutateOptions } from "react-query";
import { GalleryItem, GalleryItemFormFields } from "../types";
import { useGalleryItemCreate } from "./useGalleryItemCreate";
import { useGalleryItemUpdate } from "./useGalleryItemUpdate";

type UseGalleryFormParams = {
  artistId: string;
  galleryItem?: GalleryItem;
  defaultValues?: Partial<GalleryItem>;
  // Optional event handlers from component using the form
} & MutateOptions<GalleryItem, unknown, GalleryItemFormFields>;

/**
 * Custom hook to handle form validation and submission for creating/updating a gallery item
 */
const useGalleryItemForm = ({
  artistId,
  galleryItem,
  defaultValues,
  onSuccess,
}: UseGalleryFormParams) => {
  const { handleSubmit, ...form } = useForm<GalleryItemFormFields>({
    // Fill fields with original post data if we're editing, otherwise set some base defaults for a new post
    defaultValues: galleryItem ?? {
      status: "PUBLISHED",
      tags: [],
      galleryFolderIds: [],
      pinnedItemIds: [],
      ...defaultValues,
    },
  });

  // Run both create/edit hooks to create mutate functions and state management
  const createMutation = useGalleryItemCreate(artistId);
  const updateMutation = useGalleryItemUpdate(artistId, galleryItem?.id);

  // If we've been given an existing post, use update mutation. Otherwise, use create mutation.
  const isEdit = !!galleryItem;
  const mutation = isEdit ? updateMutation : createMutation;

  const onSubmit = handleSubmit((formData) => {
    // If we're using an update mutation, roll form fields into existing post data
    mutation.mutate(isEdit ? { ...galleryItem, ...formData } : formData, {
      onSuccess,
    });
  });

  return {
    ...form,
    isEdit,
    mutation,
    onSubmit,
  };
};

export { useGalleryItemForm };
