import { UseFormProps, useForm } from "react-hook-form";
import { useMutation } from "react-query";
import { api } from "utils/api";
import type {
  ForgotPasswordFormFields,
  ForgotPasswordFormResponse,
} from "../types";

const postForgotPassword = (fields: ForgotPasswordFormFields) =>
  api.post<ForgotPasswordFormResponse>("/auth/email/credentials/reset", {
    body: JSON.stringify(fields),
  });

const useForgotPasswordForm = (
  formOptions?: UseFormProps<ForgotPasswordFormFields>,
) => {
  const form = useForm<ForgotPasswordFormFields>(formOptions);

  const mutation = useMutation(postForgotPassword);

  return { ...form, mutation };
};

export { useForgotPasswordForm };
