import styled from "@emotion/styled";

function ItemCountCircle({ count, ...props }: { count: number }) {
  return (
    <ItemCountElement noItems={count === 0} {...props}>
      {count}
    </ItemCountElement>
  );
}
const ItemCountElement = styled.div<{ noItems?: boolean }>(
  ({ noItems, theme }) => ({
    ...theme.fonts.display,
    borderRadius: theme.borderRadius.round,
    backgroundColor: noItems ? theme.colors.fg10 : theme.colors.accent1LL,
    color: theme.colors.fg,
    display: "grid",
    fontSize: "2em",
    height: "2em",
    placeItems: "center",
    width: "2em",
  }),
);

export { ItemCountCircle };
