import styled from "@emotion/styled";

/* Used AllCaps when a word should appear uppercase. */

const AllCaps = styled.span({
  "&&": {
    textTransform: "uppercase",
    fontWeight: "inherit",
    fontSize: "inherit",
  },
});

export { AllCaps };
