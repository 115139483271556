import { Navigate, useLocation } from "react-router-dom";
import { LoadingSpinner } from "atoms";
import { useAuth } from "./AuthProvider";
import { NotAuthorized } from "./NotAuthorized";
import type { AuthProps, PlatformRoles } from "../types";

type RedirectToSignInProps = {
  createAccount?: boolean;
};

/**
 * Declaratively redirect user to SignIn with location state to redirect back on completion
 */
function RedirectToSignIn({ createAccount }: RedirectToSignInProps) {
  const location = useLocation();

  return (
    <Navigate
      to={createAccount ? "/register" : "/sign-in"}
      state={{ from: location }}
    />
  );
}

type RequireAuthenticationOptions = RedirectToSignInProps;

/**
 * HOC to inject logged in user to Component via props
 *
 * If the user is not logged in, it redirects them to SignIn component and returns once successful
 *
 * @example
 * ```
 * function Profile({ user, ...otherProps }) {
 *   console.log("guaranteed user", user);
 *   return ( ... );
 * }
 *
 * export default requireAuthentication(Profile);
 * ```
 */
const requireAuthentication = <TProps extends AuthProps>(
  Component: React.ComponentType<TProps>,
  opts: RequireAuthenticationOptions = {
    createAccount: false,
  },
) => {
  // Omit AuthProps from required props of wrapped component because they will be injected automatically
  function RequireAuthentication(props: Omit<TProps, keyof AuthProps>) {
    const { user, roles, isLoading } = useAuth();

    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (!user) {
      return <RedirectToSignIn createAccount={opts.createAccount} />;
    }

    const authProps: AuthProps = { user, roles };

    return <Component {...(props as TProps)} {...authProps} />;
  }

  return RequireAuthentication;
};

type RequireAuthorizationOptions = RequireAuthenticationOptions &
  (
    | {
        roles: (keyof Pick<
          PlatformRoles,
          | "isHugger"
          | "isCollector"
          | "isCurator"
          | "isSrCurator"
          | "isQA"
          | "isAdmin"
          | "isSuperAdmin"
        >)[];
      }
    | {
        feature: keyof Pick<
          PlatformRoles,
          | "canPost"
          | "canReview"
          | "canModerate"
          | "canCurate"
          | "showQAFeatures"
          | "showAdminFeatures"
        >;
      }
  );

/**
 * HOC to inject logged in AND authorized user to Component via props
 *
 * If the user is not logged in, it redirects them to SignIn component and returns once successful
 * If the user is not authorized, it redirects them safely to the Home page
 *
 * Options allow gating by feature:
 * ```
 * requireAuthorization(EditReviewForm, { feature: "canReview" })
 * ```
 *
 * Or gating by roles:
 * ```
 * requireAuthorization(QATickets, { roles: ["isQA", "isAdmin", "isSuperAdmin"] })
 * ```
 */
const requireAuthorization = <TProps extends AuthProps>(
  Component: React.ComponentType<TProps>,
  opts: RequireAuthorizationOptions,
) => {
  // Omit AuthProps from required props of wrapped component because they will be injected automatically
  function RequireAuthorization(props: Omit<TProps, keyof AuthProps>) {
    const { user, roles, isLoading } = useAuth();

    if (isLoading) {
      return <LoadingSpinner />;
    }

    if (!user) {
      return <RedirectToSignIn createAccount={opts.createAccount} />;
    }

    // If feature was provided, check for access in user's platform roles
    const canAccessFeature = "feature" in opts && roles[opts.feature];
    // If array of roles was provided, make sure user is at least one of them
    const hasHighEnoughRole =
      "roles" in opts && opts.roles.some((r) => roles[r]);

    if (canAccessFeature || hasHighEnoughRole) {
      const authProps: AuthProps = { user, roles };

      return <Component {...(props as TProps)} {...authProps} />;
    }

    // TODO: allow option to determine where user ends up if not authorized
    // return <Navigate to="/" replace />;
    return <NotAuthorized />;
  }

  return RequireAuthorization;
};

export { requireAuthentication, requireAuthorization };
