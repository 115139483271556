import * as React from "react";
import { useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import styled from "@emotion/styled";
import {
  FieldError,
  FlexRow,
  H2,
  IconError,
  IconOk,
  IconWarningSignAlt,
  RequiredText,
  Stack,
  Text,
} from "atoms";
import { getDimensionsFromUrl } from "features/images";

import black from "assets/images/frame-black.jpg";
import white from "assets/images/frame-white.jpg";
import natural from "assets/images/frame-natural.jpg";
import none from "assets/images/frame-none.jpg";

import {
  useUpdateProductOptions,
  type UpdateProductOptionsReq,
} from "../../api/useUpdateProductOptions";
import type { StepProps } from "../../types";
import { ProductWizardStep } from "../ProductWizardStep";
import { ArtPrintPreview } from "../ArtPrintPreview";
import { StepInput } from "../WizardStepOption";

type FrameMap = {
  [key: string]: string;
};
const frameMap: FrameMap = {
  "No Frame": none,
  Black: black,
  White: white,
  Natural: natural,
};

function StepArtPrintOptions({ dispatch, state, moveBack }: StepProps) {
  const { isLoading, mutate } = useUpdateProductOptions(state.product.id);

  // Brand new products will get this param - When present don't select
  // any of the variants so the user has to choose.
  const [searchParams, setSearchParams] = useSearchParams();
  const newProduct = searchParams.get("new");

  const {
    register,
    formState: { errors, isDirty },
    getValues,
    handleSubmit,
  } = useForm<UpdateProductOptionsReq>({
    defaultValues: {
      // New Product - Don't Select Options by Default
      productOptions: newProduct
        ? state.product.options.map((o) => ({ ...o, values: [] }))
        : state.product.options,
    },
  });

  // Remove the search param - It has already impacted defaultValues above
  // by this time. Worst case is a page-refresh loses the param and we have
  // a default selection
  React.useEffect(() => {
    if (newProduct) {
      searchParams.delete("new");
      setSearchParams(searchParams);
    }
  }, [newProduct, searchParams, setSearchParams]);

  const [assetWidth, setAssetWidth] = React.useState(0);
  const [assetHeight, setAssetHeight] = React.useState(0);

  React.useEffect(() => {
    if (state.product.assetUrl) {
      getDimensionsFromUrl(state.product.assetUrl).then(({ height, width }) => {
        setAssetWidth(width);
        setAssetHeight(height);
      });
    }
  }, [state.product.assetUrl, assetWidth, assetHeight]);

  const onSubmit = handleSubmit(({ id, productOptions }) => {
    if (isDirty) {
      mutate(
        { id, productOptions },
        {
          onSuccess: ({ product }) => {
            dispatch({ type: "updateProduct", moveNext: true, product });
          },
        },
      );
    } else {
      dispatch({ type: "moveNext" });
    }
  });

  const createValidator = (index: number) => () => {
    const vals = getValues("productOptions")[index]?.values ?? [];
    return vals.length >= 1;
  };

  const previewImage =
    state.product.imageData?.image?.url300 ??
    state.product.imageData?.image?.url ??
    state.product.assetUrl;

  return (
    <ProductWizardStep
      stepTitle="Product Options"
      state={state}
      moveBack={moveBack}
      nextStep={onSubmit}
      nextStepDisabled={isLoading}
    >
      <Stack gap="lg">
        {state.productTemplate.options.map(
          ({ key: option, values }, optIndex) => {
            const GridComponent = option === "Size" ? Grid3x : Grid4x;
            return (
              <Stack key={option}>
                <Stack>
                  <H2 size="md" textTransform="none">
                    {option}
                    <RequiredText />
                  </H2>
                  {option === "Size" && (
                    <Text size="xs">
                      Select all the print sizes you would like to offer. The
                      same artwork can be made available as prints in different
                      dimensions, as displayed in the mockups. You must select
                      at least one size.
                    </Text>
                  )}
                  {option === "Frame Color" && (
                    <Text size="xs">
                      Select all the frame choices you would like to offer. You
                      must select at least one frame option.
                    </Text>
                  )}
                </Stack>
                <GridComponent>
                  {option === "Size" &&
                    values.map((value) => {
                      const [printWidth, printHeight] = value
                        .split("x")
                        .map((n) => Number(n));

                      const dpiX = Math.floor(assetWidth / printWidth);
                      const dpiY = Math.floor(assetHeight / printHeight);
                      const dpi = Math.min(dpiX, dpiY);

                      const quality = getQuality(dpi);
                      const qualityMessage =
                        dpi > 0 ? (
                          <FlexRow gap="5px" flexWrap="nowrap">
                            <div style={{ color: quality.color }}>
                              {quality.icon}
                            </div>
                            <DpiText>
                              <span>{quality.text}</span> image quality:{" "}
                              <span>{dpi > 299 ? "300" : dpi}</span> dpi
                            </DpiText>
                          </FlexRow>
                        ) : undefined;

                      return (
                        <StepInput
                          value={value}
                          key={`${option}-${value}`}
                          label={value}
                          description={qualityMessage}
                          inputType="checkbox"
                          image={
                            <SArtPrintPreview src={previewImage} size={value} />
                          }
                          {...register(`productOptions.${optIndex}.values`, {
                            validate: createValidator(optIndex),
                          })}
                        />
                      );
                    })}

                  {option === "Frame Color" &&
                    values.map((value) => {
                      const image = frameMap[value];
                      return (
                        <StepInput
                          value={value}
                          key={`${option}-${value}`}
                          label={value}
                          inputType="checkbox"
                          image={image}
                          {...register(`productOptions.${optIndex}.values`, {
                            validate: createValidator(optIndex),
                          })}
                        />
                      );
                    })}
                </GridComponent>
                {errors.productOptions?.[optIndex] && (
                  <FieldError>
                    You need to select at least one value for this option.
                  </FieldError>
                )}
              </Stack>
            );
          },
        )}
      </Stack>
    </ProductWizardStep>
  );
}

const Grid3x = styled.div(({ theme }) => ({
  display: "grid",
  marginInline: "auto",
  gap: theme.spacing.gap,
  [theme.breakpoints.xs]: {
    gridTemplateColumns: "repeat(2, 1fr)",
  },
  "@media (min-width: 660px)": {
    gridTemplateColumns: "repeat(3, 1fr)",
  },
}));
const Grid4x = styled.div(({ theme }) => ({
  display: "grid",
  marginInline: "auto",
  gap: theme.spacing.gap,
  gridTemplateColumns: "repeat(2, 1fr)",
  [theme.breakpoints.desktop]: {
    gridTemplateColumns: "repeat(4, 1fr)",
  },
}));
const SArtPrintPreview = styled(ArtPrintPreview)(({ theme }) => ({
  width: "100%",
  height: "100%",
  objectFit: "contain",
  objectPosition: "center",
  padding: 10,
  div: {
    objectFit: "contain",
    width: "100%",
    height: "100%",
  },
  img: {
    height: "100%",
    border: "1px solid",
    borderColor: theme.colors.fg20,
    borderRadius: 2,
  },
}));

const getQuality = (dpi: number) => {
  if (dpi >= 0 && dpi < 225) {
    return {
      text: "Poor",
      color: "#D3462E",
      icon: <IconError size={18} />,
    };
  }
  if (dpi >= 225 && dpi < 280) {
    return {
      text: "Average",
      color: "#d67d00",
      icon: <IconWarningSignAlt size={18} />,
    };
  }
  if (dpi >= 280) {
    return {
      text: "Good",
      color: "#34b146",
      icon: <IconOk size={18} />,
    };
  }
  return { text: undefined, color: undefined };
};

const DpiText = styled.div(({ theme }) => ({
  color: theme.colors.fg,
  flex: 1,
  fontSize: 13,
  lineHeight: 1.1,
  textAlign: "left",
}));

StepArtPrintOptions.displayName = "Options";

export { StepArtPrintOptions, DpiText };
