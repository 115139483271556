import styled from "@emotion/styled";
import { FadeIn } from "./FadeIn";

type DropdownProps = {
  children: React.ReactNode;
  isOpen: boolean;
  hasArrow?: boolean;
  id?: string;
};

function DropdownMenu({
  children,
  isOpen,
  hasArrow = true,
  id,
  ...props
}: DropdownProps) {
  return (
    <DropdownWrapper>
      <DropdownElement
        isOpen={isOpen}
        className="DropdownMenu"
        aria-live={isOpen ? "polite" : "off"}
        id={id ?? ""}
        {...props}
      >
        <DropdownMenuBody>{children}</DropdownMenuBody>
      </DropdownElement>
      {hasArrow && (
        <Arrow isOpen={isOpen} className="DropdownMenuArrow">
          <svg
            width="12"
            height="7"
            viewBox="0 0 12 7"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path d="M0 7L6 1L12 7H0Z" fill="white" />
            <path
              d="M1 6L6 1L11 6"
              stroke="#B4B4B4"
              strokeWidth=".4px"
              strokeLinecap="round"
            />
          </svg>
        </Arrow>
      )}
    </DropdownWrapper>
  );
}

const DropdownWrapper = styled.div({});

const DropdownElement = styled(FadeIn)<{
  isOpen: boolean;
  allowScroll?: boolean;
}>(({ isOpen, theme }) => ({
  overflow: "auto",
  maxHeight: "calc(100vh - 55px)",
  display: isOpen ? "block" : "none",
  background: theme.colors.bg,
  borderRadius: theme.borderRadius.sm,
  boxShadow: theme.boxShadow.dark,
  padding: "1rem",
  position: "absolute",
  right: 0,
  textAlign: "left",
  top: "calc(100% + 11px)",
  width: "max-content",
  minWidth: "100%",
  zIndex: 100,
  [theme.breakpoints.tablet]: {
    maxHeight: "calc(100vh - 6rem)",
  },
}));

const DropdownMenuBody = styled.div(({ theme }) => ({
  "&,ul": {
    display: "flex",
    flexDirection: "column",
  },
  li: {
    backgroundColor: "rgb(0 0 0 / 0)",
    padding: 0,
    borderRadius: 0,
    margin: 0,
    textAlign: "left",
    display: "inline-block",
    textDecoration: "none",
    lineHeight: 1.2,
    'a, button, & [role="button"], & clipboard-copy': {
      ...theme.fonts.display,
      borderBottom: `2px solid ${theme.colors.transparent}`,
      color: theme.colors.fg,
      transition: "none",
      fontSize: theme.fontSizes.sm,
      textDecoration: "none",
      fontWeight: "normal",
      display: "inline-flex",
      cursor: "pointer",
      gap: 4,
      "&:hover, &:focus-visible": {
        color: theme.colors.fg,
        textDecoration: "none",
        borderBottom: "2px solid currentColor",
      },
    },
  },
}));

const Dropdown = styled.div({
  alignItems: "center",
  alignSelf: "stretch",
  display: "inline-flex",
  position: "relative",
  textAlign: "left",
});

const Arrow = styled(FadeIn)<{ isOpen: boolean }>(({ isOpen }) => ({
  // display: "none",
  // [theme.breakpoints.tablet]: {
  pointerEvents: "none",
  display: isOpen ? "block" : "none",
  zIndex: 101,
  position: "absolute",
  transform: "translate(-50%, 4px)",
  opacity: 1,
  top: "100%",
  left: "50%",
  svg: {
    display: "block",
    zIndex: 101,
    height: 8,
    width: 15,
  },
  // },
}));

export { DropdownMenu, Dropdown };
