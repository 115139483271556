import { useInfiniteQuery } from "react-query";
import { api } from "utils/api";
import {
  OwnedContractTokenResponse,
  OwnedContractTokenRequest,
} from "../types";
import { ownedTokenKeys } from "./queryKeys";

/**
 * Get a tokens for an owned contract
 */
const getOwnedContractTokens = ({
  walletId,
  contractAddress,
  searchAfterTokenId,
  chainId,
}: OwnedContractTokenRequest) => {
  if (!walletId) {
    return Promise.reject(new Error("Invalid wallet id"));
  }
  if (!contractAddress) {
    return Promise.reject(new Error("Invalid contract address"));
  }

  const params = new URLSearchParams();
  if (searchAfterTokenId) {
    params.set("searchAfterTokenId", searchAfterTokenId);
  }
  if (chainId) {
    params.set("chainId", chainId.toString());
  }
  params.set("pageSize", "14");
  const queryStr = params.toString() ? `?${params}` : "";
  return api.get<OwnedContractTokenResponse>(
    `/wallet/contracts/tokens/${walletId}/${contractAddress}${queryStr}`,
  );
};

const useGetOwnedContractTokens = (params: OwnedContractTokenRequest) =>
  useInfiniteQuery(
    ownedTokenKeys.infinite(params),
    ({ pageParam }) =>
      getOwnedContractTokens({ ...params, searchAfterTokenId: pageParam }),
    {
      getNextPageParam: (lastPage) => lastPage.searchAfterTokenId,
      enabled: !!params.walletId && !!params.contractAddress,
    },
  );

export { useGetOwnedContractTokens };
