import styled from "@emotion/styled";

const Legend = styled.legend<{ size?: "sm" }>(({ size, theme }) => ({
  ...theme.fonts.body,
  display: "block",
  fontSize: size === "sm" ? ".875rem" : theme.fontSizes.sm,
  fontWeight: "700",
  padding: 0,
  "& > small": {
    color: theme.colors.fg80,
    fontWeight: "normal",
    fontSize: theme.fontSizes.xxs,
    fontStyle: "italic",
    lineHeight: 1.3,
    marginTop: 2,
  },
}));

export { Legend };
