import { useTheme } from "@emotion/react";
import styled from "@emotion/styled";
import brush1 from "assets/images/hug-brush-1.svg";
import brush2 from "assets/images/hug-brush-2.svg";
import brush3 from "assets/images/hug-brush-3.svg";
import brush4 from "assets/images/hug-brush-4.svg";
import brush5 from "assets/images/hug-brush-5.svg";
import { isColorLight } from "utils/isColorLight";

type BrushWrapperProps = {
  bg?: string;
  brush?: "1" | "2" | "3" | "4" | "none";
  children?: React.ReactNode;
};

function BrushWrapper({
  bg = "black",
  brush,
  children,
  ...props
}: BrushWrapperProps) {
  const theme = useTheme();
  const bgColor = theme.colors[bg];
  let textColor = "";

  // if (bg && isColorLight(bgColor) === true) {
  //   textColor = "dark";
  // }

  if (bg && isColorLight(bgColor) === false) {
    textColor = "light";
  }

  return (
    <BrushWrapperDiv brush={brush} bg={bg} textColor={textColor} {...props}>
      {children}
    </BrushWrapperDiv>
  );
}

const BrushWrapperDiv = styled.div<{
  bg?: string;
  textColor?: "light" | "dark" | string;
  brush?: string;
}>(({ bg = "bg05", textColor = "", brush, theme }) => ({
  backgroundColor: theme.colors[bg],
  ...(textColor === "light" && {
    color: theme.colors.white,
    "h1,h2,h3,h4,h5,h6,p a": {
      color: "inherit",
    },
    "p a:hover,p a:focus": {
      opacity: 0.8,
    },
  }),
  ...(textColor === "dark" && {
    color: theme.colors.black,
  }),
  position: "relative",
  zIndex: 1,
  "&::before": {
    content: brush ? "''" : "none",
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    zIndex: 0,
    pointerEvents: "none",
    backgroundSize: "cover",
    backgroundPosition: "center",
    backgroundRepeat: "no-repeat",
    opacity: textColor === "light" ? 0.175 : 0.075,
    ...(brush === "1" && {
      backgroundImage: `url("${brush1}")`,
      backgroundSize: "125vmax",
    }),
    ...(brush === "2" && {
      backgroundImage: `url("${brush2}")`,
    }),
    ...(brush === "3" && {
      backgroundImage: `url("${brush3}")`,
      backgroundPosition: "top",
    }),
    ...(brush === "4" && {
      backgroundImage: `url("${brush4}")`,
    }),
    ...(brush === "5" && {
      backgroundImage: `url("${brush5}")`,
    }),
  },
  "&>.Container": {
    position: "relative",
    zIndex: 2,
  },
}));

export { BrushWrapper, type BrushWrapperProps };
