import { MutateOptions, useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import type { GalleryItemFormFields, GalleryItem } from "../types";
import { galleryKeys } from "./queryKeys";
import { rootGalleryId } from "../utils/rootGallery";
import { useGalleryItems } from "./useGalleryItems";
import { useGalleryItem } from "./useGalleryItem";

type UpdateParams = GalleryItemFormFields & {
  artistId?: string;
  id?: string;
};

/**
 * API to update a portfolio item
 */
const updateGalleryItem = ({ artistId, id, ...formData }: UpdateParams) =>
  api.put<GalleryItem>(`/gallery/artists/${artistId}/${id}`, {
    body: JSON.stringify(formData),
  });

/**
 * Custom hook to update a portfolio item
 */
const useGalleryItemUpdate = (artistId?: string, id?: string) => {
  const queryClient = useQueryClient();
  return useMutation(
    ({ itemIds, ...formData }: GalleryItemFormFields) =>
      updateGalleryItem({ artistId, id, itemIds: itemIds ?? [], ...formData }),
    {
      onSuccess: (resp) => {
        // Invalidate galleries in case an item was removed from within
        queryClient.invalidateQueries(
          galleryKeys.detailOwner("artist", artistId),
        );
        queryClient.invalidateQueries(galleryKeys.lists());

        // Update the cache with new data
        queryClient.setQueryData(
          galleryKeys.detail("artist", artistId, id),
          resp,
        );
      },
    },
  );
};

type MutationProps = GalleryItemFormFields & { sortedItems: GalleryItem[] };

/*
 * Hook to support reordering of gallery items
 */
const useGalleryItemOrder = (
  artistId?: string,
  galleryFolderId?: string,
  {
    onError = noop,
    onSettled = noop,
    onSuccess = noop,
  }: MutateOptions<GalleryItem, unknown, UpdateParams> = {},
) => {
  const queryClient = useQueryClient();

  // Root vs Specific Folder
  const galleryId = galleryFolderId ?? rootGalleryId(artistId ?? "");

  // Query Keys for the Folder & Item List
  const folderQueryKey = galleryKeys.detail("artist", artistId, galleryId);
  const itemsQueryKey = galleryKeys.items({ galleryId });

  // Load the Folder
  const { data: folder, ...folderQuery } = useGalleryItem({
    artistId,
    itemId: galleryId,
  });

  // Load All Items for that Folder
  const { data: items, ...itemsQuery } = useGalleryItems({
    galleryId,
  });

  // Mutation to Update Order
  const { mutate, ...mutation } = useMutation(
    ({ sortedItems: _, ...formData }: MutationProps) =>
      updateGalleryItem({ ...formData, artistId }),
    {
      onMutate: async ({ sortedItems, ...newFolder }) => {
        // Cancel any outgoing refetches (so they don't overwrite our optimistic update)
        await queryClient.cancelQueries(folderQueryKey);
        await queryClient.cancelQueries(itemsQueryKey);

        // Snapshot the previous value
        const previousFolder =
          queryClient.getQueryData<GalleryItem>(folderQueryKey);
        const previousItems =
          queryClient.getQueryData<GalleryItem[]>(itemsQueryKey);

        // Optimistically update to the new value
        queryClient.setQueryData(folderQueryKey, newFolder);
        queryClient.setQueryData(itemsQueryKey, sortedItems);

        // Return a context with previous data
        return { previousFolder, previousItems };
      },
      onError: (error, variables, context) => {
        // Set state back to previous items on failure
        queryClient.setQueryData(folderQueryKey, context?.previousFolder);
        queryClient.setQueryData(itemsQueryKey, context?.previousItems);
        onError(error, variables, context);
      },
      onSuccess,
      onSettled: (...args) => {
        // Invalidate Queries to force refetch
        queryClient.invalidateQueries(folderQueryKey);
        queryClient.invalidateQueries(itemsQueryKey);
        onSettled(...args);
      },
    },
  );

  // Move Item
  const reorderMove = (indexA: number, indexB: number) => {
    if (items && folder) {
      const shifted = [...items.slice(0, indexA), ...items.slice(indexA + 1)];
      const sortedItems = [
        ...shifted.slice(0, indexB),
        items[indexA],
        ...shifted.slice(indexB),
      ];
      const itemIds = sortedItems.map((item) => item.id);

      // Run Mutation for Folder
      mutate({ ...folder, itemIds, sortedItems });
    }
  };

  return {
    reorderMove,
    folder,
    items,
    mutation,
    query: {
      error: folderQuery.error ?? itemsQuery.error,
      isError: folderQuery.isError || itemsQuery.isError,
      isLoading: folderQuery.isLoading || itemsQuery.isLoading,
      isSuccess: folderQuery.isSuccess || itemsQuery.isSuccess,
    },
  };
};

export { useGalleryItemOrder, useGalleryItemUpdate };
