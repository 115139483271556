import styled from "@emotion/styled";
import { preventInvalidForwarding } from "styles/styleUtils";

type ColumnsProps = {
  cols?: 2 | 3 | 4;
};

const gap = "1em";
const desktopGrid = (cols: number) =>
  `repeat(${cols}, calc((100% / ${cols}) - (${gap} * (${
    cols - 1
  } / ${cols}))))`;

const Columns = styled("div", {
  shouldForwardProp: preventInvalidForwarding<ColumnsProps>("cols"),
})<ColumnsProps>(({ cols = 4 }) => {
  const defaultCSS = {
    display: "grid",

    gridTemplateColumns: "100%",
    "@media (min-width: 500px)": {
      gridTemplateColumns: `repeat(2, calc(50% - (${gap} / 2)))`,
    },
    gap: `${gap}`,
    "&>*:last-of-type:nth-of-type(odd)": {
      display: "none",
    },
  } as const;

  let variant = {};

  if (cols === 3) {
    variant = {
      "@media (min-width: 768px)": {
        gridTemplateColumns: `${desktopGrid(cols)}`,
        "&>*:last-of-type:nth-of-type(odd)": {
          display: "unset",
        },
        "&>article:last-of-type:nth-of-type(odd)": {
          display: "flex",
        },
      },
    } as const;
  }
  if (cols === 4) {
    variant = {
      "@media (min-width: 820px)": {
        gridTemplateColumns: `${desktopGrid(cols)}`,
        "&>*:last-of-type:nth-of-type(odd)": {
          display: "unset",
        },
        "&>article:last-of-type:nth-of-type(odd)": {
          display: "flex",
        },
      },
    } as const;
  }
  return { ...defaultCSS, ...variant };
});

export { Columns };
