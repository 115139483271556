import * as React from "react";
import * as diacritics from "diacritics";
import { flattenConnection } from "@shopify/hydrogen-react";
import type {
  Collection,
  Image,
  PageInfo,
  Product,
  ProductFilter,
} from "@shopify/hydrogen-react/storefront-api-types";
import { useInfiniteQuery, useQuery } from "react-query";
import { client } from "./client";
import { parseGid } from "../utils/shopifyId";

const COLLECTION_QUERY = `#graphql
query collection($handle: String!, $first: Int!, $after: String, $filters: [ProductFilter!]) {
  collection(handle: $handle) {
    id
    title
    description
    descriptionHtml
    handle
    image {
      id
      altText
      height
      url
      width
    }
    products(first: $first, after: $after, filters: $filters) {
      nodes {
        id
        artistId: metafield(namespace: "hug", key: "artist_id") {
          value
        }
        artistName: metafield(namespace: "hug", key: "artist_name") {
          value
        }
        publishedAt: metafield(namespace: "hug", key: "published_at") {
          value
        }
        availableForSale
        handle
        productType
        title
        featuredImage {
          id
          altText
          height
          url
          width
        }
        compareAtPriceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        priceRange {
          minVariantPrice {
            amount
            currencyCode
          }
          maxVariantPrice {
            amount
            currencyCode
          }
        }
        status: metafield(namespace: "hug", key: "status") {
          value
        }
        templateId: metafield(namespace: "hug", key: "template_id") {
          value
        }
      }
      pageInfo {
        hasNextPage
        hasPreviousPage
        endCursor
        startCursor
      }
    }
  }
}`;

type ProductNode = Pick<
  Product,
  | "id"
  | "availableForSale"
  | "featuredImage"
  | "handle"
  | "productType"
  | "title"
  | "compareAtPriceRange"
  | "priceRange"
> & {
  artistId?: { value: string };
  artistName?: { value: string };
  publishedAt?: { value: string };
  status?: { value: "DRAFT" | "ACTIVE" | "ARCHIVED" };
  templateId?: { value: string };
};

type CollectionResp = Pick<
  Collection,
  "id" | "title" | "description" | "descriptionHtml" | "handle"
> & {
  image: Image;
  products: {
    nodes: ProductNode[];
    pageInfo: PageInfo;
  };
};

type CollectionQueryVars = {
  handle: string;
  numProducts?: number;
  after?: string;
  filters?: ProductFilter[];
};

/**
 *
 * Artist ID => Collection Handle
 *
 * Convert special characters (BožicaRakić => bozicarakic)
 * Convert to lower case
 * Replace period with dash
 */
const collectionSafeHandle = (id: string) =>
  diacritics
    .remove(id)
    .toLocaleLowerCase()
    .replaceAll(".", "-")
    .replaceAll(" ", "-");

/**
 * NOTE: This defaults to filtering for available products. To override, you can provide filters
 * value of empty array for all products, or another array of filters for more specific subset.
 */
const getCollection = ({
  handle,
  numProducts = 24,
  filters = [{ available: true }],
  ...vars
}: CollectionQueryVars) =>
  client.query<{ collection: CollectionResp | null }>(COLLECTION_QUERY, {
    ...vars,
    filters,
    first: numProducts,
    handle: collectionSafeHandle(handle),
  });

const transformCollectionProducts = (products: CollectionResp["products"]) =>
  flattenConnection(products).map((prod) => ({
    ...prod,
    id: parseGid(prod.id).id,
    gid: prod.id,
    artistId: prod.artistId?.value,
    artistName: prod.artistName?.value,
    publishedAt: prod.publishedAt?.value,
    status: prod.status?.value,
    templateId: prod.templateId?.value,
  }));

const useCollectionQuery = (vars: Omit<CollectionQueryVars, "after">) =>
  useQuery(["collection", "detail", vars], () => getCollection(vars), {
    enabled: !!vars.handle,
    select: (data) =>
      !data.collection
        ? null
        : {
            ...data.collection,
            products: transformCollectionProducts(data?.collection?.products),
          },
  });

const useCollectionInfiniteQuery = (vars: CollectionQueryVars) => {
  const query = useInfiniteQuery(
    ["collection", "detail", vars],
    ({ pageParam }) => getCollection({ ...vars, after: pageParam }),
    {
      getNextPageParam: (lastPage) =>
        lastPage?.collection?.products.pageInfo.hasNextPage &&
        lastPage?.collection?.products.pageInfo.endCursor,
    },
  );

  // We can't flatMap over the data as an array like most infinite queries, the GraphQL request
  // is for a *single* collection and the pagination is only for its related products.
  const data = React.useMemo(
    () =>
      query.data && {
        ...query.data.pages?.[0]?.collection,
        products: query.data.pages?.flatMap(({ collection }) =>
          !collection ? [] : transformCollectionProducts(collection?.products),
        ),
      },
    [query.data],
  );

  return {
    ...query,
    data,
  };
};

type CollectionQueryData = NonNullable<
  ReturnType<typeof useCollectionQuery>["data"]
>;
type CollectionQueryProductData = CollectionQueryData["products"][number];

export type { CollectionQueryProductData };
export { useCollectionInfiniteQuery, useCollectionQuery };
