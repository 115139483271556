import * as React from "react";
import { Navigate, useNavigate, useSearchParams } from "react-router-dom";
import { H1, Link, NoBreak, Stack, Text } from "atoms";
import { constants } from "config";
import { useCart } from "./CartProvider";
import { useCheckoutSessionStatus } from "../api/useCheckoutSessionStatus";
import { CHECKOUT_SESSION_ID_KEY } from "../utils/stripe";
import { trackPurchase } from "../utils/analytics";

function CheckoutStatus() {
  const navigate = useNavigate();

  const [searchParams] = useSearchParams();
  const sessionId = searchParams.get(CHECKOUT_SESSION_ID_KEY) ?? "";

  const { data } = useCheckoutSessionStatus(sessionId);
  const isComplete = !!data && data.status === "complete";

  // Reset any persisted cart once checkout is complete
  const { cart, resetCart } = useCart();

  const [from] = React.useState(cart?.checkoutOpts?.from);

  React.useEffect(() => {
    if (isComplete && !!cart) {
      trackPurchase(cart.lineItems);
      resetCart();
    }
  }, [isComplete, cart, resetCart]);

  // We have location to redirect to after success, show the link and start redirect timer
  React.useEffect(() => {
    if (from) {
      const timeout = window.setTimeout(() => {
        navigate(from);
      }, 5 * 1000);

      return () => {
        window.clearTimeout(timeout);
      };
    }
    return noop;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If there is no sessionId in url params or the session is still open, go back to checkout page
  // which will either reload a stored cart and continue the checkout process or show an empty cart.
  if (!sessionId || data?.status === "open") {
    return <Navigate to="/checkout" />;
  }

  if (isComplete) {
    return (
      <>
        <H1 size="h3" textAlign="center" textTransform="none">
          Your purchase is complete
        </H1>

        <Text textAlign="center">
          <span>Check your inbox </span>
          {data.customerEmail && (
            <span>
              at <strong>{data.customerEmail}</strong>{" "}
            </span>
          )}
          <span>for your confirmation email.</span>
        </Text>
        <Text size="xs" textAlign="center">
          Need help? Contact us at{" "}
          <Link href={`mailto:${constants.supportEmail}`}>
            {constants.supportEmail}
          </Link>
          .
        </Text>

        {from && (
          <Stack>
            <Text size="xs" textAlign="center">
              You&apos;ll be redirected shortly, or you can{" "}
              <NoBreak>click the link below.</NoBreak>
            </Text>
            <Text size="sm" textAlign="center">
              <Link to={from} variant="primary" size="xs">
                Continue
              </Link>
            </Text>
          </Stack>
        )}
      </>
    );
  }

  return null;
}

export { CheckoutStatus };
