import * as React from "react";

const getMotionQuery = () =>
  window.matchMedia("(prefers-reduced-motion: no-preference)");

/**
 * Checks the user's browser preferences for reduced motion settings
 * @returns boolean
 */
function usePrefersReducedMotion() {
  // Default to no-animations, since we don't know what the
  // user's preference is on the server.
  const [prefersReducedMotion, setPrefersReducedMotion] = React.useState(true);

  React.useEffect(() => {
    const mediaQueryList = getMotionQuery();

    // Set the true initial value, now that we're on the client:
    setPrefersReducedMotion(!mediaQueryList.matches);

    // Register our event listener
    const listener = (event: MediaQueryListEvent) => {
      setPrefersReducedMotion(event.matches);
    };
    mediaQueryList.addEventListener("change", listener);
    return () => {
      mediaQueryList.removeEventListener("change", listener);
    };
  }, []);

  return prefersReducedMotion;
}

export { usePrefersReducedMotion };
