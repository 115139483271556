import * as React from "react";
import styled from "@emotion/styled";
import { preventForwarding } from "styles/styleUtils";
import { FlexRow } from "./FlexRow";
import { Button } from "./Button";
import { Text } from "./Typography";
import { IconMinusThick, IconPlus } from "./icons";

type QuantityInputProps = {
  /** Optional value for "controlled" input */
  value?: number;
  /** Default starting value for "uncontrolled" input */
  defaultValue?: number;
  /** Optional string to place directly before the number */
  prefix?: string;
  /** Faux change event handler to update parent with new value */
  onChange: (qty: number) => void;
  /** Optional minimum value, defaults to MIN_SAFE_INTEGER */
  min?: number;
  /** Optional maximum value, defaults to MAX_SAFE_INTEGER */
  max?: number;
  size?: "xs" | "sm";
  disabled?: boolean;
};

function QuantityInput({
  value,
  defaultValue = 1,
  prefix,
  onChange,
  min = Number.MIN_SAFE_INTEGER,
  max = Number.MAX_SAFE_INTEGER,
  size = "xs",
  disabled,
}: QuantityInputProps) {
  // Set controlled "input" value in state using optional value and falling back to default
  const [quantity, setQuantity] = React.useState(value ?? defaultValue);

  // If value is controlled from parent, update local value when it changes
  React.useEffect(
    () => setQuantity(value ?? defaultValue),
    [value, defaultValue, setQuantity],
  );

  // Create handlers for increment/decrement buttons
  const createHandler = (newQty: number) => () => {
    if (newQty >= min && newQty <= max) {
      setQuantity(newQty);
      onChange(newQty);
    }
  };

  const decrement = createHandler(quantity - 1);
  const increment = createHandler(quantity + 1);

  return (
    <ShopQuantity>
      <ShopQuantityButton
        buttonSize={size}
        variant="text"
        onClick={decrement}
        aria-label="Subtract one"
        disabled={!!disabled || min >= quantity}
      >
        <IconMinusThick />
      </ShopQuantityButton>

      <ShopQuantityCount size={size} buttonSize={size}>
        {prefix}
        {quantity}
      </ShopQuantityCount>

      <ShopQuantityButton
        buttonSize={size}
        variant="text"
        onClick={increment}
        aria-label="Add one"
        disabled={!!disabled || max <= quantity}
      >
        <IconPlus />
      </ShopQuantityButton>
    </ShopQuantity>
  );
}

const ShopQuantity = styled(FlexRow)(({ theme }) => ({
  backgroundColor: theme.colors.bg,
  border: "1px solid",
  borderColor: theme.colors.fg60,
  borderRadius: 2,
  fontVariantNumeric: "tabular-nums",
  flexWrap: "nowrap",
  maxWidth: "max-content",
  gap: 0,
}));

type ShopQuantityButtonProps = { buttonSize: "xs" | "sm" };

const ShopQuantityButton = styled(Button, {
  shouldForwardProp: preventForwarding<ShopQuantityButtonProps>("buttonSize"),
})<ShopQuantityButtonProps>(({ buttonSize, theme }) => ({
  width: 20,
  height: 20,
  padding: 6,
  ...(buttonSize === "sm" && {
    width: 30,
    height: 30,
    padding: 10,
  }),
  "&&:hover, &&:focus-visible": {
    backgroundColor: theme.colors.fg10,
    color: theme.colors.fg,
    opacity: 1,
  },
}));

const ShopQuantityCount = styled(Text)<{ buttonSize: "xs" | "sm" }>(
  ({ buttonSize }) => ({
    fontVariantNumeric: "tabular-nums",
    textAlign: "center",
    margin: "0 !important",
    minWidth: buttonSize === "xs" ? 20 : 30,
    pointerEvents: "none",
    paddingTop: 1,
    lineHeight: 1,
  }),
);

export { QuantityInput };
