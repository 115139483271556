import styled from "@emotion/styled";
import { useLocation } from "react-router-dom";
import { GenericImage } from "features/images";
import {
  Button,
  FlexRow,
  IconArrow,
  IconEditItem,
  IconGrab,
  IconTrashCan,
  Link,
} from "atoms";
import { paths } from "utils/paths";
import type { GalleryItem } from "../types";

type GalleryItemCardProps = Pick<
  GalleryItem,
  "id" | "title" | "image" | "ownerId"
> & {
  cropToSquare?: boolean;
  isOrdering?: boolean;
} & (
    | {
        isEditing: true;
        deleteItem: VoidFunction;
      }
    | {
        isEditing?: false;
        deleteItem?: VoidFunction;
      }
  );

function GalleryItemCard({
  id,
  ownerId,
  title,
  image,
  isEditing,
  isOrdering,
  cropToSquare = false,
  deleteItem = noop,
  ...props
}: GalleryItemCardProps) {
  const location = useLocation();
  const { height, width } = image ?? {};
  const aspectRatio = cropToSquare
    ? "1"
    : `${!!width && !!height ? width / height : "auto"}`;

  const itemText = (
    <ItemText>
      <ItemTitle>{title}</ItemTitle>
      {!isOrdering && <IconArrow style={{ flex: "0 0 auto" }} aria-hidden />}
    </ItemText>
  );

  return (
    <Card
      cropToSquare={cropToSquare}
      aspectRatio={aspectRatio}
      className={isOrdering ? "draggable" : ""}
      {...props}
    >
      <SGenericImage
        src={image}
        sizes="(max-width: 830px) 50%, (max-width: 1023px) 33%, 431px"
      />
      {(isEditing || isOrdering) && (
        <EditButtons justifyContent={isOrdering ? "flex-start" : "flex-end"}>
          {isOrdering ? (
            <IconGrab
              aria-hidden
              style={{ color: "white", fontSize: "20px" }}
            />
          ) : (
            <>
              <Link
                variant="circle"
                size="sm"
                to={paths.artistGalleryItemEdit(ownerId, id)}
                aria-label="Edit Item"
                state={{ editorAction: "edit" }}
              >
                <IconEditItem aria-hidden />
              </Link>
              <Button
                variant="circle"
                size="sm"
                onClick={deleteItem}
                aria-label="Delete Item"
              >
                <IconTrashCan aria-hidden />
              </Button>
            </>
          )}
        </EditButtons>
      )}

      {!isOrdering && (
        <ItemLink
          to={
            isEditing
              ? paths.artistGalleryItemEdit(ownerId, id)
              : paths.artistGalleryItem(ownerId, id)
          }
          state={{ from: location }}
        >
          {itemText}
        </ItemLink>
      )}
    </Card>
  );
}
const Card = styled.li<{
  aspectRatio: React.CSSProperties["aspectRatio"];
  cropToSquare?: boolean;
}>(({ aspectRatio, cropToSquare, theme }) => ({
  aspectRatio,
  borderRadius: theme.borderRadius.sm,
  margin: 0,
  listStyle: "none",
  minHeight: 60,
  position: "relative",
  overflow: "hidden",
  "&.draggable": {
    cursor: "grab",
    transitionDuration: ".3s",
    transitionProperty: "box-shadow, top, left",
    "&:before": {
      backgroundColor: theme.colors.fg10,
      borderRadius: theme.borderRadius.sm,
      content: '""',
      display: "block",
      inset: 0,
      opacity: 0,
      position: "absolute",
      transition: ".3s ease opacity",
      zIndex: 2,
    },
    "&::after": {
      ...theme.fonts.display,
      border: `2px dashed rgb(0 0 0 / .4)`,
      borderRadius: theme.borderRadius.sm,
      color: theme.colors.fg80,
      alignItems: "center",
      content: '"Drop Item"',
      display: "flex",
      fontSize: 20,
      inset: 10,
      justifyContent: "center",
      opacity: 0,
      pointerEvents: "none",
      position: "absolute",
      textAlign: "center",
      transition: ".3s ease opacity",
      zIndex: 3,
      [theme.breakpoints.xs]: {
        fontSize: 26,
      },
    },
    "&:hover": {
      boxShadow: theme.boxShadow.dark,
    },
  },
  "&.draggable:active": {
    cursor: "grabbing",
  },
  // preview seen while dragging
  "&.drag-start": {
    cursor: "grabbing !important",
    boxShadow: "none",
    outline: 0,
    border: 0,
    img: {
      borderRadius: 6,
      boxShadow: theme.boxShadow.dark,
      transform: "scale(.625)",
      transformOrigin: "center",
      opacity: 0.66,
    },
    "div::before, svg": { display: "none" },
  },
  // what stays in the original spot
  "&.draggable.dragging": {
    opacity: 0.33,
    cursor: "grabbing",
    svg: { opacity: 0 },
  },
  "&.draggable.dragging.dragging-over": {
    "&::before, &::after": { opacity: 0 },
  },
  "&.draggable.dragging-over": {
    // outlineColor: theme.colors.accent1,
    "&:before": {
      opacity: 0.9,
    },
    "&:after": {
      opacity: 1,
    },
    svg: { opacity: 0 },
  },
  "&.dragging.dragging-over": {
    outlineColor: theme.colors.transparent,
    "&:after": {
      opacity: 0,
    },
  },
  svg: { transition: ".3s ease opacity" },
  ...(cropToSquare && {
    display: "flex",
    img: {
      objectFit: "cover",
      objectPosition: "top",
      width: "100%",
      height: "100%",
    },
    "&>*": {
      alignSelf: "stretch",
    },
  }),
}));

const ItemLink = styled(Link)(({ theme }) => ({
  position: "absolute",
  inset: 0,
  display: "flex",
  alignItems: "flex-end",
  zIndex: 1,
  textDecoration: "underline",
  color: theme.colors.bg,
  lineHeight: 1,
  "&:hover > div, &:focus-visible > div, &:focus > div": {
    color: theme.colors.bg,
    opacity: 1,
  },
}));

const ItemText = styled.div(({ theme }) => ({
  opacity: 0,
  transition: ".3s ease opacity",
  width: "100%",
  borderRadius: theme.borderRadius.sm,
  display: "flex",
  flexWrap: "nowrap",
  overflow: "hidden",
  whiteSpace: "nowrap",
  alignItems: "flex-end",
  pointerEvents: "none",
  position: "absolute",
  inset: 0,
  ...theme.fonts.display,
  gap: 6,
  padding: theme.spacing.sm,
  zIndex: 2,
  [theme.breakpoints.xs]: {
    padding: theme.spacing.md,
  },
  svg: {
    position: "relative",
    zIndex: 2,
  },
  "&::before": {
    content: "''",
    position: "absolute",
    zIndex: 1,
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    opacity: 0.666,
    backgroundImage: `
    linear-gradient(
      to top,
      hsla(0, 0%, 0%, 100%) 0px,
      hsla(0, 0%, 0%, 100%) 50px,
      hsla(0, 0%, 0%, 0%) 200px
      )`,
  },
}));

const SGenericImage = styled(GenericImage)({
  "&,img": {
    height: "100%",
  },
});
const ItemTitle = styled.span({
  overflow: "hidden",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  position: "relative",
  zIndex: 2,
});

const EditButtons = styled(FlexRow)(({ theme }) => ({
  padding: theme.spacing.sm,
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  paddingBottom: theme.spacing.lg,
  gap: 10,
  "*": {
    position: "relative",
    zIndex: 2,
  },
  "a, button": {
    backgroundColor: `${theme.colors.bg}60`,
    transition: ".2s ease all",
    zIndex: 2,
  },
  "li:hover & a, li:hover & button": {
    backgroundColor: `${theme.colors.bg}`,
  },
  "&::before": {
    backgroundImage:
      "linear-gradient(180deg, #00000080, #00000040, rgba(0,0,0,0))",
    content: '""',
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    zIndex: 1,
    pointerEvents: "none",
  },
}));

// const ItemTextContainer = styled.div({
//   position: "absolute",
//   inset: 0,
//   display: "flex",
//   alignItems: "flex-end",
//   zIndex: 1,
//   "& > div": {
//     color: "white",
//     opacity: 1,
//   },
// });

export { GalleryItemCard };
