import { useQuery } from "react-query";
import { useAuth } from "features/auth";
import { api } from "utils/api";
import { Purchase, PurchaseHistoryRequest } from "../types";
import { purchaseKeys } from "./queryKeys";

const getPurchaseHistory = (params?: PurchaseHistoryRequest) => {
  const queryString = params ? `?${new URLSearchParams(params)}` : "";
  return api.get<Purchase[]>(`/commerce/history${queryString}`);
};

const usePurchaseHistory = (params?: PurchaseHistoryRequest) => {
  const { user } = useAuth();

  return useQuery(purchaseKeys.list(params), () => getPurchaseHistory(params), {
    enabled: !!user,
  });
};

export { usePurchaseHistory };
