import { useMutation, useQueryClient } from "react-query";
import { api } from "utils/api";
import { shopOrderKeys } from "./queryKeys";

type ApproveOrderParams = {
  fulfillmentOrderId: string;
};

const postApproveOrder = (params: ApproveOrderParams) =>
  api.post(`/shop/approve_order`, {
    body: JSON.stringify(params),
  });

const useAdminApproveOrder = () => {
  const queryClient = useQueryClient();

  return useMutation(postApproveOrder, {
    onSuccess: () => {
      queryClient.invalidateQueries(shopOrderKeys.lists());
    },
  });
};

export { useAdminApproveOrder };
