import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js";
import { LoadingSpinner, UnexpectedError } from "atoms";
import { useCheckoutSession } from "../api/useCheckoutSession";
import type { CheckoutSessionRequest } from "../types";
import { stripe } from "../utils/stripe";

type CheckoutFormProps = {
  cart: CheckoutSessionRequest;
};

// NOTE: we don't want to pass `checkoutOpts` to Stripe, they're configuration for UI only
function CheckoutForm({
  cart: { checkoutOpts: _checkoutOpts, ...cart },
}: CheckoutFormProps) {
  const { data, isError, isLoading, error } = useCheckoutSession(cart);

  if (data) {
    return (
      <EmbeddedCheckoutProvider stripe={stripe} options={data}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    );
  }

  if (isLoading) {
    return <LoadingSpinner />;
  }

  if (isError) {
    return <UnexpectedError error={error} />;
  }

  return null;
}

export { CheckoutForm };
